import React, { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import cx from "classnames";
import { FiImage } from "react-icons/fi";
import { useDropzone } from "react-dropzone";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import { Button, Label } from "../../foundation";
import "./Dialog.css";
import getCroppedImg from "./cropImage";

const Dialog = ({ setCroppedImage, close }) => {
	const [file, setFile] = useState();
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [rotation, setRotation] = useState(0);
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	const showCroppedImage = useCallback(async () => {
		try {
			const croppedImage = await getCroppedImg(
				file,
				croppedAreaPixels,
				rotation
			);
			setCroppedImage(croppedImage);
			close();
		} catch (e) {
			console.error(e);
		}
	}, [croppedAreaPixels, rotation]);
	const onDrop = useCallback((acceptedFiles) => {
		const img = URL.createObjectURL(acceptedFiles[0]);
		setFile(img);
	}, []);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		maxFiles: 1,
		accept: "image/jpeg, image/x-png, .jpg, .jpeg, .png",
	});

	return (
		<Modal.Body>
			{file ? (
				<>
					<div className="image-wrapper">
						<Cropper
							image={file}
							crop={crop}
							rotation={rotation}
							zoom={zoom}
							aspect={1}
							cropShape="round"
							onCropChange={setCrop}
							onRotationChange={setRotation}
							onCropComplete={onCropComplete}
							onZoomChange={setZoom}
						/>
					</div>
					<div className="image-controls">
						<div className="px-4 mb-1">
							<Label as="span">Zoom</Label>
							<Slider
								value={zoom}
								min={1}
								max={3}
								step={0.1}
								aria-labelledby="Zoom"
								onChange={(e, zoom) => setZoom(zoom)}
							/>
						</div>
						<div className="px-4 mb-1">
							<Label as="span">Rotation</Label>
							<Slider
								value={rotation}
								min={0}
								max={360}
								step={1}
								aria-labelledby="Rotation"
								onChange={(e, rotation) => setRotation(rotation)}
							/>
						</div>
					</div>
					<Modal.Footer>
						<button className="btn btn-info mx-auto" onClick={showCroppedImage}>
							Save Image
						</button>
					</Modal.Footer>
				</>
			) : (
				<div className={cx("file-dropzone")} {...getRootProps()}>
					<input {...getInputProps()} />
					{isDragActive ? (
						<p>Drop the files here ...</p>
					) : (
						<>
							<p>
								<FiImage size={40} />
							</p>
							<p>Drag &amp; drop or select a photo from your computer.</p>
							<button className="btn btn-info">Choose Image</button>
						</>
					)}
				</div>
			)}
		</Modal.Body>
	);
};

export default Dialog;
