import React, { useEffect, useRef, useState, createRef } from "react";
import {
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	Image,
	Font,
	Svg,
	Path,
	Circle,
	Polyline,
} from "@react-pdf/renderer";
import { Html } from "react-pdf-html";
import {
	useA4Scale,
	TemplatePage,
	renderHTMLContent,
	getDisplayDate,
} from "../../utils";
import { Row, Col } from "react-bootstrap";
import DummyProfilePic from "../../../../../assets/images/background/profilepic.png";
import { FiPhone } from "react-icons/fi";
import { isEmpty } from "../../../foundation";

Font.registerHyphenationCallback((word) => {
	// Return entire word as unique part
	return [word];
});

const styles = StyleSheet.create({
	page: { display: "flex", fontFamily: "Roboto", paddingVertical: "30px" },
	wrapper: {
		display: "flex",
		flexDirection: "row",
		flex: 1,
	},
	left: {
		backgroundColor: "#3644a1",
		width: "250px",
	},
	right: {
		padding: "26px 20px 0px 30px",
		width: "540px",
	},
	contentPadding: {
		paddingLeft: "54px",
		paddingRight: "54px",
	},
	profileImageWrapper: {
		display: "flex",
		alignItems: "center",
		padding: "30px",
	},
	profileImage: {
		width: "100px",
		height: "100px",
		borderRadius: "50%",
		border: "1px solid #3644a1",
	},
});

const ContactInfo = ({ data: { Phone, Country, display_email } }) => (
	<View style={{ padding: "24px", paddingLeft: "12px" }}>
		<Text
			style={{
				color: "#ffffff",
				fontWeight: 700,
				fontSize: "11px",
				marginBottom: "10px",
				textDecoration: "underline",
			}}
		>
			CONTACT ME
		</Text>
		<View
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				marginBottom: "5px",
			}}
		>
			<View
				style={{
					backgroundColor: "#ffffff",
					padding: "3px",
					borderRadius: "50%",
					flex: "0 0 16px",
				}}
			>
				<Svg
					stroke="currentColor"
					fill="none"
					strokeWidth="2"
					viewBox="0 0 24 24"
					strokeLinecap="round"
					strokeLinejoin="round"
					height="10px"
					width="10px"
					xmlns="http://www.w3.org/2000/svg"
				>
					<Path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></Path>
				</Svg>
			</View>
			<Text
				style={{
					color: "#ffffff",
					fontSize: "8px",
					paddingLeft: "8px",
				}}
			>
				{Phone}
			</Text>
		</View>
		<View
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				marginBottom: "5px",
			}}
		>
			<View
				style={{
					backgroundColor: "#ffffff",
					padding: "3px",
					borderRadius: "50%",
					flex: "0 0 16px",
				}}
			>
				<Svg
					stroke="#ffffff"
					fill="#000000"
					strokeWidth="2"
					viewBox="0 0 24 24"
					strokeLinecap="round"
					strokeLinejoin="round"
					height="10px"
					width="10px"
					xmlns="http://www.w3.org/2000/svg"
				>
					<Path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></Path>
					<Polyline points="22,6 12,13 2,6"></Polyline>
				</Svg>
			</View>
			<Text
				style={{
					color: "#ffffff",
					fontSize: "8.75px",
					paddingLeft: "8px",
				}}
			>
				{display_email}
			</Text>
		</View>
		<View
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
			}}
		>
			<View
				style={{
					backgroundColor: "#ffffff",
					padding: "3px",
					borderRadius: "50%",
					flex: "0 0 16px",
				}}
			>
				<Svg
					stroke="#ffffff"
					fill="currentColor"
					strokeWidth="2"
					viewBox="0 0 24 24"
					strokeLinecap="round"
					strokeLinejoin="round"
					height="10px"
					width="10px"
					xmlns="http://www.w3.org/2000/svg"
				>
					<Path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></Path>
					<Circle fill="#ffffff" cx="12" cy="10" r="3"></Circle>
				</Svg>
			</View>
			<Text
				style={{
					color: "#ffffff",
					fontSize: "8px",
					paddingLeft: "8px",
				}}
			>
				{Country}
			</Text>
		</View>
	</View>
);

const Skills = ({ data: { skills } }) => {
	if (!skills || !skills.length) {
		return <View />;
	}
	return (
		<View
			style={{ marginLeft: "12px", marginBottom: "0px", marginTop: "12px" }}
		>
			<Text
				style={{
					color: "#ffffff",
					fontWeight: 700,
					fontSize: "12px",
					marginBottom: "10px",
					textDecoration: "underline",
				}}
			>
				SKILLS
			</Text>
			{skills.map((s) => (
				<Text
					style={{
						color: "#ffffff",
						fontSize: "10px",
						paddingBottom: "5px",
					}}
				>
					{s.skill_name}
				</Text>
			))}
		</View>
	);
};

const Hobbies = ({ data: { hobbies } }) => {
	if (!hobbies || !hobbies.length) {
		return <View />;
	}
	return (
		<View
			style={{ marginLeft: "12px", marginBottom: "0px", marginTop: "12px" }}
		>
			<Text
				style={{
					color: "#ffffff",
					fontWeight: 700,
					fontSize: "12px",
					marginBottom: "10px",
					textDecoration: "underline",
				}}
			>
				HOBBIES
			</Text>

			{hobbies.map((s) => (
				<Text
					style={{
						color: "#ffffff",
						fontSize: "10px",
						paddingBottom: "10px",
					}}
				>
					{s.hobbies}
				</Text>
			))}
		</View>
	);
};

const Languages = ({ data: { languages } }) => {
	if (!languages || !languages.length) {
		return <View />;
	}
	return (
		<View
			style={{
				// padding: "24px",
				marginLeft: "12px",
				marginBottom: "0px",
				marginTop: "12px",
			}}
		>
			<Text
				style={{
					color: "#ffffff",
					fontWeight: 700,
					fontSize: "12px",
					marginBottom: "10px",
					textDecoration: "underline",
				}}
			>
				LANGUAGES
			</Text>
			{languages.map((s) => (
				<Text
					style={{
						color: "#ffffff",
						fontSize: "10px",
						paddingBottom: "10px",
					}}
				>
					{s.language_name}
				</Text>
			))}
		</View>
	);
};

const References = ({ data, dataKey, heading, fieldMap = {} }) => {
	const items = data[dataKey];
	const { title, subTitle, phoneNo, emailId } = fieldMap;
	if (!items || !items.length) {
		return <View />;
	}
	return (
		<View style={{ marginTop: "45px" }}>
			<Text
				style={{
					fontSize: "12px",
					fontWeight: 500,
					color: "#3644a1",
					textDecoration: "underline",
				}}
			>
				{heading}
			</Text>
			{items.map((item) => (
				<View style={{ marginTop: "15px" }}>
					<Text style={{ fontSize: "11px", fontWeight: "500" }}>
						{item[title]}
					</Text>
					<Text
						style={{ fontSize: "11px", color: "#3644a1", marginTop: "3px" }}
					>
						{item[subTitle]}
					</Text>
					<Text
						style={{ fontSize: "10px", color: "#363434", marginTop: "3px" }}
					>
						{item[phoneNo]}
					</Text>
					<Text
						style={{ fontSize: "11px", color: "#363434", marginTop: "3px" }}
					>
						{item[emailId]}
					</Text>
				</View>
			))}
		</View>
	);
};

const AdditionalInfo = ({
	data: {
		Address,
		City,
		Postal_Code,
		Driving_License,
		Nationality,
		dob,
		Place_of_Birth,
	},
}) => {
	const details = [
		{
			label: "Address",
			value: `${Address || ""} ${City || ""} ${Postal_Code || ""}`.trim(),
		},
		{
			label: "Driving License",
			value: Driving_License,
		},
		{
			label: "Nationality",
			value: Nationality,
		},
		{
			label: "Date of Birth",
			value: dob,
		},
		{
			label: "Place of Birth",
			value: Place_of_Birth,
		},
	];
	return details.map((info) =>
		info.value ? (
			<View style={{ padding: "4px 12px 8px" }}>
				<Text
					style={{
						color: "#ffffff",
						fontWeight: 600,
						fontSize: "11px",
					}}
				>
					{info.label}
				</Text>
				<Text
					style={{
						color: "#ffffff",
						fontSize: "10px",
					}}
				>
					{info.value}
				</Text>
			</View>
		) : (
			<View />
		)
	);
};

const RenderView = ({ data, dataKey, heading, fieldMap = {} }) => {
	const items = data[dataKey];
	const {
		title,
		subTitle,
		startDate,
		endDate,
		description,
		location = "city",
	} = fieldMap;
	if (!items || !items.length) {
		return <View />;
	}
	return (
		<View style={{ marginTop: "32px" }}>
			<Text
				style={{
					fontSize: "12px",
					fontWeight: 500,
					color: "#3644a1",
					textDecoration: "underline",
				}}
			>
				{heading}
			</Text>
			{items.map((item) => (
				<View style={{ marginTop: "15px" }} wrap={false}>
					<Text style={{ fontSize: "11px", fontWeight: "500" }}>
						{item[title]}
					</Text>
					<Text
						style={{ fontSize: "11px", color: "#3644a1", marginTop: "3px" }}
					>
						{item[subTitle]}
					</Text>
					<Text
						style={{
							fontSize: "10px",
							color: "#363434",
							marginTop: "3px",
							fontWeight: "400",
						}}
					>
						{getDisplayDate(item[startDate], item[endDate])}{" "}
						{item[location] && `, ${item[location]}`}
					</Text>
					{item[description] && (
						<View style={{ marginTop: "5px" }}>
							{renderHTMLContent(item[description], {
								"*": { color: "#3f3f3f" },
							})}
						</View>
					)}
				</View>
			))}
		</View>
	);
};

const Template1 = ({ data }) => {
	const {
		profileImage,
		First_Name,
		Last_Name,
		Wanted_Job_Title,
		professionalSummary: { Description = "" } = {},
	} = data;
	return (
		<TemplatePage data={data}>
			<Document>
				<Page size="A4" style={styles.page}>
					<View
						style={{
							position: "absolute",
							height: "100vh",
							width: "200px",
							left: "0px",
							top: "0px",
						}}
						fixed
					>
						<View
							style={{
								height: "100%",
								backgroundColor: "#3644a1",
							}}
						></View>
					</View>
					<View style={styles.wrapper}>
						<View style={styles.left}>
							<View style={styles.profileImageWrapper}>
								<Image
									src={profileImage || DummyProfilePic}
									style={styles.profileImage}
								/>
							</View>
							<ContactInfo data={data} />
							<AdditionalInfo data={data} />
							<Skills data={data} />
							<Hobbies data={data} />
							<Languages data={data} />
						</View>
						<View style={styles.right}>
							<View>
								<Text style={{ fontWeight: 500, fontFamily: "Roboto" }}>
									{First_Name} {Last_Name}
								</Text>
								<Text
									style={{
										fontWeight: 300,
										fontSize: "14px",
										color: "#3f3f3f",
										// marginTop: "1px",
									}}
								>
									{Wanted_Job_Title}
								</Text>
							</View>
							{!isEmpty(Description) && (
								<View style={{ marginTop: "12px" }}>
									<Text
										style={{
											fontWeight: 500,
											fontSize: "14px",
											marginBottom: "6px",
										}}
									>
										Profile summary
									</Text>
									<View style={{ color: "#3f3f3f" }}>
										{renderHTMLContent(Description)}
									</View>
								</View>
							)}
							<RenderView
								data={data}
								dataKey="employmentHistory"
								heading="WORK EXPERIENCE"
								fieldMap={{
									subTitle: "Employer",
									title: "Job_title",
									startDate: "start_date",
									endDate: "end_date",
									description: "Description",
								}}
							/>
							<RenderView
								data={data}
								dataKey="education"
								heading="EDUCATION"
								fieldMap={{
									title: "institute_name",
									subTitle: "degree",
									startDate: "start_date",
									endDate: "end_date",
									description: "Description",
								}}
							/>
							<RenderView
								data={data}
								dataKey="internships"
								heading="INTERNSHIPS"
								fieldMap={{
									title: "employer_name",
									subTitle: "job_title",
									startDate: "start_date",
									endDate: "end_date",
									description: "description",
								}}
							/>
							<RenderView
								data={data}
								dataKey="activities"
								heading="EXTRA_CURRICULAR_ACTIVITY"
								fieldMap={{
									title: "employer_name",
									subTitle: "function_title",
									startDate: "start_date",
									endDate: "end_date",
									description: "description",
								}}
							/>

							<RenderView
								data={data}
								dataKey="courses"
								heading="COURSES"
								fieldMap={{
									title: "course_name",
									subTitle: "institute_name",
									startDate: "start_date",
									endDate: "end_date",
								}}
							/>
							<References
								data={data}
								dataKey="References"
								heading="REFERENCES"
								fieldMap={{
									title: "ref_name",
									subTitle: "company_name",
									phoneNo: "phone_no",
									emailId: "ref_email",
								}}
							/>
						</View>
					</View>
				</Page>
			</Document>
		</TemplatePage>
	);
};

Template1.displayName = "Template1";
export default Template1;
