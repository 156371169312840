import React, { Fragment, useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaSearch } from "react-icons/fa";
import Url from "../../Url";
import moment from "moment";
import { useDebounce } from "helper/utils";
import {
  Col,
  Row,
  Dropdown,
  Card,
  Breadcrumb,
  Button,
  Modal,
  Table,
  Form,
  InputGroup,
} from "react-bootstrap";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import Pagination from "components/elements/advance-table/Pagination";
import { Link } from "react-router-dom";
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import DatePicker from "react-flatpickr";
import { date } from "yup";
toast.configure();

//import { set } from "react-ga";

const Stureglist = () => {
  const url1 = Url();
  const url = url1["url"];

  const admtoken = localStorage.getItem("emptoken");
  //const email = localStorage.getItem("email")

  const [getstureglist, setGetstureglist] = useState([]);

  const [searchcontent, setSearchcontent] = useState("");
  const [deletescuucess, setDeletescuucess] = useState(false);
  // const [paginationBasic, setPaginationBasic] = useState("");
  // const [activePage, setActivePage] = useState(0);
  // const [showData, setShowData] = useState();
  const [registration_date_from, setRegistrationDateFrom] = useState(moment().subtract(1, 'months').toDate());
  const [registration_date_to, setRegistrationDateTo] = useState(moment().toDate());
  const [currentdate, setCurrentdate] = useState(null);

  const todayDate = new Date();
  todayDate.setDate(todayDate.getDate() - 30);
  const [filterRegDate, setFilterRegDate] = useState(todayDate);
  {
    /*---------------Set int post inactive---------------*/
  }

  const debouncedSearchTerm = useDebounce(searchcontent, 2000);

  useEffect(() => {
    // var d = new Date();
    // var month =
    //   d.getMonth() > 9 ? "" + (d.getMonth() + 1) : "0" + (d.getMonth() + 1);
    // var day = d.getDate() > 10 ? "" + d.getDate() : "0" + d.getDate();
    // var year = d.getFullYear();
    // setCurrentdate([year, month, day].join("-"));
    // setRegistrationDateFrom([year, d.getMonth(), day].join("-"));

    const api = url + "setInactiveInternshipPost";
    fetch(api, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        //console.log(actualdata1)
      });
  }, []);
  {
    /*---------------Set int post inactive end---------------*/
  }
  const deleteStudent = (id) => {
    const res = window.confirm("The Intern will be deleted, are you sure?");
    if (res) {
      const api = url + "detetestudentbyid/" + id;
      fetch(api, {
        method: "DELETE",
        body: JSON.stringify({ id }),
        headers: { "Content-Type": "application/json" },
      })
        .then((apidata) => {
          return apidata.json();
        })
        .then((actualdata) => {
          toast.success("Intern deleted successfully ....", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
          setDeletescuucess(true);
        });
    }
  };

  /* Employer  Activate function*/

  // const employerActivate = (id) => {
  //   const res = window.confirm("This intern account will be activated, are you sure?");
  //   if (res) {
  //     const api = url + "activateEmployer";
  //     fetch(api, {
  //       method: "PUT",
  //       body: JSON.stringify({ id }),
  //       headers: { "Content-Type": "application/json" },
  //     })
  //       .then((apidata) => {
  //         return apidata.json();
  //       })
  //       .then((actualdata) => {
  //         toast.success("Intern account activated successfully ....", { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
  //         setDeletescuucess(true);
  //       });
  //   }
  // }

  // const employerDeactivate = (id) => {
  //   const res = window.confirm("This intern account will be deactivated, are you sure?");
  //   if (res) {
  //     const api = url + "deActivateEmployer";
  //     fetch(api, {
  //       method: "PUT",
  //       body: JSON.stringify({ id }),
  //       headers: { "Content-Type": "application/json" },
  //     })
  //       .then((apidata) => {
  //         return apidata.json();
  //       })
  //       .then((actualdata) => {
  //         toast.success("Intern account deactivated successfully ....", { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
  //         setDeletescuucess(true);
  //       });
  //   }
  // }

  const getStudentList = () => {
    const dateFrom = moment(registration_date_from).format('YYYY-MM-DD');
    const dateTo = moment(registration_date_to).format('YYYY-MM-DD');
    var searchString = "";
    if (searchcontent) {
      searchString = "/" + searchcontent;
    }
    const api =
      url + "GetAllStudentRegDetails" + "/" + dateFrom + "/" + dateTo + searchString;

    fetch(api, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        //console.log(actualdata1)
        setGetstureglist(actualdata1);
        setDeletescuucess(false);
      });
  };

  useEffect(() => {
    getStudentList();
  }, [deletescuucess, registration_date_from, registration_date_to,filterRegDate]);

  useEffect(() => {
    if (debouncedSearchTerm) {
        // Assume there's an API function to call
        getStudentList();
    }
  }, [debouncedSearchTerm]); // Effect runs on change of debounced search term


  // Handler for date change
  const handleFromDateChange = (selectedDates) => {
    // Update state with the first selected date, since Flatpickr supports range selection by default

    if (selectedDates.length > 0) {
      setRegistrationDateFrom(selectedDates[0]);
    }
  };

  // Handler for date change
  const handleToDateChange = (selectedDates) => {
    // Update state with the first selected date, since Flatpickr supports range selection by default

    if (selectedDates.length > 0) {
      setRegistrationDateTo(selectedDates[0]);
    }
  };


  // useEffect(() => {
  //   getStudentList();
  // }, []);
  // useEffect(() => {
  //   const api = url + `GetAllStudentRegDetails/1/${searchcontent}`;
  //   fetch(api, {
  //     method: "GET",
  //   })
  //     .then((apidata1) => {
  //       return apidata1.json();
  //     })
  //     .then((actualdata1) => {
  //       // setGetstureglist(actualdata1);
  //       let totalPages = Math.ceil(actualdata1.recordCnt / 500);
  //       let items = [];
  //       for (let i = 1; i <= totalPages; i++) {
  //         items.push(
  //           <Pagination.Item
  //             key={i}
  //             active={i === activePage}
  //             onClick={() => {
  //               setActivePage(i);
  //             }}
  //             cursor="pointer"
  //           >
  //             {i}
  //           </Pagination.Item>
  //         );
  //       }

  //       setPaginationBasic(
  //         <div>
  //           <Pagination size="md">{items}</Pagination>
  //         </div>
  //       );
  //       setShowData(actualdata1.data);
  //     });
  // }, [searchcontent]);
  // useEffect(() => {
  //   const api = url + `GetAllStudentRegDetails/${activePage}/${searchcontent}`;
  //   fetch(api, {
  //     method: "GET",
  //   })
  //     .then((apidata1) => {
  //       return apidata1.json();
  //     })
  //     .then((actualdata1) => {
  //       setGetstureglist(actualdata1);
  //       setShowData(actualdata1.data);
  //     });
  // }, [activePage]);

  /*--------------------------- Generate CSV ---------------------------*/
  const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData =
      typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

    var CSV = "";

    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index + ",";
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + "\r\n";
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + "\r\n";
    }

    if (CSV === "") {
      alert("Invalid data");
      return;
    }

    //Generate a file name
    var fileName = "IG Student List";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  /*--------------------------- Generate CSV ---------------------------*/
  // console.log(getstureglist)

  const SkillsCell = ({ value }) => {
    const maxHeight = "40px";
    const whiteSpace = value.length > 8 ? "pre-wrap" : "nowrap";
    return (
      <div style={{ maxHeight, overflow: "hidden", whiteSpace }}>{value}</div>
    );
  };

  const columns = useMemo(
    () => [
      { Header: "Serial No", Cell: (prop) => prop.row.index + 1 },
      { accessor: "fullname", Header: "Student Name" },
      { accessor: "email", Header: "Email Id", id: "email" },
      { accessor: "password", Header: "Password" },
      {
        accessor: "software_skill",
        Header: "Skills",
        Cell: ({ value }) => {
          return (
            <span>
              {value === null ? "No Skill Added" : <SkillsCell value={value} />}
            </span>
          );
        },
      },
      {
        accessor: "unable_login",
        Header: "Status",
        id: "status",
        Cell: ({ value }) => {
          return <p>{value === 1 ? "Active" : "Inactive"}</p>;
        },
      },
      { accessor: "mobileno", Header: "Mobile No" },
      { accessor: "location", Header: "Location" },
      { accessor: "education_details", Header: "Education" },
      { accessor: "created_at", Header: "Registration Date" },
      {
        accessor: "action",
        Header: "Action",
        Cell: ({ value, row }) => {
          return (
            <Button
              className="btn-sm btn-outline-danger btn-white m-1"
              onClick={() => deleteStudent(row.original.id)}
            >
              Delete
            </Button>
          );
        },
      },
    ],
    []
  );
  const dateFilter =
    getstureglist &&
    getstureglist.filter((item) => {
      const dateDate = new Date(item.created_at);
      const temp = new Date(filterRegDate);
      return dateDate > temp;
    });
  // console.log(getstureglist)

  // const data = useMemo(() => {
  //   const filtered = getstureglist.filter((item) => {
  //     const dateDate = new Date(item.created_at);
  //     const temp = new Date(filterRegDate);
  //     return dateDate > temp;
  //   });
  //   return filtered;
  // }, [getstureglist]);
  const data = useMemo(() => getstureglist, [getstureglist]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 30,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );

  const { pageIndex, globalFilter } = state;
  return (
    <Fragment>
      <Row>
        <Col lg={8} md={8} sm={8}>
          <div className="border-bottom pb-1 mb-1 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">Registered Student List</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Registration</Breadcrumb.Item>
                <Breadcrumb.Item active>Students</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div></div>
          </div>
        </Col>
        <Col>
          <div className="border-bottom pb-1 mb-1 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h5 fw-bold">Registration Date From</h1>
              <DatePicker
                value={registration_date_from}
                onChange={handleFromDateChange}
                options={{
                  altInput: true,
                  altFormat: "F j, Y",
                  dateFormat: "Y-m-d",
                }}
              />
            </div>
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h5 fw-bold">Registration Date To</h1>
              <DatePicker
                value={registration_date_to}
                onChange={handleToDateChange}
                options={{
                  altInput: true,
                  altFormat: "F j, Y",
                  dateFormat: "Y-m-d",
                }}
              />
            </div>            
            <div></div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-3 px-3 ">
          {/* <GlobalFilter
            filter={globalFilter}
            setFilter={setGlobalFilter}
            placeholder="Search"
          /> */}
          <Form.Control type="text" placeholder="Searchdd" value={searchcontent} 
          onChange={e => setSearchcontent(e.target.value)}
          />
        </Col>
        {getstureglist.length > 0 ? (
          <>
            <Col sm={12} className="ml-0">
              <button
                className="btn btn-success ml-2 mt-1"
                onClick={() =>
                  JSONToCSVConvertor(getstureglist, "_Student_details", true)
                }
              >
                Download Excel
              </button>
            </Col>
          </>
        ) : null}
      </Row>

      <Row className="mt-3">
        <Col lg={12} md={12} sm={12}>
          <Card>
            <Card.Body className="p-0">
              <div
                className="table-responsive"
                style={{ 
                overflow: "auto", 
                // height: "500px" 
              }}
              >
                <Table
                  {...getTableProps()}
                  className="text-nowrap"
                  style={{ height: "400px" }}
                >
                  <thead className="table-light">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              {/* Pagination @ Footer */}
              <Pagination
                previousPage={previousPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                showEllipsis={true}                
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Stureglist;
