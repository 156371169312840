
export const AppConfigReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'CHANGE_MODE':
      console.log("CHANGE_MODE" + JSON.stringify(payload));
       console.log("Payload value " + payload.isDark)
      // console.log("state : " + JSON.stringify(state))
      // console.log("payload : " + JSON.stringify(payload))      
      return {
        ...state,
        isDark : payload.isDark
      }   
    default:
      return state;
  }
};
