// import node module libraries
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Container, Form, Card } from "react-bootstrap";

// import custom components
import LogosTopHeading2 from "components/marketing/common/clientlogos/LogosTopHeading2";
import StartIcon from "assets/images/svg/price-icon-1.svg";
import TeamIcon from "assets/images/svg/price-icon-3.svg";

// import sub components
import ResumePricingCard from "./ResumePricingCard";

// import data files
import FAQsData from "data/marketing/pricing/FAQsData";
import Url from "components/Url";

import Axois from "axios";

import BuyNowModal from "components/buynow/BuyNowModal";
import LoginModalNew from 'components/authentication/LoginModalNew';

const ResumeBuilderPricing = () => {
  const [pricingData, setPricingData] = useState([]);
  const [resumeSubscriptions, setResumeSubscriptions] = useState([]);
  
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [showBuyNowPopup, setShowBuyNowPopup] = useState(false);
  const [product,setProduct] = useState();

  const url1 = Url();
  const url = url1["url"];
  const s3 = url1["s3"];
  const s3URL = s3 + "logos/";

  const LogoList1 = [
    {
      id: 1,
      logoimage: s3URL + "Oracle-Logo.png",
    },
    {
      id: 2,
      logoimage: s3URL + "ibm-logo.png",
    },
    {
      id: 3,
      logoimage: s3URL + "tech-mahindra-logo.png",
    },
    {
      id: 4,
      logoimage: s3URL + "HCL-Logo.png",
    },
    {
      id: 5,
      logoimage: s3URL + "astrazeneca-logo.png",
    },
    {
      id: 6,
      logoimage: s3URL + "Wipro-logo.png",
    },
  ];

  const ImageList = [StartIcon, TeamIcon];

  const fetchPricingData = async () => {
    const response = await fetch(`${url}getResumeProducts`);
    const data = await response.json();
    // console.log("data", data);
    setPricingData(data);
  };

  const handleBuyNow = (productDetails) => {
    if (isUserLoggedIn) {
      //console.log("loggedin");
      if(productDetails.id ==1){
        window.location.href="/ResumeBuilder#products";
        return;
      }

      sessionStorage.removeItem("buynowLogin");
      sessionStorage.removeItem("redirectURL");

      const product = {course_amount: productDetails.product_price, 
                      subject_id : productDetails.id,
                      itemType: 2,
                      subject_name: productDetails.plan_name,
                      course_duration: productDetails.product_active_access_days,
                      customer_type: 1
          };
        setProduct(product);
        setShowBuyNowPopup(true);
    } else {
      //console.log("NOT loggedin");
      sessionStorage.setItem("buynowLogin", true);
      sessionStorage.setItem("redirectURL", "ResumeBuilder/Pricing");
      //window.location.href = "/student-login";
      setLoginModalShow(true);
    }
  };

  useEffect(() => {
    sessionStorage.removeItem("buynowLogin");
    sessionStorage.removeItem("redirectURL");
    fetchPricingData();
    if (localStorage.getItem("stutoken")) {
      setIsUserLoggedIn(true);
    }  
  }, []);

  const pricingArray =
    pricingData.resumeProducts && Object.values(pricingData.resumeProducts);

  return (
    <Fragment>

    <LoginModalNew show={loginModalShow} setModalShow={setLoginModalShow} onHide={() => setLoginModalShow(false)} activeTab={"student"} />
      {showBuyNowPopup && (
        <BuyNowModal
          show={showBuyNowPopup}
          onHide={() => setShowBuyNowPopup(false)}
          product={product}
        />
      )}      
      <div className="py-lg-13 py-8" style={{ backgroundColor: "#06188c" }}>
        <Container>
          {/* Page header */}
          <Row className="align-items-center">
            <Col xl={{ span: 8, offset: 2 }} lg={12} md={12} sm={12}>
              <div className="text-center mb-6 px-md-8">
                <h1 className="text-white display-3 fw-bold">
                  Affordable pricing that scales with your needs...
                </h1>
                {/* <p className="text-white lead mb-4">
                  Reference giving information on its origins, as well as a
                  random Lipsum generator.
                </p> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Content */}
      <div className="mt-n8 pb-8">
        <Container>
          <Row className="justify-content-center">
            {!pricingData || !pricingData.resumeProducts ? (
              <div>loading...</div>
            ) : (
              pricingArray.map((item, index) => {
                return (
                  <Col lg={5} md={12} sm={12} key={index}>
                    <ResumePricingCard plan={item} handleBuyNow={handleBuyNow} img={ImageList[index]} />
                  </Col>
                );
              })
            )}
          </Row>
        </Container>
      </div>

      {/* Client logo */}
      <LogosTopHeading2
        title="Loved by over 5 million users from companies like"
        logos={LogoList1}
      />

      {/* FAQ */}
      <div className="py-lg-10 py-5">
        <Container>
          <Row>
            {/* Row */}
            <Col md={12} sm={12}>
              <div className="mb-8 text-center">
                <h2 className="h1">Frequently Asked Questions</h2>
              </div>
            </Col>
          </Row>
          {/* Row */}
          <Row>
            {/* FAQs List */}
            {FAQsData.map((item, index) => {
              return (
                <Col lg={4} md={6} sm={12} className="mb-3" key={index}>
                  <h4>{item.question}</h4>
                  <p>{item.answer}</p>
                </Col>
              );
            })}
            {/* Col */}
            <Col md={12} sm={12} className="mt-lg-10 mt-4">
              {/* Card*/}
              <Card>
                {/* Card body */}
                <Card.Body>
                  <div className="d-lg-flex justify-content-between align-items-center">
                    <h4 className="mb-0">Have other questions?</h4>
                    <span>
                      Send us a mail via:{" "}
                      <Link
                        to="/ContactUs"
                        target="_blank"
                      >
                        info@internshipgate.com
                      </Link>
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default ResumeBuilderPricing;
