
import { useState } from 'react';
import { Card, Image, Col, Row } from 'react-bootstrap';
import { BsEye } from 'react-icons/bs';
import Url from 'components/Url';
import { getTimePassed } from "../../helper/utils.js";

const JobListingListviewCard = (props) => {
  const { item } = props;
  const url1 = Url();
  const s3 = url1["s3"];
  const location = item.location;
  const llocation = location.split(',');

  const [s3url, setS3url] = useState(s3 + "imgupload/");

  return (
    <Card className="card-bordered mb-4 card-hover cursor-pointer">
      <Card.Body>
        <div className="d-flex align-items-center mb-3">
          <Image
            src={s3url + item.org_logo}
            height="50"
            width="50"
            alt="Company Logo"
            className="icon-shape border rounded-circle me-3"
          />
          <div>
            <h3 className="mb-1 fs-4">{item.internship_details}</h3>
            <span>at {item.org_name}</span>
          </div>
          {item.activity_rank > 0 && (
            <div className="position-absolute top-0 end-0 mt-2 me-2" style={{ color: "var(--geeks-primary)" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-lightning-fill me-1"
                viewBox="0 0 16 16"
              >
                <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"></path>
              </svg>
              <span>Actively Hiring</span>
            </div>
          )}
        </div>

        <Row className="mb-3">
          <Col xs={12} md={4} className="d-flex align-items-center mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="me-1"
              viewBox="0 0 24 24"
            >
              <path d="M12 2a6 6 0 0 0-6 6c0 2.5 1.5 4.6 3.7 5.4-.4.6-.7 1.2-.7 2 0 1.3 1.1 2.4 2.4 2.4h1.2c1.3 0 2.4-1.1 2.4-2.4 0-.8-.3-1.4-.7-2 2.2-.8 3.7-2.9 3.7-5.4a6 6 0 0 0-6-6zm0 15c-.8 0-1.5-.7-1.5-1.5h3c0 .8-.7 1.5-1.5 1.5zm0-3.5c-.8 0-1.5-.7-1.5-1.5h3c0 .8-.7 1.5-1.5 1.5z" />
            </svg>
            Skills: {item.skill}
          </Col>

          <Col xs={12} md={4} className="d-flex align-items-center mb-2">
            <i className="fe fe-briefcase me-1"></i>
            Duration: {item.duration1}
            {item.duration2 === "M" ? " Months" : " Weeks"}
          </Col>

          <Col xs={12} md={4} className="d-flex align-items-center mb-2">
            <i className="fe fe-map-pin me-1"></i>
            Location:
            {llocation.length > 5 ? (
              <span>{`${llocation.slice(0, 5).join(', ')} , `}</span>
            ) : (
              llocation.map((loc, index) => (
                <span key={index}>{`${loc}${index < llocation.length - 1 ? ', ' : ''}`}</span>
              ))
            )}
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={12} md={6} className="d-flex align-items-center mb-2">
            <span className="me-2">
              Stipend: ₹
              {item.compensation1 != null ? (
                <span className="ms-1">
                  {item.compensation1}
                  {item.compensation2 === "Lump Sum"
                    ? " " + item.compensation2
                    : item.compensation2 === "Months"
                      ? "/Month"
                      : "/Week"}
                </span>
              ) : (
                <span className="ms-1">Unpaid</span>
              )}
            </span>
          </Col>

          <Col xs={12} md={6} className="d-flex align-items-center justify-content-end mb-2">
            <i className="fe fe-briefcase me-1"></i>
            <span>
              Posted at: {getTimePassed(item.posting_date)}
            </span>
          </Col>
        </Row>

        {item.impressions > 0 && (
          <Row>
            <Col className="d-flex justify-content-end align-items-center">
              <span style={{ color: "var(--geeks-primary)" }}>
                <BsEye size={13} />
                <span className="small ms-1">{item.impressions} impressions</span>
              </span>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default JobListingListviewCard;

