import { v4 as uuid } from "uuid";
/**
 *  All Dashboard Routes
 *
 *  Understanding name/value pairs for Dashboard routes
 *
 *  Applicable for main/root/level 1 routes
 *  icon 		: String - It's only for main menu or you can consider 1st level menu item to specify icon name.
 * 				: Object - Icon as an object added from v1.4.0.
 *
 *  Applicable for main/root/level 1 and subitems routes
 * 	id 			: Number - You can use uuid() as value to generate unique ID using uuid library, you can also assign constant unique ID for react dynamic objects.
 *  title 		: String - If menu contains childern use title to provide main menu name.
 *  badge 		: String - (Optional - Default - '') If you specify badge value it will be displayed beside the menu title or menu item.
 * 	badgecolor 	: String - (Optional - Default - 'primary' ) - Used to specify badge background color.
 *
 *  Applicable for subitems / children items routes
 *  name 		: String - If it's menu item in which you are specifiying link, use name ( don't use title for that )
 *  children	: Array - Use to specify submenu items
 *
 *  Used to segrigate menu groups
 *  grouptitle : Boolean - (Optional - Default - false ) If you want to group menu items you can use grouptitle = true,
 *  ( Use title : value to specify group title  e.g. COMPONENTS , DOCUMENTATION that we did here. )
 *
 */

// import MDI icons
import Icon from "@mdi/react";
import { mdiTrello } from "@mdi/js";
import Url from "components/Url";

const url1 = Url();
const url = url1["url"];

const commonCourseChildren = [
  {
    id: uuid(),
    link: "/courses/online_training",
    name: "Online Training",
  },
  {
    id: uuid(),
    link: "/courses/technical_project",
    name: "Technical Project",
  },
  {
    id: uuid(),
    link: "/courses/assessment_quiz",
    name: "Assessment Quiz",
  },
];

export const LmsDashboardMenu = [
  {
    id: uuid(),
    title: "Dashboard",
    icon: "home",
    children: [
      { id: uuid(), link: "/dashboard/overview", name: "Overview" },
    ],
  },
  {
    id: uuid(),
    title: "My Courses",
    icon: "book",
    children: [
          {
            id: uuid(),
            title: "Web Development",
            icon: "book",
            children: [
                {
                  id: uuid(),
                  title: "Online Training",
                  icon: "book",
                  children:[
                    { id: uuid(),
                      title: "Topic 1",
                      icon:"book",
                      children:[
                        {
                          id: uuid(),
                          link: "/courses/online_training",
                          name: "Forth Level 1 - 1",
                        },
                        {
                          id: uuid(),
                          link: "/courses/online_training",
                          name: "Forth Level 1 - 2",
                        },					
                        {
                          id: uuid(),
                          link: "/courses/online_training",
                          name: "Forth Level 1 - 3",
                        },											
                      ]
                    },
                    {   id: uuid(),
                      icon:"book",
                      title: "Forth Level 2",
                      children:[
                        {
                          id: uuid(),
                          link: "/courses/online_training",
                          name: "Fourth Level 2 - 1",
                        },
                        {
                          id: uuid(),
                          link: "/courses/online_training",
                          name: "Fourth Level 2 - 2",
                        },														
                      ]
                    },
                  ]
                },
                {
                id: uuid(),
                title: "Technical Project",
                icon: "book",
                children:[
                  {
                    id:uuid(),
                    name:"Project 1",
                    link:"project1"
                  },
                  {
                    id:uuid(),
                    name:"Project 2",
                    link:"project2"
                  }
                ]
                },
                {
                id: uuid(),
                title: "Assessment Quiz",
                icon: "book" ,
                children:[
                  {
                    id:uuid(),
                    name:"Online Assesment",
                    link:"quiz1"
                  }
                ]
                },
			        ],
          },

        ],
	}
    ];

export const DashboardMenu = [
  {
    id: uuid(),
    title: "Dashboard",
    icon: "home",
    children: [
      { id: uuid(), link: "/dashboard/overview", name: "Overview" },
      { id: uuid(), link: "/dashboard/analytics", name: "Analytics" },
    ],
  },
  {
    id: uuid(),
    title: "Internship Approval",
    icon: "book-open",
    link: "/cms/internship-approval",     
    // children: [
    //   //{ id: uuid(), link: '/cms/cms-dashboard', name: 'Overview' },
    //   { id: uuid(), link: "/cms/all-posts", name: "All Blogs" },
    //   { id: uuid(), link: "/cms/blog-details/0", name: "New Blog" },
    //   { id: uuid(), link: "/cms/category", name: "Category" },
    // ],
  },
  {
    id: uuid(),
    title: "Blogs",
    icon: "book-open",
    children: [
      //{ id: uuid(), link: '/cms/cms-dashboard', name: 'Overview' },
      { id: uuid(), link: "/cms/all-posts", name: "All Blogs" },
      { id: uuid(), link: "/cms/blog-details/0", name: "New Blog" },
      { id: uuid(), link: "/cms/category", name: "Category" },
    ],
  },
  {
    id: uuid(),
    title: "Courses",
    icon: "book",
    children: [
      { id: uuid(), link: "/courses/all-courses", name: "All Courses" },
      {
        id: uuid(),
        link: "/courses/courses-category",
        name: "Courses Category",
      },
      { id: uuid(), link: "/courses/category-single", name: "Category Single" },
      { id: uuid(), link: "/courses/course-pricing", name: "Course Pricing" },
    ],
  },
  {
    id: uuid(),
    title: "Bulk Email",
    icon: "mail",
    children: [
      { id: uuid(), link: "/bulkemail/all-templates", name: "All Templates" },
      {
        id: uuid(),
        link: "/bulkemail/add-new-template",
        name: "Add New Template",
      },
      { id: uuid(), link: "/bulkemail/email-images", name: "Email Images" },
    ],
  },
  {
    id: uuid(),
    title: "Reports",
    icon: "mail",
    children: [
      {
        id: uuid(),
        link: "/reports/order-payments",
        name: "Order-Payments Report",
      },
      {
        id: uuid(),
        link: "/reports/employer-internships",
        name: "Employer Internship Report",
      },
    ],
  },
	// // Layouts top, compact and vertical - - v1.3.0
	{
		id: uuid(),
		title: 'Registration',
		icon: 'layout',
		children: [
			{ id: uuid(), link: '/dashboard/registration/reg-students', name: 'Students' },
			{ id: uuid(),link: '/dashboard/registration/reg-employees',name: 'Employees'},
			{ id: uuid(), link: '/dashboard/registration/reg-colleges', name: 'Colleges' },
      { id: uuid(), link: '/dashboard/registration/reg-mentors', name: 'Mentors' },
    ]
	},
	{
		id: uuid(),
		title: 'Study Abroad',
		icon: 'layout',
		children: [
			{ id: uuid(), link: '/reports/study-abroad-registrations', name: 'Registrations' },
		]
	},	
	{
		id: uuid(),
		title: 'Online Traning',
		icon: 'layout',
		children: [
			{ id: uuid(), link: '/dashboard/online_training/addonlinetraining', name: 'Add Online Training' },
		]
	},
	{
		id: uuid(),
		title: 'VI Certificate',
		icon: 'layout',
		children: [
			{ id: uuid(), link: '/dashboard/vi_certificate/makecertificate', name: 'Create Certificate' },
		]
	},
	{
		id: uuid(),
		title: 'Discounts',
		icon: 'layout',
		children: [
			{ id: uuid(), link: '/dashboard/discounts/capcoupons', name: 'Virtual Internship Coupons' },
			{ id: uuid(), link: '/dashboard/discounts/capdiscount', name: 'Discount' },
		]
	},
  {
    id: uuid(),
    title: "LMS",
    icon: "layout",
    children: [
      {
        id: uuid(),
        link: "/dashboard/projectapprovals",
        name: "Project Approvals",
      },
      {
        id: uuid(),
        link: "/dashboard/course-cms",
        name: "All Courses",
      }      
    ],
  },
  {
    id: uuid(),
    title: "Sudo Login",
    icon: "layout",
    children: [
      {
        id: uuid(),
        link: "/dashboard/sudo/student",
        name: "Student",
      },
      {
        id: uuid(),
        link: "/dashboard/sudo/employer",
        name: "Employer",
      },
      {
        id: uuid(),
        link: "/dashboard/sudo/college",
        name: "College",
      }      
    ],
  },
	
  {
    id: uuid(),
    title: "SEO",
    icon: "layout",
    children: [
      {
        id: uuid(),
        link: "/dashboard/seo/page-seo-settings",
        name: "Landing Pages SEO",
      },
      {
        id: uuid(),
        link: "/CMS/banner-images",
        name: "Banner Image Upload",
      },      
    ],
  },  
  {
    id: uuid(),
    title: "Mentorship",
    icon: "layout",
    children: [
      {
        id: uuid(),
        link: "/dashboard/mentorship/booking-list",
        name: "Booking List",
      },
    ],
  },    
];

export default DashboardMenu;
