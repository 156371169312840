import React, { useEffect, useRef, useState, createRef } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  Svg,
  Path,
  Circle,
  Polyline,
} from "@react-pdf/renderer";
import { Html } from "react-pdf-html";
import {
  useA4Scale,
  TemplatePage,
  renderHTMLContent,
  getDisplayDate,
} from "../../utils";
import { Row, Col, Button } from "react-bootstrap";
import DummyProfilePic from "../../../../../assets/images/background/profilepic.png";
import { FiPhone } from "react-icons/fi";
import { isEmpty } from "../../../foundation";
// import { rotateSize } from "react-easy-crop/helpers";

Font.registerHyphenationCallback((word) => {
  // Return entire word as unique part
  return [word];
});

const styles = StyleSheet.create({
  page: { display: "flex", fontFamily: "Roboto" },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  left: {
    // backgroundColor: "green",
    width: "329px",
  },
  right: {
    width: "540px",
    fontSize: "10px",
  },
  profileImageWrapper: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
    paddingBottom: "35px",
    paddingTop: "40px",
    //transform: "rotate(30deg)",
  },
  profileImage: {
    width: "143px",
    height: "143px",
    borderRadius: "34.5%",
    border: "1px solid #3644a1",
    margin: " 8px 46px",
    marginBottom: "24px",
 

  },
});

const ContactInfo = ({
  data: { Phone, Address, Postal_Code, City, display_email },
}) => (
  <View
    style={{
      paddingTop: "20px",
    }}
  >
    <View
      style={{
        backgroundColor: "pink",
      }}
    >
      <Text
        style={{
          // marginLeft: "80%",
          width: "100%",
          height: "3px",
          color: "pink",
        }}
      >
        e
      </Text>
    </View>

    <View
      style={{
        position: "relative",
        paddingLeft: "30px",
        bottom: "12px",
        paddingTop: "30px",
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "8px",
          paddingLeft: "4px",
        }}
      >
        <View
          style={{
            backgroundColor: "#ffffff",
            padding: "3px",
            borderRadius: "50%",
            flex: "0 0 16px",
          }}
        >
          <Svg
            stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            height="10px"
            width="10px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <Path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></Path>
          </Svg>
        </View>
        <Text
          style={{
            color: "#ffffff",
            fontSize: "12px",
            paddingLeft: "8px",
          }}
        >
          {Phone}
        </Text>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "8px",
          paddingLeft: "4px",
        }}
      >
        <View
          style={{
            backgroundColor: "#ffffff",
            padding: "3px",
            borderRadius: "50%",
            flex: "0 0 16px",
          }}
        >
          <Svg
            stroke="#ffffff"
            fill="#000000"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            height="10px"
            width="10px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <Path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></Path>
            <Polyline points="22,6 12,13 2,6"></Polyline>
          </Svg>
        </View>
        <Text
          style={{
            color: "#ffffff",
            fontSize: "12px",
            paddingLeft: "8px",
          }}
        >
          {display_email}
        </Text>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "8px",
          paddingLeft: "4px",
        }}
      >
        <View
          style={{
            backgroundColor: "#ffffff",
            padding: "3px",
            borderRadius: "50%",
            flex: "0 0 16px",
          }}
        >
          <Svg
            stroke="#ffffff"
            fill="currentColor"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            height="10px"
            width="10px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <Path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></Path>
            <Circle fill="#ffffff" cx="12" cy="10" r="3"></Circle>
          </Svg>
        </View>
        <Text
          style={{
            color: "#ffffff",
            fontSize: "12px",
            paddingLeft: "8px",
          }}
        >
          {City} | {Address} | {Postal_Code}
        </Text>
      </View>
    </View>

    <View
      style={{
        backgroundColor: "pink",
      }}
    >
      <Text
        style={{
          // marginLeft: "80%",
          width: "100%",
          height: "3px",
          color: "pink",
        }}
      >
        e
      </Text>
    </View>
  </View>
);

const Skills = ({ data: { skills } }) => {
  if (!skills || !skills.length) {
    return <View />;
  }
  return (
    <View>
      <View
        style={{
          paddingLeft: "2px",
          marginTop: "25px",
          position: "absolute",
          fontSize:"10px",
        }}
      >
        <Text
          style={{
            color: "#000000",
            fontWeight: 700,
            fontSize: "10px",
            marginBottom: "10px",
            letterSpacing: "2.00px",
          }}
        >
          Skills
        </Text>
        {skills.map((s) => (
          <View
            style={{
              display: "flex",

              flexDirection: "row",
              justifyContent: "space-between",
            }}
            wrap={false}
          >
            <Text
              style={{
                color: "#000000",
                fontSize: "10px",
                paddingBottom: "8px",
              }}
            >
              {s.skill_name}
            </Text>

            <View
              style={{
                backgroundColor: "#000000",
                marginTop: "5px",
                marginLeft: "20px",
                width: "78px",
                height: "4px",
                marginBottom: "12px",
              }}
            >
              <View
                style={{
                    backgroundColor: "#006699",
                  

                  height: "4px",
                  width: `${s.level * 26}px`,
                  // color: "white",
                }}
              ></View>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

const Hobbies = ({ data: { hobbies } }) => {
  if (!hobbies || !hobbies.length) {
    return <View />;
  }
  return (
    <View
      style={{
        paddingLeft: "27px",
        paddingTop: "40px",
        paddingBottom: "45px",
      }}
      Hobbies
    >
      <Text
        style={{
          color: "#ffffff",
          fontWeight: 700,
          fontSize: "18px",
          marginBottom: "8px",
          paddingLeft: "30px",
          textDecoration: "underline",
          letterSpacing: "2.75px",
        }}
      >
        HOBBIES.
      </Text>

      {hobbies.map((s) => (
        <Text
          style={{
            color: "#ffffff",
            fontSize: "14px",
            // paddingBottom: "20px",
            paddingLeft: "30px",
            alignItems: "center",
            paddingRight: "58px",
            lineHeight: "2px",
            marginTop: "8px",
            position: "relative",
          }}
        >
          {s.hobbies}
        </Text>
      ))}
    </View>
  );
};

const Languages = ({ data: { languages } }) => {
  if (!languages || !languages.length) {
    return <View />;
  }
  return (
    <View>
      <View
        style={{
          paddingLeft: "185px",
          marginTop: "30px",
        }}
      >
        <Text
          style={{
            color: "#000000",
            fontWeight: 700,
            fontSize: "13px",
            marginBottom: "10px",
            letterSpacing: "2.75",
          }}
        >
          LANGUAGES
        </Text>
        {languages.map((s) => (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingRight: "50px",
            }}
          >
            <Text
              style={{
                color: "#000000",
                fontSize: "10px",
                paddingBottom: "10px",
              }}
            >
              {s.language_name}
            </Text>

            <View
              style={{
                backgroundColor: "#000000",
                width: "78px",
                height: "4px",
                marginTop: "5px",
                // marginLeft: "7px",
                marginBottom: "12px",
              }}
            >
              <View
                style={{
                    backgroundColor: "#006699",
                    
                  height: "4px",
                  width: `${s.level * 19.5}px`,
                  // color: "white",
                }}
              ></View>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

const References = ({ data, dataKey, heading, fieldMap = {} }) => { 
  const items = data[dataKey];
  const { title, subTitle, phoneNo, emailId } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View
      style={{
        paddingLeft: "40px ",
        marginTop: "25px",
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
        }}
      >
        <View>
          <Text
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              fontstretch: "normal",

              color: "#000000",
              letterSpacing: "2.75px",
            }}
          >
            {heading}
          </Text>
        </View>
      </View>
      {items.map((item) => (
        <View style={{ marginTop: "20px" }}>
          <Text style={{ fontSize: "12px", fontWeight: "500" }}>
            {item[title]}
          </Text>
          <Text
            style={{ fontSize: "12px", color: "#000000", marginTop: "3px" }}
          >
            {item[subTitle]}
          </Text>
          <Text
            style={{ fontSize: "12px", color: "#000000", marginTop: "3px" }}
          >
            {item[phoneNo]}
          </Text>
          <Text
            style={{ fontSize: "12px", color: "#000000", marginTop: "3px" }}
          >
            {item[emailId]}
          </Text>
        </View>
      ))}
    </View>
  );
};

const AdditionalInfo = ({
  data: { Country, Driving_License, Nationality, dob, Place_of_Birth },
}) => (
  <View style={{ paddingTop: "130px", marginLeft: "35px" }}>
    <Text
      style={{
        color: "#ffffff",
        //fontWeight: 700,
        fontSize: "15px",
        marginBottom: "2px",
        fontWeight: "bold",
        marginBottom: "10px",

        textDecoration: "underline",
        letterSpacing: "2.75px",
      }}
    >
      Address
    </Text>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        marginBottom: "3px",
        alignItems: "center",
      }}
    >
      <Text
        style={{
          color: "#ffffff",
          fontSize: "12px",
        }}
      >
        {Country}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "3px",
      }}
    >
      <Text
        style={{
          color: "#ffffff",
          fontSize: "12px",
        }}
      >
        {dob}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "3px",
      }}
    >
      <Text
        style={{
          color: "#ffffff",
          fontSize: "12px",
        }}
      >
        {Nationality}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "3px",
      }}
    >
      <Text
        style={{
          color: "#ffffff",
          fontSize: "12px",
        }}
      >
        {Place_of_Birth}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "3px",
      }}
    >
      <Text
        style={{
          color: "#ffffff",
          fontSize: "12px",
        }}
      >
        {Driving_License}
      </Text>
    </View>
  </View>
);

const RenderView = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const {
    title,
    subTitle,
    startDate,
    endDate,
    description,
    location = "city",
  } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View
      style={{ marginTop: "20px", paddingRight: "10px", paddingLeft: "40px" }}
    >
      <Text
        style={{
          fontSize: "12px",
          fontWeight: "bold",
          letterSpacing: "2.75px",
          color: "#000000",
          //textDecoration: "underline",
        }}
      >
        {heading}
      </Text>
      {items.map((item) => (
        <View style={{ marginTop: "20px" }} wrap={true}>
          <Text
            style={{
              fontSize: "10px",
              color: "#363434",
              marginTop: "1px",
              position: "absolute",
              marginLeft: "225px",
            }}
          >
            {getDisplayDate(item[startDate], item[endDate])}
          </Text>
          <Text style={{ fontSize: "11px", fontWeight: "500" }}>
            {item[title]}
          </Text>
          <Text
            style={{ fontSize: "11px", color: "#242732", marginTop: "3px" }}
          >
            {item[subTitle]}
          </Text>

          <Text
            style={{ fontSize: "11px", color: "#242732", marginTop: "3px" }}
          >
            {item[location]}
          </Text>

          {item[description] && (
            <View style={{ marginTop: "15px" }}>
              {renderHTMLContent(item[description], {
                "*": { color: "#3f3f3f" },
              })}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

const Internships = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const {
    title,
    subTitle,
    startDate,
    endDate,
    description,
    location = "city",
  } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View
      style={{ marginTop: "90px", paddingRight: "10px", paddingLeft: "40px" }}
    >
      <Text
        style={{
          fontSize: "12px",
          fontWeight: "bold",
          letterSpacing: "2.75px",
          color: "#000000",
          //textDecoration: "underline",
        }}
      >
        {heading}
      </Text>
      {items.map((item) => (
        <View style={{ marginTop: "20px" }} wrap={false}>
          <Text
            style={{
              fontSize: "10px",
              color: "#363434",
              marginTop: "1px",
              position: "absolute",
              marginLeft: "225px",
            }}
          >
            {getDisplayDate(item[startDate], item[endDate])}
          </Text>
          <Text style={{ fontSize: "11px", fontWeight: "500" }}>
            {item[title]}
          </Text>
          <Text
            style={{ fontSize: "11px", color: "#242732", marginTop: "3px" }}
          >
            {item[subTitle]}
          </Text>

          <Text
            style={{ fontSize: "11px", color: "#242732", marginTop: "3px" }}
          >
            {item[location]}
          </Text>

          {item[description] && (
            <View style={{ marginTop: "15px" }}>
              {renderHTMLContent(item[description], {
                "*": { color: "#3f3f3f" },
              })}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

const template21 = ({ data }) => {
  const {
    profileImage,
    First_Name,
    Last_Name,
    Wanted_Job_Title,
    professionalSummary: { Description = "" } = {},
  } = data;
  return (
    <TemplatePage data={data}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={{
              position: "absolute",
              height: "100vh",

              width: "164px",
              left: "0px",
              top: "0px",
            }}
            fixed
          >
            <View
              style={{
                height: "655px",
                backgroundColor: "#006699",
                width: "225px ",
                // transformOriginX: "20deg",
                position: "absolute",
              }}
            ></View>
          </View>
          <View style={styles.wrapper}>
            <View style={styles.left}>
              <View style={styles.profileImageWrapper}>  
                <Image
                  src={profileImage || DummyProfilePic}
                  style={styles.profileImage}
                />
              </View>
              <ContactInfo data={data} />
              <Hobbies data={data} />
              <View
                style={{
                  paddingTop: "1px",
                }}
              >
                <View
                  style={{
                    position: "absolute",
                    paddingTop: "30px",
                    top: "30%",
                    left: "10%",
                    backgroundColor: "#ffffff",
                    width: "220px",
                    height: "110px",
                    marginLeft: "30px",
                    transform: "rotate(150deg)",
                  }}
                ></View>
                <View
                  style={{
                    position: "absolute",
                    // top: "30%",
                    right: "30%",
                    // left: "30%",
                    backgroundColor: "#ffffff",
                    width: "210px",
                    height: "110px",
                    transform: "rotate(-150deg)",
                  }}
                ></View>
              </View>

              <References
                data={data}
                dataKey="References"
                heading="References"
                fieldMap={{
                  title: "ref_name",
                  subTitle: "company_name",
                  phoneNo: "phone_no",
                  emailId: "ref_email",
                }}
              />

              <AdditionalInfo data={data} />
            </View>

            <View style={styles.right}>
              <View>
                <View
                  style={{
                    // position: "absolute",
                    backgroundColor: "#006699",

                    // width: "10px",
                    // height: "100px",

                    paddingLeft: "15px",
                    marginLeft: "20px",
                    display: "flex",
                  }}
                >
                  <Text
                    style={{
                      width: "10px",
                      height: "100px",
                      color: "pink",
                      textAlign: "right",
                      display: "flex",
                      flexDirection: "row-reverse",
                    }}
                  >
                    e
                  </Text>
                </View>
                <View
                  style={{
                    paddingTop: "22px",
                    position: "absolute",
                    backgroundColor: "#ffffff",
                    height: "100px",
                    width: "90%",
                    paddingLeft: "40px",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: 500,
                      fontFamily: "Roboto",
                      color: "#000000",
                      fontSize: "25px",
                      marginBottom: "5px",
                      marginTop: "10px",
                    }}
                  >
                    {First_Name}
                  </Text>
                  <Text
                    style={{
                      fontWeight: 500,
                      fontFamily: "Roboto",
                      color: "#000000",
                      fontSize: "30px",
                      marginBottom: "5px",
                      marginTop: "20px",
                      // marginTop: "10px",
                    }}
                  >
                    {Last_Name}
                  </Text>
                  <Text
                    style={{
                      fontWeight: 500,
                      fontFamily: "Roboto",
                      color: "#000000",
                      fontSize: "12px",
                      marginTop: "30px",
                    }}
                  >
                    {Wanted_Job_Title}
                  </Text>
                </View>

                {!isEmpty(Description) && (
                  <View
                    style={{
                      marginTop: "25px",
                      // width: "200px",
                      position: "relative",
                      paddingLeft: "40px",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "17px",
                        fontWeight: "bold",
                        top: "2px",
                        marginRight: "8px",
                        marginBottom: "10px",
                        color: "#000000",
                        letterSpacing: "2.75px",
                      }}
                    >
                      Profile Summary
                    </Text>
                    <View
                      style={{
                        textTransform: "capitalize",
                        color: "#000000",
                        fontSize: "10px",
                        fontFamily: "Roboto",
                        fontStyle: "normal", 
                        lineHeight: "normal",
                        fontWeight: 500,
                        paddingRight: "10px",
                        marginTop: "10px",
                      }}
                    >
                      {renderHTMLContent(Description)}
                    </View>
                  </View>
                )}

                {/* <Text style={{ fontWeight: 500, fontFamily: "Roboto" }}>
                  {First_Name} {Last_Name}
                </Text> */}
                {/*<Text
                  style={{
                    fontWeight: 300,
                    fontSize: "14px",
                    color: "#3f3f3f",
                    paddingLeft:"80px",
                    // marginTop: "1px",
                  }}
                >
                  {Wanted_Job_Title}
                </Text>*/}
              </View>
              <RenderView
                data={data}
                dataKey="employmentHistory"
                heading="Employment history"
                fieldMap={{
                  subTitle: "Employer",
                  title: "Job_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "Description",
                }}
              />
              <RenderView
                data={data}
                dataKey="education"
                heading="Education"
                fieldMap={{
                  title: "institute_name",
                  subTitle: "degree",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "Description",
                }}
              />

              <Skills data={data} />

              <Languages data={data} />

              <RenderView
                data={data}
                dataKey="activities"
                heading="Extar_Curricular_Activity"
                fieldMap={{
                  title: "employer_name",
                  subTitle: "function_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "description",
                }}
              />

              <Internships
                data={data}
                dataKey="internships"
                heading="Internships"
                fieldMap={{
                  title: "employer_name",
                  subTitle: "job_title",
                  startDate: "start_date  ",
                  endDate: "end_date",
                  description: "description",
                }}
              />

              <RenderView
                data={data}
                dataKey="courses"
                heading="Courses"
                fieldMap={{
                  title: "course_name",
                  subTitle: "institute_name",
                  startDate: "start_date",
                  endDate: "end_date",
                }}
              />
            </View>
            {/* <View
              style={{
                position: "absolute",
                height: "100vh",
                width: "0px",
                left: "0px",
                top: "-10px",
              }}
              fixed
              render={({ pageNumber }) => (
                <View
                  style={{
                    height: "100%",
                    backgroundColor:
                      pageNumber === 1 ? "transparent" : "#ffffff",
                  }}
                ></View>
              )}
            /> */}
          </View>
        </Page>
      </Document>
    </TemplatePage>
  );
};

template21.displayName = "Template21";
export default template21;
