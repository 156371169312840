// import node module libraries
import React, { Fragment, useState, useEffect } from "react";
import { Col, Row, Container, Image, Form, Button } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";

// import sub components
import BlogCard from "./BlogCard";
import Url from "../Url";
import {
	FacebookShareButton,
	WhatsappShareButton,
	TwitterShareButton,
	LinkedinShareButton,
} from "react-share";

import { Helmet } from "react-helmet";
// import data files
//import BlogArticlesList from 'data/blog/blogArticlesData';

const BlogArticleSingle = (props) => {
	const url1 = Url();
	const url = url1["url"];
	const [blog, setBlog] = useState();
	const [categoryList, setCategoryList] = useState([]);
	const s3 = url1["s3"];
	const s3url = s3 + "blogImages/";
	const generatedURL = props.match.params.generatedURL;

	useEffect(() => {
		const api = url + "getContentDetailsByURL/" + generatedURL;

		fetch(api, {
			method: "GET",
		})
			.then((apidata) => {
				return apidata.json();
			})
			.then((actualdata) => {
				//console.log(actualdata)
				setBlog(actualdata.contentData[0]);
				setCategoryList(actualdata);
			});
	}, []);

	const authorAndSharing = (data) => {

		return (
			<div className="mb-5">
				<div className="d-flex justify-content-between align-items-center ">
					<div className="d-flex align-items-center">
						{data.author_image ? (
							<Image
								src={s3 + data.author_image}
								alt=""
								className="rounded-circle avatar-md"
							/>
						) : (
							<Image
								src={s3 + "Account.svg"}
								alt=""
								className="rounded-circle avatar-md"
							/>
						)}
						<div className="ms-2 lh-1">
							<h5 className="mb-1 ">{data.author_name}</h5>
							{/* <span className="text-primary">{data.aboutauthor}</span> */}
						</div>
					</div>

					<div>
						<Link to="/blogs" className="ms-2 text-muted">
							Back
						</Link>
					</div>
				</div>
				<div className="text-end p-0">
					<span className="mx-2">
						<WhatsappShareButton
							title={generatedURL}
							url={encodeURI(
								window.location.href.split(".com/")[0] +
									".com/" +
									"blog/" +
									generatedURL
							)}
						>
							<i class="fab fa-whatsapp text-success fa-2x"></i>
						</WhatsappShareButton>
					</span>
					<span className="mx-2">
						<FacebookShareButton
							url={encodeURI(
								window.location.href.split(".com/")[0] +
									".com/" +
									"blog/" +
									generatedURL
							)}
							quote={generatedURL}
							hashtag="#internshipgate"
						>
							<i className="fab fa-facebook text-primary fa-2x"></i>
						</FacebookShareButton>
					</span>
					{/* <Link to="#" className="ms-2 text-muted ">
						<i className="fab fa-linkedin text-primary"></i>
					</Link> */}
					<span className="mx-2">
						<TwitterShareButton
							title={generatedURL}
							quote={"Our Blogs"}
							url={encodeURI(
								window.location.href.split(".com/")[0] +
									".com/" +
									"blog/" +
									generatedURL
							)}
						>
							<i
								style={{ color: "skyblue" }}
								className="fab fa-twitter  fa-2x"
							></i>
						</TwitterShareButton>
					</span>

					<span>
						<LinkedinShareButton
							title={generatedURL}
							quote={"Our Blogs"}
							url={encodeURI(
								window.location.href.split(".com/")[0] +
									".com/" +
									"blog/" +
									generatedURL
							)}
						>
							<i className="fab fa-linkedin text-primary fa-2x"></i>
						</LinkedinShareButton>
					</span>
				</div>
			</div>
		);
	};
	return (
		<Fragment>
			{blog ? (
				<div className="py-4 py-lg-8 pb-14 bg-white ">
					<Container>
						<Fragment key={blog.id}>
							<Helmet>
								<title>{(blog.seo_title ? blog.seo_title : "Internshipgate Blogs - " + blog.content_header )}</title>
								<meta name="keywords" content={(blog.meta_keywords ? blog.meta_keywords : blog.content_header)} />
								<meta name="description" content={(blog.meta_description ? blog.meta_description : blog.content_header)} />
							</Helmet>  					
							<Row className="justify-content-center">
								<Col xl={8} lg={8} md={12} sm={12} className="mb-2">
									<div className="text-center mb-4">
										<Link
											to="#"
											className="fs-5 fw-semi-bold d-block mb-4 text-primary"
										>
											{blog.category_name}
										</Link>
										<h1 className="display-3 fw-bold mb-4">
											{blog.content_header}
										</h1>
										{/* 
									<span className="mb-3 d-inline-block">
										{item.readinglength} min read
									</span>
									*/}
									</div>
									{/* Author */}
									{authorAndSharing(blog)}
								</Col>
							</Row>
							<Row className="justify-content-center">
								{/* Image */}
								<Col
									xl={10}
									lg={10}
									md={12}
									sm={12}
									className="mb-6 offset-xl-1 offset-lg-1"
								>
									<Image
										src={s3url + blog.image_1000_540_scroller}
										alt={blog.image_1000_540_scroller_alt}
										className="img-fluid rounded-3"
									/>
								</Col>
							</Row>
							<Row className="justify-content-center">
								<Col xl={8} lg={8} md={12} sm={12} className="mb-2">
									{/* Blog Content */}
									<div
										dangerouslySetInnerHTML={{
											__html: blog.element_description,
										}}
									></div>
									<hr className="mt-8 mb-5" />
									{/* Author */}
									{authorAndSharing(blog)}
									{/* Subscribe to Newsletter */}
									{/* 
								<div className="py-12">
									<div className="text-center mb-6">
										<h2 className="display-4 fw-bold">
											Sign up for our Newsletter
										</h2>
										<p className="mb-0 lead">
											Join our newsletter and get resources, curated content,
											and design inspiration delivered straight to your inbox.
										</p>
									</div>
									
									<Form className="row px-md-20">
										<Form.Group
											className="mb-3 col ps-0 ms-2 ms-md-0"
											controlId="formBasicEmail"
										>
											<Form.Control
												type="email"
												placeholder="Email Address"
											/>
										</Form.Group>
										<Form.Group
											className="mb-3 col-auto ps-0"
											controlId="formSubmitButton"
										>
											<Button variant="primary" type="submit">
												{' '}
												Submit
											</Button>
										</Form.Group>
									</Form>
								</div>
								*/}
								</Col>
							</Row>
						</Fragment>
					</Container>

					{/* Related Post */}
					{/* 
				<Container>
					<Row>
						<Col xl={12} lg={12} md={12} sm={12}>
							<div className="my-5">
								<h2>Related Post</h2>
							</div>
						</Col>
						{BlogArticlesList.filter(function (dataSource) {
							return (
								dataSource.id === 6 ||
								dataSource.id === 7 ||
								dataSource.id === 15
							);
						}).map((item, index) => (
							<Col xl={4} lg={4} md={6} sm={12} key={index} className="d-flex">
								<BlogCard item={item} />
							</Col>
						))}
					</Row>
				</Container>
				*/}
				</div>
			) : (
				<Container className="text-center">
					<h1>Blog not found</h1>
					<Link to="/blogs">
						<Button>Go Back</Button>
					</Link>
				</Container>
			)}
		</Fragment>
	);
};

export default withRouter(BlogArticleSingle);
