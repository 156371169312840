// import node module libraries
import { Col, Row, Container, Image } from 'react-bootstrap';

// import sub components
import JobSearchBox from 'components/Homepage/data/jobs/JobSearchBox';

// import media files
import JobHeroSection from 'assets/images/job/png/job-hero-section.png';
import JobHeroBlock1 from 'assets/images/job/job-hero-block-1.svg';
import JobHeroBlock2 from 'assets/images/job/job-hero-block-2.png';
import JobHeroBlock3 from 'assets/images/job/job-hero-block-3.svg';

import {Link} from 'react-router-dom';

const FindYourDreamJob = () => {
	return (
		<section style={{backgroundImage: 'url("https://internshipgates3.s3.ap-south-1.amazonaws.com/blogImages/background1920.6e1af133.png")'}} className="bg-light py-lg-6 py-6 bg-cover">
			{/* container */}
			<Container>
				<Row className="align-items-center">
					<Col lg={6} sm={12}>
						<div>
							<div className=" text-center text-md-start mx-auto ">
								{/* heading */}
								<h1 className=" display-2 fw-bold text-white  mb-3">
									Find dream internship that you love to do.
								</h1>
								{/* lead */}
								<p className="lead text-white" style={{ fontSize: "1.2rem" }}>
                                   Become certified with our Virtual Internship Program, avail an 80% discount, and land your first job!
                                   <span style={{ fontSize: "0.75rem" }}>
                                     <Link to="/virtual-internship" className="btn btn-primary btn-sm ms-2">Click here</Link>
                                   </span>
                                </p>

							</div>
							<div className="mt-8">
								{/* job search form */}
								<JobSearchBox />
								{/* text */}
								<span className="text-white fs-4">
									Total listing 6585 internships from 1017 companies
								</span>
							</div>
							<div className="col-4 pt-2">
								<a
								href="https://play.google.com/store/apps/details?id=com.internshipgate.igapp&pcampaignid=web_share"
								className="btn btn-sm"
								style={{ padding: 0, margin: 0 }}
								>
								<Image
									className="img-fluid rounded "
									src="https://internshipgates3.s3.ap-south-1.amazonaws.com/logos/google-play.png"
								/>
								</a>
							</div>
						</div>
					</Col>
					<Col lg={{ span: 5, offset: 1 }} sm={12} className="text-center">
						<div className="position-relative ">
							<Image src={JobHeroSection} className="img-fluid " />
							<div className="position-absolute top-0 mt-6 ms-n5 ms-md-n6 ms-lg-n12 start-0">
								<Image src={JobHeroBlock1} className="img-fluid " />
							</div>
							<div className="position-absolute bottom-0 mb-10 me-n2 me-md-n4 me-lg-n12 end-0 ">
								<Image src={JobHeroBlock2} className="img-fluid " />
							</div>
							<div className="position-absolute bottom-0 mb-n5 ms-n1 ms-md-n4 ms-lg-n7 start-0">
								<Image src={JobHeroBlock3} className="img-fluid " />
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default FindYourDreamJob;
