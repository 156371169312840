// import node module libraries
import { Card, Image, Button, Col, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import likeclicked from "assets/images/job/likeclicked.svg";
import like from "assets/images/job/liked.svg";
// import tippy tooltip
import { BsEye } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import "tippy.js/animations/scale.css";

import Url from "components/Url";
import { Fragment } from "react";
const JobListingGridviewCard = ({
  item,
  fav,
  setFav,
  Favorite,
  Apply,
  getTimePassed,
}) => {
  const url1 = Url();
  const s3 = url1["s3"];
  const url = url1["url"];
  const [s3url, setS3url] = useState(s3 + "imgupload/");

  return (
    <Card className="card-bordered card-hover h-100">
      <Card.Body>
        <div className="mb-3 mb-md-0 d-flex">
          <Col xs={7} md={8}>
            <Image
              src={s3url + item.org_logo}
              alt=""
              className="icon-shape border rounded-circle"
              style={{
                height: "56px",
                width: "56px",
              }}
            />
          </Col>
          <Col xs={5} md={4} className="d-flex justify-content-end">
            {item.activity_rank > 0 && (
              <Fragment>
                <span
                  style={{ color: "var(--geeks-primary)" }}
                  className="ml-auto"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-lightning-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"></path>
                  </svg>
                  <span>Actively Hiring</span>
                </span>
              </Fragment>
            )}
          </Col>
        </div>
        <div className="w-100 mt-3">
          <div className="d-flex justify-content-between mb-4">
            <div>
              <h3 className="mb-1 fs-4">
                <Link
                  to={`/internship-details/${item.id}`}
                  className="text-inherit me-1"
                >
                  {item.internship_details}
                </Link>
                {/* {item.featured && <span className="badge bg-danger-soft">Featured Job</span>} */}
              </h3>
              <div>
                <span>at {item.org_name} </span>
                {/* <span className="text-dark ms-2 fw-medium">
									{item.rating}{" "}
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="10"
										height="10"
										fill="currentColor"
										className="bi bi-star-fill text-warning align-baseline"
										viewBox="0 0 16 16"
									>
										<path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
									</svg>
								</span> */}
                {/* <span className="ms-0">({item.totalReviews} Reviews)</span> */}
              </div>
            </div>
            <div>
              {/* bookmark */}
              {/* <Tippy content="Add to Bookmarks" animation={"scale"}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="16"
									fill="currentColor"
									className="bi bi-bookmark text-muted bookmark"
									viewBox="0 0 16 16"
								>
									<path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
								</svg>
							</Tippy> */}

              {fav.has(item.id) ? (
                <img
                  src={likeclicked}
                  alt=" "
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    Favorite(item.id);
                  }}
                />
              ) : (
                <img
                  src={like}
                  alt=" "
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    Favorite(item.id);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="mb-2">
            <div className="mb-2 mb-md-0">
              <div className="mt-1">
                <i className="fe fe-briefcase text-muted"></i>

                <span className="ms-1">
                  Duration:{" "}
                  {item.duration1 +
                    (item.duration2 == "M" ? " Months" : " Weeks")}
                </span>
              </div>
              <div className="mt-1">
                <span className="text-muted" style={{ padding: "3px" }}>
                  &#8377;
                </span>
                <span className="ms-1">
                  Stipend:{" "}
                  {item.compensation1
                    ? item.compensation1 + " per month"
                    : "Not disclosed"}
                </span>
              </div>
              <div className="mt-1">
                <div className=" text-truncate">
                  <i className="fe fe-map-pin text-muted"></i> Location:
                  {item.location}
                </div>
              </div>

              <div className="mt-1">
                <div className=" text-truncate">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-tools"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
                  </svg>{" "}
                  Skills: {item.skill}
                </div>
              </div>
            </div>
          </div>
          <div>
            <i className="fe fe-clock text-muted"></i>
            <span className="ps-1">Starts In: Immediately</span>
          </div>
          <div>
            <i className="fe fe-clock text-muted"></i>

            <span className="ps-1">
              Posted at: {getTimePassed(item.posting_date)}
            </span>
          </div>

          {item.impressions > 0 && (
            <Fragment>
              <Row className="mt-1">
                <Col xs={6} className="mt-2 d-flex justify-content-start">
                  <span
                    style={{ color: "var(--geeks-primary)" }}
                    className="ml-auto"
                  >
                    <BsEye size={13} />
                    <span className="small ms-1">
                      {item.impressions} impressions
                    </span>
                  </span>
                </Col>
              </Row>
            </Fragment>
          )}

          <Row className="mt-1">
            <Col xs={12} className="mt-2 d-flex justify-content-end">
              <Link
                to={`/internship-details/${item.id}`}
                className="text-inherit me-1"
              >
                <button
                  type="button"
                  className="btn  btn-sm  btn-outline-warning"
                  style={{ marginRight: "10px" }}
                >
                  More details
                </button>
              </Link>
              <button
                type="button"
                className="btn btn-primary  btn-sm"
                onClick={() => Apply(item.id)}
              >
                Apply Now
              </button>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default JobListingGridviewCard;
