import React from "react";
import Button from "react-bootstrap/Button";
import { Col, Row } from "react-bootstrap";
import { BiBriefcase } from "react-icons/bi";
import { RiPlantLine } from "react-icons/ri";
import { GiChessRook } from "react-icons/gi";
import { CgFileDocument } from "react-icons/cg";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { FaLanguage } from "react-icons/fa";
import { MdSchool } from "react-icons/md";
import { HiSpeakerphone } from "react-icons/hi";
// import {AiFillControl} from "react-icons/Ai";


const AddSection = ({
  resumeForm,
  Courses,
  Activities,
  Internships,
  Hbby,
  References,
  Languages,
  CustomSection,
  //showCustomSection,
  //setShowCustomSection,
  // showActivities,
  // setShowActivities,
  // showHobbies,
  // setShowHobbies,
  // showReferences,
  // setShowReferences,
  // showCourses,
  // setShowCourses,
  // showInternships,
  // setShowInternships,
  // showLanuages,
  // setShowLanuages,
}) => {
  return (
    <div className="resume-builder__section">
      <h2>Add Section</h2>
      <div className="add__Sections d-flex flex-lg-row flex-column">
        <div className="section1 m-0 me-5 flex-1 ">

          {/* Hobbies start*/}

          <div className="hobbies"> 
            <Button
              variant="link"
              type="button"
              className="text-info text-decoration-none"
              // style={{ fontSize: "19px" }}
              onClick={() => Hbby.append({})}
              disabled={Hbby?.fields.length}
            >
              <GiChessRook style={{ fontSize: "50px", marginRight: "10px" }} />
              <span
                className="text-black fs-3"
                // style={{ color: "black" }}
              >
                Hobbies
              </span>
            </Button>
          </div>

          {/* Hobbies end */}

         {/*COURSE START*/}
         
          <div className="courses">
            <Button
              variant="link"
              type="button"
              className="text-info text-decoration-none"
              // style={{ fontSize: "19px" }}
              // onClick={() => {
              //   if(Courses?.fields.length)
              //   {resumeForm.reset({...resumeForm.getValues() ,
              //     courses : []})
              //     console.log(Courses);
              //   }
              //   else{
              //   }
              //   Courses.append({});
              // }}
              onClick={() => Courses.append({})}
              disabled={Courses?.fields.length}
            >
              <CgFileDocument
                style={{ fontSize: "50px", marginRight: "10px" }}
              />
              <span className="text-black fs-3">Courses</span>
            </Button>
          </div>

          {/*COURSE END*/}

          {/*INTERNSHIP START*/}

          <div className="internships">
            <Button
              variant="link"
              type="button"
              // style={{ fontSize: "19px" }}
              className="text-info text-decoration-none"
              onClick={() => Internships.append({})}
              disabled={Internships?.fields.length}
            >
              <BiBriefcase style={{ fontSize: "50px", marginRight: "10px" }} />
              <span className="text-black fs-3">Internships</span>
            </Button>
          </div>
        </div>
        {/*INTERNSHIP END*/}

        

        <div className="section2 flex-1" style={{ margin: "initial" }}>
          {/* <div className="languages">
            <Button
              variant="link"
              type="button"
              className="text-info text-decoration-none"
              // style={{ fontSize: "19px" }}
              onClick={() => Languages.append({})}
              disabled={Languages?.fields.length}
            >
              <FaLanguage style={{ fontSize: "50px", marginRight: "10px" }} />
              <span className="text-black fs-3">Languages</span>
            </Button>
          </div> */}
          
            {/*REFERNCES START*/}

          <div className="references">
            <Button
              variant="link"
              type="button"
              className="text-info text-decoration-none"
              // style={{ fontSize: "19px" }}
              onClick={() => References.append({})}
              disabled={References?.fields.length}
            >
              <HiOutlineSpeakerphone
                style={{ fontSize: "50px", marginRight: "10px" }}
              />
              <span className="text-black fs-3">References</span>
            </Button>
          </div>

          {/*REFERENCE END */}

          {/* <div>
            <HiSpeakerphone/>
          </div> */}
            
            {/*EXTRA- CURRCULARACTIVITY START*/}
  
  <div className="extra_curricularActivities">
						<Button
							variant="link"
							type="button"
							className="text-info text-decoration-none"
							// style={{ fontSize: "19px", textAlign: "center" }}
							onClick={() => Activities.append({})}
							disabled={Activities?.fields.length}
						>
							<RiPlantLine style={{ fontSize: "50px", marginRight: "10px" }} />
							<span 
							// style={{ color: "black" }}
							className="text-black fs-3 twitter-hover-bg"
							>Extra-curricular Activity</span>
						</Button>
					</div> 

          {/*EXTRA- CURRCULARACTIVITY END*/}


          {/*CUSTOMSECTION START*/}
          
          <div className="customSection">
            <Button
              variant="link"
              type="button"
              className="text-info text-decoration-none"
              onClick={() => CustomSection.append({})}
              
              disabled={CustomSection?.fields.length}
            >
            
              <MdSchool style={{ fontSize: "50px", marginRight: "10px", color: "#0ea5e9" }} />
            

              <span className="text-black fs-3">Custom Section</span>
            </Button>
          </div>

          {/*EXTRA- CURRCULARACTIVITY END */}
        </div>
      </div>
    </div>
  );
};

export default AddSection;
