// import node module libraries
import React, { useEffect, useState, useMemo, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Table,
  Dropdown,
  Badge,
  Button,
  Modal,
} from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";
import StarRating from "components/common/StarRating";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import GlobalFilter from "components/common/advance-table/GlobalFilter";
import Pagination from "components/common/advance-table/Pagination";
// import custom components
import Url from "components/Url";

// import profile layout wrapper
import ProfileLayout from "./ProfileLayout";

import { MentorFeedbackModal } from "components/student/MentorshipList";

const MentorEarnings = () => {
  const mentorId = localStorage.getItem("mentorId");
  const token =localStorage.getItem("admtoken") ? localStorage.getItem("admtoken") : localStorage.getItem("mentortoken");

  const [load, setLoad] = useState(false);
  const [mentorEarnings, setMentorEarnings] = useState([]);
  const [reportTotal,setReportTotal] = useState();
  const url1 = Url();
  const url = url1["url"];
  const s3 = url1["s3"];

  useEffect(() => {
    const fetchRecord = async () => {
      var api = url + "mentorEarningsReport";
      try {
        const response = await Axios.get(api, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-USER-ID": mentorId,
          },
        });
        //setPageHeading("Course Content - " + response.data.onlineTrainingList.[0].subject_name)
        setMentorEarnings(response.data.mentorEarningReport);
        setReportTotal(response.data.reportTotal);
      } catch (error) {
        //var status = error.response.status;
        toast.warning(
          "Error loading Dashboard, please logout and login again",
          { autoClose: 1000 }
        );
      } finally {

      }
    }; //end of fetchRecord

    fetchRecord();
  }, []);


  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const ActionMenu = () => {
    return (
      <div>
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle}>
            <i className="fe fe-more-vertical text-muted"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            <Dropdown.Header>SETTINGS</Dropdown.Header>
            <Dropdown.Item eventKey="1">
              <i className="fe fe-edit dropdown-item-icon"></i> Edit
            </Dropdown.Item>
            <Dropdown.Item eventKey="2">
              <i className="fe fe-trash dropdown-item-icon"></i> Remove
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };
  // Column heading and cell format setup
  const columns = useMemo(
    () => [
      { accessor: "order_id", Header: "Order Id"},
      {
        accessor: "mentorship_status",
        Header: "Mentorship Status",
        Cell: ({ value, row }) => {
          return (
            <Badge
              bg={
                value === "Scheduled"
                  ? "warning"
                  : value === "Completed"
                  ? "success"
                  : value === "Cancelled"
                  ? "info"
                  : value === "Overdue"
                  ? "danger"
                  : null
              }
              className="text-white"
            >
              {value}{" "}
            </Badge>
          );
        },
      },      
      { accessor: "service_name", Header: "Service Name" },
      { accessor: "service_duration_mins", Header: "Service Duration (Mins)" },
      { accessor: "total_amount", Header: "Total Amount" },
      { accessor: "discount", Header: "Order Discount" },
      { accessor: "gst_amount", Header: "GST" },
      { accessor: "total_amount_payable", Header: "Total Payable" },
      { accessor: "created_at", Header: "Booking Date" },
      { accessor: "price", Header: "Service Price" },
      { accessor: "mentor_discount_percent", Header: "Mentor Discount" },
      { accessor: "income_percentage_mentor", Header: "Mentor % Share" },
      { accessor: "income_percentage_company", Header: "IGATE % Share" },
      { accessor: "mentor_payment_part", Header: "Mentor Payment" },
      { accessor: "igate_payment_part", Header: "IGATE Payment" },

      /*
                {
                    accessor: 'received', Header: 'Received',
                    Cell: ({ value, row }) => {
                        return (
                            <Link to="/dashboard/applications/all"
                                className={`btn btn-${value === 'View Applications' ? 'light' : 'primary'} btn-sm`}>{value}</Link>
                        );
                    }
                },
                {
                    accessor: 'action',
                    Header: '',
                    Cell: ({ row }) => {
                        return <ActionMenu status={row.original.status} />;
                    }
                }
                */
    ],
    []
  );


  const data = useMemo(() => mentorEarnings, [mentorEarnings]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { pageIndex, globalFilter } = state;

  return (
    <ProfileLayout>
      <Card className="mt-4">
        <Card.Header>
          <h3 className="mb-0 h4">My Mentor Earnings</h3>
        </Card.Header>
        <Card.Body className="p-0 ">
          <Row className="mt-6">
            <Col lg={12}>
            {mentorEarnings.length > 0 && (
              <Table
                hover
                responsive
                {...getTableProps()}
                className="text-nowrap table-centered"
              >
                <thead className="table-light">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
                )}
              {/* Pagination @ Footer */}
              {mentorEarnings.length > 0 && (
              <Pagination
                previousPage={previousPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
              />
              )}
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="mt-3">
        {reportTotal?.total_mentor_payment && 
          <>
          <h4>Total Till Date</h4>
          <p><strong>Mentor part:</strong> INR {parseInt(reportTotal?.total_mentor_payment).toLocaleString('en-IN')}</p>
          <p><strong>IGate part:</strong> INR {parseInt(reportTotal?.total_igate_payment).toLocaleString('en-IN')}</p>

          </>
          }          
        </Card.Footer>
      </Card>
      {/* end of Page Content section*/}
    </ProfileLayout>
  );
};
export default MentorEarnings;
