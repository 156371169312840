export const DashboardMenu = [
	{
		id: 1,
		title: 'My Mentorships',
		link: '/mentor/dashboard',
		icon: 'home',
		componentName: 'dashboard'
	},
	{
		id: 2,
		title: 'My Mentorship Services',
		link: '/mentor/services/',
		icon: 'book',
		componentName: 'MentorServices'
	},
	{
		id: 4,
		title: 'Earnings',
		link: '/mentor/instructor-earnings/',
		icon: 'pie-chart'
	},
];

export const AccountSettingsMenu = [
	{
		id: 1,
		title: 'Edit Profile',
		link: '/mentor/profile/',
		icon: 'settings',
		componentName: 'MentorBasicDetails'
	},
	{
		id: 2,
		title: 'Mentor Education',
		link: '/mentor/education/',
		icon: 'user',
		componentName: 'MentorEducations'
	},
	{
		id: 3,
		title: 'Mentor Work Experience',
		link: '/mentor/work-experience',
		icon: 'refresh-cw',
		componentName: 'MentorWorkExperiences'
	},
	{
		id: 4,
		title: 'Mentor Topics',
		link: '/mentor/topics/',
		icon: 'bell',
		componentName: 'MentorTopics'
	},

];

export const InstructorDashboardMenu = [DashboardMenu, AccountSettingsMenu];

export default InstructorDashboardMenu;
