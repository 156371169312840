import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
    createRef,
    Fragment,
    useRef,
  } from "react";
  import {
    Col,
    Container,
    Form,
    ProgressBar,
    Row,
    Accordion,
    Card,
    Modal,
    ListGroup,
    Button,
    Collapse
  } from "react-bootstrap";
  import OverlayTrigger from "react-bootstrap/OverlayTrigger";
  import Tooltip from "react-bootstrap/Tooltip";
  import Spinner from "react-bootstrap/Spinner";
  import { FiChevronDown, FiChevronUp, FiUser, FiX,FiGrid } from "react-icons/fi";
  import { HiDocumentDuplicate, HiCheckCircle } from "react-icons/hi";
  import { BiArrowBack } from "react-icons/bi";
  import { BsCircleFill, BsCheckCircleFill, BsRobot, BsFillEyeFill,BsXLg,BsFillQuestionCircleFill } from "react-icons/bs";
  import "./IGBot.css";
  import {FcLeft} from "react-icons/fc";
  import { BsChatRightDots } from "react-icons/bs";
  import Api, { ENDPOINTS, getS3Url } from "components/ResumeBuilder/api.js";
  const IGBot = (props) => {
    {
      /*************************AI Suggestions modal - start **************/
    }
    const {showSuggestions,
    setShowSuggestions,
    studentId, 
    careerDetailId} = props;
    
    const [suggestions, setSuggestions] = useState("");
    const [suggestionsSearchText, setSuggestionsSearchText] = useState("");
    const [suggestionsWait, setSuggestionsWait] = useState(false);
    /**** CHANGE / AI Suggestions - Start */
    const getSuggestions = async () => {
        scroll();
        if (studentId) {
          if (careerDetailId) {
            //console.log(resumeData.professionalSummary?.Description);
            //if(resumeData.professionalSummary?.Description){suggestionsSearchText
            if (suggestionsSearchText) {
              setSuggestionsWait(true);
              try {
                let message = strip_html_tags(suggestionsSearchText);
                const ApiResponse = await Api.get(
                  'projectChat' + "/" + message
                );
                
                console.log(ApiResponse.data);

                if (ApiResponse.data.suggestions) {
                  console.log(ApiResponse.data.suggestions);
                  var str = '';
                  str = ApiResponse.data.suggestions.replace(/\.\n/g, ".<br />").replace(/\.(?![\s<])/g, ".<br />");
                  setSuggestions(str);
                } else {
                  toast.warning(
                    "No Suggestions, please check your keywords (only use 3-4 keywords)"
                  );
                }
              } catch (e) {
                console.log(e);
              } finally {
                setSuggestionsWait(false);
              }
            } else {
              toast.warning("Add some keywords to the professional summary field");
            }
          } else {
            toast.warning("Cannot get suggestions");
          }
        }
      };
    
      function strip_html_tags(str)
      {
         if ((str===null) || (str===''))
             return false;
        else
         str = str.toString();
        return str.replace(/<[^>]*>/g, '');
      }

    const [open, setOpen] = useState(false);
    
    return (
        <Fragment>
          <Card className={`igbot-ai-suggestion-card ${showSuggestions ? 'visible' : 'invisible'}`}>
            <Card.Body className="overflow-auto ps-2 ps-sm-4">
              <Row className="border-bottom pb-3">
                <Form.Group as={Col} lg={12} className="d-sm-flex">
                  <Col xs={12} md={9} className="mt-2 mt-sm-0">
                    <Form.Control
                      type="text"
                      placeholder="Enter your project related query"
                      value={suggestionsSearchText}
                      onChange={(e) => setSuggestionsSearchText(e.target.value ? e.target.value : "")}
                    />
                  </Col>
                  <Col xs={12} md={2} className="ms-2 mt-2 mt-sm-0">
                    {suggestionsWait ? (
                      <Spinner animation="grow" className="ms-3" />
                    ) : (
                      <Button>
                        <BsRobot size={20} onClick={getSuggestions} style={{ cursor: "pointer" }} />
                      </Button>
                    )}
                    <span
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                      className="ms-2"
                    >
                      <BsFillQuestionCircleFill size={15} />
                    </span>
                  </Col>
                </Form.Group>
                <Col xs sm="12">
                  <Collapse in={open}>
                    <div id="example-collapse-text">
                      <p className="small pt-2">Ask Anything related with the project</p>
                    </div>
                  </Collapse>
                </Col>
              </Row>
              {console.log(suggestions)}
              {suggestions ? (
                  <Row>
                    <Col className="d-flex align-items-top border-bottom py-2" style={{ fontSize: ".8rem" }}>
                      <Col xs={10} sm="11">
                        <div dangerouslySetInnerHTML={{ __html: suggestions }} />
                      </Col>
                    </Col>
                  </Row>
              ) : (
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    Ask Anything related with the project, click on the IGBot and let IGBot help you in the project..!!!
                  </ListGroup.Item>
                </ListGroup>
              )}
              <span className="position-absolute top-0 end-0 m-2 m-md-4 strong" style={{ cursor: "pointer" }}>
                <BsXLg size={20} onClick={() => setShowSuggestions(false)} />
              </span>
            </Card.Body>
            {/* <Card.Footer>
              <span className="small"><em>Note:the chat is not optimized for project specific queries (POC limitation)</em></span>
            </Card.Footer> */}
          </Card>
        </Fragment>
      );
    };
    
    export default IGBot;
  