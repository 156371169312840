// import node module libraries
import React, { Fragment, useMemo, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination,
	useRowSelect
} from 'react-table';
import {
	Col,
	Row,
	Dropdown,
	Card,
	Breadcrumb,
	Form,
	Button,
	Table
} from 'react-bootstrap';
import { Trash, Send, Inbox, MoreVertical } from 'react-feather';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';
import Checkbox from 'components/elements/advance-table/Checkbox';
import DotBadge from 'components/elements/bootstrap/DotBadge';
import { ToastContainer, toast } from 'react-toastify';

import Url from 'components/Url';

const EmployerInternship = () => {

	const [duration, setDuration] = useState(2);

    const [employerInternship,setEmployerInternship] = useState([]);
    
    const url1 = Url();
    const url = url1["url"]; 
    const s3 = url1["s3"];

    useEffect(() => {
		
        const api = url + "employerInternshipsReport/" + duration;
    
        fetch(api, {
          method: "GET",
        })
          .then((apidata) => {
            return apidata.json();
          })
          .then((actualdata) => {
            console.log(actualdata);
            if (actualdata.employerInternshipDashboard.length > 0) {
              setEmployerInternship(actualdata.employerInternshipDashboard);
            }
          });
      }, [duration]);

	// The forwardRef is important!!
	// Dropdown needs access to the DOM node in order to position the Menu
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
		</Link>
	));

	const ActionMenu = () => {
		return (
			<Dropdown>
				<Dropdown.Toggle as={CustomToggle}>
					<MoreVertical size="15px" className="text-secondary" />
				</Dropdown.Toggle>
				<Dropdown.Menu align="end">
					<Dropdown.Header>ACTION</Dropdown.Header>
					<Dropdown.Item eventKey="1">
						{' '}
						<Send size="18px" className="dropdown-item-icon" /> Publish
					</Dropdown.Item>
					<Dropdown.Item eventKey="2">
						{' '}
						<Inbox size="18px" className="dropdown-item-icon" /> Moved Draft
					</Dropdown.Item>
					<Dropdown.Item eventKey="3">
						{' '}
						<Trash size="18px" className="dropdown-item-icon" /> Delete
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

  /*--------------------------- Generate CSV ---------------------------*/
  const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

    var CSV = "";

    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index + ",";
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + "\r\n";
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + "\r\n";
    }

    if (CSV === "") {
      alert("Invalid data");
      return;
    }

    //Generate a file name
    var fileName = "IG Employer List";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  /*--------------------------- Generate CSV ---------------------------*/

	const columns = useMemo(
		() => [
			{ accessor: 'id', Header: 'Employer Internship Id',style: { 'white-space': 'unset' },maxWidth: 70,
			minWidth: 50,
			width: 60},
			{ accessor: 'org_name', Header: 'Employer Name',style: { 'white-space': 'unset' } },
			{ accessor: 'industry_type', Header: 'Industry Type',style: { 'white-space': 'unset' } },
			{ accessor: 'internship_details', Header: 'Internship Details',style: { 'white-space': 'unset' } },
			{ accessor: 'total_applications', Header: 'Total Applications',style: { 'white-space': 'unset' } },
            { accessor: 'shortlisted', Header: 'Shortlisted',style: { 'white-space': 'unset' } },
            { accessor: 'hired', Header: 'Hired' ,style: { 'white-space': 'unset' }},
			{ accessor: 'rejected', Header: 'Rejected' ,style: { 'white-space': 'unset' } },		
			{ accessor: 'posting_date', Header: 'Posting Date',style: { 'white-space': 'unset' } }
		],
		[]
	);

    console.log(employerInternship);

	const data = useMemo(() => employerInternship, [employerInternship]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		pageCount,
		prepareRow,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 100,
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination,
		useRowSelect,
		(hooks) => {
			hooks.visibleColumns.push((columns) => [
				...columns
			]);
		}
	);

	const { pageIndex, globalFilter } = state;

	return (
		<Fragment>
			<ToastContainer />
			<Row className="d-flex align-items-baseline">
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-2 mb-2 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">Employer Internship Report</h1>
							<Breadcrumb>
								<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item href="#">Reports</Breadcrumb.Item>
								<Breadcrumb.Item active>Employer Internship Report</Breadcrumb.Item>
							</Breadcrumb>
						</div>
						<div>
						</div>
					</div>
				</Col>
				<Col lg={3} md={3} sm={6}>
					<Form.Control
						as="select"
						required
						className="mt-2"
						value={duration}
						onChange={(e) => {
							setDuration(e.target.value);
						}}
					>
						<option value="1">All</option>
						<option value="2">Last 1 Month</option>
						<option value="3">Last 3 Months</option>
						<option value="4">Last 6 Month</option>
					</Form.Control>					
				</Col>
				<Col lg={3} md={3} sm={6}>
					<Button variant="success" size="sm"
						onClick={() => JSONToCSVConvertor(employerInternship, "Employer_Internships_dashboard", true)}
					>Download</Button>
				</Col>
			</Row>

			<Row>
				<Col lg={12} md={12} sm={12}>
					<Card>
						<Card.Body className="p-0">
							{/**
                            <div className=" overflow-hidden">
								<Row>
									<Col
										lg={12}
										md={12}
										sm={12}
										className="mb-lg-0 mb-2 px-5 py-4"
									>
										<GlobalFilter
											filter={globalFilter}
											setFilter={setGlobalFilter}
											placeholder="Search Course"
										/>
									</Col>
								</Row>
							</div>
                            */}

							<div className="table-responsive" style={{overflow: "auto",height:"500px"}}>
								<Table {...getTableProps()} className="text-nowrap" style={{height:"400px"}}>
									<thead className="table-light">
										{headerGroups.map((headerGroup) => (
											<tr {...headerGroup.getHeaderGroupProps()}>
												{headerGroup.headers.map((column) => (
													<th {...column.getHeaderProps()}>
														{column.render('Header')}
													</th>
												))}
											</tr>
										))}
									</thead>
									<tbody {...getTableBodyProps()}>
										{page.map((row) => {
											prepareRow(row);
											return (
												<tr {...row.getRowProps()}>
													{row.cells.map((cell) => {
														return (
															<td {...cell.getCellProps()}>
																{cell.render('Cell')}
															</td>
														);
													})}
												</tr>
											);
										})}
									</tbody>
								</Table>
							</div>

							{/* Pagination @ Footer */}
							<Pagination
								previousPage={previousPage}
								pageCount={pageCount}
								pageIndex={pageIndex}
								gotoPage={gotoPage}
								nextPage={nextPage}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default EmployerInternship;
