// import node module libraries
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Col, Row, Card, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// import custom components
//import { FlatPickr } from 'components/elements/flat-pickr/FlatPickr';
import FlatPickr from 'react-flatpickr';
import ApexCharts from 'components/elements/charts/ApexCharts';
import StatRightIcon from './StatRightIcon';

// import sub components
import PopularInstructor from 'components/dashboard/overview/PopularInstructor';
import MyCourses from './MyCourses';
import Activity from 'components/dashboard/overview/Activity';
import Axios from 'axios';
import Url from '../Url';
// import data files
import {
	TrafficChartSeries,
	TrafficChartOptions,
	EarningsChartSeries,
	EarningsChartOptions
} from 'data/charts/ChartData';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
	<Link
		to=""
		ref={ref}
		onClick={(e) => {
			e.preventDefault();
			onClick(e);
		}}
	>
		{children}
	</Link>
));


const ChartActionMenu = () => {
	return (
		<div>
			<Dropdown>
				<Dropdown.Toggle as={CustomToggle}>
					<i className="fe fe-more-vertical text-muted"></i>
				</Dropdown.Toggle>
				<Dropdown.Menu align="end">
					<Dropdown.Header>SETTINGS</Dropdown.Header>
					<Dropdown.Item eventKey="1">
						<i className="fe fe-external-link dropdown-item-icon "></i> Export
					</Dropdown.Item>
					<Dropdown.Item eventKey="2">
						<i className="fe fe-mail dropdown-item-icon "></i> Email Report
					</Dropdown.Item>
					<Dropdown.Item eventKey="3">
						<i className="fe fe-download dropdown-item-icon "></i> Download
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
};

const LMSDashboard = () => {

    const url1 = Url();
    const url = url1["url"];	
	const [cmsStats,setCmsStats] = useState([
		{
		"totalCourses":0,
		"totalActiveBlogs":0,
		"totalDraftBlogs":0,
		"totalPendingApprovalBlogs":0}
	]);
	const [VICourses,setVICourses] = useState();
	
	const token = localStorage.getItem("stutoken");
	const studentId = localStorage.getItem("studentId");

	useEffect(() => {

		let apiURL =  url + "getLMSVICourseList/" + studentId;
		Axios.get(
			apiURL,
			{headers: {
					"Content-type": "Application/json",
					"Authorization": `Bearer ${token}`
					}   
				}
		  )
		  .then((response) => {
			  //var response = response.data.virtualInternshipList;
			  //console.log(response.data);
			  setCmsStats ([
				{
				"totalCourses":response.data.virtualInternshipStats[0].totalCourses,
				"totalActiveCourses":response.data.virtualInternshipStats[0].totalActiveCourses,
				"totalExpiredCourses":response.data.virtualInternshipStats[0].totalExpiredCourses,
				"totalProjectsPending":0
				}]	
			  );
			  setVICourses(response.data.virtualInternshipList);
			},
			(error) => {
			  var status = error.response.status
			}
		  );	
	},[]);

	return (
		<div>
			<Row>
				<Col xl={3} lg={6} md={12} sm={12} xs={6}>
					<StatRightIcon
						title="TOTAL COURSES"
						value={cmsStats[0].totalCourses}
						//summary="No. of Blogs"
						//summaryValue="+20.9$"
						//summaryIcon="up"
						showSummaryIcon
						iconName="shopping-bag"
						iconColorVariant="primary"
						classValue="mb-1 col-xs-6"
					/>
				</Col>

				<Col xl={3} lg={6} md={12} sm={12} xs={6}>
					<StatRightIcon
						title="ACTIVE COURSES"
						value={cmsStats[0].totalActiveCourses}
						//summary="Active Blogs"
						//summaryValue="120+"
						//summaryIcon="down"
						iconName="book-open"
						iconColorVariant="primary"
						classValue="mb-1 col-xs-6"
					/>
				</Col>

				<Col xl={3} lg={6} md={12} sm={12} xs={6}>
					<StatRightIcon
						title="EXPIRED COURSES"
						value={cmsStats[0].totalExpiredCourses}
						//summary="Draft Blogs"
						//summaryValue="+1200"
						//summaryIcon="up"
						showSummaryIcon
						iconName="users"
						iconColorVariant="primary"
						classValue="mb-1 col-xs-6"
					/>
				</Col>

				<Col xl={3} lg={6} md={12} sm={12} xs={6}>
					<StatRightIcon
						title="PROJECTS PENDING APPROVAL"
						value={cmsStats[0].totalProjectsPending}
						//summary="Blogs Pending for Approval"
						//summaryValue="+200"
						//summaryIcon="up"
						showSummaryIcon
						iconName="user-check"
						iconColorVariant="primary"
						classValue="mb-1 col-xs-6"
					/>
				</Col>
			</Row>
			<Row>
				<Col xl={12} lg={12} md={12} className="mb-2">
					{VICourses &&
						<MyCourses VICourses={VICourses} />
					}
				</Col>

			</Row>
		</div>
	);
};

export default LMSDashboard;
