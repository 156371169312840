import React, { useState } from "react";
import cx from "classnames";
import { Accordion, Col, Row } from "react-bootstrap";
import moment from "moment";
import { FiChevronDown, FiPlus, FiTrash2 } from "react-icons/fi";
import Switch from "@material-ui/core/Switch";
import { InputField, Label, Button, RTE } from "../../foundation";
import { Controller } from "react-hook-form";
import Api, { ENDPOINTS } from "../../api";
import CircularProgress from "@material-ui/core/CircularProgress";

const Activities = ({ watch, register, fields, append, remove, control }) => {
	const [deleting, setDeleting] = useState(null);
	const Activities = watch("activities");
	return (
		<div className="resume-builder__section">
			<h2>Extra-curricular Activities</h2>
			{fields.map((field, index) => {
				const { employer_name, function_title, start_date, end_date } =
					Activities?.[index] || {};
				const displayDate = [];
				if (start_date) displayDate.push(moment(start_date).format("MMM YYYY"));
				if (end_date) displayDate.push(moment(end_date).format("MMM YYYY"));
				return (
					<div key={field.key} className={cx("resume-builder__section__card p-0")}>
						<input
							type="hidden"
							id={`activities[${index}].id`}
							name={`activities[${index}].id`}
							defaultValue={field.id}
							// ref={register}
							{...register(`activities[${index}].id`)}
						/>
						<Accordion defaultActiveKey={`${field.id ? "" : field.key}`}>
							<Accordion.Item
								as={"div"}
								variant="link"
								eventKey={`${field.key}`}
								className="resume-builder__section__card--title"
							>
								<Accordion.Header className="w-100">
									<div className="m-0">
										<p className="m-0">
											{employer_name && function_title
												? `${function_title}  ${employer_name}`
												: function_title || employer_name || "(Not Specified)"}
										</p>
										{displayDate.length > 0 && (
											<Label className="mb-0">{displayDate.join(" - ")}</Label>
										)}
									</div>{" "}
									{/* <span className="d-flex m-0">
										<FiChevronDown size={20} />
									</span> */}
								</Accordion.Header>
								<Accordion.Body eventKey={`${field.key}`}>
									<>
										{/* <Row>
                    <Col>
                      <Controller
                        control={control}
                        name={`activities[${index}].end_date`}
                        render={({ onChange, value, name, ref }) => (
                          <>
                            <Switch
                              checked={value === null}
                              onChange={(e) =>
                                onChange(e.target.checked ? null : "")
                              }
                              color="primary"
                            />{" "}
                            <Label as="span">Currently work here</Label>
                          </>
                        )}
                      />
                    </Col>
                  </Row> */}
										<Row className="mb-3 ">
											<Col>
												<Label>Function Title</Label>
												<Controller
													control={control}
													name={`activities[${index}].function_title`}
													render={({
														field: { onChange, onBlur, value, name, ref },
														fieldState: { invalid, isTouched, isDirty, error },
														formState,
													}) => (
														<InputField
															stretch
															placeholder="Enter function Title"
															onBlur={onBlur} // notify when input is touched
															onChange={onChange} // send value to hook form
															checked={value}
															inputRef={ref}
														/>
													)}
												/>
											</Col>
											<Col>
												<Label className="mt-1">Employer</Label>
												<Controller
													control={control}
													name={`activities[${index}].employer_name`}
													render={({
														field: { onChange, onBlur, value, name, ref },
														fieldState: { invalid, isTouched, isDirty, error },
														formState,
													}) => (
														<InputField
															stretch
															placeholder="Enter Employer Name"
															onBlur={onBlur} // notify when input is touched
															onChange={onChange} // send value to hook form
															checked={value}
															inputRef={ref}
														/>
													)}
												/>
											</Col>
										</Row>
										<Row className="mb-3">
											<Col>
												<Row>
													<Col xs={6} className="pr-1">
														<Label>Start Date</Label>
														<Controller
															control={control}
															name={`activities[${index}].start_date`}
															render={({
																field: { onChange, onBlur, value, name, ref },
																fieldState: {
																	invalid,
																	isTouched,
																	isDirty,
																	error,
																},
																formState,
															}) => (
																<InputField
																	type="date"
																	stretch
																	onBlur={onBlur} // notify when input is touched
																	onChange={onChange} // send value to hook form
																	checked={value}
																	inputRef={ref}
																/>
															)}
														/>
													</Col>
													<Col xs={6} className="pl-1">
														<Label>End Date</Label>
														<Controller
															control={control}
															name={`activities[${index}].end_date`}
															render={({
																field: { onChange, onBlur, value, name, ref },
																fieldState: {
																	invalid,
																	isTouched,
																	isDirty,
																	error,
																},
																formState,
															}) =>
																end_date === null ? (
																	<InputField
																		stretch
																		value="Present"
																		readOnly
																	/>
																) : (
																	<InputField
																		type="date"
																		min={start_date}
																		stretch
																		onBlur={onBlur} // notify when input is touched
																		onChange={onChange} // send value to hook form
																		checked={value}
																		inputRef={ref}
																	/>
																)
															}
														/>
													</Col>
												</Row>
											</Col>
											<Col>
												<Label>City</Label>
												<Controller
													control={control}
													name={`activities[${index}].city`}
													render={({
														field: { onChange, onBlur, value, name, ref },
														fieldState: { invalid, isTouched, isDirty, error },
														formState,
													}) => (
														<InputField
															stretch
															placeholder="Enter city"
															onBlur={onBlur} // notify when input is touched
															onChange={onChange} // send value to hook form
															checked={value}
															inputRef={ref}
														/>
													)}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<Label>Description</Label>
												<Controller
													control={control}
													name={`activities[${index}].description`}
													render={({
														field: { onChange, onBlur, value, name, ref },
														fieldState: { invalid, isTouched, isDirty, error },
														formState,
													}) => (
														<RTE
															value={value}
															onBlur={onBlur} // notify when input is touched
															onChange={onChange} // send value to hook form
															checked={value}
															inputRef={ref}
														/>
													)}
												/>
											</Col>
										</Row>
									</>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
						<Button
							variant="link"
							disabled={deleting === index}
							className="resume-builder__section__card--delete"
							onClick={async () => {
								try {
									setDeleting(index);
									if (field.id) {
										await Api.delete(
											`${ENDPOINTS.DELETE_EXTRA_CURRICULAR_ACTIVITIE}/${field.id}`
										);
									}
									remove(index);
								} finally {
									setDeleting(null);
								}
							}}
						>
							{deleting === index ? (
								<CircularProgress size={20} color="primary" />
							) : (
								<FiTrash2 size={20} />
							)}
						</Button>
					</div>
				);
			})}
			<Row className="mb-3">
				<Col>
					<Button className="text-info fs-4" variant="link" type="button" onClick={() => append({})}>
						<FiPlus />
						{fields.length < 1 ? " Add activity" : " Add one more activity"}
					</Button>
				</Col>
			</Row>
		</div>
	);
};

export default Activities;
