// import node module libraries
import React, { Fragment, useState, useMemo, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Card, ListGroup, Form,Spinner    
} from 'react-bootstrap';

// import media files
//import Avatar from 'assets/images/avatar-1.jpg';
import Url from "../Url";
import Education from './profile/Education';
import Internships from './profile/Internships';
import Jobs from './profile/Jobs';
import Projects from './profile/Projects';
import Trainings from './profile/Trainings';
import Documents from './profile/Documents';
import BasicDetails from './profile/BasicDetails';
import KeySkills from './profile/KeySkills';
import { toast } from 'react-toastify';


const Profile = () => {
    const url1 = Url();
	const url = url1["url"];
    const s3URL = url1["s3"];
	
    const studentName = localStorage.getItem("loginName");
	const applicantId = localStorage.getItem("applicantid");    
	const token = localStorage.getItem('stutoken');

    const [studentBasic, setStudentBasic] = useState([]);
    const [studentMobile, setStudentMobile] = useState("");    
    const [studentEducation, setStudentEducation] = useState([]);    
    const [studentSoftwareSkills, setStudentSoftwareSkills] = useState([]);    
    const [studentInternships, setStudentInternships] = useState([]);  
    const [studentProjects, setStudentProjects] = useState([]);  
    const [studentJobs, setStudentJobs] = useState([]);
    const [studentDocuments, setStudentDocuments] = useState([]);
    const [studentTrainings, setStudentTrainings] = useState([]);        
    const [locationList, setLocationList] = useState([]);
    const [skillsList, setSkillsList] = useState([]);

    const [loadData,setLoadData] = useState(false);

    const [loading, setLoading] = useState(false);

    //Following two session variables are created when redirected from the employer screen.
    //When redirected from employer screen, we need to hide all the add/edit buttons and 
    //need to display the student profile even if now user is logged in

    const empToken = localStorage.getItem("emptoken"); //we should check that it is from an employer

    const studentId = sessionStorage.getItem("studentID");
    const userType = sessionStorage.getItem("user");
    const [studentStatus,setStudentStatus] = useState(sessionStorage.getItem("studentStatus"));
    const employerInternshipId = sessionStorage.getItem("employerInternshipId");
    const internshipApplicationId = sessionStorage.getItem("internshipApplicationId");

    const [employerView, setEmployerView] = useState(false);
    const [candidateStatus,setCandidateStatus] = useState(0);

    const history = useHistory();

    useEffect(() => {

        var tmpEmployerView = false;
        if(studentId && userType == "E" && empToken){
            //console.log("setting true")
            setEmployerView(true);
            tmpEmployerView = true;
        }
        
        if(!localStorage.getItem("stutoken") && !tmpEmployerView){
            //console.log(employerView)
            window.location.href="/student-login";
            return;
        }
            
        //console.log(loadData);
        var api="";
        if(tmpEmployerView){
            api = url + "getApplicantProfileById/0/0/" + studentId;
        }else{
            api = url + "getApplicantProfileById/" + applicantId;
        }
		
		fetch(api, {
			method: "GET",
		})
           .then((apidata1) => {
            return apidata1.json();
        })
            .then((actualdata1) => {
            if (actualdata1.applicant_basic_detail !== "not-found") {
                setStudentBasic(actualdata1.applicant_basic_detail !== "not-found" ? actualdata1.applicant_basic_detail : []);
                setStudentMobile(actualdata1.applicant_mobileno.mobileno);
                setStudentEducation(actualdata1.applicant_education!== "not-found" ? actualdata1.applicant_education : []);
                setStudentSoftwareSkills(actualdata1.applicant_software_skill!== "not-found" ? actualdata1.applicant_software_skill : []);
                setStudentInternships(actualdata1.applicant_intership !== "not-found" ? actualdata1.applicant_intership : []);
                setStudentProjects(actualdata1.applicant_project !== "not-found" ? actualdata1.applicant_project : []);
                setStudentJobs(actualdata1.applicant_job !== "not-found" ? actualdata1.applicant_job : []);
                setStudentDocuments(actualdata1.applicant_document !== "not-found" ? actualdata1.applicant_document : []);
                setStudentTrainings(actualdata1.applicant_training !== "not-found" ? actualdata1.applicant_training : []);
            }
            setLocationList(actualdata1.area.length > 0 ? actualdata1.area : []);
            setSkillsList(actualdata1.software_list.length > 0 ? actualdata1.software_list : []);
            if(actualdata1.studentStatus == '1'){
                localStorage.removeItem("profileStatus");
            }else{
                localStorage.setItem("profileStatus","0");
            }
        });


        setLoadData(false);

    },[loadData,studentId, applicantId]);
    
    const changeStatus = (status) => {
        /*
        Application Status - 
        1 - Shortlisted
        2 - Hired
        3 - Offer sent
        4 - Rejected
        5 - Marked as Favorite
        */


        var confirmMessage = "";
        switch(status){
            case 1: 
                confirmMessage = "Do you want to shortlist this candidate, Are you sure?"
                break;
            case 2:
            case 3: 
                confirmMessage = "Do you want to hire this candidate, Are you sure?"
                break;
            case 4: 
                confirmMessage = "Do you want to reject this candidate, Are you sure?"
                break;                
        }
        
        const response = window.confirm(confirmMessage);

        if (response === true) {
            setLoading(true);
            //const api9 = url + "SetRejectByEmail/" + email + "/" + studentemail + "/" + pid
            const api9 = url + "updateInternshipApplicantStatus"
            const applicationStatus = status; 
            fetch(api9, {
                method: 'POST',
                body: JSON.stringify({ internshipApplicationId, applicationStatus }),
                headers: { 'Content-Type': 'application/json' }
            })
                .then((apidata1) => {
                    return apidata1.json()
                })
                .then((actualdata1) => {
                    var message="";
                    if (actualdata1 === 1) {
                        //setCandidateStatus(applicationStatus);

                        switch(applicationStatus){
                            case 2: 
                                setStudentStatus("Hired");
                                toast.success("Candicate Status updated to : HIRED");
                                break;
                            case 4:
                                setStudentStatus("Rejected");
                                toast.error("Candicate Status updated to : REJECTED");
                                break;
                        }

                    }
                    if (actualdata1 === 0) {
                        //toast.success('Candidate already rejected ....', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
                        toast.error("Cannot change status, contact support")
                    }
                    //console.log(actualdata1)
                    setLoading(false);
                })
        }
        else {
            return;
        }
    }

    const shortlist = (studentId,internshipId) => {
        
        const response = window.confirm("Do you want to Shortlist this candidate, Are you sure?")
        if (response === true) {
            setLoading(true);

            const api = url + "shortlistApplicant";
            fetch(api, {
              method: "POST",
              body: JSON.stringify({ internshipId, studentId }),
              headers: { "Content-Type": "application/json" },
            })
              .then((apidata) => {
                //console.log(apidata.body)
                return apidata.json();
              })
              .then((actualdata) => {
                if (actualdata["message"] === "Already_applied") {
                    toast.warning("Student Already shortlisted");
                }
                if (actualdata["message"] === "student_internship created") {
                    setStudentStatus("Shortlisted");
                    toast.success("Student shortlisted");
                }
                setLoading(false);
              });
        }
        else {
            return;
        }
    }

    return (
        <>
        <section className="pt-5 pb-6">
            <Container>
                <Row className="mt-0 mt-md-4">  
                <div className="col-12">
                            <p className="lead">Fields marked with * are mandatory to apply for internships</p>
                        </div>                 
                    {employerView && 
                        
                        <Row className="pb-3">
                            <Col md={12} className="d-flex justify-content-end">    
                            {
                                studentStatus == "Shortlisted" ?
                                <Fragment>
                                <button className="btn btn-success btn-sm me-2" onClick={() =>changeStatus(2)}>Hire {loading && <Spinner size="sm" />}</button>      
                                <button className="btn btn-outline-danger btn-sm me-2" onClick={() =>changeStatus(4)}>Reject {loading && <Spinner size="sm" />}</button> 
                                </Fragment>
                                : studentStatus == "Not Shortlisted" ?
                                    <button className="btn btn-success btn-sm me-2" onClick={() =>shortlist(studentId, employerInternshipId)}>Shortlist {loading && <Spinner size="sm" />} </button> 
                                : studentStatus == "Hired" ?  
                                    <Fragment>
                                        <span className="me-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="green" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                            </svg>                         
                                        </span>
                                        <span className="text-uppercase me-2">
                                            Hired
                                        </span>
                                    </Fragment>       
                                : studentStatus == "Rejected" ?   
                                    <Fragment>
                                        <span className="me-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="red" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                            </svg>                         
                                        </span>
                                        <span className="text-uppercase me-2">
                                            Rejected
                                        </span>
                                    </Fragment> 
                                : null 

                            }
                            <button className="btn btn-primary btn-sm" onClick={() => history.goBack()}>Back To Internships</button>
                            </Col>
                        </Row>
                    }
                    {/* Left hand Section - start */}
                    <Col lg={4} md={5} sm={12}>                            
                        <BasicDetails studentBasic={studentBasic} studentMobile={studentMobile} locationList={locationList} setLoadData={setLoadData} employerView={employerView} studentStatus={studentStatus}/> 
                    </Col>
                    {/* Left hand Section - end */}

                    {/* Right hand Section - start */}
                    <Col lg={8} md={7} sm={12}>                        

                        <Card className="border-0 mb-2">
                            <Card.Header>
                                <h3>Basic Details</h3>   
                            </Card.Header>
                            <Card.Body>                                                    
                                <Education studentEducation={studentEducation}  setLoadData={setLoadData} employerView={employerView} />

                                <KeySkills studentSoftwareSkills={studentSoftwareSkills} skillsList={skillsList} setLoadData={setLoadData} applicantId={studentBasic.id} employerView={employerView} />
                            </Card.Body>
                        </Card>

                        <Card className="border-0 mb-2">
                            <Card.Header>
                            <h3>Other Details</h3>   
                            </Card.Header>
                            <Card.Body>              
                                <Internships studentInternships={studentInternships} setLoadData={setLoadData} applicantId={studentBasic.id} locationList={locationList} employerView={employerView} />

                                <Projects studentProjects={studentProjects} setLoadData={setLoadData} applicantId={studentBasic.id} locationList={locationList} employerView={employerView} />

                                <Trainings studentTrainings={studentTrainings} setLoadData={setLoadData} applicantId={studentBasic.id} locationList={locationList} employerView={employerView} />

                               {/* <Documents studentDocuments={studentDocuments} setLoadData={setLoadData} applicantId={studentBasic.id} /> */}
                            </Card.Body>
                        </Card>                                                                                                          
                    </Col>
                    {/* Right hand Section - end */}
                </Row>
            </Container>
        </section>
        </>
    )
}

export default Profile