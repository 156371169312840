// import node module libraries
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Card, Badge, Dropdown, Row, Col, Image } from "react-bootstrap";
import "./ProjectInstructions.css";
// import bootstrap icons
import { Calendar4, Clock, CurrencyDollar } from "react-bootstrap-icons";
import {
  PiNumberCircleOneFill,
  PiNumberCircleTwoFill,
  PiNumberCircleThreeFill,
} from "react-icons/pi";

import LMSProjectImg from "assets/images/svg/Lms-Project.svg";

const ProjectIntructions = (props) => {
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu

  const { projectDetails } = props;

  console.log(projectDetails);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  return (
    <>
      {projectDetails ? (
        <>
          <Card>
            <Card.Header className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h3
                    className="mb-0"
                    style={{ color: "var(--geeks-secondary)" }}
                  >
                    Project: {projectDetails?.project_name}
                  </h3>
                </div>
                {/* <div>
                            <ActionMenu />
                        </div> */}
              </div>
              {/* <Col xs={12} className="d-flex flex-row mb-2">
					<div>
						<span>Current Status: 
							<Badge className="small ms-1" bg={projectDetails?.project_status ==0 ? "secondary" : projectDetails?.project_status == 1 ? "warning" : projectDetails?.project_status == 2 ? "success" : projectDetails?.project_status == 3 ? "danger" : "secondary"}>
								{projectDetails?.project_status_desc}
							</Badge>
							</span>
						</div>
						<div>
							{projectDetails?.project_status == 2 && 
								<a href={projectDetails?.online_assessment_link} className="btn btn-primary btn-sm ms-2">Go to Online Assessment</a>
							}		
						</div>					
					</Col>					 */}
            </Card.Header>
            <Card.Body>
              <p
                dangerouslySetInnerHTML={{
                  __html: projectDetails?.project_description,
                }}
              ></p>
            </Card.Body>
          </Card>

          {projectDetails?.prerequisite &&
          <Card className="mt-2">
            <Card.Header className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h3
                    className="mb-0"
                    style={{ color: "var(--geeks-secondary)" }}
                  >
                    Project Prerequisite
                  </h3>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <p
                dangerouslySetInnerHTML={{ 
                  __html: projectDetails?.prerequisite,
                }}
              ></p>
            </Card.Body>
          </Card>
          }


          <Card className="mt-2">
            <Card.Header className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h3
                    className="mb-0"
                    style={{ color: "var(--geeks-secondary)" }}
                  >
                    Project Instructions
                  </h3>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
            <p
                dangerouslySetInnerHTML={{
                  __html: projectDetails?.prerequisite,
                }}
              ></p>              
              <p
                dangerouslySetInnerHTML={{
                  __html: projectDetails?.project_instructions,
                }}
              ></p>
            </Card.Body>
          </Card>
        </>
      ) : (
        <div className="d-flex justify-content-between align-items-center">
          <h5>Project Details not set, contact support</h5>
        </div>
      )}
    </>
  );
};

export default ProjectIntructions;
