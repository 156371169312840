// import node module libraries
import React, { Fragment, useMemo, useEffect,useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Button,
  Badge,
  Dropdown,
  Table,
  Form,
  Modal,
} from "react-bootstrap";
import { XCircle, MoreVertical } from "react-feather";

// import custom components
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import Url from "components/Url";
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import ReactPaginate from "react-paginate";
import { ChevronLeft, ChevronRight } from "react-feather";

const MentorshipBookingList = () => {
  const url1 = Url();
  const s3 = url1.s3;
  const url = url1.url;
  const [load,setLoad] = useState(false);
  const [mentorshipBookingList, setMentorshipBookingList] = useState([]);
  const [showProjectComment, setShowProjectComment] = useState(false);
  const [projectComment, setProjectComment] = useState();
  const [careerDetailId,setCareerDetailId] = useState();

  const token = localStorage.getItem('admtoken');

  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const fetchData = (page) => {

    let apiURL =  url + "getAllMentorBookings" + (currentPage ? "?page=" + currentPage: "");

    Axios.get(
        apiURL,
        {headers: {
                "Content-type": "Application/json",
                "Authorization": `Bearer ${token}`
                }   
            }
      )
      .then((response) => {
          //var response = response.data.virtualInternshipList;
          setMentorshipBookingList (response.data.mentorBookings.data); // need to use mentorBookings.data due to pagination component
          //setCurrentPage(response.data.current_page);
          // setting page count
          setPageCount(response.data.last_page);          
        },
        (error) => {
          var status = error.response.status
        }
      );	
      setLoad(false);
};	

const handleApprove = (careerDetailId, status) => {
    let apiURL =  url + "updateStudentProjectStatus";
    try{
        Axios.post(
            apiURL,
            {careerDetailId,status:status,projectComment },
            {headers: {
                    "Content-type": "Application/json",
                    "Authorization": `Bearer ${token}`
                    }   
                }
        ).then((response) => {
            console.log("in then");

            console.log(response);

            if (response.status === 200) {
                toast.success(response.data.message,{autoClose: 1500});
                setLoad(true);
            }else{
                console.log("in else");
                handleErrorResponse(response);
            }
            },(error) => {
                console.log("in error");
                toast.warning("Project status not updated, please check",{autoClose: 1500})
            }
        );	
        
    }catch(error){
        console.log(error.response);

        if (error.response) {
            // The request was made, but the server responded with an error
            handleErrorResponse(error.response);
          } else if (error.request) {
            // The request was made, but no response was received
            console.error('No response received.');
          } else {
            // Something happened in setting up the request that triggered an error
            console.error('Error:', error.message);
          }
    }	
};

const handleErrorResponse = (response) => {
    // Check specific HTTP status codes and display the error message
    switch (response.status) {
      case 400:
        console.error('Bad Request:', response.data);
        // Display the error message to the user
        toast.warning(`Bad Request: ${response.data.message}`);
        break;
      case 403:
        console.error('Unauthorized:', response.data);
        // Display the error message to the user
        toast.warning(`Unauthorized: ${response.data.message}`);
        break;
      case 404:
        console.error('Not Found:', response.data);
        // Display the error message to the user
        toast.warning(`Not Found: ${response.data.message}`);
        break;
      default:
        console.error('Unhandled Error:', response.data);
        // Display a generic error message to the user
        toast.warning('An error occurred. Please try again later.');
    }
  };

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const ActionMenu = () => {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-secondary" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Header>SETTINGS</Dropdown.Header>
          <Dropdown.Item eventKey="1">
            {" "}
            <XCircle size="18px" /> Reject with Feedback
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      { accessor: "mentor_booking_id", Header: "ID"},     
      { accessor: "student_name", Header: "Student Name"},
      { accessor: "student_email", Header: "Student Email"},
      { accessor: "student_mobileno", Header: "Student Mobile"},
      { accessor: "mentor_name", Header: "Mentor Name"},
      { accessor: "mentor_email", Header: "Student Email"},
      { accessor: "mentor_mobileno", Header: "Student Mobile"},
      { accessor: "service_name", Header: "Mentor Subject"},
      { accessor: "service_duration_mins", Header: "Session Duration"},
      { accessor: "meeting_link", Header: "Meeting Link"},
      { accessor: "payment_status", Header: "Booking Status"},
      { accessor: "mentorship_status", Header: "Status"},
      {accessor: "mentorship_date", Header: "Session Date"},

    ],
    []
  );

  const data = useMemo(() => mentorshipBookingList, [mentorshipBookingList]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, globalFilter } = state;

  return (
    <Fragment>
      <Modal
        show={showProjectComment}
        onHide={() => setShowProjectComment(false) }
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
            <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
            <Modal.Body className="pb-0">
            <Form.Group className="mb-3" >
                <Form.Control
                type="text"
                onChange={(e) => setProjectComment(e.target.value)}
                placeholder="Add Comment"
                className="mb-3"
                />
                <Button variant="primary" className="btn-sm" onClick={() => {setShowProjectComment(false) ; handleApprove(careerDetailId, 3)  }} >Save</Button>
                <Button variant="outline-secondary" className="btn-sm ms-2" onClick={() => {setShowProjectComment(false);setCareerDetailId(0);}} >Cancel</Button>
            </Form.Group>        
            </Modal.Body>
        </Modal>
        <div className=" overflow-hidden">
        <Row>
          <Col lg={12} md={12} sm={12} className="px-5 ">
            <h3>Mentorship Booking List</h3>
          </Col>
        </Row>
        </div>

      <div className=" overflow-hidden">
        <Row>
          <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-4 px-5 ">
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              placeholder="Search by email, Project name, Student name etc"
            />
          </Col>
        </Row>
      </div>

      <div className="table-responsive border-0 overflow-y-hidden">
        <Table {...getTableProps()} className="text-nowrap">
          <thead className="table-light">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      {/* Pagination @ Footer */}
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        onPageChange={handlePageChange}
        previousLabel={<ChevronLeft size="10px" />}
        nextLabel={<ChevronRight size="10px" />}
        containerClassName={"justify-content-center mb-0 p-2 pagination"}
        previousLinkClassName={"page-link mx-1 rounded"}
        nextLinkClassName={"page-link mx-1 rounded"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link mx-1 rounded"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"active"}
      />
      <ToastContainer />
    </Fragment>
  );
};

export default MentorshipBookingList;
