// import node module libraries
import { Link, useHistory } from 'react-router-dom';
import {
    Image,
    Container,
    Row,
    Col,
    Card,
    Form,
    Button
} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';

// import media files
import ImgEmployerRegistration from '../../assets/images/employer-registration.png';
import Url from 'components/Url';

const EmployerRegistration = () => {
    const url1 = Url()
    const url = url1.url
    const history = useHistory()
    const registerEmp = (e) => {
        e.preventDefault();
        let stat;
        const fullname = e.target.name.value;
        const mobileno = e.target.number.value;
        const email = e.target.email.value;
        const password = e.target.password.value;
    
        if (email.includes("gmail" || "yahoo" || "hotmail" || "rediffmail" || "outlook" || "aol" || "msn" || "live" || "ymail" || "mail" || "free" || "")) {
            toast.warning("Public email not allowed, please use official id to register")
        //   toast.error("Public email not allowed, please use official id to register", { position: toast.POSITION.TOP_CENTER });
          return;
        }
    
        const api = url + "employer";
        fetch(api, {
          method: "POST",
          body: JSON.stringify({ fullname, email, mobileno, password }),
          headers: { "Content-Type": "application/json" },
        })
          .then((apidata) => {
            stat = apidata.status
            return apidata.json();
          })
          .then((actualdata) => {
            console.log(stat);
            if(stat === 201 || stat === 200){
                toast.success("Email is sent, Please verify your account.");
                //Umesh - 20220607 - start
                //call zoho CRM API to add employer lead
                const zohoLeadapi = url + "addEmployerLead";
                fetch(zohoLeadapi, {
                method: "POST",
                body: JSON.stringify({ fullname, email, mobileno }),
                headers: { "Content-Type": "application/json" },
                }).then((apidata) => {
                return apidata.json();
                });
                //Umesh - 20220607 - end                
                history.push('/');
            }
            if (stat === 401) {
                toast.warning(actualdata.message)
            //   toast.error("Email already exist", { position: toast.POSITION.TOP_CENTER });
              return;
            }            
            if (stat === 404) {
                toast.warning("Email already exist")
            //   toast.error("Email already exist", { position: toast.POSITION.TOP_CENTER });
              return;
            }
            
          })

      };
      
    return (
        <>
        <ToastContainer />
        <section className="py-8">
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="text-center">
                            <h1 className="display-5 fw-bold mb-2">Hire next Interns at <span
                                className="text-primary">Internship<span className="text-secondary">gate</span></span></h1>
                            <p className="lead fw-semibold">Post jobs for <Link to="#" className="link-secondary">free</Link> now</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-lg-8 mt-4 align-items-center">
                    <Col lg={{ order: 1 , span:6 }} xs={{ order: 2 , span:12 }} className="text-center text-lg-start">
                        <div>
                            <Image src={ImgEmployerRegistration} alt="" className="w-100" />
                        </div>
                    </Col>
                    <Col xl={6} xs={12} lg={{ order: 2 , span:6 }}>
                        <div className="mx-xl-8 mb-8 mb-lg-0">
                            <Card className="shadow border-0">
                                <Card.Body className="p-6">
                                    <h2 className="mb-4">Create an account to continue</h2>
                                    <Form onSubmit={registerEmp}>
                                        <Form.Group className="mb-3" controlId="full-name">
                                            <Form.Control type="text" name="name" placeholder="Full Name" required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="employer-email">
                                            <Form.Control type="email" name="email" placeholder="Official Email Id" required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="employer-mobile">
                                            <Form.Control type="number" name="number" placeholder="Mobile Number" required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="employer-password">
                                            <Form.Control type="password" name="password" placeholder="Password" required />
                                        </Form.Group>
                                        <div className="mb-4">
                                            <small>By registering, you agree to our <Link to="#">Terms and
                                                Conditions</Link>.</small>
                                        </div>
                                        <div className="d-grid">
                                            <Button variant="primary" type="submit">Register</Button>
                                        </div>
                                    </Form>
                                    {/* <div className="mt-3">
                                        <span>Already registered? <Link to="#">Login</Link></span>
                                    </div> */}
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    )
}

export default EmployerRegistration