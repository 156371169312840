// import node module libraries
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// import MDI icons
import Icon from '@mdi/react';
import { mdiStar } from '@mdi/js';

// Import required utility file
import { numberWithCommas } from 'helper/utils';
import Url from 'components/Url';
import "./ribbon.css";

const GKInstructorCard = ({ item }) => {

	const url1 = Url();
	const url = url1["url"];
	const s3URL = url1["s3"];
	
	return (
		<Card className="mb-4 card-hover  position-relative">
			{topMentors.includes(item.mentor_id) ? (
			<span className="ribbonStyle">
				{/* <div>Top</div>
			<div>Selling</div> */}
			</span>
			) : null}			
			{/* img */}
			<Card.Img
				variant="top"
				src={s3URL + "Mentors/" + item.profile_pic}
				className="rounded-top-md img-fluid"
			/>
			{/* card body */}
			<Card.Body>
				<h3 className="mb-0 fw-semi-bold">
					{' '}
					<Link to={item.link} className="text-inherit">
						{item.fullname}
					</Link>
				</h3>
				<p className="mb-3">{item.current_role}</p>
				<div className="lh-1  d-flex justify-content-between">
					<div>
						<span className="fs-6 text-warning">
							<Icon path={mdiStar} size={0.5} /> <span>{item.mentor_rating ? item.mentor_rating : 4.5}</span>
						</span>
					</div>
					<div>
						<span className="fs-6 text-muted">
							<span className="text-dark">
								{numberWithCommas(item.no_of_mentorships)}
							</span>{' '}
							Students
						</span>
					</div>
					<div className="mb-4">
						<span className="fs-6 text-muted">
							<span className="text-dark">{item.work_experience_year}</span> Years Work Exp
						</span>
					</div>
				</div>
				<div className="lh-1  d-flex justify-content-between">
					<Button variant='outline-primary' className="btn-sm" 
					 onClick={() => { window.location.href="/mentorship-details/" + item.id}}
					>Book Now</Button>
				</div>
			</Card.Body>
		</Card>
	);
};

// Typechecking With PropTypes
GKInstructorCard.propTypes = {
	item: PropTypes.object.isRequired
};

export default GKInstructorCard;
