import Url from "components/Url";

const url1 = Url();
const s3 = url1["s3"];
const s3URL = s3 + "logos/";

export const companyLogo = [
  {
    id: 1,
    logoimage: s3URL + "Oracle-Logo.png",
  },
  {
    id: 2,
    logoimage: s3URL + "ibm-logo.png",
  },
  {
    id: 3,
    logoimage: s3URL + "tech-mahindra-logo.png",
  },
  {
    id: 4,
    logoimage: s3URL + "HCL-Logo.png",
  },
  {
    id: 5,
    logoimage: s3URL + "astrazeneca-logo.png",
  },
  {
    id: 6,
    logoimage: s3URL + "Wipro-logo.png",
  },
  {
    id: 7,
    logoimage: s3URL + "Cognizant-Logo.png",
  },
  {
    id: 8,
    logoimage: s3URL + "Hexaware-Technologies-logo.png",
  },
  {
    id: 9,
    logoimage: s3URL + "l-n-t-logo.png",
  },
  {
    id: 10,
    logoimage: s3URL + "mindtree-logo.png",
  },
  {
    id: 11,
    logoimage: s3URL + "persistent-systems-logo.png",
  },
  {
    id: 12,
    logoimage: s3URL + "mpasis logo.png",
  },
];
