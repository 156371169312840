// import node module libraries
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Button,
  Modal,
  Row,
  Col,
  Breadcrumb,
  Spinner,
  Form,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { ChevronLeft, ChevronRight } from "react-feather";
import Axios from "axios";

// import custom components
import { FormSelect } from "components/elements/form-select/FormSelect";
import Sidebar from "./Sidebar";
import Select from "react-select";
import CandidateInfoCardVIStudents from "../common/cards/CandidateInfoCardVIStudents";
import ChatApp from "../chat/Chat";
import Url from "components/Url";
const VIStudents = () => {
  const statusInfo = useParams();
  const appStatus = "recommended";
  const intDetails = JSON.parse(window.localStorage.getItem("intDetails"));
  const [skillsOptions, setSkillsOptions] = useState();
  const [locationOptions, setLocationOptions] = useState();
  const [skills, setSkills] = useState([]);
  const [location, setLocation] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const id = window.localStorage.getItem("intId");
  const [name, setName] = useState("");
  const [ids, setId] = useState();
  const [load, setLoad] = useState(false);
  const url1 = Url();
  const url = url1["url"];
  const [loading, setLoading] = useState(false);
  const [candidateInfo, setCandidateInfo] = useState();
  const [internshipRecord, setInternshipRecord] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const RecordsPerPage = 10;
  const pagesVisited = pageNumber * RecordsPerPage;
  const [employerInternship, setEmployerInternship] = useState();

  //Logic start for filters other than AI recommended

  const handleSkillChange = (e) => {
    const changedSkills = e;
    const selectedSkillsArray = changedSkills.map((skill) => skill.value);
    setSelectedSkills(selectedSkillsArray);
    setPageNumber(1);
  };

  const handleLocationChange = (e) => {
    const changedLocations = e;
    const selectedLocationsArray = changedLocations.map(
      (location) => location.value
    );
    setSelectedLocations(selectedLocationsArray);
    setPageNumber(1);
  };

  //Logic end for filters other than AI recommended

  // console.log(displayRecords);

  useEffect(() => {
    setCandidateInfo([]); //reset the data first,
    let paramSkills = skills ? "/" + skills : "";
    let paramLocations  = location ? "/" + location : "";
    let paramPageNo = pageNumber ? "?page=" + pageNumber : "";

    var skillsFilter = skills?.map((item) => item.value).join(",");
    var locationFilter = location?.map((item) => item.value).join(",");
    var params = "";
    if (skillsFilter) {
    params = params + "/" + skillsFilter;
    } else {
    params = params + "/null";
    }
    if (locationFilter) {
    params = params + "/" + locationFilter;
    } else {
    params = params + "/null";
    }

    let apiURL =
      url + "getVIStudents/"+ id + params + paramPageNo;

    Axios.get(
      apiURL
      // {headers: {
      //         "Content-type": "Application/json",
      //         "Authorization": `Bearer ${token}`
      //         }
      //     }
    ).then(
      (response) => {
        //var response = response.data.virtualInternshipList;
        setCandidateInfo(response.data.studentList.data);
        setEmployerInternship(response.data.employerInternship)
        //setCurrentPage(response.data.current_page);
        // setting page count
        setPageCount(response.data.studentList.last_page);
      },
      (error) => {
        var status = error.response.status;
      }
    );
  }, [load, skills, location, pageNumber]);

  useEffect(() => {

    const locapi = url + "getLocationList";
    fetch(locapi, {
      method: "GET",
    })
      .then((locdata) => {
        return locdata.json();
      })
      .then((locdata1) => {
        setLocationOptions(locdata1);
      });

    //get software skills
    const api4 = url + "getSoftwareSkill";
    fetch(api4, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        setSkillsOptions(actualdata1);
      });
  }, []);

  const handlePageChange = (selectedPage) => {
    setPageNumber(selectedPage.selected + 1);
  };

  return (
    <Fragment>
      <section className="pt-5 pb-6 bg-white">
        <Container>
          <Row className="border-bottom pb-5 g-0 align-items-center">
            <Col md={9}>
              <div className="mb-4">
                <Breadcrumb>
                  <Breadcrumb.Item href="/empdashboard" as="li">
                    Dashboard
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Internship Single</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <h1 className="mb-md-0">
                Certified Students
              </h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pb-8 bg-white">
        <Container>
          <Row>
            <Col lg={3}>
              <Sidebar setLoad={setLoad} />
            </Col>
            <Col lg={6}>
              <div className="mt-6 mt-lg-0 mb-4">
                <h4 className="mb-md-0">
                  Following students have completed the virtual internship
                  program at <span style={{color:"#2C3895"}}>Internship</span><span style={{color:"#F98F43"}}>gate</span>
                </h4>
                {candidateInfo?.map((item, index) => {
                  return (
                    <CandidateInfoCardVIStudents
                      item={item}
                      internship_status={employerInternship.internship_status}
                      key={index}
                      infoType={"VIStudents"}
                      handleShow={handleShow}
                      employerInternshipId={id}
                      setId={setId}
                      setName={setName}
                      internshipApplicationId={item.internship_application_id}
                      url={url}
                      setLoad={setLoad}
                      employerInternshipDetails={employerInternship}
                    />
                  );
                })}

                <ReactPaginate
                  pageCount={pageCount}
                  pageRangeDisplayed={5}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageChange}
                  previousLabel={<ChevronLeft size="10px" />}
                  nextLabel={<ChevronRight size="10px" />}
                  containerClassName={
                    "justify-content-center mb-0 p-2 pagination"
                  }
                  previousLinkClassName={"page-link mx-1 rounded"}
                  nextLinkClassName={"page-link mx-1 rounded"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link mx-1 rounded"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"active"}
                />
              </div>
            </Col>

            {/* Filter For AI recommended profiles */}
            <Col lg={3}>
              <div className="">
                <h2 className="mb-3">Filter</h2>
                <Form>
                  <div className="mb-3">
                    <Form.Group className="mb-3" controlId="skills">
                      <Form.Label>Skills</Form.Label>
                      <Select
                        isMulti
                        options={skillsOptions}
                        onChange={(e) => setSkills(e)}
                      />
                    </Form.Group>
                  </div>
                  <div className="mb-3">
                    <Form.Group className="mb-3" controlId="locations">
                      <Form.Label>Locations</Form.Label>
                      <Select
                        isMulti
                        options={locationOptions}
                        onChange={(e) => setLocation(e)}
                      />
                    </Form.Group>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default VIStudents;
