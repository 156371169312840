import React from 'react'

// import sub components
import JobSearchBox from 'components/marketing/common/jobs/JobSearchBox';


const CompanyList = () => {
  return (
    <main>
    <section className="py-8 bg-light">
      <div className="container">
        <div className="row ">
          <div className="col-lg-8 col-md-10 col-12">
            {/* text */}
            <div>
              <div className="mb-4"> <h1 className=" fw-bold mb-1">Discover Best Places to Work!</h1>
                <p>Company reviews. Salaries. Interviews. Jobs.</p></div>
                <JobSearchBox/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="py-8">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="mb-4"><h2>List of companies in India</h2></div>
            <div className="card card-bordered  mb-4 card-hover cursor-pointer">
              {/* card body */}
              <div className="card-body">
                <div>
                  <div className="d-lg-flex">
                    <div className="mb-3 mb-lg-0">
                      {/* Img */}
                      <img src="../../assets/images/job/job-brand-logo/job-list-logo-1.svg" alt="Geeks - Bootstrap 5 Template" className="icon-shape border rounded-3 icon-xxl"/>
                    </div>
                    {/* text */}
                    <div className="w-100 ms-lg-4">
                      <div className="d-flex justify-content-between mt-1">
                        <div>
                          {/* heading */}
                          <h3 className="mb-1"><a href="company-about.html" className="text-inherit">HelpScout</a>
                          </h3>

                          <div>
                            {/* star */}
                            <span className="text-dark fw-medium">4.5 <svg xmlns="http://www.w3.org/2000/svg" width="10"
                              height="10" fill="currentColor" className="bi bi-star-fill text-warning align-baseline"
                              viewBox="0 0 16 16">
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                              {/* review */}
                            </span><span className="ms-0">(131 Reviews)</span>
                          </div>

                        </div>
                        <div>
                          {/* button */}
                          <a href="#" className="btn btn-outline-primary btn-sm">Follow</a>
                        </div>

                      </div>
                      <div>
                        <div className="mt-4">
                          {/* row */}
                          <div className="row g-2">
                            {/* icon */}
                            <div className="col-12 col-md-6">
                              <div><span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                                className="bi bi-clock-fill text-muted align-text-bottom" viewBox="0 0 16 16">
                                <path
                                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                              </svg></span>
                                {/* icon */}
                                <span className="ms-1">55 year old</span></div>
                            </div>
                            <div className="col-12 col-md-6">
                              {/* icon */}
                              <div> <span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                                className="bi bi-geo-alt-fill text-muted align-text-bottom" viewBox="0 0 16 16">
                                <path
                                  d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                              </svg></span>
                                <span className="ms-1">Ahmedabad, Gujarat</span></div>
                            </div>
                            <div className="col-12 col-md-6">
                              {/* icon */}
                              <div><span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                                className="bi bi-building  text-muted align-text-bottom" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                  d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z" />
                                <path
                                  d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
                              </svg></span>
                                <span className="ms-1">Public</span></div>
                              {/* icon */}
                            </div>
                            <div className="col-12 col-md-6">
                              <div><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="bi bi-people-fill text-muted align-text-bottom" viewBox="0 0 16 16">
                                <path
                                  d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                <path fillRule="evenodd"
                                  d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z" />
                                <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                              </svg></span>
                                <span className="ms-1">745 Employees (India)</span></div>
                            </div>
                          </div>



                          <div>
                            {/* text */}
                            <div className="mt-3">
                              <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut in velit mollis, pellentesque
                                lorem a, faucibus leo. Praesent tempus id augue ut ullamcorper. Donec dignissim ante sed
                                metus sagittis porta nec sed purus. </p>
                            </div>


                          </div>
                        </div>
                      </div>
                    </div>


                  </div>

                </div>
              </div>
            </div>
            <div className="card card-bordered  mb-4 card-hover cursor-pointer">
              {/* card body */}
              <div className="card-body p-5">
                <div>
                  <div className="d-lg-flex">
                    <div className="mb-3 mb-lg-0">
                      {/* Img */}
                      <img src="../../assets/images/job/job-brand-logo/job-list-logo-2.svg" alt=""
                        className="icon-shape border rounded-3 icon-xxl"/>
                    </div>
                    {/* text */}
                    <div className="w-100 ms-lg-4">
                      <div className="d-flex justify-content-between mt-1">
                        <div>
                          {/* heading */}
                          <h3 className="mb-1"><a href="company-about.html" className="text-inherit">AirTable</a>
                          </h3>

                          <div>
                            {/* icon */}
                            <span className="text-dark fw-medium">4 <svg xmlns="http://www.w3.org/2000/svg" width="10"
                              height="10" fill="currentColor" className="bi bi-star-fill text-warning align-baseline"
                              viewBox="0 0 16 16">
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                              {/* reviews */}
                            </span><span className="ms-0">(120 Reviews)</span>
                          </div>

                        </div>
                        <div>
                          {/* button */}
                          <a href="#" className="btn btn-outline-primary btn-sm">Follow</a>
                        </div>

                      </div>
                      <div>
                        <div className="mt-4">
                          <div className="row g-2">
                            <div className="col-12 col-md-6">
                              {/* icon */}
                              <div><span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                                className="bi bi-clock-fill text-muted align-text-bottom" viewBox="0 0 16 16">
                                <path
                                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                              </svg></span>
                                <span className="ms-1">48 year old</span></div>
                            </div>
                            {/* icon */}
                            <div className="col-12 col-md-6">
                              <div> <span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                                className="bi bi-geo-alt-fill text-muted align-text-bottom" viewBox="0 0 16 16">
                                <path
                                  d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                              </svg></span>
                                <span className="ms-1">Jaipur, Rajasthan</span></div>
                            </div>
                            {/* icon */}
                            <div className="col-12 col-md-6">
                              <div><span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                                className="bi bi-building  text-muted align-text-bottom" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                  d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z" />
                                <path
                                  d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
                              </svg></span>
                                <span className="ms-1">Private</span></div>
                            </div>
                            {/* icon */}
                            <div className="col-12 col-md-6">
                              <div><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="bi bi-people-fill text-muted align-text-bottom" viewBox="0 0 16 16">
                                <path
                                  d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                <path fillRule="evenodd"
                                  d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z" />
                                <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                              </svg></span>
                                <span className="ms-1">522 Employees (India)</span></div>
                            </div>
                          </div>

                          <div className="mt-3">
                            {/* para */}
                            <p className="mb-0">Vivamus euismod eros ut nulla faucibus scelerisque. Integer non hendrerit ligula, sed
                              posuere mi. Nullam at porttitor ante. Nulla facilisi. Cras eleifend varius lacus id dictum.
                              Aliquam semper vel nisi sed molestie.  </p>

                          </div>
                        </div>
                      </div>
                    </div>


                  </div>

                </div>
              </div>
            </div>
            <div className="card card-bordered  mb-4 card-hover cursor-pointer">
              {/* card body */}
              <div className="card-body p-5">
                <div>
                  <div className="d-lg-flex">
                    <div className="mb-3 mb-lg-0">
                      {/* Img */}
                      <img src="../../assets/images/job/job-brand-logo/job-list-logo-3.svg" alt=""
                        className="icon-shape border rounded-3 icon-xxl"/>
                    </div>
                    {/* text */}
                    <div className="w-100 ms-lg-4">
                      <div className="d-flex justify-content-between mt-1">
                        <div>
                          {/* heading */}
                          <h3 className="mb-1"><a href="company-about.html" className="text-inherit">Square</a>
                          </h3>

                          <div>
                            {/* icon */}
                            <span className="text-dark fw-medium">4.5 <svg xmlns="http://www.w3.org/2000/svg" width="10"
                              height="10" fill="currentColor" className="bi bi-star-fill text-warning align-baseline"
                              viewBox="0 0 16 16">
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                              {/* review */}
                            </span><span className="ms-0">(125 Reviews)</span>
                          </div>

                        </div>
                        <div>
                          {/* button */}
                          <a href="#" className="btn btn-outline-primary btn-sm">Follow</a>
                        </div>

                      </div>
                      <div>
                        <div className="mt-4">
                          {/* row */}
                          <div className="row g-2">
                            <div className="col-12 col-md-6">
                              {/* icon */}
                              <div><span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                                className="bi bi-clock-fill text-muted align-text-bottom" viewBox="0 0 16 16">
                                <path
                                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                              </svg></span>
                                <span className="ms-1">52 year old</span></div>
                            </div>
                            {/* icon */}
                            <div className="col-12 col-md-6">
                              <div> <span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                                className="bi bi-geo-alt-fill text-muted align-text-bottom" viewBox="0 0 16 16">
                                <path
                                  d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                              </svg></span>
                                <span className="ms-1">Hastsal, Delhi</span></div>
                            </div>
                            {/* icon */}
                            <div className="col-12 col-md-6">
                              <div><span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                                className="bi bi-building  text-muted align-text-bottom" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                  d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z" />
                                <path
                                  d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
                              </svg></span>
                                <span className="ms-1">Public</span></div>
                            </div>
                            {/* icon */}
                            <div className="col-12 col-md-6">
                              <div><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="bi bi-people-fill text-muted align-text-bottom" viewBox="0 0 16 16">
                                <path
                                  d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                <path fillRule="evenodd"
                                  d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z" />
                                <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                              </svg></span>
                                <span className="ms-1">414 Employees (India)</span></div>
                            </div>
                          </div>


                          {/* text */}
                          <div className="mt-3">
                            <p className="mb-0">Phasellus mattis eleifend ipsum, mollis porta arcu vehicula quis. Fusce vel placerat ante.
                              Morbi quis erat eget tellus vulputate blandit ac et libero. Donec ac mollis ante. Sed
                              dignissim erat non ante tristique, a sagittis lorem interdum.
                            </p>
                          </div>


                        </div>
                      </div>
                    </div>


                  </div>

                </div>
              </div>
            </div>
            <div className="card card-bordered  mb-4 card-hover cursor-pointer">
              {/* card body */}
              <div className="card-body p-5">
                <div>
                  <div className="d-lg-flex">
                    <div className="mb-3 mb-lg-0">
                      {/* Img */}
                      <img src="../../assets/images/job/job-brand-logo/job-list-logo-4.svg" alt=""
                        className="icon-shape border rounded-3 icon-xxl"/>
                    </div>
                    {/* text */}
                    <div className="w-100 ms-lg-4">
                      <div className="d-flex justify-content-between mt-1">
                        <div>
                          {/* heading */}
                          <h3 className="mb-1"><a href="company-about.html" className="text-inherit">Round Table</a>
                          </h3>

                          <div>
                            {/* star */}

                            <span className="text-dark fw-medium">3.5 <svg xmlns="http://www.w3.org/2000/svg" width="10"
                              height="10" fill="currentColor" className="bi bi-star-fill text-warning align-baseline"
                              viewBox="0 0 16 16">
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                              {/* reviews */}
                            </span><span className="ms-0">(92 Reviews)</span>
                          </div>

                        </div>
                        <div>
                          {/* button */}
                          <a href="#" className="btn btn-outline-primary btn-sm">Follow</a>
                        </div>

                      </div>
                      <div>
                        <div className="mt-4">
                          <div className="row g-2">
                            <div className="col-12 col-md-6">
                              {/* icon */}
                              <div><span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                                className="bi bi-clock-fill text-muted align-text-bottom" viewBox="0 0 16 16">
                                <path
                                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                              </svg></span>
                                <span className="ms-1">51 year old</span></div>
                            </div>
                            {/* icon */}
                            <div className="col-12 col-md-6">
                              <div> <span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                                className="bi bi-geo-alt-fill text-muted align-text-bottom" viewBox="0 0 16 16">
                                <path
                                  d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                              </svg></span>
                                <span className="ms-1">Pune, Chennai</span></div>
                            </div>
                            {/* icon */}
                            <div className="col-12 col-md-6">
                              <div><span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                                className="bi bi-building  text-muted align-text-bottom" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                  d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z" />
                                <path
                                  d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
                              </svg></span>
                                <span className="ms-1">Private</span></div>
                            </div>
                            {/* icon */}
                            <div className="col-12 col-md-6">
                              <div><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="bi bi-people-fill text-muted align-text-bottom" viewBox="0 0 16 16">
                                <path
                                  d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                <path fillRule="evenodd"
                                  d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z" />
                                <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                              </svg></span>
                                <span className="ms-1">1314 Employees (India)</span></div>
                            </div>
                          </div>

                          {/* text */}

                          <div className="mt-3">
                            <p className="mb-0">Phasellus mattis eleifend ipsum, mollis porta arcu vehicula quis. Fusce vel placerat ante.
                              Morbi quis erat eget tellus vulputate blandit ac et libero. Donec ac mollis ante. Sed
                              dignissim erat non ante tristique, a sagittis lorem interdum.
                            </p>
                          </div>



                        </div>
                      </div>
                    </div>


                  </div>

                </div>
              </div>
            </div>
            {/* pagination */}
            <nav aria-label="Page navigation example">
              <ul className="pagination  mb-0">
                <li className="page-item disabled">
                  <a className="page-link mx-1 rounded" href="#" tabindex="-1" aria-disabled="true"><i className="mdi mdi-chevron-left"></i></a>
                </li>
                <li className="page-item active">
                  <a className="page-link mx-1 rounded" href="#">1</a>
                </li>
                <li className="page-item">
                  <a className="page-link mx-1 rounded" href="#">2</a>
                </li>
                <li className="page-item">
                  <a className="page-link mx-1 rounded" href="#">3</a>
                </li>
                <li className="page-item">
                  <a className="page-link mx-1 rounded" href="#"><i className="mdi mdi-chevron-right"></i></a>
                </li>
              </ul>
            </nav>

          </div>
          <div className="col-md-4 mt-4 mt-md-0">
            <div className="card border mb-6 mb-md-0 shadow-none">
              <div className="card-header">
                <h4 className="mb-0 fs-5"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  className="bi bi-filter text-muted me-2" viewBox="0 0 16 16">
                  <path
                    d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                </svg>All Filters</h4>
              </div>
              <div className="card-body py-3">
                <a className="fs-5 text-dark fw-semi-bold" data-bs-toggle="collapse" href="#collapseExample" role="button"
                  aria-expanded="false" aria-controls="collapseExample">
                  Designations <span className="float-end"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                    fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                    <path fillRule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                  </svg></span>
                </a>
                <div className="collapse show" id="collapseExample">
                  <div className="mt-3">
                    <div className="form-check mb-2">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckLocationOne" />
                      <label className="form-check-label" for="flexCheckLocationOne">
                        Accountant <span className="text-muted">(4)</span>
                      </label>
                    </div>
                    <div className="form-check mb-2 ">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckLocationTwo " checked />
                      <label className="form-check-label" for="flexCheckLocationTwo ">
                        Executive Accountant <span className="text-muted">(8)</span>
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckLocationThree " />
                      <label className="form-check-label" for="flexCheckLocationThree ">
                        Assistant Manger <span className="text-muted">(12)</span>
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckLocationFour " />
                      <label className="form-check-label" for="flexCheckLocationFour ">
                        Software Developer <span className="text-muted">(23)</span>
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckLocationFive " />
                      <label className="form-check-label" for="flexCheckLocationFive ">
                        HR Executive <span className="text-muted">(28)</span>
                      </label>
                    </div>

                  </div>
                </div>

              </div>
              <div className="card-body border-top py-3">
                <a className="fs-5 text-dark fw-semi-bold" data-bs-toggle="collapse" href="#collapseExampleSecond"
                  role="button" aria-expanded="false" aria-controls="collapseExampleSecond">
                  Locations <span className="float-end"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                    fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                    <path fillRule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                  </svg></span>
                </a>
                <div className="collapse show" id="collapseExampleSecond">
                  <div className="mt-3">
                    <div className="form-check mb-2">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckLocationOne" />
                      <label className="form-check-label" for="flexCheckLocationOne">
                        Gujarat <span className="text-muted">(4)</span>
                      </label>
                    </div>
                    <div className="form-check mb-2 ">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckLocationTwo " checked />
                      <label className="form-check-label" for="flexCheckLocationTwo ">
                        Rajasthan <span className="text-muted">(6)</span>
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckLocationThree " />
                      <label className="form-check-label" for="flexCheckLocationThree ">
                        Delhi <span className="text-muted">(5)</span>
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckLocationFour " />
                      <label className="form-check-label" for="flexCheckLocationFour ">
                        Pune <span className="text-muted">(12)</span>
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckLocationFive " />
                      <label className="form-check-label" for="flexCheckLocationFive ">
                        Kolkata <span className="text-muted">(8)</span>
                      </label>
                    </div>

                  </div>
                </div>

              </div>

              <div className="card-body border-top py-3">
                <a className="fs-5 text-dark fw-semi-bold" data-bs-toggle="collapse" href="#collapseExampleThird"
                  role="button" aria-expanded="false" aria-controls="collapseExampleThird">
                  Company Rating <span className="float-end"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                    fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                    <path fillRule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                  </svg></span>
                </a>
                <div className="collapse show" id="collapseExampleThird">
                  <div className="mt-3">
                    <div className="form-check mb-2">
                      <input className="form-check-input" type="radio" name="flexRadiorating" id="flexRadiorating1" />
                      <label className="form-check-label" for="flexRadiorating1">
                        <i className="bi bi-star-fill text-warning"></i>
                        <i className="bi bi-star-fill text-warning"></i>
                        <i className="bi bi-star-fill text-warning"></i> <i className="bi bi-star-fill text-warning"></i>
                        <i className="bi bi-star-fill text-warning"></i>
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input className="form-check-input" type="radio" name="flexRadiorating" id="flexRadiorating2" checked />
                      <label className="form-check-label" for="flexRadiorating2">
                        <i className="bi bi-star-fill text-warning"></i>
                        <i className="bi bi-star-fill text-warning"></i>
                        <i className="bi bi-star-fill text-warning"></i> <i className="bi bi-star-fill text-warning"></i>
                        & above
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input className="form-check-input" type="radio" name="flexRadiorating" id="flexRadiorating3" />
                      <label className="form-check-label" for="flexRadiorating3">
                        <i className="bi bi-star-fill text-warning"></i>
                        <i className="bi bi-star-fill text-warning"></i>
                        <i className="bi bi-star-fill text-warning"></i>
                        & above
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input className="form-check-input" type="radio" name="flexRadiorating" id="flexRadiorating4" />
                      <label className="form-check-label" for="flexRadiorating4">
                        <i className="bi bi-star-fill text-warning"></i>
                        <i className="bi bi-star-fill text-warning"></i>

                        & above
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="flexRadiorating" id="flexRadiorating5" />
                      <label className="form-check-label" for="flexRadiorating5">
                        <i className="bi bi-star-fill text-warning"></i>


                        & above
                      </label>
                    </div>


                  </div>
                </div>

              </div>
              <div className="card-body border-top py-3">
                <a className="fs-5 text-dark fw-semi-bold" data-bs-toggle="collapse" href="#collapseExampleFourth"
                  role="button" aria-expanded="false" aria-controls="collapseExampleFourth">
                  Company Type
                  <span className="float-end"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                    fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                    <path fillRule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                  </svg></span>
                </a>
                <div className="collapse show" id="collapseExampleFourth">
                  <div className="mt-3">
                    <div className="form-check mb-2">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckCompanyFive" />
                      <label className="form-check-label" for="flexCheckCompanyFive">
                        Startup <span className="text-muted">(4,532)</span>
                      </label>
                    </div>
                    <div className="form-check mb-2 ">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckCompanySix " checked />
                      <label className="form-check-label" for="flexCheckCompanySix ">
                        Public <span className="text-muted">(532)</span>
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckCompanySeven " />
                      <label className="form-check-label" for="flexCheckCompanySeven ">
                        Government <span className="text-muted"> (312)</span>
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckCompanyEight " />
                      <label className="form-check-label" for="flexCheckCompanyEight ">
                        Conglomerate <span className="text-muted">(670)</span>
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckCompanyNine " />
                      <label className="form-check-label" for="flexCheckCompanyNine ">
                        Unicorn <span className="text-muted">(88)</span>
                      </label>
                    </div>

                  </div>
                </div>

              </div>
              <div className="card-body py-3 d-grid">
                <a href="#" className="btn btn-outline-secondary">
                  Clear Data
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  </main>
  )
}

export default CompanyList