// import node module libraries
import React, { Fragment, useMemo, useState, useEffect } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Button,
  Image,
  Dropdown,
  Table,
  Container,
  Modal,
  Form,
  Spinner
} from "react-bootstrap";
import Axios from "axios";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import { BsPlayCircle } from "react-icons/bs";

import { ToastContainer, toast } from "react-toastify";

// import custom components
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import DotBadge from "components/elements/bootstrap/DotBadge";
import Url from "components/Url";
import GKYouTube from "../GKYouTube";
import YouTube from "react-youtube";
import Select from "react-select";

const QuizModal = (props) => {
    const {     
        generatedQuiz,
        setGeneratedQuiz,
        showQuizModal,
        setShowQuizModal,
        setLoad,
        courseId
     } = props;
  

    const url1 = Url();
    const url = url1["url"];
  
    //const Detail = [];
  
    const [apiMessage, setApiMessage] = useState("");
    const [apiStatus, setApiStatus] = useState(0);
  
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const admtoken = localStorage.getItem("admtoken");

    const handleDelete = (index) => {
      const updatedQuizData = generatedQuiz.filter((_, i) => i !== index);
      setGeneratedQuiz(updatedQuizData);
    };


    const handleClose = () => {
      setShowQuizModal(false);
      setLoad(true);
    };
  

    const handleSave = (e) => {
      e.preventDefault();
      const form = e.currentTarget;
  
      if (form.checkValidity() === false) {
        e.stopPropagation();
        setCreateValidate(true);
        return;
      }
  
      let apiURL = url + "saveGeneratedQuiz";
  
      try {
        Axios.post(
          apiURL,
          {
            courseId,
            generatedQuiz
          },
          {
            headers: {
              "Content-type": "Application/json",
              Authorization: `Bearer ${admtoken}`,
            },
          }
        ).then(
          (response) => {
            if (response.status === 200) {
              setApiMessage(response.data.message);
              setApiStatus(1);
              setButtonDisabled(true);
              //setLoad(true);
            } else {
              setApiMessage(response.data.message);
              setApiStatus(2);
            }
          },
          (error) => {
            setApiMessage(response.data.message);
            setApiStatus(2);
          }
        );
      } catch (error) {
        console.log(error.response);
  
        if (error.response) {
          // The request was made, but the server responded with an error
          handleErrorResponse(error.response);
        } else if (error.request) {
          // The request was made, but no response was received
          console.error("No response received.");
        } else {
          // Something happened in setting up the request that triggered an error
          console.error("Error:", error.message);
        }
      }
    };

    return (
      <Modal show={showQuizModal} onHide={handleClose} size="lg" fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>Review the questions</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '100vh', overflowY: 'auto' }}>
        <Container>
          <div className="col-12">
            <ul>
              <li>Review the questions</li>
              <li>Delete unwanted questions and then save</li>
              <li>If you close the screen, you have to regenerate the questions.</li>
              <li>Questions can be saved only once. Button will be disabled after save</li>
            </ul>
            </div>

      <div className="mt-3">
        <Row className="mb-3">
          <Col sm={3} xs={6}>
            <Button
              variant="primary"
              onClick={handleSave}
              size="sm"
              disabled={buttonDisabled}
            >
              Save
            </Button>
          </Col>    
          <Row>
            {apiMessage !== "" && (
              <span className={apiStatus ? "text-success " : "text-danger "}>
                {apiMessage}
              </span>
            )}
            {/*apiStatus==1 && <span className="text-success small">Education details updated successfully</span> */}
          </Row>{" "}            
        </Row>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Question</th>
              <th>Option 1</th>
              <th>Option 2</th>
              <th>Option 3</th>
              <th>Option 4</th>
              <th>Correct Option</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {console.log(generatedQuiz)}
            {generatedQuiz?.map((item, index) => (
                <tr key={index}>
                  <td>{index+1}</td>
                  <td>{item.Question}</td>
                  <td>{item.op1}</td>
                  <td>{item.op2}</td>
                  <td>{item.op3}</td>
                  <td>{item.op4}</td>
                  <td>{item.correct_option}</td>
                  <td>
                    <Col className="d-flex">
                      <span
                        onClick={() => handleEdit(item.id, index)}
                        style={{ cursor: "pointer" }}
                      >
                        <PencilSquare />
                      </span>
                      <span
                        onClick={() => handleDelete(index)}
                        className="ms-3"
                        style={{ cursor: "pointer" }}
                      >
                        <Trash />
                      </span>
                    </Col>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      </Container>
        </Modal.Body>
      </Modal>
    );
  };
  
  export default QuizModal;