import React, { Fragment,useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Image,
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import ImgcollegeRegistration from "../../assets/images/college_student.png";
import Url from "components/Url";

const collegeRegistration = () => {
  const url1 = Url();
  const url = url1.url;
  const history = useHistory();

  useEffect(() => {
    const res = window.confirm("This registration is exclusively for colleges, if you are a student then register at student registration page. Do you want to continue registering as college?");
    if (!res) {
      window.location.href = "/";
    }
  }, []);

  const registerCollege = (e) => {
    e.preventDefault();
    let stat;
    const college_name = e.target.name.value;
    const mobileno = e.target.number.value;
    const email = e.target.email.value;
    const password = e.target.password.value;
    // const collegeName = "ACHARYA INSTITUTE OF TECHNOLOGY";
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (
      email.includes(
        "gmail" ||
          "yahoo" ||
          "hotmail" ||
          "rediffmail" ||
          "outlook" ||
          "aol" ||
          "msn" ||
          "live" ||
          "ymail" ||
          "mail" ||
          "free" ||
          ""
      )
    ) {
      toast.warning(
        "Public email not allowed, please use official id to register"
      );
      return;
    }

    if (!passwordRegex.test(password)) {
      toast.error(
        "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one digit, and one special character."
      );
      return;
    }

    const api = url + "registerCollege"; // New API endpoint for college registration
    fetch(api, {
      method: "POST",
      body: JSON.stringify({
        college_name,
        email,
        mobileno,
        password,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((apidata) => {
        stat = apidata.status;
        return apidata.json();
      })
      .then((actualdata) => {
        console.log(stat);
        if (stat === 201 || stat === 200) {
          toast.success("Email is sent, Please verify your account.");
          // Umesh - 20220607 - start
          // Call Zoho CRM API to add college student lead
          const zohoLeadapi = url + "addCollegeStudentLead"; // Update the API endpoint
          fetch(zohoLeadapi, {
            method: "POST",
            body: JSON.stringify({ college_name, email, mobileno }),
            headers: { "Content-Type": "application/json" },
          }).then((apidata) => {
            return apidata.json();
          });
          // Umesh - 20220607 - end
          history.push("/");
        }
        if (stat === 404) {
          toast.warning("Email already exists");
          return;
        }
      });
  };

  return (
    <>
      <ToastContainer />
      <section className="py-8">
        <Container>
          <Row>
            <Col xs={12}>
              <div className="text-center">
                <h1 className="display-5 fw-bold mb-2">
                Register your College at{" "}
                  <span className="text-primary">
                    Internship<span className="text-secondary">gate</span>
                  </span>
                </h1>
                <p className="lead fw-semibold">
                  Get started by registering for free.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mt-lg-8 mt-4 align-items-center">
            <Col
              lg={{ order: 1, span: 6 }}
              xs={{ order: 2, span: 12 }}
              className="text-center text-lg-start"
            >
              <div>
                <Image src={ImgcollegeRegistration} alt="" className="w-100" />
              </div>
            </Col>
            <Col xl={6} xs={12} lg={{ order: 2, span: 6 }}>
              <div className="mx-xl-8 mb-8 mb-lg-0">
                <Card className="shadow border-0">
                  <Card.Body className="p-6">
                    <h2 className="mb-4">Create an account to continue</h2>
                    <Form onSubmit={registerCollege}>
                      <Form.Group className="mb-3" controlId=" college-name">
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="College Name"
                          required
                        />
                      </Form.Group>

                      {/* <Form.Group className="mb-3" controlId="college-name">
                        <Form.Control
                          type="text"
                          name="collegename"
                          placeholder="college Name"
                          required
                        />
                      </Form.Group> */}
                      <Form.Group className="mb-3" controlId="student-email">
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="College Email Id"
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="student-mobile">
                        <Form.Control
                          type="number"
                          name="number"
                          placeholder="Mobile Number"
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="student-password">
                        <Form.Control
                          type="password"
                          name="password"
                          placeholder="Password"
                          required
                        />
                      </Form.Group>
                      <div className="mb-4">
                        <small>
                          By registering, you agree to our{" "}
                          <Link to="#">Terms and Conditions</Link>.
                        </small>
                      </div>
                      <div className="d-grid">
                        <Button variant="primary" type="submit">
                          Register as college
                        </Button>
                      </div>
                    </Form>
                    {/* <div className="mt-3">
                                          <span>Already registered? <Link to="#">Login</Link></span>
                                      </div> */}
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default collegeRegistration;
