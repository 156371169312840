import React, { useEffect, useRef, useState, createRef } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  Svg,
  Path,
  Circle,
  Polyline,

} from "@react-pdf/renderer";
import { Html } from "react-pdf-html";
import {
  useA4Scale,
  TemplatePage,
  renderHTMLContent,
  getDisplayDate,
} from "../../utils";
import { Row, Col } from "react-bootstrap";
import DummyProfilePic from "../../../../../assets/images/background/profilepic.png";
import { FiPhone } from "react-icons/fi";
import { isEmpty } from "../../../foundation";
import { green } from "@material-ui/core/colors";

Font.registerHyphenationCallback((word) => {
  // Return entire word as unique part
  return [word];
});

const styles = StyleSheet.create({
  page: {
    display: "flex",
    fontFamily: "Roboto",
     paddingVertical: "30px",
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  left: {
    width: "228px",
  },
  right: {
    padding: "3px 20px 25px 15px", //26px 30px 0px 20px
    width: "367px",
  },
  contentPadding: {
    paddingLeft: "30px",
    paddingRight: "54px",
  },
  // profileImageWrapper: {
  //   display: "flex",
  //   alignItems: "center",
  //   padding: "30px",
  // },
  // profileImage: {
  //   width: "122px",
  //   height: "122px",
  //   borderRadius: "50%",
  //   border: "1px solid #3644a1",
  // },
});

const ContactInfo = ({
  data: { Phone, Address, City, Postal_Code, display_email,template_color_code },
}) => (
  <View
    style={{
      // padding: " 32px",
      backgroundColor: "white",
      height: "160px",
      paddingLeft: "32px",
      paddingTop: "15px",
    }}
  >
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        //alignItems: "center",
        // marginBottom: "5px",
        marginTop: "10px",
      }}
    >
      <View
        style={{
          backgroundColor:template_color_code,
          paddingTop: "5px",
          // borderRadius: "50%",
          //flex: "0 0 16px",
          
          paddingRight: "5px",
          height: "22px",
          width: "33px",
        }}
      >
        <Svg
          stroke="#ffffff"
          fill="#000000"
          strokeWidth="2"
          viewBox="0 0 20 20"
          strokeLinecap="round"
          strokeLinejoin="round"
          // height="15px"
          // width="17px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></Path>
          <Polyline points="22,6 12,13 2,6"></Polyline>
        </Svg>
      </View>
      <Text
        style={{
          color: "#000000",
          fontSize: "10px",
          paddingLeft: "5px",
          marginTop: "5px",
          //paddingRight: "25px",
          width:"27px",
        }}
      >
        {display_email}
      </Text>
    </View>

    <View
      style={{
        display: "flex",
        flexDirection: "row",
        //alignItems:"center",
        // marginBottom: "5px",
      }}
    >
      <View
        style={{
          backgroundColor:template_color_code,
          paddingTop: "5px",
          // borderRadius: "50%",
          //flex: "0 0 16px",
          height: "20px",
          width: "33px",
        }}
      >
        <Svg
          stroke="currentColor"
          fill="#fffffff"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          // height="12px"
          // width="15px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></Path>
        </Svg>
      </View>
      <Text
        style={{
          color:"#000000",
          fontSize: "10px",
          paddingLeft: "10px",
          paddingRight: "10px",
          marginTop: "5px",
          width:"35px",
        }}
      >
        {Phone}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        //alignItems: "center",
        // marginTop: "10px",
        paddingBottom: "5px",
      }}
    >
      <View
        style={{
          backgroundColor:template_color_code,
          paddingTop: "5px",
          // borderRadius: "50%",
          paddingLeft: "5px",
          //flex: "0 0 16px",
          //paddingBottom: "5px",
          height: "30px",
          width: "35px",
        }}
      >
        <Svg
          stroke="#ffffff"
          fill="#000000"
          strokeWidth="2"
          viewBox="0 0 30 30"
          strokeLinecap="round"
          strokeLinejoin="round"
          // height="16px"
          // width="17px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></Path>
          <Circle fill="#f1bb1a" cx="12" cy="10" r="3"></Circle>
        </Svg>
      </View>
      <Text
        style={{
          color: "#000000", //#ffffff
          fontSize: "9px",
          paddingLeft: "10px",
          paddingRight: "1px",
          marginTop: "5px",
        }}
      >
        {`${Address || ""} ${City || ""} ${Postal_Code || ""}`.trim()}
      </Text>
    </View>
  </View>
);

const Skills = ({ data: { skills } }) => {
  if (!skills || !skills.length) {
    return <View />;
  }
  return (
    <View
      style={{
        padding: "8px 20px",
        marginTop: "4px",

        // border: "2px solid blue",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontWeight: "bold",
          fontSize: "20px",
          marginBottom: "10px",
          letterSpacing: "1.95px",
          fontstretch: "normal",
        }}
      >
        SKILLS
      </Text>
      {skills.map((s) => (
        <View
          style={{
            display: "flex",

            //flexDirection: "row",
            justifyContent: "space-between",
          }}
          wrap={false}
        >
          <Text
            style={{
              color: "#000000",
              fontSize: "15px",
              paddingBottom: "5px",
              fontWeight: "bold",
            }}
          >
            {s.skill_name}
          </Text>
          <View
            style={{
              backgroundColor: "#000000",
              width: "189px",
              height: "6px",
              marginBottom: "5px",
              borderRadius: "50px",
              // marginRight: "30%",
              marginTop: "5px",
            }}
          >
            <View
              style={{
                backgroundColor: "#ffffff",
                borderRadius: "50px",
                height: "6px",
                width: `${s.level * 63}px`,
                // color: "white",
              }}
            ></View>
            
          </View>
        </View>
      ))}
    </View>
  );
};

const Hobbies = ({ data: { hobbies } }) => {
  if (!hobbies || !hobbies.length) {
    return <View />;
  }
  return (
    <View
      style={{
        padding: "8px 20px",
        marginTop: "15px",

        // border: "2px solid #D3D3D3"
      }}
    >
      <Text
        style={{
          color: "#000000",
          //textDecoration: "underline",
          fontSize: "20px",
          fontWeight: "bold",
          letterSpacing: "1.95px",
          marginBottom: "10px",
        }}
      >
        HOBBIES
      </Text>

      {hobbies.map((s) => (
        <Text
          style={{
            color: "#000000",
            fontSize: "14px",
            paddingBottom: "10px",
          }}
        >
          {s.hobbies}
        </Text>
      ))}
    </View>
  );
};
{/*
const Languages = ({ data: { languages } }) => {
  if (!languages || !languages.length) {
    return <View />;
  }
  return (
    <View
      style={{
        padding: "20px 32px",
        paddingLeft: "1px",
        paddingRight: "50%",
        // border: "2px solid yellow"
      }}
      wrap={false}
    >
      <Text
        style={{
          color: "#242732",
          //textDecoration: "underline",
          fontSize: "20px",
          fontWeight: "bold",
          marginBottom: "10px",
          letterSpacing: "1.95px",
        }}
      >
        LANGUAGES
      </Text>
      {languages.map((s) => (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              color: "#000000",
              fontSize: "15px",
              fontWeight: "bold",
              paddingBottom: "10px",
              // paddingRight: "5px",
            }}
          >
            {s.language_name}
          </Text>
          <View
            style={{
              backgroundColor: "#999999",
              width: "78px",
              height: "5px",
              marginBottom: "10px",
              borderRadius: "50px",
            }}
          >
            <View
              style={{
                backgroundColor: "#10edd2",
                borderRadius: "50px",
                height: "5px",
                width: `${s.level * 19.5}px`,
                // color: "white",
              }}
            ></View>
          </View>
        </View>
      ))}
    </View>
  );
};
*/}

const Languages = ({ data: { languages } }) => {
  if (!languages || !languages.length) {
    return <View />;
  }
  return (
    <View style={{ paddingLeft:"52px", marginTop: "15px",fontSize:"15px", }}>
      <Text
        style={{
          color: "#000000",
          fontWeight: 700,
          fontSize: "14px",
          marginBottom: "10px",
          paddingLeft: "-35px",
        }}             
      >
        LANGUAGES
      </Text>
      {languages.map((s) => (
        <Text
          style={{
            color: "#14141f",
            fontSize: "15px",
            paddingBottom: "10px",
            paddingLeft: "5px",
            paddingLeft: "-35px",
          }}
        >
          {s.language_name}
        </Text>
      ))}
    </View>
  );
};
const References = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const { title, subTitle, startDate, endDate, phoneNo, emailId, description } =
    fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View
      style={{
        padding: "8px 20px",
        marginTop: "10px",
      }}
    >
      <Text
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          color: "#000000",
          letterSpacing: "1.95px",
          //textDecoration: "underline",
        }}
      >
        {heading}
      </Text>
      {items.map((item) => (
        <View style={{ marginTop: "15px" }}>
          <Text
            style={{ fontSize: "11px", fontWeight: "500", color: "#000000" }}
          >
            {item[title]}
          </Text>
          <Text
            style={{ fontSize: "11px", color: "#000000", marginTop: "3px" }}
          >
            {item[subTitle]}
          </Text>
          <Text
            style={{ fontSize: "10px", color: "#000000", marginTop: "3px" }}
          >
            {getDisplayDate(item[startDate], item[endDate])}{" "}
            {item[location] && `, ${item[location]}`}
          </Text>

          <Text
            style={{ fontSize: "10px", color: "#000000", marginTop: "3px" }}
          >
            {item[phoneNo]}
          </Text>
          <Text
            style={{ color: "#000000", marginTop: "3px", fontSize: "10px" }}
          >
            {item[emailId]}
          </Text>
          {item[description] && (
            <View style={{ marginTop: "5px"}}>
              {renderHTMLContent(item[description], 
              {
                "*": { color: "#000000", },
              })}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

const AdditionalInfo = ({
  data: {
    // Address,
    // City,
    // Postal_Code,
    Country,

    Driving_License,
    // Nationality,
    dob,
    Place_of_Birth,
  },
}) => {
  const details = [
    {
      label: "Country",
      value: Country,
    },
    {
      label: "Driving License",
      value: Driving_License,
    },
    // {
    //   label: "Nationality",
    //   value: Nationality,
    // },
    {
      label: "Date of Birth",
      value: dob,
    },
    {
      label: "Place of Birth",
      value: Place_of_Birth,
    },
  ];
  return details.map((info) =>
    info.value ? (
      <View
        style={{
          padding: "8px 20px",
          //paddingLeft: "42px",
        }}
      >
        <Text
          style={{
            color: "#000000",
            fontWeight: 700,
            fontSize: "11px",
            paddingBottom: "4px",
          }}
        >
          {info.label}
        </Text>
        <Text
          style={{
            color: "#000000",
            fontSize: "10px",
          }}
        >
          {info.value}
        </Text>
      </View>
    ) : (
      <View />
    )
  );
};

const RenderView = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const {
    title,
    subTitle,
    startDate,
    endDate,
    description,
    location = "city",
  } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View style={{ marginTop: "22px" }}>
      <Text
        style={{
          fontSize: "20px",
          fontWeight: "700",
          letterSpacing: "1.95px",
          color: "#242732",
        }}
      >
        {heading}
      </Text>
      {items.map((item) => (
        <View style={{ marginTop: "12px" }} wrap={true}>
          <Text style={{ fontSize: "11px", fontWeight: "500" }}>
            {item[title]}
          </Text>
          <Text
            style={{
              textAlign: "right",
              fontSize: "10px",
              color: "#363434",
            }}
          >
            {getDisplayDate(item[startDate], item[endDate])}{" "}
          </Text>
          <Text
            style={{ fontSize: "11px", color: "#242732", marginTop: "3px" }}
          >
            {item[subTitle]}
          </Text>
          <Text
            style={{
              fontSize: "10px",
              color: "#363434",
              marginTop: "3px",
            }}
          >
            {item[location] && `${item[location]}`}
          </Text>
          {item[description] && (
            <View style={{ marginTop: "5px"}}>
              {renderHTMLContent(item[description], {
                "*": { color: "#000000" ,paddingRight:"8px",textAlign:"justify",},
              })}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};


const CustomSection = ({ data, dataKey, heading, fieldMap = {} }) =>{
  const items=data[dataKey];
  console.log("data key", data[dataKey]);
  const {title, city, startDate, endDate, description,custom_title }=fieldMap;
    if(!items||!items.length){
       return <View/>;
    }
    const {
      template_color_code,
    }=data;
    return (
      <View
        style={{
          marginBottom: "15px",
          paddingTop:"10px",
          paddingLeft:"-15px",
        }}
      >
        <Text
          style={{
            fontSize: "16px",
            fontWeight: 600,
             color:"#000000",
          }}
        >
          {heading}
        </Text>
        {items.map((item) => (
          
          <View style={{ marginBottom: "8px" }} wrap={true}>
            
            <View
              style={{
                fontSize: "20px",
                fontWeight: "900",
                color: "#000000",
                paddingLeft:"-15px",
              }}
            >
              <Text>{item[custom_title]}</Text>
              
            </View>
            <View
              style={{
                fontSize: "13px",
                fontWeight: "500",
                marginTop: "10px",
              }}
            >
              <Text>{item[title]}</Text>
            </View>
            <Text
              style={{
                fontSize: "10px",
                color: "#363434",
                marginTop: "3px",
                marginBottom: "4px",
              }}
            >
              {getDisplayDate(item[startDate], item[endDate])}
  
              {item[city] ? ` ,  ${item[city]}` : ""}
              
            </Text>

            {item[description] && (
              <View 
                style={{
                  marginTop: "4px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                }}
              >
              {console.log(dataKey, item[description])}

                {renderHTMLContent(item[description], {
                  "*": {
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "12px",
                    paddingRight: "5px",
                    alignItems: "baseline",
                    position: "sticky",
                    textAlign: "justify",
                  },
                })}
              </View>
            )}
          </View>
        ))}
      </View>
    );
};
const template15 = ({ data }) => {
  const {
    //profileImage,
    First_Name,
    Last_Name,
    Wanted_Job_Title,
    Nationality,
    template_color_code,
    professionalSummary: { Description = "" } = {},
  } = data;
  return (
    <TemplatePage data={data}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={{
              position: "absolute",
              height: "100vh",
              width: "164px",
              left: "0px",
              top: "0px",
            }}
            fixed
          >
            <View
              style={{
                height: "100%",
                width: "228px",
                backgroundColor:template_color_code,
              }}
            ></View>
          </View>
          <View style={styles.wrapper}>
            <View style={styles.left}>
              {/* <View style={styles.profileImageWrapper}>
                <Image
                  src={profileImage || DummyProfilePic}
                  style={styles.profileImage}
                />
              </View> */}

              <ContactInfo data={data} />
              <AdditionalInfo data={data} />

           
              <Skills data={data} />
              <Languages data={data} />
              <References
                data={data}
                dataKey="References"
                heading="REFERENCES"
                fieldMap={{
                  title: "ref_name",
                  subTitle: "company_name",
                  phoneNo: "phone_no",
                  emailId: "ref_email",
                }}
              />
              <Hobbies data={data} />
            </View>
            <View style={styles.right}>
              <View>
                <View
                  style={{
                    backgroundColor: "#ffffff",
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "5px",
                    paddingTop: "10px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "38px",
                      fontWeight: 30,
                      color:template_color_code,
                      marginTop: "8px",
                      marginLeft: "30px",
                    }}
                  >
                    {First_Name} {Last_Name}
                    {/* YOUR NAME */}
                  </Text>

                  <Text
                    style={{
                      fontSize: "18px",
                      color:template_color_code,
                      marginTop: "3px",
                      marginLeft: "30px",
                      fontWeight: "700",
                      marginBottom: "6px",
                      width: "200px",
                    }}
                  >
                    {Wanted_Job_Title}
                    {/* CURRENT JOB TITLE */}
                  </Text>

                 {/* <Text
                    style={{
                      lineHeight: "2",
                      fontWeight: "600",
                      marginLeft: "30px",
                      color: "#000000",
                      // textDecoration: "underline",
                    }}
                  >
                    {Nationality}
                  </Text>
                  */}
                  {/* {renderHTMLContent(
                    `<div style="text-align : center"> <span class="first" >${First_Name} </span>  <span class="second">${Last_Name}</span> </div>`,
                    {
                      ".first": {
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                        fontSize: "20px",
                        // margin: "0px 14px 0 -2px",
                        color: "#242732",
                        letterSpacing: "3.75px",
                        width: "100px",
                        border: "2px solid #D3D3D3",
                      },
                      ".second": {
                        display: "inline-block",
                        fontWeight: "normal",
                        fontFamily: "Roboto",
                        fontSize: "20px",
                        color: "#242732",
                        letterSpacing: "3.75px",
                        // margin: "0 20px 0 30px",
                        paddingLeft: "4px",
                      },
                    }
                  )} */}
                </View>

                {/* <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // justifyContent: "space-between",
                    border: "2px solid #333",
                    padding: "20px",
                    width: "300px",
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      // border: "2px solid #D3D3D3",
                      marginRight: "4px",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                        fontSize: "20px",
                        margin: "0px 14px 0 -2px",
                        color: "#242732",
                        letterSpacing: "3.75px",
                        width: "100px",
                      }}
                    >
                      {First_Name.length <= 10 ? First_Name : `${First_Name} `}
                      
                    </Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontWeight: "normal",
                        fontFamily: "Roboto",
                        fontSize: "20px",
                        color: "#242732",
                        letterSpacing: "3.75px",
                        margin: "0 20px 0 30px",
                        width: "120px",
                      }}
                    >
                      {First_Name.length > 10
                        ? `  
                      ${Last_Name}`
                        : Last_Name}
                    </Text>
                  </View>
                </View> */}
                {/* <Text
                  style={{
                    position: "relative",
                    fontWeight: "normal",
                    fontSize: "10px",
                    fontSize: "14px",
                    fontFamily: "Roboto",
                    letterSpacing: "1.5px",
                    textAlign: "center",
                    color: "#333",
                    bottom: "8px",
                    backgroundColor: "white",
                    width: "120px",
                    margin: "0 80px 0 80px",
                  }}
                >
                  {Wanted_Job_Title}
                </Text> */}
              </View>
              {!isEmpty(Description) && (
                <View style={{ marginTop: "35px" }}>
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      letterSpacing: "1.95px",
                      color: "#242732",
                      marginBottom: "3px",
                      
                    }}
                  >
                    PROFILE
                  </Text>
                  {renderHTMLContent([Description], 
                    {
                      "*":{paddingRight:"8px",textAlign:"justify"},
                    })}
                </View>
              )}
                 
              <RenderView
                data={data}
                dataKey="employmentHistory"
                heading="EXPERIENCE"
                fieldMap={{
                  title: "Job_title",
                  subTitle: "Employer",
                  startDate: "start_date",
                  endDate: "end_date",

                  description: "Description",
                }}
              />

              <RenderView
                data={data}
                dataKey="internships"
                heading="INTERNSHIPS"
                fieldMap={{
                  title: "employer_name",
                  subTitle: "job_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "description",
                }}
              />
               <RenderView
                data={data}
                dataKey="education"
                heading="EDUCATION"
                fieldMap={{
                  title: "institute_name",
                  subTitle: "degree",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "Description",
                }}
              />
        
              <RenderView
                data={data}
                dataKey="activities"
                heading="EXTRA_CURRICULAR_ACTIVITY"
                fieldMap={{
                  title: "employer_name",
                  subTitle: "function_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "description",
                }}
              />
              <CustomSection
              data={data}
                dataKey="customSection"
                //heading="custom_title"
                fieldMap={{
                  custom_title:"custom_title",
                  title: "section_name",
                  city: "city",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "description",
                }}
              />
            
              <RenderView
                data={data}
                dataKey="courses"
                heading="COURSES"
                fieldMap={{
                  title: "course_name",
                  subTitle: "institute_name",
                  startDate: "start_date",
                  endDate: "end_date",
                }}
              />
            </View>
          </View>
        </Page>
        {/* <View
          style={{
            marginTop: "16px",
            Color: "#ffffff",
            //margin: "44px 0 0",
          }}
        >

        </View> */}
      </Document>
    </TemplatePage>
  );
};

template15.displayName = "Template15";
export default template15;
