import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
//import Col from "react-bootstrap/Col";
//import Row from "react-bootstrap/Row";
import {
  Form,
  Col,
  Row,
  Card,
  Image,
  Modal,
  FormSelect,
} from "react-bootstrap";
import Url from "../../Url";
//import { FormSelect } from 'components/elements/form-select/FormSelect';
import CropImageDialog from "components/ProfileCrop/CropImageDialog";
import { toast } from "react-toastify";

const BasicDetails = (props) => {
  const {
    studentBasic,
    studentMobile,
    locationList,
    employerView,
    setLoadData,
    studentStatus
  } = props;

  const url1 = Url();
  const url = url1["url"];
  const s3URL = url1["s3"];
  // const [after, setAfter] = useState(false)

  const [applicantId, setApplicantId] = useState(studentBasic.id);
  const [title, setTitle] = useState(studentBasic.title);
  const [studentName, setStudentName] = useState(studentBasic.name);
  const [phoneNo, setPhoneNo] = useState(studentMobile);
  const [email, setEmail] = useState(studentBasic.email);
  const [location, setLocation] = useState(studentBasic.location);
  const [address, setAddress] = useState(studentBasic.address);
  const [expYear, setExpYear] = useState(studentBasic.exp_year);
  const [expMonth, setExpMonth] = useState(studentBasic.exp_month);
  const [validated, setValidated] = useState(false);
  const [internship_interest_status, setInternshipInterestStatus] = useState(studentBasic.internship_interest_status);

  const [editMode, setEditMode] = useState(null);
  const [showBasicProfileUpdateStatus, setShowBasicProfileUpdateStatus] =
    useState(-1);

  const [showProfileImageDialog, setShowProfileImageDialog] = useState(false);
  //   const [previewpic, setPreviewpic] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const salutationOptions = [
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Miss", label: "Miss" },
    { value: "Ms.", label: "Ms." },
  ];

  const yearList = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
  ];

  const monthList = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
  ];

  useEffect(() => {
    setApplicantId(studentBasic.id);
    setTitle(studentBasic.title);
    setStudentName(studentBasic.name);
    setPhoneNo(studentMobile);
    setEmail(studentBasic.email); 
    setPhoneNo(studentMobile);
    setEmail(studentBasic.email);
    setLocation(studentBasic.location);
    setAddress(studentBasic.address);
    setExpYear(studentBasic.exp_year);
    setExpMonth(studentBasic.exp_month);
    setInternshipInterestStatus(studentBasic.internship_interest_status);
  }, [studentBasic]);

  const handleCheckboxChange = (e) => {
    setInternshipInterestStatus(e.target.checked ? 1 : 0);
  };
  
  const saveBasicDetails = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    if (!studentName || !title || !location || !phoneNo) {
      setShowBasicProfileUpdateStatus(-1);
      alert("All fields are mandatory!");
      return;
    }

    const var1 = JSON.stringify({
      applicantId,
      title,
      name: studentName,
      phoneNo,
      email,
      location,
      address,
      exp_year: expYear,
      exp_month: expMonth,
      internship_interest_status
    });
    console.log(var1);

    fetch(url + "updateApplicantBasicDetail", {
      method: "POST",
      body: JSON.stringify({
        applicantId,
        title,
        name: studentName,
        phoneNo,
        email,
        location,
        address,
        exp_year: expYear,
        exp_month: expMonth,
        internship_interest_status,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((op) => {
        return op.json();
      })
      .then((op1) => {
        setShowBasicProfileUpdateStatus(op1.status);
        setErrorMessage(op1.message);
        setEditMode(false);
        //setAfter(false);
      });

    //Umesh - 20220620 - start
    //call zoho CRM API to update student profile in CRM
    /*       
        const zohoLeadapi = url + "updateStudentProfileInCRM";
        //this is for debug purpose
        const apiCallingLocation = "EditResume";
        fetch(zohoLeadapi, {
            method: "POST",
            body: JSON.stringify({ email, apiCallingLocation }),
            headers: { "Content-Type": "application/json" },
        }).then((apidata) => {
            return apidata.json();
        });
        //Umesh - 20220620 - end

        //history.push("/StuResume")
        //window.location.href = "/StuResume"
        */
  };

  const picSubmit = async (data) => {
    const pic = studentBasic.pic;
    const x = pic === undefined ? "not-found" : pic;
    var logo;
    const imgFile = await fetch(data).then((r) => r.blob());
    //console.log(imgFile);
    const formData = new FormData();
    formData.append("pic", imgFile);
    fetch(url + "uploadApplicantProfilePicture", {
      method: "POST",
      body: formData,
    })
      .then((op) => {
        return op.json();
      })
      .then((op1) => {
        return op1;
      })
      ///////////////////////////
      .then((op2) => {
        //console.log(op2["message"]);
        if (op2["message"] !== "not-found") {
          var logo = op2["message"];
        } else {
          logo = x;
        }
        const pic = logo;
        //console.log(pic);

        const api = url + "updateApplicantPictureById";
        fetch(api, {
          method: "POST",
          body: JSON.stringify({ pic, applicantId }),
          headers: { "Content-Type": "application/json" },
        })
          .then((op) => {
            return op.json();
          })
          .then((op1) => {
            //console.log(op1)
            if (op1["message"] === "picture_updated_successfully") {
              setLoadData(true);
              setShowProfileImageDialog(false);
              //setFile(null);
              toast.success("Profile picture uploaded successfully....", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
              });
            }
          });
      });
  };
  return (
    <Fragment>
      <Card className="border-0 mb-2">
        <Card.Header>
          <div className="d-flex align-items-end">
            <div className="position-relative">
              <Image
                src={
                  studentBasic.pic
                    ? s3URL + "stuprofilepicupload/" + studentBasic.pic
                    : s3URL + "Account.svg"
                }
                alt=""
                className="avatar avatar-xl rounded-circle"
              />

              {!employerView && (
                <i
                  className={
                    "fe fe-edit-2 fs-5 bg-primary text-white p-2 rounded-circle"
                  }
                  style={{
                    position: "absolute",
                    bottom: -5,
                    right: -5,
                    cursor: "pointer",
                  }}
                  onClick={() => setShowProfileImageDialog(true)}
                ></i>
              )}
            </div>
            <div className="ps-2">
              <h3 className="text-capitalize">
                {title} {studentName}
              </h3>
              {(employerView && studentStatus == "Not Shortlisted") ? "x".repeat(7) + email?.substring(7) : email}
              <br />
              {(employerView && studentStatus == "Not Shortlisted") ? "x".repeat(7) + phoneNo?.substring(7) :phoneNo}
            </div>
            {!editMode && (
              <div className={"ms-auto mb-1" + (employerView && " d-none")}>
                <Link to="#" className="me-1" title="Edit">
                  <i
                    className="fe fe-edit fs-3"
                    onClick={() => setEditMode(true)}
                  ></i>
                </Link>
              </div>
            )}

            <Modal
              show={showProfileImageDialog}
              onHide={() => setShowProfileImageDialog(false)}
              className=""
            >
              <CropImageDialog
                close={() => setShowProfileImageDialog(true)}
                setCroppedImage={picSubmit}
              />
            </Modal>
          </div>
          <div className="col-12  pt-2">
            {(employerView && studentStatus == "Not Shortlisted") && (<span className="small text-primary blockquote-footer"><em>Shortlist the student to view the email and mobile number</em></span>)}
            </div>          
        </Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={saveBasicDetails}>
            <Row className="mb-3">
              {editMode && (
                <Fragment>
                  <Col md={3}>
                    <span className="text-dark">*Name:</span>
                  </Col>
                  <Col md={9}>
                    <Row>
                      <Col md={5} xs={12} className="mb-3">
                        <Form.Control
                          as={FormSelect}
                          placeholder="Title"
                          name="salutation"
                          defaultValue={title}
                          onChange={(e) => setTitle(e.target.value)}
                          required
                          //options={salutationOptions}
                        >
                          {salutationOptions.map((item, index) =>
                            index == 0 ? (
                              <>
                                <option value="" key={index}>
                                  Select Title
                                </option>
                                <option value={item.value} key={index + 1}>
                                  {item.label}
                                </option>
                              </>
                            ) : (
                              <option value={item.value} key={index + 1}>
                                {item.label}
                              </option>
                            )
                          )}
                        </Form.Control>
                      </Col>
                      <Col md={7} xs={12} className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Full Name"
                          id="name"
                          name="name"
                          value={studentName}
                          onChange={(e) => setStudentName(e.target.value)}
                          required
                          isValid={validated && studentName}
                          isInvalid={validated && !studentName}
                        />
                        <Form.Control.Feedback type="valid">
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please Enter Your Name.
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                  </Col>
                </Fragment>
              )}
            </Row>

            {editMode && (
              <Row className="mb-3">
                <Col md={3}>
                  <span className="text-dark">*Mobile:</span>
                </Col>
                <Col md={9}>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="phoneNo"
                      id="phoneNo"
                      name="phoneNo"
                      value={phoneNo}
                      maxLength={10}
                      onChange={(e) => setPhoneNo(e.target.value)}
                      pattern="^[0-9]{10}$"
                      required
                      isInvalid={
                        validated && phoneNo && !phoneNo.match(/^[0-9]{10}$/)
                      }
                    />
                    <Form.Control.Feedback type="valid">
                      Looks good!
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid 10-digit mobile number.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            )}

            <Row className="mb-3">
              <Col md={3}>
                <span className="text-dark">*Location:</span>
              </Col>
              <Col md={9}>
                {editMode ? (
                  <Form.Group>
                    <Form.Control
                      as={FormSelect}
                      placeholder="Location"
                      name="location"
                      defaultValue={location}
                      onChange={(e) => setLocation(e.target.value)}
                      required
                      isInvalid={validated && !location}
                      //options={salutationOptions}
                    >
                      {locationList.map((item, index) =>
                        index == 0 ? (
                          <>
                            <option value="" key={index}>
                              Select location
                            </option>
                            <option value={item.placename} key={index + 1}>
                              {item.placename}
                            </option>
                          </>
                        ) : (
                          <option value={item.placename} key={index + 1}>
                            {item.placename}
                          </option>
                        )
                      )}
                    </Form.Control>
                    <Form.Control.Feedback type="valid">
                      Looks good!
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please select your location.
                    </Form.Control.Feedback>
                  </Form.Group>
                ) : (
                  <span className="">{location}</span>
                )}
              </Col>
            </Row>
            
            <Row className="mb-3">
              <Col md={3}>
                <span className="text-dark">Address:</span>
              </Col>
              <Col md={9}>
                {editMode ? (
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Address"
                      id="address"
                      name="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <Form.Control.Feedback type="valid">
                      Looks good!
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter your address.
                    </Form.Control.Feedback>
                  </Form.Group>
                ) : (
                  <span className="">{address}</span>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={3}>
                <span className="text-dark" style={{ fontSize:"13px"}}>Experience</span>
              </Col>
              <Col md={9}>
                {editMode ? (
                  <Row>
                    <Col md={5} xs={12} className="mb-3">
                      <Form.Control
                        as={FormSelect}
                        placeholder="Years"
                        name="exp_years"
                        defaultValue={expYear}
                        onChange={(e) => setExpYear(e.target.value)}
                      >
                        {yearList.map((item, index) => (
                          <option value={item.value} key={index}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col md={5} xs={12} className="mb-3">
                      <Form.Control
                        as={FormSelect}
                        placeholder="Months"
                        name="exp_months"
                        defaultValue={expMonth}
                        onChange={(e) => setExpMonth(e.target.value)}
                      >
                        {monthList.map((item, index) => (
                          <option value={item.value} key={index}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Row>
                ) : (
                  <span className="">
                    {" "}
                    {expYear ? expYear + " Year" : null}
                    {expMonth ? " " + expMonth + " Months" : null}
                  </span>
                )}
              </Col>
            </Row>
            {!employerView &&
            <>
            <hr />
            <Row className="mb-3">
              <Col md={9}>
                <span className="text-dark h4">Actively looking for internships?</span>
              </Col>
              <Col md={3}>
                {editMode ? (
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="checkbox"
                      id="internship_interest_status"
                      name="internship_interest_status"
                      checked={internship_interest_status === 1}
                      onChange={handleCheckboxChange}
                    />
                  </Form.Group>
                ) : (
                  <span className="h3">{internship_interest_status===1 ? "Yes" : "No"}</span>
                )}
              </Col>
              <Col><span className="text-danger"><em>Mandatory to check above to receive internships from employers</em></span></Col>
            </Row>        
            </>
            }    
            {editMode && (
              <div className="mb-3 d-inline">
                <button type="submit" className="btn btn-primary btn-sm">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-outline-warning btn-sm ms-2"
                  onClick={() => setEditMode(false)}
                >
                  cancel
                </button>
              </div>
            )}
            <Row>
              {/* {after && !studentName && <span>Name is require</span>}
                {after && !studentMobile && <span>Mobile is require</span>}
                {after && !location && <span>Location is require</span>}
                {after && !address && <span>Address is require</span>} */}
              {
                <span className="text-danger fw-bold p-3">
                  {!title && <span>Title, </span>}
                  {!studentName && <span>Name, </span>}
                  {!address && <span>Address, </span>}
                  {!phoneNo && <span>Phone Number, </span>}
                  {!location && <span>Location </span>}
                  {(!title ||
                    !location ||
                    !studentName ||
                    !address ||
                    !phoneNo) && <span> Required!</span>}
                </span>
              }
            </Row>
            <Row>
              {showBasicProfileUpdateStatus == 0 && (
                <span className="text-danger small">
                  {errorMessage
                    ? errorMessage
                    : "Profile update failed, please try later"}
                </span>
              )}
              {showBasicProfileUpdateStatus == 1 && (
                <span className="text-success small">
                  Profile updated successfully
                </span>
              )}
              {showBasicProfileUpdateStatus == 3 && (
                <span className="text-success small">
                  Profile phot updated successfully
                </span>
              )}
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default BasicDetails;
