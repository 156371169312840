// import node module libraries
import { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Image,
  Toast,
  Tab,
  Tabs,
} from "react-bootstrap";

// import media files
import Logo from "assets/images/brand/logo/logo-icon.svg";

import Url from "../Url";
import LoginModalNew from 'components/authentication/LoginModalNew';
import { ToastContainer, toast } from 'react-toastify';

const StudentLogin = () => {
  //Internshipgate - Authentication Request- start
  const url1 = Url();
  const url = url1["url"];
  const s3 = url1["s3"];

  const [s3url, setS3url] = useState(s3 + "imgupload/");

  const [enablelogin, setEnablelogin] = useState(false);
  const [show, setShow] = useState(false);
  sessionStorage.setItem("showTab","employer");
  const history = useHistory();
  const [showTab,setShowTab] = useState("");
  const [modalShow, setmodalShow] = useState(true);
  /*
  useEffect(() => {

    const empToken = localStorage.getItem("emptoken");
    if(empToken){
      const token1 = localStorage.getItem("token1");
      const token2 = localStorage.getItem("token2");
      if(token1 && token2) window.location.href="http://localhost:8001/Employer/" + token2 +"/" + token1 +"/" + empToken;  
      else localStorage.clear();
    }
    

  },[]);
  */

  const handleSubmit = (event) => {
    event.preventDefault();

    const email = event.target.email.value;
    const password = event.target.password.value;

    const api = url + "login";
    fetch(api, {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: { "Content-Type": "application/json" },
    })
      .then((apidata) => {
        //console.log(apidata)
        return apidata.json();
      })
      .then((actualdata) => {
        if (
          actualdata.message === "Email not verified, please verify your email"
        ) {
          alert(actualdata.message);
          return;
        }
        console.log(actualdata);
        const success = actualdata["message"];
        const applicant_id = actualdata["applicant_id"];
        const student_id = actualdata["id"];
        const studentToken = actualdata["token"];

        if (success === "logged-in") {
          console.log(actualdata);

          localStorage.setItem("stutoken", studentToken);
          localStorage.setItem("email", email);
          localStorage.setItem("applicantid", applicant_id);
          localStorage.setItem("studentId", student_id);
          localStorage.setItem("studentName", actualdata.fullname);
          localStorage.setItem("token1", actualdata.token1);
          localStorage.setItem("token2", actualdata.token2);
          console.log(actualdata.studentStatus);
          if(actualdata.studentStatus == '1'){
            localStorage.removeItem("profileStatus");
          }else{
            localStorage.setItem("profileStatus", '0');
          }
          setEnablelogin(true);
        }else{
          alert("Invalid Email or password");
        }
      });
  };

  const handleEmployerSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    const controller = "employerlogin";
    const api = url + controller;
    fetch(api, {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: { "Content-Type": "application/json" },
    })
      .then((apidata) => {
        return apidata.json();
      })
      .then((actualdata) => {
        if (
          actualdata.message === "Email not verified, please verify your email"
        ) {
          alert(actualdata.message);
          return;
        }
        if (actualdata["message"] === "logged-in") {
          //localStorage.setItem("emptoken", "33vsefkjevkfk6546kkrguk" + Math.random() * 1000 + "546vfjvfefejyewrhfnb2164hhdfh");
          localStorage.setItem("emptoken", actualdata["token"]);
          localStorage.setItem("email", email);
          localStorage.setItem("id", actualdata.id);
          localStorage.setItem("loginName", actualdata.fullname);
          localStorage.setItem("token1", actualdata.token1);
          localStorage.setItem("token2", actualdata.token2);
          
          if(actualdata.employerStatus == '1'){
            localStorage.removeItem("empProfileStatus");
          }else{
            localStorage.setItem("empProfileStatus", '0');
          }
          //window.location.href="http://localhost:8001/Employer/" + actualdata.token2 +"/" + actualdata.token1 +"/" + actualdata["token"];
          window.location.href="/empdashboard";
          //window.location.href = "/";
          return 1;
        } else {
          alert("Invalid Email or password");
        }
      });
  };

  if (enablelogin) {
    //if the internshipId field is set then redirect to the internship-details page and handle the automatic application

    if (!(sessionStorage.getItem("redirectURL") === null)) {
      var redirectURL = "/" + sessionStorage.getItem("redirectURL");
      //history.push(redirectURL);
      window.location.href = redirectURL;
      return;
    }

    if (localStorage.getItem("internshipIdApplication")) {
      window.location.href =
        "/internship-details/" +
        localStorage.getItem("internshipIdApplication");
      return;
    }
    window.location.href = "/student-dashboard";
    return 1;
    window.location.href = "/virtual-internship";
  }

useEffect(() => {
  const activeTab = sessionStorage.getItem("showTab");
  setShowTab(activeTab);
  sessionStorage.removeItem("showTab");  
  if(activeTab == "student" || activeTab == "employer"){
    toast.warning("Session Expired, Please login again");
    console.log("setting login expired");
  }
},[]);
  
return (
  <>
  <ToastContainer />
  <LoginModalNew show={modalShow} setModalShow={setmodalShow} onHide={() => setmodalShow(false)} activeTab={showTab ? showTab : "student"} />

<Row className="align-items-center justify-content-center g-0 min-vh-100">
  <Col lg={4} md={5} className="py-4 py-xl-0">
    <Card>
        <Card.Body className="p-6">
          Session Expired, Please login
        </Card.Body>
    </Card>
  </Col>
</Row>
</>
  )
};

const test = () => { 
  return (
    <Fragment>
      <Row className="align-items-center justify-content-center g-0 min-vh-100">
        <Col lg={4} md={5} className="py-4 py-xl-0">
          <Card>
            <Card.Body className="p-6">
              <div className="mb-4">
                <Tabs
                  defaultActiveKey="student"
                  id="uncontrolled-tab-example"
                  className="mb-3 d-flex justify-content-center"
                >
                  <Tab eventKey="student" title="Student Login">
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg={12} md={12} className="mb-3">
                          {/* Username or email */}
                          <Form.Label>Email Id </Form.Label>
                          <Form.Control
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email address here"
                            required
                          />
                        </Col>
                        <Col lg={12} md={12} className="mb-3">
                          {/* Password */}
                          <Form.Label>Password </Form.Label>
                          <Form.Control
                            type="password"
                            id="password"
                            name="password"
                            placeholder="**************"
                            required
                          />
                        </Col>
                        <Col lg={12} md={12} className="mb-3">
                          {/* Checkbox */}
                          <div className="d-md-flex justify-content-between align-items-center">
                            <Form.Group
                              className="mb-3 mb-md-0"
                              controlId="formBasicCheckbox"
                            >
                              {/* <Form.Check type="checkbox" label="Remember me" /> */}
                            </Form.Group>
                            <Link to="/register">Not registered?.</Link>
                          </div>
                        </Col>
                        <Col lg={12} md={12} className="mb-0 d-grid gap-2">
                          {/* Button */}
                          <Button variant="primary" type="submit">
                            Login
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Tab>
                  <Tab eventKey="employer" title="Employer Login">
                    <Form onSubmit={handleEmployerSubmit}>
                      <Row>
                        <Col lg={12} md={12} className="mb-3">
                          {/* Username or email */}
                          <Form.Label>Official Email Id </Form.Label>
                          <Form.Control
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email address here"
                            required
                          />
                        </Col>
                        <Col lg={12} md={12} className="mb-3">
                          {/* Password */}
                          <Form.Label>Password </Form.Label>
                          <Form.Control
                            type="password"
                            id="password"
                            name="password"
                            placeholder="**************"
                            required
                          />
                        </Col>
                        <Col lg={12} md={12} className="mb-3">
                          {/* Checkbox */}
                          <div className="d-md-flex justify-content-between align-items-center">
                            <Form.Group
                              className="mb-3 mb-md-0"
                              controlId="formBasicCheckbox"
                            >
                              {/* <Form.Check type="checkbox" label="Remember me" /> */}
                            </Form.Group>
                            <Link to="/register">Not registered?.</Link>
                          </div>
                        </Col>
                        <Col lg={12} md={12} className="mb-0 d-grid gap-2">
                          {/* Button */}
                          <Button variant="primary" type="submit">
                            Login
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Tab>
                </Tabs>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
  }

export default StudentLogin;
