// import node module libraries
import { useState } from "react";
import { Col, Row, Container, Button, Image, Carousel } from "react-bootstrap";
import ModalVideo from "react-modal-video";
import CustomerStoriesCard from "./CustomerStoriesCard";
// import media files

import Slider from "react-slick";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Url from "components/Url";

const CustomerStories = () => {
  const [isOpen, setOpen] = useState(false);
  const [videoID, setVideoID] = useState("");
  const url1 = Url();
	const url = url1["url"];
  const s3URL = url1["s3"];   

  const [MobileScr, setMobilescr] = useState(
    window.innerWidth > 520 ? false : true
  );

  const testimonials = [
    {
      name: "Saurabh Panday",
      designation: "Web Development Intern",
      category: "Web Development",
      img: s3URL + "testimonials/Saurabh_Panday.jpg",
      videoId: "U1qbKZtT68I",
      description:
        "One of the best parts of my internship was the supportive team environment Everyone was so welcoming and willing to help me grow. At the end of my internship, I felt proud of what I had accomplished and confident in my skills. Thank you for watching! I hope this video inspired you to pursue your own internship opportunities. ",
    },
    {
      name: "Sandesh Kayat",
      designation: "DevOps Intern",
      category: "DevOps",
      img: s3URL + "testimonials/Sandesh_Kayat.jpg",
      videoId: "eFr5DpNBDi8",
      description:
        "Throughout my internship, I delved deep into the world of DevOps, gaining invaluable insights into the intricate workings of web development. From managing infrastructure to streamlining deployment processes, every task presented a new opportunity to learn and grow.As I worked on various projects, I encountered challenges that pushed me out of my comfort zone, but with the support of my team, I was able to overcome them and emerge stronger than ever. Each accomplishment fueled my confidence and reaffirmed my passion for DevOps.",
    },        
    {
      name: "Shashank Shekhar",
      designation: "Web Development Intern",
      category: "Web Development",
      img: s3URL + "testimonials/Shashank_Shekhar.jpg",
      videoId: "sVZEthKXZyU",
      description:
        "I want to share with you my incredible internship experience during my internship, I had the opportunity to work on some really exciting projects. I learned so much about web development. One of the best parts of my internship was the supportive team environment Everyone was so welcoming and willing to help me grow. At the end of my internship, I felt proud of what I had accomplished and confident in my skills",
    },
    {
      name: "Ashish Kumar",
      designation: "Web Development Intern",
      category: "Web Development",
      img:s3URL + "testimonials/Ashish_Kumar.jpg",
      videoId: "eAIegasr0LY",
      description:
        "I completed my web development internship with InternshipGate's virtual program, fulfilling my requirements from college. The structured real-world projects boosted my confidence and prepared me for future job opportunities. I highly recommend InternshipGate for its well-designed platform offering valuable projects, training, and mentorship.",
    },
    {
      name: "Rishabh",
      designation: "Data Science Intern",
      category: "Data Science",
      img: s3URL + "testimonials/Rishabh.jpg",
      videoId: "FpOEzOQvRAE",
      description:
        "I recently completed my data science internship with internshipgate. The virtual internship program has allowed me to complete my internship right from the college. The internship program is well structured with real projects providing me with confidence to be ready for this future and secure job.The internshipgate platform is well designed with projects training and mentorship. I would like to recommend everyone to join this platform as it will help you to land your first job.",
    },
    {
      name: "Aditya Choudhary",
      designation: "Internet of things (IOT) intern",
      category: "IOT",
      img: s3URL + "testimonials/Student_test1.jpg",
      videoId: "6Dw7lFf5Tbc",
      description:
        "Internship Gate helped me in searching my true passion and following that. It helped me in getting an internship in the field of my interest and stepping out of my comfort zone to achieve and learn something new in life.",
    },
    {
      name: "Sakshi Katara",
      designation: "Data Science Intern",
      category: "Data Science",
      img: s3URL + "testimonials/student_testi_2.png",
      videoId: "2CRpwXxCHOs",
      description:
        "Internships are an essential part for any student who wants to enhance their skills in their area of interest. I'm so grateful for choosing Internship Gate. I would recommend this platform to everyone who are seeking good companies for an efficient career enhancement.",
    },
    {
      name: "Manvi Agarwal",
      designation: "Data Science Intern",
      category: "Data Science",
      img: s3URL + "testimonials/student_testi_3.jpg",
      videoId: "uwJ9pXPuQvE",
      description:
        "The Internship Gate proved to be the gate to my success. The training rendered to me are worth to apply in professional life. My association with Internship Gate brought me clicks in my marketing field.",
    },
  ];

  const handleVideo = (id) => {
    setVideoID(id);
    setOpen(true);
  };

  const NextArrow = ({ onClick }) => {
    return (
      <Button className="arrow next" onClick={onClick}>
        <IoIosArrowForward style={{ color: "whitesmoke" }} />
      </Button>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <Button className="arrow prev" onClick={onClick}>
        <IoIosArrowBack style={{ color: "whitesmoke" }} />
      </Button>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "500px",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <section className="py-lg-6 bg-white py-6">
      <Container>
        <Row>
          <Col>
            <Row className="text-center">
              <Col md={12} className="px-md-16 mb-4 mt-2">
                <span className="text-uppercase text-primary fw-semi-bold ls-md">
                  Student stories
                </span>
                <h2 className="h1 fw-bold mt-3  mb-2">
                  What or Student stories
                </h2>
                <p className="mb-0 fs-4">
                  A student story is an engaging article integrating testimonial
                  quotes from a happy client or customer praising the work
                  completed together.
                </p>
              </Col>
            </Row>
            {/* <Col className="align-items-center d-flex flex-column flex-lg-row"> */}
            <div className="align-items-center d-flex flex-column flex-lg-row">
              {/* <Carousel variant="dark">
                {testimonials.map((item, index) => (
                  <Carousel.Item key={index}>
                    <CustomerStoriesCard
                      handleVideo={handleVideo}
                      item={item}
                    />
                  </Carousel.Item>
                ))}
              </Carousel> */}

              <Slider {...settings}>
                {testimonials.map((item, index) => (
                  <CustomerStoriesCard key={index} handleVideo={handleVideo} item={item} />
                ))}
              </Slider>

              <ModalVideo
                channel="youtube"
                //autoplay
                isOpen={isOpen}
                videoId={videoID}
                onClose={() => setOpen(false)}
              />
            </div>
            {/* </Col> */}
            {/* <Row>
                <Col xs>
                  <Button as="a" variant="outline-primary" className="mt-lg-2 mt-4" href="#">View All Stories</Button>
                </Col>
              </Row> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CustomerStories;
