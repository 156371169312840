// import node module libraries
import React, { useEffect, useState, useMemo, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Table,
  Dropdown,
  Badge,
  Button,
  Modal,
} from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";
import StarRating from "components/common/StarRating";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import GlobalFilter from "components/common/advance-table/GlobalFilter";
import Pagination from "components/common/advance-table/Pagination";
// import custom components
import Url from "components/Url";

// import profile layout wrapper
import ProfileLayout from "./ProfileLayout";

import { MentorFeedbackModal } from "components/student/MentorshipList";

const MentorDashboard = () => {
  const mentorId = localStorage.getItem("mentorId");
  const token = localStorage.getItem("admtoken") ? localStorage.getItem("admtoken") : localStorage.getItem("mentortoken");

  const [load, setLoad] = useState(false);
  const [mentorBookings, setMentorBookings] = useState([]);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [mentorBookingID, setMentorBookingID] = useState();
  const [mentorFeedbackID, setMentorFeedbackID] = useState();
  const [mentorFeedback, setMentorFeedback] = useState();
  const [mentorDetails, setMentorDetails] = useState();

  const url1 = Url();
  const url = url1["url"];
  const s3 = url1["s3"];

  const [activeItem, setActiveItem] = useState("");

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const renderComponent = () => {
    switch (activeItem) {
      case "dashboard":
        return <Dashboard />;
      case "profile":
        return <Profile />;
      // Add cases for other menu items
      default:
        return null;
    }
  };
  useEffect(() => {
    const fetchRecord = async () => {
      var api = url + "getMentorBookingsForMentor" + "/" + mentorId;
      try {
        const response = await Axios.get(api, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-USER-ID": mentorId,
          },
        });

        //setPageHeading("Course Content - " + response.data.onlineTrainingList.[0].subject_name)
        setMentorBookings(response.data.mentorBookings);
        setLoad(false);
      } catch (error) {
        //var status = error.response.status;
        toast.warning("Error loading Dashboard, please logout and login again",{ autoClose: 1000 });
        sessionStorage.setItem("redirectURL","/mentor/login");
        window.location.href="/logout";
      } finally {
        setLoad(false);
      }
    }; //end of fetchRecord

    fetchRecord();
  }, [load]);

  const handleClose = () => {
    setShowFeedbackModal(false);
  };

  const getMentorFeedback = async (mentorFeedbackID) => {
    const api = url + "getMentorshipFeedback" + "/" + mentorFeedbackID;
    try {
      const response = await Axios.get(api, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-USER-ID": mentorId,
        },
      });

      //setPageHeading("Course Content - " + response.data.onlineTrainingList.[0].subject_name)
      if (response.data.mentor_feedback.feedback) {
        setMentorFeedback(response.data.mentor_feedback);
        setShowFeedbackModal(true);
      } else {
        toast.warning("No Feedback provided", { autoClose: 1000 });
      }
    } catch (error) {
      //var status = error.response.status;
      toast.warning("Error loading Feedback, please try later", {
        autoClose: 1000,
      });
    } finally {
      setLoad(false);
    }
  };

  const handleStatusChange = async (e, mentorBookingId, status) => {
    e.preventDefault();
    console.log(mentorBookingId);
    console.log(status);
    const res = window.confirm(
      "You are about to change the mentorship booking status, are you sure?"
    );
    if (res) {
      const api = url + "changeMentorshipBookingStatus";
      try {
        const response = await Axios.post(
          api,
          {
            mentorBookingId: mentorBookingId,
            status: status,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${token}`,
              "X-USER-ID": mentorId,
            },
          }
        );

        //setPageHeading("Course Content - " + response.data.onlineTrainingList.[0].subject_name)
        if (response.data.message) {
          toast.success(response.data.message, { autoClose: 1000 });
        } else {
          toast.warning("Check if the status is updated", { autoClose: 1000 });
        }
      } catch (error) {
        //var status = error.response.status;
        toast.warning("Cannot change status, contact support", {
          autoClose: 1000,
        });
      } finally {
        setLoad(true);
      }
    }
  };

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const ActionMenu = () => {
    return (
      <div>
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle}>
            <i className="fe fe-more-vertical text-muted"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            <Dropdown.Header>SETTINGS</Dropdown.Header>
            <Dropdown.Item eventKey="1">
              <i className="fe fe-edit dropdown-item-icon"></i> Edit
            </Dropdown.Item>
            <Dropdown.Item eventKey="2">
              <i className="fe fe-trash dropdown-item-icon"></i> Remove
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };
  // Column heading and cell format setup
  const columns = useMemo(
    () => [
      { accessor: "mentor_booking_id", Header: "ID", show: false },
      { accessor: "student_name", Header: "Student Name" },
      { accessor: "student_mobileno", Header: "Student Mobile" },
      { accessor: "student_email", Header: "Student Email" },
      { accessor: "service_name", Header: "Mentorship Subject" },
      { accessor: "mentorship_date", Header: "Mentorship Date" },
      { accessor: "service_duration_mins", Header: "Duration (Mins)" },
      { accessor: "created_at", Header: "Booking Date" },
      {
        accessor: "mentorship_status",
        Header: "Status",
        Cell: ({ value, row }) => {
          return (
            <Badge
              bg={
                value === "Scheduled"
                  ? "warning"
                  : value === "Completed"
                  ? "success"
                  : value === "Cancelled"
                  ? "info"
                  : value === "Overdue"
                  ? "danger"
                  : null
              }
              className="text-white"
            >
              {value}{" "}
            </Badge>
          );
        },
      },
      {
        accessor: "action",
        Header: "Feedback",
        Cell: ({ row }) => {
          return row.original.mentorship_status_id == 2 ? (
            row.original.mentor_feedback_id ? (
              <Button
                variant="outline-info"
                size="sm"
                onClick={() => {
                  getMentorFeedback(row.original.mentor_feedback_id);
                }}
              >
                View Feedback
              </Button>
            ) : (
              <span>Feedback Not Provided</span>
            )
          ) : (
            <span>Not Completed</span>
          );
        },
      },
      {
        accessor: "mentorship_status_id",
        Header: "Change Status",
        Cell: ({ value, row }) => {
          console.log();
          switch (value) {
            case 1:
              return (
                <Fragment>
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle}>
                      <Button
                        href="#"
                        variant="outline-primary"
                        className="btn-sm"
                      >
                        Change Status
                      </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <Dropdown.Header>STATUS</Dropdown.Header>
                      <Dropdown.Item
                        onClick={(e) =>
                          handleStatusChange(
                            e,
                            row.original.mentor_booking_id,
                            2
                          )
                        }
                      >
                        Completed
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) =>
                          handleStatusChange(
                            e,
                            row.original.mentor_booking_id,
                            3
                          )
                        }
                      >
                        Cancelled
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Fragment>
              );
              break;
            case 2:
              return (
                <Fragment>
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle}>
                      <Button
                        href="#"
                        variant="outline-primary"
                        className="btn-sm"
                      >
                        Change Status
                      </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <Dropdown.Header>STATUS</Dropdown.Header>
                      <Dropdown.Item
                        onClick={(e) =>
                          handleStatusChange(
                            e,
                            row.original.mentor_booking_id,
                            1
                          )
                        }
                      >
                        Scheduled
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) =>
                          handleStatusChange(
                            e,
                            row.original.mentor_booking_id,
                            3
                          )
                        }
                      >
                        Cancelled
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Fragment>
              );
              break;

            case 3:
              return (
                <Fragment>
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle}>
                      <Button
                        href="#"
                        variant="outline-primary"
                        className="btn-sm"
                      >
                        Change Status
                      </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <Dropdown.Header>STATUS</Dropdown.Header>
                      <Dropdown.Item
                        onClick={(e) =>
                          handleStatusChange(
                            e,
                            row.original.mentor_booking_id,
                            1
                          )
                        }
                      >
                        Scheduled
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) =>
                          handleStatusChange(
                            e,
                            row.original.mentor_booking_id,
                            2
                          )
                        }
                      >
                        Completed
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Fragment>
              );
              break;
            default:
              return (
                <Fragment>
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle}>
                      <Button
                        href="#"
                        variant="outline-primary"
                        className="btn-sm"
                      >
                        Change Status
                      </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <Dropdown.Header>STATUS</Dropdown.Header>
                      <Dropdown.Item
                        onClick={(e) =>
                          handleStatusChange(
                            e,
                            row.original.mentor_booking_id,
                            1
                          )
                        }
                      >
                        Scheduled
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) =>
                          handleStatusChange(
                            e,
                            row.original.mentor_booking_id,
                            2
                          )
                        }
                      >
                        Completed
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) =>
                          handleStatusChange(
                            e,
                            row.original.mentor_booking_id,
                            3
                          )
                        }
                      >
                        Cancelled
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Fragment>
              );
              break;
          }
        },
      },
      /*
                {
                    accessor: 'received', Header: 'Received',
                    Cell: ({ value, row }) => {
                        return (
                            <Link to="/dashboard/applications/all"
                                className={`btn btn-${value === 'View Applications' ? 'light' : 'primary'} btn-sm`}>{value}</Link>
                        );
                    }
                },
                {
                    accessor: 'action',
                    Header: '',
                    Cell: ({ row }) => {
                        return <ActionMenu status={row.original.status} />;
                    }
                }
                */
    ],
    []
  );

  const data = useMemo(() => mentorBookings, [mentorBookings]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { pageIndex, globalFilter } = state;

  return (
    <ProfileLayout>
      {showFeedbackModal && mentorFeedback && (
        <Modal show={showFeedbackModal} onHide={handleClose} size="md">
          <Modal.Header closeButton>
            <Modal.Title>Feedback</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2">
              <div className="col-4 text-end ">
                <h5 className="m-1">Star Rating</h5>
              </div>

              <div className="col-8 d-md-flex">
                <Row>
                  <Col md={6}>
                    <StarRating
                      value={mentorFeedback.star_rating}
                      viewOnly={true}
                    />
                  </Col>
                </Row>
              </div>
            </Row>

            <Row className="mb-2">
              <div className="col-4 text-end ">
                <h5 className="m-1">Feedback</h5>
              </div>
              <div className="col-8">
                <span>{mentorFeedback.feedback}</span>
              </div>
            </Row>
            <Row className="mb-2 d-flex d-inline">
              <div className="mb-3 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-outline-warning btn-sm ms-2"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </Row>
          </Modal.Body>
        </Modal>
      )}
      <Card className="mt-4">
        <Card.Header>
          <h3 className="mb-0 h4">My Mentor Bookings</h3>
        </Card.Header>
        <Card.Body className="p-0 ">
          <Row className="mt-6">
            <Col lg={12}>
              <Table
                hover
                responsive
                {...getTableProps()}
                className="text-nowrap table-centered"
              >
                <thead className="table-light">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

              {/* Pagination @ Footer */}
              <Pagination
                previousPage={previousPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {/* end of Page Content section*/}
    </ProfileLayout>
  );
};
export default MentorDashboard;
