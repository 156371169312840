import React, { Fragment, useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaSearch } from "react-icons/fa";
import Url from "../../Url";

import {
  Col,
  Row,
  Dropdown,
  Card,
  Breadcrumb,
  Button,
  Modal,
  Table,
  Form,
  InputGroup,
} from "react-bootstrap";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import Pagination from "components/elements/advance-table/Pagination";
import { Link } from "react-router-dom";
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import DatePicker from "react-flatpickr";
import { date } from "yup";
toast.configure();

//import { set } from "react-ga";

const CollegeRegistration = () => {
  const url1 = Url();
  const url = url1["url"];

  const admtoken = localStorage.getItem("emptoken");
  //const email = localStorage.getItem("email")

  const [collegeRegistration, setCollegeRegistration] = useState([]);

  const [searchcontent, setSearchcontent] = useState("");
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  // const [paginationBasic, setPaginationBasic] = useState("");
  // const [activePage, setActivePage] = useState(0);
  // const [showData, setShowData] = useState();
  const [registration_date_from, setRegistrationDateFrom] = useState("");
  const [currentdate, setCurrentdate] = useState(null);

  const todayDate = new Date();
  todayDate.setDate(todayDate.getDate() - 365);
  const [filterRegDate, setFilterRegDate] = useState(todayDate);
  {
    /*---------------Set int post inactive---------------*/
  }
  useEffect(() => {
    var d = new Date();
    var month =
      d.getMonth() > 9 ? "" + (d.getMonth() + 1) : "0" + (d.getMonth() + 1);
    var day = d.getDate() > 10 ? "" + d.getDate() : "0" + d.getDate();
    var year = d.getFullYear();
    setCurrentdate([year, month, day].join("-"));
    setRegistrationDateFrom([year, d.getMonth(), day].join("-"));
  }, []);
  {
    /*---------------Set int post inactive end---------------*/
  }
  const deleteCollege = (id) => {
    const res = window.confirm("The College will be deleted, are you sure?");
    if (res) {
      const api = url + "deteteCollegeById/" + id;
      fetch(api, {
        method: "DELETE",
        body: JSON.stringify({ id }),
        headers: { "Content-Type": "application/json" },
      })
        .then((apidata) => {
          return apidata.json();
        })
        .then((actualdata) => {
          toast.success("Intern deleted successfully ....", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
          setDeleteSuccess(true);
        });
    }
  };

  const changeCollegeStatus = (id,status) => {
    var message = "activated";
    if(status == 0){
      message = "deactivated"
    }

    const res = window.confirm("This college will be " + message +", are you sure?");
    if (res) {
      const api = url + "changeCollegeStatus";
      fetch(api, {
        method: "PUT",
        body: JSON.stringify({ id,status }),
        headers: { "Content-Type": "application/json" },
      })
        .then((apidata) => {
          return apidata.json();
        })
        .then((actualdata) => {
          toast.success("College account "+message+" successfully ....", { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
          setDeleteSuccess(true);
        });
    }
  }

  const changeCollegeVerificationStatus = (id,status) => {
    var message = "verified";
    if(status === 0){
      message = "unverified"
    }

    const res = window.confirm("This college will be " + message +", are you sure?");
    if (res) {
      const api = url + "changeCollegeVerificationStatus";
      fetch(api, {
        method: "PUT",
        body: JSON.stringify({ id,status }),
        headers: { "Content-Type": "application/json" },
      })
        .then((apidata) => {
          return apidata.json();
        })
        .then((actualdata) => {
          toast.success("College account "+message+" successfully ....", { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
          setDeleteSuccess(true);
        });
    }
  }

  const getCollegeList = () => {
    const RegDateFrom = registration_date_from;

    const api =
      url + "getAllCollegeRegistrations" + (RegDateFrom ? "/" + RegDateFrom : "");

    fetch(api, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        //console.log(actualdata1)
        setCollegeRegistration(actualdata1.collegeRegistrations);
        setDeleteSuccess(false);
      });
  };

  useEffect(() => {
    getCollegeList();
  }, [deleteSuccess, filterRegDate]);

 

  /*--------------------------- Generate CSV ---------------------------*/
  const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData =
      typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

    var CSV = "";

    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index + ",";
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + "\r\n";
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + "\r\n";
    }

    if (CSV === "") {
      alert("Invalid data");
      return;
    }

    //Generate a file name
    var fileName = "IG College List";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  /*--------------------------- Generate CSV ---------------------------*/
  // console.log(collegeRegistration)

  const columns = useMemo(
    () => [
      { Header: "Serial No", Cell: (prop) => prop.row.index + 1 },
      { accessor: "college_name", Header: "College Name" },
      { accessor: "email", Header: "Email Id", id: "email" },
      { accessor: "mobileno", Header: "Mobile No", id: "mobileno" },
      { accessor: "password", Header: "Password" },
      { accessor: "contact_name",Header: "Contact name" },
      { accessor: "created_at", Header: "Registration Date" },
      { accessor: 'active_ind', Header: 'Activation Status', id: "status_action",
      Cell: ({value, row}) => {
          return(
          <div>
              {value === 1 && <Button className="btn-sm" variant="warning" onClick={() => changeCollegeStatus(row.original.id,0)}>Deactivate</Button>}
              {value === 0 && <Button className="btn-sm btn-success" onClick={() => changeCollegeStatus(row.original.id,1)}>Activate</Button>}
          </div>
          )
      }
      },      
      { accessor: 'verification_ind', Header: 'Verification Status', id: "verification_ind",
      Cell: ({value, row}) => {
          return(
          <div>
              {value === 1 && <Button className="btn-sm" variant="warning" onClick={() => changeCollegeVerificationStatus(row.original.id,0)}>Unverify</Button>}
              {value === 0 && <Button className="btn-sm btn-success" onClick={() => changeCollegeVerificationStatus(row.original.id,1)}>Verify</Button>}
          </div>
          )
      }
      },            
      {
        accessor: "action",
        Header: "Action",
        Cell: ({ value, row }) => {
          return (
            <Button
              className="btn-sm btn-outline-danger btn-white"
              onClick={() => deleteCollege(row.original.id)}
            >
              Delete
            </Button>
          );
        },
      },
    ],
    []
  );
  const dateFilter =
  collegeRegistration &&
    collegeRegistration.filter((item) => {
      const dateDate = new Date(item.created_at);
      const temp = new Date(filterRegDate);
      return dateDate > temp;
    });
  // console.log(collegeRegistration)

  const data = useMemo(() => {
    const filtered = collegeRegistration.filter((item) => {
      const dateDate = new Date(item.created_at);
      const temp = new Date(filterRegDate);
      return dateDate > temp;
    });
    return filtered;
  }, [collegeRegistration]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 100,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );

  const { pageIndex, globalFilter } = state;
  return (
    <Fragment>
      <Row>
        <Col lg={10} md={10} sm={10}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">Registered College List</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Registration</Breadcrumb.Item>
                <Breadcrumb.Item active>Colleges</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div></div>
          </div>
        </Col>
        <Col>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h5 fw-bold">Registration Date From</h1>
              <DatePicker
                value={filterRegDate}
                selected={filterRegDate}
                onChange={(date) => setFilterRegDate(date)}
              />
            </div>
            <div></div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-3 px-3 ">
          <GlobalFilter
            filter={globalFilter}
            setFilter={setGlobalFilter}
            placeholder="Search"
          />
        </Col>
        {console.log(collegeRegistration)}
        {collegeRegistration.length > 0 ? (
          <>
            <Col sm={12} className="ml-0">
              <button
                className="btn btn-success ml-2 mt-1"
                onClick={() =>
                  JSONToCSVConvertor(collegeRegistration, "_College_details", true)
                }
              >
                Download Excel
              </button>
            </Col>
          </>
        ) : null}
      </Row>

      <Row className="mt-3">
        <Col lg={12} md={12} sm={12}>
          <Card>
            <Card.Body className="p-0">
              <div
                className="table-responsive"
                style={{ 
                overflow: "auto", 
                // height: "500px" 
              }}
              >
                <Table
                  {...getTableProps()}
                  className="text-nowrap"
                  style={{ height: "400px" }}
                >
                  <thead className="table-light">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              {/* Pagination @ Footer */}
              <Pagination
                previousPage={previousPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CollegeRegistration;
