import { v4 as uuid } from 'uuid';

// import media files
import StripeLogo from 'assets/images/brand/gray-logo-stripe.svg';
import AirbnbLogo from 'assets/images/brand/gray-logo-airbnb.svg';
import DiscordLogo from 'assets/images/brand/gray-logo-discord.svg';
import IntercomLogo from 'assets/images/brand/gray-logo-intercom.svg';
import PinterestLogo from 'assets/images/brand/gray-logo-pinterest.svg';
import NetflixLogo from 'assets/images/brand/gray-logo-netflix.svg';

const LogoList2 = [
	{
		id: uuid(),
		logoimage: "https://internshipgates3.s3.ap-south-1.amazonaws.com/imgupload/1678171716blob"
	},
	{
		id: uuid(),
		logoimage: "https://internshipgates3.s3.ap-south-1.amazonaws.com/imgupload/1677825964blob"
	},
	{
		id: uuid(),
		logoimage: "https://internshipgates3.s3.ap-south-1.amazonaws.com/imgupload/1676033210blob"
	},
	{
		id: uuid(),
		logoimage: "https://internshipgates3.s3.ap-south-1.amazonaws.com/imgupload/1665132479blob"
	},
	{
		id: uuid(),
		logoimage: "https://internshipgates3.s3.ap-south-1.amazonaws.com/imgupload/1677305335blob"
	},
	{
		id: uuid(),
		logoimage: NetflixLogo
	}
];

export default LogoList2;
