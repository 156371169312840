// import node module libraries
import { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import {
    Modal,
    Row,
    Col,
    Image,
    Button
} from 'react-bootstrap';

// import custom components
import Url from 'components/Url';
import RightIconInfoCard from './common/cards/RightIconInfoCard';

// import media files
import Excelsheet from 'assets/images/excelsheet.png';

const DashboardIconBox = (props) => {
    const { item, id } = props;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [excelData, setExcelData] = useState()
    const [type, setType] = useState("")
    const url1 = Url();
    const url = url1.url


    const intposted = () => {
        const api = url + 'getEmployerInternshipApplications/' + id;
        fetch(api, {
            method: 'GET',
        })
            .then((apidata1) => {
                return apidata1.json();
            })
            .then((actualdata1) => {
                setExcelData(actualdata1);
                console.log(actualdata1);
            });
    };

    const apprcvd = () => {
		//Umesh - 20220906 - start
		//const api = url + "empDashboardAppRecvd/" + email;
		const api = url + 'getEmployerInternshipApplications/' + id;
		//Umesh - 20220906 - end
		fetch(api, {
			method: 'GET',
		})
			.then((apidata1) => {
				return apidata1.json();
			})
			.then((actualdata1) => {
				setExcelData(actualdata1);
                console.log(actualdata1);
			});
	};

    const shrtlstd = () => {
		//Umesh - 20220906 - start
		//const api = url + "empDashboardShortlisted/" + email;
		const api = url + 'getEmployerInternshipApplications/' + id + '/1';
		//Umesh - 20220906 - end
		fetch(api, {
			method: 'GET',
		})
			.then((apidata1) => {
				return apidata1.json();
			})
			.then((actualdata1) => {
				setExcelData(actualdata1);
                console.log(actualdata1);
			});
	};

    const ttlhire = () => {
		//const api = url + "empDashboardHire/" + email;
		const api = url + 'getEmployerInternshipApplications/' + id + '/2';
		fetch(api, {
			method: 'GET',
		})
			.then((apidata1) => {
				return apidata1.json();
			})
			.then((actualdata1) => {
                setExcelData(actualdata1);
                console.log(actualdata1);
			});
	};

    const rejct = () => {
		//const api = url + "empDashboardOffersent/" + email;
		const api = url + 'getEmployerInternshipApplications/' + id + '/4';
		fetch(api, {
			method: 'GET',
		})
			.then((apidata1) => {
				return apidata1.json();
			})
			.then((actualdata1) => {
                setExcelData(actualdata1);
                console.log(actualdata1);
			});
	};


    const callFunctions = (text) => {
        if(text === "Posted"){
            intposted();
            setType("_Internship_posted")
            return;
        }
        if(text === "Received"){
            apprcvd();
            setType("_Application_received")
            return;
        }
        if(text === "Shortlisted"){
            shrtlstd();
            setType("_Shortlisted")
            return;
        }
        if(text === "Hired"){
            ttlhire();
            setType("_Hired")
            return;
        }
        if(text === "Rejected"){
            rejct();
            setType("_Rejected")
            return;
        }
    }

    const JSONToCSVConvertorNew = (JSONData, ReportTitle, ShowLabel) => {
		//If JSONData is not an object then JSON.parse will parse the JSON string in an Object
		var arrData =
			typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData;

		var CSV = '';
		var csvData = null;
		var header = '';
		/*
      header - This variable contains the header row of the excel sheet.
      csvData - This variable contains the json data converted into comma delimited rows. We are using the map function to create the new array
      CSV - Plain comma delimited string created using the csvData Array
    */

		if (ReportTitle === '_Internship_posted') {
			header =
				'"Organization Name","email","Internship Industry","Internship Details","Location","Total Openings","Internship Type","Duration","Internship Start Date","Job Description","Stipend Type","Compensation","Perks","Skills Required","Internship Creation Date","Internship Posting Date","internship_status"';
			//use map function to create the array with the comma delimited rows
			csvData = arrData.map(function (row) {
				return (
					'"' +
					row.companyname +
					'","' +
					row.email +
					'","' +
					row.industry_type +
					'","' +
					row.internship_details +
					'","' +
					row.location +
					'","' +
					row.total_opening +
					'","' +
					row.type +
					'","' +
					row.duration +
					'","' +
					row.startdate +
					'","' +
					row.job_description +
					'","' +
					row.stipend_type +
					'","' +
					row.compensation +
					'","' +
					row.perks +
					'","' +
					row.skill +
					'","' +
					row.created_at +
					'","' +
					row.posting_date +
					'","' +
					row.internship_status +
					'"'
				);
			}); // the csvData is an array of comma delimited string
		}

		if (
			ReportTitle === '_Application_received' ||
			ReportTitle === '_Shortlisted' ||
			ReportTitle === '_Rejected' ||
			ReportTitle === '_Hired'
		) {
			header =
				'"Student Name","Student Email","Organization Name","Internship Details","Internship Type","Location","Internship Start Date","Compensation","Duration","Application Status"';

			//use map function to create the array with the comma delimited rows
			csvData = arrData.map(function (row) {
				return (
					'"' +
					row.student_name +
					'","' +
					row.student_email +
					'","' +
					row.companyname +
					'","' +
					row.internship_details +
					'","' +
					row.type +
					'","' +
					row.location +
					'","' +
					row.startdate +
					'","' +
					row.compensation +
					'","' +
					row.duration +
					'","' +
					row.application_status +
					'"'
				);
			});
		}

		//Convert the comma delimited array into plain text CSV file. \r\n is appended to mark end of each csv row
		for (var i = 0; i < csvData.length; i++) {
			if (i == 0 && ShowLabel) {
				//add header to the csv file only at the beginning (i==0)
				CSV = header + '\r\n';
			}
			//add rows one by one to the csv file
			CSV += csvData[i] + '\r\n';
		}

		if (CSV === '') {
			alert('No Data Found');
			return;
		}

		//Generate a file name
		var fileName = 'IG_';
		//this will remove the blank-spaces from the title and replace it with an underscore
		fileName += ReportTitle.replace(/ /g, '_');

		//Initialize file format you want csv or xls
		var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

		// Now the little tricky part.
		// you can use either>> window.open(uri);
		// but this will not work in some browsers
		// or you will not get the correct file extension

		//this trick will generate a temp <a /> tag
		var link = document.createElement('a');
		link.href = uri;

		//set the visibility hidden so it will not effect on your web-layout
		link.style = 'visibility:hidden';
		link.download = fileName + '.csv';

		//this part will append the anchor tag and remove it after automatic click
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

    return (
        <Fragment>
            <Link to={item.link} className="text-reset" onClick={() => {
                callFunctions(item.subText)
                handleShow()
                }}>
                <RightIconInfoCard item={item} />
            </Link>

            {/* Other Modal Popups  */}
            <Modal centered show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title id="othersModalLabel">
                        <h3>{item.title}</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-center">
                        <Col lg={8} xs={12} className="text-center">
                            <Image src={Excelsheet} alt="" className="img-fluid mb-4" />
                            <Button variant="primary" onClick={() => JSONToCSVConvertorNew(excelData, type, true)}>Download Excelsheet</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}

export default DashboardIconBox