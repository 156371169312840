import React, { useState, useEffect, Fragment } from "react";
import { useParams } from 'react-router'
import Url from "../../Url";
import { toast } from "react-toastify";
import { Container, Row, Col } from "react-bootstrap";

toast.configure();

const simulateNetworkRequest = () => {
  return new Promise((resolve) => setTimeout(resolve, 1000));
};

const ScheduleConfirmation = () => {

  const { confirmationStatus, encryptedInterviewId } = useParams();
  const url1 = Url();
  const url = url1["url"];
  console.log(confirmationStatus + encryptedInterviewId);

  useEffect(() => {
    const api = url + "sendInterviewResponseToEmployer";
    const currentURL  = window.location.hostname;
    fetch(api, {
      method: "POST",
      body: JSON.stringify({ confirmationStatus, 
                              encryptedInterviewId
                            }),
      headers: { "Content-Type": "application/json" },
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        console.log(actualdata1);
      });
  }, []);

  return (
    <div className="py-10 bg-white">
        <Container>

                <Row>
              <Col lg={{ span: 8, offset: 2 }} md={12} sm={12} className="mb-12 lead">
                {confirmationStatus == 1 ? (
                  <Fragment>
                <Col xs={12} className="d-flex">
                <span className="pe-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-calendar2-check-fill" viewBox="0 0 16 16">
  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zm-2.6 5.854a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/>
</svg></span>
<div className="ml-0 mr-1">You have accepted the interview schedule.</div></Col></Fragment>
                ):
                <Fragment>
                <Col xs={12} className="d-flex">
                <span className="pe-2">                  
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-calendar-x-fill" viewBox="0 0 16 16">
  <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zM6.854 8.146 8 9.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 10l1.147 1.146a.5.5 0 0 1-.708.708L8 10.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 10 6.146 8.854a.5.5 0 1 1 .708-.708z"/>
</svg>                  
                  </span><div className="ml-0 mr-1">Your response to reschedule is sent to the employer.</div></Col></Fragment>
                }
        </Col>
      </Row>

    </Container>
    </div>
  );
};

export default ScheduleConfirmation;
