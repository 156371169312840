import React, { Fragment, useState, useEffect } from "react";
import {
  Col,
  Row,
  Container,
  Nav,
  Card,
  Tab,
  ListGroup,
  Image,
  Button,
} from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";

import Url from "../Url";

const Payment = () => {
  const url1 = Url();
  const url = url1["url"];
  const razor_key = url1["razor_key"];

  const [orderDetails, setOrderDetails] = useState([]);
  const [loadPaymentGateway, setLoadPaymentGateway] = useState(false);
  const [processCompleted, setProcessCompleted] = useState(false);
  const [processResult, setProcessResult] = useState([
    { paymentStatus: "Success" },
  ]);
  const [paymentCancelled, setPaymentCancelled] = useState(0);

  const razorPay = () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.body.appendChild(script);
    script.onload = loadrazorPay;
  };

  const loadrazorPay = () => {
    var amount = orderDetails.total_amount_payable * 100;
    var phnno = orderDetails.mobileno;
    var email = orderDetails.email;
    var pgOrderId = orderDetails.pg_order_id; //this is the payment gateway order id generated through payment gateway order API
    var options = {
      key: razor_key,
      amount: amount,
      currency: "INR",
      payment: {
        capture: "automatic",
        capture_options: {
          automatic_expiry_period: 12,
          manual_expiry_period: 7200,
          refund_speed: "optimum",
        },
      },
      name: "Internshipgate",
      description: "IG_CAP_Transaction",
      image: "https://internshipgate.com/static/media/header_logo.88fca79c.svg",
      order_id: pgOrderId,

      handler: function (response) {
        console.log(response.razorpay_payment_id);
        var pg_payment_id = null;
        var payment_status = 1;
        if (response.razorpay_payment_id) {
          pg_payment_id = response.razorpay_payment_id;
          payment_status = 2;
        } else {
          pg_payment_id = null;
          payment_status = 3;
        }

        const order_id = orderDetails.order_id;
        const payment_id = orderDetails.payment_id; //primary key for payments record

        setProcessResult({
          orderId: order_id,
          paymentStatus: payment_status == 2 ? "Success" : "Failed",
          pgPaymentId: pg_payment_id,
          course_name: orderDetails.subject_name,
          course_duration: orderDetails.course_duration,
        });

        if (payment_status == 2) {
          sessionStorage.removeItem("pendingOrderId"); //remove teh sessionStorage order id
          var redirectURL = "";
          switch (orderDetails.item_type) {
            case 1:
              redirectURL = "/virtual-internship";
              break;
            case 2:
              redirectURL = "/resumes";
              break;
            case 3:
                redirectURL = "/student-dashboard#mentorship";
                break;              
          }

          window.setTimeout(() => {
            // console.log("Redirected from here 111");
            window.location.href = redirectURL;
            return;
          }, 2000);
        }

        setProcessCompleted(true);
        /*
                ***************** All the logic below is being handled by the Razorpay webhook handler verify_payment@payment_controller from the backend
                const api = url + "updatePaymentDetails";
                fetch(api, {
                    method: "POST",
                    body: JSON.stringify({ order_id, payment_id, pg_payment_id,payment_status }),
                    headers: { "Content-Type": "application/json" },
                })
                    .then((apidata) => {
                        console.log(apidata)
                        return apidata.json();
                    })
                    .then((actualdata) => {
                        setProcessResult(actualdata);

                        if(actualdata.paymentStatus == "Success"){
                            console.log("sending email");
                            sessionStorage.removeItem("pendingOrderId");//remove teh sessionStorage order id
                            const email=localStorage.getItem("email");
                            const subject = actualdata.course_name;
                            if(email !== ''){
                                const api1 = url + "SendMailAfterCAP";
                                fetch(api1, {
                                    method: "POST",
                                    body: JSON.stringify({ email, subject }),
                                    headers: { "Content-Type": "application/json" },
                                })
                                .then((apidata2) => {
                                    return apidata2.json();
                                })
                                .then((actualdata2) => {
                                    console.log(actualdata2)
                                });
                            }
                            window.setTimeout(() => {
                                console.log("Redirected from here 111");
                                window.location.href = "/virtual-internship";
                            }, 2000);                            
                        }

                        setProcessCompleted(true);
                    });
                */
      },
      prefill: {
        email: email,
        contact: phnno,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3644a1",
      },
      modal: {
        ondismiss: function () {
          setPaymentCancelled(1);
          var redirectURL = "";
          switch (orderDetails.item_type) {
            case 1:
              redirectURL = "/virtual-internship";
              break;
            case 2:
              redirectURL = "/resumes";
              break;
            case 3:
                redirectURL = "/student-dashboard/#mentorship";
                break;              
            default:
              redirectURL = "/student-dashboard";
              break;
          }

          window.setTimeout(() => {
            // console.log("Redirected from here 2222");
            window.location.href = redirectURL;
          }, 2000);
        },
      },
    };
    const rzp1 = new window.Razorpay(options);

    rzp1.open();
  };

  useEffect(() => {
    const sessionObject = JSON.parse(sessionStorage.getItem("pendingOrderId"));
    const orderId = sessionObject?.orderId;
    const loginHash = localStorage.getItem("stutoken");

    if (orderId) {
      const api = url + "getOrderDetails" + "/" + orderId + "/" + loginHash;
      (async () => {
        var response = await fetch(api, {
          method: "GET",
        });
        var actualdata1 = await response.json();
        if (actualdata1.orderDetails.length == 0) {
          toast.warning(actualdata1.message);
          //   console.log("Redirected from here 333");
          window.location.href = "/virtual-internship";
          return;
        }

        setOrderDetails(actualdata1.orderDetails[0]);
        setLoadPaymentGateway(true);
      })();
    }
  }, []);

  useEffect(() => {
    //razorpay will be loaded only after the order is fetched and we have set the flag
    if (loadPaymentGateway) {
      razorPay();
    }
  }, [loadPaymentGateway]);

  if (processResult.length > 1 && processResult.paymentStatus == "Success") {
    //window.location.href = "/CareerAcceleratorProgram1"
  }

  return (
    <Fragment>
      <ToastContainer />
      <Row className="align-items-center justify-content-center g-0">
        <Col lg={4} md={5} className="py-8 py-xl-0 my-6">
          <Card>
            <Card.Body className="p-6 ">
              <Col className="text-center">
                {paymentCancelled === 1 ? (
                  <Fragment>
                    <div>
                      <p>
                        <span className="lead">Order#:</span>{" "}
                        {orderDetails.order_id}
                      </p>
                      <p>
                        You have chosen to cancel the order process,
                        Auto-redirecting to {orderDetails.service_name}...
                      </p>
                    </div>
                    <p className="py-2">
                      {" "}
                      Or you can{" "}
                      <Link to={{ pathname: "/student-dashboard" }}>
                        Click Here
                      </Link>{" "}
                      To Go to your Dashboard
                    </p>
                  </Fragment>
                ) : !processCompleted ? (
                  <Fragment>
                    <div>
                      <p>
                        Please wait, Processing Payment for order #
                        {orderDetails.order_id}
                      </p>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div>
                      <h3>
                        Your Payment for Order # {orderDetails.order_id} 
                        has been{" "}
                        {processResult.paymentStatus}, <br />
                        Auto-redirecting to {orderDetails.service_name}...
                      </h3>
                    </div>
                    <p className="py-2">
                      {" "}
                      Or you can{" "}
                      <Link to={{ pathname: "/student-dashboard" }}>
                        Click Here
                      </Link>{" "}
                      To Go to your Dashboard
                    </p>
                  </Fragment>
                )}
              </Col>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
export default Payment;
