import React, { Fragment, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Button,
  Card,
  Col,
  Row,
  Spinner,
  Container,
  Breadcrumb,
  InputGroup,
} from "react-bootstrap";
import Select from "react-select";
import CheckEmployerProfileStatus from "./CheckEmployerProfileStatus";
import Url from "components/Url";
import { ToastContainer, toast } from "react-toastify";
import { HiOutlinePlusSm, HiOutlineCheck } from "react-icons/hi";
import { BsRobot } from "react-icons/bs";

// import { RTE } from "components/ResumeBuilder/foundation/index.js";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const PostNewInternship = (props) => {
  const [internshipDetails, setInternshipDetails] = useState([]);
  const [showOthers, setShowOthers] = useState(false);
  const [industryType, setIndustryType] = useState("Engineering");
  const [intDetails, setIntDetails] = useState("Business development");
  const [scope, setScope] = useState("Domestic");
  const [openings, setOpenings] = useState("");
  const [intType, setIntType] = useState("");
  const [duration, setDuration] = useState("");
  const [duration2, setDuration2] = useState("M");
  const [description, setDescription] = useState("");
  const [stipType, setStipType] = useState("");
  const [perk, setPerk] = useState("");
  const [stipend, setStipend] = useState("");
  const [stipend2, setStipend2] = useState("Months");
  const [skillsdata, setSkillsData] = useState();
  const [skills, setSkills] = useState();
  const [software_skill_1, setSoftwareskill] = useState([]);
  const [placement_offer, setPlacement_offer] = useState(null);
  const [companyname, setCompanyname] = useState("");
  const [intObject, setIntObject] = useState();
  const [startdate, setStartdate] = useState("");
  const [active, setActive] = useState(true);
  const [locationData, setLocationData] = useState("");
  const [location, setLocation] = useState("");
  const [currentdate, setCurrentdate] = useState(null);
  const [cityOptions, setCityOptions] = useState();
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [isProcessing, setIsProcessing] = useState(false);
  const [employerProfile, setEmployerProfile] = useState([]);
  const [validated, setValidated] = useState(false);
  const history = useHistory();
  const url1 = Url();
  const url = url1["url"];
  const intID = props.match.params.intID;
  const empID = localStorage.getItem("id");
  const [topCities, setTopCities] = useState([]);
  const [otherCities, setOtherCities] = useState();
  const [step, setStep] = useState(1);
  const [selectedCities, setSelectedCities] = useState([]);
  const [waitForBot, setWaitForBot] = useState(false);

  const quillRef = useRef(null);

  const industryTypeOptions = [
    { value: "Select", label: "Select" },
    { value: "Design", label: "Design" },
    { value: "Education and Training", label: "Education and Training" },
    { value: "Engineering", label: "Engineering" },
    { value: "Environmental Services", label: "Environmental Services" },
    { value: "Finance and Banking", label: "Finance and Banking" },
    { value: "Healthcare", label: "Healthcare" },
    { value: "Hospitality and Tourism", label: "Hospitality and Tourism" },
    { value: "Humanities", label: "Humanities" },
    {
      value: "Information Technology (IT)",
      label: "Information Technology (IT)",
    },
    { value: "Legal Services", label: "Legal Services" },
    { value: "MBA", label: "MBA" },
    { value: "Manufacturing", label: "Manufacturing" },
    { value: "Marketing and Advertising", label: "Marketing and Advertising" },
    { value: "Media and Entertainment", label: "Media and Entertainment" },
    { value: "Media", label: "Media" },
    { value: "NGO", label: "NGO" },
    {
      value: "Nonprofit and Social Services",
      label: "Nonprofit and Social Services",
    },
    {
      value: "Real Estate and Property Management",
      label: "Real Estate and Property Management",
    },
    { value: "Retail and E-commerce", label: "Retail and E-commerce" },
    { value: "Science", label: "Science" },
    {
      value: "Transportation and Logistics",
      label: "Transportation and Logistics",
    },
  ];
  const duration1Options = [
    { value: "", label: "Select" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
  ];
  const duration2Options = [
    { value: "", label: "Select" },
    { value: "M", label: "Months" },
    { value: "W", label: "Weeks" },
  ];

  {
    /*
   <option value="">Select</option>
                      <option value="Engineering">ENGINEERING</option>
                      <option value="Design">DESIGN</option>
                      <option value="NGO">NGO</option>
                      <option value="Science">SCIENCE</option>
                      <option value="Media">MEDIA</option>
                      <option value="Humanities">HUMANITIES</option>
                      <option value="MBA">MBA</option>
 
  
  // const internshipDetails = [
  //   'Business development', 'Web Development',
  //   'Social Media Marketing', 'Human Resources', 'Campus Ambassodor', 'Graphic Design',
  //   'Content Writing', 'Mobile App', 'Operations'
  // ]
  */
  }
  const internshipType = [
    "Full Time",
    "Work From Home",
    "Internships For Women",
    "On Field/Office",
    "Part Time",
    "Internships Job Offer",
  ];
  const stipendTypeList = [
    "Fixed",
    "Negotiable",
    "Performance based",
    "Unpaid",
  ];
  const stipendType1 = [
    {
      value: "Months",
      label: "Monthly",
    },
    {
      value: "Week",
      label: "Weekly",
    },
  ];
  const stipendPerks = [
    "Certificate",
    "Flexible Working Hours",
    "Informal Dress Code",
    "1 Leave A Month",
    "6 Hours Work",
    "Free Snacks",
  ];

  // const handleChange = (e) => {
  //   e.persist();
  //   console.log(e);
  //   setIntDetails(e.target.value);
  //   if (internshipDetails.includes(e.target.value)) {
  //     setShowOthers(false);
  //   } else {
  //     setShowOthers(true);
  //   }
  // };

  const formatDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    setCurrentdate(today);
  };

  useEffect(() => {
    if (quillRef.current) {
      const quillEditor = quillRef.current.getEditor();
      quillEditor.root.style.height = '100%';
    }
  }, []);

  useEffect(() => {
    formatDate();
    let isNew = 1;
    if (intID > 0) {
      isNew = 0;
    }

    if (isNew == 1) {
      setScope("Domestic");
      setActive(true);
    }
    if (isNew == 0) {
      const api = url + "getEmployerInternshipById/" + intID;
      fetch(api, {
        method: "GET",
      })
        .then((apidata) => {
          return apidata.json();
        })
        .then((response) => {
          setActive(true);
          setIntObject(response);
          setIndustryType(response.industry_type);
          //if(response.internship_details.includes("Business development") || response.internship_details.includes("Graphic Design") || response.internship_details.includes("Web development") || response.internship_details.includes("Content writing") || response.internship_details.includes("Social media marketing") ||  response.internship_details.includes("Marketing") || response.internship_details.includes("Human resources") || response.internship_details.includes("Mobile app") || response.internship_details.includes("Campus Ambassodor") || response.internship_details.includes("Operations")){

          if (internshipDetails.includes(response.internship_details) || (!response.internship_details || response.internship_details?.length == 0)) {
            setShowOthers(false);
          } else {
            console.log("setting true");
            setShowOthers(true);
          }
          setIntDetails(response.internship_details);
          if (response.location) {
            const selectedCityLabels = response.location
              .split(",")
              .map((option) => ({ label: option, value: option }));
            setSelectedCities(response.location);
            setLocation(selectedCityLabels.map((city) => city.label).join(","));
          }
          setOpenings(response.total_opening);
          setIntType(response.type);
          // if (response["type"].includes("Full Time")) {
          //   setType1(true);
          // }
          // if (response["type"].includes("Part Time")) {
          //   setType2(true);
          // }
          // if (response["type"].includes("Work from home")) {
          //   setType3(true);
          // }
          // if (response["type"].includes("Internships Job Offer")) {
          //   setType4(true);
          // }
          // if (response["type"].includes("Internships for Women")) {
          //   setType5(true);
          // }
          // if (response["type"].includes("On Field/Office")) {
          //   setType6(true);
          // }
          setDuration(response.duration1);
          setDuration2(response.duration2);
          setDescription(response.job_description);
          setStipType(response.stipend_type);
          setStipend(response.compensation1);
          setStipend2(response.compensation2);
          setPerk(response.perks);
          setPlacement_offer(response.placement_offer);
          setCompanyname(response.companyname);
          setStartdate(response.startdate);
          if (response.skill) {
            setSkillsData(response.skill.split(","));
          }

          setAIInternshipDescript(response.job_description);
        });
    }

    var api = url + "getEmployerProfileById/" + empID;
    //Umesh - 20220906  -End
    fetch(api, {
      method: "GET",
    })
      .then((profileJson) => {
        return profileJson.json();
      })
      .then((profileData) => {
        //console.log(profileData);
        setEmployerProfile(profileData);
        //console.log(profileData.org_name);
        setCompanyname(profileData.org_name);
      });
  }, [internshipDetails]);

  useEffect(() => {
    // Get software skill list
    const api1 = url + "getSoftwareSkill";
    fetch(api1, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        //console.log(actualdata1)
        setSoftwareskill(actualdata1);
      });
    //get internship details
    const api2 = url + "getRefInternshipDetails";
    fetch(api2, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        //console.log(actualdata1)
        setInternshipDetails(actualdata1);
      });
  }, []);

  useEffect(() => {
    if (locationData) {
      let data = locationData?.map((el) => ({ value: el, label: el }));
      setLocation(data);
    }
  }, [locationData]);

  useEffect(() => {
    if (skillsdata) {
      let data = skillsdata?.map((el) => ({ value: el, label: el }));
      setSkills(data);
    }
  }, [skillsdata]);

  useEffect(() => {
    const api1 = url + "getLocationMasterDetails";
    fetch(api1, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        setCityOptions(actualdata1);
        setTopCities(actualdata1?.slice(0, 10));
        setOtherCities(actualdata1?.slice(10));
      });
  }, [location]);

  const handleChange = (field, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleNext = () => setStep((prevStep) => prevStep + 1);
  const handlePrev = () => setStep((prevStep) => prevStep - 1);

  const handleInternshipDetailClick = (value) => {
    setIntDetails(value);
    if (internshipDetails?.length == 0 || internshipDetails.includes(value)) {
      if (value == "Others") {
        setIntDetails("");
        console.log("setting true");
        setShowOthers(true);
      } else {
        setShowOthers(false);
      }
    } else {
      console.log("setting true");
      setShowOthers(true);
    }
  };

  const handleInternshipTypeClick = (item) => {
    setIntType((prev) => {
      const intTypesArray = prev ? prev.split(",") : [];
      if (intTypesArray.includes(item)) {
        return intTypesArray.filter((intType) => intType !== item).join(",");
      } else {
        return [...intTypesArray, item].join(",");
      }
    });
  };

  // const handleCityClick = (item) => {
  //   setLocation((prev) => {
  //     const locationsArray = prev ? prev.split(',') : [];
  //     if (locationsArray.includes(item.label)) {
  //       return locationsArray.filter(location => location !== item.label).join(',');
  //     } else {
  //       return [...locationsArray, item.label].join(',');
  //     }
  //   });
  // };

  const handlePerkClick = (perkName) => {
    setPerk((prev) => {
      const perksArray = prev ? prev.split(",") : [];
      if (perksArray.includes(perkName)) {
        return perksArray.filter((perk) => perk !== perkName).join(",");
      } else {
        return [...perksArray, perkName].join(",");
      }
    });
  };

  const handleCityClick = (item) => {
    const cityLabel = item.label;
    if (selectedCities.includes(cityLabel)) {
      setSelectedCities(selectedCities.filter((city) => city !== cityLabel));
      setLocation(
        location
          .split(",")
          .filter((city) => city !== cityLabel)
          .join(",")
      );
    } else {
      setSelectedCities([...selectedCities, cityLabel]);
      setLocation(location ? `${location},${cityLabel}` : cityLabel);
    }
  };

  const handleDropdownChange = (selectedOptions) => {
    const selectedCityLabels = selectedOptions.map((option) => option.label);
    setSelectedCities(selectedCityLabels);
    setLocation(selectedCityLabels.join(","));
  };

  // Combine topCities and selectedCities

  const combinedCities = [
    ...topCities,
    ...(otherCities?.filter(
      (city) =>
        selectedCities?.includes(city.label) &&
        !topCities?.some((topCity) => topCity.label === city.label)
    ) || []),
  ];

  const generateAIInternshipDescription = (e) => {


    e.preventDefault();
    //setIsProcessing(true);
    setDescription("");
    setWaitForBot(true);

    const skillValues = skills.map((skill) => skill.value).join(",");

    const postValues = JSON.stringify({
      skill: skillValues,
      stipend_type: stipType,
      compensation1: stipType == "Unpaid" ? null : stipend,
      compensation2: stipType == "Unpaid" ? null : "Months",
      perks: perk,
      internship_details: intDetails,
      location: location,
      type: intType,
      duration1: duration + " Months",
      startdate: "immediately ",
    });

    const api = url + "getAIInternshipDescription";

    try {
      fetch(api, {
        method: "POST",
        body: postValues,
        headers: { "Content-Type": "application/json" },
      })
        .then((apidata) => {
          //console.log(apidata.body)
          return apidata.json();
        })
        .then((actualdata) => {
          if (actualdata["status"] == 1) {
            setAIInternshipDescript(actualdata.html);
          } else {
            var message = actualdata["data"];
            toast.error(message, { autoClose: 1000 });
          }
          setWaitForBot(false);
        });
    } catch (e) {
      setWaitForBot(false);
    }
  };

  const submit = (e) => {
    
    e.preventDefault();
    setIsProcessing(true);
    const skillValues = skills.map((skill) => skill.value).join(",");

    const postValues = JSON.stringify({
      id: intID,
      employer_id: empID,
      email: email,
      companyname: companyname,
      skill: skillValues,
      stipend_type: stipType,
      compensation1: stipType == "Unpaid" ? null : stipend,
      compensation2: stipType == "Unpaid" ? null : "Months",
      perks: perk,
      placement_offer,
      companyname: companyname,
      industry_type: industryType,
      internship_details: intDetails,
      location: location,
      total_opening: openings,
      type: intType,
      duration1: duration,
      duration2: "M",
      startdate: startdate || currentdate,
      job_description: description,
      active: active,
    });

    const form = e.currentTarget;

    // if (form.checkValidity() === false) {
    //   e.stopPropagation();
    //   setValidated(true);
    //   toast.warning("Please fill all mandatory fields", {closeOnClick: true,
    //   pauseOnHover: false,toastId: 'success1', autoClose: 2000});
    //   setIsProcessing(false);
    //   return;
    // }

    const api = url + "updateEmployerInternship";

    try {
      fetch(api, {
        method: "POST",
        body: postValues,
        headers: { "Content-Type": "application/json" },
      })
        .then((apidata) => {
          //console.log(apidata.body)
          return apidata.json();
        })
        .then((actualdata) => {
          if (actualdata["status"] == 1) {
            var message = actualdata["message"];
            //toast.success(message, { autoClose: 1000 });
            alert(message);
            if (!intID) {
              window.location.href =
                "/empdashboard/post-internship/" + actualdata.intshipid;
            }

            //var wait = new Promise((r) => setTimeout(r, 3000));
            // setIsSent(true);
            // toast.success("Internship successfully posted....", { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
          } else {
            var message = actualdata["data"];
            toast.error(message, { autoClose: 1000 });
          }
          setIsProcessing(false);
        });
    } catch (e) {
      setIsProcessing(false);
    }
  };

  const setAIInternshipDescript = (message) => {
    
      const quill = quillRef.current.getEditor();
      // let newContent = `<p>${message}</p>`;
      let newContent = message;
      quill.root.innerHTML = newContent;
      setDescription(newContent);

  };

  const handleQuillChange = (value) => {
    setDescription(value);
  };

  return (
    <Fragment>
      <section className="pt-4 pb-4">
        <CheckEmployerProfileStatus staticModal={true} />

        <Container>
          <Row className="border-bottom pb-2 g-0 align-items-center">
            <Col md={9}>
              <div className="mb-4">
                <Breadcrumb>
                  <Breadcrumb.Item as="li" href="/empdashboard">
                    Dashboard
                  </Breadcrumb.Item>
                  <Breadcrumb.Item as="li" active>
                    Post New Internship
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <h3 className="mb-md-0">Post New Internship / Job</h3>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pb-8 ">
        <Container>
          <Row>
            <Col lg={{ offset: 2, span: 7 }}>
              <Col className="h5 pb-2">
                Organization Name: <span className="h3">{companyname}</span>
              </Col>

              <Form
                noValidate
                // validated={validated}
                className="row"
                onSubmit={submit}
              >
                <Col lg={12}>
                  {/* Card - 1 Start */}
                  <Card className="card-body mb-4">
                    <Row className="row">
                      {/* <Col xs={12}>
          <Form.Group className="mb-3" controlId="industry-type">
            <Form.Label>Industry Type</Form.Label>
            <Select
              options={industryTypeOptions}
              onChange={(e) => handleChange("industryType", e.value)}
              value={{
                label: formValues.industryType,
                value: formValues.industryType,
              }}
              required
            />
          </Form.Group>
        </Col> */}

                      <Col lg={12}>
                        <figure>
                          <blockquote class="blockquote text-secondary">
                            <p>Internship Category</p>
                          </blockquote>
                          <figcaption class="blockquote-footer">
                            Select a category for the internship
                          </figcaption>
                        </figure>
                        <div className="d-flex flex-wrap">
                          {internshipDetails.map((item, index) => (
                            <Button
                              variant={
                                intDetails === item
                                  ? "success"
                                  : "outline-primary"
                              }
                              size={"sm"}
                              key={index}
                              className="m-1"
                              onClick={() => handleInternshipDetailClick(item)}
                            >
                              {item}{" "}
                              {intDetails === item ? (
                                <HiOutlineCheck size={15} className="ms-2" />
                              ) : (
                                <HiOutlinePlusSm size={15} className="ms-2" />
                              )}
                            </Button>
                          ))}
                          <Button
                            variant={
                              intDetails === "Others"
                                ? "success"
                                : "outline-primary"
                            }
                            size={"sm"}
                            className="m-1"
                            onClick={() =>
                              handleInternshipDetailClick("Others")
                            }
                          >
                            Others{" "}
                            {intDetails === "Others" ? (
                              <HiOutlineCheck size={15} className="ms-2" />
                            ) : (
                              <HiOutlinePlusSm size={15} className="ms-2" />
                            )}
                          </Button>
                        </div>
                        {console.log(showOthers)}
                        {showOthers && (
                          <Form.Group
                            controlId="internship-details-others-value"
                            className="mt-3 col-sm-6 col-12"
                          >
                            <Form.Control
                              value={intDetails}
                              onChange={(e) => setIntDetails(e.target.value)}
                              type="text"
                              placeholder="Others"
                              required
                              size="sm"
                              isValid={intDetails?.length > 0}
                              isInvalid={intDetails?.length === 0}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter internship details.
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">
                              Looks good!
                            </Form.Control.Feedback>
                          </Form.Group>
                        )}
                        <Col lg={12} className="mt-3">
                          <Form.Group className="mb-3" controlId="skills">
                            <figure>
                              <blockquote class="blockquote text-secondary">
                                <p>Skills Required</p>
                              </blockquote>
                              <figcaption class="blockquote-footer">
                                Select one or many skills
                              </figcaption>
                            </figure>
                            <Select
                              isMulti
                              options={software_skill_1}
                              value={skills}
                              onChange={(e) => setSkills(e)}
                              required
                            />
                          </Form.Group>
                        </Col>
                      </Col>
                    </Row>
                  </Card>
                  {/* Card - 2 End */}
                  {/* Card - 3 Start */}
                  <Card className="card-body mb-4">
                    <Row>
                      <Col lg={12} className="mt-3">
                        <Form.Group controlId="cities">
                          <figure>
                            <blockquote class="blockquote text-secondary">
                              <p>City/Cities</p>
                            </blockquote>
                            <figcaption class="blockquote-footer">
                              Select one or many cities
                            </figcaption>
                          </figure>

                          <div className="d-flex flex-wrap mb-2">
                            {combinedCities?.map((item, index) => (
                              <Button
                                key={index}
                                variant={
                                  selectedCities?.includes(item.label)
                                    ? "success"
                                    : "outline-primary"
                                }
                                size={"sm"}
                                className="m-1"
                                onClick={() => handleCityClick(item)}
                              >
                                {item.label}{" "}
                                {selectedCities?.includes(item.label) ? (
                                  <HiOutlineCheck size={15} className="ms-2" />
                                ) : (
                                  <HiOutlinePlusSm size={15} className="ms-2" />
                                )}
                              </Button>
                            ))}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} className="mt-2">
                        <span className="small">
                          <em>or select from the list</em>
                        </span>
                        <Select
                          isMulti
                          value={otherCities?.filter((city) =>
                            selectedCities?.includes(city.label)
                          )}
                          onChange={handleDropdownChange}
                          options={otherCities}
                          required
                        />
                      </Col>
                      <Row>
                        <Col lg={6} className="mt-4">
                          <figure>
                            <blockquote class="blockquote text-secondary">
                              <p>No. of openings</p>
                            </blockquote>
                            <figcaption class="blockquote-footer">
                              No. of internship posts
                            </figcaption>
                          </figure>
                          <Form.Group controlId="openings*">
                            <Form.Control
                              value={openings}
                              onChange={(e) => setOpenings(e.target.value)}
                              type="number"
                              placeholder="No of Openings * (Only Numbers)"
                              required
                              isValid={validated && openings.length > 0}
                              isInvalid={validated && openings.length === 0}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter no of openings.
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">
                              Looks good!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Row>
                  </Card>

                  <Card className="card-body mb-4">
                    <Row>
                      <Col lg={12} className="mt-3">
                        <figure>
                          <blockquote class="blockquote text-secondary">
                            <p>Internship Type</p>
                          </blockquote>
                        </figure>

                        <div className="d-flex flex-wrap">
                          {internshipType.map((item, index) => (
                            <Button
                              variant={
                                intType?.includes(item)
                                  ? "success"
                                  : "outline-primary"
                              }
                              size={"sm"}
                              key={index}
                              className="m-1"
                              onClick={() => handleInternshipTypeClick(item)}
                            >
                              {item}{" "}
                              {intType?.includes(item) ? (
                                <HiOutlineCheck size={15} className="ms-2" />
                              ) : (
                                <HiOutlinePlusSm size={15} className="ms-2" />
                              )}
                            </Button>
                          ))}
                        </div>
                      </Col>

                      <Col lg={12} className="mt-4">
                        <figure>
                          <blockquote class="blockquote text-secondary">
                            <p>Internship Duration</p>
                          </blockquote>
                          <figcaption class="blockquote-footer">
                            Internship duration in months
                          </figcaption>
                        </figure>

                        <div className="d-flex flex-wrap">
                          {[1, 3, 6, 12].map((item, index) => (
                            <Button
                              variant={
                                duration == item ? "success" : "outline-primary"
                              }
                              size={"sm"}
                              key={index}
                              className="m-1"
                              onClick={() => setDuration(item)}
                            >
                              {item}
                              {item == 1 ? " Month" : " Months"}
                              {duration == item ? (
                                <HiOutlineCheck size={15} className="ms-2" />
                              ) : (
                                <HiOutlinePlusSm size={15} className="ms-2" />
                              )}
                            </Button>
                          ))}
                        </div>
                      </Col>

                      <Col lg={12} className="mt-2">
                        <span className="small">
                          <em>Or Enter Internship Duration (Months)</em>
                        </span>
                        <Row>
                          <Col lg={9} className="mb-2 mb-lg-0">
                            <InputGroup className="mb-3" controlId="duration">
                              <Form.Control
                                type="text"
                                placeholder="Duration"
                                value={duration}
                                onChange={(e) => setDuration(e.target.value)}
                                required
                              />
                              <InputGroup.Text
                                id="basic-addon1"
                                className="bg-body"
                              >
                                Months
                              </InputGroup.Text>
                            </InputGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                  {/* Card - 3 End */}
                  {/* Card - 4 Start */}
                  <Card className="card-body mb-4">
                    <Row>
                      <Col lg={12} className="mt-3">
                        <Form.Group controlId="cities">
                          <figure>
                            <blockquote class="blockquote text-secondary">
                              <p>Stipend Type</p>
                            </blockquote>
                            <figcaption class="blockquote-footer">
                              Fixed, Performance based or upaid
                            </figcaption>
                          </figure>

                          <div className="d-flex flex-wrap mb-2">
                            {stipendTypeList?.map((item, index) => (
                              <Button
                                key={index}
                                variant={
                                  stipType?.split(",")?.includes(item)
                                    ? "success"
                                    : "outline-primary"
                                }
                                size={"sm"}
                                className="m-1"
                                onClick={() => setStipType(item)}
                              >
                                {item}{" "}
                                {location.split(",")?.includes(item) ? (
                                  <HiOutlineCheck size={15} className="ms-2" />
                                ) : (
                                  <HiOutlinePlusSm size={15} className="ms-2" />
                                )}
                              </Button>
                            ))}
                          </div>
                        </Form.Group>
                      </Col>

                      <Col lg={12} className="mt-3">
                        <figure>
                          <blockquote class="blockquote text-secondary">
                            <p>Stipend</p>
                          </blockquote>
                          <figcaption class="blockquote-footer">
                            Stipend amount in INR
                          </figcaption>
                        </figure>

                        <Row>
                          <div className="d-flex flex-wrap">
                            {[1000, 3000, 5000, 10000, 15000].map(
                              (item, index) => (
                                <Button
                                  variant={
                                    stipend == item
                                      ? "success"
                                      : "outline-primary"
                                  }
                                  size={"sm"}
                                  key={index}
                                  className="m-1"
                                  onClick={() => setStipend(item)}
                                >
                                  {"INR "}
                                  {item}
                                  {stipend == item ? (
                                    <HiOutlineCheck
                                      size={15}
                                      className="ms-2"
                                    />
                                  ) : (
                                    <HiOutlinePlusSm
                                      size={15}
                                      className="ms-2"
                                    />
                                  )}
                                </Button>
                              )
                            )}
                          </div>
                          <Col lg={6} className="mb-2 mb-lg-0">
                            <span className="small">
                              <em>Or Enter Stipend (INR)</em>
                            </span>
                            <InputGroup className="mb-3" controlId="stipend">
                              <InputGroup.Text className="bg-body">
                                INR
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                placeholder="Stipend"
                                value={stipend}
                                onChange={(e) => setStipend(e.target.value)}
                                required
                              />
                              <InputGroup.Text className="bg-body">
                                Per Month
                              </InputGroup.Text>
                            </InputGroup>
                          </Col>
                        </Row>
                      </Col>

                      <Col lg={12} className="mt-3">
                        <figure>
                          <blockquote class="blockquote text-secondary">
                            <p>Perks</p>
                          </blockquote>
                          <figcaption class="blockquote-footer">
                            Select one or many Perks provided during internship
                          </figcaption>
                        </figure>

                        <div className="d-flex flex-wrap">
                          {[
                            "Certificate",
                            "Letter of Recommendation",
                            "Flexible Hours",
                            "Informal Dress Code",
                            "5 Days a Week",
                            "Free Snacks & Beverages",
                          ].map((perkName, index) => (
                            <Button
                              key={index}
                              variant={
                                perk?.includes(perkName)
                                  ? "success"
                                  : "outline-primary"
                              }
                              size={"sm"}
                              className="m-1"
                              onClick={() => handlePerkClick(perkName)}
                            >
                              {perkName}{" "}
                              {perk?.split(",").includes(perkName) ? (
                                <HiOutlineCheck size={15} className="ms-2" />
                              ) : (
                                <HiOutlinePlusSm size={15} className="ms-2" />
                              )}
                            </Button>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  </Card>
                  {/* Card - 4 end */}
                  {/* Card - 5 Start */}
                  <Card className="card-body mb-4">
                    <Row>
                      {/* <Col lg={12} className="mt-3">
                        <Form.Group controlId="description">
                          {console.log(description)}
                          <figure>
                            <blockquote class="blockquote text-secondary">
                              <p>Internship Description</p>
                            </blockquote>
                            <figcaption class="blockquote-footer">
                              IGBot generated Internship description, modify it
                              to fit your specific requirements
                            </figcaption>
                          </figure>
                          <div>
                            <Button
                              variant="outline-primary"
                              size={"sm"}
                              onClick={generateAIInternshipDescription}
                            >
                              Generate Description
                            </Button>
                          </div>
                          <Form.Control
                            as="textarea"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            rows={10}
                            required
                            isValid={validated && description.length > 0}
                            isInvalid={validated && description.length === 0}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter internship description.
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="valid">
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>

                      </Col> */}

                      <Col lg={12} className="mt-3">
                        <Form.Group controlId="description">
                          {console.log(description)}
                          <figure>
                            <blockquote class="blockquote text-secondary">
                              <p>Internship Description</p>
                            </blockquote>
                            <figcaption class="blockquote-footer">
                              IGBot generated Internship description, modify it
                              to fit your specific requirements
                            </figcaption>
                          </figure>
                          <div className="mb-2 text-primary">AI generated internship descirption by IGBot 
                            <Button
                              variant="outline-primary"
                              size={"sm"}
                              className="ms-2"
                              onClick={generateAIInternshipDescription}
                              disabled={waitForBot}
                            >
                              <BsRobot className="me-1" size={20} /> Generate Description

                              {waitForBot && <Spinner size="sm" /> }
                              
                            </Button>
                          </div>
                          <div className="mb-8">
                          <ReactQuill
                            ref={quillRef}
                            value={description}
                            onChange={handleQuillChange}
                            modules={{
                              clipboard: {
                                matchVisual: false,
                              },
                            }}
                            style={{ height: '300px' }} // Set the desired height with inline CSS
                            />
                            </div>
                        </Form.Group>
                      </Col>

                      {/* <Col lg={12} className="mt-3">
                        <Form.Group className="mb-3" controlId="active">
                          <Form.Check
                            checked={active}
                            onChange={(e) =>
                              setActive(e.target.checked)
                            }
                            type="checkbox"
                            label="Active"
                          />
                        </Form.Group>
                      </Col> */}
                    </Row>
                  </Card>
                  {/* Card - 5 end */}
                  {/* <Button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isProcessing}
                  >
                    Submit
                    {isProcessing && (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                        Processing...
                      </>
                    )}
                  </Button> */}
                  {/* Save Draft  OR Post Internship buttons */}
                  <div>
                    <Button
                      variant="outline-primary"
                      disabled={
                        isProcessing ||
                        intObject?.internship_status == "Inactive"
                      }
                      className="ms-1"
                      type="submit"
                      onClick={(e) => {
                        setActive(0);
                        //setIsProcessing(true);
                      }}
                    >
                      Save Draft{" "}
                      {isProcessing && active == 0 && <Spinner size="sm" />}
                    </Button>

                    <Button
                      variant="primary"
                      type="submit"
                      disabled={
                        isProcessing ||
                        intObject?.internship_status == "Inactive"
                      }
                      className="ms-2"
                      onClick={(e) => {
                        setActive(1);
                        //setIsProcessing(true);
                      }}
                    >
                      Save and Post Internship{" "}
                      {isProcessing && active == 1 && <Spinner size="sm" />}
                    </Button>

                    <Button
                      variant="outline-secondary"
                      className="ms-2"
                      onClick={(e) => {
                        history.goBack();
                        //setIsProcessing(true);
                      }}
                    >
                      Back To Dashboard
                    </Button>
                  </div>
                </Col>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default PostNewInternship;
