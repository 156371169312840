import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Container,
  Modal,
  Form,
  Button
} from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import Url from "components/Url";
import GoogleAuthenticator from "./GoogleAuthenticator";
import { GoogleOAuthProvider } from "@react-oauth/google";
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

const MentorLogin = (props) => {
  const [enablelogin, setEnablelogin] = useState(false);
  const url1 = Url();
  const url = url1.url;
  const url2 = url1["url1"];
  const [show, setShow] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('mentortoken'));

  const [activeTab, setActiveTab] = useState("mentor");

  const [showGoogleLogin, setShowGoogleLogin] = useState(false);
  const [showSelectionButtons, setShowSelectionButtons] = useState(true);
  const [showTabNumber, setShowTabNumber] = useState(1);

  if(token) setEnablelogin(true);
  
  if (enablelogin) {
    if (!(sessionStorage.getItem("redirectURL") === null)) {
      var redirectURL = "/" + sessionStorage.getItem("redirectURL");
      window.location.href = redirectURL;
      return;
    }

    window.location.href = "/mentor/dashboard";
    return 1;
  }

  useEffect(() => {
    //setShowSelectionButtons(true);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const email = event.target.email.value;
    const password = event.target.password.value;

    const api = url + "checkMentorLogin";
    fetch(api, {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: { "Content-Type": "application/json" },
    })
      .then((apidata) => {
        return apidata.json();
      })
      .then((actualdata) => {
        if (
          actualdata.message === "Email not verified, please verify your email"
        ) {
          toast.warning(actualdata.message);
          return;
        }
        const success = actualdata["message"];
        const mentor_id = actualdata["id"];
        const mentorToken = actualdata["token"];

        if (success === "logged-in") {
          localStorage.setItem("mentortoken", mentorToken);
          localStorage.setItem("email", email);
          localStorage.setItem("mentorId", mentor_id);
          localStorage.setItem("mentorName", actualdata.fullname);
          localStorage.setItem("token1", actualdata.token1);
          localStorage.setItem("token2", actualdata.token2);
          localStorage.setItem("baseInd",actualdata.baseAuth );
          if (actualdata.mentorStatus == "1") {
            localStorage.removeItem("profileStatus");
          } else {
            localStorage.setItem("profileStatus", "0");
          }
          setEnablelogin(true);
        } else {
          toast.error("Invalid Email or password");
        }
      });
  };

  const handleForgotPass = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const api =
      url + `mentorFpass` + "/" + url2;
    fetch(api, {
      method: "POST",
      body: JSON.stringify({ email }),
      headers: { "Content-Type": "application/json" },
    })
      .then((apidata) => {
        return apidata.json();
      })
      .then((actualdata) => {
        if (actualdata["message"] === "Mail Sent") {
          toast.success("Password sent to your registered Mail-id", {
            position: toast.POSITION.TOP_CENTER,
          });
          setShow(false);
        } else {
          toast.error("Invalid Mail-id", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
        }
      });
  };

  return (
    <section className="py-8" style={{ backgroundColor: '#f8f9fa' }}>
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={8} lg={5}>
            <Card className="shadow border-0" style={{ borderRadius: '15px' }}>
              <Card.Body className="p-5">
                <h4 className="mb-4 text-center" style={{ color: '#000000' }}>Mentor Login</h4>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="mentor-email">
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Mentor Email Id"
                      required
                      style={{ borderRadius: '10px' }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="mentor-password">
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="Password"
                      required
                      style={{ borderRadius: '10px' }}
                    />
                  </Form.Group>
                  <Row className="mb-3">
                    <Col className="d-flex justify-content-between">
                      <Button variant="primary" type="submit" style={{ borderRadius: '10px' }}>
                        Login
                      </Button>
                      <Link
                        to="#"
                        onClick={() => setShow(true)}
                        className="btn btn-link"
                        style={{ color: '#0000FF' }}
                      >
                        Forgot password?
                      </Link>
                    </Col>
                  </Row>
                  <div className="text-center mb-3">
                    <p className="mb-1">Want to be a mentor at InternshipGate? Register</p>
                    <Link to="/mentor/register" style={{ color: '#0000FF' }}>Mentor</Link>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/***********************  FORGOT PASSWORD MODAL START *********************************/}
        <Modal centered show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Forgot Password</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleForgotPass}>
            <Modal.Body>
              <Form.Group className="mb-3" controlId="employer-email">
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Registered email (Mentorship)"
                  required
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                Send Password
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        {/***********************  FORGOT PASSWORD MODAL END *********************************/}
      </Container>
      <ToastContainer />
    </section>
  );
};

export default MentorLogin;
