// import node module libraries
import React, { Fragment, useState, useMemo, useEffect } from 'react'
import {Modal, Button, Row, ModalBody} from 'react-bootstrap';

const CheckStudentProfileStatus = (props) => {

    const {staticModal = false} = props;
    //this component is standalone component that checks for the student profile completion status and shows the dialog box accordingly
    const [show, setShow] = useState(false);
    useEffect(() => {
        
        if(localStorage.getItem("profileStatus") !== 'undefined' && localStorage.getItem("profileStatus") == '0' ){
            console.log("setting show modal");
            setShow(true);
        }

    },[])

    return (
        <Fragment>

            <Modal show={show} onHide={(e) => {setShow(false)} }
                  backdrop={staticModal ? "static" : true} 
                  keyboard={!staticModal}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered>
                <Modal.Header className='py-0 py-2' closeButton={!staticModal}>
                    <Modal.Title className=' d-flex fs-3 justify-content-center w-100' id="contained-modal-title-vcenter">
                        Incomplete Profile
                    </Modal.Title>
                </Modal.Header>            
                <ModalBody  className="py-6 d-flex justify-content-center flex-column">
                     <div className="fs-3 fw-bold text-center pb-5">To find and apply for internships, please <span className='text-secondary'>Complete Your Profile First</span></div>
                    <Row className='d-flex justify-content-center w-100'>
                     <Button href="/student-profile" className="btn w-50 btn-primary">Go To Profile</Button>   
                     </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default CheckStudentProfileStatus;