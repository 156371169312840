import React, { Fragment,useState, useEffect } from "react"
import {
    Col,
    Row,
    Card,
    Breadcrumb,
    Button,
    Table,
    Form
} from 'react-bootstrap';
import {mdiDeleteForever, mdiFileEditOutline } from '@mdi/js';

import DotBadge from 'components/elements/bootstrap/DotBadge';
import {
    useTable,
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
} from 'react-table';
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from 'components/elements/advance-table/Pagination';
import { useMemo } from "react";
import Url from "../../Url";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Icon from "@mdi/react";

toast.configure()
const CapCoupons = () => {

    const url1 = Url()
    const url = url1["url"]

    const admtoken = localStorage.getItem("emptoken")
    //const email = localStorage.getItem("email")

    const [getcouponlist, setGetcouponlist] = useState([])

    const [discountP, setDiscountP] = useState("")

    const [expDate, setExpDate] = useState(null)

    const [createcouponmodal, setCreatecouponmodal] = useState(false)

    const [createSpecialCoupon, setCreateSpecialCoupon] = useState(false)

    const [couponcreated, setCouponcreated] = useState(false)
    const [editSpecialCoupon, setEditSpecialCoupon] = useState(false)
    const [couponName, setCouponName] = useState()
    const [couponCode, setCouponCode] = useState()
    const [isActive, setIsActive] = useState("")
    const [isSpecial, setIsSpecial] = useState("")
    const [couponId, setCouponId] = useState()



    const currentDate = new Date().toISOString().split('T')[0];

    useEffect(() => {
        const api = url + "getCouponList"
        console.log(url)
        fetch(api, {
            method: 'GET',
        })
            .then((apidata1) => {
                return apidata1.json()
            })
            .then((actualdata1) => {
                //console.log(actualdata1)
                setGetcouponlist(actualdata1.couponList)
            }
            )
    }, [couponcreated])

    const createCoupon = () => {
        setCouponcreated(false)
        var id = null;
        if(couponId){
            id = couponId;
        }
        var coupon_name = couponName;
        var coupon_code = couponCode;
        var special_code = isSpecial;
        var discount = discountP;
        var used_no_of_times = isActive;
        var exp_date = expDate;

        // console.log(coupon_name,coupon_code,"Special Code:", special_code, discount, used_no_of_times, exp_date)
        const api = url + "saveCoupon"
        console.log(api)
        fetch(api, {
            method: 'POST',
            body: JSON.stringify({...(id && { id }), coupon_name,coupon_code, special_code, discount, used_no_of_times, exp_date }),
            headers: { 'Content-Type': 'application/json' }
        })
            .then((apidata) => {
                console.log(apidata)
                return apidata.json()
            })
            .then((actualdata) => {
                // console.log(actualdata)
                if (actualdata["message"] === "Record added") {
                    setCouponCode(actualdata.coupon_code)
                    setCreateSpecialCoupon(false)
                    setExpDate("")
                    setCouponcreated(true)
                    toast.success('Coupon generated successfully ....', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
                }
                if (actualdata["message"] === "Record updated") {
                    setEditSpecialCoupon(false)
                    setCouponcreated(true)
                    toast.success('Coupon updated successfully ....', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
                }
            })
    }

    const getCouponbyid = (id) => {

        const api = url + "getCouponDetails" + "/" + id
        // console.log(api)
        fetch(api, {
            method: "GET",  
        })
        .then((apidata1) => {
            return apidata1.json()
        })
        .then((actualdata1) => {
            setCouponId(actualdata1.couponData[0].id)
            setCouponName(actualdata1.couponData[0].coupon_name)
            setDiscountP(actualdata1.couponData[0].discount)
            setCouponCode(actualdata1.couponData[0].coupon_code)
            setExpDate(actualdata1.couponData[0].exp_date)
            setIsActive(actualdata1.couponData[0].used_no_of_times)
            setEditSpecialCoupon(true);
        })
    }

    const deleteOnlineTraining = (id) => {

        const res = window.confirm("The trainig will be deleted, are you sure?")
        if (res) {
            // console.log(id)
            const api = url + "deleteCoupon" + "/" + id
            fetch(api, {
                method: 'DELETE',
                body: JSON.stringify({}),
                headers: { 'Content-Type': 'application/json' }
            })
                .then((apidata) => { 
                    return apidata.json()
                })
                .then((actualdata) => {
                    console.log(actualdata)
                    if(actualdata["message"] === "Record Deleted"){
                        setCouponcreated(true)
                        toast.success('Coupon deleted successfully ....', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
                    }

                })
        }
    }  

    /*--------------------------- Generate CSV ---------------------------*/
    const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
        //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
        var arrData =
            typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

        var CSV = "";

        //This condition will generate the Label/Header
        if (ShowLabel) {
            var row = "";

            //This loop will extract the label from 1st index of on array
            for (var index in arrData[0]) {
                //Now convert each value to string and comma-seprated
                row += index + ",";
            }

            row = row.slice(0, -1);

            //append Label row with line break
            CSV += row + "\r\n";
        }

        //1st loop is to extract each row
        for (var i = 0; i < arrData.length; i++) {
            var row = "";

            //2nd loop will extract each column and convert it in string comma-seprated
            for (var index in arrData[i]) {
                row += '"' + arrData[i][index] + '",';
            }

            row.slice(0, row.length - 1);

            //add a line break after each row
            CSV += row + "\r\n";
        }

        if (CSV === "") {
            alert("Invalid data");
            return;
        }

        //Generate a file name
        var fileName = "IG Virtual Internship coupons";
        //this will remove the blank-spaces from the title and replace it with an underscore
        fileName += ReportTitle.replace(/ /g, "_");

        //Initialize file format you want csv or xls
        var uri = "data:text/csv;charset=utf-8," + escape(CSV);

        // Now the little tricky part.
        // you can use either>> window.open(uri);
        // but this will not work in some browsers
        // or you will not get the correct file extension

        //this trick will generate a temp <a /> tag
        var link = document.createElement("a");
        link.href = uri;

        //set the visibility hidden so it will not effect on your web-layout
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";

        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    /*--------------------------- Generate CSV ---------------------------*/
    // console.log(getcouponlist)
    const columns = useMemo(
        () => [
            { Header: 'Serial no', Cell: (props) => (<span>{props.row.index + 1}</span>)},
            { accessor: 'coupon_name', Header: 'Coupon Name' },
            // { accessor: 'created_at', Header: 'Created Date'},
            { accessor: 'discount', Header: 'Discount %'},
            { accessor: 'exp_date', Header: 'Expiry Date'},
            { accessor: 'coupon_code', Header: 'Coupon Code'},
            { accessor: 'used_no_of_times', Header: 'Status',
            Cell: ({ value, row }) => {
                return (
                    <p>
                        
                        {value === 1 ? (
                            <DotBadge bg="danger">Used</DotBadge>
                            ) : value === 0 && row.original.exp_date < currentDate ? (
                            <DotBadge bg="warning">Expired</DotBadge>
                            ) : (
                            <DotBadge bg="success">Active</DotBadge>
                        )}

                         
                    </p>
                );
            } 
            },
            // { accessor: 'id', Header: 'Subject Name'},
            // { accessor: 'special_code', Header: 'Special'},
            // { accessor: 'updated_at', Header: 'Subject Name'},
            { accessor: 'special_code', Header: 'Action',
                Cell: ({value, row}) => {
                    return(
                        
                        <span>
                            {value === 0 ? 
                             <>
                            <Icon path={mdiFileEditOutline}
                                    color="gray"
                                    disabled
                                    size={"20px"}
                            />
                            <Icon path={mdiDeleteForever}
                                    color="gray"
                                    size={"20px"}
                            />
                             </>
                            : 
                            <>
                            {/* <Button className="btn-sm btn-outline-danger btn-white m-1" onClick={() => deleteOnlineTraining(row.original.id)}>
                                Delete
                            </Button> */}
                            <Icon path={mdiFileEditOutline}
                                    color="orange"
                                    size={"20px"}
                                    onClick={() => {getCouponbyid(row.original.id), setIsSpecial(1)}}
                            />
                            <Icon path={mdiDeleteForever}
                                    color="orange"
                                    size={"20px"}
                                    onClick={() => deleteOnlineTraining(row.original.id)}
                            />
                            {/* <Button variant="secondary" className="btn-sm m-1"onClick={() => {getCouponbyid(row.original.id), setIsSpecial(1)}}>
                                Edit
                            </Button> */}
                            </>
                            }
                        </span>
                        
                )
            }
        },
        ],
        []
        );

        const data = useMemo(() => getcouponlist, [getcouponlist]);

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            nextPage,
            previousPage,
            state,
            gotoPage,
            pageCount,
            prepareRow,
            setGlobalFilter
        } = useTable(
            {
                columns,
                data,
                initialState: {
                    pageSize: 100,
                    hiddenColumns: columns.map((column) => {
                        if (column.show === false) return column.accessor || column.id;
                        else return false;
                    })
                }
            },
            useFilters,
            useGlobalFilter,
            usePagination,
            useRowSelect,
            (hooks) => {
                hooks.visibleColumns.push((columns) => [
                    ...columns
                ]);
            }
        );

        const { pageIndex, globalFilter } = state;
    return (
        <>
            {/*-------------------------- Create Discount coupon Modal-------------------------- */}
            <Modal
                size=""
                show={createcouponmodal}
                className="modalbgscreen d-none d-sm-block"
                aria-labelledby="example-modal-sizes-title-sm"
            >

                <Modal.Body className="mt-0">

                    <Row>
                        Create Virtual Internship Coupons
                    </Row>

                    <Row className="mt-1">
                        <span className="ml-0 mr-1 mt-1">Coupon Name:</span>
                        <span className="mt-0">
                            <Form.Control placeholder="Name of the coupon" className="ml-0 "
                                required
                                onChange={(e) => { setCouponName(e.target.value) }}
                            />
                        </span>
                    </Row>

                    <Row className="mt-1">
                        <span className="ml-0 mr-1 mt-1">Discount %:</span>
                        <span className="mt-0">
                            <Form.Control placeholder="Discount %" className="ml-0 "
                                required
                                onChange={(e) => { setDiscountP(e.target.value) }}
                            />
                        </span>
                    </Row>

                    <Row className="mt-1">
                        <span className="ml-0 mr-1 mt-1">Expiry Date:</span>
                        <span className="mt-0">
                           
                               <Form.Control
                                    required
                                    type="date"
                                    value={expDate}
                                    min={currentDate}
                                    onChange={(e) => setExpDate(e.target.value)}
                                />
                    
                        </span>
                    </Row>

                    <Row className="mt-2 ml-0 mr-0">
                        <Col>
                            <button className="btn btn-primary m-1"
                                onClick={() => createCoupon()}
                            >Save</button>
                             <button className="btn btn-white btn-outline-primary m-1"
                                onClick={() => setCreatecouponmodal(false)}>
                                Cancel
                            </button>
                        </Col>
                    </Row>
                    {couponcreated && 
                    <Row>
                        <span className="fw-bold text-primary mt-2">New Coupon Code: {couponCode}</span>
                    </Row>
                    }

                </Modal.Body>
            </Modal>
            {/*-------------------------- Create Discount coupon Modal end-------------------------- */}

            {/* Create Special Coupon Modal     */}
            <Modal
                size=""
                show={createSpecialCoupon}
                className="modalbgscreen d-none d-sm-block"
                aria-labelledby="example-modal-sizes-title-sm"
            >

                <Modal.Body className="mt-0">

                    <Row>
                        Create Virtual Internship Coupons
                    </Row>

                    <Row className="mt-1">
                        <span className="ml-0 mr-1 mt-1">Type: <b>Special Coupon</b></span>
                    </Row>
                    <Row className="mt-1">
                        <span className="ml-0 mr-1 mt-1">Coupon Name:</span>
                        <span className="mt-0">
                            <Form.Control placeholder="Name of the coupon" className="ml-0 "
                                required
                                onChange={(e) => { setCouponName(e.target.value) }}
                            />
                        </span>
                    </Row>
                    <Row className="mt-1">
                        <span className="ml-0 mr-1 mt-1">Coupon Code:</span>
                        <span className="mt-0">
                            <Form.Control placeholder="e.g WELCOME10" className="ml-0 "
                                required
                                onChange={(e) => { setCouponCode(e.target.value) }}
                            />
                        </span>
                    </Row>

                    <Row className="mt-1">
                        <span className="ml-0 mr-1 mt-1">Discount %:</span>
                        <span className="mt-0">
                            <Form.Control placeholder="Discount %" className="ml-0 "
                                required
                                onChange={(e) => { setDiscountP(e.target.value) }}
                            />
                        </span>
                    </Row>

                    <Row className="mt-1">
                        <span className="ml-0 mr-1 mt-1">Expiry Date:</span>
                        <span className="mt-0">
                        <Form.Control
                                    required
                                    type="date"
                                    value={expDate}
                                    min={currentDate}
                                    onChange={(e) => setExpDate(e.target.value)}
                                />
                        </span>
                    </Row>

                    <Row className="mt-2 ml-0 mr-0">
                        <Col>
                            <button className="btn btn-primary m-1"
                                onClick={() => createCoupon()}
                            >Save</button>
                             <button className="btn btn-white btn-outline-primary m-1"
                                onClick={() => setCreateSpecialCoupon(false)}>
                                Cancel
                            </button>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>

            {/* Edit Special Coupon Modal */}
            <Modal
                size=""
                show={editSpecialCoupon}
                className="modalbgscreen d-none d-sm-block"
                aria-labelledby="example-modal-sizes-title-sm"
            >

                <Modal.Body className="mt-0">

                    <Row>
                        Edit Virtual Internship Coupon
                    </Row>

                    <Row className="mt-1">
                        <span className="ml-0 mr-1 mt-1">Type: <b>Special Coupon</b></span>
                    </Row>
                    <Row className="mt-1">
                        <span className="ml-0 mr-1 mt-1">Coupon Name:</span>
                        <span className="mt-0">
                            <Form.Control placeholder="Name of the coupon" className="ml-0 "
                                required
                                value = {couponName}
                                onChange={(e) => { setCouponName(e.target.value) }}
                            />
                        </span>
                    </Row>
                    <Row className="mt-1">
                        <span className="ml-0 mr-1 mt-1">Coupon Code:</span>
                        <span className="mt-0">
                            <Form.Control placeholder="e.g WELCOME10" className="ml-0 "
                                required
                                value = {couponCode}
                                onChange={(e) => { setCouponCode(e.target.value) }}
                            />
                        </span>
                    </Row>

                    <Row className="mt-1">
                        <span className="ml-0 mr-1 mt-1">Discount %:</span>
                        <span className="mt-0">
                            <Form.Control placeholder="Discount %" className="ml-0 "
                                required
                                value={discountP}
                                onChange={(e) => { setDiscountP(e.target.value) }}
                            />
                        </span>
                    </Row>

                    <Row className="mt-1">
                        <span className="ml-0 mr-1 mt-1">Expiry Date:</span>
                        <span className="mt-0">
                            <Form.Control type="date" placeholder="Expiry Date" className="ml-0 "
                                    required
                                    value={expDate}
                                    min={currentDate}
                                    onChange={(e) => setExpDate(e.target.value)}
                            />
                        </span>
                    </Row>

                    <Row className="mt-2 ml-0 mr-0">
                        <Col>
                            <button className="btn btn-primary m-1"
                                onClick={() => createCoupon()}
                            >Save</button>
                             <button className="btn btn-white btn-outline-primary m-1"
                                onClick={() => setEditSpecialCoupon(false)}>
                                Cancel
                            </button>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>

            <Row className="mt-5"></Row>

            <Fragment>
    <Row>
      <Col lg={12} md={12} sm={12}>
        <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
          <div className="mb-3 mb-md-0">
            <h1 className="mb-1 h2 fw-bold">Virtual Internship Coupon List</h1>
            <Breadcrumb>
              <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item href="#">Career Accelerator</Breadcrumb.Item>
              <Breadcrumb.Item active>Virtual Internship Coupon List</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
          </div>
        </div>
      </Col>
    </Row>
    <Row>
    <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-3 px-3 ">
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              placeholder="Search"
            />
    </Col>
    <Col sm={12} className="ml-0 d-flex justify-content-between">
    <Button     
            className="btn btn-success m-1"
            onClick={() => JSONToCSVConvertor(getcouponlist, "_list", true)}
            >Download Excel
    </Button>
    <div>
    <Button     
            className="btn m-1"
            onClick={() => { setCreatecouponmodal(true),setCouponcreated(false), setIsSpecial("0") }}
            >Create Coupon
    </Button>
    <Button     
            className="btn m-1"
            onClick={() => { setCreateSpecialCoupon(true), setIsSpecial("1") }}
            >Create Special Coupon
    </Button>
    </div>
    </Col>
    </Row>

    <Row className="mt-3">
      <Col lg={12} md={12} sm={12}>
        <Card>
          <Card.Body className="p-0">
            <div className="table-responsive" style={{overflow: "auto",height:"500px"}}>
              <Table {...getTableProps()} className="text-nowrap" style={{height:"400px"}}>
                <thead className="table-light">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>

            {/* Pagination @ Footer */}
            <Pagination
              previousPage={previousPage}
              pageCount={pageCount}
              pageIndex={pageIndex}
              gotoPage={gotoPage}
              nextPage={nextPage}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
            </Fragment>


        </>
    )
}

export default CapCoupons