import React, { Fragment, useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
//import Col from "react-bootstrap/Col";
//import Row from "react-bootstrap/Row";
import {
  Form,
  Col,
  Row,
  Card,
  Button,
  Modal,
  Spinner,
  Image,
} from "react-bootstrap";
import Url from "../../Url";
//import { FormSelect } from 'components/elements/form-select/FormSelect';
import CropImageDialog from "components/ProfileCrop/CropImageDialog";
import { toast } from "react-toastify";
import { FlatPickr } from "components/elements/flat-pickr/FlatPickr";
import Axios from "axios";
import Select from "react-select/creatable";
import ProfileLayout from "./ProfileLayout";

const MentorBasicDetails = () => {
  const mentorId = localStorage.getItem("mentorId");
  const token = localStorage.getItem("admtoken") ? localStorage.getItem("admtoken") : localStorage.getItem("mentortoken");
  const [loading, setLoading] = useState(false);
  const [mentorDetails, setMentorDetails] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [industryTypeList, setIndustryTypeList] = useState([]);

  const [organizationRolesList, setOrganizationRolesList] = useState([]);
  const [organizationList, setOrganizationList] = useState([]);

  const [skillsList, setSkillsList] = useState([]);
  const [mentorSkills, setMentorSkills] = useState([]);

  const [locationList, setLocationList] = useState([]);

  const [skills, setSkills] = useState([]);
  const [isMasterDataLoaded, setIsMasterDataLoaded] = useState(false);
  const [showProfileImageDialog, setShowProfileImageDialog] = useState(false);

  const [updateImage, setUpdateImage] = useState(false);
  
  const [createValidate, setCreateValidate] = useState(false);

  const [formData, setFormData] = useState({
    mentor_id: "",
    fullname: "",
    mobileno: "",
    current_organization_id: "",
    gender_id: "",
    industry_id: "",
    current_role: "",
    work_experience_year: "",
    headline: "",
    short_bio: "",
    language_id: "",
    profile_pic: "",
    mentorship_category: "",
    mentor_rating: "",
    no_of_mentorships: "",
    meeting_link: "",
    sort_order: "",
    top_mentor: "",
    display_current_organization_ind:"1",
  });

  const url1 = Url();
  const url = url1["url"];
  const s3 = url1["s3"];

  const genderList = [
    { value: 1, label: "Female" },
    { value: 2, label: "Male" },
    { value: 3, label: "Non-Binary/Non-Conforming" },
    { value: 4, label: "Prefer Not to Say" },
  ];

  useEffect(() => {
    // Fetch initial data or set default values
    
    fetchMasterData();
  }, []);

  useEffect(() => {
    if(isMasterDataLoaded){
  	  fetchData();
    }
    setLoading(false);
  }, [isMasterDataLoaded]);

  // useEffect(() => {
  //   // Initiates the fetching of master data and then mentor details.
  //   const initializeData = async () => {
  //     await fetchMasterData(); // Make sure this function awaits all its async operations
  //     // Now fetchMentorData only after master data is fully loaded
  //     await fetchData(); // Adjust fetchData to ensure it awaits its async operations too
  //     setLoading(false); // Only set loading to false after all data is fetched
  //   };
  //   initializeData();
  // }, []); // Empty dependency array means this effect runs only once, similar to componentDidMount

  const fetchData = async () => {
    // var map = new Map(response.data.organizationRolesList?.map(option => [option.value, option]));
    // setorganizationRolesListMap (map);
    setLoading(true);
    var api = url + "getMentorshipDetails" + "/" + mentorId;
    try {
      const response = await Axios.get(api, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-USER-ID": mentorId,
        },
      });

      setFormData({
        ...response.data.mentorshipDetails?.[0],

        current_organization_id: organizationList.find(
          (option) =>
            option.value ==
            response.data.mentorshipDetails?.[0].current_organization_id
        ),
        city_id: locationList.find(
          (option) =>
            option.value == response.data.mentorshipDetails?.[0].city_id
        ),
        gender_id: genderList.find(
          (option) =>
            option.value == response.data.mentorshipDetails?.[0].gender_id
        ),
        industry_id: industryTypeList.find(
          (option) =>
            option.value == response.data.mentorshipDetails?.[0].industry_id
        ),
        current_role: organizationRolesList.find(
          (option) =>
            option.value == response.data.mentorshipDetails?.[0].current_role
        ),
        industry_id: industryTypeList.find(
          (option) =>
            option.value == response.data.mentorshipDetails?.[0].industry_id
        ),
        language_id: (response.data.mentorshipDetails?.[0].language_id ? (response.data.mentorshipDetails?.[0].language_id
          .split(",")
          ?.map((language) =>
            languageList.find((option) => option.value == language)
          )) : [])
      });
      console.log("here");

      if (response.data?.mentorSkills?.length > 0 && skillsList.length > 0) {
        let skillsArray = [];
        response.data?.mentorSkills.map((skill, index) => {
          skillsArray.push(
            skillsList.find((option) => option.value == skill.skill_id)
          );
        });

        setMentorSkills(skillsArray);
      } else {
        setMentorSkills([]);
      }
    } catch (error) {
      //var status = error.response.status;
      console.log(error);
      console.log("basic detail error");
      toast.warning(
        "Error loading mentor Details, please logout and login again",
        { autoClose: 1000 }
      );
    }
  };

  const fetchMasterData = async () => {
    
    var api = url + "getMastersForMentorship";
    try {
      const response = await Axios.get(api, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-USER-ID": mentorId,
        },
      });

      // Simultaneously fetch other master data after the first Axios call if needed
      const skillsPromise = fetch(url + "getSoftwareSkillIdLabel").then(
        (response) => response.json()
      );
      const locationPromise = fetch(
        url + "getLocationMasterDetailsIdLabel"
      ).then((response) => response.json());

      // Wait for all promises to resolve
      const [skillsData, locationData] = await Promise.all([
        skillsPromise,
        locationPromise,
      ]);

      //   const api1 = url + "getSoftwareSkillIdLabel";
      //   fetch(api1, {
      // 	method: "GET",
      //   })
      // 	.then((apidata1) => {
      // 	  return apidata1.json();
      // 	})
      // 	.then((actualdata1) => {
      // 	  setSkillsList(actualdata1);
      // 	});
      //   //get internship details
      //   const api2 = url + "getLocationMasterDetailsIdLabel";

      //   fetch(api2, {
      // 	method: "GET",
      //   })
      // 	.then((apidata1) => {
      // 	  return apidata1.json();
      // 	})
      // 	.then((actualdata1) => {
      // 	  setLocationList(actualdata1);
      // 	});

      //setPageHeading("Course Content - " + response.data.onlineTrainingList.[0].subject_name)
      setLanguageList(response.data.languageList);
      setIndustryTypeList(response.data.industryTypeList);
      setOrganizationRolesList(response.data.organizationRolesList);
      setOrganizationList(response.data.organizationList);
      setSkillsList(skillsData);
      setLocationList(locationData);
      setIsMasterDataLoaded(true);
    } catch (error) {
      //var status = error.response.status;
      console.log("master error");
      toast.warning(
        "Error loading mentor Details, please logout and login again",
        { autoClose: 1000 }
      );
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const setLocation = (e) => {
    const { name, value } = e;
    setFormData((prevData) => ({
      ...prevData,
      ["location_id"]: value,
    }));
  };

  const setIndustry = (e) => {
    const { label, value } = e;
    setFormData((prevData) => ({
      ...prevData,
      [industry_id]: value,
    }));
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    setFormData((prevState) => ({
      ...prevState,
      [actionMeta.name]: selectedOption,
    }));
  };

  const handleSubmit = async (e) => {
    
    e.preventDefault();


    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      toast.warning("All fields are mandatory, please make sure that all field values are entered",{autoClose:1000});
      e.stopPropagation();
      setCreateValidate(true);
      return;
    }    
    setLoading(true);

    formData.mentorSkills = mentorSkills;

    if(localStorage.getItem("admtoken")){
      try {
        formData.admin = true;
        // Make PUT or POST request to update data
        var api = url + "saveMentorBasicDetailsAdmin";
        var admtoken = localStorage.getItem("admtoken");
        const response = await Axios.post(api, formData, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${admtoken}`,
            "X-USER-ID": mentorId,
          },
        });
  
        toast.success(response.data.message, { autoClose: 1000 });
        setLoading(false);
      } catch (error) {
        console.error("Error updating data:", error);
        toast.warning(response.data.message, { autoClose: 1000 });
        setLoading(false);
      } finally {
        setLoading(false); // Indicate the end of the save operation, regardless of outcome
      }
    }else{

      try {
        
        // Make PUT or POST request to update data
        var api = url + "saveMentorBasicDetails";

        const response = await Axios.post(api, formData, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-USER-ID": mentorId,
          },
        });

        toast.success(response.data.message, { autoClose: 1000 });
        setLoading(false);
      } catch (error) {
        console.error("Error updating data:", error);
        toast.warning(response.data.message, { autoClose: 1000 });
        setLoading(false);
      } finally {
        setLoading(false); // Indicate the end of the save operation, regardless of outcome
      }
    }
  };


  const handleSaveNewOrganizationName = async (inputValue) => {

    const apiURL = url + "saveOrganization";
    const res = await Axios.post(apiURL, { organization_name: inputValue });

    const result = res.data.organizationList;
    const newOrgId = res.data.newOrgId;
    setOrganizationList(result);
    setFormData({
      ...formData,
      current_organization_id: result.find(
        (option) => option.value == newOrgId
      ),
    });
  };

  const handleSaveNewSkill = async (inputValue) => {

    const apiURL = url + "saveSkill";
    const res = await Axios.post(apiURL, { skill: inputValue });

    const result = res.data.skillList;
    const newSkillId = res.data.newSkillId;
    setSkillsList(result);


    console.log(newSkillId);

    const newSkill = result.find(skill => skill.value === newSkillId);

    console.log(newSkill);
    if (newSkill) {
      setMentorSkills(prevSelected => [...prevSelected, newSkill]);
    } 
  };

  const picSubmit = async (data) => {
    const imgFile = await fetch(data).then((r) => r.blob());
    setUpdateImage(false);
    const uploadPicForm = new FormData();
    uploadPicForm.append("pic", imgFile);
    uploadPicForm.append("mentor_id", formData.mentor_id);
    var api = url + "uploadMentorProfilePic";
    const response = await Axios.post(api, uploadPicForm, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-USER-ID": mentorId,
      },
    });
    if (response.status === 201) {
      setShowProfileImageDialog(false);
      setUpdateImage(true);
      //setFile(null);
      toast.success("Profile picture uploaded successfully....", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };


  return (
    <ProfileLayout updateImage={updateImage}>
      <div>
        {loading ? (
          <Modal show={loading}>
            <Modal.Body>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p>Please Wait....</p>
          </Modal.Body>
          </Modal>
        ) : (
          <Card className="border-0">
            <Card.Header>
              <div className="mb-3 mb-lg-0">
                <h3 className="mb-0">Profile Details</h3>{formData?.verification_ind == 1 ? <span className="badge bg-success">Verified</span> : <span className="badge bg-warning">Unverified</span>}
                <p className="mb-0 small fst-italic">
                {formData?.verification_ind !== 1 && "Complete your profile including the mentorship services and slots. Once Internshipgate has verified all the details, your mentor profile will be displayed in the mentorship listing" }
                </p>
                
              </div>
            </Card.Header>
            <Card.Body>
              <p className="text-danger fst-italic">*All fields are mandatory</p>
              <h5 className="mb-2">Email:{formData.email} </h5>
              <div className="d-lg-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center mb-4 mb-lg-0">
                  <div className="position-relative">
                    <Image
                      src={`${s3}Mentors/${formData.profile_pic}${
                        updateImage ? "?" + new Date().getTime() : ""
                      }`}
                      id="img-uploaded"
                      className="avatar-xl rounded-circle"
                      alt=""
                    />
                    <i
                      className={
                        "fe fe-edit-2 fs-5 bg-primary text-white p-2 rounded-circle"
                      }
                      style={{
                        position: "absolute",
                        bottom: -5,
                        right: -5,
                        cursor: "pointer",
                      }}
                      onClick={() => setShowProfileImageDialog(true)}
                    ></i>
                  </div>
                  <div className="ms-3">
                    <h4 className="mb-0">Your avatar</h4>
                    <p className="mb-0">
                      PNG or JPG no bigger than 800px wide and tall.
                    </p>
                  </div>
                </div>
              </div>
              <hr className="my-5" />
              <div>
                {/* Form */}
                <Form noValidate onSubmit={handleSubmit}>
                  <Row>
                    <Col sm={12} className="mb-3">
                      <Form.Group className="mb-3" controlId="formPhone">
                        <Form.Label>Headline</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Mentor Headline"
                          name="headline"
                          value={formData.headline}
                          onChange={handleChange}
                          required
                          isValid={createValidate && formData.headline}
                          isInvalid={createValidate && !formData.headline}                                 
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} className="mb-3">
                      <Form.Group className="mb-3" controlId="formshort_bio">
                        <Form.Label>Short Bio (Min. 200 characters)</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Short Bio"
                          name="short_bio"
                          value={formData.short_bio}
                          onChange={handleChange}
                          minLength={200}
                          required
                          isValid={createValidate && formData.short_bio}
                          isInvalid={createValidate && !formData.short_bio}                            
                        />
                  
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    {/* First name */}
                    <Col md={6} sm={12} className="mb-3">
                      <Form.Group className="mb-3" controlId="formFullName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Full Name"
                          name="fullname"
                          value={formData.fullname}
                          onChange={handleChange}
                          required
                          isValid={createValidate && formData.fullname}
                          isInvalid={createValidate && !formData.fullname}                              
                        />
                      </Form.Group>
                    </Col>
                    {/* Phone */}
                    <Col md={6} sm={12} className="mb-3">
                      <Form.Group className="mb-3" controlId="formPhone">
                        <Form.Label>Mobile No</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Mobile No"
                          name="mobileno"
                          value={formData.mobileno}
                          onChange={handleChange}
                          required
                          isValid={createValidate && formData.mobileno}
                          isInvalid={createValidate && !formData.mobileno}                             
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12} className="mb-3">
                      <Form.Group className="mb-3" controlId="formcurrent_role">
                        <Form.Label>Gender</Form.Label>
                        <Select
                          name="gender_id"
                          value={formData.gender_id}
                          onChange={handleSelectChange}
                          options={genderList}
                          required
                          isValid={createValidate && formData.gender_id}
                          isInvalid={createValidate && !formData.gender_id}                               
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12} className="mb-3">
                      <Form.Group
                        className="mb-3"
                        controlId="formwork_experience"
                      >
                        <Form.Label>Work Experience</Form.Label>
                        <Form.Control
                          type="text"
                          name="work_experience_year"
                          value={formData.work_experience_year}
                          onChange={handleChange}
                          placeholder="Work Experience"
                          required
                          isValid={createValidate && formData.work_experience_year}
                          isInvalid={createValidate && !formData.work_experience_year}                                
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6} sm={12} className="mb-3">
                      <Form.Group className="mb-3" controlId="formcurrent_role">
                        <Form.Label>Current Organization</Form.Label>
                        <Select
                          isClearable
                          isSearchable
                          name="current_organization_id"
                          value={formData.current_organization_id}
                          onChange={handleSelectChange}
                          options={organizationList}
                          placeholder="Select Current Organization"
                          required
                          onCreateOption={handleSaveNewOrganizationName}
                          isValid={createValidate && formData.current_organization_id}
                          isInvalid={createValidate && !formData.current_organization_id}                             
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12} className="mb-2">
                    <Form.Group className="mb-2" controlId="formActiveInd">
                      <Form.Check
                        type="checkbox"
                        label="Show Current Organization on mentorship page?"
                        name="display_current_organization_ind"
                        checked={formData.display_current_organization_ind}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>                    
                    <Col md={6} sm={12} className="mb-3">
                      <Form.Group className="mb-3" controlId="formcurrent_role">
                        <Form.Label>Current Role</Form.Label>
                        <Select
                          name="current_role"
                          value={formData.current_role}
                          onChange={handleSelectChange}
                          options={organizationRolesList}
                          //onCreateOption={handleCreateCurrentRole}
                          placeholder="Select Current Role"
                          required
                          isValid={createValidate && formData.current_role}
                          isInvalid={createValidate && !formData.current_role}                              
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12} className="mb-3">
                      <Form.Group className="mb-3" controlId="formcurrent_role">
                        <Form.Label>Industry Type</Form.Label>
                        <Select
                          name="industry_id"
                          value={formData.industry_id}
                          onChange={handleSelectChange}
                          options={industryTypeList}
                          placeholder="Select Industry Type"
                          required
                          isValid={createValidate && formData.industry_id}
                          isInvalid={createValidate && !formData.industry_id}                             
                        />
                      </Form.Group>
                    </Col>                    
                    {/* State */}
                    <Col md={6} sm={12} className="mb-3">
                      <Form.Group className="mb-3" controlId="formlocation_id">
                        <Form.Label>Current Location</Form.Label>
                        <Select
                          name="city_id"
                          options={locationList}
                          className=""
                          placeholder="Current City"
                          value={formData.city_id}
                          onChange={handleSelectChange}
                          required
                          isValid={createValidate && formData.city_id}
                          isInvalid={createValidate && !formData.city_id}                             
                        />
                      </Form.Group>
                    </Col>
                    {/* Address Line 1 */}
                    <Col md={6} sm={12} className="mb-3">
                      <Form.Group className="mb-3" controlId="formmentorSkills">
                        <Form.Label>Skills</Form.Label>
                        <Select
                          isMulti
                          name="mentorSkills"
                          options={skillsList}
                          className=""
                          placeholder="Select skills"
                          value={mentorSkills}
                          onChange={(e) => {
                            setMentorSkills(e);
                          }}
                          isClearable
                          isSearchable
                          required
                          onCreateOption={handleSaveNewSkill}
                          isValid={createValidate && formData.mentorSkills?.length > 0}
                          isInvalid={createValidate && !formData.mentorSkills?.length > 0}                              
                        />
                      </Form.Group>
                    </Col>
                    {/* Birthday */}
                    <Col md={6} sm={12} className="mb-3">
                      <Form.Group className="mb-3" controlId="formlanguage_id">
                        <Form.Label>Known Languages</Form.Label>
                        <Select
                          isMulti
                          name="language_id"
                          value={formData.language_id}
                          onChange={handleSelectChange}
                          options={languageList}
                          placeholder="Select Known Languages"
                          required
                          isValid={createValidate && formData.language_id}
                          isInvalid={createValidate && !formData.language_id}                               
                        />
                      </Form.Group>
                      <Form.Control.Feedback type="invalid">
                          Languages are mandatory
                      </Form.Control.Feedback>                      
                    </Col>
                    <Col sm={12} className="mb-3">
                      <Form.Group className="mb-3" controlId="formmeeting_link">
                        <Form.Label>Meeting Link</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Provide your individual meeting link"
                          name="meeting_link"
                          value={formData.meeting_link}
                          onChange={handleChange}
                          required
                          isValid={createValidate && formData.meeting_link}
                          isInvalid={createValidate && !formData.meeting_link}                          
                        />
                        <Form.Text className="text-muted small">
                          This meeting link should be used only for mentorship.
                          The link will be shared with the mentee during
                          booking.
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                          Meeting link is mandatory
                         </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm={12} className="mb-3">
                      <Form.Group className="mb-3" controlId="formmeeting_link">
                        <h3>Commercials</h3>
                        <Form.Text className="text-muted small">
                          Set by Internshipgate after discussing with mentor
                        </Form.Text>
                        {localStorage.getItem("admtoken") ? 
                          (
                          <Col>
                          <Row>
                            <Col xs={12} md={6}>
                              <Form.Label>Mentor Share %</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Provide mentor %"
                                name="income_percentage_mentor"
                                value={formData.income_percentage_mentor}
                                onChange={handleChange}
                                required
                                isValid={createValidate && formData.income_percentage_mentor}
                                isInvalid={createValidate && !formData.income_percentage_mentor}                          
                              />                  
                            </Col>       
                            <Col xs={12} md={6}>
                              <Form.Label>IGate Share %</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Provide Internshipgate %"
                                name="income_percentage_company"
                                value={formData.income_percentage_company}
                                onChange={handleChange}
                                required
                                isValid={createValidate && formData.income_percentage_company}
                                isInvalid={createValidate && !formData.income_percentage_company}                          
                              />          
                            </Col>
                            </Row>                                           
                          </Col>
                          ) :
                          (
                          <>
                          <p className="mt-4"><Form.Label >Mentor Share: {formData.income_percentage_mentor?formData.income_percentage_mentor + "%":"Not Available"}</Form.Label></p>
                          <p><Form.Label>Internshipgate Share: {formData.income_percentage_company ? formData.income_percentage_company + "%": "Not Available"}</Form.Label></p>
                          </>
                          )  
                          }
                        </Form.Group>
                    </Col>                    
                    {/* Button */}
                    <Col sm={12} md={12}>
                      <Button variant="primary" type="submit">
                        Save Profile {loading && <Spinner></Spinner>}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Card.Body>
          </Card>
        )}

        {showProfileImageDialog && (
          <Modal
            show={showProfileImageDialog}
            onHide={() => setShowProfileImageDialog(false)}
            className=""
          >
            <CropImageDialog
              close={() => setShowProfileImageDialog(true)}
              setCroppedImage={picSubmit}
            />
          </Modal>
        )}
      </div>
    </ProfileLayout>
  );
};

export default MentorBasicDetails;
