import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  createRef,
  Fragment,
  useRef,
} from "react";
import {
  Col,
  Container,
  Form,
  ProgressBar,
  Row,
  Accordion,
  Card,
  Modal,
  ListGroup,
  Button,
  Collapse
} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Spinner from "react-bootstrap/Spinner";
import { FiChevronDown, FiChevronUp, FiUser, FiX,FiGrid } from "react-icons/fi";
import { HiDocumentDuplicate, HiCheckCircle } from "react-icons/hi";
import { BiArrowBack } from "react-icons/bi";
import { BsCircleFill, BsCheckCircleFill, BsRobot, BsFillEyeFill,BsXLg,BsFillQuestionCircleFill } from "react-icons/bs";
import "./ProfessionalSummaryAICard.css";
import {FcLeft} from "react-icons/fc";

const ProfessionalSummaryAICard = (props) => {
  {
    /*************************AI Suggestions modal - start **************/
  }

  const {suggestions,
    suggestionsWait,
    getSuggestions,
    setSuggestionsSearchText,
    setProfessionalSummary,
    suggestionsSearchText,
    setShowSuggestions,
    showSuggestions,
    handleSuggestionLineClick
   } = props;

  const tooltip = (
    <Tooltip id="tooltip">
      Use 4-5 keywords like "Software Engineer,5 years experience, ReactJS, Mysql" or "Project Manager, 12 years experience, Banking and financial solutions"
    </Tooltip>
  );
  const [open, setOpen] = useState(false);
  
  return (
  <Fragment>
    <Card className= {showSuggestions ? `visible h-100 ai-suggestion-card` : "invisible ai-suggestion-card"}  >
      <Card.Body className="overflow-auto ps-2 ps-sm-4">
          <Row className="border-bottom pb-3">
          <Form.Group
            as={Col}
            lg={12}
            className="d-sm-flex"
            //controlId="suggestionsSearchText"
          >
            {/* Search box - start */}
            <Col xs={12} md={9} className="mt-2 mt-sm-0">
              
                <Form.Control
                  type="text"
                  placeholder="Software engineer, 5 years experience, ReactJS, MySQL"
                  id="suggestionsSearchText"
                  name="suggestionsSearchText"
                  value={suggestionsSearchText}
                  onChange={(e) =>
                    setSuggestionsSearchText(
                      e.target.value ? e.target.value : ""
                    )
                  }
                />

            </Col>
            {/* Search box - end */}            
            <Col xs={12} md={2} className="ms-2 mt-2 mt-sm-0">
              {/* Search <Robot> button / spinner after search - start */}
              {suggestionsWait ? (
                <Spinner animation="grow" className="ms-3" />
              ) : (
                <Button onClick={getSuggestions}>
                  <BsRobot size={20} />
                  Generate
                </Button>
              )}
              {/* Search <Robot> button / spinner after search - end */}
              
              {/* Help Button - start */}
              <span
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
                className="ms-2"
              >
                <BsFillQuestionCircleFill size={15} />
              </span>
              {/* Help Button - end */}
            </Col>

          </Form.Group>
            <Col xs sm="12">
              <Collapse in={open}>
                <div id="example-collapse-text"><p className="small pt-2">Use 4-5 keywords like "Software Engineer,5 years experience, ReactJS, Mysql" or "Project Manager, 12 years experience, Banking and financial solutions". <span className="fst-italic">From the suggestions, each line is selectable. To copy complete paragraph, click on the blue arrow</span></p></div>
              </Collapse>                
            </Col>
          </Row>
        {suggestions.length > 0 ? (
            <Fragment>
            {suggestions.map((suggestion, index) => {
              return (
                  
                  <Row>
                    <Col className="d-flex align-items-top border-bottom py-2" style={{fontSize:".8rem"}}>
                      <Col xs={2} sm="1">
                        <span
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => setProfessionalSummary(suggestions[index])}                          
                        ><FcLeft size={20} /></span>
                      </Col>
                      <Col xs={10} sm="11">
                      
                      {suggestion.split(".").map((suggestionLine, index) => (
                          <span href="#" className="suggestionLine" onClick={handleSuggestionLineClick} key={index}>{suggestionLine +"."}</span>
                      ))}  
                      </Col>
                    </Col>
                  </Row>
              );
            })}
          </Fragment>
        ) : (
          <Fragment>
            <ListGroup variant="flush">
              <ListGroup.Item>
                Add keywords, click on the IGBot and let IGBot create
                Professional Summary for you..!!!
              </ListGroup.Item>
            </ListGroup>
          </Fragment>
        )}
        <span className="position-absolute top-0 end-0 m-2 m-md-4 strong" style={{cursor: "pointer"}}> <BsXLg size={20} onClick={() => setShowSuggestions(false) } /></span>
      </Card.Body>
    </Card>
  </Fragment>
  
  );
};

export default ProfessionalSummaryAICard;
