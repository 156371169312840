// import node module libraries
import React, { Fragment, useMemo } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Link } from "react-router-dom";
import { Col, Row, Button, Image, Dropdown, Table } from "react-bootstrap";
import { XCircle, MoreVertical } from "react-feather";

// import custom components
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import DotBadge from "components/elements/bootstrap/DotBadge";
import Url from "components/Url";

const CoursesTable = ({ courses_data, setLoad }) => {
  const url1 = Url();
  const s3 = url1.s3;
  const url = url1.url;

  const handleApprove = (id, status) => {
    const api = url + "changeVICourseStatus";
		const config = {
			method: "post",
			body: JSON.stringify({
					courseId: id,
					status
			}),
			headers: {"Content-type" : "application/json"}
		}
		fetch(api, config).then(res => res.json())
		.then((data) => {
			if(data.message === "Course Status Updated"){
				setLoad(true);
			}
		})
  };
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const ActionMenu = () => {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-secondary" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Header>SETTINGS</Dropdown.Header>
          <Dropdown.Item eventKey="1">
            {" "}
            <XCircle size="18px" /> Reject with Feedback
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      { accessor: "subject_id", Header: "ID", show: false },
      {
        accessor: "subject_name",
        Header: "COURSES",
        Cell: ({ value, row }) => {
          let date;
          if (row.original.created_at) {
            date = row.original.created_at.split(" ")[0];
          } else {
            date = "NA";
          }
          return (
            <Link
              className="text-inherit"
              to={
                "/dashboard/course-cms/" +
                row.original.subject_id
              }
            >
              <div
                onClick={() =>
                  window.localStorage.setItem(
                    "subject_id",
                    row.original.subject_id
                  )
                }
                className="d-lg-flex align-items-center"
              >
                <div>
                  <Image
                    src={s3 + "courseImages/" + row.original.preview_image}
                    alt=""
                    className="img-4by3-lg rounded"
                  />
                </div>
                <div className="ms-lg-3 mt-2 mt-lg-0">
                  <h4 className="mb-1 text-primary-hover">{value}</h4>
                  <span className="text-inherit">Added on {date}</span>
                </div>
              </div>
            </Link>
          );
        },
      },
      {
        accessor: "course_duration",
        Header: "Duration",
        Cell: ({ value, row }) => {
          value === 1 ? value="1 Month" : value === 3 ? value="3 Months" : null
          return (
            <div className="d-flex align-items-center">
              <div className="mb-0">{value}</div>
            </div>
          );
        },
      },
      { accessor: "created_at", Header: "", show: false },
      {
        accessor: "lms_course_id",
        Header: "INSTRUCTOR",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex align-items-center">
              <Image
                src={s3 + "Account.svg"}
                alt=""
                className="rounded-circle avatar-xs me-2"
              />
              <h5 className="mb-0">Mihir Choudhary</h5>
            </div>
          );
        },
      },
      {
        accessor: "active_ind",
        Header: "STATUS",

        Cell: ({ value, row }) => {
          {
            value === 0 ? (value = "Draft") : null;
          }
          {
            value === 1 ? (value = "Under Review") : null;
          }
          {
            value === 2 ? (value = "Live") : null;
          }
          return (
            <Fragment>
              <DotBadge
                bg={
                  value === "Draft"
                    ? "warning"
                    : value === "Live"
                    ? "success"
                    : value === "Under Review"
                    ? "danger"
                    : ""
                }
              ></DotBadge>
              {value}
            </Fragment>
          );
        },
      },
      // {
      //   accessor: "course_status",
      //   Header: "ACTION",
      //   Cell: ({ value, row }) => {
      //     console.log();
      //     {value === "Active" ? (value = 2) : value === "Draft" ? (value = 0) : value = 1}
      //     if (value === 1) {
      //       return (
      //         <Fragment>
      //           <Button
      //             href="#"
      //             variant="outline"
      //             className="btn-outline-danger text-black btn-sm"
      //             onClick={() => handleApprove(row.original.subject_id, 0)}
      //           >
      //             Reject
      //           </Button>{" "}
      //           <Button
      //             onClick={() => handleApprove(row.original.subject_id, 2)}
      //             href="#"
      //             variant="success"
      //             className="btn-sm"
      //           >
      //             Approved
      //           </Button>
      //         </Fragment>
      //       );
      //     }
      //     if (value === 2) {
      //       return (
      //         <Fragment>
      //            <Dropdown>
      //   <Dropdown.Toggle as={CustomToggle}>
      //   <Button href="#" variant="secondary" className="btn-sm">
      //             Change Status
      //     </Button>
      //   </Dropdown.Toggle>
      //   <Dropdown.Menu align="end">
      //     <Dropdown.Header>STATUS</Dropdown.Header>
      //     <Dropdown.Item onClick={() => handleApprove(row.original.subject_id, 0)}>
      //       Draft
      //     </Dropdown.Item>
      //     <Dropdown.Item onClick={() => handleApprove(row.original.subject_id, 1)}>
      //       Under Review
      //     </Dropdown.Item>
      //   </Dropdown.Menu>
      // </Dropdown>
      //         </Fragment>
      //       );
      //     }
      //   },
      // },
    ],
    []
  );

  const data = useMemo(() => courses_data, [courses_data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, globalFilter } = state;

  return (
    <Fragment>
      <div className=" overflow-hidden">
        <Row>
          <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-4 px-5 ">
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              placeholder="Search Course"
            />
          </Col>
        </Row>
      </div>

      <div className="table-responsive border-0 overflow-y-hidden">
        <Table {...getTableProps()} className="text-nowrap">
          <thead className="table-light">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      {/* Pagination @ Footer */}
      <Pagination
        previousPage={previousPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        nextPage={nextPage}
      />
    </Fragment>
  );
};

export default CoursesTable;
