import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Container,
  Card,
  Nav,
  Navbar,
  Form,
} from "react-bootstrap";
import "./LandingPage.css";
import checkIcon from "../../assets/images/background/check.png";
import Group from "../../assets/images/png/Group.png";
import Frame from "../../assets/images/png/Frame.png";
import Sakshi from "../../assets/images/png/sakshi.png";
import frame358 from "../../assets/images/png/Frame358.png";
import one from "../../assets/images/png/1.png";
import two from "../../assets/images/png/2.png";
import three from "../../assets/images/png/3.png";
import Group6 from "../../assets/images/png/Group6.png";
import Group7 from "../../assets/images/png/Group7.png";
import Group8 from "../../assets/images/png/Group8.png";
import mobileResume from "../../assets/images/background/mobileResume.png";
import greenCheckIcon from "../../assets/images/svg/checkGreen.svg";
import resumebg from "../../assets/images/background/resumebg.png";
import Slider from "react-slick";
import Api, { ENDPOINTS } from "./api";
import Url from "components/Url";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { BiLockAlt } from "react-icons/bi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoginModalNew from "components/authentication/LoginModalNew";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

export default function LandingPage() {
  const [MobileScr, setMobilescr] = useState(
    window.innerWidth > 520 ? false : true
  );

  const url1 = Url();
  const url = url1["url"];
  const s3 = url1["s3"];
  const templateIMGURL = s3 + "ResumeBuilderTemplates/";
  const studentId = localStorage.getItem("studentId");
  const token = localStorage.getItem("stutoken");
  const history = useHistory();
  const email = localStorage.getItem("email");

  const [resumeTemplates, setResumeTemplates] = useState([]);

  const [loginModalShow, setLoginModalShow] = useState(false);

  const [resumeCategories, setResumeCategories] = useState([]);

  const [selectedFilter, setSelectedFilter] = useState(null);

  const [resumeSubscriptions, setResumeSubscriptions] = useState([]);
  const [seodetails, setSeodetails] = useState("");
  const NextArrow = ({ onClick }) => {
    return (
      <Button className="arrow next" onClick={onClick}>
        <IoIosArrowForward style={{ color: "whitesmoke" }} />
      </Button>
    );
  };

  useEffect(() => {
    if (
      sessionStorage.getItem("redirectURL") &&
      sessionStorage.getItem("resumeTemplate") &&
      studentId
    ) {
      selectThisTemplate(sessionStorage.getItem("resumeTemplate"));
      sessionStorage.removeItem("resumeTemplate");
      sessionStorage.removeItem("redirectURL");
    }

    const api = url + "getResumeTemplateList/1";

    fetch(api, {
      method: "GET",
    })
      .then((apidata) => {
        return apidata.json();
      })
      .then((actualdata) => {
        // console.log(actualdata);
        setResumeTemplates(actualdata.resumeTemplateList);
        setResumeCategories(actualdata.resumeCategories);
      });

    if(studentId){
      const resumeSubscriptionAPI = url + "getResumeSubscriptions/" + studentId;
      var status = 0;
      fetch(resumeSubscriptionAPI, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((apidata1) => {
          status = apidata1.status;
          return apidata1.json();
        })
        .then((actualdata1) => {
          console.log(actualdata1);
          if(status == 403){ 
            setResumeSubscriptions([]);
            toast.warning(actualdata1.message)
          }
          else {
            setResumeSubscriptions(actualdata1.resumeSubscription);
          }
        });
      }

      if(window.location.href.includes("#products")){
        chooseResumePlan();
      }

      /*********** get page SEO Settings */

      var api1 = url + "getPageSeoSettingById/" + 4;
      fetch(api1, {
      method: "GET",
      })
      .then((apidata1) => {
      return apidata1.json();
      })
      .then((actualdata1) => {
      setSeodetails(actualdata1);
      });      


  }, []);

  const PrevArrow = ({ onClick }) => {
    return (
      <Button className="arrow prev" onClick={onClick}>
        <IoIosArrowBack style={{ color: "whitesmoke" }} />
      </Button>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "500px",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const selectThisTemplate = useCallback((resume_template, template_id) => {
    if (!studentId) {
      sessionStorage.setItem("redirectURL", "ResumeBuilder");
      sessionStorage.setItem("resumeTemplate", resume_template);
      //history.push("/student-login");
      setLoginModalShow(true);
    } else {
      //const resume_id = Date.now();
      Api.post(ENDPOINTS.CREATE_RESUME, {
        email,
        resume_template,
        studentId,
        template_id,
      }).then((res) => {
        console.log(res.data);
        history.push(`/resumes/${res.data.new_resume_id}/edit`);
      });
    }
  }, []);

  const settings1 = {
    className: "center",
    infinite: true,
    // autoplay: true,
    // autoplaySpeed: 1500,
    centerMode: true,
    centerPadding: "2px",
    dots: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          centerMode: false,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: false,
        },
      },
    ],
  };

  const chooseResumePlan = () => {
    const elmnt = document.getElementById("resumePlan");
    elmnt.scrollIntoView();
  };

  useEffect(() => {
    function debounce(fn, ms) {
      let timer;
      return (_) => {
        clearTimeout(timer);
        timer = setTimeout((_) => {
          timer = null;
          fn.apply(this, arguments);
        }, ms);
      };
    }
    const debouncedResizeHandler = debounce(() => {
      if (window.innerWidth <= 520) setMobilescr(true);
      else setMobilescr(false);
    }, 100);

    window.addEventListener("resize", debouncedResizeHandler);
    // setGetdevwidth(window.innerWidth + "px");

    //Mobile Optimization start
    return () => window.removeEventListener("resize", debouncedResizeHandler);
  });

  const uniqueResumeCategoryNames = [
    ...new Set(
      resumeCategories.map((category) => category.resume_category_name)
    ),
  ];
  const filterOptions = ["All Templates", ...uniqueResumeCategoryNames];

  // Filter the resume templates based on the selected filter
  const filteredTemplates =
    selectedFilter && selectedFilter !== "All Templates"
      ? resumeTemplates.filter((resumeTemplate) =>
          resumeCategories.some(
            (category) =>
              category.resume_category_name === selectedFilter &&
              category.template_id === resumeTemplate.id
          )
        )
      : resumeTemplates;

  // Handle the selection of a filter option
  const handleFilterSelection = (filter) => {
    setSelectedFilter(filter);
  };

  return (
    <Fragment>
      <Helmet>
        <title>{seodetails.page_title}</title>
        <meta name="keywords" content={seodetails.page_meta_keyword} />
        <meta name="description" content={seodetails.page_meta_description} />
      </Helmet>    
    
      <div className="" style={{ backgroundColor: "#fff" }}>
        <LoginModalNew
          show={loginModalShow}
          setModalShow={setLoginModalShow}
          onHide={() => setLoginModalShow(false)}
          activeTab={"student"}
        />
        <div
          className={`p-3 mb-5 ${MobileScr ? "" : ""}`}
          style={{ backgroundImage: "linear-gradient(0deg,#3644a1,#001289)" }}
        >
          <Container className="d-flex justify-content-evenly">
            <div className="m-5 mt-10">
              <span
                className={`${
                  MobileScr ? "display-3" : "display-2"
                } text-white mb-5`}
              >
                <p className="mb-0">Build a professional</p>

                <b>resume - 10x faster</b>
              </span>
              <div
                className={`${
                  MobileScr ? "display-7" : "display-7"
                } text-white mb-5 mt-4`}
              >
                <p className="mb-0 lead">
                  Create a beautiful resume with ready made templates. Use our
                  <br />
                  <span className="text-warning fw-bold">AI Assistant</span> to
                  tailor your resume to the job you want
                </p>
              </div>
              <span className={`text-center ${MobileScr ? "" : ""}`}>
                <button
                  className="btn btn-warning me-2 mb-2"
                  style={{ backgroundColor: "#f47920" }}
                  onClick={chooseResumePlan}
                >
                  Create Resume
                </button>
                {studentId && (
                  <Link to="/resumes" className="btn btn-outline-white mb-2 me-2">
                    My Resume
                  </Link>
                )}
              </span>
            </div>
            {MobileScr ? (
              " "
            ) : (
              <div>
                <img src={Group} alt=".." width="410" height="487" />
              </div>
            )}
          </Container>
          {/* <div className="d-flex justify-content-center">
            <div className="bg-light w-75 shadow-lg rounded" style={{"marginTop": "-70px"}}>
                  <Row>
                    <Col md={1} className="top-0 start-0 border-end bg-white" style={{"height": "400px"}}>
                      <div className="d-flex flex-column justify-content-between gap-6 p-2">
                        <img src={Vector} style={{"width": "24px", "height": "24px"}}></img>
                        <img src={Vector} style={{"width": "24px", "height": "24px"}}></img>
                        <img src={Vector} style={{"width": "24px", "height": "24px"}}></img>
                        <img src={Vector} style={{"width": "24px", "height": "24px"}}></img>
                        <img src={Vector} style={{"width": "24px", "height": "24px"}}></img>
                      </div>
                    </Col>
                    <Col md={11}>
                      <div className="d-flex bg-white justify-content-between border pt-4" style={{"marginLeft": "-13px"}}>
                        <div>Ai Writing Assistant</div>
                        <div>Rohan Jadhav</div>
                      </div>
                      <div>
                      <form className="w-75 p-4">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">Job Description</label>
                          <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Ask AI writing assistant"/>												
                        </div>
                        <div class="mb-3">
                          <label for="exampleInputPassword1" class="form-label">Description</label>
                          <textarea type="text" class="form-control" id="exampleInputPassword1"/>
                        </div>
                        <button type="submit" class="btn btn-primary">Submit</button>
                      </form>
                      </div>
                    </Col>
                  </Row>
                  </div>
            
          </div> */}
        </div>
        <div className={`py-3 bg-darkpx-4 ${MobileScr ? "d-none" : ""}`}>
          <div className="d-flex justify-content-around flex-wrap">
            <div className="card text-center shadow-none" style={{ width: "18rem" }}>
              <img
                className="card-img-top"
                src={Group6}
                alt="Card image cap"
                style={{ height: "190px", width: "200px", marginLeft: "44px" }}
              />
              <div className="card-body">
                <img src={one} className="card-title"></img>
                <h4 className="card-text">Choose a Template</h4>
                <p>
                  Choose from our extensive template collection and personalize to
                  your style.
                </p>
              </div>
            </div>
            <div className="card text-center shadow-none" style={{ width: "18rem" }}>
              <img
                className="card-img-top"
                src={Group7}
                alt="Card image cap"
                style={{ height: "190px", width: "200px", marginLeft: "44px" }}
              />
              <div className="card-body">
                <img src={two} className="card-title"></img>
                <h4 className="card-text">Enter Data</h4>
                <p>Click to add job-specific content, pre-written by experts.</p>
              </div>
            </div>
            <div className="card text-center shadow-none" style={{ width: "18rem" }}>
              <img
                className="card-img-top"
                src={Group8}
                alt="Card image cap"
                style={{ height: "190px", width: "200px", marginLeft: "44px" }}
              />
              <div className="card-body">
                <img src={three} className="card-title"></img>
                <h4 className="card-text">Download Your Resume</h4>
                <p>Download resume in the file format you need and send!</p>
              </div>
            </div>
          </div>
        </div>
        <div className={`py-3 px-4 mb-5 ${MobileScr ? "" : "d-none"}`}>
          <Slider {...settings}>
            <div
              className="card text-center shadow-none justify-content-center"
              style={{ width: "18rem" }}
            >
              <img
                className="card-img-top"
                src={Group6}
                alt="Card image cap"
                style={{ height: "190px", width: "200px", marginLeft: "50px" }}
              />
              <div className="card-body text-center">
                <img
                  src={one}
                  className="card-title"
                  style={{ marginLeft: "100px" }}
                ></img>
                <h4 className="card-text">Choose a Template</h4>
                <p>
                  Choose from our extensive template collection and personalize to
                  your style.
                </p>
              </div>
            </div>
            <div
              className="card text-center shadow-none  align-items-center"
              style={{ width: "18rem" }}
            >
              <img
                className="card-img-top"
                src={Group7}
                alt="Card image cap"
                style={{ height: "190px", width: "200px", marginLeft: "50px" }}
              />
              <div className="card-body">
                <img
                  src={two}
                  className="card-title"
                  style={{ marginLeft: "100px" }}
                ></img>
                <h4 className="card-text">Enter Data</h4>
                <p>Click to add job-specific content, pre-written by experts.</p>
              </div>
            </div>
            <div
              className="card text-center shadow-none  align-items-center"
              style={{ width: "18rem" }}
            >
              <img
                className="card-img-top"
                src={Group8}
                alt="Card image cap"
                style={{ height: "190px", width: "200px", marginLeft: "50px" }}
              />
              <div className="card-body">
                <img
                  src={three}
                  className="card-title"
                  style={{ marginLeft: "100px" }}
                ></img>
                <h4 className="card-text">Download Your Resume</h4>
                <p>Download resume in the file format you need and send!</p>
              </div>
            </div>
          </Slider>
        </div>
        <div className="d-flex flex-column bg-light pt-5 pb-5 justify-content-center">
          <h1 className="blackcolor text-center display-6" id="resumePlan">
            AI-Generated Resume Templates
          </h1>
          <br />

          <p className="greycolor text-center display-9">
            Unleash your potential with our AI-based resume builder
            <br />
            The smart way to stand out and land your dream job
          </p>
          {/* NavBar */}
          <Nav className="navbar border-bottom shado d-flex justify-content-center gap-6 pb-0 ">
            <div className="filter-options-container">
              {filterOptions.map((filterOption, index) => (
                <button
                  key={index}
                  onClick={() => handleFilterSelection(filterOption)}
                  className={`filter-button ${
                    selectedFilter === filterOption ? "active" : ""
                  }`}
                >
                  <span className="button-text">{filterOption}</span>
                </button>
              ))}
            </div>
          </Nav>

          <Slider {...settings1} className="my-4">
            {filteredTemplates.map((resumeTemplate, index) => (
              <div className="position-relative" key={index}>
                <div className="image">
                  <img
                    src={templateIMGURL + resumeTemplate.template_preview_image}
                    alt={resumeTemplate.template_name}
                  />
                </div>
                <div className="position-absolute top-50 start-50 translate-middle">
                  {(resumeSubscriptions.length == 0 ) ? (
                    index === 0 ? (
                      <Button
                        size="md"
                        onClick={() =>
                          selectThisTemplate(
                            resumeTemplate.template_name,
                            resumeTemplate.id
                          )
                        }
                      >
                        Select Free Template
                      </Button>
                    ) : (
                      <Link to="/ResumeBuilder/Pricing">
                        <Button
                          className="bg-warning border-0"
                          size="md"
                          // onClick={() =>
                          //   selectThisTemplate(
                          //     resumeTemplate.template_name,
                          //     resumeTemplate.id
                          //   )
                          // }
                        >
                          <BiLockAlt size={20} className="me-1" />
                          Unlock Template
                        </Button>
                      </Link>
                    )
                  ) : (
                    <Button
                      size="md" className="SelectTemplate"
                      onClick={() =>
                        selectThisTemplate(
                          resumeTemplate.template_name,
                          resumeTemplate.id
                        )
                      }
                    >
                      Select Template
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="container text-center mt-5 pt-5 mb-3">
          <h2>The Benefits</h2>
          <p className="mb-5 h5">
            Create stunning resumes effortlessly with pre-designed templates,
            <br /> formatting options, and content suggestions.
          </p>
          <div className="d-flex justify-content-around flex-wrap">
            <div className="bg-light text-center p-4 rounded">
              <img src={frame358} className="w-100"></img>
            </div>
            <div className="d-flex flex-column justify-content-between p-3">
              <div className="bg-light p-4 rounded">
                <h4>Easy to edit online</h4>
                <p>
                  Easy-to-edit feature that takes the hassle out of updating
                  resume. <br />
                  With just a few clicks, effortlessly modify information, add or
                  remove sections, <br />
                  and customize the design to suit your preferences
                </p>
              </div>
              <div className="p-4">
                <h4>AI writing assistance</h4>
                <p>
                  Say goodbye to guesswork and uncertainty. AI-powered platform
                  also offers <br /> real-time feedback and suggestions to help
                  you improve your resume's content
                </p>
              </div>
              <div className="p-4">
                <h4>Export file any format online</h4>
                <p>
                  Facility that allows you to export your resume in various file
                  formats.
                  <br /> Whether you prefer a PDF, Word document, or even a plain
                  text file
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="bg-light p-5"
          style={{ marginBottom: "80px", marginTop: "80px" }}
        >
          {/* <p
            className={` bluecolor ${
              MobileScr ? "display-6" : "display-4"
            } text-center mb-1`}
          >
            <p className="fw-normal mb-0">Trusted by students</p>

            <b>around the world</b>
          </p> */}
          <div className="text-center mt-4">
            <h2>Trusted by Students</h2>
            <p className="w-75 mx-auto h5">
              Read on to explore their journeys and see how our innovative
              technology has transformed their job prospects.
              <br /> Discover how our platform has empowered students like you to
              create professional resumes
            </p>
          </div>
          <Slider {...settings}>
            <div className="d-flex justify-content-center">
              <div className={` card ${MobileScr ? "w-100" : "w-75"}`}>
                <div
                  className={`d-md-flex d-flex justify-content-between flex-wrap`}
                >
                  <Col md={5}>
                    <img src={s3 + "testimonials/Student_test1.jpg"} alt=".." height="413px" className="p-2" />
                  </Col>
                  <Col md={6}>
                    <div className="d-flex flex-column justify-content-between p-2 mt-5">
                      <div>
                        <img
                          src={Frame}
                          className="mb-3"
                          style={{ width: "40px", height: "40px" }}
                        ></img>
                      </div>
                      <div>
                        <p>
                          I recently used a resume builder on the InternshipGate
                          website, and it was an incredible experience. The
                          user-friendly platform offered modern templates,
                          pre-written content suggestions, and customization
                          options. It provided valuable guidance and allowed me to
                          export my resume in multiple formats. Highly recommended
                          for creating a polished and impactful resume with
                          InternshipGate!
                        </p>
                        <div>
                          <h4>Aditya Choudhary</h4>
                          Internet of things (IOT) Intern
                        </div>
                      </div>
                    </div>
                  </Col>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className={` card ${MobileScr ? "w-100" : "w-75"}`}>
                <div
                  className={`d-md-flex d-flex justify-content-between flex-wrap`}
                >
                  <Col md={5}>
                    <img
                      src={
                        "https://internshipgates3.s3.ap-south-1.amazonaws.com/stuprofilepicupload/Ankit.jpg"
                      }
                      alt=".."
                      width="100%"
                      className="p-2"
                    />
                  </Col>
                  <Col md={6}>
                    <div className="d-flex flex-column justify-content-between p-2 mt-5">
                      <div>
                        <img
                          src={Frame}
                          className="mb-3"
                          style={{ width: "40px", height: "40px" }}
                        ></img>
                      </div>
                      <div>
                        <p>
                          The Internshipgate resume builder application was a
                          game-changer for me. As a recent graduate, I was
                          struggling to create a compelling resume that showcased
                          my skills effectively. However, with the Internshipgate
                          resume builder's user-friendly interface and expert
                          design templates, I was able to craft a professional and
                          eye-catching resume in no time. It not only saved me
                          hours of frustration but also helped me land multiple
                          interviews. Thanks to the resume builder, I secured my
                          dream job and kick-started my career with confidence.
                        </p>
                        <div>
                          <h4>Ankit Kumar</h4>
                          DevOps Intern
                        </div>
                      </div>
                    </div>
                  </Col>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className={` card ${MobileScr ? "w-100" : "w-75"}`}>
                <div
                  className={`d-md-flex d-flex justify-content-between flex-wrap`}
                >
                  <Col md={5}>
                    <img
                      src={
                        "https://internshipgates3.s3.ap-south-1.amazonaws.com/stuprofilepicupload/PriyanshSharma.jpg"
                      }
                      alt=".."
                      height="100%"
                      className="p-2"
                    />
                  </Col>
                  <Col md={6}>
                    <div className="d-flex flex-column justify-content-between p-2 mt-5">
                      <div>
                        <img
                          src={Frame}
                          className="mb-3"
                          style={{ width: "40px", height: "40px" }}
                        ></img>
                      </div>
                      <div>
                        <p>
                          The resume builder application with AI was a
                          game-changer for me. The AI facility provided valuable
                          suggestions and personalized feedback, ensuring that my
                          resume was optimized for success. Thanks to this
                          innovative tool, I secured multiple job offers and found
                          the perfect position that aligned with my career
                          aspirations. I'm incredibly grateful for the AI-powered
                          resume builder, and I highly recommend it to anyone
                          looking to elevate their job search to new heights.
                        </p>
                        <div>
                          <h4>Priyansh Sharma</h4>
                          Web Development intern
                        </div>
                      </div>
                    </div>
                  </Col>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </Fragment>
  );
}
