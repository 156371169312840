// import node module libraries
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Avatar1 from 'assets/images/avatar/avatar-1.jpg';
// import sub components
import TestimonialCard from './TestimonialCard';

const TestimonialsSlider = () => {
	const settings = {
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					initialSlide: 2
				}
			},
			{
				breakpoint: 540,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};
	const TestimonialsList = [
		{
			id: 1,
			name: 'Sandip Chauhan',
			designation: 'Delhi',
			image: "https://internshipgates3.s3.ap-south-1.amazonaws.com/Account.svg",
			content:
			"Enrolling in the Internshipgate Mentorship Programme was one of the best decisions I made for my career. The guidance and support I received were exceptional, helping me navigate the complexities of the industry with ease. Thank you for such a valuable experience!",
			rating: 5.0,
			color: 'primary'
		},
		{
			id: 2,
			name: 'Meenal singh',
			designation: 'Kanpur',
			image: "https://internshipgates3.s3.ap-south-1.amazonaws.com/Account.svg",
			content:
			"Internshipgate Mentorship Programme exceeded my expectations in every way. The mentor assigned to me provided invaluable insights and personalized advice that accelerated my professional growth. I am truly grateful for the opportunity to be a part of this program.",
			rating: 5.0,
			color: 'info'
		},
		{
			id: 3,
			name: 'Soumya Nagpure',
			designation: 'Maharashtra',
			image: "https://internshipgates3.s3.ap-south-1.amazonaws.com/Account.svg",
			content:
			"I can't speak highly enough of the Internshipgate Mentorship Programme! The mentorship I received was tailored to my needs, and the guidance provided was instrumental in shaping my career path. I highly recommend this program to anyone looking to kickstart their career journey.",
			rating: 5.0,
			color: 'danger'
		},
	];	
	return (
		<Fragment>
			<Slider {...settings} className="pb-5 mb-5 testimonials">
				{TestimonialsList.map((item, index) => (
					<div className="item p-2" key={item.id}>
						<TestimonialCard key={index} item={item} />
					</div>
				))}
			</Slider>
		</Fragment>
	);
};

// Specifies the default values for props
TestimonialsSlider.defaultProps = {
	recommended: false,
	popular: false,
	trending: false
};

// Typechecking With PropTypes
TestimonialsSlider.propTypes = {
	recommended: PropTypes.bool,
	popular: PropTypes.bool,
	trending: PropTypes.bool
};

export default TestimonialsSlider;
