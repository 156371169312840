// import node module libraries
import React, { Fragment, useMemo } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Link } from "react-router-dom";
import { Col, Row, Button, Image, Dropdown, Table, Badge } from "react-bootstrap";
import { XCircle, MoreVertical } from "react-feather";

// import custom components
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import DotBadge from "components/elements/bootstrap/DotBadge";
import Url from "components/Url";

const MyCourses = (props) => {

	const url1 = Url();
	const s3 = url1.s3;
	const url = url1.url;	
  const { VICourses } = props;
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const ActionMenu = () => {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-secondary" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Header>SETTINGS</Dropdown.Header>
          <Dropdown.Item eventKey="1">
            {" "}
            <XCircle size="18px" /> Reject with Feedback
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      { accessor: "project_ai_topic_id", Header: "ID", show: false },
      {
        accessor: "subject_name",
        Header: "COURSES",
        Cell: ({ value, row }) => {
          let date;
          if (row.original.created_at) {
            date = row.original.created_at.split(" ")[0];
          } else {
            date = "NA";
          }
          return (
            <Fragment>
            {row.original.course_status != "Expired" ? 
            (
            <Link
              className="text-inherit"
              to={row.original.last_visited_lms_page}
            >
              <div
                onClick={() =>
                  window.localStorage.setItem(
                    "subject_id",
                    row.original.course_id
                  )
                }
                className="d-lg-flex align-items-center"
              >
                <div>
                  <Image
                    src={s3 + "courseImages/" + row.original.preview_image}
                    alt=""
                    className="img-4by3-lg rounded"
                  />
                </div>
                <div className="ms-lg-3 mt-2 mt-lg-0">
                  <h4 className="mb-1 text-primary-hover">{value}</h4>
                  <span className="text-inherit">Added on {row.original.start_date}</span>
                </div>
              </div>
            </Link>
            ) : 
            (

              <div className="d-lg-flex align-items-center">
                <div>
                  <Image
                    src={s3 + "courseImages/" + row.original.preview_image}
                    alt=""
                    className="img-4by3-lg rounded"
                  />
                </div>
                <div className="ms-lg-3 mt-2 mt-lg-0">
                  <h4 className="mb-1 text-primary-hover">{value}</h4>
                  <span className="text-inherit">Added on {row.original.start_date}</span>
                </div>
              </div>

            )
            }
            </Fragment>
          );
        },
      },
      {
        accessor: "course_duration_desc", Header: "Duration"
      },
      {
        accessor: "validity_date", Header: "Validity Date"
      },	  
      {
        accessor: "course_status", Header: "STATUS",
		Cell: ({ value, row }) => {

			return (
				<span>
				{
        row.original.test_result == 1 ? <Badge bg="success">Completed</Badge> : (
        value == "Expired" ? (
					<Badge className="small" bg="danger">
						{value}
					</Badge>
				  ) : (
					<Badge bg="warning">{value}</Badge>
				  )
          )
				}
				</span>
			);

		}
      },
      // {
      //   accessor: "shortcutmenu",
      //   Header: "",
      //   Cell: () => {
      //     return <ActionMenu />;
      //   },
      // },
    ],
    []
  );

  const data = useMemo(() => VICourses, [VICourses]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, globalFilter } = state;

  return (
    <Fragment>
      <div className=" overflow-hidden">
        <Row>
          <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-4 px-5 ">
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              placeholder="Search Course"
            />
          </Col>
        </Row>
      </div>

      <div className="table-responsive border-0 overflow-y-hidden">
        <Table {...getTableProps()} className="text-nowrap">
          <thead className="table-light">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      {/* Pagination @ Footer */}
      <Pagination
        previousPage={previousPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        nextPage={nextPage}
      />
    </Fragment>
  );
};

export default MyCourses;
