// import node module libraries
import React, { useState, useEffect } from 'react';
import {useLocation} from 'react-router-dom';

// import sub components
import NavbarVertical from './NavbarVertical';
import HeaderDefault from './HeaderDefault';
import LoginModalNew from 'components/authentication/LoginModalNew';

const DashboardIndex = (props) => {
  const { children, className, overflowHidden } = props;
  const [showMenu, setShowMenu] = useState(true);
  const [loginModalShow, setLoginModalShow] = useState(false);
  const location = useLocation();

  const isLMS = location.pathname.includes("lms");

  //Internshipgate - Very Basic Authentication - start
  const [adminId, setIsAutheticated] = useState(
    localStorage.getItem('adminid')
  );
  const [adminToken, setAdminToken] = useState(
    localStorage.getItem('admtoken')
  );

  const studentId = localStorage.getItem("studentId");
  const studentToken = localStorage.getItem("stutoken");

  //Internshipgate - Very Basic Authentication - end

  const ToggleMenu = () => {
    return setShowMenu(!showMenu);
  };
  useEffect(() => {
    document.body.style.backgroundColor = '#f1f5f9';

    //Internshipgate - Very Basic Authentication - start
    if(isLMS && (!studentId || !studentToken)){
      sessionStorage.setItem("redirectURL","lms");
      setLoginModalShow(true);
      return;
    }else if(!isLMS && (!adminId || !adminToken) ) {
      window.location.href = '/authentication/sign-in';
    }
    //Internshipgate - Very Basic Authentication - end
      //Hide the whatsapp widget - start
      const waWidget = document.getElementById("whatsappwidget");
      if(waWidget)
        waWidget.style.visibility = "hidden";
      //Hide the whatsapp widget - end
  });

  return (
    <div
      id='db-wrapper'
      className={`${overflowHidden ? 'chat-layout' : ''} ${
        showMenu ? '' : 'toggled'
      }`}
    >
      <div className='navbar-vertical navbar' style={{fontSize:"8px !important"}} >
        <NavbarVertical
          showMenu={showMenu}
          onClick={(value) => setShowMenu(value)}
        />
      </div>
      <div id='page-content'>
        <div className='header'>
          <HeaderDefault
            data={{
              showMenu: showMenu,
              SidebarToggleMenu: ToggleMenu,
            }}
          />
        </div>
        <div className={`container-fluid ${className ? className : 'p-4'}`}>
          {children}
        </div>
      </div>
      <LoginModalNew show={loginModalShow} setModalShow={setLoginModalShow} onHide={() => setLoginModalShow(false)} activeTab={"student"} />
    </div>
  );
};
export default DashboardIndex;
