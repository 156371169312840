import React, { Fragment,useState, useEffect } from "react"
import { useForm } from "react-hook-form";

import {
    Col,
    Row,
    Dropdown,
    Card,
    Breadcrumb,
    Button,
    Table,
    Form
} from 'react-bootstrap';
import {
    useTable,
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
} from 'react-table';
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from 'components/elements/advance-table/Pagination';
import { useMemo } from "react";

// import Navadmin from './Navadmin'
import Url from "../../Url";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { act } from "react-dom/test-utils";

toast.configure()
const CoursePricing = () => {

    const { register, handleSubmit } = useForm()

    const url1 = Url()
    const url = url1["url"]
    const s3 = url1["s3"]

    

    const [getcoursedetails, setGetcoursedetails] = useState([])
    const [getcourseplan, setGetcourseplan] = useState("")
    const [deletescuucess, setDeletescuucess] = useState(false)

    const [planType, setPlanType] = useState()
    const [isActive, setisActive] = useState()
    const [mrp, setMrp] = useState()
    const [id, setId] = useState()
    const [modal, setModal] = useState(false)
    const [addModal, setAddModal] = useState(false);

    useEffect(() => {
        const api = url + "getCoursePricingList"
        
        fetch(api, {
            method: 'GET',
        })
            .then((apidata1) => {
                return apidata1.json()
            })
            .then((actualdata1) => {
                setGetcoursedetails(actualdata1.coursePricingList)
            }
            )
    }, [deletescuucess])

    useEffect(() => {
        const api = url + "getCoursePlans"

        fetch(api, {
            method: 'GET',
        })
        .then((apidata1) => {
            return apidata1.json()
        })
        .then((actualdata1) => {
            // console.log(actualdata1.planList)
            setGetcourseplan(actualdata1.planList)
        })
    }, [])
    
    const getOTbyid = (id) => {
        const api = url + "getCoursePricingDetails" + "/" + id

        fetch(api, {
            method: 'GET',
        })
            .then((apidata1) => {
                return apidata1.json()
            })
            .then((actualdata1) => {
                // console.log(actualdata1.coursePricingData[0])
                setId(actualdata1.coursePricingData[0].id)
                setMrp(actualdata1.coursePricingData[0].course_mrp)
                setisActive(actualdata1.coursePricingData[0].active_ind)
                setPlanType(actualdata1.coursePricingData[0].course_duration)
                setModal(true)
            }
        )
    }


    const saveCoursePlan = (data) => {
        // console.log(data)
        
        var course_duration = data.level;
        var course_mrp = data.mrp;

        var active_ind = data.status;
       
        console.log("f:", id, course_duration, course_mrp, active_ind)
        const api  = url + "saveCoursePricing"

        fetch(api, {
            method: "POST",
            body: JSON.stringify({...(id && { id }), course_duration, course_mrp, active_ind}),
            headers: {"Content-Type": "application/json"}
        })
        .then((apidata) => {
            console.log(apidata)
            return apidata.json()
        })
        .then((actualdata) => {
            if(actualdata["message"] === "Record added"){
                setDeletescuucess(true);
                setModal(false);
                toast.success("Course Pricing added succesfully!")
            }

            if(actualdata["message"] === "Record updated"){
                setDeletescuucess(true);
                setModal(false);
                toast.success("Record updated succesfully!")
            }
        })
        

    }

    const CustomCell = ({ value }) => {
        const maxHeight = "40px";
        const whiteSpace = value.length > 30 ? "pre-wrap" : "nowrap";
        return (
          <div style={{ maxHeight, overflow: "hidden", whiteSpace }}>{value}</div>
        );
    };

    const columns = useMemo(
        () => [
            { accessor: 'course_duration', Header: 'Course Duration' },
            { accessor: 'course_mrp', Header: 'Course Price'},
            { accessor: 'created_at', Header: 'Registration Date'},
            { accessor: 'active_ind', Header: 'Status',
                Cell: ({value}) => {
                    return(
                        <>
                            {value === 1 ? <span>Active</span> : <span>Not Active</span>}
                        </>
                    )
                }
            },
            { accessor: 'action', Header: 'Action',
                Cell: ({value, row}) => {
                    return(
                        <>
                            <Button className="btn-sm m-1 btn-outline-warning btn-white" onClick={() => getOTbyid(row.original.id)}>
                                Edit
                            </Button>
                        </>
                    )
                }
            },
        ],
        []
        );


    const data = useMemo(() => getcoursedetails, [getcoursedetails]);
        data.reverse();
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            nextPage,
            previousPage,
            state,
            gotoPage,
            pageCount,
            prepareRow,
            setGlobalFilter
        } = useTable(
            {
                columns,
                data,
                initialState: {
                    pageSize: 100,
                    hiddenColumns: columns.map((column) => {
                        if (column.show === false) return column.accessor || column.id;
                        else return false;
                    })
                }
            },
            useFilters,
            useGlobalFilter,
            usePagination,
            useRowSelect,
            (hooks) => {
                hooks.visibleColumns.push((columns) => [
                    ...columns
                ]);
            }
        );

        const { pageIndex, globalFilter } = state;


    return (
        <>

            <Modal
                size=""
                show={addModal}
                className="modalbgscreen d-none d-sm-block"
                aria-labelledby="example-modal-sizes-title-sm"
            >

                <Modal.Body className="mt-0 firstloginmodal modalnotification">

                    <form onSubmit={handleSubmit(saveCoursePlan)}>
                        <Row>
                            Add/Edit Pricing
                        </Row>

                        {getcourseplan &&  <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Plan Type:</span>
                            <span>
                                <Form.Control as="select" placeholder="Plan Type" className="ml-0 "

                                    name="level"
                                    required
                                    ref={register}
                                    onChange={(e) => {
                                        setPlanType(e.target.value)
                                    }}
                                >  
                                    {getcourseplan.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.plan_name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </span>
                        </Row>}

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Course MRP</span>
                            <span>
                                <Form.Control placeholder="eg. 4999" className="ml-5 "

                                    name="mrp"
                                    required
                                    ref={register}
                                    onChange={(e) => setMrp(e.target.value)}
                                />
                            </span>

                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Is active:</span>
                            <span>
                                <Form.Control as="select" placeholder="Is active" className="ml-4"

                                    name="status"
                                    required
                                    ref={register}
                                    onChange={(e) => setisActive(e.target.value)}
                                >
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </Form.Control>

                            </span>
                        </Row>

                        <Row className="mt-2 ml-0 mr-0">
                            <Col>
                                <Button className="m-1" type="submit">
                                    Save
                                </Button>
                                 <Button className="m-1 ml-0 btn-outline-primary btn-white"
                                    onClick={() => setAddModal(false)}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
            {/*-------------------------- Create Training Modal end-------------------------- */}


            {/*-------------------------- Update Training Modal-------------------------- */}
           
              <Modal
                size=""
                show={modal}
                className="modalbgscreen d-none d-sm-block"
                aria-labelledby="example-modal-sizes-title-sm"
            >

                <Modal.Body className="mt-0 firstloginmodal modalnotification">

                    <form onSubmit={handleSubmit(saveCoursePlan)}>
                        <Row>
                            Add/Edit Pricing
                        </Row>

                        {getcourseplan &&  <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Plan Type:</span>
                            <span>
                                <Form.Control as="select" placeholder="Plan Type" className="ml-0 "

                                    name="level"
                                    required
                                    ref={register}
                                    value={planType}
                                    onChange={(e) => {
                                        setPlanType(e.target.value)
                                    }}
                                >  
                                    {getcourseplan.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.plan_name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </span>
                        </Row>}

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Course MRP</span>
                            <span>
                                <Form.Control placeholder="eg. 4999" className="ml-5 "

                                    name="mrp"
                                    required
                                    ref={register}
                                    value={mrp}
                                    onChange={(e) => setMrp(e.target.value)}
                                />
                            </span>

                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Is active:</span>
                            <span>
                                <Form.Control as="select" placeholder="Is active" className="ml-4"

                                    name="status"
                                    required
                                    ref={register}
                                    onChange={(e) => setisActive(e.target.value)}
                                >
                                    <option value={isActive}>{isActive === 1 ? "Yes": "No"}</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </Form.Control>

                            </span>
                        </Row>

                        <Row className="mt-2 ml-0 mr-0">
                            <Col>
                                <Button className="m-1" type="submit">
                                    Save
                                </Button>
                                 <Button className="m-1 ml-0 btn-outline-primary btn-white"
                                    onClick={() => setModal(false)}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
            {/*-------------------------- Update Training Modal end-------------------------- */}


            <Fragment>
    <Row>
      <Col lg={12} md={12} sm={12}>
        <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
          <div className="mb-3 mb-md-0">
            <h1 className="mb-1 h2 fw-bold">Course Pricing</h1>
            <Breadcrumb>
              <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item href="#">Courses</Breadcrumb.Item>
              <Breadcrumb.Item active>Course Pricing</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
          </div>
        </div>
      </Col>
    </Row>
    <Row>
    <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-3 px-3 ">
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              placeholder="Search"
            />
    </Col>
    <Col sm={12} className="ml-0">
    <Button 
            onClick={() => { setAddModal(true) }}
            >Add Pricing</Button>
            </Col>
    </Row>

    <Row className="mt-3">
      <Col lg={12} md={12} sm={12}>
        <Card>
          <Card.Body className="p-0">
            <div className="table-responsive" style={{overflow: "auto",height:"500px"}}>
              <Table {...getTableProps()} className="text-nowrap" style={{height:"400px"}}>
                <thead className="table-light">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>

            <Pagination
              previousPage={previousPage}
              pageCount={pageCount}
              pageIndex={pageIndex}
              gotoPage={gotoPage}
              nextPage={nextPage}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
            </Fragment>

        </>
    )
}

export default CoursePricing