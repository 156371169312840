// import node module libraries
import { useState, useEffect, Fragment } from 'react';
import { format } from 'date-fns'
import {
    Row,
    Col,
    Card,
    Modal,
    Button,
    Form,
    Alert,ButtonGroup,ToggleButton, Spinner, Badge
} from 'react-bootstrap';
import Url from 'components/Url';
// import custom components
import { FormSelect } from 'components/elements/form-select/FormSelect';
import { FlatPickr } from 'components/elements/flat-pickr/FlatPickr';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { BsExclamationCircle, BsCheckCircleFill, BsMortarboardFill   } from "react-icons/bs";
import { PiCertificateFill } from "react-icons/pi";
import { FaLocationDot } from "react-icons/fa6";
import { FaUserCheck } from "react-icons/fa";
import { IoCalendarNumber } from "react-icons/io5";
import { BsCalendar2Check } from "react-icons/bs";

const CandidateInfoCardVIStudents = (props) => {
    const { item, infoType, employerInternshipId,internshipApplicationId, url, setLoad, setId, setName, handleShow,
        internship_status,employerInternshipDetails
    } = props;

    const [modalShow, setModalShow] = useState(false);
    const token = localStorage.getItem("emptoken")
    const [loading, setLoading] = useState(false)
    //console.log(internshipApplicationId);
    const [showWarning, setShowWarning] = useState(false);
    
    const viewStudentProfile = () => {
        var studentStatus = (item.hired_ind == 1 ? "Hired" 
                             : item.rejected_ind == 1 ? "Rejected" 
                             : item.shortlisted_ind == 1 ? "Shortlisted"
                             : "Not Shortlisted");

        window.sessionStorage.setItem("studentID", item.student_id)
        window.sessionStorage.setItem("user", "E")
        window.sessionStorage.setItem("studentStatus",studentStatus)
        window.sessionStorage.setItem("employerInternshipId",employerInternshipId)
        window.sessionStorage.setItem("internshipApplicationId",internshipApplicationId)
        window.location.href="/student-profile"
    }

    const shortlist = (studentId,internshipId) => {
        setLoading(true)
        const response = window.confirm("Do you want to Shortlist this candidate, Are you sure?")
        if (response === true) {
            const api = url + "shortlistApplicant";
            fetch(api, {
              method: "POST",
              body: JSON.stringify({ internshipId, studentId }),
              headers: { "Content-Type": "application/json" },
            })
              .then((apidata) => {
                //console.log(apidata.body)
                return apidata.json();
              })
              .then((actualdata) => {
                if (actualdata["message"] === "Already_applied") {
                    toast.warning("Student shortlisted successfully");
                  setLoad(true);
                  setLoading(false)
                  return;
                }
                if (actualdata["message"] === "student_internship created") {
                    toast.success("Student shortlisted successfully");
                    setLoad(true);
                    setLoading(false)
                }
              });
        }
        else {
            return;
        }
    }

    return (
        <>
        <ToastContainer />
        <Card className="mb-4">
            <Card.Body>
                
                    <Row>
                        <div className="col-md-9 col-12 d-flex align-items-center">
                    <h2 className="mb-0 text-capitalize">{item.student_name}</h2>
                    <span className={"ms-3 badge text-"+
                                    (item.percentMatch >=75 ? "bg-success" : 
                                     item.percentMatch <75 && item.percentMatch >=50 ? "bg-warning" : 
                                     item.percentMatch <50 && item.percentMatch >=25 ? "bg-secondary" 
                                     : "bg-danger" )}>
                                    {item.percentMatch ? item.percentMatch + "% skills match" : null}
                    </span>
                    </div>
                    {item.internship_interest_status == 1 && 
                        <Fragment>
                            <div className="col-md-3 col-12">
                                <Badge bg="light"  style={{"color":"var(--geeks-primary)",backgroundColor:"white"}} >
                                <span className="ml-auto pe-1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill-check" viewBox="0 0 16 16">
                                    <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                                    <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z"/>
                                    </svg>
                                </span>
                                Actively looking
                                </Badge>
                            </div> 
                        </Fragment>
                    }
                    </Row>
                
                <div className="mt-4">
                {item.software_skill && 
                        <Row className="mb-2">
                            <Col md={4} xs={12}>
                                <span className="me-1"><FaUserCheck /></span> <span className="text-uppercase ls-md">Skills:</span>
                            </Col>
                            <Col md={8} xs={12} className="d-flex alicol-12">
                                <span className="ms-4">{item.software_skill?.replaceAll(',',', ')}</span>
                            </Col>
                        </Row>
                    }
                    {item.all_courses && 
                        <Row className="mb-2">
                            <Col md={4} xs={12}>
                                <span className="me-1"><PiCertificateFill /> </span> <span className="text-uppercase ls-md">Certified In:</span>
                            </Col>
                            <Col md={8} xs={12} className="d-flex alicol-12">
                                <span className="ms-4">{item.all_courses}</span>
                            </Col>
                        </Row>
                    }                     
                    <Row className="mb-2 ">
                        <Col md={4} xs={12}>
                            <span className="me-1"><FaLocationDot /></span> <span className="text-uppercase ls-md">Location:</span>
                        </Col>
                        <Col md={8} xs={12} className="d-flex align-items-strach">
                            <span className="ms-4">{item.student_location ? item.student_location : "Not Available"}</span>
                        </Col>
                    </Row>
                   
                    <Row className="mb-2">
                        <Col md={4} xs={12}>
                            <span className="me-1"><BsMortarboardFill /></span> <span className="text-uppercase ls-md">Education:</span>
                        </Col>
                        <Col md={8} xs={12} className="d-flex ali">
                            <span className="ms-4">{item.degree ? item.degree : "Not Available"}</span>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={4} xs={12}>
                        <span className="me-1">
                            <BsCheckCircleFill />
                        </span>
                        <span className="text-uppercase ls-md" style={{fontSize:"0.775rem"}}>Internship Status:</span>
                        </Col>
                        <Col md={8} xs={7} className="d-flex alicol-12">
                            <Badge bg={`ms-4 badge bg-${item.internship_status == 'Completed' ? 'primary' : "success"} `} className="ms-4"  >{item.internship_status}</Badge>

                        </Col>
                    </Row>            
                    <Row className="mb-2">
                        <Col md={4} xs={12}>
                        <span className="me-1">
                            <IoCalendarNumber />
                        </span>
                        {item.validity_date ?
                        <span className="text-uppercase ls-md" style={{fontSize:"0.775rem"}}>Internship Completion Date:</span>
                        :
                         <span className="text-uppercase ls-md" style={{fontSize:"0.775rem"}}>Profile Created on:</span>
                        }
                        </Col>
                        <Col md={8} xs={7} className="d-flex alicol-12">
                            {item.validity_date ?
                                <span className="ms-4">{item.validity_date}</span>
                            :
                                <span className="ms-4">{item.profile_creation_date}</span>
                            }
                        </Col>
                    </Row>                               
                </div>
                <Row>

                    <div className=" mt-3">
                        
                        <Button variant="outline-secondary" onClick={() => viewStudentProfile()} className="mx-md-2 mt-2 mt-md-0">View Profile</Button>
                        <ScheduledInterview
                            show={modalShow}
                            data={{
                                name: item.student_name, 
                                interviewID: item.interview_id,
                                companyName: employerInternshipDetails.companyname,
                                intDetails: employerInternshipDetails.internship_details,
                                internshipApplicationId: internshipApplicationId
                            }}
                            onHide={() => setModalShow(false)}
                        />

                        {item.hired_ind == 1 && internshipApplicationId
                            ?   <Fragment>
                                    <span className="me-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="green" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                        </svg>                         
                                    </span>
                                    <span className="text-uppercase">
                                        Hired
                                    </span>
                                </Fragment>       
                            : item.rejected_ind == 1 
                            ?   <Fragment>
                                    <span className="me-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="red" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                        </svg>                         
                                    </span>
                                    <span className="text-uppercase">
                                        Rejected
                                    </span>
                                </Fragment> 
                            :item.shortlisted_ind == 1 
                            ? <Fragment>
                                <span className="me-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="gray" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                    </svg>                         
                                </span>
                                <span className="text-uppercase">
                                    Shortlisted
                                </span>
                            </Fragment>  
                            :                                                           
                            <Button variant="light"  disabled={internship_status !== "Active"} onClick={() => shortlist(item.student_id,employerInternshipId)}>{loading ? <>Shortlist <Spinner size="sm" /></> : "Shortlist"}</Button>
                        }
                        {internshipApplicationId ? 
                        <Fragment>
                            <Button variant="outline-success"  disabled={internship_status !== "Active"} className='mx-2' onClick={() => {
                                    handleShow(true)
                                    setId(item.id)
                                    setName(!token ? item.companyname : item.student_name)
                            }}
                            >
                                Chat
                            </Button>            
                            <Button variant="primary" disabled={internship_status !== "Active"} onClick={() => setModalShow(true)}> 
                                Schedule Interview
                            </Button>
                        </Fragment>
                        :
                        <Fragment>
                            <Button variant="outline-success"  disabled={internship_status !== "Active"} className='mx-2' onClick={() => {
                                    setShowWarning(true)
                            }}
                            >
                                Chat
                            </Button>            
                            <Button variant="primary" disabled={internship_status !== "Active"} onClick={() => setShowWarning(true)}> 
                                Schedule Interview
                            </Button>
                        </Fragment>                        
                        }
                    </div>        
                </Row>
            </Card.Body>
        </Card>
            <Modal show={showWarning} onHide={(e) => {setShowWarning(false)} }
                    backdrop="static"
                    keyboard={false}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered>
                <Modal.Header className='py-0 py-2' closeButton={true}>
                    <Modal.Title className=' d-flex fs-3 justify-content-center w-100' id="contained-modal-title-vcenter py-4">
                        <BsExclamationCircle size={40} className="text-warning"  />
                    </Modal.Title>
                </Modal.Header>            
                <Modal.Body  className="py-6 d-flex justify-content-center flex-column">
                
                     <div className="fs-3 text-center pb-5">To commence a chat or arrange an interview with the applicant, kindly shortlist the candidate.</div>
                </Modal.Body>
            </Modal>
        </>

    )
}

/*********************************************************************************************/
/******************************** INTERVIEW SCHEDULER ****************************************/
/*********************************************************************************************/

const ScheduledInterview = (props) => {
    const startTimeOptions = [
        { value: '', label: 'Start Time' },
        { value: '2:45pm', label: '2:45pm' },
        { value: '3:00pm', label: '3:00pm' },
        { value: '3:15pm', label: '3:15pm' },
        { value: '3:30pm', label: '3:30pm' },
        { value: '3:45pm', label: '3:45pm' },
        { value: '4:00pm', label: '4:00pm' },
        { value: '4:15pm', label: '4:15pm' },
        { value: '4:30pm', label: '4:30pm' },
        { value: '4:45pm', label: '4:45pm' },
        { value: '5:00pm', label: '5:00pm' },
        { value: '5:15pm', label: '5:15pm' },
        { value: '5:30pm', label: '5:30pm' },
        { value: '5:45pm', label: '5:45pm' },
        { value: '6:00pm', label: '6:00pm' },
        { value: '6:15pm', label: '6:15pm' },
        { value: '6:30pm', label: '6:30pm' },
        { value: '6:45pm', label: '6:45pm' },
        { value: '7:00pm', label: '7:00pm' },
        { value: '7:15pm', label: '7:15pm' },
        { value: '7:30pm', label: '7:30pm' },
        { value: '7:45pm', label: '7:45pm' },
        { value: '8:00pm', label: '8:00pm' },
        { value: '8:15pm', label: '8:15pm' },
        { value: '8:30pm', label: '8:30pm' },
        { value: '8:45pm', label: '8:45pm' },
        { value: '9:00pm', label: '9:00pm' },
        { value: '9:15pm', label: '9:15pm' },
        { value: '9:30pm', label: '9:30pm' },
        { value: '9:45pm', label: '9:45pm' }
    ];

    const endTimeOptions = [
        { value: '', label: 'End Time' },
        { value: '2:45pm (0 mins)', label: '2:45pm (0 mins)' },
        { value: '3:00pm (15 mins)', label: '3:00pm (15 mins)' },
        { value: '3:15pm (30 mins)', label: '3:15pm (30 mins)' },
        { value: '3:30pm (45 mins)', label: '3:30pm (45 mins)' },
        { value: '3:45pm (1 hr)', label: '3:45pm (1 hr)' },
        { value: '4:00pm (1 hr 15 mins)', label: '4:00pm (1 hr 15 mins)' },
        { value: '4:15pm (1 hr 30 mins)', label: '4:15pm (1 hr 30 mins)' },
        { value: '4:30pm (1 hr 45 mins)', label: '4:30pm (1 hr 45 mins)' },
        { value: '4:45pm (2 hrs)', label: '4:45pm (2 hrs)' },
        { value: '5:00pm (2 hrs 15 mins)', label: '5:00pm (2 hrs 15 mins)' },
        { value: '5:15pm (2 hrs 30 mins)', label: '5:15pm (2 hrs 30 mins)' },
        { value: '5:30pm (2 hrs 45 mins)', label: '5:30pm (2 hrs 45 mins)' },
        { value: '5:45pm (3 hrs)', label: '5:45pm (3 hrs)' },
        { value: '6:00pm (3 hrs 15 mins)', label: '6:00pm (3 hrs 15 mins)' },
        { value: '6:15pm (3 hrs 30 mins)', label: '6:15pm (3 hrs 30 mins)' },
        { value: '6:30pm (3 hrs 45 mins)', label: '6:30pm (3 hrs 45 mins)' },
        { value: '6:45pm (4 hrs)', label: '6:45pm (4 hrs)' },
        { value: '7:00pm (4 hrs 15 mins)', label: '7:00pm (4 hrs 15 mins)' },
        { value: '7:15pm (4 hrs 30 mins)', label: '7:15pm (4 hrs 30 mins)' },
        { value: '7:30pm (4 hrs 45 mins)', label: '7:30pm (4 hrs 45 mins)' },
        { value: '7:45pm (5 hrs)', label: '7:45pm (5 hrs)' },
        { value: '8:00pm (5 hrs 15 mins)', label: '8:00pm (5 hrs 15 mins)' },
        { value: '8:15pm (5 hrs 30 mins)', label: '8:15pm (5 hrs 30 mins)' },
        { value: '8:30pm (5 hrs 45 mins)', label: '8:30pm (5 hrs 45 mins)' },
        { value: '8:45pm (6 hrs)', label: '8:45pm (6 hrs)' },
        { value: '9:00pm (6 hrs 15 mins)', label: '9:00pm (6 hrs 15 mins)' },
        { value: '9:15pm (6 hrs 30 mins)', label: '9:15pm (6 hrs 30 mins)' },
        { value: '9:30pm (6 hrs 45 mins)', label: '9:30pm (6 hrs 45 mins)' },
        { value: '9:45pm (7 hrs)', label: '9:45pm (7 hrs)' },
        { value: '10:00pm (7 hrs 15 mins)', label: '10:00pm (7 hrs 15 mins)' }

    ];

    const interviewTypeList = [
        { name: 'Video Call', value: '1' },
        { name: 'Phone', value: '2' },
        { name: 'In Office', value: '3' },
    ];        
    const [checked, setChecked] = useState(false);
    const [radioValue, setRadioValue] = useState('1');
  
    const data = props.data
    const [showVideo, setShowVideo] = useState(true)
    const [showPhone, setShowPhone] = useState(false)
    const [showOffice, setShowOffice] = useState(false)
    const [title, setTitle] = useState("")
    const [date, setDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [duration, setDuration] = useState();
    const [link, setLink] = useState("");
    const [address, setAddress] = useState("");
    const [contact, setContact] = useState("")
    const [description, setDescription] = useState("")
    const url1 = Url();
    const url = url1["url"];
    const [interviewType, setInterviewType] = useState('1');
    const [loading, setLoading] = useState(false)

    const [item, setItem] = useState({ InterviewType: 1 });
    const { InterviewType } = item;

    const loadInterviewSchedule = (id) => {
        const api = url + "getInterviewScheduleById/" + id;
    //Umesh - 20220906 - end
    fetch(api, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        //console.log(actualdata1)
        if(actualdata1.length > 0){
          setTitle(actualdata1[0].interview_title);
          setDuration(actualdata1[0].interview_duration);
          setDate(actualdata1[0].interview_datetime?.substr(0,10));
          setStartTime(actualdata1[0].interview_datetime?.substr(11,5));
          setLink(actualdata1[0].interview_link);
          setContact(actualdata1[0].interview_contactno);
          setAddress(actualdata1[0].interview_address);
          setDescription(actualdata1[0].interview_description);
          setInterviewType(actualdata1[0].interview_type);
          setItem(prevState => ({
            ...prevState,
            InterviewType: actualdata1[0].interview_type
        }))
        
        switch (actualdata1[0].interview_type) {
            case 1: 
                setShowVideo(true); setShowPhone(false); setShowOffice(false);
                break;
            case 2:
                setShowVideo(false); setShowPhone(true); setShowOffice(false);
                break;
            case 3:
                setShowVideo(false); setShowPhone(false); setShowOffice(true);
                break;
            default :   
                setShowVideo(true); setShowPhone(false); setShowOffice(false);
        }
        }
        
      });
      }

    useEffect(() => {
        setTitle("Interview Schedule - " + data.companyName + ": " + data.intDetails);
        if(data?.interviewID){
          loadInterviewSchedule(data?.interviewID);
        }
      }, []);

      const submit = (e) => {
        setLoading(true)
        e.preventDefault()
        var statusCode;
        const interviewDateTime = date + " " + startTime;
        const api = url + "updateInterviewSchedule";
        if(!(interviewDateTime !== false)){
            toast.warning("Please select interview duration");
            setLoading(false)
        }
        if(!(item !== false)){
            toast.warning("Please select interview duration");
            setLoading(false)
        }    
        if(!(duration !== false)){
            toast.warning("Please select interview duration");
            setLoading(false)
        }    
               
        fetch(api, {
          method: "POST",
          body: JSON.stringify({ id: data?.interviewID, 
                                 internshipApplicationId: data?.internshipApplicationId, 
                                 title: title, 
                                 desc: description, 
                                 interviewDateTime: interviewDateTime,
                                 interviewType: item?.InterviewType,
                                 duration: duration,
                                 address: address,
                                 interviewLink: link,
                                 contactno: contact
                                }),
          headers: { "Content-Type": "application/json" },
        })
          .then((apidata1) => {
            statusCode = apidata1.status
            return apidata1.json();
          })
          .then((actualdata1) => {
            //console.log(actualdata1)
    
            if (statusCode === 200) {
                toast.success("Interview scheduled successfully");
                setLoading(false)
              props.onHide()
            }
          });
      };

    
    const handleChange = e => {
        e.persist();
        setInterviewType(e.target.value);
        setItem(prevState => ({
            ...prevState,
            InterviewType: e.target.value
        }));

        //console.log(e.target.value);
        switch (e.target.value) {
            case '1': 
                setShowVideo(true); setShowPhone(false); setShowOffice(false);
                break;
            case '2':
                setShowVideo(false); setShowPhone(true); setShowOffice(false);
                break;
            case '3':
                setShowVideo(false); setShowPhone(false); setShowOffice(true);
                break;
            default :   
                setShowVideo(true); setShowPhone(false); setShowOffice(false);
        }
    };

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            keyboard={false} 
            backdrop={"static"}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <span><BsCalendar2Check /></span>
                    <span className="ms-2">Schedule Interview</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>To: <span className="bg-light text-dark py-1 px-2 rounded-3 text-capitalize">{data.name}</span></span>
                <Form className="row mt-4 g-2" onSubmit={submit}>
                    <Form.Group as={Col} lg={12} className="mb-3" controlId="add-title">
                        <Form.Label>Add Title</Form.Label>
                        <Form.Control type="text" value={title} placeholder="e.g. First round Interview with Leader" onChange={(e) => setTitle(e.target.value)} required />
                    </Form.Group>
                    <Form.Group as={Col} lg={6} className="mb-3" controlId="interview-date">
                        <Form.Label>Interview date</Form.Label>
                        <input
                        type="date"
                        className="w-100 p-2 rounded"
                        value={date}
                        id="interviewDate"
                        required
                        onChange={(e) => setDate(e.target.value)}
                        />
                        {/* <Form.Control as={FlatPickr} value={''} placeholder="Select Date" required /> */}
                    </Form.Group>
                    <Form.Group as={Col} lg={6} className="mb-3" controlId="interview-time">
                        <Form.Label>Interview time</Form.Label>
                        <input
                        type="time"
                        className="w-100 p-2 rounded"
                        value={startTime}
                        id="interviewTime"
                        required
                        onChange={(e) => setStartTime(e.target.value)}
                        />
                        {/* <Form.Control as={FlatPickr} value={''} placeholder="Select Date" required /> */}
                    </Form.Group>
                    {/* <Col xs={7} className="mb-3">
                        <Form.Label>Interview time <small className="text-muted">IST (+5:30 hours GMT)</small></Form.Label>
                        <Row className="g-2">
                            <Form.Group as={Col} md={6} className="mb-3" controlId="start-time">
                                <Form.Control as={FormSelect} options={startTimeOptions} value={startTime} required defaultselected='' />
                            </Form.Group>
                            <Form.Group as={Col} md={6} className="mb-3" controlId="end-time">
                                <Form.Control as={FormSelect} options={endTimeOptions} required defaultselected='' />
                            </Form.Group>
                        </Row>
                    </Col> */}
                    <Col xs={12} className="mb-3">
                        <Form.Label>Duration</Form.Label>
                        <div className="mb-3">
                            <Form.Check inline defaultChecked={duration === 1 ? true : false} onChange={() => setDuration(1)} label="30 Minute" name="duration" type="radio" id={`duration-1`} />
                            <Form.Check inline defaultChecked={duration === 2 ? true : false} onChange={() => setDuration(2)} label="60 Minute" name="duration" type="radio" id={`duration-2`} />
                        </div>
                    </Col>
                    <Col xs={12} className="mb-3">
                        <Form.Label>Interview type</Form.Label>
                        <div className="hide-show d-flex">
                            
                            <ButtonGroup>
                                {interviewTypeList.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`radio-${idx}`}
                                    type="radio"
                                    variant={"outline-primary"}
                                    name="interviewType"
                                    value={radio.value}
                                    checked={interviewType == radio.value}
                                    //onChange={(e) => setRadioValue(e.currentTarget.value)}
                                    onChange={handleChange}
                                >
                                    {radio.name}
                                </ToggleButton>
                                ))}
                            </ButtonGroup>                    
                        </div>    

                        {showVideo && <div className="hide-show-video mt-3" >
                            <Form.Group as={Col} xs={12} className="mb-3" controlId="link">
                                <Form.Label>Share video call link</Form.Label>
                                <Form.Control  required={showVideo} type="url" value={link} onChange={(e) => setLink(e.target.value)} placeholder="e.g. https://meet.google.com/internshipgate-interview-meet" />
                            </Form.Group>
                        </div>}
                        
                        {showPhone && <div className="hide-show-phone1 mt-3">
                            <Form.Group as={Col} xs={12} className="mb-3" controlId="phone-number2">
                                <Form.Label>Share your contact number</Form.Label>
                                <Form.Control type="text" required={showPhone} maxLength={10} value={contact} onChange={(e) => setContact(e.target.value)}  placeholder="+91-7004619795" />
                            </Form.Group>
                        </div>}
                        {showOffice && <div className="hide-show-office mt-3">
                            <Col xs={12}>
                                <Alert variant="warning" className="d-flex">
                                    <div className="mt-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"></path>
                                        </svg>
                                    </div>
                                    <div className="ms-3">
                                        Due to Covid 19, schedule an in-office interview only if it’s necessary. In
                                        case it is unavoidable, please wear mask and ensure social distancing at the
                                        workplace.
                                    </div>
                                </Alert>
                            </Col>
                            <Form.Group as={Col} xs={12} className="mb-3" controlId="office-address">
                                <Form.Label>Add office address</Form.Label>
                                <Form.Control type="text" required={showOffice} value={address} onChange={(e) => setAddress(e.target.value)} as="textarea" rows="2" placeholder="" />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} className="mb-3" controlId="phone-number2">
                                <Form.Label>Share your contact number</Form.Label>
                                <Form.Control type="text" required={showOffice} maxLength={10} value={contact} onChange={(e) => setContact(e.target.value)}  placeholder="+91-7004619795" />
                            </Form.Group>

                        </div>
                        }


                        <Form.Group as={Col} xs={12} className="mb-3" controlId="message">
                                <Form.Label>Add description</Form.Label>
                                <Form.Control type="url" value={description} onChange={(e) => setDescription(e.target.value)} as="textarea" rows="4" placeholder="Interview Description"/>
                            </Form.Group>                        
                    </Col>
                    <Col xs={12}>
                        <Button type="submit" >{loading ? <Spinner size="sm" /> : "Schedule Interview "}</Button>
                    </Col>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default CandidateInfoCardVIStudents