import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import "tippy.js/animations/scale.css";
import React, { useState } from "react";
import { Row, Col, Container, Image } from "react-bootstrap";
import { mdiSchoolOutline, mdiAccountGroup, mdiFinance } from "@mdi/js";
import { Helmet } from "react-helmet";
import teamData from "./IGteam";
import FeatureTopIconCard from "../marketing/common/features/FeatureTopIconCard";
import OurStats from "./OurStats";
import bannerimg from "../../assets/images/internship-banner.png";
import Url from "components/Url";

const AboutUs = () => {
  
  const url1 = Url();
  const url = url1["url"];
  const s3 =  url1["s3"];

  const [selectedTeamMember, setSelectedTeamMember] = useState(teamData[0]);
  const handleDescription = (item) => {
    setSelectedTeamMember(item);
  };

  const title = "Our Values";
  const description = `We are committed to holistic student growth, blending technical proficiency with personal development. Through innovative technology, we empower students with hands-on skills for their careers. We prioritize fostering connections, facilitating collaboration between students and employers to mutually thrive in the corporate realm.`;



  const features = [
    {
      id: 1,
      icon: mdiSchoolOutline,
      title: "Internship for everyone",
      description: `Through its innovative programs and connections with employers, Internshipgate strives to make internships accessible and beneficial for every student, regardless of their background or aspirations.`,
    },
    {
      id: 2,
      icon: mdiAccountGroup,
      title: "Make education accessible ",
      description: `Internshipgate is committed to making education accessible by providing an open platform for students to explore diverse careers and secure meaningful internships, fostering holistic growth and preparing them for success in the corporate world.`,
    },
    {
      id: 3,
      icon: mdiFinance,
      title: "Learn and Grow",
      description: ` With a dynamic blend of hands-on training in emerging technologies and personalized resume building, Internshipgate paves the way for students to thrive in their chosen careers and emerge as confident contributors to the corporate world.`,
    },
  ];

  return (
    <>
      <Helmet>
        {/*<title>Internship | Summer Internship | Internship in India
                </title> */}
        <title>Internshipgate | About us</title>
        <meta
          name="description"
          content="Internshipgate is the best internship and online training platform which provide  paid or unpaid internships in Engineering, MBA, and other streams."
        />
      </Helmet>
      <div className="py-10 bg-white ">
        <Container style={{ maxWidth: "1704px" }}>
          <Row>
            <Col
              lg={{ span: 9, offset: 2 }}
              md={12}
              sm={12}
              className="mb-1 px-2"
            >
              {/* caption */}
              <Row>
                <Col lg={3}>
                  <img src={bannerimg} alt="" style={{ width: "100%" }} />
                </Col>
                <Col lg={9} className="m-auto">
                  <h1 className="display-3 mb-3 p-2">
                    Hi there, we’re{" "}
                    <span className="text-primary">Internshipgate</span>
                  </h1>
                </Col>
              </Row>
              {/* para  */}
              <div style={{ textAlign: "justify" }} className="px-2">
                <p className="h3 text-body  mb-3 ">
                  Internshipgate serves as a gateway for students seeking to
                  explore diverse career pathways and secure meaningful
                  internships. This open platform acts as a bridge connecting
                  aspiring interns with prospective employers, facilitating the
                  pursuit of their desired internship programs.
                </p>
                <p className="mb-3 h3 text-body lh-lg ">
                  Internshipgate provides varied features that helps in overall
                  development and excellence of the student. Firstly it helps in
                  building skills through its career accelerator program which
                  provides a full-fledge training in the emerging technologies
                  such as - Blockchain, Data Science, Internet of things (IOT),
                  Container, Bigdata, Node JS, etc for the student to get
                  practical exposure and gain ample knowledge in the domain.
                </p>
                <p className="mb-3 h3 text-body lh-lg ">
                  Secondly, it helps the students build the best resume through
                  its Resume Builder program with more than 100 templates and
                  designs that will help you in building the best resume and
                  stand out of the crowd through it.
                </p>
                <p className="mb-3 h3 text-body lh-lg ">
                  Third, it helps the students to interact with different
                  organisations and secure an internship in the domain of their
                  choice and gain practical and real-life experience of the
                  corporate world. The internship program helps the student in
                  building a relationship with the employer and present the best
                  of themselves.
                </p>
                <p className="mb-3 h3 text-body lh-lg ">
                  It is a division of ISAN DATA SYSTEMS, and as always, we focus
                  on overall enhancement of our future generation and the future
                  corporate world. Internshipgate aims at development of student
                  in every aspect and make them ready for the corporate life and
                  help them in doing best of their work.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <OurStats />
        </Container>
        <div className="py-lg-12 py-10" style={{ backgroundColor: "#f1f5f9" }}>
          <Container>
            {/* Section Heading */}
            <Row>
              <Col
                md={6}
                sm={12}
                className="offset-right-md-6 mb-6 m-auto text-center"
              >
                <h2
                  className="display-4 mb-3 fw-bold"
                  style={{ color: "#2c3895" }}
                >
                  {title}
                </h2>
                <p className="lead">{description}</p>
              </Col>
            </Row>
            {/* Section Heading */}
            <Row>
              {features.map((item, index) => {
                return (
                  <Col md={4} sm={12} key={index}>
                    <FeatureTopIconCard item={item} />
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
        <div className="py-lg-12 py-10 bg-white">
          <Container>
            <Row>
              <Col md={6} sm={12} className="offset-right-md-6 mb-10 m-auto">
                {/* <!-- heading --> */}
                <h2
                  className="display-5 mb-3 fw-bold text-center"
                  style={{ color: "#2c3895" }}
                >
                  {" "}
                  Team
                </h2>
                <h1 className="display-4 mb-3 fw-bold text-center">
                  {" "}
                  MEET OUR LEADERS
                </h1>
              </Col>
            </Row>
            <Row>
              {teamData.map((item, index) => (
                <Col md={2} sm={3} key={index} className="col-3">
                  <div
                    className="p-xl-5 p-lg-3 mb-3 mb-lg-0"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDescription(item)}
                  >
                    <Tippy
                      content={
                        <div>
                          <h4 className="mb-0 fw-bold">{item.name}</h4>
                          <span>{item.designation} </span>
                        </div>
                      }
                      theme={"light"}
                      animation={"scale"}
                    >
                      <Image
                        src={s3 + "team/" + item.image}
                        alt=""
                        className="imgtooltip img-fluid rounded-circle"
                      />
                    </Tippy>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>

        {selectedTeamMember && (
          <div className="description">
            <Container>
              <Row>
                <Col
                  lg={{ span: 8, offset: 2 }}
                  md={12}
                  sm={12}
                  className="mb-1 px-2"
                >
                  <div className="text-center">
                    <h1 style={{ color: "#2c3895" }}>
                      {selectedTeamMember.name}
                    </h1>
                    <h2 className="display-6">
                      {selectedTeamMember.designation}
                    </h2>
                  </div>
                  <br />
                  <hr
                    style={{
                      height: "2px",
                      width: "28%",
                      margin: "auto",
                      backgroundColor: "#2c3895",
                    }}
                  />
                  <br />
                  <p>{selectedTeamMember.description}</p>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
    </>
  );
};

export default AboutUs;
