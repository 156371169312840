import React, { Fragment,useState, useEffect } from "react"
import { useForm } from "react-hook-form";
import {
    Col,
    Row,
    Dropdown,
    Card,
    Breadcrumb,
    Button,
    Table,
    Form
} from 'react-bootstrap';
import {
    useTable,
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
} from 'react-table';
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import { Trash, Send, Inbox, MoreVertical } from 'react-feather';
import Pagination from 'components/elements/advance-table/Pagination';
import { Link } from "react-router-dom";
import { useMemo } from "react";

// import Navadmin from './Navadmin'
import Url from "../../Url";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import DotBadge from 'components/elements/bootstrap/DotBadge';
import { ClockFill, Discord } from "react-bootstrap-icons";
//import { set } from "react-ga";

toast.configure()
const Discount = () => {

    // const { register, handleSubmit } = useForm()
    const urls = Url();
    const url1 = urls["url"];
    const [discount_list, setDiscount_list] = useState([])
    const [load, setLoad] = useState(false)
    const [creatediscountmodal, setCreatediscountmodal] = useState(false)
    const [editdiscountmodal, setEditdiscountmodal] = useState(false)
    const [deletesuccess, setDeletesuccess] = useState(false)
    const [offerName, setOfferName] = useState("")
    const [planType, setPlanType] = useState("")
    const [discountP, setDiscountP] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [isActive, setIsActive] = useState("")
    const [getotid, setGetotid] = useState("")

     const currentDate = new Date().toISOString().split('T')[0];

    useEffect(() => {
        const api = url1 + "getDiscountList";
        const config = {
        method: "GET",
        };
        fetch(api, config)
        .then((res) => res.json())
        .then((data) => {
            // console.log(data.discountList);
            setDiscount_list(data.discountList);
            // sortCourses(data.discountList)  
            setLoad(false)
        });
    }, [load]);

    const onSubmit = () => {
        var id = null;
        if(getotid){
            id = getotid;
        }
        var discount_offer_name = offerName
        var discount_start_date = startDate
        var discount_end_date = endDate
        var active_ind = isActive
        var course_duration = planType
        var discount_percent = discountP
        // console.log(discount_off÷er_name)
        setLoad(false);
        console.log(discount_offer_name)
        const api = url1 + "saveDiscount"
        fetch(api, {
            method: 'POST',
            body: JSON.stringify({...(id && {id}), course_duration, discount_percent,discount_offer_name,active_ind, discount_start_date, discount_end_date }),
            headers: { 'Content-Type': 'application/json' }
        })
            .then((apidata) => {
                console.log(apidata)
                return apidata.json()
            })
            .then((actualdata) => {
                console.log(actualdata)
                if (actualdata["message"] === "Record added") {
                    setCreatediscountmodal(false)
                    setEditdiscountmodal(false);
                    toast.success('Discount Added successfully ....', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
                }
                if (actualdata["message"] === "Record updated") {
                    setEditdiscountmodal(false);
                    setLoad(true);
                    toast.success('Discount Updated successfully ....', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
                }
            })

    }

    const getOTbyid = (id) => {
        const api = url1 + "getDiscountDetails" + "/" + id
        
        fetch(api, {
            method: 'GET',
        })
            .then((apidata1) => {
                // console.log(apidata1)
                return apidata1.json()
            })
            .then((actualdata1) => {
                
                // console.log("here:",actualdata1)
                // console.log(actualdata1.discountData[0].discount_offer_name)
                //setGetcoursedetailsbyid(actualdata1)
                setOfferName(actualdata1.discountData[0].discount_offer_name)
                setPlanType(actualdata1.discountData[0].course_duration)
                setDiscountP(actualdata1.discountData[0].discount_percent)
                setStartDate((actualdata1.discountData[0].discount_start_date).substr(0,10))
                setEndDate((actualdata1.discountData[0].discount_end_date).substr(0,10))
                setIsActive(actualdata1.discountData[0].active_ind)
                setGetotid(actualdata1.discountData[0].id)
                setEditdiscountmodal(true)
            }
        )
    }

    const deleteOnlineTraining = (id) => {

        const res = window.confirm("The trainig will be deleted, are you sure?")
        if (res) {
            // console.log(id)
            const api = url1 + "deleteDiscount" + "/" + id
            fetch(api, {
                method: 'DELETE',
                body: JSON.stringify({}),
                headers: { 'Content-Type': 'application/json' }
            })
                .then((apidata) => { 
                    return apidata.json()
                })
                .then((actualdata) => {

                    toast.success('Discount deleted successfully ....', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
                    setLoad(true)

                })
        }
    }   

    // console.log(discount_list)

    const columns = useMemo(
        () => [
            { accessor: 'discount_offer_name', Header: 'Offer Name' },
            { accessor: 'course_duration', Header: 'Plan Type'},
            { accessor: 'discount_percent', Header: 'Discount %'},
            { accessor: 'discount_start_date', Header: 'Start Date'},
            { accessor: 'discount_end_date', Header: 'End Date'},
            { accessor: 'active_ind', Header: 'Status',
            Cell: ({ value }) => {
                return (
                    <p>
                        {value === 1 ? 
                        <DotBadge bg="success">
                            Active
                        </DotBadge>
                         : 
                         <DotBadge bg="danger">
                            Inactive
                         </DotBadge>
                         }
                    </p>
                );
            } 
            },
            { accessor: 'active_ind', Header: 'Action',id:"action",
                Cell: ({value, row}) => {
                    return(
                        <>
                            <Button variant="secondary" className="btn-sm m-1"onClick={() => getOTbyid(row.original.id)}>
                                Edit
                            </Button>
                            <Button className="btn-sm btn-outline-danger btn-white m-1" onClick={() => deleteOnlineTraining(row.original.id)}>
                                Delete
                            </Button>
                        </>
                    )
                }
            },
        ],
        []
        );

    const data = useMemo(() => discount_list, [discount_list]);
        
    const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            nextPage,
            previousPage,
            state,
            gotoPage,
            pageCount,
            prepareRow,
            setGlobalFilter
        } = useTable(
            {
                columns,
                data,
                initialState: {
                    pageSize: 100,
                    hiddenColumns: columns.map((column) => {
                        if (column.show === false) return column.accessor || column.id;
                        else return false;
                    })
                }
            },
            useFilters,
            useGlobalFilter,
            usePagination,
            useRowSelect,
            (hooks) => {
                hooks.visibleColumns.push((columns) => [
                    ...columns
                ]);
            }
        );

    const { pageIndex, globalFilter } = state;

           
    return (
        <>
             <Modal
                size=""
                show={creatediscountmodal}
                className="modalbgscreen d-none d-sm-block"
                aria-labelledby="example-modal-sizes-title-sm"
            >

                <Modal.Body className="mt-0 firstloginmodal modalnotification">

                    <form   >
                        <Row>
                            Add new Discount
                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 mt-3">Offer Name:</span>
                            <span className="mt-0">
                                <Form.Control placeholder="Offer Name" className="ml-0 "
                                    name="discount_offer_name"
                                    onChange={e => setOfferName(e.target.value)}
                                />
                            </span>

                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1">Plan Type:</span>
                            <span>
                                <Form.Control as="select" placeholder="Plan Type" className=" ml-0 "

                                    name="course_duration"
                                    onChange={e => setPlanType(e.target.value)}

                                >
                                    <option value="">Select</option>
                                    <option value="1">1 Month</option>
                                    <option value="3">3 Month</option>
                                    <option value="6">6 Month</option>
                                </Form.Control>
                            </span>

                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1">Discount %</span>
                            <span>
                                <Form.Control placeholder="Enter Discount %" className="ml-4"

                                    name="discount_percent"
                                    required
                                    onChange={e => setDiscountP(e.target.value)}

                                />
                            </span>
                        </Row>
                        
                        <Row className="mt-3">
                            <span>Start Date:</span>
                            <span>
                                <Form.Control 
                                    type = "date"
                                    name = "discount_start_date"
                                    required
                                    min = {currentDate}
                                    onChange={e => setStartDate(e.target.value)}
                                />
                                
                            </span>
                        </Row>
                        <Row className="mt-3">
                            <span>End Date:</span>
                            <span>
                                <Form.Control 
                                type="date"
                                name = "discount_end_date"
                                required
                                onChange={e => setEndDate(e.target.value)}
                                >

                                </Form.Control>
                            </span>
                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1">Is active</span>
                            <span>
                                <Form.Control as="select" placeholder="Is active?" className="ml-5"
                                    name="active_ind"
                                    required
                                    onChange={e => setIsActive(e.target.value)}
                                >
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </Form.Control>
                            </span>
                        </Row>

                        <Row className="mt-2 ml-0 mr-0">
                            <Col>
                                <Button className="m-1" onClick = {() => onSubmit()}>
                                    Save
                                </Button>
                                <Button className="btn-white btn-outline-primary m-1"
                                    onClick={() => setCreatediscountmodal(false)}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>

                    </form>
                </Modal.Body>
            </Modal>

            {/*-------------------------- Create Training Modal end-------------------------- */}


            {/*-------------------------- Update Training Modal-------------------------- */}
           
            <Modal
                size=""
                show={editdiscountmodal}
                className="modalbgscreen d-none d-sm-block"
                aria-labelledby="example-modal-sizes-title-sm"
            >

                <Modal.Body className="mt-0 firstloginmodal modalnotification">

                    <form   >
                        <Row>
                            Edit Discount
                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 mt-3">Offer Name:</span>
                            <span className="mt-0">
                                <Form.Control placeholder="Offer Name" className="ml-0 "
                                    name="discount_offer_name"
                                    value={offerName}
                                    onChange={e => setOfferName(e.target.value)}
                                />
                            </span>

                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1">Plan Type:</span>
                            <span>
                                <Form.Control as="select" placeholder="Plan Type" className=" ml-0 "
                                    value={planType}
                                    name="course_duration"
                                    onChange={e => setPlanType(e.target.value)}

                                >
                                    <option value="">Select</option>
                                    <option value="1">1 Month</option>
                                    <option value="3">3 Month</option>
                                    <option value="6">6 Month</option>
                                </Form.Control>
                            </span>

                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1">Discount %</span>
                            <span>
                                <Form.Control placeholder="Enter Discount %" className="ml-4"
                                    value={discountP}
                                    name="discount_percent"
                                    required
                                    onChange={e => setDiscountP(e.target.value)}

                                />
                            </span>
                        </Row>
                        
                        <Row className="mt-3">
                            <span>Start Date:</span>
                            <span>
                                <Form.Control 
                                    value = {startDate}
                                    type = "date"
                                    name = "discount_start_date"
                                    required
                                     min = {currentDate}
                                    onChange={e => setStartDate(e.target.value)}
                                />
                                
                            </span>
                        </Row>
                        <Row className="mt-3">
                            <span>End Date:</span>
                            <span>
                                <Form.Control 
                                value={endDate}
                                type="date"
                                name = "discount_end_date"
                                required
                                onChange={e => setEndDate(e.target.value)}
                                >

                                </Form.Control>
                            </span>
                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1">Is active</span>
                            <span>
                                <Form.Control as="select" placeholder="Is active?" className="ml-5"
                                    value={isActive}
                                    name="active_ind"
                                    required
                                    onChange={e => setIsActive(e.target.value)}
                                >
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </Form.Control>
                            </span>
                        </Row>

                        <Row className="mt-2 ml-0 mr-0">
                            <Col>
                                <Button className="m-1" onClick = {() => onSubmit()}>
                                    Save
                                </Button>
                                <Button className="btn-white btn-outline-primary m-1"
                                    onClick={() => setEditdiscountmodal(false)}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>

                    </form>
                </Modal.Body>
            </Modal>
            {/*-------------------------- Update Training Modal end-------------------------- */}


            <Fragment>
                <Row>
                <Col lg={12} md={12} sm={12}>
                    <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
                    <div className="mb-3 mb-md-0">
                        <h1 className="mb-1 h2 fw-bold">Add Discount</h1>
                        <Breadcrumb>
                        <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item href="#">Career Accelerator</Breadcrumb.Item>
                        <Breadcrumb.Item active>Add Discount</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div>
                    </div>
                    </div>
                </Col>
                </Row>
                <Row>
                <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-3 px-3 ">
                        <GlobalFilter
                        filter={globalFilter}
                        setFilter={setGlobalFilter}
                        placeholder="Search"
                        />
                </Col>
                <Col sm={12} className="ml-0">
                <Button 
                        onClick={() => { setCreatediscountmodal(true) }}
                        >Add New Discount</Button>
                        </Col>
                </Row>

                <Row className="mt-3">
                <Col lg={12} md={12} sm={12}>
                    <Card>
                    <Card.Body className="p-0">
                        <div className="table-responsive" style={{overflow: "auto",height:"500px"}}>
                        <Table {...getTableProps()} className="text-nowrap" style={{height:"400px"}}>
                            <thead className="table-light">
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                    </th>
                                ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                        </td>
                                    );
                                    })}
                                </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                        </div>

                        <Pagination
                        previousPage={previousPage}
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        gotoPage={gotoPage}
                        nextPage={nextPage}
                        />
                    </Card.Body>
                    </Card>
                </Col>
                </Row>
            </Fragment>
        </>
    )
}

export default Discount