import React, { Fragment, useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Url from "../../Url";

import {
  Col,
  Row,
  Card,
  Breadcrumb,
  Table,
} from "react-bootstrap";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import Pagination from "components/elements/advance-table/Pagination";
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import DatePicker from "react-flatpickr";

toast.configure();

//import { set } from "react-ga";

const StudyAbroadRegistrations = () => {
  const url1 = Url();
  const url = url1["url"];

  const admtoken = localStorage.getItem("admtoken");

  const [getregList, setGetregList] = useState([]);

  const [searchcontent, setSearchcontent] = useState("");
  const [deletescuucess, setDeletescuucess] = useState(false);

  const [registration_date_from, setRegistrationDateFrom] = useState("");
  const [currentdate, setCurrentdate] = useState(null);

  const todayDate = new Date();
  todayDate.setDate(todayDate.getDate() - 30);
  const [filterRegDate, setFilterRegDate] = useState(todayDate);
  {
    /*---------------Set int post inactive---------------*/
  }
  useEffect(() => {
    var d = new Date();
    var month =
      d.getMonth() > 9 ? "" + (d.getMonth() + 1) : "0" + (d.getMonth() + 1);
    var day = d.getDate() > 10 ? "" + d.getDate() : "0" + d.getDate();
    var year = d.getFullYear();
    setCurrentdate([year, month, day].join("-"));
    setRegistrationDateFrom([year, d.getMonth(), day].join("-"));

  }, []);
  {
    /*---------------Set int post inactive end---------------*/
  }
  const deleteStudent = (id) => {
    const res = window.confirm("The Intern will be deleted, are you sure?");
    if (res) {
      const api = url + "detetestudentbyid/" + id;
      fetch(api, {
        method: "DELETE",
        body: JSON.stringify({ id }),
        headers: { "Content-Type": "application/json" },
      })
        .then((apidata) => {
          return apidata.json();
        })
        .then((actualdata) => {
          toast.success("Intern deleted successfully ....", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
          setDeletescuucess(true);
        });
    }
  };


  const getStudentList = () => {
    const RegDateFrom = registration_date_from;
    var searchString = "";
    if (searchcontent) {
      searchString = "/" + searchcontent;
    }

    const api =
      url + "getStudyApplicantList" +"/" + RegDateFrom  + searchString;
    
      var status = 0;

    fetch(api, {
      method: "GET",
      headers: { "Content-Type": "application/json", "Authorization" : "Bearer " + admtoken },
    })
      .then((apidata1) => {
        status = apidata1.status;
        if(status == '403' ){
            alert("Unauthorised");
            setGetregList([]);
            return;
        }
        return apidata1.json();
      })
      .then((actualdata1) => {
        //console.log(actualdata1)
        setGetregList(actualdata1);
        setDeletescuucess(false);
      });
  };

  useEffect(() => {
    getStudentList();
  }, [deletescuucess, registration_date_from, filterRegDate]);

  useEffect(() => {
    getStudentList();
  }, []);


  /*--------------------------- Generate CSV ---------------------------*/
  const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData =
      typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

    var CSV = "";

    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index + ",";
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + "\r\n";
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + "\r\n";
    }

    if (CSV === "") {
      alert("Invalid data");
      return;
    }

    //Generate a file name
    var fileName = "StudyAbroadRegistrations";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  /*--------------------------- Generate CSV ---------------------------*/
  // console.log(getregList)


  const columns = useMemo(
    () => [
      { Header: "Serial No", Cell: (prop) => prop.row.index + 1 },
      { accessor: "name", Header: "Applicant Name" },
      { accessor: "email", Header: "Email Id", id: "email" },
      { accessor: "mobileno", Header: "Mobile No" },
      { accessor: "created_at", Header: "Application Date" },
    ],
    []
  );
  const dateFilter =
    getregList &&
    getregList.filter((item) => {
      const dateDate = new Date(item.created_at);
      const temp = new Date(filterRegDate);
      return dateDate > temp;
    });
  // console.log(getregList)

  const data = useMemo(() => {
    const filtered = getregList.filter((item) => {
      const dateDate = new Date(item.created_at);
      const temp = new Date(filterRegDate);
      return dateDate > temp;
    });
    return filtered;
  }, [getregList]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 100,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );

  const { pageIndex, globalFilter } = state;
  return (
    <Fragment>
      <Row>
        <Col lg={10} md={10} sm={10}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">Study Abroad Registration List</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Study Abroad</Breadcrumb.Item>
                <Breadcrumb.Item active>Registrations</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div></div>
          </div>
        </Col>
        <Col>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h5 fw-bold">Registration Date From</h1>
              <DatePicker
                value={filterRegDate}
                selected={filterRegDate}
                onChange={(date) => setFilterRegDate(date)}
              />
            </div>
            <div></div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-3 px-3 ">
          <GlobalFilter
            filter={globalFilter}
            setFilter={setGlobalFilter}
            placeholder="Search"
          />
        </Col>
        {getregList.length > 0 ? (
          <>
            <Col sm={12} className="ml-0">
              <button
                className="btn btn-success ml-2 mt-1"
                onClick={() =>
                  JSONToCSVConvertor(getregList, "_StudyAbroad_applicant_list", true)
                }
              >
                Download Excel
              </button>
            </Col>
          </>
        ) : null}
      </Row>

      <Row className="mt-3">
        <Col lg={12} md={12} sm={12}>
          <Card>
            <Card.Body className="p-0">
              <div
                className="table-responsive"
                style={{ 
                overflow: "auto", 
                // height: "500px" 
              }}
              >
                <Table
                  {...getTableProps()}
                  className="text-nowrap"
                  style={{ height: "400px" }}
                >
                  <thead className="table-light">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              {/* Pagination @ Footer */}
              <Pagination
                previousPage={previousPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default StudyAbroadRegistrations;
