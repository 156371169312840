import React from "react";
import { Button as BootstrapButton } from "react-bootstrap";
import "./index.css";

const Button = React.forwardRef((props, ref) => {
  const { children, ...otherProps } = props;
  return (
    <BootstrapButton ref={ref} bsPrefix="igBtn" {...otherProps}>
      {children}
    </BootstrapButton>
  );
});
export default Button;
