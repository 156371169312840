// import node module libraries
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Button,
  Modal,
  Row,
  Col,
  Breadcrumb,
  Spinner,
  Form,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { ChevronLeft, ChevronRight } from "react-feather";
import {MdOutlineVerifiedUser} from "react-icons/md";

// import custom components
import { FormSelect } from "components/elements/form-select/FormSelect";
import Sidebar from "./Sidebar";
import Select from "react-select";
import CandidateInfoCard from "../common/cards/CandidateInfoCard";
import CandidateInfoData from "../data/CandidateInfoData";
import ChatApp from "../chat/Chat";
import Url from "components/Url";
const Applications = () => {
  const statusInfo = useParams();
  const appStatus = statusInfo.appstatus;
  const intDetails = JSON.parse(window.localStorage.getItem("intDetails"));
  const [skillsOptions, setSkillsOptions] = useState();
  const [locationOptions, setLocationOptions] = useState();
  const [skills, setSkills] = useState([]);
  const [location, setLocation] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const id = window.localStorage.getItem("intId");
  const [name, setName] = useState("");
  const [ids, setId] = useState();
  const [load, setLoad] = useState(true);
  const url1 = Url();
  const url = url1["url"];
  const [loading, setLoading] = useState(false);
  const [candidateInfo, setCandidateInfo] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  /* Display the VI Student Popup start */
  //display the popup by reading the vcalue from the localstorage. Delete the localstorage value to prevent further displaying the popup
  if(!(intDetails.internship_status == "Active")){
    localStorage.removeItem("showVIStudentMessage");  
  }
  const [showVIStudentMessage, setShowVIStudentMessage] = useState(localStorage.getItem("showVIStudentMessage"));
  localStorage.removeItem("showVIStudentMessage");
  /* Display the VI Student Popup end */

  // const CandidateInfo = CandidateInfoData.filter(function (dataSource) {
  //   return appStatus === "all" ? true : dataSource.status === appStatus;
  // });

  const [pageNumber, setPageNumber] = useState(0);
  const RecordsPerPage = 10;
  const pagesVisited = pageNumber * RecordsPerPage;
  const pageCount = Math.ceil(candidateInfo?.length / RecordsPerPage);

  const changePage = ({ selected }) => {
    //console.log(selected);
    setPageNumber(selected);
  };

  //Logic start for filters other than AI recommended

  const handleSkillChange = (e) => {
    const changedSkills = e;
    const selectedSkillsArray = changedSkills.map((skill) => skill.value);
    setSelectedSkills(selectedSkillsArray);
  };

  const handleLocationChange = (e) => {
    const changedLocations = e;
    const selectedLocationsArray = changedLocations.map(
      (location) => location.value
    );
    setSelectedLocations(selectedLocationsArray);
  };

  const filteredStudents = candidateInfo?.filter((student) => {
    // Convert the student's skills and locations to an array
    const studentSkillsArray = student.software_skill?.split(",");
    const studentLocationArray = student.student_location?.split(",");
    console.log(studentLocationArray)
    console.log(selectedLocations);

    const skillMatch =
      selectedSkills.length === 0 ||
      selectedSkills.some((selectedSkill) =>
        studentSkillsArray?.includes(selectedSkill)
      );

    const locationMatch =
      selectedLocations.length === 0 ||
      selectedLocations.some((selectedLocation) =>
        studentLocationArray?.includes(selectedLocation)
      );

    return skillMatch && locationMatch;
  });

  //Logic end for filters other than AI recommended

  var displayRecords = (
    appStatus === "recommended" ? candidateInfo : filteredStudents
  )
    ?.slice(pagesVisited, pagesVisited + RecordsPerPage)
    .map((item, index) => {
      return (
        <CandidateInfoCard
          item={item}
          key={index}
          infoType={appStatus}
          handleShow={handleShow}
          employerInternshipId={id}
          setId={setId}
          setName={setName}
          internshipApplicationId={
            appStatus === "recommended"
              ? item.internship_application_id
              : item.id
          }
          url={url}
          setLoad={setLoad}
        />
      );
    });

  // console.log(displayRecords);

  useEffect(() => {
    if(load) {
      setCandidateInfo([]); //reset the data first,
      let statusID = "";
      if (appStatus === "shortlisted") {
        statusID = "/1";
      }
      if (appStatus === "hired") {
        statusID = "/2";
      }
      if (appStatus === "rejected") {
        statusID = "/4";
      }
      var api = "";

      if (appStatus === "recommended") {
        var skillsFilter = skills?.map((item) => item.value).join(",");
        var locationFilter = location?.map((item) => item.value).join(",");
        var params = "";
        if (skillsFilter) {
          params = params + "/" + skillsFilter;
        } else {
          params = params + "/null";
        }
        if (locationFilter) {
          params = params + "/" + locationFilter;
        } else {
          params = params + "/null";
        }
        // console.log("SkillsFilter", skillsFilter);

        api = url + "inviteCandidate/" + id + params;
        fetch(api, {
          method: "GET",
        })
          .then((apidata1) => {
            return apidata1.json();
          })
          .then((actualdata1) => {
            setCandidateInfo(actualdata1.studentList);
            setLoad(false);
          });
      } else {
        console.log(load);
        api = url + "getApplicantListForInternship/" + id + statusID;
        fetch(api, {
          method: "GET",
        })
          .then((apidata1) => {
            return apidata1.json();
          })
          .then((actualdata1) => {
            setCandidateInfo(actualdata1);
            //setLoad(false);
          });
      }
      // console.log("candidateInfo ", candidateInfo);
      setLoad(false);
    }
  }, [load, skills, location]);

  useEffect(() => {
    const locapi = url + "getLocationList";
    fetch(locapi, {
      method: "GET",
    })
      .then((locdata) => {
        return locdata.json();
      })
      .then((locdata1) => {
        setLocationOptions(locdata1);
      });

    //get software skills
    const api4 = url + "getSoftwareSkill";
    fetch(api4, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        setSkillsOptions(actualdata1);
      });
  }, []);

  // var studentdatalist = [];

  // function filterSkillList(val) {
  //   if (skills?.length > 0) {
  //     candidateInfo
  //       .filter((item) => {
  //         if (item.software_skill.toLowerCase().includes(val.toLowerCase())) {
  //           return item;
  //         }
  //       })
  //       .map((item) => studentdatalist.push(item));
  //     if (skills?.length > 0 && location?.length > 0) {
  //       studentdatalist = [];
  //       //const data = studentbasic1.slice();
  //       studentdatalist.push(...candidateInfo);
  //       setCandidateInfo(studentdatalist);
  //     } else {
  //       setCandidateInfo(studentdatalist);
  //     }
  //   }
  // }

  // // console.log("Skills : ", skills);

  // function filterListLoc(val) {
  //   if (location?.length > 0) {
  //     candidateInfo
  //       .filter((item) => {
  //         if (item.student_location) {
  //           if (
  //             item.student_location.toLowerCase().includes(val.toLowerCase())
  //           ) {
  //             return item;
  //           }
  //         }
  //       })
  //       .map((item) => studentdatalist.push(item));
  //     if (skills?.length > 0 && location?.length > 0) {
  //       studentdatalist = [];
  //       studentdatalist.push(...candidateInfo);
  //       setCandidateInfo(studentdatalist);
  //     } else {
  //       setCandidateInfo(studentdatalist);
  //     }
  //   }
  // }

  // console.log(
  //   "Filtered Students based on skills and location : ",
  //   filteredStudents
  // );

  return (
    <Fragment>
      <section className="pt-5 pb-6 bg-white">
        <Container>
          <Row className="border-bottom pb-5 g-0 align-items-center">
            <Col md={9}>
              <div className="mb-4">
                <Breadcrumb>
                  <Breadcrumb.Item href="/empdashboard" as="li">
                    Dashboard
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Internship Single</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <h1 className="mb-md-0">
                Applications for{" "}
                <span className="text-secondary">{intDetails.name}</span>
              </h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pb-8 bg-white">
        <Container>
          <Row>
            <Col lg={3}>
              <Sidebar setLoad={setLoad} candidateInfo={candidateInfo} />
            </Col>
            <Col lg={6}>
              <div className="mt-6 mt-lg-0 mb-4">
                {load ? (
                  <Modal
                    show={load}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Body>
                      <Spinner size={30} /> Please Wait{" "}
                    </Modal.Body>
                  </Modal>
                ) : (
                  <>
                    <h3 className="mb-4">
                      Showing{" "}
                      {appStatus === "recommended"
                        ? candidateInfo?.length
                        : filteredStudents?.length}{" "}
                      candidates
                    </h3>
                    <Fragment>
                      <Row>
                        {displayRecords?.length > 0 ? (
                          displayRecords
                        ) : (
                          <Col>No matching records found.</Col>
                        )}
                      </Row>
                      
                      {displayRecords?.length > 0 &&
                      <ReactPaginate
                        previousLabel={<ChevronLeft size="14px" />}
                        nextLabel={<ChevronRight size="14px" />}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={
                          "justify-content-center mb-0 pagination"
                        }
                        previousLinkClassName={"page-link mx-1 rounded"}
                        nextLinkClassName={"page-link mx-1 rounded"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link mx-1 rounded"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"active"}
                      />
                      }
                    </Fragment>
                  </>
                )}
              </div>
            </Col>
            {appStatus !== "recommended" ? (
              // Filter For profiles other than AI recommended
              <Col lg={3}>
                <div className="">
                  <h2 className="mb-3">Filter</h2>
                  <Form>
                    <div className="mb-3">
                      <Form.Group className="mb-3" controlId="skills">
                        <Form.Label>Skills</Form.Label>
                        <Select
                          isMulti
                          options={skillsOptions}
                          onChange={handleSkillChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="mb-3">
                      <Form.Group className="mb-3" controlId="locations">
                        <Form.Label>Locations</Form.Label>
                        <Select
                          isMulti
                          options={locationOptions}
                          onChange={handleLocationChange}
                        />
                      </Form.Group>
                    </div>
                  </Form>
                </div>
              </Col>
            ) : (
              // Filter For AI recommended profiles
              <Col lg={3}>
                <div className="">
                  <h2 className="mb-3">Filter</h2>
                  <Form>
                    <div className="mb-3">
                      <Form.Group className="mb-3" controlId="skills">
                        <Form.Label>Skills</Form.Label>
                        <Select
                          isMulti
                          options={skillsOptions}
                          onChange={(e) => setSkills(e)}
                        />
                      </Form.Group>
                    </div>
                    <div className="mb-3">
                      <Form.Group className="mb-3" controlId="locations">
                        <Form.Label>Locations</Form.Label>
                        <Select
                          isMulti
                          options={locationOptions}
                          onChange={(e) => setLocation(e)}
                        />
                      </Form.Group>
                    </div>
                    {/* <div className="mt-3 d-flex justify-content-md-end">
                    <Button
                      variant="outline-primary"
                      className="ms-1"
                      type="button"
                    >
                      Reset
                    </Button>
                    <Button variant="primary" type="submit" className="ms-2">
                      Show
                    </Button>
                  </div> */}
                  </Form>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </section>
      <Modal
        backdrop="static"
        show={show}
        onHide={handleClose}
        scrollable
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="chatModalLabel">
            Messages from all internships & jobs
          </Modal.Title>
        </Modal.Header>
        <ChatApp ids={ids} names={name} />
      </Modal>
      
      <Modal show={showVIStudentMessage} onHide={(e) => {setShowVIStudentMessage(false)} }
              backdrop="static"
              keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered>
          <Modal.Header className='py-0 py-2' closeButton={true}>
              <Modal.Title className=' d-flex fs-3 justify-content-center w-100' id="contained-modal-title-vcenter py-4">
                  <MdOutlineVerifiedUser size={40} className="text-success"  />
              </Modal.Title>
          </Modal.Header>            
          <Modal.Body  className="py-6 d-flex justify-content-center flex-column">
                <div className="fs-3 text-center pb-5">Recruit certified interns by <span style={{color:"#2C3895"}}>Internship</span><span style={{color:"#F98F43"}}>gate</span>.</div>
          </Modal.Body>
          <Modal.Footer>
                <Button variant="primary" className="btn btn-sm" onClick={() => {window.location.href="/empdashboard/applications/internshipgate-certified-interns"}}>View certified Interns</Button>
                <Button variant="outline-secondary" className="btn btn-sm" onClick={() => {setShowVIStudentMessage(false)}}>Close</Button>
          </Modal.Footer>
        </Modal>         
    </Fragment>
  );
};

export default Applications;
