/* eslint-disable prettier/prettier */
// import node module libraries
import { Col, Row, Container, Button, Card } from "react-bootstrap";

// import custom components
import StatTopSVGIcon from "components/Homepage/stats/StatTopSVGIcon";

// import required data files
import JobCategoriesData from "components/Homepage/data/jobs/JobCategoriesData";
import { Link } from "react-router-dom";

const BrowseJobCategories = () => {
  return (
    <section className="py-lg-6 bg-light py-6">
      <Container>
        <Row>
          <Col xl={{ span: 10, offset: 1 }} md={12} xs={12}>
            <Row className="text-center">
              <Col md={12} className="px-lg-10 mb-4 mt-2">
                {/* text */}
                <span className="text-uppercase text-primary fw-semi-bold ls-md">
                  Browse Category
                </span>
                {/* heading */}
                <h2 className="h1 fw-bold mt-3">
                  Discover internships across popular roles
                </h2>
                {/* text */}
                <p className="mb-0 fs-4">
                  Select a role and we'll show you relevant internships for it!
                </p>
              </Col>
            </Row>

            <Row className="gy-4">
              {JobCategoriesData.map((item, index) => {
                return (
                  <Col lg={3} md={4} xs={12} key={index}>
                    <Link
                      to={"/Internship/" + item.category}
                      className="flex-fill"
                    >
                      <StatTopSVGIcon item={item} />
                    </Link>
                  </Col>
                );
              })}
              {/* view all categories button/link */}
              {/* <Col xs={12} className="mt-8 text-center">
								<Button as="a" variant="outline-primary" href="#">
									View All Category
								</Button>
							</Col> */}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BrowseJobCategories;
