// import node module libraries
import { useState } from "react";
import { Col, Form, InputGroup, Button } from "react-bootstrap";

const JobSearchBox = ({ searchAnything, setSearchAnything }) => {
  const [input, setInput] = useState(searchAnything);

  const handleChange = (e) => {
    setInput(e.target.value);
    setSearchAnything(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="bg-white rounded-md-pill shadow rounded-3 mb-4">
      <div className="p-md-2 p-4">
        <Form className="row g-1" onSubmit={handleSubmit}>
          <Col md={9} sm={12}>
            {/* input group job title */}
            <InputGroup>
              <InputGroup.Text
                id="searchJob"
                className="bg-transparent border-0 pe-0 ps-md-3 ps-md-0"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  className="bi bi-search text-muted"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </InputGroup.Text>
              <Form.Control
                type="search"
                placeholder="Search Anything"
                aria-label="Job Title"
                aria-describedby="searchJob"
                className="rounded-pill border-0 ps-3 form-focus-none"
                value={input}
                onChange={handleChange}
              />
            </InputGroup>
          </Col>
          <Col md={3} sm={12} className="text-end d-grid pe-2">
            {/* button */}
            <Button type="submit" className="rounded-pill">
              Search
            </Button>
          </Col>
        </Form>
      </div>
    </div>
  );
};

export default JobSearchBox;
