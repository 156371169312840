// import node module libraries
import React, { useContext, Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  Accordion,
  Card,
  useAccordionButton,
  AccordionContext,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Url from "components/Url";
const GKAccordionActions = ({ accordionItems, setEditSubtopic, setTopicId, setSubtopicName, setSubtopicDesc, setLoad, setDeleteSubtopicID }) => {
  const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );
    const url1 = Url();
    const url = url1.url;
    let subtopicId;
    const handleEdit = (names) => {
      accordionItems.map(el => {
        if(names === el.title){
          setEditSubtopic(el.id)
          setTopicId(el.topic_id)
          setSubtopicName(el.title)
          setSubtopicDesc(el.description)
          return; 
          }
      })
    }

    const getDeleteID = (names) => {
      accordionItems.map(el => {
        if(names === el.title){
          handleDelete(el.id)
          return; 
          }
      })
    }
    

    const handleDelete = (id) => {
      if(id && window.confirm("Are you sure? Want to delete subtopic?")){
        const api = url + "deleteVICourseSubTopicById/" + id;
        const config = {
         method: "DELETE"
        }
        fetch(api, config).then(res => res.json())
        .then((data) => {
         if(data.message === "Record Deleted"){
           alert("Subtopic Deleted Successfuly")
           setLoad(true)
         }
        })
      }
    } 
    const isCurrentEventKey = currentEventKey === eventKey;
    const overlayKeyEdit = uuid();
    const overlayKeyDelete = uuid();
    return (
      <Fragment>
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="mb-0">
            <Link
              onClick={decoratedOnClick}
              aria-expanded={isCurrentEventKey}
              to="#"
              className="text-inherit"
            >
              <span className="align-middle p-1">{children}</span>
            </Link>
          </h5>
          <div>
              <Link to="#" className="me-1 text-inherit" title="Edit">
                <i className="fe fe-edit fs-6" onClick={() => handleEdit(children)}></i>
              </Link>

              <Link to="#" className="me-1 text-inherit" title="Delete">
                <i onClick={() => getDeleteID(children)} className="fe fe-trash-2 fs-6"></i>
              </Link>

            <Link
              to="#"
              className="text-inherit"
              data-bs-toggle="collapse"
              onClick={decoratedOnClick}
              aria-expanded={isCurrentEventKey}
            >
              <span className="chevron-arrow">
                <i className="fe fe-chevron-down fs-5"></i>
              </span>
            </Link>
          </div>
        </div>
      </Fragment>
    );
  };

  const generateKey = (pre) => {
    pre = pre.toLowerCase();
    pre = pre.replace(" ", "_");
    return `${pre}_${new Date().getTime()}`;
  };
  return (
    <Fragment>
      {accordionItems[0].id !== -1 ? (
        <Accordion defaultActiveKey={accordionItems[0].id}>
          {accordionItems.map((item, index) => (
            <Card
              key={generateKey(item.title + index)}
              className="px-2 py-2 mb-1 shadow-none"
            >
              <Card.Header className="bg-transparent border-0 p-0">
                <div className="border-0">
                  <h3 className="mb-0 fw-bold">
                    <ContextAwareToggle eventKey={item.id + index}>
                      {item.title}
                    </ContextAwareToggle>
                  </h3>
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey={item.id + index}>
                <Card.Body className="fs-4">
                  {item.description}
                  {/* {item.content}	 */}
                  {/* <Link to="#" className="btn btn-secondary btn-sm mb-1">
                    Add Article +
                  </Link>{" "}
                  <Link to="#" className="btn btn-secondary btn-sm mb-1">
                    Add Description +
                  </Link> */}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      ) : null}
    </Fragment>
  );
};

export default GKAccordionActions;
