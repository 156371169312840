// import node module libraries
import React, { Fragment, useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Modal, Row, Col, Table } from "react-bootstrap";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";

// import bootstrap / feather icons
import { FileEarmarkText, Files, Star } from "react-bootstrap-icons";
import { FaRegMessage } from "react-icons/fa6";

// import custom components
import { ToastContainer, toast } from "react-toastify";
import RightIconInfoCard from "components/common/cards/RightIconInfoCard";
import GlobalFilter from "components/common/advance-table/GlobalFilter";
import Pagination from "components/common/advance-table/Pagination";

// import data files
// import InfoCardsData from './data/InfoCardsData';

import Chat from "../employer/chat/Chat";
import Url from "../Url";

// import CheckcollegeProfileStatus from "./CheckStudentProfileStatus";
import collegeStudentRoutes from "./CollegeStudentRoutes";
import { StudentsList } from "data/users/StudentsData";
import JobsList from "components/college/CollegeIntListing/JobsList";
import ApplicationStatus from "./ApplicationStatus";
import CollegeFeedback from "./CollegeFeedback";

const Dashboard = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const url1 = Url();
  const url = url1["url"];

  // localStorage.setItem("loginName", college_name);
  const college_Id = localStorage.getItem("collegeId");
  // const college_name = localStorage.getItem("college_name");
  const token = localStorage.getItem("collegetoken");

  const [collegebasic, setcollegebasic] = useState("");
  const [collegeData, setCollegeData] = useState([]);
  const [dashBoardStats, setdashBoardStats] = useState("");
  const [showApplicationStatus, setShowApplicationStatus] = useState(false);
  const [showInternshipListing, setShowInternshipListing] = useState(false);
  const [showCollegeFeedback, setShowCollegeFeedback] = useState(false);

  const [applicationList, setApplicationList] = useState([]);
  const [applicationStatusList, setApplicationStatusList] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const [loadData, setLoadData] = useState(false);
  const [name, setName] = useState("");

  const [test, setTest] = useState("test");

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn-icon btn btn-ghost btn-sm"
    >
      {children}
    </Link>
  ));

  if (!localStorage.getItem("collegetoken")) {
    window.location.href = "/college-login";
    return;
  }

  useEffect(() => {
    let status;
    const api3 = url + "getCollegeProfile" + "/" + college_Id;
    fetch(api3, {
      method: "GET",
    })
      .then((apidata1) => {
        status = apidata1.status;
        return apidata1.json();
      })
      .then((actualdata1) => {
        // console.log(actualdata1);
        setCollegeData(actualdata1[0]);
      });

    // getNewMsgCounter();

    async function fetchData() {
      try {
        var returnStatus = 200;
        const api2 = url + "getCollegeDashboardStats/" + college_Id;
        
        const response1 = await fetch(api2, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        
        const actualdata1 = await response1.json();
        setApplicationList(actualdata1.studentList);
        setdashBoardStats([
          {
            id: 1,
            icon: <FileEarmarkText />,
            text: actualdata1.studentList.length,
            subText: "Student List",
            color: "secondary",
            link: "#",
            bg: "rgba(254,233,217, 0.8)",
            onClick: () => {
              setShowApplicationStatus(false);
              setShowInternshipListing(false);
              setShowCollegeFeedback(false);
            },
          },
          {
            id: 2,
            icon: <Star />,
            text: "",
            subText: "Latest Internship",
            color: "success",
            link: "#",
            bg: "rgba(215,240,229, 0.8)",
            onClick: () => {
              setShowApplicationStatus(false);
              setShowInternshipListing(true);
              setShowCollegeFeedback(false);
            },
          },
          {
            id: 3,
            icon: <Files />,
            text: "",
            subText: "Application Status",
            color: "info",
            link: "#",
            bg: "rgba(204,226,233, 0.8)",
            onClick: () => {
              setShowApplicationStatus(true);
              setShowInternshipListing(false);
              setShowCollegeFeedback(false);
            },
          },
          {
            id: 4,
            icon: <FaRegMessage height={40} width={40} />,
            text: "",
            subText: "Feedback Form",
            color: "info",
            link: "#",
            bg: "rgba(204,226,233, 0.8)",
            onClick: () => {
              setShowApplicationStatus(false);
              setShowInternshipListing(false);
              setShowCollegeFeedback(true);
            },
          },
        ]);        
        // Your logic for setting application list and dashboard stats
        
        // Only proceed if the first request was successful
        const api4 = url + "getStudentApplicationStatusForCollege/" + college_Id;
        const response2 = await fetch(api4, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        
        if (response2.status === 200) {
          const actualdata2 = await response2.json();
          setApplicationStatusList(actualdata2.studentApplicationList);
        } else {
          toast.error("Something went wrong");
        }
        
      } catch (error) {
        setApplicationList([]);
        setdashBoardStats([]);
        toast.warning("User is not verified yet, contact support");
        console.error(error);
        return; // Exit the function early
      }
    }
    
    if (college_Id) {
      fetchData();
    }
    setLoadData(false);
  }, []);

  // const redirectToLMS = () => {
  //   const token = localStorage.getItem("collegetoken");
  //   const collegeId = localStorage.getItem("collegeId");

  //   const api = url + "verifyLMSUser";
  //   fetch(api, {
  //     method: "POST",
  //     body: JSON.stringify({ token, collegeId }),
  //     headers: { "Content-Type": "application/json" },
  //   })
  //     .then((apidata1) => {
  //       return apidata1.json();
  //     })
  //     .then((actualdata1) => {
  //       // console.log("######", actualdata1);
  //       setName(actualdata1.college_name);

  //       if (actualdata1.apiURL !== "") {
  //         let a = document.createElement("a");
  //         a.target = "_blank";
  //         a.href = actualdata1.apiURL;
  //         a.click();
  //         //window.location.href = actualdata1.apiURL;
  //       } else {
  //         alert(
  //           "Your session has expired, please login again to access your courses"
  //         );
  //         return false;
  //       }
  //     });
  // };

  // Column heading and cell format setup
  const columns = useMemo(
    () => [
      { accessor: "id", Header: "ID" },
      { accessor: "student_name", Header: "Student Name" },
      { accessor: "email", Header: "Email Id" },
      { accessor: "location", Header: "Location" },

      { accessor: "mobileno", Header: " Mobile No" },
      { accessor: "degree", Header: "Degree" },
      { accessor: "specialization", Header: "Specialization" },

      { accessor: "created_at", Header: "Date & Time" },
    ],
    []
  );

  const data = useMemo(() => applicationList, [applicationList]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { pageIndex, globalFilter } = state;

  const shareInternship = (id) => {
    const api = url + "shareInternshipListing";

    fetch(api, {
      method: "POST",
      body: JSON.stringify({ collegeId: college_Id, internshipId: id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        // console.log(actualdata1);
        if (actualdata1.length > 0) {
          toast.success("Internship shared successfully");
        } else {
          toast.error("Internship sharing failed");
        }
      });
  };

  return (
    <Fragment>
      <ToastContainer />
      {/* Welcome Section */}

      {/* s */}
      {/* {console.log(viCourseList.length)} */}

      <section className=" pt-5 pb-6 ">
        <Container>
          <Row className=" pb-5 g-0 align-items-center">
            <Col md={9}>
              <h1 className="mb-md-0">
                Hi, Welcome back!{" "}
                <span className="text-secondary">
                  {collegeData?.college_name} 
                </span>
                {collegeData?.verification_ind !== 1 && (<span style={{fontSize:"0.8rem",color:"red"}}> (*Unverified User)</span>)}
              </h1>
            </Col>
            <Col md={3} className="text-md-end">
              <Link to="/coldashboard/profile" className="btn btn-secondary">
                View Profile
              </Link>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Information cards section */}
      <section className="pb-8 ">
        <Container>
          <Row className="row-cols-xl-6 row-cols-md-3 g-3">
            {dashBoardStats.length > 0 &&
              dashBoardStats.map((item, index) => {
                return (
                  <Col className="col" key={index}>
                    <Link
                      to={item.link}
                      className="text-reset"
                      onClick={item.onClick}
                    >
                      <RightIconInfoCard item={item} />
                    </Link>
                  </Col>
                );
              })}
            {/* {dashBoardStats.length > 0 && (
              <Col className="col">
                <Link to="#" className="text-reset" onClick={handleShow}>
                  <RightIconInfoCard
                    item={dashBoardStats[dashBoardStats.length - 1]}
                  />
                </Link>
              </Col>
            )} */}
          </Row>
        </Container>
      </section>

      {/* Internship Data List */}

      <section className="pb-14 bg-white pt-3">
        <Container>
          {showApplicationStatus ? (
            <ApplicationStatus data={applicationStatusList} />
          ) : showInternshipListing ? (
            <JobsList share={shareInternship} />
          ) : showCollegeFeedback ? (
            <CollegeFeedback token={token} />
          ) : (
            <>
              <Row className="align-items-center">
                <Col md={8}>
                  <div>
                    <h2 className="mb-md-0 mb-3">College student list </h2>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="pt-2">
                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                      placeholder="Search college student"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-6">
                <Col lg={12}>
                  <Table
                    hover
                    responsive
                    {...getTableProps()}
                    className="text-nowrap table-centered"
                  >
                    <thead className="table-light">
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        //console.log(row)
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>

                  {/* Pagination @ Footer */}
                  <Pagination
                    previousPage={previousPage}
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                  />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </section>

      {/* Chat Modal Popup  */}
      <Modal
        backdrop="static"
        show={show}
        onHide={handleClose}
        scrollable
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="chatModalLabel">
            Messages from all internships & jobs
          </Modal.Title>
        </Modal.Header>
        <Chat />
      </Modal>
    </Fragment>
  );
};

export default Dashboard;
