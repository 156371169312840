// import node module libraries
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { Fragment, useState, useEffect } from 'react'

// import custom components
import ApexCharts from 'components/elements/charts/ApexCharts';
import Url from 'components/Url';
import Axios from 'axios';


const QuizResult = (props) => {
  
  const [load, setLoad] = useState(false);
	const url1 = Url();
	const url = url1["url"];
  const studentId = localStorage.getItem('studentId');
  const token = localStorage.getItem('stutoken');
  const encryptedCareerDetailId = localStorage.getItem('encryptedCareerDetailId');


  const [studentTest,setStudentTest] = useState([]);
  const [score,setScore] = useState(0);
  const [loading, setLoading] = useState(true);

  const [studentTestId,setStudentId] = useState(props.match.params.studentTestId);

  const QuizResultChartSeries = [score];

  const color1 = score > 65 ? "#38a169" : "#ff1a1a";
  const color2 = score > 65 ? "#d1f5ea" : "#ffb3b3";

  const QuizResultChartOptions = {
    colors: [color1],
    plotOptions: {
      radialBar: {
        hollow: { margin: -2, size: '50%', background: color2 },
        dataLabels: {
          name: { show: true, fontSize: '18px', fontWeight: 600, offsetY: 7 },
          value: { show: false }
        }
      },
    },
    labels: [score + "%"],
  };

  useEffect(() => {
    getStudentTest();
	},[studentTestId , load]);	


  const getStudentTest = () => {

		let apiURL =  url + "getStudentTest/" + studentId + "/" + studentTestId;
		Axios.get(
			apiURL,
			{headers: {
					"Content-type": "Application/json",
					"Authorization": `Bearer ${token}`
					}   
				}
		  )
		  .then((response) => {
			  setStudentTest(response.data.studentTest);
        let total_marks_obtained = response.data.studentTest.total_marks_obtained;
        let total_marks = response.data.studentTest.total_marks;
        setScore(Math.floor((total_marks_obtained/total_marks)*100,2).toFixed(2));
			},
			(error) => {
			  var status = error.response.status
			}
		  );	    
      setLoading(false);
  }//end of getQuestion function

  return (
      <Card>
        <Card.Body className="p-10 text-center">
          {!loading ? 
          <Fragment>
          <div className="mb-4 ">
            {score >= 65 ?
            ( 
              <Fragment>
                <h2>🎉 Congratulations. You passed!</h2>
                <p className="mb-0 px-lg-14">You are successfully completed the quiz. Now you click on
                  finish and back to your quiz page.</p>
              </Fragment>
            ) :
            (
              <Fragment>
                <h2 className="text-danger">Unfortunately, you did not pass the online test.!</h2>
                <p className="mb-0 px-lg-14">Please revisit the online course and try again.</p>
              </Fragment>
            )
            }
          </div>
          <div className="d-flex justify-content-center">
            <div className="resultChart">
              <ApexCharts
                options={QuizResultChartOptions}
                series={QuizResultChartSeries}
                type="radialBar"
                height={150}
              />
            </div>
          </div>
          <div className="mt-3">
            <span>Your Score: <span className="text-dark">{score}%</span></span><br />
            <span className="mt-2 d-block">Passing Score: <span className="text-dark">65%</span></span>
          </div>
          <div className="mt-5">
            <Link to={"/lms/online-training/quiz/" + encryptedCareerDetailId} className="btn btn-primary">Finish</Link>
            {/* <Link to="#" className="btn btn-outline-secondary ms-2">Share <i className="fe fe-external-link"></i></Link> */}
          </div>
          </Fragment>
          :
          (
            <div className="mb-4 ">
              <p>Loading......</p>
            </div>
          )
          }
        </Card.Body>
      </Card>
  )
}

export default QuizResult;
