// import node module libraries
import { Card, Form, Button } from "react-bootstrap";

// import custom components
import { FormSelect } from "components/elements/form-select/FormSelect";
// import ReactQuillEditor from "components/elements/editor/ReactQuillEditor";
import ReactQuillEditor from "react-quill";
import { useEffect, useState } from "react";
// import GKTagsInput from "components/elements/tags/GKTagsInput";
import "./basicinfo.css";
import ReactTagInput from "@pathofdev/react-tag-input";
import { Link } from "react-router-dom";

const BasicInformation = (props) => {
  const {
    courseData,
    categoryList,
    title,
    setTitle,
    level,
    setLevel,
    duration,
    setDuration,
    description,
    setDescription,
    previewText,
    setPreviewText,
    tags,
    setTags,
    faqs,
    setFaqs,
    heading,
    setHeading,
    handleSubmit,
    next,
    courseDuration
  } = props;
  useEffect(() => {

    if (courseData) {
      console.log(courseDuration);
      setTitle(courseData.subject_name);
      setDescription(courseData.subject_description);
      setFaqs(courseData.faqs);
      setHeading(courseData.subject_heading);
      setPreviewText(courseData.preview_text)
    }
    if(courseData?.course_duration === 1){
      setDuration(1)
    }
    if(courseData?.course_duration === 3){
      setDuration(3)
    }
    if(courseData?.course_duration === 6){
      setDuration(6)
    }

    if (courseData?.course_competency_level === 1) {
      setLevel("Beginner");
    } else if (courseData?.course_competency_level === 2) {
      setLevel("Intermediate");
    } else if (courseData?.course_competency_level === 3) {
      setLevel("Advance");
    }
    setLevel(courseData?.course_competency_level);

    if (courseData?.category_ids) {
      var data = [];
      const ids = courseData.category_ids.split(",");
      for (let i = 0; i < ids.length; i++) {
        for (let j = 0; j < categoryList.length; j++) {
          if (parseInt(ids[i]) === parseInt(categoryList[j].category_id)) {
            data.push(categoryList[j].category_name);
          }
        }
      }
      setTags(data);
    }
  }, [courseData, categoryList]);

  const CoursesLevel = [
    { value: "Select", label: "Select" },
    { value: "1", label: "Beignners" },
    { value: "2", label: "Intermediate" },
    { value: "3", label: "Advance" },
  ];

  /*
  const CourseDuration = [
    { value: "Select", label: "Select" },
    { value: 1, label: "1 Month" },
    { value: 3, label: "3 Month" },
  ];
  */

  const handleNext = () => {
    if(!title || !level || !duration){
			alert("Please fill required fields.")
			return;
		}
    next();
    
  }
  // const handleSubmit = () => {
  //   console.log(image)
  //   let id = courseData?.subject_id ? courseData.subject_id : null
  //   const api = url + "updateVICourse";
  //   const config = {
  //     method: "POST",
  //     body: JSON.stringify({
  //       id,
  //       subject_name: title,
  //       subject_heading: heading,
  //       subject_description: description,
  //       faqs: faqs,
  //       generated_url: courseData?.generated_url,
  //       lms_course_id: "",
  //       preview_text: ""

  //     }),
  //     headers: {
  //       "Content-Type": "application/json"
  //     }
  //   }
  //   fetch(api, config).then(res => res.json())
  //   .then((result) => {
  //     console.log(result)
  //     if(result.message === "success"){
  //       alert("Basic information updated successfully.")
  //       setLoad(true)
  //       next();
  //     }

  //   })
  // }

  return (
    <Form>
      {/* Card */}
      <Card className="mb-3 ">
        <Card.Header className="border-bottom px-4 py-3">
          <h4 className="mb-0">Basic Information</h4>
        </Card.Header>
        {/* Card body */}
        <Card.Body>
          {/* Title  */}
          <Form.Group className="mb-3">
            <Form.Label htmlFor="courseTitle">Course Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Course Title"
              id="course_title"
              name="course_title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <Form.Text className="text-muted">
              Write a 60 character course title.
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="courseTitle">Subject Heading</Form.Label>
            <Form.Control
              type="text"
              placeholder="Course Title"
              id="course_title"
              name="course_title"
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="courseTitle">Preview Text</Form.Label>
            <Form.Control
              type="text"
              placeholder="Course Title"
              id="course_title"
              name="course_title"
              value={previewText}
              onChange={(e) => setPreviewText(e.target.value)}
            />
          </Form.Group>

          {/* Category */}
          {/* <Form.Group className="mb-3">
            <Form.Label>Category</Form.Label>
            <ReactTagInput tags={tags} onChange={(e) => setTags(e)} />
            <Form.Text className="text-muted">
              Help people find your courses by choosing categories that
              represent your course.
            </Form.Text>
          </Form.Group> */}

          {/*Course Duration */}
          <Form.Group className="mb-3">
            <Form.Label>Courses Duration</Form.Label>
            <Form.Select
              id="courses_level"
              name="courses_level"
              placeholder="Select level"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            >
              {courseDuration?.map(({ id, plan_name }, index) => (
                <option value={id} key={id}>
                  {plan_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          {/* Courses level */}
          <Form.Group className="mb-3">
            <Form.Label>Courses level</Form.Label>
            <Form.Select
              id="courses_level"
              name="courses_level"
              placeholder="Select level"
              value={level}
              onChange={(e) => setLevel(e.target.value)}
            >
              {CoursesLevel.map(({ value, label }, index) => (
                <option value={value} key={value}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          {/* Course Description*/}
          <Form.Group className="mb-3">
            <Form.Label>Course Description</Form.Label>
            <ReactQuillEditor
              value={description}
              id="course_description"
              name="course_description"
              onChange={(e) => setDescription(e)}
            />
            <Form.Text className="text-muted">
              A brief summary of your courses.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>FAQs</Form.Label>
            <ReactQuillEditor
              value={faqs}
              id="course_description"
              name="course_description"
              onChange={(e) => setFaqs(e)}
            />
            <Form.Text className="text-muted">
              Add Frequently asked questions and answers
            </Form.Text>
          </Form.Group>
        </Card.Body>
      </Card>
      {/* Button */}
      <Button variant="success" onClick={handleSubmit}>
        Save
      </Button>
      <Button className="float-end" variant="primary" onClick={handleNext}>
        Next
      </Button>
    </Form>
  );
};
export default BasicInformation;
