// import node module libraries
import { useState, useContext } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';

// import bootstrap icons
import { Paperclip } from 'react-bootstrap-icons';

// import context file
import { ChatContext } from 'context/Context';

// import utility file
import { getDateValue, getTimeValue } from 'helper/utils';

const SendMessage = (props) => {
	const { setMessage, message, postChatMessage } = props;
	// const {
	// 	ChatState: { loggedInUserId, activeThread },
	// 	ChatDispatch
	// } = useContext(ChatContext);

	const handleSubmit = () => {
		postChatMessage()
	};
	return (
		<Row className="g-2">
			<Col lg={11} xs={8}>
				<div className="position-relative">
					<Form.Control
						as="textarea"
						placeholder="Type a New Message"
						rows="1"
						id="Excerpt"
						value={message}
						onChange={({ target }) => setMessage(target.value)}
						style={{ height: '40px' }}
					/>
					{/* <div className="position-absolute end-0 bottom-0 px-3 py-2">
						<Paperclip size={16} />
					</div> */}
				</div>
			</Col>
			<Col lg={1} xs={4}>
				<Button
					variant="primary"
					bsPrefix="btn"
					type="submit"
					className="btn-focus-none w-100"
					onClick={handleSubmit}
				>
					Send
				</Button>
			</Col>
		</Row>
	);
};
export default SendMessage;
