// import node module libraries
import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// import MDI icons
import Icon from "@mdi/react";
import { mdiStar } from "@mdi/js";

// Import required utility file
import { numberWithCommas } from "helper/utils";

const MentorCard = ({ item }) => {
  return (
    <Card className="my-sm-4 my-2 card-hover">
      {/* img */}
      {item.top_mentor ? (
        <span className="ribbonStyle">
          {/* <div>Top</div>
        <div>Selling</div> */}
        </span>
      ) : null}      
      <div className="d-flex flex-row justify-content-center my-2">
        <Card.Img
          variant="top"
          src={item.profile_pic}
          className="img-fluid"
          style={{ width: "50%", borderRadius: "50%" }}
        />
        <p>Available ASAP</p>
      </div>
      {/* card body */}
      <Card.Body className="pt-0">
        <h3 className="mb-0 fw-semi-bold">
          {" "}
            {item.fullname}
        </h3>
        <p className="mb-2">{item.role_name} 
          {(item.display_current_organization_ind && item.organization_name) ? ` at ${item.organization_name}` : null}
        </p>

        <div className="lh-base  d-flex flex-column">
          <div>
            <span className="fs-6 text-muted">
              Skills -{" "}
              <span className="text-dark">
                {item.mentor_skills?.length > 3
                  ? `${item.mentor_skills?.slice(0, 3).join(", ")} +${
                      item.mentor_skills?.length - 3
                    } more`
                  : item.mentor_skills?.join(", ")}
              </span>
            </span>
          </div>
          <div>
            <span className="fs-6 text-muted">
              Mentorship offered in -{" "}
              <span className="text-dark">
                {item.mentor_topics?.length > 2
                  ? `${item.mentor_topics?.slice(0, 2).join(", ")} +${
                      item.mentor_topics?.length - 2
                    } more`
                  : item.mentor_topics?.join(", ")}
              </span>
            </span>
          </div>
          <div className="mt-2 d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex gap-1">
              <span className="fs-6 text-warning">
                <Icon path={mdiStar} size={0.5} />{" "}
                <span>{item.mentor_rating}</span>
              </span>
              <span className="fs-6 text-muted">
                ({numberWithCommas(item.no_of_mentorships)} Reviews)
              </span>
            </div>
            <Link to={`mentorship-details/${item.id}`}>
              <button className="btn btn-primary btn-sm">Book Now</button>
            </Link>
          </div>
          {/* <div>
            <span className="fs-6 text-muted">
              <span className="text-dark">{item.courses}</span> Course
            </span>
          </div> */}
        </div>
      </Card.Body>
    </Card>
  );
};

// Typechecking With PropTypes
MentorCard.propTypes = {
  item: PropTypes.object.isRequired,
};

export default MentorCard;
