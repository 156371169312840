import { useState, useEffect } from 'react';
/**
 * Functions in utils
 */

/**
 * Add commas to a number
 * v1.0.0
 */
export const numberWithCommas = (x, decimal = 0) => {
	return x.toLocaleString('en-US', { minimumFractionDigits: decimal });
};

/**
 * Get the file extension from given file name
 * v1.2.0
 */
export const getFileExtension = (filename) => {
	const extension = filename.split('.').pop();
	return extension;
};

/**
 * Get the random number between min and max value
 * v1.2.0
 */
export const getRandomNo = (min = 0, max = 100) => {
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * Get the color name/value based on given status
 * v1.2.0
 */
export const getStatusColor = (itemstatus) => {
	let color = '';
	switch (itemstatus) {
		case 'In Progress':
			color = 'info';
			break;
		case 'Pending':
			color = 'warning';
			break;
		case 'Finished':
			color = 'success';
			break;
		case 'Cancel':
			color = 'danger';
			break;
		default:
			color = 'primary';
	}
	return color;
};

/**
 * Get the color name/value based on given status
 * v1.2.0
 */
export const getCategoryColor = (category) => {
	let color = '';
	switch (category) {
		case 'Saas Services':
		case 'Entertainment':
		case 'Extra':
			color = 'info';
			break;
		case 'Design':
			color = 'warning';
			break;
		case 'Marketing':
			color = 'success';
			break;
		case 'Development':
			color = 'danger';
			break;
		case 'SEO':
			color = 'primary';
			break;
		default:
			color = 'primary';
	}
	return color;
};

//get chunk from array
export const chunk = (arr, chunkSize = 1, cache = []) => {
	const tmp = [...arr];
	if (chunkSize <= 0) return cache;
	while (tmp.length) cache.push(tmp.splice(0, chunkSize));
	return cache;
};

// function to get time value in hh:mm AM | PM format
export const getTimeValue = (date) => {
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var ampm = hours >= 12 ? 'PM' : 'AM';
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? '0' + minutes : minutes;
	var strTime = hours + ':' + minutes + ' ' + ampm;
	return strTime;
};

// function to get date value in Month Name DD, YYYY format
export const getDateValue = (date) => {
	const month = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec'
	];
	const yyyy = date.getFullYear();
	let mm = date.getMonth();
	let dd = date.getDate();
	var today = month[mm] + ' ' + dd + ', ' + yyyy;
	return today;
};

export const getTimePassed = (startDate) => {
	let endDate = Date.now();
	const oneDayMs = 1000 * 60 * 60 * 24;
	const diffMs = endDate - new Date(startDate).getTime();
	const diffDays = Math.floor(diffMs / oneDayMs);
	const years = Math.floor(diffDays / 365);
	const months = Math.floor(diffDays / 30.44) % 12;
	const days = diffDays - (years * 365) - (Math.floor(months * 30.44));
	let ans = "";
	if (years != 0){
		years == 1 ? ans += `${years} year` : ans += `${years} years`;
	}
	if (months != 0){
		months == 1 ? ans += ` ${months} month` : ans += ` ${months} months`;
	}

	if (days != 0){
		days == 1 ? ans += ` ${days} day` : ans += ` ${days} days`;
	}

	if (ans == "") ans = "less than a day ago";	
	else ans += " ago";	
	return ans;
};


  /*--------------------------- Generate CSV ---------------------------*/
  export const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData =
      typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

    var CSV = "";

    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index + ",";
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + "\r\n";
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + "\r\n";
    }

    if (CSV === "") {
      alert("Invalid data");
      return;
    }

    //Generate a file name
    var fileName = "IG Student List";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  /*--------------------------- Generate CSV ---------------------------*/
  // console.log(getstureglist)

// Custom hook for debouncing any fast changing value
export const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        // Set debouncedValue to value (passed in) after the specified delay
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        // Return a cleanup function that will be called every time useEffect is re-called.
        // useEffect will only be re-called if value or delay changes (see the array of dependencies)
        return () => clearTimeout(handler);
    }, [value, delay]); // Only re-call effect if value or delay changes

    return debouncedValue;
};

const utils = [
	numberWithCommas,
	getFileExtension,
	getRandomNo,
	getStatusColor,
	chunk,
	getTimeValue,
	getDateValue,
	getTimePassed,
	JSONToCSVConvertor,
	useDebounce
];

export default utils;
