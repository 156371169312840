import { v4 as uuid } from 'uuid';

const EmployerRoutes = [
	{
		id: uuid(),
		menuitem: "Dashboard",
		link: "/empdashboard",
	},
	{
		id: uuid(),
		menuitem: 'Profile',
		link: '/empdashboard/profile',
	},
	{
		id: uuid(),
		menuitem: 'Post Internship',
		link: '/empdashboard/post-internship'
	}
	
];

export default EmployerRoutes;
