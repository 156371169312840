import React, { useEffect, useRef, useState, createRef } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  Svg,
  Path,
  Circle,
  Polyline,
  Rect,
  Line,
} from "@react-pdf/renderer";
import { Html } from "react-pdf-html";
import {
  useA4Scale,
  TemplatePage,
  renderHTMLContent,
  getDisplayDate,
} from "../../utils";

import DummyProfilePic from "../../../../../assets/images/background/profilepic.png";

import { isEmpty } from "../../../foundation";

Font.registerHyphenationCallback((word) => {
  // Return entire word as unique part
  return [word];
});

const styles = StyleSheet.create({
  page: { display: "flex", fontFamily: "Roboto", paddingVertical: "30px" },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  left: {
    width: "300px",
  },
  right: {
    backgroundColor: "#bfc1c2",
    padding: "26px 20px 0px 30px",
    width: "540px",
  
  },
  contentPadding: {
    paddingLeft: "54px",
    paddingRight: "54px",
  },
  profileImageWrapper: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "290px",
    position: "absolute",
    paddingTop: "20px",
    paddingLeft: "60px",
     top:"8px",
   
  },
  profileImage: {
    width: "148px",
    height: "170px",
    border: "3px solid #ffffff",
    borderRadius: "5%",
    top:"8px",
  },
});

const AdditionalInfo = ({
  data: {
    Address,
    City,
    Postal_Code,
    Driving_License,
    Nationality,
    dob,
    Place_of_Birth,
  },
}) => (
  <View style={{ marginTop: "2px" }}>
    <Text
      style={{
        color: "#000000",
        //fontWeight: 700,
        fontSize: "20px",
        marginBottom: "2px",
        fontWeight: "bold",
        //textDecoration: "underline",
      }}
    >
      Contact
    </Text>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        marginBottom: "3px",
        alignItems: "center",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontSize: "10px",
          paddingLeft: "10px",
        }}
      >
        {`${Address || ""} ${City || ""} ${Postal_Code || ""}`.trim()}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "5px",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontSize: "10px",
          paddingLeft: "10px",
        }}
      >
        {City}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "3px",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontSize: "10px",
          paddingLeft: "10px",
        }}
      >
        {dob}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "3px",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontSize: "10px",
          paddingLeft: "10px",
        }}
      >
        {Nationality}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "3px",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontSize: "10px",
          paddingLeft: "10px",
        }}
      >
        {Place_of_Birth}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "3px",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontSize: "10px",
          paddingLeft: "10px",
        }}
      >
        {Driving_License}
      </Text>
    </View>
  </View>
);

const ContactInfo = ({ data: { Phone, Country, display_email,template_color_code } }) => (
  <View
    style={{
      marginTop: "50px",
      paddingTop: "3px",
      paddingLeft: "35px",
      backgroundColor: template_color_code,
      alignContent: "space-around",
      flexDirection: "column-reverse",

      borderRadius: "65%",
      position: "fixed",
    }}
  >
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "5px",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontSize: "12px",
          paddingLeft: "8px",
        }}
      >
        {Phone}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "5px",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontSize: "11.25px",
          paddingLeft: "8px",
        }}
      >
        {display_email}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontSize: "12px",
          paddingLeft: "8px",
          paddingVertical: "3px",
        }}
      >
        {Country}
      </Text>
    </View>
  </View>
);

const Courses = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const { title, subTitle, startDate, endDate, description } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View style={{ marginTop: "4px" }}>
      <View
        style={{
          padding: "8px 20px",
          marginTop: "4px",
          paddingLeft: "15%",
        }}
      >
        <View>
          <Text
            style={{
              color: "#000000",
              fontWeight: "bold",
              fontSize: "20px",
              marginBottom: "10px",
              fontstretch: "normal",
            }}
          >
            {heading}
          </Text>
        </View>
      </View>

      {items.map((item) => (
        <View style={{ paddingLeft: "30px", marginLeft: "5px" }}>
          <Text
            style={{ fontSize: "12px", fontWeight: "500", color: "#000000" }}
          >
            {item[title]}
          </Text>
          <Text
            style={{ fontSize: "12px", color: "#000000", marginTop: "3px" }}
          >
            {item[subTitle]}
          </Text>
          <Text
            style={{
              fontSize: "10px",
              color: "#000000",
              marginTop: "3px",
              fontWeight: "400",
            }}
          >
            {getDisplayDate(item[startDate], item[endDate])}
          </Text>

          {item[description] && (
            <View style={{ marginTop: "5px" }}>
              {renderHTMLContent(item[description], {
                "*": { color: "#000000", fontSize: "12px" },
              })}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

const Skills = ({ data: { skills,template_color_code } }) => {
  if (!skills || !skills.length) {
    return <View />;
  }
  return (
    <View
      style={{
        padding: "8px 20px",
        marginTop: "4px",
        paddingLeft: "15%",

        // border: "2px solid blue",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontWeight: "bold",
          fontSize: "20px",
          marginBottom: "10px",
          // letterSpacing: "1.95px",
          fontstretch: "normal",
        }}
      >
        Skills
      </Text>
      {skills.map((s) => (
        <View
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              color: "#000000",
              fontSize: "15px",
              paddingBottom: "5px",
              paddingLeft: "5px",
              fontWeight: "bold",
            }}
          >
            {s.skill_name}
          </Text>
          <View
            style={{
              backgroundColor: "#bfc1c2",
              width: "141px",
              height: "8px",
              marginBottom: "5px",
              borderRadius: "50px",
              // marginRight: "30%",
              marginTop: "5px",
            }}
          >
            <View
              style={{

                backgroundColor: template_color_code,
                borderRadius: "50px",
                height: "8px",
                width: `${s.level * 47}px`,
                // color: "white",
              }}
            ></View>
          </View>
        </View>
      ))}
    </View>
  );
};

const Hobbies = ({ data: { hobbies } }) => {
  if (!hobbies || !hobbies.length) {
    return <View />;
  }
  return (
    <View
      style={{
        padding: "8px 20px",
        marginTop: "4px",
        paddingLeft: "15%",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontWeight: "bold",
          fontSize: "20px",
          marginBottom: "10px",
          // letterSpacing: "1.95px",
          fontstretch: "normal",
        }}
      >
        Hobbies
      </Text>

      {hobbies.map((s) => (
        <Text
          style={{
            color: "#000000",
            fontSize: "12px",
            paddingBottom: "5px",
            paddingLeft: "5px",
          }}
        >
          {s.hobbies}
        </Text>
      ))}
    </View>
  );
};
const Languages = ({ data: { languages } }) => {
  if (!languages || !languages.length) {
    return <View />;
  }
  return (
    <View style={{ paddingLeft: "36px", marginTop: "12px" }}>
      <Text
        style={{
          color: "#000000",
          fontWeight: "bold",
          fontSize: "20px",
          marginBottom: "10px",
          fontstretch: "normal",
        }}
      >
        Languages
      </Text>
      {languages.map((s) => (
        <Text
          style={{
            color: "#000000",
            fontSize: "12px",
            paddingBottom: "5px",
            paddingLeft: "5px",
          }}
        >
          {s.language_name}
        </Text>
      ))}
    </View>
  );
};

const References = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const { title, subTitle, phoneNo, emailId } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View
      style={{
        padding: "8px 20px",
        marginTop: "4px",
        paddingLeft: "15%",
      }}
    >
      <Text
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          color: "#000000",
        }}
      >
        {heading}
      </Text>
      {items.map((item) => (
        <View style={{ paddingTop: "3px", paddingLeft: "10px" }}>
          <Text
            style={{ fontSize: "11px", fontWeight: "500", color: "#000000" }}
          >
            {item[title]}
          </Text>
          <Text
            style={{ fontSize: "11px", color: "#000000", paddingTop: "3px" }}
          >
            {item[subTitle]}
          </Text>

          <Text
            style={{ fontSize: "10px", color: "#000000", paddingTop: "3px" }}
          >
            {item[phoneNo]}
          </Text>
          <Text
            style={{
              color: "#000000",
              marginTop: "3px",
              fontSize: "10px",
            }}
          >
            {item[emailId]}
          </Text>
        </View>
      ))}
    </View>
  );
};
const RenderView = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const {
    title,
    subTitle,
    startDate,
    endDate,
    description,
    location = "city",
  } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View
      style={{
        // border: "2px solid red",
        maxWidth: "280px",
      }}
    >
      <Text
        style={{
          fontSize: "20px",
          fontWeight: "800",
          color: "#000000",
          paddingTop: "5px",
        }}
      >
        {heading}
      </Text>
      {items.map((item) => (
        <View
          style={{ paddingTop: "5px", marginTop: "2px", paddingLeft: "10px" }}
        >
          <Text style={{ fontSize: "11px", fontWeight:800,color:"#000000", }}>
            {item[title]}
          </Text>
          <Text
            style={{ fontSize: "11px", color: "#000000", marginTop: "3px",fontWeight:800 }}
          >
            {item[subTitle]}
          </Text>
          <Text
            style={{ fontSize: "10px", color: "#000000", marginTop: "3px",fontWeight:800 }}
          >
            {getDisplayDate(item[startDate], item[endDate])}{" "}
            {item[location] && `, ${item[location]}`}
          </Text>
          {item[description] && (
            <View style={{ marginTop: "5px" }}>
              {renderHTMLContent(item[description], {
                "*": 
                { 
                color: "#000000",
                fontWeight:"800",
                textAlign:"justify",
                width:"310px",
                paddingTop:"-12px",
                paddingRight:"10px",
              },
              })}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

const CustomSection = ({ data, dataKey, heading, fieldMap = {} }) =>{
  const items=data[dataKey];
  console.log("data key", data[dataKey]);
  const {title, city, startDate, endDate, description,custom_title }=fieldMap;
    if(!items||!items.length){
       return <View/>;
    }
    return (
      <View
        style={{
          maxWidth: "280px",
        }}
      >
        <Text
          style={{
            fontSize: "16px",
            fontWeight: 600,
             color:"#000000",
            
            
          }}
        >
          {heading}
        </Text>
        {items.map((item) => (
          
          <View style={{ marginBottom: "8px" }} wrap={true}>
            
            <View
              style={{
               fontSize: "20px",
          fontWeight: "800",
          color: "#000000",
          paddingTop: "5px",
              }}
            >
              <Text>{item[custom_title]}</Text>
            </View>
            <View
              style={{
                fontSize: "13px",
                fontWeight: "500",
                marginTop: "10px",
              }}
            >
              <Text>{item[title]}</Text>
              
            </View>
          
            <Text
              style={{
                fontSize: "10px",
                color: "#363434",
                marginTop: "3px",
                marginBottom: "4px",
              }}
            >
              {getDisplayDate(item[startDate], item[endDate])}
  
              {item[city] ? ` ,  ${item[city]}` : ""}
              
            </Text>

            {item[description] && (
              <View
                style={{
                  marginTop: "4px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                }}
              >
              {console.log(dataKey, item[description])}

                {renderHTMLContent(item[description], {
                  "*": {
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "12px",
                    paddingRight: "5px",
                    alignItems: "baseline",
                    position: "sticky",
                    textAlign: "justify",
                  },
                })}
              </View>
            )}
          </View>
        ))}
      </View>
    );
};
const Template12 = ({ data }) => {
  const {
    profileImage,
    First_Name,
    Last_Name,
    Wanted_Job_Title,
    template_color_code,
    professionalSummary: { Description = "" } = {},
  } = data;
  return (
    <TemplatePage data={data}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={{
              backgroundColor: template_color_code,
              //boxShadow: "10px 20px 9px #000000",
              width: "100%",
              height: "180px",
              paddingRight: "50%",
              paddingLeft: "23%",
              marginRight:"-20px",
              borderLeftWidth: "40px",
              borderColor:"#ffffff",
            }}
          >
            <Text
              style={{
                fontWeight: 500,
                fontFamily: "Roboto",
                fontSize: "36px",
                letterSpacing: "1.95px",
                marginLeft: "-50px",
                marginTop: "30px",
              }}
            >
              {First_Name}
            </Text>
            <Text
              style={{
                fontFamily: "Roboto",
                // paddingTop: "1px",
                fontWeight: "900",
                fontSize: "40px",
                letterSpacing: "1.95px",
                marginLeft: "-55px",
                marginTop: "0px",
              }}
            >
              {Last_Name}
            </Text>
            <Text
              style={{
                fontWeight: 300,
                fontSize: "20px",
                color: "#000000",
                marginLeft: "-50px",
                marginTop: "5px",
                width:"200px",
              }}
            >
              {Wanted_Job_Title}
            </Text>

            {/*  {!isEmpty(Description) && (
              <View
                style={{
                  paddingTop: "2px",
                  color: "blue",
                  paddingRight: "30%",
                
                 
                }}
              >
                {renderHTMLContent(Description)}
              </View>
            )}
            */}

            <View
              style={{
                position: "absolute",
                height: "100vh",
                width: "200px",
                left: "0px",
                top: "0px",
              }}
              fixed
              render={({ pageNumber }) => (
                <View
                  style={{
                    height: "100%",
                    backgroundColor:
                      pageNumber === 1 ? "transparent" : "#ffffff",
                  }}
                ></View>
              )}
            />
          </View>
          <View style={styles.profileImageWrapper}>
            <Image
              src={profileImage || DummyProfilePic}
              style={styles.profileImage}
            />
          </View>

          <View style={styles.wrapper}>
            <View style={styles.left}>
              <ContactInfo data={data} />
              {/* <AdditionalInfo data={data} /> */}
              <Skills data={data} />
              <Languages data={data} />
              <Courses
                data={data}
                dataKey="courses"
                heading="COURSES"
                fieldMap={{
                  title: "course_name",
                  subTitle: "institute_name",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "Description",
                }}
              />
              <Hobbies data={data} />
              <References
                data={data}
                dataKey="References"
                heading="References"
                fieldMap={{
                  title: "ref_name",
                  subTitle: "company_name",
                  phoneNo: "phone_no",
                  emailId: "ref_email",
                }}
              />
            </View>
            <View style={styles.right}>
             
             

              {!isEmpty(Description) && (
                  <View
                    style={{
                      marginTop: "2px",
                      // width: "200px",
                      marginRight: "10px",
                      paddingRight:"10px",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        top: "2px",
                        marginRight: "8px",
                        marginBottom: "4px",
                        color: "#000000",
                      }}
                    >
                      Professional Summary
                    </Text>
                    <View 
                  
                      style={{
                        textTransform: "capitalize",
                        color: "#000000",
                        marginTop: "10px",
                        fontSize: "12px",
                        fontFamily: "Roboto",
                        lineHeight: "normal",
                        fontWeight: 800,
                        textAlign:"justify",
                        paddingLeft:"13px",
                        paddingRight:"10px",
                       
                      }}
                    >
                      {renderHTMLContent(Description)}
                   
                    </View>

                  </View>
                )}
                
               <RenderView               
                data={data}
                dataKey="education"    
                heading="Education"
                fieldMap={{
                  title: "institute_name",
                  subTitle: "degree",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "Description",
                }}
              />
              <RenderView
                data={data}
                dataKey="employmentHistory"
                heading="Work Experience"
                fieldMap={{
                  subTitle: "Employer",
                  title: "Job_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "Description",
                }}
              />
              <RenderView
                data={data}
                dataKey="internships"
                heading="Internships"
                fieldMap={{
                  title: "employer_name",
                  subTitle: "job_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "description",
                }}
              />
              <RenderView
                data={data}
                dataKey="activities"
                heading="Extra Curricular Activity"
                fieldMap={{
                  title: "employer_name",
                  subTitle: "function_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "description",
                }}
              />
              <CustomSection
              data={data}
                dataKey="customSection"
                //heading="custom_title"
                fieldMap={{
                  custom_title:"custom_title",
                  title: "section_name",
                  city: "city",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "description",
                }}
              />
            </View>
          </View>
        </Page>
      </Document>
    </TemplatePage>
  );
};

Template12.displayName = "Template12";
export default Template12;
