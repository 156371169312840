import React, { Fragment } from "react";
import HeroMentor from "./HeroMentor";
import LogosTopHeading from "components/common/LogosTopHeading";
import { companyLogo } from "./static-data";
import MentorsFilterPage from "./MentorsFilterPage";
import TestimonialSection from "./TestimonialSection";
import { Container } from "react-bootstrap";
import BecomeAnInstructor from "./BecomeInstructor";
const MentorsLanding = () => {
  return (
    <>
      <HeroMentor />
      <MentorsFilterPage scrollId="topMentors" />
      <LogosTopHeading title="Mentors from Top Companies" logos={companyLogo} />
      <Container>
        <TestimonialSection />

        <BecomeAnInstructor />
      </Container>
    </>
  );
};

export default MentorsLanding;
