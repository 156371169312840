const SelectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#f2f5fa",
    borderRadius: "3px",
    fontSize: "0.875rem",
    border: 0,
    minHeight: 37
  })
};

export default SelectStyles;
