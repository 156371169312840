// import node module libraries
import { Card } from "react-bootstrap";

const RightIconInfoCard = ({ item }) => {
  return (
    <Card>
      <Card.Body>
        <div className="d-flex justify-content-between ">
          <div>
            {item.text?.length > 10 ? (
              <h5 className="mb-1">{item.text}</h5>
            ) : (
              <h2 className="mb-1">{item.text}</h2>
            )}
          </div>
          <div>
            <div
              style={{ backgroundColor: `${item.bg}` }}
              className={`icon-shape icon-md rounded-circle text-${item.color}`}
            >
              {item.icon}
            </div>
          </div>
        </div>
        <span>{item.subText}</span>
        <div></div>
      </Card.Body>
    </Card>
  );
};
export default RightIconInfoCard;
