import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Col,
  Container,
  DropdownButton,
  Row,
  ToggleButton,
} from "react-bootstrap";
import Item from "react-bootstrap/DropdownItem";
import SectionHeadingCenter from "components/marketing/common/section-headings/SectionHeadingCenter";
// import sub components
import Url from "components/Url";
import MentorCard from "./MentorCard";
import Filter from "./filterInput/Filter";

// const DummyMentorsList = [
//   {
//     id: 1,
//     image: InstructorImage1,
//     fullname: "Mary Roberts",
//     current_role: "Professional Web Developer",
//     company: "Google",
//     skills: ["HTML", "CSS", "JavaScript", "React", "Node.js"],
//     mentorship: [
//       "Web Development",
//       "Frontend Development",
//       "Backend Development",
//     ],
//     link: "#",
//     mentor_rating: 4.5,
//     no_of_mentorships: 1200,
//   },
//   {
//     id: 2,
//     image: InstructorImage1,
//     fullname: "Mary Roberts",
//     current_role: "Professional Web Developer",
//     company: "Google",
//     skills: ["HTML", "CSS", "JavaScript", "React", "Node.js"],
//     mentorship: [
//       "Web Development",
//       "Frontend Development",
//       "Backend Development",
//     ],
//     link: "#",
//     mentor_rating: 4.5,
//     no_of_mentorships: 1200,
//   },
//   {
//     id: 3,
//     image: InstructorImage1,
//     fullname: "Mary Roberts",
//     current_role: "Professional Web Developer",
//     company: "Google",
//     skills: ["HTML", "CSS", "JavaScript", "React", "Node.js"],
//     mentorship: [
//       "Web Development",
//       "Frontend Development",
//       "Backend Development",
//     ],
//     link: "#",
//     mentor_rating: 4.5,
//     no_of_mentorships: 1200,
//   },
//   {
//     id: 4,
//     image: InstructorImage1,
//     fullname: "Mary Roberts",
//     current_role: "Professional Web Developer",
//     company: "Google",
//     skills: ["HTML", "CSS", "JavaScript", "React", "Node.js"],
//     mentorship: [
//       "Web Development",
//       "Frontend Development",
//       "Backend Development",
//     ],
//     link: "#",
//     mentor_rating: 4.5,
//     no_of_mentorships: 1200,
//   },
//   {
//     id: 5,
//     image: InstructorImage1,
//     fullname: "Mary Roberts",
//     current_role: "Professional Web Developer",
//     company: "Google",
//     skills: ["HTML", "CSS", "JavaScript", "React", "Node.js"],
//     mentorship: [
//       "Web Development",
//       "Frontend Development",
//       "Backend Development",
//     ],
//     link: "#",
//     mentor_rating: 4.5,
//     no_of_mentorships: 1200,
//   },
//   {
//     id: 6,
//     image: InstructorImage1,
//     fullname: "Mary Roberts",
//     current_role: "Professional Web Developer",
//     company: "Google",
//     skills: ["HTML", "CSS", "JavaScript", "React", "Node.js"],
//     mentorship: [
//       "Web Development",
//       "Frontend Development",
//       "Backend Development",
//     ],
//     link: "#",
//     mentor_rating: 4.5,
//     no_of_mentorships: 1200,
//   },
//   {
//     id: 7,
//     image: InstructorImage1,
//     fullname: "Mary Roberts",
//     current_role: "Professional Web Developer",
//     company: "Google",
//     skills: ["HTML", "CSS", "JavaScript", "React", "Node.js"],
//     mentorship: [
//       "Web Development",
//       "Frontend Development",
//       "Backend Development",
//     ],
//     link: "#",
//     mentor_rating: 4.5,
//     no_of_mentorships: 1200,
//   },
//   {
//     id: 8,
//     image: InstructorImage1,
//     fullname: "Mary Roberts",
//     current_role: "Professional Web Developer",
//     company: "Google",
//     skills: ["HTML", "CSS", "JavaScript", "React", "Node.js"],
//     mentorship: [
//       "Web Development",
//       "Frontend Development",
//       "Backend Development",
//     ],
//     link: "#",
//     mentor_rating: 4.5,
//     no_of_mentorships: 1200,
//   },
// ];

const MentorsFilterPage = ({ scrollId }) => {
  const url1 = Url();
  const url = url1["url"];
  const s3URL = url1["s3"];
  const mentorsUrl = url + "getMentorList";

  const [mentors, setMentors] = useState([]);
  const [checked, setChecked] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({});

  const title = "Mentorship by Industry Expert";
  const subtitle = " World-class Mentors";
  const description = `Empower your professional growth by booking a session with our unmatched team of mentors.`;

  const isLaptop = useMediaQuery({ minWidth: 1024, maxWidth: 1445 });

  const fetchMentors = async () => {
    try {
      const response = await fetch(mentorsUrl, {
        method: "GET",
      });

      const data = await response.json();

      const updatedMentors = data.mentorList.map((item) => {
        return {
          ...item,
          profile_pic: s3URL + "Mentors/" + item.profile_pic,
          mentor_skills: item.mentor_skills
            ?.split(",")
            .map((item) => item.trim()),
          mentor_topics: item.mentor_topics
            ?.split(",")
            .map((item) => item.trim()),
        };
      });

      setMentors(updatedMentors);
    } catch (error) {
      console.log("Error fetching mentors : ", error);
    }
  };

  useEffect(() => {
    fetchMentors();
  }, []);

  const skills = mentors?.flatMap((item) => item.mentor_skills);

  const uniqueSkills = [...new Set(skills)];

  const skillsOptions = uniqueSkills.map((item) => ({
    value: item,
    label: item,
  }));

  const mentorship = mentors?.flatMap((item) => item.mentor_topics);

  const uniqueMentorship = [...new Set(mentorship)];

  const mentorshipOptions = uniqueMentorship.map((item) => ({
    value: item,
    label: item,
  }));

  const handleFilterChange = (name, value) => {
    setSelectedFilter((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // console.log("Filter : ", selectedFilter);

  const filteredMentors = mentors?.filter((item) => {
    const hasRequiredSkills =
      !selectedFilter.skills ||
      selectedFilter.skills.every((skill) =>
        item.mentor_skills?.includes(skill.value)
      );

    const hasRequiredMentorships =
      !selectedFilter.mentorship ||
      selectedFilter.mentorship.every((mentorship) =>
        item.mentor_topics?.includes(mentorship.value)
      );

    const hasTopMentor = checked ? item.top_mentor == 1 : true;

    return hasRequiredSkills && hasRequiredMentorships && hasTopMentor;
  });

  const renderMentors = () => {
    return filteredMentors?.map((item, index) => {
      return (
        <Col
          key={item.id}
          xl={3}
          lg={4}
          md={6}
          sm={12}
          className={`${isLaptop && index === 3 ? "d-lg-none d-xl-block" : ""}`}
        >
          <MentorCard item={item} />
        </Col>
      );
    });
  };

  return (
    <>
      <div
        className="py-4 py-lg-8 bg-light-gradient-top bg-white"
        id={scrollId}
      >
        <Container>
          <Row className="mb-4 py-4 justify-content-center">
            <Col lg={8} md={12} sm={12} className="text-center">
              <span className="text-primary mb-3 d-block text-uppercase fw-semi-bold ls-xl">
                {subtitle}
              </span>
              <h2 className="mb-2 display-4 fw-bold ">{title}</h2>
              <p className="lead">{description}</p>
            </Col>
          </Row>
          <Row
            xxl={5}
            xl={4}
            lg={3}
            md={2}
            sm={2}
            className="justify-content-center"
          >
            <Col className="mb-3">
              <Filter
                name="Skills"
                options={skillsOptions}
                placeholder="Select Skills"
                className="text-nowrap"
                onChange={(value) => handleFilterChange("skills", value)}
              />
            </Col>
            <Col className="mb-3">
              <Filter
                name="Mentorship Offered"
                options={mentorshipOptions}
                placeholder="Mentorships Offered"
                className="text-nowrap"
                onChange={(value) => handleFilterChange("mentorship", value)}
              />
            </Col>
            <Col>
              <div className="d-flex flex-row justify-content-left text-nowrap">
                {/* <DropdownButton
                  id="dropdown-basic-button"
                  title="Price"
                  variant="outline-primary"
                >
                  <Item href="#/action-1">Price-1</Item>
                </DropdownButton> */}

                <ToggleButton
                  className="mb-2"
                  id="toggle-check"
                  type="checkbox"
                  variant="outline-primary"
                  checked={checked}
                  value="1"
                  onChange={(e) => setChecked(e.currentTarget.checked)}
                >
                  Top Mentors
                </ToggleButton>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-left">{renderMentors()}</Row>
          {/* <Row>
            <Col md={12} className="mt-3 text-center">
              <Link to="/allMentors" className="btn btn-primary">
                See All Instructors
              </Link>
            </Col>
          </Row> */}
        </Container>
      </div>
    </>
  );
};

export default MentorsFilterPage;
