import React, { useEffect, useRef, useState, createRef } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  Svg,
  Path,
  Circle,
  Polyline,
} from "@react-pdf/renderer";
import { Html } from "react-pdf-html";
import {
  useA4Scale,
  TemplatePage,
  renderHTMLContent,
  getDisplayDate,
} from "../../utils";
import { Row, Col } from "react-bootstrap";
import DummyProfilePic from "../../../../../assets/images/background/profilepic.png";
import { GrMail } from "react-icons/gr";
import { FiPhone } from "react-icons/fi";
import { isEmpty } from "../../../foundation";

// const styles = StyleSheet.create({
//     page: { display: "flex", fontFamily: "Roboto", paddingVertical: "30px"},
//     wrapper: {
//       display: "flex",
//       flexDirection: "row",
//       flex: 1,
//     },
//     left: {
//       backgroundColor: "#3644a1",
//       width: "205px",
//       marginVertical: "-30px",
//     },
//     right: {
//       padding: "26px 20px 0px 54px",
//       width: "540px",
//     },
//     contentPadding: {
//       paddingLeft: "54px",
//       paddingRight: "54px",
//     },
//     profileImageWrapper: {
//       backgroundColor: "#273489",
//       display: "flex",
//       alignItems: "center",
//       padding: "30px",
//     },
//     profileImage: {
//       width: "120px",
//       height: "120px",
//       borderRadius: "50%",
//       border: "1px solid #273489",
//     },
// });

const styles = StyleSheet.create({
  page: {
    // display: "flex",
    fontFamily: "Roboto",
    paddingVertical: "30px",
  },
  wrapper: {
    backgroundColor: "#ffffff",
    marginTop: "-40px",
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  contact: {
    fontSize: "12px",
    //textAlign: "left",
    color: "#157698",
    marginRight: "50px",
  },
  profileImage: {
    width: "140px",
    height: "135px",
    borderRadius: "25%",
    marginBottom: "45px",
    marginTop: "34px",
    marginLeft: "-20px",
  },
  right: {
    width: "520px",
    display: "flex",
  },
  left: {
    width: "300px",
    marginRight: "7px",
  },
});

const AdditionalInfo = ({
  data: {
    Address,
    City,
    Country,
    Driving_License,
    Nationality,
    dob,
    Place_of_Birth,
    Postal_Code,
  },
}) => (
  <View
    style={{
      marginLeft: "20px",
      marginTop: "15px",
      marginBottom: "10px",
      marginRight: "10px",
    }}
  >
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        marginBottom: "3px",
        alignItems: "center",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontSize: "12px",
        }}
      >
        {Address}  {City} {Postal_Code}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        marginBottom: "3px",
        alignItems: "center",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontSize: "12px",
        }}
      >
        {Country}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "3px",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontSize: "12px",
        }}
      >
        {dob}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "3px",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontSize: "12px",
        }}
      >
        {Nationality}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "3px",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontSize: "12px",
        }}
      >
        {Place_of_Birth}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "3px",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontSize: "12px",
        }}
      >
        {Driving_License}
      </Text>
    </View>
  </View>
);

const Skills = ({ data: { skills } }) => {
  if (!skills || !skills.length) {
    return <View />;
  }
  return (
    <View
      style={{
        paddingBottom: "10px",
        marginTop: "15px",
        left: "8px",
      }}
    >
      <Text
        style={{
          color: "#34a6ba",
          fontSize: "20px",
          fontWeight: "700",
        }}
      >
        Skills
      </Text>
      {skills.map((s) => (
        <View
          style={{
            display: "flex",
            marginTop: "5px",
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: "#000405",
              fontSize: "14px",
              padding: "5px",
              textAlign: "left",
              width:"4px",
            }}
          >
            {s.skill_name}
            {/* skill_name */}
          </Text>
          <View
            style={{
              backgroundColor: "#999999",
              width: "120px",
              height: "7.5px",
              marginBottom: "5px",
              marginTop: "5px",
              marginRight: "30%",
              border:"1px solid #999999",
              borderRadius:"10px",
            
            }}
          >
            <View
              style={{
                backgroundColor: "#34a6ba",
                height: "7.5px",
                width: `${s.level * 39}px`,
               
               
              }}
            ></View>
          </View>
        </View>
      ))}
    </View>
  );
};

const Languages = ({ data: { languages } }) => {
  if (!languages || !languages.length) {
    return <View />;
  }
  return (
    <View
      style={{
        paddingLeft: "8px",
        marginTop: "10px",
      }}
    >
      <Text
        style={{
          color: "#34a6ba",
          fontSize: "20px",
          fontWeight: "700",
          marginBottom: "10px",
        }}
      >
        Languages
      </Text>
      {languages.map((s) => (
        <Text
          style={{
            color: "#000405",
            fontSize: "14px",
            paddingBottom: "5px",
          }}
        >
          {s.language_name}
        </Text>
      ))}
    </View>
  );
};

const Hobbies = ({ data: { hobbies } }) => {
  if (!hobbies || !hobbies.length) {
    return <View />;
  }
  return (
    <View style={{ marginTop: "10px", paddingLeft: "8px" }}>
      <Text
        style={{
          color: "#34a6ba",
          fontSize: "20px",
          marginBottom: "5px",
          fontWeight: 500,
        }}
      >
        Hobbies
      </Text>

      {hobbies.map((s) => (
        <Text
          style={{
            fontSize: "11px",
            fontWeight: 500,
            color: "#636a78",
            marginTop: "3px",
          }}
        >
          {s.hobbies}
        </Text>
      ))}
    </View>
  );
};
const References = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const { title, subTitle, phoneNo, emailId } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View style={{ marginLeft: "8px", marginTop: "8px" }}>
      <Text
        style={{
          fontSize: "20px",
          fontWeight: 700,
          color: "#34a6ba",
        }}
      >
        {heading}
      </Text>
      {items.map((item) => (
        <View style={{ marginTop: "5px" }}>
          <Text
            style={{ fontSize: "14px", fontWeight: "500", color: "#000000" }}
          >
            {item[title]}
          </Text>
          <Text
            style={{ fontSize: "12px", color: "#34a6ba", marginTop: "3px" }}
          >
            {item[subTitle]}
          </Text>
          <Text
            style={{ fontSize: "11px", color: "#363434", marginTop: "3px" }}
          >
            {item[phoneNo]}
          </Text>
          <Text
            style={{ fontSize: "11px", color: "#363434", marginTop: "3px" }}
          >
            {item[emailId]}
          </Text>
        </View>
      ))}
    </View>
  );
};
const CustomSection = ({ data, dataKey, heading, fieldMap = {} }) =>{
  const items=data[dataKey];
  console.log("data key", data[dataKey]);
  const {title, city, startDate, endDate, description,custom_title }=fieldMap;
    if(!items||!items.length){
       return <View/>;
    }
    return (
      <View
        style={{
          marginLeft: "8px",
           marginTop: "8px",
           top:"5px",
        }}
      >
        <Text
          style={{
            fontSize: "16px",
            fontWeight: 600,
            color: "#000000",
          }}
        >
          {heading}
        </Text>
        {items.map((item) => (
          
          <View style={{ marginBottom: "8px" }} wrap={true}>
            
            <View
              style={{
                fontSize: "20px",
                fontWeight: "900",
                color: "#34a6ba",
              }}
            >
              <Text>{item[custom_title]}</Text>
              
            </View>
            <View
              style={{
                fontSize: "13px",
                fontWeight: "500",
                marginTop: "10px",
              }}
            >
              <Text>{item[title]}</Text>
              
            </View>
          
            <Text
              style={{
                fontSize: "10px",
                color: "#363434",
                marginTop: "3px",
                marginBottom: "4px",
              }}
            >
              {getDisplayDate(item[startDate], item[endDate])}
  
              {item[city] ? ` ,  ${item[city]}` : ""}
              
            </Text>

            {item[description] && (
              <View 
                style={{
                  marginTop: "4px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                }}
              >
              {console.log(dataKey, item[description])}

                {renderHTMLContent(item[description], {
                  "*": {
                    color:"#636a78",
                    fontWeight: 500,
                    fontSize: "12px",
                    paddingRight: "5px",
                    alignItems: "baseline",
                    position: "sticky",
                    textAlign: "justify",
                  },
                })}
              </View>
            )}
          </View>
        ))}
      </View>
    );
};

const Experience = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const { title, subTitle, startDate, endDate, description } = fieldMap;

  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View
      style={{
        marginLeft: "5px",
        // Bottam: "60px",

        height: "200px",
      }}
    >
      <Text
        style={{
          fontSize: "20px",
          fontWeight: 900,
          color: "#34a6ba",
        }}
      >
        {heading}
      </Text>
      {items.map((item) => (
        <View style={{ diplay: "inline-flex", marginTop: "20px" }}>
          <Text
            style={{ fontSize: "14px", fontWeight: "500", color: "#000000" }}
          >
            {item[title]}
            {/* Title */}
          </Text>
          <Text
            style={{
              fontSize: "12px",
              color: "#34a6ba",
              textAlign: "center",
              position: "absolute",
              marginLeft: "40%",
            }}
          >
            {item[subTitle]}
            {/* SubTitle */}
          </Text>

          <Text
            style={{
              fontSize: "12px",
              color: "#363434",
              marginTop: "20px",
              fontWeight: "400",
              // textAlign: "right",
              position: "absolute",
            }}
          >
            {getDisplayDate(item[startDate], item[endDate])}{" "}
            {item[location] && `, ${item[location]}`}
          </Text>

          {item[description] && (
            <View
              style={{
                marginLeft: "40%",
              }}
            >
              {renderHTMLContent(item[description], {
                "*": { color: "#636a78" },
              })}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

const RenderView = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const { title, subTitle, startDate, endDate, description } = fieldMap;

  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View style={{ marginLeft: "20px", marginBottam: "15px" }}>
      <Text
        style={{
          fontSize: "20px",
          fontWeight: 900,
          color: "#34a6ba",
        }}
      >
        {heading}
      </Text>
      {items.map((item) => (
        <View style={{ diplay: "inline-flex", marginTop: "10px" }}>
          <Text
            style={{ fontSize: "14px", fontWeight: "500", color: "#000000" }}
          >
            {item[title]}
            {/* Title */}
          </Text>

          <Text
            //   style={{ fontSize: "10px", color: "#636a78", marginTop: "3px" }}
            // >
            //   {getDisplayDate(item[startDate], item[endDate])}
            // </Text>
            // <Text
            style={{
              fontSize: "14px",
              color: "#363434",
              marginTop: "3px",
              fontWeight: "400",
            }}
          >
            {getDisplayDate(item[startDate], item[endDate])}{" "}
            {item[location] && `, ${item[location]}`}
          </Text>

          <Text
            style={{ fontSize: "12px", color: "#34a6ba", marginTop: "3px" }}
          >
            {item[subTitle]}
            {/* SubTitle */}
          </Text>

          {item[description] && (
            <View style={{ marginTop: "5px", marginRight: "15px" }}>
              {renderHTMLContent(item[description], {
                "*": { color: "#636a78",textAlign:"justify",},
              })}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

const Template10 = ({ data }) => {
  const {
    Phone,
    display_email,
    First_Name,
    Last_Name,
    Wanted_Job_Title,
    professionalSummary: { Description = "" } = {},
  } = data;
  return (
    <TemplatePage data={data}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={{
              display: "flex",

              marginLeft: "30%",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "4px",
              }}
            >
              <Text
                style={{
                  fontSize: "26px",
                  fontWeight: 800,
                  color: "#157698",
                  marginTop: "10px",
                  textAlign: "left",
                   
                }}
              >
             {First_Name?First_Name:"Your Name"}
              </Text>
              <Text
                style={{
                  fontSize: "26px",
                  color: "#a9a9a9",
                  marginTop: "10px",
                  textAlign: "left",
                  paddingLeft:"12.5px",
                  fontWeight:"800",
                }}
              >
                {Last_Name}
              </Text>
            </View>
            <Text
              style={{
                fontSize: "14px",
                color: "#157698",
                marginTop: "6px",
                marginLeft: "4px",
                fontWeight: "600",
                marginBottom: "6px",
                width: "200px",

              }}
            >
              {Wanted_Job_Title}
            </Text>
            {/* <ContactInfo data={data} /> */}

            <View
              style={{
                backgroundColor: "grey",
                height: "3px",
                marginTop: "20px",
              }}
            >
              ..
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "30px",
                height: "110px",
                fontSize: "13px",
                // backgroundColor: "blue",
              }}
            >
              {/* <Text style={{ lineHeight: "2", fontWeight: "500" }}>{"www.website.com"}</Text> */}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "8px",
                }}
              >
                <View>
                  <Svg
                    stroke="#10edd2"
                    fill="none"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    height="16px"
                    width="16px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <Path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></Path>
                  </Svg>
                </View>

                <Text
                  style={{
                    lineHeight: "2",
                    fontWeight: "500",
                    marginLeft: "8%",
                  }}
                >
                  {Phone ? Phone : "0123456789"}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "14%",
                }}
              >
                <View>
                  <Svg
                    stroke="#10edd2"
                    fill="none"
                    stroke-width="0"
                    viewBox="0 0 14 16"
                    height="20px"
                    width="16px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <Path
                      fill-rule="evenodd"
                      d="M0 4v8c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H1c-.55 0-1 .45-1 1zm13 0L7 9 1 4h12zM1 5.5l4 3-4 3v-6zM2 12l3.5-3L7 10.5 8.5 9l3.5 3H2zm11-.5l-4-3 4-3v6z"
                    ></Path>
                  </Svg>
                </View>

                <Text
                  style={{
                    lineHeight: "2",
                    fontWeight: "500",
                    marginLeft: "8%",
                  }}
                >
                  {display_email ? display_email : "email@example.com"}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.wrapper}>
            <View style={styles.left}>
              <View
              // style={{
              //   // border: "2px solid yellow",
              //   flex: 1,
              // }}
              >
                {!isEmpty(Description) && (
                  <View
                    style={{
                      //padding: "10px 15px 10px 15px",
                      marginLeft: "20px",
                      marginBottam: "10px",
                      backgroundColor: "#ffffff",
                      fontWeight: "400",
                      fontSize: "8px",
                      color: "#34a6ba",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      letterSpacing: "1px",
                      marginTop: "26px",
                      //textAlign: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "18px",
                        fontWeight: 600,
                        top: "2px",
                        marginRight: "8px",
                        marginBottom: "4px",
                        //color: "black",
                        // textDecoration: "underline",
                      }}
                    >
                      Profile Summary
                    </Text>

                    <Text
                      style={{
                        marginTop: "5px",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        color: "#363434",
                        marginRight: "5px",
                        textAlign:"justify",
                      }}
                    >
                      {renderHTMLContent(Description)}
                    </Text>
                  </View>
                )}

                <AdditionalInfo data={data} />

                <RenderView
                  data={data}
                  dataKey="education"
                  heading="Education"
                  fieldMap={{
                    title: "institute_name",
                    subTitle: "degree",
                    startDate: "start_date",
                    endDate: "end_date",
                    description: "Description",
                  }}
                />
                <View style={{ marginTop: "10px" }}>
                  <RenderView
                    data={data}
                    dataKey="internships"
                    heading="Internships"
                    fieldMap={{
                      title: "employer_name",
                      subTitle: "job_title",
                      startDate: "start_date",
                      endDate: "end_date",
                      description: "description",
                    }}
                  />
                </View>

                   <View style={{ marginTop: "8px" }}>
                  <RenderView
                    data={data}
                    dataKey="courses"
                    heading="Courses"
                    fieldMap={{
                      title: "course_name",
                      subTitle: "Institute",
                      startDate: "start_date",
                      endDate: "end_date",
                    }}
                  />
                <View style={{ marginTop: "10px", }}>
                  <RenderView
                    data={data}
                    dataKey="activities"
                    heading="Extra_Activities"
                    fieldMap={{
                      title: "employer_name",
                      subTitle: "function_title",
                      startDate: "start_date",
                      endDate: "end_date",
                      description: "description",
                    }}
                  />
                </View>

             
                </View>
              </View>
            </View>
            <View style={styles.right}>
              <View
                style={{
                  // border: "2px solid blue",
                  flex: 1,
                  // width: "200px",
                  //marginTop: "12px",
                  paddingTop: "30px",
                  paddingRight: "15px",
                  // width:"200px",
                  textAlign:"justify",
                }}
              >
                <Experience
                  data={data}
                  dataKey="employmentHistory"
                  heading="Work Experience"
                  fieldMap={{
                    title: "Employer",
                    subTitle: "Job_title",
                    startDate: "start_date",
                    endDate: "end_date",
                    description: "Description",
                  }}
                />

                <Skills data={data} />

                <Hobbies data={data} />

                {/* <RenderView
                  data={data}
                  dataKey="hobbies"
                  heading="Hobbies"
                  fieldMap={{
                    title: "hobbies",
                  }}
                /> */}

                <Languages data={data} />

                <References
                  data={data}
                  dataKey="References"
                  heading="References"
                  fieldMap={{
                    title: "ref_name",
                    subTitle: "company_name",
                    phoneNo: "phone_no",
                    emailId: "ref_email",
                  }}
                />
                <CustomSection
                data={data}
                  dataKey="customSection"
                  //heading="custom_title"
                  fieldMap={{
                    custom_title:"custom_title",
                    title: "section_name",
                    city: "city",
                    startDate: "start_date",
                    endDate: "end_date",
                    description: "description",
                  }}
                />
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </TemplatePage>
  );
};

Template10.displayName = "Template10";
export default Template10;
