// import node module libraries
import { Fragment, useState, useEffect } from "react";
import { Col, Row,Image } from "react-bootstrap";
// import sub components
import JobListingListviewCard from "./JobListingListviewCard";
import Url from "components/Url";
import { getTimePassed } from "../../helper/utils.js";
import { Link } from "react-router-dom";

const JobsListView = ({ postrec, Apply }) => {
  const [fav, setFav] = useState(new Set());
  const url1 = Url();
  const url = url1["url"];
  const s3 = url1["s3"];
  const studentId = localStorage.getItem("studentId");
  const bannerIndices = {
    1: "creatives/Banner_1.jpg",
    3: "creatives/Banner_2.jpg",
    5: "creatives/Banner_3.jpg",
    7: "creatives/Banner_4.jpg",
  };

  const Favorite = (employerInternshipId) => {
    if (!studentId) {
      alert("Please log in first");
      return;
    }
    const api = url + "addRemoveFavoriteInternship";

    fetch(api, {
      method: "POST",
      body: JSON.stringify({ employerInternshipId, studentId }),
      headers: { "Content-Type": "application/json" },
    })
      .then((apidata) => {
        return apidata.json();
      })
      .then((actualdata) => {
        if (actualdata["favorite_ind"] == 1) {
          let st = new Set();
          fav.forEach((ele) => st.add(ele));
          st.add(employerInternshipId);
          setFav(st);
          alert("Added to Favorites");
        }
        if (actualdata["favorite_ind"] == 0) {
          console.log("already");
          let st = new Set();
          fav.forEach((ele) => {
            if (employerInternshipId != ele) st.add(ele);
          });

          setFav(st);
          alert("Removed from Favorites");
        }
      });
  };
  // get the favourite post details
  useEffect(() => {
    const controller = "getStudentFavoriteInternships/" + studentId;
    const api = url + controller;
    fetch(api, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        console.log("inside fav", actualdata1);
        let favStore = new Set();
        actualdata1.forEach((ele) => {
          favStore.add(ele);
        });
        console.log(favStore, "store");
        setFav(favStore);
      });
  }, []);
  //   console.log("see", fav);
  return (
    <Fragment>
      <Row>
        {postrec.length > 0 ? (
          postrec.map((record, index) => {
            return (
              <Fragment  key={index}>
              <Col xs={12} className="mb-4" key={index}>
                <JobListingListviewCard
                  item={record}
                  key={index}
                  fav={fav}
                  setFav={setFav}
                  Apply={Apply}
                  Favorite={Favorite}
                  getTimePassed={getTimePassed}
                />
              </Col>
              {bannerIndices[index] && (
                <Row className="w-100 mb-4 ms-1 p-0">
                  <Link to="/virtual-internship" target="_blank" rel="noopener noreferrer">
                    <Image src={`${s3}${bannerIndices[index]}`} className="w-100 img-fluid rounded" />
                  </Link>
                </Row>
              )} 
              </Fragment>             
            );
          })
        ) : (
          <Col>No matching records found.</Col>
        )}
      </Row>
    </Fragment>
  );
};

export default JobsListView;
