// import node module libraries
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Container, Tab, Nav } from "react-bootstrap";

// import sub components
import GetEnrolledCourseCard from "./GetEnrolledCourseCard";

// import data files
import { AllCoursesData } from "data/slider/AllCoursesData";

import Url from "../Url";

const MostPopularCourses = () => {
  const url1 = Url();
  const url = url1["url"];
  const s3URL = url1["s3"] + "courseImages/";
  const [search, setSearch] = useState("");
  const [courseList, setCourseList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [endDate, setEndDate] = useState("")
  const [timeRemaining, setTimeRemaining] = useState();
  const [dataLoaded, setDataLoaded] = useState(false)

  useEffect(() => {
    const api = url + "getAllActiveVICourses";

    fetch(api, {
      method: "GET",
    })
      .then((apidata) => {
        return apidata.json();
      })
      .then((actualdata) => {
        setCourseList(actualdata.courseData);
        setCategoryList(actualdata.categoryList);
        setTabs(actualdata.planList);
        setEndDate(actualdata.courseData[0].discount_end_date)
        setDataLoaded(true);
      });
  }, []);

  useEffect(() => {
    if(dataLoaded){
      const intervalId = setInterval(() => {
        setTimeRemaining(calculateTimeRemaining());
      }, 1000);
  
      return () => clearInterval(intervalId);
    }
  }, [dataLoaded]);

  function calculateTimeRemaining() {
    if (!endDate) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
    const endTime = new Date(endDate).getTime();
    const currentTime = new Date().getTime();
    const timeDiff = endTime - currentTime;

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((timeDiff / 1000 / 60) % 60);
    const seconds = Math.floor((timeDiff / 1000) % 60);

    return { days, hours, minutes, seconds };
  }

  var timeRemainingString;
  if(timeRemaining){
   timeRemainingString = `${timeRemaining.days}d ${timeRemaining.hours}h ${timeRemaining.minutes}m ${timeRemaining.seconds}s`;
  }  

  let min,
    max = 0;


  return (
    <Fragment>
      <section className="py-lg-8 py-4 bg-white" id="popularcourses">
        <Container>
          <Row>
            <Col xs={12}>
              <div className="mb-2">
                <h2 className="mb-0 h1">Most Popular Internships</h2>
                <div className="d-sm-flex d-block align-items-center justify-content-between w-100">
                    <p className="mb-3 mb-sm-0">
                    These are the most popular Internships in year{" "}
                    {new Date().getFullYear()}
                    </p>
                    <p className="mb-3 mb-sm-0 fw-bold">Hurry up...! Discount Offer Closes in <span className="text-danger">{timeRemainingString}</span></p>
                <Col md={3}>
                <input
                    type="search"
                    value={search}
                    className="form-control"
                    placeholder="Search Internships"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Col>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {tabs.length > 0 ? (
                <Tab.Container defaultActiveKey={tabs[0].id}>
                  <Nav className="nav-lb-tab bg-gray-200 px-5 rounded-3 ">
                    {tabs?.map((tab, index) => {
                      return (
                        <Nav.Item
                          key={tab.id}
                          className={index == 0 ? "ms-0" : ""}
                        >
                          <Nav.Link
                            eventKey={tab.id}
                            className="mb-sm-3 mb-md-0"
                          >
                            {tab.plan_name}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                  <Tab.Content>
                    {/* 
                      <Tab.Pane eventKey={"Development"} className="pb-4 p-4 ps-0 pe-0" key={1}>
                        <Row>
                        {courseList.map((course, index) => (                         
                          <Col lg={3} md={6} sm={12} key={course.subject_id}> <GetEnrolledCourseCard item={course} /></Col>
                          )
                        )}                       
                        </Row>
                      </Tab.Pane>
                      */}

                    {tabs.map((tab, index) => {
                      return (
                        <Tab.Pane
                          eventKey={tab.id}
                          className="pb-4 p-4 ps-0 pe-0"
                          key={index}
                        >
                          <Row>
                            {/* <Col md={12}>
                            <input
                              type="search"
                              value={search}
                              className="form-control mb-3"
                              placeholder="Search courses"
                              onChange={e => setSearch(e.target.value)}
                            />
                          </Col> */}
                            {courseList
                              .filter((x) => x.course_duration == tab.id)
                              .map((course, index) =>
                                search ? (
                                  course.subject_name
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ? (
                                    <Col lg={3} md={6} sm={12} key={index}>
                                      {" "}
                                      <GetEnrolledCourseCard item={course} />
                                    </Col>
                                  ) : null
                                ) : (
                                  <Col lg={3} md={6} sm={12} key={index}>
                                    {" "}
                                    <GetEnrolledCourseCard item={course} />
                                  </Col>
                                )
                              )}
                          </Row>
                        </Tab.Pane>
                      );
                    })}
                  </Tab.Content>
                </Tab.Container>
              ) : null}
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default MostPopularCourses;
