// import node module libraries
import { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';

// import custom components
//import SectionHeadingCenter from 'components/marketing/common/section-headings/SectionHeadingCenter';
import TestimonialsSlider from './TestimonialsSlider';


const TestimonialSection = () => {
	const title = 'Don’t just take our word for it.';
	const subtitle = 'Testimonials';
	const description = `1000+ students are already getting mentorships at different locations`;

	return (
		<Fragment>
			{/*  Section left heading */}
			<SectionHeadingCenter
				title={title}
				subtitle={subtitle}
				description={description}
			/>

			<Row className="mb-4">
				<Col md={12}>
					{/*  Testimonial slider */}
					<TestimonialsSlider />
				</Col>
			</Row>
		</Fragment>
	);
};

// Centered aligned section heading
// title, subtitle with description in centered aligned

// import node module libraries

const SectionHeadingCenter = ({ title, subtitle, description }) => {
	return (
		<Row className="mb-4 pt-8 justify-content-center">
			<Col lg={8} md={12} sm={12} className="text-center">
				<span className="text-primary mb-3 d-block text-uppercase fw-semi-bold ls-xl">
					{subtitle}
				</span>
				<h2 className="mb-2 display-4 fw-bold ">{title}</h2>
				<p className="lead">{description}</p>
			</Col>
		</Row>
	);
};

export default TestimonialSection;
