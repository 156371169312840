// Section : Features
// Style : Four Columns Features Section

// import node module libraries
import { Col, Row, Container } from 'react-bootstrap';

// import custom components
import SectionHeadingCenter from 'components/marketing/common/section-headings/SectionHeadingCenter';
import FeatureTopIcon from 'components/marketing/common/features/FeatureTopIcon';

const Features4Columns = () => {
	const title = 'Finding it hard to Upskill.';
	const subtitle = 'We are here to help you out';
	const description = `Choose from some of the most popular in demand skills
	given below or Search your own skill.`;

	const features = [
		{
			id: 1,
			icon: 'settings',
			title: 'Learn the latest skills',
			description: `Lorem ipsum dolor sit amet, lorem consectetur adipiscing elit.`
		},
		{
			id: 2,
			icon: 'user',
			title: 'Get ready for a career',
			description: `Pellentesque eu mi rhoncus, rhoncus tortor a, interdum nisi.`
		},
		{
			id: 3,
			icon: 'award',
			title: 'Earn a Certificate',
			description: `Quisque tempus lectus cursus, imperdiet eros vel, pulvinar arcu.`
		},
		{
			id: 4,
			icon: 'users',
			title: 'Upskill your organization',
			description: `Etiam dignissim est tristique ex porta, bibendum commodo.`
		}
	];

	return (
		<div className="py-2 py-lg-2 bg-white">
			<Container>
				<SectionHeadingCenter
					title={title}
					description={description}
					subtitle={subtitle}
				/>
				{/* <Row>
					{features.map((item, index) => {
						return (
							<Col lg={3} md={6} sm={12} key={index}>
								<FeatureTopIcon item={item} />
							</Col>
						);
					})}
				</Row> */}
			</Container>
		</div>
	);
};

export default Features4Columns;
