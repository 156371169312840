import React, { useState, useEffect, Fragment } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  Form,
  FormSelect,
  Card,
  Spinner,
} from "react-bootstrap";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import Url from "components/Url";
import Axios from "axios";
import Select from "react-select/creatable";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import ProfileLayout from "./ProfileLayout";

const MentorEducations = () => {
  const [loadData, setLoadData] = useState(false);
  const [mentorEducation, setMentoEducation] = useState([]);
  const [educationDetail, setEducationDetail] = useState({});
  const [showEducationModal, setShowEducationModal] = useState(false);

  const mentorId = localStorage.getItem("mentorId");
  const token = localStorage.getItem("admtoken") ? localStorage.getItem("admtoken") : localStorage.getItem("mentortoken");
  const url1 = Url();
  const url = url1["url"];
  const s3URL = url1["s3"];

  useEffect(() => {
    
    fetchData();
    setLoadData(false);
  }, [loadData]);

  const fetchData = async () => {

    var api = url + "getMentorEducation" + "/" + mentorId;

    try {
      const response = await Axios.get(api, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-USER-ID": mentorId,
        },
      });

      setMentoEducation(response.data.mentorEducation);
    } catch (error) {

      toast.warning("Error getting the mentor education data");
    }
  }; //end of fetchData()

  const editEducation = async (id) => {
    var api = url + "getMentorEducationDetails" + "/" + id;
    try {
      const response = await Axios.get(api, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-USER-ID": mentorId,
        },
      });

      if (response.data.educationDetails) {
        setEducationDetail(response.data.educationDetails);
        setShowEducationModal(true);
      }
    } catch (error) {
      toast.warning("Error getting the mentor education data");
    }
  };

  async function deleteEducation(id) {
    if (
      id &&
      window.confirm("Are you sure? Want to delete Education detail?")
    ) {
      const api = url + "deleteMentorEducationDetails/" + id;


      try {
        const response = await Axios.delete(api, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-USER-ID": mentorId,
          },
        });
  
        toast.success(response.data.message,{autoClose:1000});
        setLoadData(true);

      } catch (error) {
        toast.warning("Error getting the mentor education data");
      }

    }
  }

  const addEducation = () => {
    setEducationDetail([]);
    setShowEducationModal(true);
  };

  return (
    <ProfileLayout>
      <Row className="mb-2 border-bottom">
        <Card className="border-0">
          <Card.Header>
            <div className="mb-3 mb-lg-0">
              <h3 className="mb-0">Education Details</h3>
            </div>
          </Card.Header>
          <Card.Body>

            {mentorEducation.length > 0 ? (
              mentorEducation.map((item, index) => (
                <Col className="my-2 py-2 col " key={index}>
                  <div className="h5">
                    {item.degree}-{item.specialization}
                    {/*Bachelor of Computer Applications (BCA), Computer Application*/}
                    <Fragment>
                      <span
                        onClick={() =>
                          deleteEducation(item.mentor_education_id)
                        }
                        className="float-end"
                        style={{ cursor: "pointer" }}
                      >
                        <Trash />
                      </span>
                      <span
                        onClick={() => editEducation(item.mentor_education_id)}
                        className="float-end mx-2 pe-auto"
                        style={{ cursor: "pointer" }}
                      >
                        <PencilSquare />
                      </span>
                    </Fragment>
                  </div>
                  <div className="mainHeadings">
                    {item.institute_name}
                  </div>
                  <div className="mainHeadings">
                    <>
                      From {item.start_date === "NA" ? null : item.start_date}{" "}
                      to {item.end_date === null ? "Present" : item.end_date}
                    </>
                  </div>
                </Col>
              ))
            ) : (
              <div className="mt-2 text-danger">
                Education Details are mandatory...!!
              </div>
            )}

            <Row>
              <Col>
                <div className="mb-3 d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={addEducation}
                  >
                    Add Education{" "}
                  </button>
                </div>
              </Col>
            </Row>

            {showEducationModal ? (
              <EducationPopup
                setShowEducationModal={setShowEducationModal}
                showEducationModal={showEducationModal}
                setLoadData={setLoadData}
                educationDetail={educationDetail}
                token={token}
                mentorId={mentorId}
              />
            ) : null}
          </Card.Body>
        </Card>
      </Row>
    </ProfileLayout>
  );
};

/**********************************************************************************************/
/************************* EducationPopup Modal Component *************************************/
/**********************************************************************************************/

const EducationPopup = (props) => {
  const {
    setShowEducationModal,
    showEducationModal,
    setLoadData,
    educationDetail,
    token,
    mentorId,
  } = props;

  const url1 = Url();
  const url = url1["url"];

  //const educationDetail = [];

  const [isEdit, setIsEdit] = useState(false);
  const [createValidate, setCreateValidate] = useState(false);

  const [loading, setLoading] = useState(false);

  const [degreeList, setDegreeList] = useState([]);
  const [specializationList, setSpecializationList] = useState([]);
  const [apiMessage, setApiMessage] = useState("");
  const [apiStatus, setApiStatus] = useState(0);

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [degree, setDegree] = useState("");

  const [formData, setFormData] = useState({
    id: educationDetail?.id ? educationDetail?.id : 0,
    mentor_id: educationDetail?.mentor_id ? educationDetail?.mentor_id : 0,
    degree: educationDetail?.degree ? educationDetail?.degree : "",
    specialization: educationDetail?.specialization
      ? educationDetail?.specialization
      : "",
    institute_name: educationDetail?.institute_name
      ? educationDetail?.institute_name
      : "",
    start_date: educationDetail?.start_date ? educationDetail?.start_date : "",
    end_date: educationDetail?.end_date ? educationDetail?.end_date : "",
  });

  let currentYear = new Date().getFullYear();

  let startYearArray = [];
  let endYearArray = [];
  for (var i = 0; i < 30; i++) {
    startYearArray.push({ value: currentYear - i, label: currentYear - i });
  }

  for (var i = 0; i < 34; i++) {
    endYearArray.push({
      value: currentYear + 4 - i,
      label: currentYear + 4 - i,
    });
  }

  const [startYearList, setStartYearList] = useState(startYearArray);
  const [endYearList, setEndYearList] = useState(endYearArray);

  const [collegeList, setCollegeList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [masterDataLoaded, setMasterDataLoaded] = useState(false);

  useEffect(() => {
    
    setLoading(true);

    const fetchData = async () => {
      await searchCollegeList();
      await populateDegreeList();
  
      if (educationDetail.degree) {
        await populateSpecializationList(educationDetail.degree);
      }
  
      setMasterDataLoaded(true);
    };
  
    setIsEdit(!!educationDetail.id);
    fetchData();
  }, [educationDetail]);

  useEffect(() => {
    if (masterDataLoaded) {

      setFormData({
        ...educationDetail,
        institute_name: collegeList.find(
          (option) => option.value == educationDetail.institute_name
        ),
        degree: degreeList?.find(
          (option) => option.value == educationDetail.degree
        ),
        specialization: specializationList?.find(
          (option) => option.value == educationDetail.specialization
        ),
        start_date: startYearList?.find(
          (option) => option.value == educationDetail.start_date
        ),
        end_date: endYearList?.find(
          (option) => option.value == educationDetail.end_date
        ),
      });
      setMasterDataLoaded(false);
      setLoading(false);
    }
  }, [masterDataLoaded, collegeList, degreeList, specializationList]);

  async function populateDegreeList() {


    const locapi = url + "getDegreeList";

    try {
      const response = await fetch(locapi, { method: "GET" });
      const degreeList = await response.json();

      setDegreeList(degreeList); // Assuming setDegreeList is a function that updates your state or performs a similar action
    } catch (error) {
      console.error("Failed to populate degree list:", error);
    }
  }

  async function populateSpecializationList(degreeVal) {
    //get Degree list
    //e.preventDefault();
    //const degree = e.target.value;
    setDegree(degreeVal);
    const locapi = url + "getSpecializationList/" + degreeVal;

    try {
      const response = await fetch(locapi, { method: "GET" });
      const specialization = await response.json();
      setSpecializationList(specialization); // Assuming setDegreeList is a function that updates your state or performs a similar action
    } catch (error) {
      console.error("Failed to populate degree list:", error);
    }
  }

  const handleSelectChange = (selectedOption, actionMeta) => {
    if (actionMeta.name == "degree") {
      populateSpecializationList(selectedOption.label);
    }

    setFormData((prevState) => ({
      ...prevState,
      [actionMeta.name]: selectedOption,
    }));
  };

  const saveEducationDetails = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setCreateValidate(true);
      return;
    }


    try{
    const creapi = url + "saveMentorEducationDetails";
    const response = await Axios.post(
      creapi,
      {
        mentor_education_id: formData.id ? formData.id : null,
        mentor_id: formData.mentor_id ? formData.mentor_id : mentorId,
        degree: formData.degree.value,
        specialization: formData.specialization.value,
        institute_name: formData.institute_name.value,
        start_date: formData.start_date.value,
        end_date: formData.end_date.value,
      },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-USER-ID": mentorId,
        },
      }
    );


    setApiMessage(response.data.message);
    setApiStatus(response.data.status);
    
    if (!formData.id && response.data.status == 1) {
      setButtonDisabled(true);
    }

    if(response.data.status == 1) setLoadData(true);
    }catch (error) {
      console.error(error);
      // Handle different types of errors
      if (error.response) {
        // The server responded with a status code outside the 2xx range
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
  
        // Example: set an error message based on the status code or error response
        if (error.response.status === 400) {
          setApiMessage("Validation error: Please check your input.");
        } else {
          setApiMessage("An unexpected error occurred. Please try again.");
        }
        setApiStatus(error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
        setApiMessage("No response from the server. Please check your network connection.");
      } else {
        // Something happened in setting up the request that triggered an error
        console.log('Error', error.message);
        setApiMessage("An error occurred. Please try again.");
      }
    }
  };

  let cancel;

  const searchCollegeList = async (query) => {
    if (cancel) {
      // Cancel the previous request before making a new request
      cancel.cancel();
    }
    // Create a new CancelToken
    cancel = Axios.CancelToken.source();
    const resources = {};
    try {
      if (resources[query]) {
        // Return result if it exists
        setCollegeList(resources[query]);
        return;
      }
      const apiURL = url + "getCollegeListByName" + (query ? "/" + query : "");
      const res = await Axios.get(apiURL, { cancelToken: cancel.token });

      const result = res.data.collegeList;
      // Store response
      resources[query] = result;

      setCollegeList(result);
    } catch (error) {
      if (Axios.isCancel(error)) {
        // Handle if request was cancelled
        console.log("Request canceled", error.message);
      } else {
        // Handle usual errors
        console.log("Something went wrong: ", error.message);
      }
    }
  };

  /************ COLLEGE DROPDOWN DYNAMIC SEARCH - START ************************* */

  const handleCreate = async (inputValue) => {

    const apiURL = url + "addNewCollege";
    const res = await Axios.post(apiURL, { collegeName: inputValue });

    const result = res.data.collegeList;
    //setInstitute_Name(inputValue);
    setFormData({
      ...formData,
      institute_name: result.find(
        (option) => option.value == inputValue
      ),});
    setCollegeList(result);
  };

  /************ COLLEGE DROPDOWN DYNAMIC SEARCH - END ************************* */

  const handleClose = () => {
    setShowEducationModal(false);
    setLoadData(true);
  };
  return (
    <Modal show={showEducationModal} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          {isEdit ? "Edit Education Details" : "Add Education Details"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? 
        <>
        Loading Data...Please wait { } <Spinner />

        </>
        :
          (
        <Form noValidate onSubmit={saveEducationDetails}>
          <Row className="mb-2">
            <div className="col-4 text-end ">
              <h5 className="m-1">College Name</h5>
            </div>
            <div className="col-8">
              <Form.Group className="mb-3" controlId="institute_name">
                <Select
                  name="institute_name"
                  isClearable
                  isSearchable
                  inputValue={inputValue}
                  onInputChange={(value) => setInputValue(value)}
                  // onChange={(e) =>
                  //   setInstitute_Name(e.target.value ? e.target.value : "")
                  // }
                  onChange={handleSelectChange}
                  options={collegeList}
                  value={formData.institute_name}
                  onCreateOption={handleCreate}
                />

                <Form.Control.Feedback type="invalid">
                  Please enter Institute Name
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-4 text-end ">
              <h5 className="m-1">Degree</h5>
            </div>
            <div className="col-8">
              <Select
                id="degree"
                name="degree"
                placeholder="Select Degree"
                value={formData.degree}
                //onChange={(e) => setDegree(e.target.value ? e.target.value : "")}
                onChange={handleSelectChange}
                options={degreeList}
                required
                isValid={createValidate && degree}
                isInvalid={createValidate && !degree}
              />

              <Form.Control.Feedback type="invalid">
                Please select Degree
              </Form.Control.Feedback>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-4 text-end ">
              <h5 className="m-1">Stream / Specialization</h5>
            </div>
            <div className="col-8">
              <Select
                id="specialization"
                name="specialization"
                placeholder="Select Specialization"
                value={formData.specialization}
                onChange={handleSelectChange}
                required
                options={specializationList}
                isValid={createValidate && specialization}
                isInvalid={createValidate && !specialization}
              />

              <Form.Control.Feedback type="invalid">
                Please select Specialization
              </Form.Control.Feedback>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-4 text-end ">
              <h5 className="m-1">Start Year</h5>
            </div>
            <div className="col-8">
              <Select
                id="start_date"
                name="start_date"
                placeholder="Select Start Date"
                value={formData.start_date}
                onChange={handleSelectChange}
                options={startYearList}
                required
                isValid={createValidate && start_date}
                isInvalid={createValidate && !start_date}
              />

              <Form.Control.Feedback type="invalid">
                Please select Start Year
              </Form.Control.Feedback>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-4 text-end ">
              <h5 className="m-1">End Year</h5>
            </div>
            <div className="col-8">
              <Select
                id="end_date"
                name="end_date"
                placeholder="Select End Year"
                value={formData.end_date}
                onChange={handleSelectChange}
                options={endYearList}
                required
                isValid={createValidate && end_date}
                isInvalid={createValidate && !end_date}
              />
              <Form.Control.Feedback type="invalid">
                Please select End Year
              </Form.Control.Feedback>
            </div>
          </Row>
          <Row className="mb-2 d-flex d-inline">
            <div className="mb-3 d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary btn-sm"
                disabled={buttonDisabled}
              >
                Save
              </button>

              <button
                type="button"
                className="btn btn-outline-warning btn-sm ms-2"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </Row>
          <Row>
            {apiMessage !== "" && (
              <span className={apiStatus ? "text-success " : "text-danger "}>
                {apiMessage}
              </span>
            )}
            {/*apiStatus==1 && <span className="text-success small">Education details updated successfully</span> */}
          </Row>{" "}
        </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MentorEducations;
