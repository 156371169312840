import React, { useState, useEffect, Fragment } from "react";
import { Col, Row, Button, Modal, Form, FormSelect } from 'react-bootstrap';
import { PencilSquare, Trash } from "react-bootstrap-icons";
import Url from "components/Url";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Trainings = (props) => {
    const {studentTrainings,
           setLoadData,
           applicantId,
           locationList,
           employerView
        } = props; 

    const [trainingDetail, setTrainingDetail] = useState({});
    const [showTrainingModal, setShowTrainingModal] = useState(false);

    const url1 = Url();
    const url = url1["url"];
    const s3URL = url1["s3"];

    function deleteTraining(id) {
        if (id && window.confirm("Are you sure? Want to delete Training detail?")) {
            const api = url + "deleteApplicantTrainingById/" + id;
            const config = {
            method: "DELETE",
            };
            fetch(api, config)
            .then((res) => res.json())
            .then((data) => {
                if (data.message === "Record deleted") {
                alert("Training Details Deleted Successfuly");
                setLoadData(true);
                }
            });
        }
    }    
    function editTraining(id) {
        //console.log("editTraining" + id);
        const api1 = url + "getApplicantTrainingById/" + id;
        fetch(api1, {
            method: "GET",
        })
        .then((apidata1) => {
            return apidata1.json();
        })
        .then((actualdata1) => {
            setTrainingDetail(actualdata1);
        });        
        setShowTrainingModal(true);
    }       
    
    const addTraining = () => {
        setTrainingDetail([]);
        setShowTrainingModal(true);
    }        
    
    return (
        <Row className="mb-2 border-bottom">
        <Col md={3}>
            <span className="text-dark">Trainings:</span>
        </Col>
        <Col md={9}>
            {studentTrainings.length > 0 ? (
                studentTrainings.map((item,index) => (
                    <Col className="ml-2" key={index}>
                        <div className="h5">
                            {item.tra_profile}
                            {!employerView && 
                            <Fragment>
                            <span
                                onClick={() => deleteTraining(item.id)}
                                className="float-end"
                                style={{cursor:"pointer"}}
                                >
                                <Trash />
                            </span>
                            <span
                                onClick={() => editTraining(item.id)}  
                                className="float-end mx-2 pe-auto"
                                style={{cursor:"pointer"}}
                                >
                                <PencilSquare />
                            </span>           
                            </Fragment>
                            }                    
                        </div>
                        <div>
                            {item.tra_org_name}, {item.tra_location}
                        </div>
                        <div className="">
                            From{" "}
                            {
                                item.tra_start_date
                            }{" "}
                            to{" "}
                            {item.tra_current_status ===
                            "Currently working here"
                                ? "Present"
                                : 
                                        item.tra_end_date
                                    }
                        </div>
                        <div className="mb-4 text-justify">
                            {item.tra_description}
                        </div>
                    </Col>
                ))
                ) : (
                    <div className="mt-2">No training details.....</div>
                )}
                {!employerView &&
                <Row>
                    <Col><div className="mb-3 d-flex justify-content-end">
                        <button type="button" className="btn btn-primary btn-sm" onClick={addTraining}>Add Training </button></div>
                    </Col>
                </Row>
                }
        </Col>
    
            {showTrainingModal ? (
                <TrainingPopup
                    setShowTrainingModal={setShowTrainingModal}
                    showTrainingModal={showTrainingModal}
                    setLoadData={setLoadData}
                    trainingDetail={trainingDetail}
                    locationList={locationList}
                />
            ) : null}            
        </Row>
    )
}

/**********************************************************************************************/
/************************* TrainingPopup Modal Component ************************************/
/**********************************************************************************************/

const TrainingPopup = (props) => {

    const {setShowTrainingModal,
           showTrainingModal,
           setLoadData,
           trainingDetail,
           locationList,
           employerView
         } = props;

    const url1 = Url();
    const url = url1["url"];    

    //const trainingDetail = [];

    const [isEdit,setIsEdit] = useState(false);
    
    const [show, setShow] = useState(props.show);
    
    const [id,setId] = useState("");
    const [email, setEmail] = useState("");
	const [tra_profile, setTra_profile] = useState("");
	const [tra_org_name, setTra_org_name] = useState("");
	const [tra_location, setTra_location] = useState("");
	const [tra_start_date, setTra_start_date] = useState("");
	const [tra_end_date, setTra_end_date] = useState("");
	const [tra_current_status, setTra_current_status] = useState("");
	const [tra_description, setTra_description] = useState("");

    const [apiMessage, setApiMessage] = useState("");
    const [apiStatus, setApiStatus] = useState(0);

    const [currentDate,setCurrentDate] = useState(new Date());

	const [currentlyWorkHere, setCurrentlyWorkHere] = useState(false);
    
    const [buttonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
        console.log(trainingDetail);
        if(trainingDetail.id){

            setIsEdit(true);

            setId(trainingDetail.id);
            setEmail((trainingDetail.email ? trainingDetail.email : localStorage.getItem("email")));

            setTra_profile(trainingDetail.tra_profile);
            setTra_org_name(trainingDetail.tra_org_name);
            setTra_location(trainingDetail.tra_location);
            setTra_start_date(trainingDetail.tra_start_date);
            setTra_end_date(trainingDetail.tra_end_date);

            setTra_current_status(trainingDetail.tra_current_status);
            if(trainingDetail.tra_current_status == "Currently working here"){
                setCurrentlyWorkHere(true);
            }else{
                setCurrentlyWorkHere(false);                
            }

            setTra_description(trainingDetail.tra_description);
        }else{
            setCurrentlyWorkHere(false);
        }

    },[trainingDetail]);

	const handleCurrentlyWorkHere = () => {

		setCurrentlyWorkHere(!currentlyWorkHere);
        //the new state will be available in next render hence we need to check for the existing
        if(!currentlyWorkHere){
            setTra_current_status("Currently working here");
            setTra_end_date("");
        }else{
            setTra_current_status("NO");
        }

	};

    const handleClose = () => {
        setShowTrainingModal(false);
        setLoadData(true);
    }

    const saveTrainingDetails = e => {
        
        e.preventDefault();
        const applicantId = localStorage.getItem("applicantid");
        const email = localStorage.getItem("email");

        const creapi = url + "updateApplicantTraining";
        fetch(creapi, {
            method: "POST",
            body: JSON.stringify({
                id,
                email,
                tra_profile,
                tra_org_name,
                tra_location,
                tra_start_date,
                tra_end_date,
                tra_current_status,
                tra_description,
                applicantId,
            }),
            headers: { "Content-Type": "application/json" },
        })
        .then((apidata) => {
            return apidata.json();
        })
        .then((actualdata) => {
            setApiMessage(actualdata["message"]);
            setApiStatus(actualdata["status"]);

            if(!id && actualdata["status"] == 1){
                setButtonDisabled(true);
            }            

        });
        return;
    }

    return (
        <Modal show={showTrainingModal} onHide={handleClose} size="md">
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? "Edit Training Details" : "Add Training Details"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>            
                <Row className="mb-2">
                    <div className="col-4 text-end ">
                        <span className="h5 m-1">Training Program</span>
                    </div>
                    <div className="col-8">
                        <Form.Control
                            type="text"
                            placeholder="Name of Training"
                            id="tra_profile"
                            name="tra_profile"
                            value={tra_profile}
                            onChange={(e) => setTra_profile(e.target.value)}
                            required
                        />     
                    </div>
                </Row>
                
                <Row className="mb-2">
                    <div className="col-4 text-end ">
                        <span className="h5 m-1">Organization</span>
                    </div>
                    <div className="col-8">
                        <Form.Control
                            type="text"
                            placeholder="Organization/Institute Name"
                            id="tra_org_name"
                            name="tra_org_name"
                            value={tra_org_name}
                            onChange={(e) => setTra_org_name(e.target.value)}
                            required
                        /> 
                    </div>
                </Row>
                
                <Row className="mb-2">
                    <div className="col-4 text-end ">
                            <span className="h5 m-1">Location</span>
                        </div>

                        <div className="col-8">

                            <Form.Control
                                type="text"
                                placeholder="Enter Location"
                                id="tra_location"
                                name="tra_location"
                                value={tra_location}
                                onChange={(e) => setTra_location(e.target.value)}
                                required
                                //options={salutationOptions}
                            />
                    </div>
                </Row>     
                <Row className="mb-2">
                    <div className="col-4 text-end ">
                        <h5 className="m-1">Start Date</h5>
                    </div>
                    <div className="col-8 d-md-flex">
                        <Form.Group >
                            <Form.Control
                                type="date"
                                size="sm"
                                placeholder="Start Date"
                                className="expected-y"
                                onFocus={(e) => e.target.select()}
                                value={tra_start_date}
                                onChange={(e) => setTra_start_date(e.target.value)}
                                max={currentDate}
                                required
                            />                
                        </Form.Group>   
                    </div>
                </Row>
                
                <Row className="mb-2">
                    <div className="col-4 text-end ">
                        <h5 className="m-1">End Date</h5>
                    </div>
                     
                    <div className="col-8 d-md-flex">
                        {!currentlyWorkHere ? 
                        (
                        <Form.Group >
                            <Form.Control
                                type="date"
                                size="sm"
                                placeholder="End date"
                                className="expected-y"
                                onFocus={(e) => e.target.select()}
                                value={tra_end_date}
                                onChange={(e) => setTra_end_date(e.target.value)}
                                max={currentDate}
                                required
                            />                
                        </Form.Group>
                        ):"Currently working"
                        }   
                    </div>
                    
                </Row>
      
                <Row className="mb-2">
                    <div className="col-4 text-end ">
                        <h5 className="m-1">Currently working</h5>
                    </div>
                    <div className="col-8">
                        <Form.Check
                            className="option radiotype1 "
                            type="checkbox"
                            name="tra_current_status"
                            onClick={handleCurrentlyWorkHere}
                            checked={currentlyWorkHere}
                            //onChange={(e) => {handleCurrentlyWorkHere}}
                            label={false}
                        />                     
                    </div>
                </Row>
 
                <Row className="mb-2">
                    <div className="col-4 text-end ">
                        <h5 className="m-1">Description</h5>
                    </div>
                    <div className="col-8">
                        <Form.Control
                            placeholder="short discription max -500"
                            as="textarea"
                            rows={3}
                            name="tra_description"
                            onFocus={(e) => e.target.select()}
                            value={tra_description}
                            onChange={(e) => setTra_description(e.target.value)}
                            required
                        />                     
                    </div>
                </Row>
 
                <Row className="mb-2 d-flex d-inline">
                    <div className="mb-3 d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary btn-sm" disabled={buttonDisabled} onClick={saveTrainingDetails}>Save</button>

                        <button type="button" className="btn btn-outline-warning btn-sm ms-2" onClick={handleClose}>Close</button>
                    </div>
                </Row>               

                <Row>
                    {apiMessage !== "" && <span className={ (apiStatus ? "text-success " : "text-danger ")}>{apiMessage}</span> }
                    {/*apiStatus==1 && <span className="text-success small">Training details updated successfully</span> */}
                </Row>                                                                                           
            </Modal.Body>

        </Modal>
    );
}


export default Trainings;