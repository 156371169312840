import React, { memo, useCallback, useEffect, useState } from "react";
import { Col, Container, Row, Badge } from "react-bootstrap";
import { Link } from 'react-router-dom';
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardHeader from "@material-ui/core/CardHeader";
import { FiPlus, FiTrash2, FiCopy, FiEdit } from "react-icons/fi";
import Api, { ENDPOINTS } from "../../../Api";
import { Button } from "react-bootstrap";
//import Studentnavbar from "../../NavbarFullLength/NavbarFullLength";
import Template1Img from "./templates/template1/Resume_template1.png";
// import Template2Img from "../Resume_template2.png";
// import Template3Img from "../Resume_template3.png";
// import Template4Img from "../Resume_template4.png";
// import Template5Img from "../Resume_template5.png";
// import Template6Img from "../Resume_template6.png";
// import Template7Img from "../Resume_template7.png";
// import Template8Img from "../Resume_template8.png";
// import Template9Img from "../Resume_template9.png";
import "./ResumeList.css";
import Template1 from "./templates/template1";
// import Template2 from "./templates/template2";
// import Template3 from "./templates/template3";
// import Template4 from "./templates/template4";
// import Template5 from "./templates/template5";
// import Template6 from "./templates/template6";
// import Template7 from "./templates/template7";
// import Template8 from "./templates/template8";
// import Template9 from "./templates/template9";
import { Redirect } from "react-router-dom";

import Url from "components/Url";

const TEMPLATES_MAP = {
  [Template1.displayName]: Template1Img,
  // [Template2.displayName]: Template2Img,
  // [Template3.displayName]: Template3Img,
  // [Template4.displayName]: Template4Img,
  // [Template5.displayName]: Template5Img,
  // [Template6.displayName]: Template6Img,
  // [Template7.displayName]: Template7Img,
  // [Template8.displayName]: Template8Img,
  // [Template9.displayName]: Template9Img,
};

const url1 = Url();
const url = url1["url"];
const s3 = url1["s3"];
const templateIMGURL = s3 + "ResumeBuilderTemplates/";

const ResumeList = ({ history }) => {
  const [resumes, setResumes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resumeSubscriptions, setResumeSubscriptions] = useState([]);
  const token = localStorage.getItem("stutoken");
  const email = localStorage.getItem("email");
const studentId = localStorage.getItem("studentId");

  const fetchResumes = useCallback(async () => {
    try {
      const { data } = await Api.get(`${ENDPOINTS.GET_ALL_RESUMES}/${studentId}`);
      setResumes(data);
    } finally {
      setLoading(false);
    }
  }, [setResumes]);

  const createResume = useCallback(async () => {
    // const resume_id = Date.now();
    // try {
    //   setLoading(true);
    //   await Api.post(ENDPOINTS.CREATE_RESUME, {
    //     email,
    //     resume_id,
    //     resume_template: Template1.displayName
    //   });
    //   history.push(`/resumes/${resume_id}/edit`);
    // } finally {
    //   setLoading(false);
    // }
    history.push(`/ResumeBuilder#products`);
  }, []);

  const deleteResume = useCallback(async (id) => {
    
    if (id && window.confirm("Are you sure? Want to delete the resume?")) {
      try {
        setLoading(true);
        await Api.delete(`${ENDPOINTS.DELETE_RESUME}/${id}`);
        await fetchResumes();
      } finally {
        setLoading(false);
      }
    }

  }, []);

  const copyResume = useCallback(async (id) => {
    console.log("copying resume " + id);

    if (id && window.confirm("Are you sure? Want to copy to a new resume?")) {
      try {
        setLoading(true);
        const { data } = await Api.post(`${ENDPOINTS.COPY_RESUME}/${id}`);

        if(data.new_resume_id > 0){
          console.log("Redirecting... after copy");
          localStorage.setItem('showResumeTemplates','1');
          history.push(`/resumes/${data.new_resume_id}/edit`);
        }
      } finally {
        setLoading(false);
      }
    }
    
  }, []);

  useEffect(() => {
    if (!email) {
      history.replace("/StudentLink");
    }
    fetchResumes();

    const resumeSubscriptionAPI = url + "getResumeSubscriptions/" + studentId;
    var status = 0;
    fetch(resumeSubscriptionAPI, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((apidata1) => {
        status = apidata1.status;
        return apidata1.json();
      })
      .then((actualdata1) => {
        console.log(actualdata1);
        if(status == 403){ 
          setResumeSubscriptions([]);
          toast.warning(actualdata1.message)
        }
        else {
          setResumeSubscriptions(actualdata1.resumeSubscription);
        }
      });

  }, [fetchResumes]);

  return (
    <>

          <Container>
            <Row className="my-4">
              <Col>
                <h2>
                  <strong>Resumes</strong> {loading && <CircularProgress />}
                </h2>
              </Col>
              {/* <Col>
              <Button
                  variant="primary"
                  onClick={() => {
                    if (loading) return;
                    createResume();
                  }}
                >
                  New Resume
                </Button>              
              </Col> */}

            </Row>
            <Row className="mb-4" >
            {resumeSubscriptions.length > 0 ? (
              <Col>
                <Badge bg="secondary">
                  {`You have active Resume Builder subscription till ${resumeSubscriptions[0].end_date}`}
                  </Badge>
              </Col>              )
              :
              (
                <Col>
                <p>
                You don't have any active subscriptions.{' '} <Link to="/ResumeBuilder/Pricing">Click Here</Link> to get one.
                  </p>
              </Col>                             
              )
              }              
            </Row>
            <Row>
              {resumes.map((resume, i) => (
                <Col
                  key={resume.id}
                  xs={6}
                  md={4}
                  lg={3}
                  style={{ margin: "0 0 30px" }}
                >
                  <Card>
                    <CardHeader
                      action={
                        <>
                        <Button
                          variant="link"
                          className="p-1"
                          onClick={() => {
                            history.push(`/resumes/${resume.resume_id}/edit`);
                          }}
                        >
                          <FiEdit size={20} color="#f44336" />
                        </Button>                        
                        <Button
                          variant="link"
                          className="p-1"
                          onClick={() => copyResume(resume.resume_id)}
                        >
                          <FiCopy size={20} color="#f44336" />
                        </Button>
                        <Button
                          variant="link"
                          className="p-1"
                          onClick={() => deleteResume(resume.id)}
                        >
                          <FiTrash2 size={20} color="#f44336" />
                        </Button>
                        </>
                      }
                      title={`Resume ${i + 1}`}
                      subheader={moment(resume.updated_at).format(
                        "MMMM D, YYYY"
                      )}
                    />

                    <CardActionArea
                      onClick={() => {
                        history.push(`/resumes/${resume.resume_id}/edit`);
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="140"
                        image={templateIMGURL + resume.template_preview_image
                        }
                        alt={resume.resume_id}
                        style={{ objectPosition: "top" }}
                      />
                      {/* {console.log(resume.resume_template)} */}
                    </CardActionArea>
                  </Card>
                </Col>
              ))}
              <Col xs={6} md={4} lg={3} style={{ margin: 0 }}>
                <Card>
                  <CardActionArea
                    onClick={() => {
                      if (loading) return;
                      createResume();
                    }}
                    style={{
                      height: 227,
                      color: "#007bff",
                      textAlign: "center",
                    }}
                  >
                    <CardContent>
                      <FiPlus size={40} />
                      <Typography variant="h6" component="div">
                        New Resume
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Col>
            </Row>
          </Container>

    </>
  );
};

export default memo(ResumeList);
