
const teamData = [
  {
    id: 1,
    name: "BHAVNA PRIYADARSHINI",
    designation: "CEO",
    image: "1.png",
    description:
      "As the Chief Executive Officer of company, Bhavna has enjoyed a spectacular run in the IT industry. She uses all the experience that she has accumulated from running various IT businesses to understand the needs of our clients. Before joining ISAN Data Systems, Bhavna worked in various roles in the IT business and non-profit sector. She has done master’s in Art from Vinoba Bhave University. Bhavna has immense experience of working with IT industry and Education Sector. A young and creative mind with astonishing ideas and solutions to every problem, adding great values to the position. Bhavna being a responsible leader, helpful mentor for the team member and a supportive leader of the company is the most amazing quality about her. With her on the position means taking the company to the new heights of success and bringing glories for the company.",
  },
  {
    id: 2,
    name: "EKSHA ARORA",
    designation: "Director",
    image: "2.png",
    description:
      "Eksha, an IT professional with more than 10 years of rich experience in the areas of implementation, transition, and migration of Infrastructure services. An Army School alumni who completed her higher studies from the University of Jammu in Electronics, and Communications engineering. She started her career as a Storage Administrator in IBM, worked there for around 3.5 years and then moved to HCL Technologies as a Technical Consultant in the Infrastructure Services Division. She moved to Australia in 2018 after getting her permanent residency and joined “NBN Australia” as Infrastructure Specialist. With immense knowledge and experience of working in Public Cloud and Hybrid Cloud Model, she joined ISAN DATA SYSTEMS in 2020 as a director.",
  },
  {
    id: 3,
    name: "SUNIL KUMAR SINGH",
    designation: "Director",
    image: "3.png",
    description:
      "As the operational head of the company, Sunil has enjoyed a spectacular run in the IT industry. He uses all the experience that he has accumulated from running various IT businesses to understand the needs of our clients fully. Thus, Sunil helps us deliver solutions that are entirely in line with our customers’ requirements. He is undoubtedly the reason for the company’s strong customer-oriented branding.",
  },
  {
    id: 4,
    name: "SEEMA SHARMA",
    designation: "Director",
    image: "4.png",
    description:
      "Under the aegis of Seema, She leverages her extensive IT experience to guide our young group of experts and build strategies that deliver predictable and sustained growth. She excels in bringing the client's visions from concept to reality. Her unique combination of database experience, network & software support and map production translates remarkably well to our technical and visual aspects of IT infrastructure.",
  },
  {
    id: 5,
    name: "KANTI CHAUDHARY",
    designation: "Human Resource Head",
    image: "5.png",
    description:
      "Starting her career from ISAN Data Systems, Kanti has consistently risen through the ranks. She is currently working in the company as the human resource and employee engagement leader. As a young Turk responsible for bringing in the most exceptional talent in the company, Kanti has been doing an excellent job at making this organization a much better place to work at.",
  },
  {
    id: 6,
    name: "UMESH KSHIRSAGAR",
    designation: "System Architect",
    image: "UmeshK_Team.png",
    description:
      "Meet Umesh, our seasoned System Architect at Internshipgate. With over two decades of expertise in the technology industry, Umesh has honed his skills through impactful contributions to esteemed global organizations such as TCS, IBM, Amdocs, and more. His role as a solution architect revolves around the intricate realm of enterprise-level database design, data integration, and ensuring seamless data flow across diverse systems. Umesh brings a wealth of experience and a keen eye for detail, making him an invaluable asset to our team as we navigate complex technological landscapes and drive innovation forward.",
  },
];

export default teamData;
