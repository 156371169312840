import React, { useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import moment from "moment";
import { FiChevronDown, FiPlus, FiTrash2 } from "react-icons/fi";
import { InputField, Label, Button, RTE } from "../../foundation";
import { Controller } from "react-hook-form";
import Switch from "@material-ui/core/Switch";
import Api, { ENDPOINTS } from "../../api";
import CircularProgress from "@material-ui/core/CircularProgress";

const Education = ({ watch, register, fields, append, remove, control }) => {
	const [deleting, setDeleting] = useState(null);
	const education = watch("education");
	return (
		<div className="resume-builder__section">
			<h2>Education</h2>
			<Label as="p">
				A varied education on your resume sums up the value that your learnings
				and background will bring to job.
			</Label>
			{fields.map((field, index) => {
				const { institute_name, degree, start_date, end_date } =
					education?.[index] || {};
				const displayDate = [];
				if (start_date) displayDate.push(moment(start_date).format("MMM YYYY"));
				if (end_date) displayDate.push(moment(end_date).format("MMM YYYY"));
				return (
					<div key={field.key} className="resume-builder__section__card p-0">
						<input
							type="hidden"
							id={`education[${index}].id`}
							name={`education[${index}].id`}
							defaultValue={field.id}
							{...register(`education[${index}].id`)}
						/>
						<Accordion defaultActiveKey={`${field.id ? "" : field.key}`}>
							<Accordion.Item
								as={"div"}
								variant="link"
								eventKey={`${field.key}`}
								className="resume-builder__section__card--title"
							>
								<Accordion.Header className="w-100">
									<div className="m-0">
										<p className="m-0">
											{institute_name && degree
												? `${degree} at ${institute_name}`
												: degree || institute_name || "(Not Specified)"}
										</p>
										{displayDate.length > 0 && (
											<Label className="mb-0">{displayDate.join(" - ")}</Label>
										)}
									</div>{" "}
									{/* <span className="d-flex m-0">
										<FiChevronDown size={20} />
									</span> */}
								</Accordion.Header >
								<Accordion.Body eventKey={`${field.key}`}>
									<>
										<Row>
											<Col>
												<Controller
													control={control}
													name={`education[${index}].end_date`}
													render={({ 
														field: {onChange, value, name, ref }
													}) => (
														<>
															<Switch
																checked={value === null}
																onChange={(e) =>
																	onChange(e.target.checked ? null : "")
																}
																color="primary"
															/>{" "}
															<Label as="span">Currently study here</Label>
														</>
													)}
												/>
											</Col>
										</Row>
										<Row className="mb-3">
											<Col>
												<Label>Institute</Label>
												<Controller
													control={control}
													name={`education[${index}].institute_name`}
													render={({
														field: { onChange, onBlur, value, name, ref },
														fieldState: { invalid, isTouched, isDirty, error },
														formState,
													}) => (
														<InputField
															stretch
															placeholder="Enter Institute Name"
															onBlur={onBlur} // notify when input is touched
															onChange={onChange} // send value to hook form
															checked={value}
															inputRef={ref}
														/>
													)}
												/>
											</Col>
											<Col>
												<Label>Degree</Label>
												<Controller
													control={control}
													name={`education[${index}].degree`}
													render={({
														field: { onChange, onBlur, value, name, ref },
														fieldState: { invalid, isTouched, isDirty, error },
														formState,
													}) => (
														<InputField
															stretch
															placeholder="Enter Degree"
															onBlur={onBlur} // notify when input is touched
															onChange={onChange} // send value to hook form
															checked={value}
															inputRef={ref}
														/>
													)}
												/>
											</Col>
										</Row>
										<Row className="mb-3">
											<Col>
												<Row>
													<Col xs={6} className="pr-1">
														<Label>Start Date</Label>
														<Controller
															control={control}
															name={`education[${index}].start_date`}
															render={({
																field: { onChange, onBlur, value, name, ref },
																fieldState: {
																	invalid,
																	isTouched,
																	isDirty,
																	error,
																},
																formState,
															}) => (
																<InputField
																	type="date"
																	stretch
																	onBlur={onBlur} // notify when input is touched
																	onChange={onChange} // send value to hook form
																	checked={value}
																	inputRef={ref}
																/>
															)}
														/>
													</Col>
													<Col xs={6} className="pl-1">
														<Label>End Date</Label>
														<Controller
															control={control}
															name={`education[${index}].end_date`}
															render={({
																field: { onChange, onBlur, value, name, ref },
																fieldState: {
																	invalid,
																	isTouched,
																	isDirty,
																	error,
																},
																formState,
															}) =>
																end_date === null ? (
																	<InputField
																		stretch
																		value="Present"
																		readOnly
																	/>
																) : (
																	<InputField
																		type="date"
																		min={start_date}
																		stretch
																		onBlur={onBlur} // notify when input is touched
																		onChange={onChange} // send value to hook form
																		checked={value}
																		inputRef={ref}
																	/>
																)
															}
														/>
													</Col>
												</Row>
											</Col>
											<Col>
												<Label>City</Label>
												<Controller
													control={control}
													name={`education[${index}].city`}
													render={({
														field: { onChange, onBlur, value, name, ref },
														fieldState: { invalid, isTouched, isDirty, error },
														formState,
													}) => (
														<InputField
															stretch
															placeholder="Enter city"
															onBlur={onBlur} // notify when input is touched
															onChange={onChange} // send value to hook form
															checked={value}
															inputRef={ref}
														/>
													)}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<Label>Description</Label>
												<Controller
													control={control}
													name={`education[${index}].Description`}
													render={({
														field: { onChange, onBlur, value, name, ref },
														fieldState: { invalid, isTouched, isDirty, error },
														formState,
													}) => (
														<RTE
															value={value}
															onBlur={onBlur} // notify when input is touched
															onChange={onChange} // send value to hook form
															checked={value}
															inputRef={ref}
														/>
													)}
												/>
											</Col>
										</Row>
									</>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
						<Button
							variant="link"
							disabled={deleting === index}
							className="resume-builder__section__card--delete"
							onClick={async () => {
								try {
									setDeleting(index);
									if (field.id) {
										await Api.delete(
											`${ENDPOINTS.DELETE_EDU_DETAIL}/${field.id}`
										);
									}
									remove(index);
								} finally {
									setDeleting(null);
								}
							}}
						>
							{deleting === index ? (
								<CircularProgress size={20} color="primary" />
							) : (
								<FiTrash2 size={20} />
							)}
						</Button>
					</div>
				);
			})}
			<Row className="mb-3">
				<Col>
					<Button className="text-info fs-4" variant="link" type="button" onClick={() => append({})}>
						<FiPlus />
						{fields.length < 1 ? " Add education" : " Add one more education"}
					</Button>
				</Col>
			</Row>
		</div>
	);
};

export default Education;
