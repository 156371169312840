import React, { useState, useEffect } from "react";

import "./ScrollButton.css";
import { Button } from "react-bootstrap";

const ScrollButton = React.forwardRef((props, ref) => {
    const { children, ...otherProps } = props;
  const [scrolling, setScrolling] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Button ref={ref} className={`btn-sm ${scrolling ? "circle me-4 mb-4" : "circle me-4 mb-4 "}`}  {...otherProps}>
        { children } 
    </Button>
  );
});

export default ScrollButton;
