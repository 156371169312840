import { v4 as uuid } from "uuid";

const NavbarDefault = [
  {
    id: uuid(),
    menuitem: "Internships",
    link: "/Intlisting",
  },
  {
    id: uuid(),
    menuitem: "Mentorship",
    link: "/mentorship",
  },
  // {
  //   id: uuid(),
  //   menuitem: "Online Training",
  //   link: "/OnlineTraining",
  // },
  {
    id: uuid(),
    menuitem: "Resume Builder",
    // link: 'https://resume.internshipgate.com/ResumeBuilder'
    link: "/ResumeBuilder",
  },
  {
    id: uuid(),
    menuitem: "Virtual Internship",
    link: "/virtual-internship",
  },

  {
    id: uuid(),
    menuitem: "Study Abroad",
    link: "/study-abroad",
  },
  {
    id: uuid(),
    menuitem: "Dashboard",
    link: "/student-dashboard",
    studentLogin: true,
  },

  /*
	,
	{
		id: uuid(),
		menuitem: 'CMS',
		link: '/contentadmin',
	}
	*/
];

export default NavbarDefault;
