// import node module libraries
import { Link } from 'react-router-dom';
import { Col, Row, Container, Image, Modal,Form,Spinner,Button } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import './StudyAbroad.css';
// import custom components
import SectionHeadingCenter from 'components/marketing/common/section-headings/SectionHeadingCenter';
import USA from "assets/images/studyabroad/USA.webp";
import UK from "assets/images/studyabroad/UK.webp";
import Germany from "assets/images/studyabroad/Germany.webp";
import Australia from "assets/images/studyabroad/Australia.webp";
import Russia from "assets/images/studyabroad/Russia.webp";
import China from "assets/images/studyabroad/China.webp";
import Singapore from "assets/images/studyabroad/Singapore.webp";
import Canada from "assets/images/studyabroad/Canada.webp";
// import sub components
import StudyDestinationCard from './StudyDestinationCard';
import Url from 'components/Url';
// import data files
import { InstructorsList } from 'data/marketing/LandingCoursesData';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

const TopStudyDestinations = (props) => {
	const title = 'Top Study Destinations';
	const subtitle = 'World-class Universities';
	const description = `Embark on a journey of knowledge and innovation with our elite selection of top-class universities. Click on the countries below to know more.`;
	const { showLeadCapture,setShowLeadCapture,loading , setLoading } = props;
	const StudyDestinations = [
		{
			id: 1,
			image: USA,
			name: 'USA',
		},
		{
			id: 2,
			image: UK,
			name: 'UK',
		},
		{
			id: 3,
			image: Germany,
			name: 'Germany',
		},
		{
			id: 4,
			image: Australia,
			name: 'Australia',
		},				
		{
			id: 5,
			image: Canada,
			name: 'Canada',
		},
		{
			id: 6,
			image: China,
			name: 'China',
		},
		{
			id: 7,
			image: Singapore,
			name: 'Singapore',
		},
		{
			id: 8,
			image: Russia,
			name: 'Russia',
		},			
	];

	const isLaptop = useMediaQuery({ minWidth: 1024, maxWidth: 1445 });

    const url1 = Url()
    const url = url1["url"]

	return (
		<div className="py-4 py-lg-8 bg-light-gradient-top bg-white" id="studydestinations_section">
			<Container>
				<SectionHeadingCenter
					title={title}
					description={description}
					subtitle={subtitle}
				/>
				<Row className="row row-cols-1 row-cols-lg-5 mt-3 justify-content-center">
					{StudyDestinations.map((item, index) => (
						<div className="img-container" key={index} style={{cursor:"pointer"}} onClick={ () => {setShowLeadCapture(true)}}>

							<Image className="img-fluid image rounded-top-md" src={item.image} alt="Study in USA" />
							<div className="img-overlay">{item.name}</div>

					</div>
					))}
				</Row>

			</Container>
		</div>
	);
};

export default TopStudyDestinations;
