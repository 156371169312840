// import node module libraries
import React, {
	Fragment,
	useEffect,
	useState,
	useRef,
  } from "react";
import { Card, Form, Button, Image, Row, Spinner } from 'react-bootstrap';
import Url from 'components/Url';


const CoursesMedia = (props) => {
	const { next, previous, handleImage, handleSubmit, setIsSaved, courseData, isUploading, setIsUploading } = props;
	const url1 = Url();
	const url = url1["url"];
	const s3 = url1["s3"];
	return (
		<Form>
			{/* Card */}
			<Card className="mb-3  border-0">
				<Card.Header className="border-bottom px-4 py-3">
					<h4 className="mb-0">Courses Media</h4>
				</Card.Header>
				{/* Card body */}
				<Card.Body>
					{/* Course cover image */}
					<Form.Label>Course cover image</Form.Label>
					<Form.Group className="mb-1 input-group">
					{courseData?.preview_image ? (
						<Row style={{maxWidth: "500px"}}>
                            <Image
                              src={
                                s3 +
                                "courseImages/" +
                                courseData.preview_image
                              }
                            />
						</Row>
                        ) : (
                          <Row>
                            <div>Image not uploaded</div>
                          </Row>
                        )}					
					<Row>
						<Form.Control
							id="inputfavicon"
							type="file"
							className="form-control mt-2"
							onChange={event => {
								setIsUploading(true)
								handleImage(event)
							}}
						/>
						{/* <Form.Label
							htmlFor="inputfavicon"
							className="input-group-text mb-0"
						>
							Upload
						</Form.Label> */}
						<Form.Text className="text-muted">
							Upload your course image here. It must meet our course image
							quality standards to be accepted. Important guidelines: 750x440
							pixels; .jpg, .jpeg,. gif, or .png. no text on the image.
						</Form.Text>
					</Row>
						</Form.Group>
					{/* Video URL  */}
					<Form.Group className="mb-3 mt-3">
						<Form.Control type="text" placeholder="Video URL" id="VideoURL" />
						<Form.Text className="text-muted">
							Enter a valid video URL. Students who watch a well-made promo
							video are 5X more likely to enroll in your course.
						</Form.Text>
					</Form.Group>
				</Card.Body>
			</Card>

			{/* Button */}
			<div className="d-flex justify-content-between">
				<Button disabled={isUploading ? true : false} variant="success" onClick={handleSubmit}>
					{isUploading
					?
					<Spinner animation="border" variant="light" size="sm" />
					:
					"Save"
				}
				</Button>
				<div>
				<Button className="mx-2" disabled={isUploading ? true : false} variant="secondary" onClick={() => {
          setIsSaved(false)
          previous()
          }}>
					Previous
				</Button>
				<Button disabled={isUploading ? true : false} variant="primary" onClick={next}>
					Next
				</Button>
				</div>
			</div>
		</Form>
	);
};
export default CoursesMedia;
