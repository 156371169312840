// import node module libraries
import { Link, useHistory } from 'react-router-dom';
import {
    Image,
    Container,
    Row,
    Col,
    Card,
    Form,
    Button
} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
// import media files
import GoogleAuthenticator from './GoogleAuthenticator';
import { GoogleOAuthProvider } from '@react-oauth/google';
import CTAInstructor from 'assets/images/png/cta-instructor-1.png';
import pic2 from 'assets/images/mentorLanding/pic2.jpg'

import Url from 'components/Url';
import { useState } from 'react';

const MentorRegistration = () => {
    const url1 = Url();
    const url = url1.url
    const s3 = url1["s3"];
    const [message,setMessage] = useState();
    const [showError, setShowError] = useState(false);
    const history = useHistory()

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowError(false);
        let stat 
        const fullname = e.target.name.value;
        const mobileno = e.target.number.value;
        const email = e.target.email.value;
        const password = e.target.password.value;
        const coupen_code = "NA";
    
        const api = url + "createMentorReg";
        fetch(api, {
          method: "POST",
          body: JSON.stringify({
            fullname,
            email,
            mobileno,
            password,
            coupen_code,
          }),
          headers: { "Content-Type": "application/json" },
        })
          .then((apidata) => {
            stat = apidata.status
            return apidata.json();
          })
          .then((actualdata) => {
            if(stat === 200 || stat === 201){
                toast.success("Registration Successful,Please check the email and verify your account.");
                //Umesh - 20220607 - start
                //call zoho CRM API to add mentor lead
                // const zohoLeadapi = url + "addMentorLead";
                // fetch(zohoLeadapi, {
                //     method: "POST",
                //     body: JSON.stringify({ fullname, email, mobileno }),
                //     headers: { "Content-Type": "application/json" },
                // }).then((apidata) => {
                //     console.log("Registered");
                // });
                //Umesh - 20220607 - end                
                history.push('/mentorship');
            }
            console.log(actualdata);
            if(stat == 501){
                setMessage(actualdata.message);
                setShowError(true);
                }
              // toast.error('Email already exist', { position: toast.POSITION.TOP_CENTER })
              return;
            
        });

      };

    return (
        <>
        <ToastContainer />
        <section className="py-8">
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="text-center">
                            <h1 className="display-5 fw-bold mb-2">Sign-up as a mentor and manage your mentorships <span className="text-secondary">free</span></h1>
                            <p className="lead fw-semibold">2000+ mentors are already mentoring on Internshipgate</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-lg-8 mt-4 align-items-center">
                    <Col lg={{ order: 1, span: 6 }} xs={{ order: 2, span: 12 }} className="text-center text-lg-start">
                        <div>
                            {/* <Image src={s3 + "survey-img.svg"} alt="" className="w-100" /> */}
                            {/* <img src={Dollor} alt=""/> */}
                            {/* <img src={CTAInstructor} alt="" className="img-fluid mt-n13"/> */}
                            <h3 className="w-100" style={{textAlign:"center"}}>Mentorship transforms dreams into reality.</h3>
                            <img src={pic2} alt=""  className="w-100"></img>
                            
                        </div>
                    </Col>
                    <Col xl={6} xs={12} lg={{ order: 2, span: 6 }}>
                        <div className="mx-xl-8 mb-8 mb-lg-0">
                            <Card className="shadow border-0">
                                <Card.Body className="p-2">
                                    <h2 className="mb-4">Create an account to continue</h2>
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group className="mb-3" controlId="mentor-full-name">
                                            <Form.Control type="text" name="name" placeholder="Full Name" required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="mentor-email">
                                            <Form.Control type="email" name="email" placeholder="Email Address" required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="mentor-mobile">
                                            <Form.Control type="number" name="number" placeholder="Mobile Number" required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="mentor-password">
                                            <Form.Control type="password" name="password" placeholder="Password" required />
                                        </Form.Group>
                                        {/* <Form.Check className="mb-4"
                                            type="checkbox"
                                            id="refferralCode"
                                            label=" I have a referral code"
                                        /> */}
                                        <div className="d-grid">
                                            <Button variant="primary" type="submit">Register</Button>
                                        </div>
                                        <div className="d-grid text-center mt-3">
                                            <p>Already Registered? <Link to="/mentor/login">Login as a Mentor</Link></p>
                                        </div>                                        
                                    </Form>
                                    {/* <div className="mt-3">
                                        <span>Already registered? <Link to="#">Login</Link></span>
                                    </div> */}
                                {showError && 
                                <div>
                                    <h4>Errors:</h4>
                                    {Object.keys(message).map((key) => (
                                        <div key={key}>
                                        <ul className="text-danger">
                                            {message[key].map((error, index) => (
                                            <li key={index}>{error}</li>
                                            ))}
                                        </ul>
                                        </div>
                                    ))}
                                    </div>                                
                                }
                                </Card.Body >
                                {/* <Card.Footer>
                                    <Col className="h5 text-center">Or One Click Sign-up with google id</Col>
                                    <Col xs={{span:8, offset:1}} sm={{span:10, offset:3}}>
                                        <GoogleOAuthProvider clientId="295493707204-t8uvccmg3mf93tvi75eh1s26kuso18je.apps.googleusercontent.com">
                                            <GoogleAuthenticator />
                                        </GoogleOAuthProvider>                                       
                                    </Col>
                                </Card.Footer> */}
                            </Card>

                          
                        </div>
                    </Col>
                </Row>

            </Container>
        </section>
        </>
    )
}

export default MentorRegistration