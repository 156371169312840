export const FAQsData = [
	{
		id: 1,
		question: 'How does your resume builder work?',
		answer:
			'Our resume builder is designed to make the process of creating a professional resume easy and efficient. Simply sign up, choose a template, input your information, and customize as needed. The platform guides you through each step, ensuring your resume looks polished and tailored to your needs.'
	},
	{
		id: 2,
		question: 'Is my information safe on your platform?',
		answer:
			'Yes, we take the security and privacy of your information seriously. Our website uses encryption protocols to safeguard your data. We do not share your personal details with third parties without your consent. For more details, please review our privacy policy.'
	},
	{
		id: 3,
		question: 'Can I edit my resume after I\'ve created it?',
		answer:
			'Absolutely! You can log in at any time to update and edit your resume. Whether you\'ve gained new skills or want to tailor your resume for a specific job, our platform allows you to make changes easily.'
	},
	{
		id: 4,
		question: 'Are there limitations on the number of resumes I can create?',
		answer:
			'No, there are no limitations. You can create and download as many resumes as you need. Our goal is to provide you with the flexibility to customize your resumes for different job opportunities.'
	},
	{
		id: 5,
		question: 'Can I download my resume in different formats?',
		answer:
			'Yes, our resume builder supports multiple formats, including PDF, Word, and plain text. Choose the format that best suits your needs or the requirements of the job application.'
	},
	{
		id: 6,
		question: 'Do you offer templates for specific industries?',
		answer:
			'Yes, we offer a variety of templates designed for different industries. Whether you\'re in finance, IT, healthcare, or any other field, you\'ll find templates that cater to the specific requirements of your industry.'
	},
	{
		id: 7,
		question: 'Is there a cost associated with using your resume builder?',
		answer:
			'Basic access to our resume builder is free. However, we also offer premium features, such as additional template options and advanced customization, through a subscription plan. You can explore our pricing page for more details.'
	},
	{
		id: 8,
		question: 'Can I get assistance if I encounter issues while using the platform?',
		answer:
			'Certainly! Our support team is ready to assist you. You can reach out through our contact page or refer to our comprehensive help center, which includes step-by-step guides and troubleshooting tips.'
	},
	{
		id: 9,
		question: 'How can I share my resume with potential employers?',
		answer:
			'You can download your resume in the desired format and attach it to job applications or email it directly to employers. Additionally, our platform provides a shareable link to your online resume, allowing you to showcase your professional profile easily.'
	}
];
export default FAQsData;
