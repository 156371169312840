import React, { useEffect, useRef, useState, createRef } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  Svg,
  Path,
  Circle,
  Polyline,
} from "@react-pdf/renderer";
import { Html } from "react-pdf-html";
import {
  useA4Scale,
  TemplatePage,
  renderHTMLContent,
  getDisplayDate,
} from "../../utils";
import { Row, Col } from "react-bootstrap";
import DummyProfilePic from "../../../../../assets/images/background/profilepic.png";
import { FiPhone } from "react-icons/fi";
import { isEmpty } from "../../../foundation";


Font.registerHyphenationCallback((word) => {
  // Return entire word as unique part
  return [word];
});

const styles = StyleSheet.create({
  page: {
    display: "flex",
    fontFamily: "Roboto",
    paddingVertical: "20px",
  },
  wrapper: {
    // display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  left: {
    // backgroundColor: "green",
    width: "340px",
  },
  right: {
    padding: "0px 20px 0px 20px",
    width: "580px",
    paddingLeft: "40px",
    paddingTop: "10px",
    color: "#000000",
    fontSize: "10px",
  },
  profileImageWrapper: {
    display: "flex",
    marginLeft: "15px",
    position: "absolute",
  },
  nameWrapper: {
    width: "180px",
    display: "flex",
    paddingLeft: "20px",
    paddingBottom: "20px",
  },
  profileImage: {
    width: "180px",
    height: "270px",
    // borderRadius: "50%",
    border: "2px solid #3644a1",
    margin: " 0px 20px",
    marginBottom: "4px",
   
  },
});

const ContactInfo = ({ data: { Phone, Country, display_email, City,Address,Postal_Code } }) => (
  <View
    style={{
      paddingLeft: "36px",
      paddingTop: "5px",
    }}
  >
    <Text
      style={{
        color: "#000000",
        fontWeight: 700,
        fontSize: "14px",
        paddingTop: "2px",
        marginTop: "1px",
        marginBottom: "5px",
        paddingBottom: "2px",
        borderTop: "2px solid #000000",
        borderBottom: "2px solid #000000",
      }}
    >
      CONTACT
    </Text>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "8px",
        paddingLeft: "2px",
      }}
    >
      <View style={{ paddingBottom: "2px," }}>
        <Svg
          stroke="#10edd2"
          fill="none"
          stroke-width="2"
          viewBox="0 0 24 24"
          stroke-linecap="round"
          stroke-linejoin="round"
          height="16px"
          width="16px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></Path>
        </Svg>
      </View>
      <Text
        style={{
          color: "#000000",
          fontSize: "12px",
          paddingLeft: "8px",
        }}
      >
        {Phone}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "8px",
        paddingLeft: "3px",
      }}
    >
      <View>
        <Svg
          stroke="#10edd2"
          fill="none"
          stroke-width="0"
          viewBox="0 0 14 16"
          height="20px"
          width="16px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            fill-rule="evenodd"
            d="M0 4v8c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H1c-.55 0-1 .45-1 1zm13 0L7 9 1 4h12zM1 5.5l4 3-4 3v-6zM2 12l3.5-3L7 10.5 8.5 9l3.5 3H2zm11-.5l-4-3 4-3v6z"
          ></Path>
        </Svg>
      </View>
      <Text
        style={{
          color: "#000000",
          fontSize: "12px",
          paddingLeft: "8px",
        }}
      >
        {display_email}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "8px",
        paddingLeft: "2px",
      }}
    >
      <View>
        <Svg
          stroke="#10edd2"
          fill="none"
          stroke-width="0"
          viewBox="0 0 24 24"
          height="16px"
          width="16px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
          ></Path>
          <Path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
          ></Path>
        </Svg>
      </View>
      <Text
        style={{
          color: "#000000",
          fontSize: "12px",
          paddingLeft: "8px",
        }}
      >
        {Address} | {City} | {Country}
      | {Postal_Code}
      </Text>
    </View>
  </View>
);


const Skills = ({ data: { skills } }) => {
  if (!skills || !skills.length) {
    return <View />;
  }
  return (
    <View
      style={{
        paddingLeft: "36px",
        marginTop: "12px",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontWeight: "bold",
          fontSize: "14px",
          marginBottom: "10px",
          letterSpacing: "1.95px",
          fontstretch: "normal",
        }}
      >
        SKILLS
      </Text>
      {skills.map((s) => (
        <View
          style={{
            display: "flex",
            //flexDirection: "row",
            justifyContent: "space-between",
          }}
          wrap={false}
        >
          <Text
            style={{
              color: "#000000",
              fontSize: "15px",
              paddingBottom: "5px",
              fontWeight: "bold",
            }}
          >
            {s.skill_name}
          </Text>
          <View
            style={{
              
              backgroundColor: "grey",
              width: "189px",
              height: "4px",
              marginBottom: "5px",
              borderRadius: "50px",
              // marginRight: "30%",
              marginTop: "5px",
            }}
          >
            <View
              style={{
                backgroundColor: "#000000",
                borderRadius: "50px",
                height: "4px",
                width: `${s.level * 63}px`,
                // color: "white",
              }}
            ></View>
          </View>
        </View>
      ))}
    </View>
  );
};

const Hobbies = ({ data: { hobbies } }) => {
  if (!hobbies || !hobbies.length) {
    return <View />;
  }
  return (
    <View
      style={{
        width: "170px",
        marginLeft: "15px",
        paddingLeft: "18px",
        marginRight: "15px",
        marginTop: "12px",
        marginBottom: "20px",
        paddungBottom: "40px",
        paddingTop: "12px",
        // paddingBottom: "15px",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontWeight: 700,
          fontSize: "14px",
          marginBottom: "10px",
          borderTop: "2px solid #000000",
          borderBottom: "2px solid #000000",
          paddingBottom: "2px",
          paddingTop: "2px",
        }}
      >
        HOBBIES
      </Text>

      {hobbies.map((s) => (
        <Text
          style={{
            color: "#000000",
            fontSize: "12px",
            // paddingBottom: "15px",
            paddingLeft: "8px",
            alignItems: "center",
          }}
        >
          {s.hobbies}
        </Text>
      ))}
    </View>
  );
};

const Languages = ({ data: { languages } }) => {
  if (!languages || !languages.length) {
    return <View />;
  }
  return (
    <View style={{ paddingLeft: "36px", marginTop: "12px" }}>
      <Text
        style={{
          color: "#000000",
          fontWeight: 600,
          fontSize: "14px",
          paddingTop: "2px",
          marginBottom: "10px",
          borderTop: "2px solid #000000",
          borderBottom: "2px solid #000000",
        }}
      >
        LANGUAGE
      </Text>
      {languages.map((s) => (
				<Text
					style={{
            
            color: "#000000",
            fontWeight: 500,
            fontSize: "12px",
            paddingBottom: "8px",
            paddingLeft: "8px",
            
					}}
				>
					{s.language_name}
				</Text>
			))}
    </View>
  );
};

const References = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const { title, subTitle, phoneNo, emailId } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
          paddingLeft: "36px",
          // marginTop: "15px",
        }}
      >
        <View>
          <Text
            style={{
              fontSize: "14px",
              fontWeight: 600,
              color: "#000000",
              paddingTop: "2px",
              paddingBottom: "2px",
              borderTop: "2px solid #000000",
              borderBottom: "2px solid #000000",
            }}
          >
            {heading}
          </Text>
        </View>
      </View>
      {items.map((item) => (
        <View style={{ marginTop: "10px", paddingLeft: "45px" }}>
          <Text style={{ fontSize: "12px", fontWeight: 500, color: "#000000" }}>
            {item[title]}
          </Text>
          <Text
            style={{
              fontSize: "12px",
              fontWeight: 500,
              color: "#000000",
              marginTop: "3px",
            }}
          >
            {item[subTitle]}
          </Text>
          <Text
            style={{
              fontSize: "12px",
              fontWeight: 500,
              color: "#000000",
              marginTop: "3px",
            }}
          >
            {item[phoneNo]}
          </Text>
          <Text
            style={{
              fontSize: "12px",
              fontWeight: 500,
              color: "#000000",
              marginTop: "3px",
            }}
          >
            {item[emailId]}
          </Text>
        </View>
      ))}
    </View>
  );
};

// const AdditionalInfo = ({
//   data: {
//     Address,
//     City,
//     Postal_Code,
//     Driving_License,
//     Nationality,
//     dob,
//     Place_of_Birth,
//   },
// }) => {
//   const details = [
//     {
//       label: "Address",
//       value: `${Address || ""} ${City || ""} ${Postal_Code || ""}`.trim(),
//     },
//     {
//       label: "Driving License",
//       value: Driving_License,
//     },
//     {
//       label: "Nationality",
//       value: Nationality,
//     },
//     {
//       label: "Date of Birth",
//       value: dob,
//     },
//     {
//       label: "Place of Birth",
//       value: Place_of_Birth,
//     },
//   ];
//   return details.map((info) =>
//     info.value ? (
//       <View style={{ paddingLeft: "42px", bottom: "6px" }}>
//         <Text
//           style={{
//             color: "#ffffff",
//             fontWeight: 700,
//             fontSize: "12px",
//             //paddingLeft:"50px",
//             marginBottom: "4px",
//           }}
//         >
//           {info.label}
//         </Text>
//         <Text
//           style={{
//             color: "#ffffff",
//             fontSize: "10px",
//             marginBottom: "12px",
//           }}
//         >
//           {info.value}
//         </Text>
//       </View>
//     ) : (
//       <View />
//     )
//   );
// };

const Courses = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const { title, subTitle, startDate, endDate, description } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View style={{ marginTop: "4px" }}>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
          marginLeft: "-42px ",
        }}
      >
        <View>
          <Text
            style={{
              fontSize: "14px",
              fontWeight: 600,
              color: "#000000",
              paddingLeft: "40px",
              marginTop: "15px",
            }}
          >
            {heading}
          </Text>
        </View>
      </View>

      {items.map((item) => (
        <View style={{ marginTop: "15px" }}>
          <Text
            style={{ fontSize: "12px", fontWeight: "500", color: "#000000" }}
          >
            {item[title]}
          </Text>
          <Text
            style={{ fontSize: "12px", color: "#000000", marginTop: "3px" }}
          >
            {item[subTitle]}
          </Text>
          <Text
            style={{
              fontSize: "10px",
              color: "#000000",
              marginTop: "3px",
              fontWeight: "400",
            }}
          >
            {getDisplayDate(item[startDate], item[endDate])}
          </Text>

          {item[description] && (
            <View style={{ marginTop: "5px" }}>
              {renderHTMLContent(item[description], {
                "*": { color: "#000000", fontSize: "12px",textAlign:"justify" },
              })}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

const RenderView = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const {
    title,
    subTitle,
    startDate,
    endDate,
    description,
    location = "city",
  } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View style={{ marginTop: "4px" }}>
      <View
        style={{
          display: "flex",
          paddingLeft: "40px",
          flexDirection: "row",
          alignItems: "baseline",
          marginLeft: "-42px ",
        }}
      >
        {/* {heading == "Work Experience" ? (
          <View
            style={{
              fontSize: "12px",
              fontWeight: "100",
              top: "8px",
              marginRight: "8px",
            }}
          >
            <Svg
              stroke="#53e4ed"
              fill="none"
              stroke-width="0"
              viewBox="0 0 24 24"
              height="32px"
              width="32px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Path d="M20,6h-3V4c0-1.103-0.897-2-2-2H9C7.897,2,7,2.897,7,4v2H4C2.897,6,2,6.897,2,8v11c0,1.103,0.897,2,2,2h16 c1.103,0,2-0.897,2-2V8C22,6.897,21.103,6,20,6z M15,4v2H9V4H15z M4,8h4h8h1h3v4h-3v-2h-2v2H9v-2H7v2H4V8z M4,19v-5h3v2h2v-2h6v2 h2v-2h3.001v5H4z"></Path>
            </Svg>
          </View>
        ) : null} */}

        {/* {heading == "EDUCATION" ? (
          <View
            style={{
              fontSize: "12px",
              fontWeight: "100",
              top: "8px",
              marginRight: "8px",
            }}
          >
            <Svg
              stroke="#53e4ed"
              fill="none"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="32px"
              width="32px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Path d="M149.688 85.625c-1.234.005-2.465.033-3.72.063-33.913.806-75.48 10.704-127.25 33.718V362.78c60.77-28.82 106.718-37.067 144.22-33.092 33.502 3.55 59.685 16.66 83.562 31.187v-242.97c-23.217-17.744-50.195-30.04-85.97-32-3.52-.192-7.142-.296-10.843-.28zm211.968 0c-3.7-.016-7.322.088-10.844.28-35.773 1.96-62.75 14.256-85.968 32v242.97c23.876-14.527 50.06-27.637 83.562-31.188 37.502-3.974 83.45 4.272 144.22 33.094V119.407c-51.77-23.014-93.337-32.912-127.25-33.72-1.255-.028-2.486-.056-3.72-.06zm5.72 261.78c-1.038-.002-2.074.017-3.095.033-4.808.075-9.43.37-13.905.843-33.932 3.597-59.603 17.976-85.53 34.44v.28c-6.554-1.99-13.02-2.37-19.408-.97-25.566-16.177-51.003-30.202-84.468-33.75-5.595-.592-11.44-.883-17.564-.842-32.04.213-71.833 9.778-124.687 35.937v42.53c60.77-28.823 106.714-37.067 144.218-33.092 18.545 1.965 34.837 6.845 49.75 13.28-4.682 6.064-9.308 13.268-13.875 21.688h117.156c-5.93-8.22-11.798-15.414-17.626-21.56 14.996-6.503 31.39-11.43 50.062-13.408 37.503-3.974 83.448 4.27 144.22 33.094v-42.53c-53.16-26.31-93.115-35.863-125.25-35.97z"></Path>
            </Svg>
          </View>
        ) : null} */}

        {/* {heading == "Extra_curricular_Activity" ? (
          <View
            style={{
              fontSize: "12px",
              fontWeight: "100",
              top: "8px",
              marginRight: "8px",
            }}
          >
            <Svg
              stroke="#53e4ed"
              fill="none"
              stroke-width="0"
              viewBox="0 0 24 24"
              height="32px"
              width="32px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <Path fill="none" d="M0 0h24v24H0z"></Path>
                <Path d="M4 2h16a1 1 0 0 1 1 1v19.276a.5.5 0 0 1-.704.457L12 19.03l-8.296 3.702A.5.5 0 0 1 3 22.276V3a1 1 0 0 1 1-1zm8 11.5l2.939 1.545-.561-3.272 2.377-2.318-3.286-.478L12 6l-1.47 2.977-3.285.478 2.377 2.318-.56 3.272L12 13.5z"></Path>
              </g>
            </Svg>
          </View>
        ) : null} */}

        {/* {heading == "COURSES" ? (
          <View
            style={{
              fontSize: "12px",
              fontWeight: "100",
              top: "8px",
              marginRight: "8px",
            }}
          >
            <Svg
              stroke="#53e4ed"
              fill="none"
              stroke-width="0"
              viewBox="0 0 24 24"
              height="32px"
              width="32px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9H9V9h10v2zm-4 4H9v-2h6v2zm4-8H9V5h10v2z"></Path>
            </Svg>
          </View>
        ) : null} */}

        {/* {heading == "INTERNSHIPS" ? (
          <View
            style={{
              fontSize: "12px",
              fontWeight: "100",
              top: "8px",
              marginRight: "8px",
            }}
          >
            <Svg
              stroke="#53e4ed"
              fill="none"
              stroke-width="0"
              viewBox="0 0 15 15"
              height="32px"
              width="32px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Path
                fill-rule="evenodd"
                d="M0 12.5A1.5 1.5 0 001.5 14h13a1.5 1.5 0 001.5-1.5V6.85L8.129 8.947a.5.5 0 01-.258 0L0 6.85v5.65z"
                clip-rule="evenodd"
              ></Path>
              <Path
                fill-rule="evenodd"
                d="M0 4.5A1.5 1.5 0 011.5 3h13A1.5 1.5 0 0116 4.5v1.384l-7.614 2.03a1.5 1.5 0 01-.772 0L0 5.884V4.5zm5-2A1.5 1.5 0 016.5 1h3A1.5 1.5 0 0111 2.5V3h-1v-.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5V3H5v-.5z"
                clip-rule="evenodd"
              ></Path>
            </Svg>
          </View>
        ) : null} */}

        <View>
          <Text
            style={{
              fontSize: "14px",
              fontWeight: 600,
              color: "#000000",
              // paddingLeft: "40px",
              marginTop: "15px",
              paddingTop: "2px",
              paddingBottom: "2px",
              borderTop: "2px solid #000000",
              borderBottom: "2px solid #000000",
            }}
          >
            {heading}
          </Text>
        </View>
      </View>

      {items.map((item) => (
        <View style={{ marginTop: "15px" }}>
          <Text
            style={{ fontSize: "12px", fontWeight: "500", color: "#000000" }}
          >
            {item[title]}
          </Text>
          <Text
            style={{ fontSize: "12px", color: "#000000", marginTop: "3px" }}
          >
            {item[subTitle] + " "}
            &#40;
            {item[location] && `${item[location]}`}
            &#41;
          </Text>
          <Text
            style={{
              fontSize: "10px",
              color: "#000000",
              marginTop: "3px",
              fontWeight: "400",
            }}
          >
            {getDisplayDate(item[startDate], item[endDate])}
          </Text>

          {item[description] && (
            <View style={{ marginTop: "5px" }}>
              {renderHTMLContent(item[description], {
                "*": { color: "#000000", fontSize: "12px",paddingRight:"8px",textAlign:"justify"},
              })}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

const CustomSection = ({ data, dataKey, heading, fieldMap = {} }) =>{
  const items=data[dataKey];
  console.log("data key", data[dataKey]);
  const {title, city, startDate, endDate, description,custom_title }=fieldMap;
    if(!items||!items.length){
       return <View/>;
    }
    return (
      <View
        style={{
          marginBottom: "8px",
          paddingTop:"10px",
        }}
      >
        <Text
          style={{
            fontSize: "16px",
            fontWeight: 600,
            color: "#000000",
            
          }}
        >
        
          {heading}
        </Text>
        {items.map((item) => (
          
          <View style={{ marginBottom: "8px" }} wrap={true}>
            
            <View
              style={{
                fontSize: "16px",
                fontWeight: "900",
                borderTop: "2px solid #000000",
                borderBottom: "2px solid #000000",
                width:"150px",
                
              }}
            >
              <Text>{item[custom_title]}</Text>
              
            </View>



            <View
              style={{
                fontSize: "13px",
                fontWeight: "500",
                marginTop: "10px",
              }}
            >
              <Text>{item[title]}</Text>
              
            </View>
          
            <Text
              style={{
                fontSize: "10px",
                color: "#363434",
                marginTop: "3px",
                marginBottom: "4px",
              }}
            >
              {getDisplayDate(item[startDate], item[endDate])}
  
              {item[city] ? ` ,  ${item[city]}` : ""}
              
            </Text>

            {item[description] && (
              <View
                style={{
                  marginTop: "4px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                }}
              >
              {console.log(dataKey, item[description])}

                {renderHTMLContent(item[description], {
                  "*": {
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "12px",
                    paddingRight: "5px",
                    alignItems: "baseline",
                    position: "sticky",
                    textAlign: "justify",
                  },
                })}
              </View>
            )}


          </View>
        ))}
      </View>
    );
};


const template17 = ({ data }) => {
  const {
    profileImage,
    First_Name,
    Last_Name,
    Wanted_Job_Title,
    professionalSummary: { Description = "" } = {},
    template_color_code,
  } = data;
  return (
    <TemplatePage data={data}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={{
              position: "absolute",
              height: "100vh",
              width: "164px",
              left: "0px",
              top: "0px",
            }}
            fixed
          >
            <View
              style={{
                height: "100%",
                // backgroundColor: "yellow",
                width: "220px ",
              }}
            ></View>
          </View>
          <View
            style={{
              position: "absolute",
              backgroundColor:template_color_code,
              width: "750px",
              height: "140px",
              top: "10%",
              right: "30%",
              transform: "rotate(130deg)",
            }}
          ></View>

          <View style={styles.profileImageWrapper}>
            <Image
              src={profileImage || DummyProfilePic}
              style={styles.profileImage}
            />
          </View>

          <View style={styles.wrapper}>
            <View style={styles.left}>
              <View style={styles.nameWrapper}>
                <View
                  style={{
                    paddingTop: "260px",
                    marginLeft: "20px",
                    textAlign: "left",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: 500,
                      fontFamily: "Roboto",
                      color: "#000000",
                      fontSize: "25px",
                      marginBottom: "5px",
                      textTransform:"uppercase",
                    }}
                  >
                    {First_Name}
                  </Text>

                  <Text
                    style={{
                      fontWeight: 500,
                      fontFamily: "Roboto",
                      color: "#000000",
                      fontSize: "25px",
                      marginBottom: "5px",
                      textTransform:"uppercase",
                    }}
                  >
                    {Last_Name}
                  </Text>

                  <Text
                    style={{
                      fontWeight: 500,
                      fontFamily: "Roboto",
                      color: "#000000",
                      fontSize: "13px",
                      textAlign: "left",
                      textTransform:"uppercase",
                    }}
                  >
                    {Wanted_Job_Title}
                  </Text>
                </View>
              </View>

              <ContactInfo data={data} />
              <Skills data={data} />
              <Languages data={data} />
              <Hobbies data={data} />
              <References
                data={data}
                dataKey="References"
                heading="REFERENCES"
                fieldMap={{
                  title: "ref_name",
                  subTitle: "company_name",
                  phoneNo: "phone_no",
                  emailId: "ref_email",
                }}
              />
              {/* <AdditionalInfo data={data} /> */}
            </View>

            <View
              style={{
                position: "absolute",
                height: "100vh",
                width: "0px",
                left: "0px",
                top: "-10px",
              }}
              fixed
              render={({ pageNumber }) => (
                <View
                  style={{
                    height: "100%",
                    backgroundColor:
                      pageNumber === 1 ? "transparent" : "#ffffff",
                  }}
                ></View>
              )}
            />
            <View style={styles.right}>
              <View
                style={{
                  position: "absolute",

                  marginTop: "600px",
                  backgroundColor:template_color_code,
                  width: "600px",
                  height: "150px",
                  transform: "rotate(130deg)",
                }}
              ></View>

              <View
                style={{
                  position: "absolute",
                  marginTop: "870px",
                  backgroundColor:"#FF8C00",
                  width: "600px",
                  height: "50px",
                  transform: "rotate(130deg)",
                }}
              ></View>
              <View>
                {!isEmpty(Description) && (
                  <View
                    style={{
                      marginTop: "12px",
                      marginLeft: "0px",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "14px",
                        color: "#000000",
                        fontWeight: 600,
                        top: "2px",
                     
                        marginRight: "20px",
                        paddingTop: "2px",
                        marginBottom: "4px",
                        borderTop: "2px solid #000000",
                        borderBottom: "2px solid #000000",
                      }}
                    >
                      SUMMARY
                    </Text>
                    <View
                      style={{
                        color: "#000000",
                        fontSize: "12px",
                        lineHeight: 1.35,
                        lineWidth: 1.2,
                        textAlign:"justify",
                      }}
                    >
                     
                      
        
              {renderHTMLContent([Description], 
              {
                "*": { color: "#000000", fontSize: "12px",paddingRight:"8px",textAlign:"justify"},
              })}
           
                    </View>
                  </View>
                )}
              </View>

              <RenderView
                data={data}
                dataKey="employmentHistory"
                heading="Work Experience"
                fieldMap={{
                  subTitle: "Employer",
                  title: "Job_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "Description",
                }}
              />

              <RenderView
                data={data}
                dataKey="education"
                heading="MY EDUCATION"
                fieldMap={{
                  title: "institute_name",
                  subTitle: "degree",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "Description",
                }}
              />

              <RenderView
                data={data}
                dataKey="internships"
                heading="INTERNSHIPS"
                fieldMap={{
                  title: "employer_name",
                  subTitle: "job_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "description",
                }}
              />

              <Courses
                data={data}
                dataKey="courses"
                heading="COURSES"
                fieldMap={{
                  title: "course_name",
                  subTitle: "institute_name",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "Description",
                }}
              />
              <RenderView
              data={data}
              dataKey="activities"
              heading="Extra_curricular_Activity"
              fieldMap={{
                title: "employer_name",
                subTitle: "function_title",
                startDate: "start_date",
                endDate: "end_date",
                description: "description",
              }}
            />

              <CustomSection
              data={data}
                dataKey="customSection"
                //heading="custom_title"
                fieldMap={{
                  custom_title:"custom_title",
                  title: "section_name",
                  city: "city",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "description",
                }}
              />
            </View>
          </View>
        </Page>
      </Document>
    </TemplatePage>
  );
};

template17.displayName = "Template17";
export default template17;
