/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
// import custom components
import { useEffect, useState } from 'react';
import LogosTopHeadingOffset2 from 'components/Homepage/clientlogos/LogosTopHeadingOffset2';
import Url from 'components/Url';
// import sub components
import BrowseJobCategories from './BrowseJobCategories';
import CustomerStories from './CustomerStories';
import Blogs from './Blogs';
import FindYourDreamJob from './FindYourDreamJob';
import HowItWorks from './Process';
import LatestJobOpening from './LatestJobOpening';
import TopCompanies from './TopCompanies';
import { Helmet } from 'react-helmet';
import { Modal } from 'react-bootstrap';
//import NavbarDefault from 'layouts/marketing/navbars/NavbarDefault';
//import InternshipgateFooter from './InternshipgateFooter';
import PopupImage from "assets/images/brand/mobile-app-popup_1.jpg";

// import data files
import LogoList2 from 'components/Homepage/clientlogos/LogoList2';

const LandingJob = () => {
	const url1 = Url();
	const url = url1['url'];
	const [internships, setInternships] = useState([]);
	const [seodetails, setSeodetails] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(true);

	useEffect(() => {
		const api1 = url + 'search-internships/all-internships/duration-all/starting-from-all/all/all/all/stipend-any/all/all/all/DESC'

		fetch(api1, {
			method: 'GET',
		})
			.then((apidata1) => {
				return apidata1.json();
			})
			.then((actualdata1) => {
				setInternships(actualdata1.data.slice(0, 5))
				// console.log(actualdata1.data.slice(0, 5))
				//setCategory(actualdata1)
			});
		/*********** get page SEO Settings */

		var api = url + "getPageSeoSettingById/1";
		fetch(api, {
			method: "GET",
		})
		.then((apidata1) => {
			return apidata1.json();
		})
		.then((actualdata1) => {
			setSeodetails(actualdata1);
		});

	}, []);

	const handleCloseModal = () => {
		setIsModalOpen(false);
	  };

	// console.log("Normal data : ");
	// console.log(internships);
	// const location = internships.map(l => l.location)
	// const llocation = location.map(ll => ll.split(','))
	// console.log(llocation);

	// const dateTime = internships.map(obj => obj.posting_date)


	// console.log(dateTime);

	// console.log("Sorted data : ");
	// const sortedInternships = internships.sort((a, b) => b.created_at.localeCompare(a.created_at));
	// console.log(sortedInternships);

	return (
		<main>
			<Helmet>
				<title>{seodetails.page_title}</title>
				<meta name="keywords" content={seodetails.page_meta_keyword} />
				<meta name="description" content={seodetails.page_meta_description} />
			</Helmet>     			
			<FindYourDreamJob />

			{/* Logos section  */}
			{/* <section className="py-8">
				<LogosTopHeadingOffset2 limit={5} offset={1} logos={LogoList2} />
			</section> */}

			{/* Latest Job Opening section */}
			<LatestJobOpening apiData={internships} />

			{/* How It Works section */}
			{/* <HowItWorks /> */}

			{/* Browse Category section */}
			<BrowseJobCategories />

			{/* Customer stories section */}
			<CustomerStories />

			{/* Top companies hiring section */}
			<TopCompanies />
			
			<Blogs />

			<Modal show={isModalOpen} onHide={handleCloseModal} size="md" dialogClassName="modal-90w" centered>
            <Modal.Header closeButton>
			
            </Modal.Header>
            <Modal.Body>
				<a href="https://play.google.com/store/apps/details?id=com.internshipgate.igapp&pcampaignid=web_share">
					<img src={PopupImage} alt="popup mobile" className="img-fluid me-2" />
				</a>
            </Modal.Body>
        	</Modal>   
		</main>
	);
};

export default LandingJob;
