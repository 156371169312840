import React from 'react';
import { Row, Col, Button, ListGroup } from 'react-bootstrap';

const Pagination = ({
  previousPage,
  pageCount,
  pageIndex,
  gotoPage,
  nextPage
}) => {
  // Helper function to determine which pages to show
  const pageNumbers = () => {
    const pages = [];
    const startPage = Math.max(0, pageIndex - 1);
    const endPage = Math.min(pageCount - 1, pageIndex + 1);

    // Always show the first three pages if within range
    for (let i = 0; i <= Math.min(2, pageCount - 1); i++) {
      pages.push(i);
    }

    // Show ellipsis if there's a gap
    if (startPage > 3) {
      pages.push('...');
    }

    // Show three pages around the current page
    for (let i = startPage; i <= endPage; i++) {
      if (!pages.includes(i)) {
        pages.push(i);
      }
    }

    // Show ellipsis if there's a gap
    if (endPage < pageCount - 4) {
      pages.push('...');
    }

    // Always show the last three pages if within range
    for (let i = Math.max(pageCount - 3, endPage + 1); i < pageCount; i++) {
      if (!pages.includes(i)) {
        pages.push(i);
      }
    }

    return pages;
  };

  return (
    <Row>
      <Col lg={12} md={12} sm={12}>
        <div className="pb-5">
          <nav>
            <ListGroup
              as="ul"
              bsPrefix="pagination"
              className="justify-content-center mb-0"
            >
              <ListGroup.Item as="li" bsPrefix="page-item">
                <Button
                  onClick={() => previousPage()}
                  className="page-link mx-1 rounded"
                >
                  <i className="fe fe-chevron-left"></i>
                </Button>
              </ListGroup.Item>
              {pageNumbers().map((page) =>
                page === '...' ? (
                  <ListGroup.Item as="li" bsPrefix="page-item">
                    <span className="page-link mx-1 rounded">...</span>
                  </ListGroup.Item>
                ) : (
                  <ListGroup.Item
                    as="li"
                    bsPrefix="page-item"
                    key={page}
                    className={`page-item ${pageIndex === page ? 'active' : ''}`}
                  >
                    <Button
                      className="page-link mx-1 rounded"
                      onClick={() => gotoPage(page)}
                    >
                      {page + 1}
                    </Button>
                  </ListGroup.Item>
                )
              )}
              <ListGroup.Item as="li" bsPrefix="page-item">
                <Button
                  onClick={() => nextPage()}
                  className="page-link mx-1 rounded"
                >
                  <i className="fe fe-chevron-right"></i>
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </nav>
        </div>
      </Col>
    </Row>
  );
};

export default Pagination;
