import React, { useEffect, useRef, useState, createRef } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  Svg,
  Path,
  Circle,
  Polyline,
} from "@react-pdf/renderer";
import { Html } from "react-pdf-html";
import {
  useA4Scale,
  TemplatePage,
  renderHTMLContent,
  getDisplayDate,
} from "../../utils";
import { Row, Col } from "react-bootstrap";
import DummyProfilePic from "../../../../../assets/images/background/profilepic.png";
import { FiPhone } from "react-icons/fi";
import { isEmpty } from "../../../foundation";

Font.registerHyphenationCallback((word) => {
  // Return entire word as unique part
  return [word];
});




const styles = StyleSheet.create({
  page: {
    display: "flex",
    fontFamily: "Roboto",
    paddingVertical: "10px",
    backgroundColor: "#ffff99",
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  left: {
    //backgroundColor: "#ffff00",
    width: "270px",
  },
  right:{
    backgroundColor:"#ffff99",
    height:"100vh",

   
  },
  profileImageWrapper: {
    display: "flex",
    flexDirection: "row-reverse",
    paddingBottom: "4px",
    paddingRight: "20px",
  },
  profileImage: {
    width: "140px",
    height: "140px",
    borderRadius: "50%",
    border: "1px solid #3644a1",
    marginBottom: "0px",
    paddingBottom: "0px",
  },
});


const ContactInfo = ({ data: { Phone, City, display_email, Country } }) => (
  <View
    style={{
      paddingTop: "60px",
      paddingLeft: "40px",
      bottom: "12px",
    }}
  >
    <Text
      style={{
        color: "#000000",
        fontWeight: 700,
        fontSize: "12px",
        marginBottom: "5px",
        paddingBottom: "10px",
        letterSpacing: "2.75px",
        paddingLeft: "3px",
      }}
    >
      CONTACT
    </Text>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "8px",
        paddingLeft: "4px",
      }}
    >
      <View
        style={{
          backgroundColor: "#000000",
          padding: "3px",
          borderRadius: "50%",
          flex: "0 0 16px",
        }}
      >
        <Svg
          stroke="currentColor"
          fill="#ffffff"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="10px"
          width="10px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></Path>
        </Svg>
      </View>
      <Text
        style={{
          color: "#000000",
          fontSize: "12px",
          paddingLeft: "8px",
        }}
      >
        {Phone}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "8px",
        paddingLeft: "4px",
      }}
    >
      <View
        style={{
          backgroundColor: "#000000",
          padding: "3px",
          borderRadius: "50%",
          flex: "0 0 16px",
        }}
      >
        <Svg
          stroke="#000000"
          fill="#ffffff"
          strokeWidth="2"
          viewBox="0 0 25 25"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="10px"
          width="10px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></Path>
          <Polyline points="22,6 12,13 2,6"></Polyline>
        </Svg>
      </View>
      <Text
        style={{
          color: "black",
          fontSize: "10px",
          paddingLeft: "8px",
        }}
      >
        {display_email}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "8px",
        paddingLeft: "4px",
      }}
    >
      <View
        style={{
          backgroundColor: "#000000",
          padding: "3px",
          borderRadius: "50%",
          flex: "0 0 16px",
        }}
      >
        <Svg
          stroke="#ffffff"
          fill="ffffff"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="10px"
          width="10px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></Path>
          <Circle fill="#000000" cx="12" cy="10" r="3"></Circle>
        </Svg>
      </View>
      <Text
        style={{
          color: "#000000",
          fontSize: "12px",
          paddingLeft: "8px",
        }}
      >
        {City} | {Country}
      </Text>
    </View>
  </View>
);

const Courses = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const { title, subTitle, startDate, endDate, description } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View style={{ marginTop: "4px" }} wrap={false}>
      <View
        style={{
          marginTop: "4px",
          paddingLeft: "31px",
        }}
      >
        <View>
          <Text
            style={{
              color: "#000000",
              fontWeight: "bold",
              fontSize: "12px",
              marginBottom: "10px",
              letterSpacing: "2.75px",
              fontstretch: "normal",
            }}
          >
            {heading}
          </Text>
        </View>
      </View>

      {items.map((item) => (
        <View
          style={{
            paddingLeft: "33px",
            marginLeft: "5px",
            marginBottom: "5px",
          }}
        >
          <Text
            style={{ fontSize: "12px", fontWeight: "500", color: "#000000" }}
          >
            {item[title]}
          </Text>
          <Text
            style={{ fontSize: "12px", color: "#000000", marginTop: "3px" }}
          >
            {item[subTitle]}
          </Text>
          <Text
            style={{
              fontSize: "10px",
              color: "#000000",
              marginTop: "3px",
              fontWeight: "400",
            }}
          >
            {getDisplayDate(item[startDate], item[endDate])}
          </Text>

          {item[description] && (
            <View style={{ marginTop: "5px" }}>
              {renderHTMLContent(item[description], {
                "*": { color: "#000000", fontSize: "12px" },
              })}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

const Skills = ({ data: { skills } }) => {
  if (!skills || !skills.length) {
    return <View />;
  }
  return (
    <View
      style={{
        // padding: "8px 20px",
        marginTop: "4px",
        paddingLeft: "40px",

        // border: "2px solid blue",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontWeight: "bold",
          fontSize: "12px",
          marginBottom: "10px",
          letterSpacing: "2.75px",
          fontstretch: "normal",
        }}
      >
        SKILLS
      </Text>
      {skills.map((s) => (
        <View
          style={{
            display: "flex",
            //flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              color: "#000000",
              fontSize: "12px",
              paddingBottom: "5px",
            }}
          >
            {s.skill_name}
          </Text>
          <View
            style={{
              backgroundColor: "#000000",
              width: "150px",
              height: "3px",
              marginBottom: "12px",
            }}
          >
            <View
              style={{
                backgroundColor: "#ffffff",
                height: "3px",
                width: `${s.level * 50}px`,
              }}
            ></View>
          </View>
        </View>
      ))}
    </View>
  );
};

const Hobbies = ({ data: { hobbies } }) => {
  if (!hobbies || !hobbies.length) {
    return <View />;
  }
  return (
    <View style={{ paddingLeft: "31px", paddingTop: "10px" }}>
      <Text
        style={{
          color: "#000000",
          fontWeight: 700,
          fontSize: "12px",
          marginBottom: "10px",
          letterSpacing: "2.75px",
        }}
      >
        HOBBIES
      </Text>

      {hobbies.map((s) => (
        <Text
          style={{
            color: "#000000",
            fontSize: "12px",
            paddingBottom: "5px",
            paddingLeft: "5px",
            width:"200px",
          }}
        >
          {s.hobbies}
        </Text>
      ))}
    </View>
  );
};

const Languages = ({ data: { languages } }) => {
	if (!languages || !languages.length) {
		return <View />;
	}
	return (
		<View
			style={{

        paddingLeft: "31px", 
        marginTop: "12px"
				
			}}
		>
			<Text
				style={{
          color: "#000000",
          fontWeight: 600,
          fontSize: "14px",
          paddingTop: "2px",
          marginBottom: "10px",
        
				}}
			>
				LANGUAGES
			</Text>
			{languages.map((s) => (
				<Text
					style={{
            
            color: "#000000",
            fontWeight: 500,
            fontSize: "12px",
            paddingBottom: "8px",
            paddingLeft: "8px",
            
					}}
				>
					{s.language_name}
				</Text>
			))}
		</View>
	);
};
{/*
const Languages = ({ data: { languages } }) => {
  if (!languages || !languages.length) {
    return <View />;
  }
  return (
    <View
      style={{
        paddingLeft: "40px",
        marginTop: "4px",
        // border: "2px solid yellow"
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontWeight: "bold",
          fontSize: "12px",
          marginBottom: "10px",
          letterSpacing: "2.75px",
          fontstretch: "normal",
        }}
      >
        LANGUAGES
      </Text>
      {languages.map((s) => (
        <View
          style={{
            display: "flex",

            //flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              color: "#000000",
              fontSize: "12px",
              paddingBottom: "5px",
              // paddingRight: "5px",
            }}
          >
            {s.language_name}
          </Text>
          <View
            style={{
              backgroundColor: "#000000",
              width: "150px",
              height: "3px",
              marginBottom: "12px",
            }}
          >
            <View
              style={{
                backgroundColor: "#ffffff",
                borderRadius: "50px",
                height: "3px",
                width: `${s.level * 38}px`,
                // color: "white",
              }}
            ></View>
          </View>
        </View>
      ))}
    </View>
  );
};
*/}
const References = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const { title, subTitle, phoneNo, emailId } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View
      style={{
        paddingLeft: "31px ",
        marginTop: "15px",
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
        }}
      >
        <View>
          <Text
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              fontstretch: "normal",
              color: "#000000",
              letterSpacing: "2.75px",
            }}
          >
            {heading}
          </Text>
        </View>
      </View>
      {items.map((item) => (
        <View style={{ paddingLeft: "5px", marginTop: "5px" }}>
          <Text style={{ fontSize: "12px", fontWeight: "500" }}>
            {item[title]}
          </Text>
          <Text
            style={{ fontSize: "12px", color: "#363434", marginTop: "3px" }}
          >
            {item[subTitle]}
          </Text>
          <Text
            style={{ fontSize: "12px", color: "#363434", marginTop: "3px" }}
          >
            {item[phoneNo]}
          </Text>
          <Text
            style={{ fontSize: "12px", color: "#363434", marginTop: "3px" }}
          >
            {item[emailId]}
          </Text>
        </View>
      ))}
    </View>
  );
};

const AdditionalInfo = ({
  data: {
    Address,
    Postal_Code,
    Driving_License,
    Nationality,
    dob,
    Place_of_Birth,
  },
}) => {
  const details = [
    {
      label: "Address",
      value: Address,
    },
    {
      label: "Postal Code",
      value: Postal_Code,
    },
    {
      label: "Driving License",
      value: Driving_License,
    },
    {
      label: "Nationality",
      value: Nationality,
    },
    {
      label: "Date of Birth",
      value: dob,
    },
    {
      label: "Place of Birth",
      value: Place_of_Birth,
    },
  ];
  return details.map((info) =>
    info.value ? (
      <View style={{ paddingLeft: "40px", bottom: "6px" }}>
        <Text
          style={{
            color: "#000000",
            fontWeight: 700,
            fontSize: "12px",
            //paddingLeft:"50px",
            marginBottom: "6px",
          }}
        >
          {info.label}
        </Text>
        <Text
          style={{
            color: "#000000",
            fontSize: "10px",
            marginBottom: "10px",
          }}
        >
          {info.value}
        </Text>
      </View>
    ) : (
      <View />
    )
  );
};

const RenderView = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const {
    title,
    subTitle,
    startDate,
    endDate,
    description,
    location = "city",
  } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View style={{ marginTop: "20px", right:"30px", }}>
      <Text
        style={{
          fontSize: "12px",
          fontWeight: "bold",
          letterSpacing: "2.75px",
          color: "#000000",
        }}
      >
        {heading}
      </Text>
      {items.map((item) => (
        <View style={{ marginTop: "5px" }}>
          <Text style={{ fontSize: "11px", fontWeight: "500" }}>
            {item[title]}
          </Text>
          <Text
            style={{ fontSize: "11px", color: "#242732", marginTop: "3px" }}
          >
            {item[subTitle]}
          </Text>
          <Text
            style={{ fontSize: "10px", color: "#363434", marginTop: "3px" }}
          >
            {getDisplayDate(item[startDate], item[endDate])}{" "}
            {item[location] && `, ${item[location]}`}
          </Text>
          {item[description] && (
            <View style={{ marginTop: "5px" }}>
              {renderHTMLContent(item[description], {
                "*": { color: "#3f3f3f",textAlign:"justify",width:"260px",paddingRight:"15px",}
              })}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

const CustomSection = ({ data, dataKey, heading, fieldMap = {} }) =>{
  const items=data[dataKey];
  console.log("data key", data[dataKey]);
  const {title, city, startDate, endDate, description,custom_title }=fieldMap;
    if(!items||!items.length){
       return <View/>;
    }
    return (
      <View
        style={{
          marginTop: "20px", right:"30px",
        }}
      >
        <Text
          style={{
            fontSize: "16px",
            fontWeight: 600,
            color: "#000000",
          }}
        >
        
          {heading}
        </Text>
        {items.map((item) => (
          
          <View style={{ marginBottom: "8px" }} wrap={true}>
            
            <View
              style={{
                fontSize: "16px",
                fontWeight: "900",
                
              }}
            >
              <Text>{item[custom_title]}</Text>
              
            </View>



            <View
              style={{
                fontSize: "13px",
                fontWeight: "500",
                marginTop: "10px",
              }}
            >
              <Text>{item[title]}</Text>
              
            </View>
          
            <Text
              style={{
                fontSize: "10px",
                color: "#363434",
                marginTop: "3px",
                marginBottom: "4px",
              }}
            >
              {getDisplayDate(item[startDate], item[endDate])}
  
              {item[city] ? ` ,  ${item[city]}` : ""}
              
            </Text>

            {item[description] && (
              <View
                style={{
                  marginTop: "4px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                }}
              >
              {console.log(dataKey, item[description])}

                {renderHTMLContent(item[description], {
                  "*": {
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "12px",
                    paddingRight: "5px",
                    alignItems: "baseline",
                    position: "sticky",
                    textAlign: "justify",
                  },
                })}
              </View>
            )}


          </View>
        ))}
      </View>
    );
};



const template18 = ({ data }) => {
  const {
    profileImage,
    First_Name,
    Last_Name,
    Wanted_Job_Title,
    template_color_code,
    professionalSummary: { Description = "" } = {},
  } = data;
  return (
    <TemplatePage data={data}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={{
              position: "absolute",
              height: "100vh",
              width: "270px",
              left: "0px",
              top: "0px",
              backgroundColor:template_color_code,
            }}
            fixed
          >
            <View
              style={{
                height: "100%",
             
                // paddingRight: "60%",
                // position: "absolute",
              }}
            ></View>
          </View>

          <View style={styles.wrapper}>
            <View style={styles.left}>
              <View
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "row",
                  // marginRight: "50%",
                }}
              >
                <Text
                  style={{
                    flexDirection:"row",
                    fontWeight: 800,
                    fontFamily: "Roboto",
                    color: "#000000",
                    fontSize: "26px",
                    marginTop: "40px",
                    paddingLeft:"20px",
                    letterSpacing: "2px",
                  }}
                >
                  {First_Name} { }{Last_Name}
                 
                </Text>
              </View>

              <View
                style={{
                  // position: "absolute",

                  display: "flex",
                  flexDirection: "row",
                  // marginRight: "50%",
                }}
              >
                <Text
                  style={{
                    fontWeight: 600,
                     fontFamily: "Roboto",
                    color: "#000000",
                    fontSize: "12px",
                    marginTop: "50px",
                    paddingLeft:"25px",
                    letterSpacing: "2.75px",
                    marginTop: "4px",
                  }}
                >
                  {Wanted_Job_Title}
                </Text>
              </View>

              <ContactInfo data={data} />
              <AdditionalInfo data={data} />
              <Skills data={data} />
              <Languages data={data} />
              <Courses
                data={data}
                dataKey="courses"
                heading="COURSES"
                fieldMap={{
                  title: "course_name",
                  subTitle: "institute_name",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "Description",
                }}
              />
              <Hobbies data={data} />

              <References
                data={data}
                dataKey="References"
                heading="REFERENCES"
                fieldMap={{
                  title: "ref_name",
                  subTitle: "company_name",
                  phoneNo: "phone_no",
                  emailId: "ref_email",
                }}
              />
            </View>
                
            <View style={{                //right section starting 
               width: "320px",
               color: "#000000",
               backgroundColor: "#ffff99",
               fontSize: "10px",
               height:"100%",
            }
            }>
              <View>
          
                <View style={styles.profileImageWrapper}>
                  <Image
                    src={profileImage || DummyProfilePic}
                    style={styles.profileImage}
                  />
                </View>
              </View>
              <View
                style={{
                  marginTop: "2px",
                  paddingLeft: "70px",
                  paddingRight: "40px",
                }}
              >
                {!isEmpty(Description) && (
                  <View
                    style={{
                      marginTop: "2px",
                      width: "260px",
                      marginRight:"5px",
                      textAlign:"justify",
                      right:"30px",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "12px",
                        fontWeight: 600,
                        top: "2px",
                        marginRight: "3px",
                        marginBottom: "4px",
                        color: "#000000",
                        letterSpacing: "2.75px",
                        textAlign:"justify",
                      }}
                    >
                      PROFESSIONAL SUMMARY
                    </Text>
                    <View
                      style={{
                        textTransform: "capitalize",
                        color: "#3f3f3f",
                        marginTop: "10px",
                        fontSize: "10px",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        fontWeight: 600,
                        textAlign:"justify",
                        width: "260px",
                        paddingRight:"20px",
                      }}
                    >
                      {renderHTMLContent(Description)}
                    </View>
                  </View>
                )}

                <RenderView
                  data={data}
                  dataKey="employmentHistory"
                  heading="EMPLOYMENT HISTORY"
                  fieldMap={{
                    subTitle: "Employer",
                    title: "Job_title",
                    startDate: "start_date",
                    endDate: "end_date",
                    description: "Description",
                  }}
                />
                <RenderView
                  data={data}
                  dataKey="education"
                  heading="EDUCATION"
                  fieldMap={{
                    title: "institute_name",
                    subTitle: "degree",
                    startDate: "start_date",
                    endDate: "end_date",
                    description: "Description",
                  }}
                />
                <RenderView
                  data={data}
                  dataKey="internships"
                  heading="INTERNSHIPS"
                  fieldMap={{
                    title: "employer_name",
                    subTitle: "job_title",
                    startDate: "start_date",
                    endDate: "end_date",
                    description: "description",
                  }}
                />
              
                <RenderView
                  data={data}
                  dataKey="activities"
                  heading="EXTRA CURRICULAR ACTIVITY"
                  fieldMap={{
                    title: "employer_name",
                    subTitle: "function_title",
                    startDate: "start_date",
                    endDate: "end_date",
                    description: "description",
                  }}
                />
                <CustomSection
                data={data}
                  dataKey="customSection"
                  //heading="custom_title"
                  fieldMap={{
                    custom_title:"custom_title",
                    title: "section_name",
                    city: "city",
                    startDate: "start_date",
                    endDate: "end_date",
                    description: "description",
                  }}
                />
                

              </View>
            </View>
          </View>
        </Page>
      </Document>
    </TemplatePage>
  );
};

template18.displayName = "Template18";
export default template18;
