// import node module libraries
import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

// import custom components
import { Avatar } from 'components/elements/bootstrap/Avatar';
// import GKTippy from 'components/elements/tooltips/GKTippy';

// import context file
import { ChatContext } from 'context/Context';

// import hook file
import useChatOperations from 'hooks/useChatOperations';

const ChatHeader = (props) => {
	const { setHideChatBox, name, setId } = props;

	// const {
	// 	ChatState: { activeThread }
	// } = useContext(ChatContext);

	// const { getUserDetailsById } = useChatOperations();

	// let ActiveChatInfo = getUserDetailsById(activeThread.userId)

	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to=""
			ref={ref}
			className="text-link"
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
		</Link>
	));
	const ActionMenu = () => {
		return (
			<Dropdown as="span" className="dropdown ms-2" >
				<Dropdown.Toggle as={CustomToggle}>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
						<path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
					</svg>
				</Dropdown.Toggle>
				<Dropdown.Menu align="start">
					<Dropdown.Item eventKey="1" className="px-3">Mark as unread </Dropdown.Item>
					<Dropdown.Item eventKey="3" className="px-3">Report </Dropdown.Item>
					<Dropdown.Item eventKey="4" className="px-3">Block Chat</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};
	return (
		<Fragment>
			<div className="bg-white border-top border-bottom px-4 py-3 sticky-top" style={{marginRight: 'auto'}}>
				<div className="d-flex justify-content-between align-items-center">
					<div className="d-flex align-items-center">
						{/* media */}
						<div className="d-flex align-items-center">
							<Link to="#" onClick={() => setId()} className="me-2 d-xl-none d-block" data-close>
								<i className="fe fe-arrow-left"></i></Link>
							<Avatar
								size="md"
								className="rounded-circle text-dark"
								type='initial'
								src="initial"
								alt={name}
								name={name}
								variant="warning"
							/>
							{/* media body */}
							<div className=" ms-2">
								<h4 className="mb-0">{name}</h4>
							</div>
						</div>
						{/* <div>
							<Link to="#" className="ms-6">View application <span className="ms-1"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
								<path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
								<path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
							</svg></span>
							</Link>
						</div> */}
					</div>
					{/* <div className="d-none d-lg-block">
						<Link to="#" className="btn btn-outline-danger">Not Interested</Link>
						<Link to="#" className="btn btn-outline-primary ms-1">Hire</Link>
						<Link to="#" className="btn btn-primary ms-1">Shortlist</Link>
						<ActionMenu />
					</div> */}
				</div>
			</div>
		</Fragment>
	);
};
export default ChatHeader;
