/* eslint-disable prettier/prettier */
// import node module libraries
import { Col, Row, Container, Button, Image } from 'react-bootstrap';
import {Fragment} from "react";
// import custom components//
import JobListingListviewCard from 'components/Homepage/JobListingListviewCard';

// import required data files
import JobsListingData from 'components/Homepage/data/jobs/JobsListingData';
import { Link } from 'react-router-dom';
import Url from "components/Url";

const LatestJobOpening = ({ apiData }) => {
	const url1 = Url();
	const s3 = url1["s3"];

	const bannerIndices = {
		1: "creatives/Banner_1.jpg",
		3: "creatives/Banner_2.jpg",
		5: "creatives/Banner_3.jpg",
		7: "creatives/Banner_4.jpg",
	  };
	return (
		<section className="py-lg-6 bg-white py-6">
			<Container>
				<Row className="row">
					<Col xl={{ span: 8, offset: 2 }} md={12} xs={12}>
						<div className="text-center mb-8">
							<span className="text-uppercase text-primary fw-semi-bold ls-md">
								Latest Internship Opening
							</span>
							<h2 className="h1 fw-bold mt-3">
								Explore remote friendly, flexible internship opportunities.
							</h2>
						</div>
						{apiData.map((item, index) => { 
							return (
							<Fragment key={index}>
							<Link to={"internship-details/"+item.id} >
								<JobListingListviewCard item={item} key={index} />
							</Link>
							{bannerIndices[index] && (
								<Row className="w-100 mb-4 p-0">
								<Link to="/virtual-internship" target="_blank" rel="noopener noreferrer">
									<Image src={`${s3}${bannerIndices[index]}`} className="w-100 img-fluid rounded" />
								</Link>
								</Row>
							)}							
							</Fragment>
							)
						})}
						{/* button */}
						<div className="mt-6 text-center">
							<Link to="/Intlisting">
							<Button variant="outline-primary">
								Browse All Internship Postings
							</Button>
							</Link>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default LatestJobOpening;
