import React, { useState } from "react";
import cx from "classnames";
import { Accordion, Col, Row } from "react-bootstrap";
import moment from "moment";
import { FiChevronDown, FiPlus, FiTrash2 } from "react-icons/fi";
import Switch from "@material-ui/core/Switch";
import { InputField, Label, Button, RTE } from "../../foundation";
import { Controller } from "react-hook-form";
import Api, { ENDPOINTS } from "../../api";
import CircularProgress from "@material-ui/core/CircularProgress";

const Hobbies = ({
	Hbby,
	watch,
	register,
	fields,
	append,
	remove,
	control,
	reset,
	getValues,
}) => {
	const [deleting, setDeleting] = useState(null);
	const Hobbies = watch("hobbies");
	return (
		<div className="resume-builder__section">
			<h2>
				Hobbies
				{/* <Button
          variant="link"
          type="button"
          style={{ fontSize: "28px", marginLeft: "8px" }}
          onClick={() => {
            if (Hbby?.fields.length) {
              reset({ ...getValues(), hobbies: [] });
              console.log(Hobbies);
            }
            // else {
            //   hobbies.append({});
            // }
          }}
        >
          delete
        </Button> */}
			</h2>

			{fields.map((field, index) => {
				const { hobbies, Hobbies_title } = Hobbies?.[index] || {};
				// const displayDate = [];
				// if (start_date) displayDate.push(moment(start_date).format("MMM YYYY"));
				// if (end_date) displayDate.push(moment(end_date).format("MMM YYYY"));
				return (
					<div key={field.key} className={cx("resume-builder__section__card p-0")}>
						<input
							type="hidden"
							id={`hobbies[${index}].id`}
							name={`hobbies[${index}].id`}
							defaultValue={field.id}
							{...register(`hobbies[${index}].id`)}
						/>
						<Accordion defaultActiveKey={`${field.id ? "" : field.key}`}>
							<Accordion.Item
								as={"div"}
								variant="link"
								eventKey={`${field.key}`}
								className="resume-builder__section__card--title"
							>
								<Accordion.Header className="w-100" >
									<div className="m-0">
										<p className="m-0">
											{hobbies && Hobbies_title
												? `${Hobbies_title} at ${hobbies}`
												: Hobbies_title || hobbies || "(Not Specified)"}
										</p>
										{/* {displayDate.length > 0 && (
                    <Label className="mb-0">{displayDate.join(" - ")}</Label>
                  )} */}
									</div>{" "}
									{/* <span className="d-flex m-0">
										<FiChevronDown size={20} />
									</span> */}
								</Accordion.Header>
								<Accordion.Body eventKey={`${field.key}`}>
									<>
										{/* <Row>
                    <Col>
                      <Controller
                        control={control}
                        name={`Hobbies[${index}].end_date`}
                        render={({ onChange, value, name, ref }) => (
                          <>
                            <Switch
                              checked={value === null}
                              onChange={(e) =>
                                onChange(e.target.checked ? null : "")
                              }
                              color="primary"
                            />{" "}
                            <Label as="span">Currently work here</Label>
                          </>
                        )}
                      />
                    </Col>
                  </Row> */}
										<Row className="mb-3 ">
											<Col>
												<Label className="mt-1">What do you like ?</Label>
												<Controller
													control={control}
													name={`hobbies[${index}].hobbies`}
													render={({
														field: { onChange, onBlur, value, name, ref },
														fieldState: { invalid, isTouched, isDirty, error },
														formState,
													}) => (
														<InputField
															style={{ height: "100px", width: "100%" }}
															stretch
															placeholder="e.g. Skiing, Skydiving, Painting"
															onBlur={onBlur} // notify when input is touched
															onChange={onChange} // send value to hook form
															checked={value}
															inputRef={ref}
														/>
													)}
												/>
											</Col>
											{/* <Col>
                      <Label>Institution</Label>
                      <Controller
                        control={control}
                        name={`Hobbies[${index}].Hobbies_title`}
                        render={(controlProps) => (
                          <InputField
                            stretch
                            placeholder="Enter Hobbies_title name"
                             onBlur={onBlur} // notify when input is touched
      onChange={onChange} // send value to hook form
      checked={value}
      inputRef={ref}
                          />
                        )}
                      />
                    </Col> */}
										</Row>
										{/* <Row className="mb-3" style={{maxWidth:"52%"}}>
                    <Col >
                      <Row>
                        <Col xs={6} className="pr-1">
                          <Label>Start Date</Label>
                          <Controller
                            control={control}
                            name={`Hobbies[${index}].start_date`}
                            render={(controlProps) => (
                              <InputField
                                type="date"
                                stretch
                                 onBlur={onBlur} // notify when input is touched
      onChange={onChange} // send value to hook form
      checked={value}
      inputRef={ref}
                              />
                            )}
                          />
                        </Col>
                        <Col xs={6} className="pl-1">
                          <Label>End Date</Label>
                          <Controller
                            control={control}
                            name={`Hobbies[${index}].end_date`}
                            render={(controlProps) =>
                              end_date === null ? (
                                <InputField stretch value="Present" readOnly />
                              ) : (
                                <InputField
                                  type="date"
                                  min={start_date}
                                  stretch
                                   onBlur={onBlur} // notify when input is touched
      onChange={onChange} // send value to hook form
      checked={value}
      inputRef={ref}
                                />
                              )
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Label>City</Label>
                      <Controller
                        control={control}
                        name={`Hobbies[${index}].city`}
                        render={(controlProps) => (
                          <InputField
                            stretch
                            placeholder="Enter city"
                             onBlur={onBlur} // notify when input is touched
      onChange={onChange} // send value to hook form
      checked={value}
      inputRef={ref}
                          />
                        )}
                      />
                    </Col>
                  </Row> */}
										{/* <Row>
                    <Col>
                      <Label>Description</Label>
                      <Controller
                        control={control}
                        name={`Hobbies[${index}].Description`}
                        render={({ onChange, value, name, ref }) => (
                          <RTE value={value} onChange={onChange} />
                        )}
                      />
                    </Col>
                  </Row> */}
									</>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
						<Button
							variant="link"
							disabled={deleting === index}
							className="resume-builder__section__card--delete"
							onClick={async () => {
								try {
									setDeleting(index);
									if (field.id) {
										await Api.delete(`${ENDPOINTS.DELETE_HOBBY}/${field.id}`);
									}
									remove(index);
								} finally {
									setDeleting(null);
								}
							}}
						>
							{deleting === index ? (
								<CircularProgress size={20} color="primary" />
							) : (
								<FiTrash2 size={20} />
							)}
						</Button>
					</div>
				);
			})}
			{/* <Row className="mb-3">
        <Col>
          <Button variant="link" type="button" onClick={() => append({})}>
            {fields.length < 1 ? (
              <>
                <FiPlus /> Add hobbies
              </>
            ) : (
              ""
            )}
          </Button>
        </Col>
      </Row> */}
		</div>
	);
};

export default Hobbies;
