import Url from "components/Url";
import React, { useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { EnvelopeOpen, GeoAlt, Telephone } from "react-bootstrap-icons";
import { toast } from "react-toastify";

const CollegeFeedback = ({ token }) => {
  const url1 = Url();
  const url = url1["url"];
  const api = url + "collegeFeedback";

  const [formData, setformData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        email: formData.email,
        name: formData.name,
        message: formData.msg,
      }),
    })
      .then((apidata) => {
        return apidata.json();
      })
      .then((actualdata) => {
        setLoading(false);
        // console.log(actualdata);
        toast.success(actualdata.message);
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
        toast.error(error.message);
      });
  };

  return (
    <div>
      <Row>
        <Col xs={12} md={6}>
          <Form>
            <Form.Group className="my-3">
              <Form.Text className="my-3 ml-0">Email *</Form.Text>
              <Form.Control
                type="email"
                className="form-control-contact"
                required
                placeholder=""
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="my-3">
              <Form.Text className="Email my-3 ml-0">Name *</Form.Text>
              <Form.Control
                type="text"
                className="form-control-contact"
                required
                placeholder=""
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="my-3">
              <Form.Text className="my-3 ml-0">Message *</Form.Text>
              <Form.Control
                as="textarea"
                className="form-control-contact-msg"
                rows="5"
                required
                name="msg"
                value={formData.msg}
                onChange={handleChange}
              />
            </Form.Group>

            <Button
              className="mt-2"
              type="submit"
              variant="primary"
              disabled={loading}
              onClick={handleSubmit}
            >
              {" "}
              {loading ? (
                <span>
                  <Spinner size="sm" /> Please wait....
                </span>
              ) : (
                "Send Message"
              )}
            </Button>
          </Form>
        </Col>
        <Col xs={12} md={6} className="mt-3">
          <div className="fs-2 lead">Feel free to get in touch</div>
          <div className="fs-5 mt-3">
            <Telephone size={16} className="text-primary me-1" /> +91
            8105566534, +91 7678360337
          </div>
          <div className="fs-5 mt-3">
            <EnvelopeOpen size={16} className="text-primary me-2" />
            info@internshipgate.com
          </div>
          <div className="fs-5 mt-3">
            <GeoAlt size={17} className="text-primary me-2" />
            1st floor, 27th main, 13th Cross Rd, 1st Sector, HSR Layout,
            Bengaluru, Karnataka 560102
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CollegeFeedback;
