import React, { useEffect, useRef, useState, createRef } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  Svg,
  Path,
  Circle,
  Polyline,
  Rect,
  Line,
} from "@react-pdf/renderer";
import { Html } from "react-pdf-html";
import {
  useA4Scale,
  TemplatePage,
  renderHTMLContent,
  getDisplayDate,
} from "../../utils";
import { Row, Col } from "react-bootstrap";
import DummyProfilePic from "../../../../../assets/images/background/profilepic.png";
import { FiPhone } from "react-icons/fi";
import { isEmpty } from "../../../foundation";

Font.registerHyphenationCallback((word) => {
  // Return entire word as unique part
  return [word];
});

const styles = StyleSheet.create({
  page: { display: "flex", fontFamily: "Roboto", paddingVertical: "30px" },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  left: {
    //backgroundColor: "#2441ff",
    width: "236px",
  },
  right: {
    padding: "0px 20px 0px 20px",
    width: "540px",
    paddingLeft: "124px",
    color: "#000000",
    fontSize: "10px",
  },
  profileImageWrapper: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "50px",
    paddingBottom: "40px",
  },
  profileImage: {
    width: "143px",
    height: "143px",
    borderRadius: "50%",
    border: "1px solid #3644a1",
    margin: " 8px 46px",
    marginBottom: "24px",
    paddingBottom: "0px",
  },
});

const ContactInfo = ({ data: { Phone, Country, display_email } }) => (
  <View
    style={{
      //    padding: "24px",
      paddingLeft: "32px",
      bottom: "12px",
    }}
  >
    <Text
      style={{
        color: "#ffffff",
        fontWeight: 700,
        fontSize: "14px",
        marginBottom: "5px",
        paddingBottom: "10px",
      }}
    >
      Basic Details
    </Text>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "8px",
        paddingLeft: "4px",
      }}
    >
      <View
        style={{
          backgroundColor: "#ffffff",
          padding: "3px",
          borderRadius: "50%",
          flex: "0 0 16px",
        }}
      >
        <Svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="10px"
          width="10px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></Path>
        </Svg>
      </View>
      <Text
        style={{
          color: "#ffffff",
          fontSize: "12px",
          paddingLeft: "8px",
        }}
      >
        {Phone}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "8px",
        paddingLeft: "4px",
      }}
    >
      <View
        style={{
          backgroundColor: "#ffffff",
          padding: "3px",
          borderRadius: "50%",
          flex: "0 0 16px",
        }}
      >
        <Svg
          stroke="#ffffff"
          fill="#000000"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="10px"
          width="10px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></Path>
          <Polyline points="22,6 12,13 2,6"></Polyline>
        </Svg>
      </View>
      <Text
        style={{
          color: "#ffffff",
          fontSize: "12px",
          paddingLeft: "8px",
        }}
      >
        {display_email}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "8px",
        paddingLeft: "4px",
      }}
    >
      <View
        style={{
          backgroundColor: "#ffffff",
          padding: "3px",
          borderRadius: "50%",
          flex: "0 0 16px",
        }}
      >
        <Svg
          stroke="#ffffff"
          fill="currentColor"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="10px"
          width="10px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></Path>
          <Circle fill="#ffffff" cx="12" cy="10" r="3"></Circle>
        </Svg>
      </View>
      <Text
        style={{
          color: "#ffffff",
          fontSize: "12px",
          paddingLeft: "8px",
        }}
      >
        {Country}
      </Text>
    </View>
  </View>
);
const Skills = ({ data: { skills } }) => {
  if (!skills || !skills.length) {
    return <View />;
  }
  return (
    <View
      style={{
        paddingLeft: "36px",
        marginTop: "15px",
        paddingLeft: "18%",
        top: "-18px",


      }}
    >
      <Text
        style={{
          color: "#ffffff",
          marginRight:"5px",
          paddingLeft: "-2px",
          fontWeight: "bold",
          fontSize: "15px",
          marginBottom: "10px",
          // letterSpacing: "1.95px",
          fontstretch: "normal",
        }}
      >
        Skills
      </Text>
      {skills.map((s) => (
        <View
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              color: "#ffffff",
              fontSize: "12px",
              paddingBottom: "3px",
              paddingLeft: "8px",
              fontWeight: "bold",
            }}
          >
            {s.skill_name}
          </Text>
          <View
            style={{
              backgroundColor:"#ffffff",
              width: "141px",
              height: "6px",
              marginBottom: "5px",
              borderRadius: "50px",
              // marginRight: "30%",
              marginTop: "5px",
            }}

          >
            <View
              style={{
                backgroundColor: "#8a30cd",
                borderRadius: "50px",
                height: "6px",
                width: `${s.level * 47}px`,
                // color: "white",
              }}
            ></View>
          </View>
        </View>
      ))}
    </View>
  );
};


const Hobbies = ({ data: { hobbies } }) => {
  if (!hobbies || !hobbies.length) {
    return <View />;
  }
  return (
    <View style={{ paddingLeft: "34px" }}>
      <Text
        style={{
          color: "#ffffff",
          fontWeight: 700,
          fontSize: "14px",
          marginBottom: "10px",
        }}
      >
        HOBBIES
      </Text>

      {hobbies.map((s) => (
        <Text
          style={{
            color: "#ffffff",
            fontSize: "10px",
            paddingBottom: "10px",
            paddingLeft: "8px",
          }}
        >
          {s.hobbies}
        </Text>
      ))}
    </View>
  );
};

const Languages = ({ data: { languages } }) => {
  if (!languages || !languages.length) {
    return <View />;
  }
  return (
    <View style={{ paddingLeft: "34px" }}>
      <Text
        style={{
          color: "#ffffff",
          fontWeight: 700,
          fontSize: "14px",
          marginBottom: "10px",
          //paddingLeft:"50px",
        }}
      >
        LANGUAGES
      </Text>
      {languages.map((s) => (
        <Text
          style={{
            color: "#ffffff",
            fontSize: "10px",
            paddingBottom: "10px",
            paddingLeft: "8px",
          }}
        >
          {s.language_name}
        </Text>
      ))}
    </View>
  );
};

const References = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const { title, subTitle, phoneNo, emailId } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  const {
    template_color_code,
  }=data;
  return (
    <View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
          marginLeft: "-42px ",
        }}
      >
        <View
          style={{
            fontSize: "12px",
            fontWeight: "100",
            top: "8px",
            marginRight: "8px",
          }}
        >
          <Svg
            stroke="#a053ed"
            fill="none"
            stroke-width="0"
            viewBox="0 0 24 24"
            height="32px"
            width="32px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <Path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
            ></Path>
          </Svg>
        </View>
        <View>
          <Text
            style={{
              fontSize: "12px",
              fontWeight: 700,
              color:template_color_code,
            }}
          >
            {heading}
          </Text>
        </View>
      </View>
      {items.map((item) => (
        <View style={{ marginTop: "15px" }}>
          <Text style={{ fontSize: "12px", fontWeight: "500" }}>
            {item[title]}
          </Text>
          <Text
            style={{ fontSize: "12px", color: "#8a30cd", marginTop: "3px" }}
          >
            {item[subTitle]}
          </Text>
          <Text
            style={{ fontSize: "12px", color: "#363434", marginTop: "3px" }}
          >
            {item[phoneNo]}
          </Text>
          <Text
            style={{ fontSize: "12px", color: "#363434", marginTop: "3px" }}
          >
            {item[emailId]}
          </Text>
        </View>
      ))}
    </View>
  );
};

const AdditionalInfo = ({
  data: {
    Address,
    City,
    Postal_Code,
    Driving_License,
    Nationality,
    dob,
    Place_of_Birth,
  },
}) => {
  const details = [
    {
      label: "Address",
      value: `${Address || ""} ${City || ""} ${Postal_Code || ""}`.trim(),
    },
    {
      label: "Driving License",
      value: Driving_License,
    },
    {
      label: "Nationality",
      value: Nationality,
    },
    {
      label: "Date of Birth",
      value: dob,
    },
    {
      label: "Place of Birth",
      value: Place_of_Birth,
    },
  ];
  return details.map((info) =>
    info.value ? (
      <View style={{ paddingLeft: "40px", bottom: "6px" }}>
        <Text
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "12px",
            //paddingLeft:"50px",
            marginBottom: "6px",
          }}
        >
          {info.label}
        </Text>
        <Text
          style={{
            color: "#ffffff",
            fontSize: "10px",
            marginBottom: "10px",
          }}
        >
          {info.value}
        </Text>
      </View>
    ) : (
      <View />
    )
  );
};

const RenderView = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const {
    title,
    subTitle,
    startDate,
    endDate,
    description,
    location = "city",
  } = fieldMap;
  if (!items || !items.length) {
    return <View />; 
  }
  const{
    template_color_code
  }=data;
  return (
    <View style={{ marginTop: "4px" }}>
      <View
       style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        marginLeft: "-42px ",
      }}
      >
        {heading == "WORK EXPERIENCE" ? (
          <View
            style={{
              fontSize: "12px",
              fontWeight: "100",
              top: "8px",
              marginRight: "8px",
            }}
          >
            <Svg
              stroke="#a053ed"
              fill="none"
              stroke-width="0"
              viewBox="0 0 24 24"
              height="32px"
              width="32px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Path d="M20,6h-3V4c0-1.103-0.897-2-2-2H9C7.897,2,7,2.897,7,4v2H4C2.897,6,2,6.897,2,8v11c0,1.103,0.897,2,2,2h16 c1.103,0,2-0.897,2-2V8C22,6.897,21.103,6,20,6z M15,4v2H9V4H15z M4,8h4h8h1h3v4h-3v-2h-2v2H9v-2H7v2H4V8z M4,19v-5h3v2h2v-2h6v2 h2v-2h3.001v5H4z"></Path>
            </Svg>
          </View>
        ) : null}
        {heading == "EDUCATION" ? (
          <View
            style={{
              fontSize: "12px",
              fontWeight: "100",
              top: "8px",
              marginRight: "8px",
            }}
          >
            <Svg
              stroke="#a053ed"
              fill="#a053ed"
              stroke-width="2"
              viewBox="0 0 16 16"
              height="32px"
              width="32px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Path
                fill-rule="evenodd"
                d="M3.214 1.072C4.813.752 6.916.71 8.354 2.146A.5.5 0 018.5 2.5v11a.5.5 0 01-.854.354c-.843-.844-2.115-1.059-3.47-.92-1.344.14-2.66.617-3.452 1.013A.5.5 0 010 13.5v-11a.5.5 0 01.276-.447L.5 2.5l-.224-.447.002-.001.004-.002.013-.006a5.017 5.017 0 01.22-.103 12.958 12.958 0 012.7-.869zM1 2.82v9.908c.846-.343 1.944-.672 3.074-.788 1.143-.118 2.387-.023 3.426.56V2.718c-1.063-.929-2.631-.956-4.09-.664A11.958 11.958 0 001 2.82z"
                clip-rule="evenodd"
              ></Path>
              <Path
                fill-rule="evenodd"
                d="M12.786 1.072C11.188.752 9.084.71 7.646 2.146A.5.5 0 007.5 2.5v11a.5.5 0 00.854.354c.843-.844 2.115-1.059 3.47-.92 1.344.14 2.66.617 3.452 1.013A.5.5 0 0016 13.5v-11a.5.5 0 00-.276-.447L15.5 2.5l.224-.447-.002-.001-.004-.002-.013-.006-.047-.023a12.582 12.582 0 00-.799-.34 12.96 12.96 0 00-2.073-.609zM15 2.82v9.908c-.846-.343-1.944-.672-3.074-.788-1.143-.118-2.387-.023-3.426.56V2.718c1.063-.929 2.631-.956 4.09-.664A11.956 11.956 0 0115 2.82z"
                clip-rule="evenodd"
              ></Path>
            </Svg>
          </View>
        ) : null}
        {heading == "EXTRA_CURRICULAR_ACTIVITY" ? (
          <View
            style={{
              fontSize: "12px",
              fontWeight: "100",
              top: "8px",
              marginRight: "8px",
            }}
          >
            <Svg
              stroke="#a053ed"
              fill="none"
              stroke-width="0"
              viewBox="0 0 24 24"
              height="32px"
              width="32px"
              xmlns="http://www.w3.org/2000/svg"
            >
                <Path fill="none" d="M0 0h24v24H0z"></Path>
                <Path d="M4 2h16a1 1 0 0 1 1 1v19.276a.5.5 0 0 1-.704.457L12 19.03l-8.296 3.702A.5.5 0 0 1 3 22.276V3a1 1 0 0 1 1-1zm15 17.965V4H5v15.965l7-3.124 7 3.124zM12 13.5l-2.939 1.545.561-3.272-2.377-2.318 3.286-.478L12 6l1.47 2.977 3.285.478-2.377 2.318.56 3.272L12 13.5z"></Path>
            </Svg>
          </View>
        ) : null}
        {heading == "COURSES" ? (
          <View
            style={{
              fontSize: "12px",
              fontWeight: "100",
              top: "8px",
              marginRight: "8px",
            }}
          >
            <Svg
              stroke="#a053ed"
              fill="none"
              stroke-width="0"
              viewBox="0 0 1024 1024"
              height="32px"
              width="32px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Path d="M832 64H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32zm-260 72h96v209.9L621.5 312 572 347.4V136zm220 752H232V136h280v296.9c0 3.3 1 6.6 3 9.3a15.9 15.9 0 0 0 22.3 3.7l83.8-59.9 81.4 59.4c2.7 2 6 3.1 9.4 3.1 8.8 0 16-7.2 16-16V136h64v752z"></Path>
            </Svg>
          </View>
        ) : null}
        {heading == "INTERNSHIPS" ? (
          <View
            style={{
              fontSize: "12px",
              fontWeight: "100",
              top: "8px",
              marginRight: "8px",
            }}
          >
            <Svg
              stroke="#a053ed"
              fill="none"
              stroke-width="0"
              viewBox="0 0 16 16"
              height="32px"
              width="32px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Path
                fill-rule="evenodd"
                d="M0 12.5A1.5 1.5 0 001.5 14h13a1.5 1.5 0 001.5-1.5v-6h-1v6a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-6H0v6z"
                clip-rule="evenodd"
              ></Path>
              <Path
                fill-rule="evenodd"
                d="M0 4.5A1.5 1.5 0 011.5 3h13A1.5 1.5 0 0116 4.5v2.384l-7.614 2.03a1.5 1.5 0 01-.772 0L0 6.884V4.5zM1.5 4a.5.5 0 00-.5.5v1.616l6.871 1.832a.5.5 0 00.258 0L15 6.116V4.5a.5.5 0 00-.5-.5h-13zM5 2.5A1.5 1.5 0 016.5 1h3A1.5 1.5 0 0111 2.5V3h-1v-.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5V3H5v-.5z"
                clip-rule="evenodd"
              ></Path>
            </Svg>
          </View>
        ) : null}
        <View>
          <Text
            style={{
              fontSize: "12px",
              fontWeight: 700,
              color:template_color_code,
            }}
          >
            {heading}
          </Text>
        </View>
      </View>
      {items.map((item) => (
        <View style={{ marginTop: "15px" }}>
          <Text style={{ fontSize: "12px", fontWeight: "500" }}>
            {item[title]}
          </Text>
          <Text
            style={{ fontSize: "12px", color: "#8a30cd", marginTop: "3px" }}
          >
            {item[subTitle]}
          </Text>
          <Text
            style={{
              fontSize: "12px",
              //color: "#363434",
              color: "#7d7e7f",
              marginTop: "3px",
              fontWeight: "400",
            }}
          >
            {getDisplayDate(item[startDate], item[endDate])}{" "}
            {item[location] && `, ${item[location]}`}
          </Text>
          {item[description] && (
            <View style={{ marginTop: "5px" }}>
              {renderHTMLContent(item[description], {
                "*": { color: "#3f3f3f",textAlign:"justify",paddingRight:"8px",}
              })}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

const CustomSection = ({ data, dataKey, heading, fieldMap = {} }) =>{
  const items=data[dataKey];
  console.log("data key", data[dataKey]);
  const {title, city, startDate, endDate, description,custom_title }=fieldMap;
    if(!items||!items.length){
       return <View/>;
    }
    const {
      template_color_code,
    }=data;
    return (
      <View
        style={{
         marginTop: "4px",paddingRight:"20px",paddingTop:"10px",
        }}
      >
      <Svg
      xmlns="http://www.w3.org/2000/svg"
      height="30px"
      width="25px"
      viewBox="0 0 24 24"
      style={{
        position: "absolute",
        marginLeft: "-35px",
        top: "8px",
      }}
    >
      <Rect x="3" y="2" width="18" height="20" rx="2" ry="2" style={{ fill: "#f0f0f0", stroke: template_color_code, strokeWidth: 1,paddingLeft:"10px",Top:"-10px",}} />
      <Line x1="7" y1="8" x2="17" y2="8" style={{ stroke: "#000", strokeWidth: 1 }} />
      <Line x1="7" y1="12" x2="17" y2="12" style={{ stroke: "#000", strokeWidth: 1 }} />
      <Line x1="7" y1="16" x2="13" y2="16" style={{ stroke: "#000", strokeWidth: 1 }} />
      <Path d="M 16 19 L 20 15" style={{ stroke: "#000", strokeWidth: 1 }} />
      <Path d="M 20 15 L 18 13 L 14 17 L 16 19" style={{ fill: template_color_code, stroke: "#000", strokeWidth: 1 }} />
      <Path d="M 18 13 L 17 12 L 19 10 L 20 11" style={{ fill: template_color_code, stroke: "#000", strokeWidth: 1 }} />
    </Svg>
    

        <Text
          style={{
            fontSize: "16px",
            fontWeight: 600,
             color:"#000000",
          }}
        >
          {heading}
        </Text>
        {items.map((item) => (
          
          <View style={{ marginBottom: "8px" }} wrap={true}>
            
            <View
              style={{
                fontSize: "16px",
                fontWeight: "900",
                color:template_color_code,
                paddingLeft:"-5px",
              }}
            >
              <Text>{item[custom_title]}</Text>
              
            </View>
            <View
              style={{
                fontSize: "13px",
                fontWeight: "500",
                marginTop: "10px",
              }}
            >
              <Text>{item[title]}</Text>
              
            </View>
          
            <Text
              style={{
                fontSize: "10px",
                color: "#363434",
                marginTop: "3px",
                marginBottom: "4px",
              }}
            >
              {getDisplayDate(item[startDate], item[endDate])}
  
              {item[city] ? ` ,  ${item[city]}` : ""}
              
            </Text>

            {item[description] && (
              <View
                style={{
                  marginTop: "4px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                }}
              >
              {console.log(dataKey, item[description])}

                {renderHTMLContent(item[description], {
                  "*": {
                    color: "#3f3f3f",
                    fontWeight: 500,
                    fontSize: "12px",
                    paddingRight: "5px",
                    alignItems: "baseline",
                    position: "sticky",
                    textAlign: "justify",
                  },
                })}
              </View>
            )}
          </View>
        ))}
      </View>
    );
};




const Template8 = ({ data }) => {
  const {
    profileImage,
    First_Name,
    Last_Name,
    Wanted_Job_Title,
    template_color_code,
    professionalSummary: { Description = "" } = {},
  } = data;
  return (
    <TemplatePage data={data}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={{
              position: "absolute",
              height: "100vh",
              width: "164px",
              left: "0px",
              top: "0px",

            }}
            fixed
          >
            <View
              style={{
                height: "100%",
                backgroundColor:template_color_code,
                width: "250px ",
              }}
            ></View>
          </View>
          <View style={styles.wrapper}>
            <View style={styles.left}>
              <View style={styles.profileImageWrapper}>
                <Image
                  src={profileImage || DummyProfilePic}
                  style={styles.profileImage}
                />
                <Text
                  style={{
                    fontWeight: 500,
                    fontFamily: "Roboto",
                    color: "#ffffff",
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  {First_Name} {Last_Name}
                </Text>
                <Text
                  style={{
                    fontWeight: 500,
                    fontFamily: "Roboto",
                    color: "#ffffff",
                    fontSize: "12px",
                    width: "150px",
                    textAlign:"center"
                  }}
                >
                  {Wanted_Job_Title}
                </Text>
              </View>
              <ContactInfo data={data} />
              <AdditionalInfo data={data} />
              <Skills data={data} />
              <Hobbies data={data} />
              <Languages data={data} />
            </View>
            <View style={styles.right}>
              <View>
                {!isEmpty(Description) && (
                  <View
                    style={{
                      marginTop: "12px",
                      // width: "200px",
                      marginLeft: "0px",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        top: "2px",
                        marginRight: "8px",
                        marginBottom: "4px",
                        color:template_color_code,
                      }}
                    >
                      Profile Summary
                    </Text>
                    <View
                      style={{
                        textTransform: "capitalize",
                        color: "#3f3f3f",
                        fontSize: "10px",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        fontWeight: 500,
                        textAlign:"justify",
                        paddingRight:"35px",
                      }}
                    >
                      {renderHTMLContent(Description)}
                    </View>
                  </View>
                )}

                {/* <Text style={{ fontWeight: 500, fontFamily: "Roboto" }}>
                  {First_Name} {Last_Name}
                </Text> */}
                {/*<Text
                  style={{
                    fontWeight: 300,
                    fontSize: "14px",
                    color: "#3f3f3f",
                    paddingLeft:"80px",
                    // marginTop: "1px",
                  }}
                >
                  {Wanted_Job_Title}
                </Text>*/}
              </View>
              <RenderView
                data={data}
                dataKey="employmentHistory"
                heading="WORK EXPERIENCE"
                fieldMap={{
                  subTitle: "Employer",
                  title: "Job_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "Description",
                }}
              />
              <RenderView
                data={data}
                dataKey="education"
                heading="EDUCATION"
                fieldMap={{
                  title: "institute_name",
                  subTitle: "degree",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "Description",
                }}
              />
              <RenderView
                data={data}
                dataKey="internships"
                heading="INTERNSHIPS"
                fieldMap={{
                  title: "employer_name",
                  subTitle: "job_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "description",
                }}
              />
              <RenderView
                data={data}
                dataKey="activities"
                heading="EXTRA_CURRICULAR_ACTIVITY"
                fieldMap={{
                  title: "employer_name",
                  subTitle: "function_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "description",
                }}
              />

              <RenderView
                data={data}
                dataKey="courses"
                heading="COURSES"
                fieldMap={{
                  title: "course_name",
                  subTitle: "institute_name",
                  startDate: "start_date",
                  endDate: "end_date",
                }}
              />
              <References
                data={data}
                dataKey="References"
                heading="REFERENCES"
                fieldMap={{
                  title: "ref_name",
                  subTitle: "company_name",
                  phoneNo: "phone_no",
                  emailId: "ref_email",
                }}
              />
              <CustomSection
              data={data}
                dataKey="customSection"
                //heading="custom_title"
                fieldMap={{
                  custom_title:"custom_title",
                  title: "section_name",
                  city: "city",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "description",
                }}
              />
            </View>
            <View
              style={{
                position: "absolute",
                height: "100vh",
                width: "0px",
                left: "236px",
                top: "-10px",
              }}
              fixed
              render={({ pageNumber }) => (
                <View
                  style={{
                    height: "100%",
                    backgroundColor:
                      pageNumber === 1 ? "transparent" : "#ffffff",
                  }}
                ></View>
              )}
            />
          </View>
        </Page>
      </Document>
    </TemplatePage>
  );
};

Template8.displayName = "Template8";
export default Template8;
