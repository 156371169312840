import React, { useMemo } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import { Badge, Col, Pagination, Row, Table } from "react-bootstrap";
import GlobalFilter from "components/elements/advance-table/GlobalFilter";

const ApplicationStatus = ({ data }) => {
  //   console.log(data);
  const columns = useMemo(
    () => [
      { accessor: "applicant_basic_detail_id", Header: "ID" },
      { accessor: "student_name", Header: "Student Name" },
      { accessor: "internship_details", Header: "Internship Name" },
      { accessor: "companyname", Header: "Company Name" },
      {
        accessor: "application_status",
        Header: "Status",
        Cell: ({ value, row }) => {
          return (
            <Badge
              bg={
                value === "Not Shortlisted"
                  ? "warning"
                  : value === "Hired/Offer Sent"
                  ? "success"
                  : value === "Rejected"
                  ? "danger"
                  : value === "Shortlisted"
                  ? "info"
                  : null
              }
              className="text-white"
            >
              {value}{" "}
            </Badge>
          );
        },
      },
    ],
    []
  );

  const statusData = useMemo(() => data, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: statusData || [],
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { pageIndex, globalFilter } = state;

  return (
    <div>
      <h1>Application Status</h1>
      <>
        <Row className="align-items-center">
          <Col md={8}>
            <div>
              <h2 className="mb-md-0 mb-3">College student list </h2>
            </div>
          </Col>
          <Col md={4}>
            <div className="pt-2">
              <GlobalFilter
                filter={globalFilter}
                setFilter={setGlobalFilter}
                placeholder="Search college student"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-6">
          <Col lg={12}>
            <Table
              hover
              responsive
              {...getTableProps()}
              className="text-nowrap table-centered"
            >
              <thead className="table-light">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  //console.log(row)
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            {/* Pagination @ Footer */}
            <Pagination
              previousPage={previousPage}
              pageCount={pageCount}
              pageIndex={pageIndex}
              gotoPage={gotoPage}
              nextPage={nextPage}
            />
          </Col>
        </Row>
      </>
    </div>
  );
};

export default ApplicationStatus;
