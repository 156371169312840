import React from 'react'
import { Button } from 'react-bootstrap'

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
    const nextPage = () => {
            if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    const prevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }
    return (
        <div className="d-flex justify-content-between">
                      <button className="btn btn-secondary" onClick={prevPage} >
                        <i className="fe fe-arrow-left"></i> Previous
                      </button>
                      <button className="btn btn-primary" onClick={nextPage}>
                        Next <i className="fe fe-arrow-right"></i>
                      </button>
                      {/* <Button>

                      </Button> */}
                    </div>
        
    )
}

export default Pagination