// import node module libraries
import React, { useContext } from 'react';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import { ListGroup, Badge } from 'react-bootstrap';

// import hook file
import useChatOperations from 'hooks/useChatOperations';

// import context file
import { ChatContext } from 'context/Context';

const UnreadMessagesList = (props) => {
	const { setHideChatBox } = props;

	// const {
	// 	ChatState: { threads, activeThread },
	// 	ChatDispatch
	// } = useContext(ChatContext);
	// const {
	// 	getUserDetails,
	// 	getLastMessageTime
	// } = useChatOperations();

	// const handleOpenChatThread = (item) => {
	// 	setHideChatBox(true);
	// 	ChatDispatch({ type: 'CHANGE_THREAD', payload: item });
	// };
	// const UnreadMessages = threads.filter(function (dataSource){ return dataSource.read === false; })
	return (
		<SimpleBar style={{ maxHeight: '650px' }}>
			<ListGroup bsPrefix="list-unstyled" as="ul" className="contacts-list">
							<ListGroup.Item
								as="li"
								bsPrefix=" "
								role="button"
								className={`py-3 px-4 chat-item contacts-item active`}
							>
								<div className="d-flex justify-content-between align-items-center"
									// onClick={() => handleOpenChatThread(item)}
								>
									<Link to="#" className="text-reset contacts-link">
										<div className="">
											<div className="d-flex align-items-center justify-content-between mb-1">
												<h4 className="mb-0">
													student name 
												</h4>
												<small>time</small>
											</div>
											<div>
												<p>Campus Ambassador</p>
												<p><small>Phone no.: 9876543210</small>
													<small className="ms-6">Email: email</small>

												</p>
												<div>
													<Badge
														bg="success"
														className="border text-bg-white"
														pill>
														rejected
													</Badge>

												</div>
											</div>
										</div>
									</Link>
								</div>
							</ListGroup.Item>
			</ListGroup>
		</SimpleBar>
	);
};
export default UnreadMessagesList;
