// import node module libraries
import React, { Fragment, useEffect, useState } from 'react';
import { Card, Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Select from "react-select";

import Url from "../Url";
// import profile layout wrapper
import ProfileLayoutWrap from '../marketing/account-settings/ProfileLayoutWrap';

const Unsubscribe = (props) => {
	const location = useLocation();
    const ut = props.match.params.ut;
	const emailHash = props.match.params.emailHash;

    console.log(props);
	
	const url1 = Url();
	const url = url1["url"];

	const [message,setMessage] = useState("");
	const [unsubscribeReasonOptions,setUnsubscribeReasonOptions] = useState([]);
	const [unsubscribeReasonId,setUnsubscribeReasonId] = useState();
	const [email, setEmail] = useState("");
	useEffect( () => {
		const apiURL = url + "getUnsubscribeReasons";
		fetch(apiURL, {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		})
		.then((apidata) => { 
			return apidata.json()
		})
		.then((actualdata) => {
			console.log(actualdata)
			setUnsubscribeReasonOptions(actualdata);
		})		

	},[]);

	const unsubscribeClicked = (e) => {

		if(!email){
			alert("Please enter email id");
			return 1;
		}
		const res = window.confirm("You are about to unsubscibe updates from internshipgate, are you sure?")
		if (res) {
			// console.log(id)
			const api = url + "unsubscribe";
			fetch(api, {
				method: 'POST',
				body: JSON.stringify({ut:ut,id:email,unsubscribeReasonId:unsubscribeReasonId ? unsubscribeReasonId : unsubscribeReasonOptions.findIndex(x => x.label === 'Others')}),
				headers: { 'Content-Type': 'application/json' }
			})
				.then((apidata) => { 
					return apidata.json()
				})
				.then((actualdata) => {
					console.log(actualdata)
					if(actualdata["message"] === "unsubscribed"){
						setMessage ("You have been unsubscibed from all the updates from internshipgate.com");
					}else{
						setMessage ("Some issue in unsubscribing, please send an email to info@internshipgate.com to unsubscribe");
					}

				})
		}
	};
    
	return (
        <Container className="p-4">
            <Row className="justify-content-md-center">
            <Col sm={6}>
			<Card className="border-0">
				{/* <Card.Header>
					<div className="mb-3 mb-lg-0">
						<h3 className="mb-0">Unsubscribe</h3>
					</div>
				</Card.Header> */}
				<Card.Body>
					{message ? 
					<p>
						{message}
					</p>
					:
					(
					<Fragment>
						<h1 className="text-danger">Unsubscribing from Internshipgate mailing list?</h1>
						<h4>We would be grateful if you could tell us why</h4>
						
                        <Form.Control className="mb-4"
                          type="text"
                          placeholder="email"
                          id="email"
                          name="email"
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />						
						<Select className="mb-4"
                            options={unsubscribeReasonOptions}
                            onChange={(e) => setUnsubscribeReasonId(e.value)}
                          />
						<Button variant="secondary" onClick={unsubscribeClicked} >Unsubscribe</Button>
					</Fragment>
					)
					}
					{/* <Link to="/" className="btn btn-outline-danger btn-sm">
						Close My Account
					</Link> */}
				</Card.Body>
			</Card>
            </Col>
            </Row>
            </Container>
        
	);
};

export default Unsubscribe;
