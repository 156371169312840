// import node module libraries
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Image, Card, Badge } from "react-bootstrap";
import "./ribbon.css";

// import custom components
import Ratings from "components/marketing/common/ratings/Ratings";

// import utility file
import { numberWithCommas } from "helper/utils";

import Url from "../Url"; //internshipgate import

const GetEnrolledCourseCard = ({ item }) => {
  // useEffect(() => {
  //   console.log("VI items : ", item);
  // }, []);

  const url1 = Url();
  const url = url1["url"];
  const s3URL = url1["s3"] + "courseImages/";
  
  const topSellingCourseIds = [3,20,1,18,7,24,12,28,11,27,14,29,4,21,42];

  const badgeBG = () => {
    switch (item.course_competency_level) {
      case 1:
        //'Beginner':
        return "info-soft";
      case 2:
        //'Intermediate':
        return "success-soft";
      case 3:
        //'Advance':
        return "danger-soft";
      default:
        return "primary-soft";
    }
  };

  return (
    <Card className="mb-4 card-hover position-relative">
      {topSellingCourseIds.includes(item.subject_id) ? (
        <span className="ribbonStyleCourse">
          {/* <div>Top</div>
        <div>Selling</div> */}
        </span>
      ) : null}
      {/* Card Image */}
      <Link to={"/" + item.generated_url} className="card-img-top">
        {/*<Image src={s3URL + item.preview_image} alt={item.preview_image_alt} className="card-img-top rounded-top-md" /> */}
        {/* <Image src={"https://picsum.photos/id/" + Math.round(Math.random()*100,0) + "/825/490"} alt=" " className="card-img-top rounded-top-md" /> */}
        {
          <Image
            src={s3URL + item.preview_image}
            alt={item.preview_image_alt}
            className="card-img-top rounded-top-md"
          />
        }
      </Link>
      {/* Card Body */}
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <Badge bg={badgeBG()}>
            {item.course_competency_level == 1
              ? "Beginner"
              : item.course_competency_level == 2
              ? "Intermediate"
              : item.course_competency_level == 3
              ? "Advance"
              : "Intermediate"}
          </Badge>
          {/* <span className="badge bg-info-soft">{item.level}</span> */}
          <Link to={"#"} className="text-muted fs-5">
            <i className="fe fe-heart align-middle"></i>
          </Link>
        </div>
        <h4 className="mb-2 text-truncate-line-2 ">
          <Link to={"/" + item.generated_url} className="text-inherit">
            {item.subject_name}
          </Link>
        </h4>
        {/*<small>By: {item.instructor_name}</small> */}
        <div className="lh-1 mt-3">
          <span className="text-warning me-1">
            <Ratings
              rating={
                item.star_rating ? numberWithCommas(item.star_rating) : 4.5
              }
            />
          </span>
          <span className="text-warning me-1">{item.star_rating}</span>
          <span className="fs-6 text-muted">({item.enrolled_numbers})</span>
        </div>
      </Card.Body>
      {/* Card Footer */}
      <Card.Footer>
        <div className="row align-items-center g-0">
          <div className="col">
            {/* <h5 className="mb-0">&#8377; <span style={{color:red }}>{item.discount_percent ? item.product_price : null }</span> {item.discount_percent ? Math.round(item.product_price * (100 -item.discount_percent)/100,0) : item.product_price } </h5> */}
            <h5 className="mb-0">
              &#8377;{item.course_amount}{" "}
              {item.discount_percent > 0 && (
                <span className="text-danger text-decoration-line-through small ">
                  &#8377;{item.course_mrp}{" "}
                </span>
              )}{" "}
              {item.discount_percent > 0 && (
                <span className="text-danger small">
                  (-{item.discount_percent}%)
                </span>
              )}
            </h5>
          </div>
          <div className="col-auto">
            <Link to={"/" + item.generated_url} className="text-inherit">
              <i className="fe fe-shopping-cart text-primary align-middle me-2"></i>
              Get Enrolled
            </Link>
          </div>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default GetEnrolledCourseCard;
