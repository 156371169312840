// import node module libraries
import { Col, Row, Container, Tab, Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { ChevronLeft, ChevronRight } from "react-feather";
// import custom components

import { Fragment, useState, useEffect, useRef } from "react";

// import sub components
import JobSearchBox from "./JobSearchBox";
import JobFilters from "./JobFilters";
import GridListViewButton from "components/elements/miscellaneous/GridListViewButton";
import JobsListView from "./JobsListView";
import JobsGridView from "./JobsGridView";
import CheckStudentProfileStatus from "components/student/CheckStudentProfileStatus";
import Url from "../Url";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginModalNew from "components/authentication/LoginModalNew";

let paramsLocation = [],
  paramsText = "";
const JobsList = ({ ...props }) => {
  //   console.log("props", props);
  let data = props.match?.params.data;
  if (data) {
    let idx = data.search("internships-in-");
    if (idx == -1) paramsText = data;
    else {
      let locate = data.substring(idx + 15);
      paramsLocation = [{ value: locate, label: locate }];
      if (idx != 0) paramsText = data.substring(0, idx - 1);
    }
  }

  const sortByOptions = [
    { value: "Latest", label: "Latest" },
    { value: "Oldest", label: "Oldest" },
  ];

  const [pageCount, setPageCount] = useState(0);
  const changePage = ({ selected }) => {
    console.log("changes", selected + 1);
    setCurrentPage(selected + 1);
  };
  const studentId = localStorage.getItem("studentId");
  const email = localStorage.getItem("email");
  // filter states starts

  const [locationFilter, setLocationFilter] = useState([...paramsLocation]);

  const [skillsFilter, setSkillsFilter] = useState([]);
  const [internshipDetailsFilter, setInternshipDetailsFilter] = useState([]);
  const [durationFilter, setDurationFilter] = useState("");
  const [startingfilter, setStartingfilter] = useState("all");
  const [stipendfilter, setStipendfilter] = useState("any");
  const [isWFH, setIsWFH] = useState(false);
  const [isIFW, setIsIFW] = useState(false);
  const [isFullTime, setIsFullTime] = useState(false);
  const [isPartTime, setIsPartTime] = useState(false);
  const [isIWJO, setIsIWJO] = useState(false);
  const [isOnField, setIsOnFiled] = useState(false);
  const [searchAnything, setSearchAnything] = useState(paramsText);
  const [sorting, setSorting] = useState("Latest");
  const [lastUpdated, setLastUpdated] = useState("all");
  const [loginModalShow, setLoginModalShow] = useState(false);
  // filter states end

  const [totalint, setTotalint] = useState("");
  const [postrec, setPostrec] = useState([]);
  const [totalpage, setTotalpage] = useState("");
  const [pageno, setPageno] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  // const [fav, setFav] = useState();
  const stutoken = localStorage.getItem("stutoken");

  const url1 = Url();
  const url = url1["url"];
  const s3 = url1["s3"];

  const fetchData = (isPageChanged) => {
    let apiToCall;
    let wfh = "";
    let skills = "";
    let location = "all-internships";
    let duration = "/all-duration";
    let startdate = "/starting-immidiately";
    let parttime = "/all";
    let fulltime = "/all";
    let stipend = "/all";
    let forwomen = "/all";
    let withjoboffers = "/all";
    let onfieldoffice = "/all";
    let internshipDetails = "/all";

    if (searchAnything.length == 0) {
      if (locationFilter.length > 0) {
        location = "internships-in-";
        location =
          location + locationFilter.map((item) => item.value).join(",");
      }
      if (skillsFilter.length > 0) {
        let skill = skillsFilter.map((item) => item.value).join(",");
        location = skill + "-" + location;
      }
      
      

      if (isWFH) location = "work-from-home-" + location;
      if (durationFilter.length > 0) duration = "/duration-" + durationFilter;
      if (internshipDetailsFilter) internshipDetails = "/" + internshipDetailsFilter.value;
      if (isIFW) forwomen = "/internships-for-women";
      if (isPartTime) parttime = "/part-time-internship ";
      if (isFullTime) fulltime = "/part-time-internship ";
      if (stipendfilter.length > 0) stipend = "/stipend-" + stipendfilter;
      if (isIWJO) withjoboffers = "/with-job-offer";
      if (isOnField) onfieldoffice = "/onfield-office ";
    }

    apiToCall =
      url +
      "search-internships/" +
      wfh +
      skills +
      location +
      duration +
      startdate +
      forwomen +
      parttime +
      fulltime +
      stipend +
      withjoboffers +
      onfieldoffice +
      internshipDetails;
    // lastUpdatedParams
    if (searchAnything.length > 0) {
      apiToCall += "/" + searchAnything;
    } else apiToCall += "/all";
    // for last update
    apiToCall += `/${lastUpdated}`;
    if (sorting != "Latest") apiToCall += "/" + sorting;

    if (isPageChanged) apiToCall += "?page=" + currentPage;

    fetch(apiToCall, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        setTotalint(actualdata1.total);

        setPostrec(actualdata1.data);
        //console.log("alldata", actualdata1);

        setTotalpage(actualdata1.last_page);
        setCurrentPage(actualdata1.current_page);
        // setting page count
        setPageCount(actualdata1.last_page);
        window.scrollTo(0, 0);
      });
  };
  const Apply = (internshipId) => {
    if (stutoken) {
      var studentId = localStorage.getItem("studentId");
      const api = url + "applyForInternship";
      fetch(api, {
        method: "POST",
        body: JSON.stringify({ internshipId, studentId }),
        headers: { "Content-Type": "application/json" },
      })
        .then((apidata) => {
          return apidata.json();
        })
        .then((actualdata) => {
          if (actualdata["message"] === "No_software_skill") {
            // setStusftskill(true);
            toast.warning("No software skill. Please add");
          }
          if (actualdata["message"] === "Already_applied") {
            toast.warning("Already Applied");
            return;
          }
          if (actualdata["message"] === "student_internship created") {
            // setApplysuccessmodal(true);
            toast.success("You have successfully applied to this internship");
          }
        });
    } else {
      toast.error("To apply for an internship, please login first", {
        autoClose: 5000,
      });
      localStorage.setItem("internshipIdApplication", internshipId);
      //window.location.href = "/student-login";
      setLoginModalShow(true);
    }
  };
  useEffect(() => {
    paramsText = "";
    setCurrentPage(1);
    fetchData(false);
  }, [
    locationFilter,
    skillsFilter,
    durationFilter,
    startingfilter,
    stipendfilter,
    isWFH,
    isIFW,
    isFullTime,
    isPartTime,
    isIWJO,
    isOnField,
    searchAnything,
    sorting,
    lastUpdated,
    internshipDetailsFilter,
  ]);
  useEffect(() => {
    // if (currentPage != 1) fetchData(true);
    // else
    fetchData(true);
  }, [currentPage]);

  return (
    <>
      <ToastContainer />
      <main>
        <LoginModalNew
          show={loginModalShow}
          setModalShow={setLoginModalShow}
          onHide={() => setLoginModalShow(false)}
          activeTab={"student"}
        />
        <PageSeoSettings parameters={data} url={url} />
        <section className="py-4 bg-light">
          <Container>
            <CheckStudentProfileStatus staticModal={true} />
            <Row>
              <Col lg={8} md={10} xs={12}>
                <div>
                  <div className="mb-4">
                    {paramsText ? (
                      <h1 className=" fw-bold mb-4">
                        Showing internships for
                        <span className="text-primary"> {paramsText}</span> in
                        India
                      </h1>
                    ) : (
                      <h1 className=" fw-bold mb-4">
                        Showing
                        <span className="text-primary"> All</span> internships
                        in India
                      </h1>
                    )}
                  </div>
                  <JobSearchBox
                    searchAnything={searchAnything}
                    setSearchAnything={setSearchAnything}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-4 bg-white">
          <Container>
            <Row>
              <Col md={4} xl={3}>
                <JobFilters
                  locationFilter={locationFilter}
                  setLocationFilter={setLocationFilter}
                  skillsFilter={skillsFilter}
                  setSkillsFilter={setSkillsFilter}
                  durationFilter={durationFilter}
                  setDurationFilter={setDurationFilter}
                  internshipDetailsFilter={internshipDetailsFilter}
                  setInternshipDetailsFilter={setInternshipDetailsFilter}
                  startingfilter={startingfilter}
                  setStartingfilter={setStartingfilter}
                  stipendfilter={stipendfilter}
                  setStipendfilter={setStipendfilter}
                  isWFH={isWFH}
                  setIsWFH={setIsWFH}
                  isIFW={isIFW}
                  setIsIFW={setIsIFW}
                  isFullTime={isFullTime}
                  setIsFullTime={setIsFullTime}
                  isPartTime={isPartTime}
                  setIsPartTime={setIsPartTime}
                  isIWJO={isIWJO}
                  setIsIWJO={setIsIWJO}
                  isOnField={isOnField}
                  setIsOnFiled={setIsOnFiled}
                  searchAnything={searchAnything}
                  setSearchAnything={setSearchAnything}
                  lastUpdated={lastUpdated}
                  setLastUpdated={setLastUpdated}
                />
              </Col>
              <Col xl={9} md={8} className="mb-6 mb-md-0">
                <Tab.Container defaultActiveKey="grid">
                  <Row className="row align-items-center mb-4">
                    <Col xs>
                      <p className="mb-0">Showing {totalint} internship</p>
                    </Col>
                    <Col className="col-auto">
                      <div className="d-flex ">
                        <GridListViewButton keyGrid="grid" keyList="list" />
                        <Form.Select
                          //options={sortByOptions}
                          // placeholder="Sorting"
                          value={sorting}
                          onChange={(e) => {
                            setSorting(e.target.value);
                          }}
                        >
                          {sortByOptions.map((item, index) => (
                            <option value={item.value} key={index}>
                              {item.label}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Col>
                  </Row>
                  <Tab.Content>
                    <Tab.Pane
                      eventKey="list"
                      className="pb-4 px-0 react-code"
                      mountOnEnter={true}
                      unmountOnExit={true}
                    >
                      <JobsListView postrec={postrec} Apply={Apply} />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="grid"
                      className="pb-4 px-0"
                      mountOnEnter={true}
                      this
                      unmountOnExit={true}
                    >
                      <JobsGridView postrec={postrec} Apply={Apply} />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
                <ReactPaginate
                  previousLabel={<ChevronLeft size="10px" />}
                  nextLabel={<ChevronRight size="10px" />}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"justify-content-center mb-0 pagination"}
                  previousLinkClassName={"page-link mx-1 rounded"}
                  nextLinkClassName={"page-link mx-1 rounded"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link mx-1 rounded"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"active"}
                  forcePage={currentPage - 1}
                />
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
};

const PageSeoSettings = (props) => {
  const { parameters, url } = props;
  const [seodetails, setSeodetails] = useState("");
  useEffect(() => {
    /*********** get page SEO Settings */

    var api = url + "getPageSeoSettingById/2";
    fetch(api, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        setSeodetails(actualdata1);
      });
  }, []);

  return (
    <Fragment>
      {/* {console.log(parameters)}
      {console.log(url)} */}

      {parameters === "Internships" ? (
        <Helmet>
          <title>{seodetails.page_title}</title>
          <meta name="keywords" content={seodetails.page_meta_keyword} />
          <meta name="description" content={seodetails.page_meta_description} />
        </Helmet>
      ) : parameters === "internship-in-bangalore" ? (
        <Helmet>
          <title>Internship in Bangalore | Internship | Internshipgate</title>
          <meta
            name="description"
            content="Find the best summer internships in Bangalore through our Virtual Internship in Engineering, MBA and other streams in 2021. Apply now."
          />
        </Helmet>
      ) : parameters === "internship-in-pune" ? (
        <Helmet>
          <title>Internship in Pune | Internship | Internshipgate</title>
          <meta
            name="description"
            content="Find the best summer internships in Pune through our Virtual Internship in Engineering, MBA and other streams in 2021. Apply now."
          />
        </Helmet>
      ) : parameters === "internship-in-mumbai" ? (
        <Helmet>
          <title>Internship in Mumbai | Internship | Internshipgate</title>
          <meta
            name="description"
            content="Find the best summer internships in Mumbai through our Virtual Internship in Engineering, MBA and other streams in 2021. Apply now."
          />
        </Helmet>
      ) : parameters === "internship-in-delhi" ? (
        <Helmet>
          <title>Internship in Delhi | Internship | Internshipgate</title>
          <meta
            name="description"
            content="Find the best summer internships in Delhi through our Virtual Internship in Engineering, MBA and other streams in 2021. Apply now."
          />
        </Helmet>
      ) : parameters === "internship-in-hyderabad" ? (
        <Helmet>
          <title>Internship in Hyderabad | Internship | Internshipgate</title>
          <meta
            name="description"
            content="Find the best summer internships in Hyderabad through our Virtual Internship in Engineering, MBA and other streams in 2021. Apply now."
          />
        </Helmet>
      ) : parameters === "internship-in-kolkata" ? (
        <Helmet>
          <title>Internship in Kolkata | Internship | Internshipgate</title>
          <meta
            name="description"
            content="Find the best summer internships in Kolkata through our Virtual Internship in Engineering, MBA and other streams in 2021. Apply now."
          />
        </Helmet>
      ) : parameters === "design-internship" ? (
        <Helmet>
          <title>Design-Internship | Internshipgate</title>
          <meta
            name="description"
            content="Find the best summer internships in Design through our Virtual Internship in Engineering, MBA and other streams in 2021. Apply now."
          />
        </Helmet>
      ) : parameters === "Business Analytics-internship" ? (
        <Helmet>
          <title>Business Analytics-internship | Internshipgate</title>
          <meta
            name="description"
            content="Find the best summer internships in Business Analytics through our Virtual Internship in Engineering, MBA and other streams in 2021. Apply now."
          />
        </Helmet>
      ) : parameters === "Digital Marketing-internship" ? (
        <Helmet>
          <title>Digital Marketing-Internship | Internshipgate</title>
          <meta
            name="description"
            content="Find the best summer internships in Digital Marketing through our Virtual Internship in Engineering, MBA and other streams in 2021. Apply now."
          />
        </Helmet>
      ) : parameters === "Finance-internship" ? (
        <Helmet>
          <title>Finance-Internship | Internshipgate</title>
          <meta
            name="description"
            content="Find the best summer internships in Finance through our Virtual Internship in Engineering, MBA and other streams in 2021. Apply now."
          />
        </Helmet>
      ) : parameters === "Content Writing-internship" ? (
        <Helmet>
          <title>Content Writing-Internship | Internshipgate</title>
          <meta
            name="description"
            content="Find the best summer internships in Content Writing through our Virtual Internship in Engineering, MBA and other streams in 2021. Apply now."
          />
        </Helmet>
      ) : parameters === "Marketing-internship" ? (
        <Helmet>
          <title>Marketing-Internship | Internshipgate</title>
          <meta
            name="description"
            content="Find the best summer internships in Marketing through our Virtual Internship in Engineering, MBA and other streams in 2021. Apply now."
          />
        </Helmet>
      ) : parameters === "Internships_in" ? (
        <Helmet>
          <title>
            Internship | Summer Internship 2021 | Internship in India
          </title>
          <meta
            name="description"
            content="Internshipgate is the best internship and online training platform for students with  paid or unpaid internships in Engineering, MBA, and other streams."
          />
        </Helmet>
      ) : (
        <Helmet>
          <title>{seodetails.page_title}</title>
          <meta name="keywords" content={seodetails.page_meta_keyword} />
          <meta name="description" content={seodetails.page_meta_description} />
        </Helmet>
      )}
    </Fragment>
  );
};

export default JobsList;
