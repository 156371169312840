// import node module libraries
import ReactPaginate from "react-paginate";
import { Fragment, useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { Col, Row, Alert } from "react-bootstrap";

// import sub components
import JobListingGridviewCard from "./JobListingGridviewCard";
import Url from "components/Url";
import { getTimePassed } from "../../../helper/utils.js";

const JobsGridView = ({ postrec, Apply, share }) => {
  const [fav, setFav] = useState(new Set());
  const url1 = Url();
  const url = url1["url"];
  const studentId = localStorage.getItem("studentId");

  const Favorite = (employerInternshipId) => {
    if (!studentId) {
      alert("Please log in first");
      return;
    }
    const api = url + "addRemoveFavoriteInternship";

    fetch(api, {
      method: "POST",
      body: JSON.stringify({ employerInternshipId, studentId }),
      headers: { "Content-Type": "application/json" },
    })
      .then((apidata) => {
        return apidata.json();
      })
      .then((actualdata) => {
        if (actualdata["favorite_ind"] == 1) {
          let st = new Set();
          fav.forEach((ele) => st.add(ele));
          st.add(employerInternshipId);
          setFav(st);
          alert("Added to Favorites");
        }
        if (actualdata["favorite_ind"] == 0) {
          console.log("already");
          let st = new Set();
          fav.forEach((ele) => {
            if (employerInternshipId != ele) st.add(ele);
          });

          setFav(st);
          alert("Removed from Favorites");
        }
      });
  };
  // get the favourite post details
  useEffect(() => {
    const controller = "getStudentFavoriteInternships/" + studentId;
    const api = url + controller;
    fetch(api, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        // console.log("inside fav", actualdata1);
        let favStore = new Set();
        actualdata1.forEach((ele) => {
          favStore.add(ele);
        });
        // console.log(favStore, "store");
        setFav(favStore);
      });
  }, []);
  return (
    <Fragment>
      <Row>
        {postrec.length > 0 ? (
          postrec.map((record, index) => {
            return (
              <Col lg={6} xs={12} className="mb-4" key={index}>
                <JobListingGridviewCard
                  item={record}
                  key={index}
                  fav={fav}
                  setFav={setFav}
                  Favorite={Favorite}
                  Apply={Apply}
                  share={share}
                  getTimePassed={getTimePassed}
                />
              </Col>
            );
          })
        ) : (
          <Col>No matching records found.</Col>
        )}
      </Row>
    </Fragment>
  );
};

export default JobsGridView;
