// import node module libraries
import React, {
    Fragment,
    useEffect,
    useState,
    useRef,
  } from "react";

  import { Modal,Spinner } from "react-bootstrap";
  import { Link } from "react-router-dom";
  import * as XLSX from 'xlsx';
  //import Select from 'react-select';
  
  import LinkIcon from "react-feather/dist/icons/link";
  import ImageIcon from "react-feather/dist/icons/image";
  import { Trash, XCircle, Copy, Video } from "react-feather";
  import {
    Col,
    Row,
    Breadcrumb,
    Card,
    Button,
    Form,
    InputGroup,
    FormControl,
    ListGroup,
    Image,
    Div,
    Badge,
    Toast,
  } from "react-bootstrap";
  
  // import custom components
  import { FlatPickr } from "components/elements/flat-pickr/FlatPickr";
  import { FormSelect } from "components/elements/form-select/FormSelect";
  import { DropFiles } from "components/elements/dropfiles/DropFiles";
  import DotBadge from "components/elements/bootstrap/DotBadge";
  //import ReactQuillEditor from 'components/elements/editor/ReactQuillEditor';
  import ReactQuillEditor from "react-quill";
  import moment from "moment";

  // import media files
  import Avatar1 from "assets/images/avatar/avatar-1.jpg";
  
  import Url from "../../Url";
  
  const EmailTemplate = (props) => {
    const url1 = Url();
    const url = url1["url"]; 
    const s3 = url1["s3"];
    const [emailTemplate, setEmailTemplate] = useState([]);
    const id = props.match.params.id;

    const handleClose = () => setShowSampleModal(false);
    const handleShow = (e) => 
      {e.preventDefault; setShowSampleModal(true);}
    const [showSampleModal,setShowSampleModal] = useState(false);

    const [emailJobStats, setEmailJobStats] = useState([]);

    const [show, setShow] = useState(false);
    const [fetchAgain, setFetchAgain] = useState(false)
    const [apiMessage, setAPIMessage] = useState("");
    
    const userId = localStorage.getItem("adminid");
    //const [currentDate, setCurrentDate] = useState(new Date());
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    const [formData, setFormData] = useState({
        email_subject: null,
        email_addresses: null,
        email_message: null,
        user_id: userId
    });
    
    const  modules  = {
      toolbar: [
          [{ 'size': ['small', false, 'large', 'huge'] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ script:  "sub" }, { script:  "super" }],
          ["blockquote", "code-block"],
          [{ list:  "ordered" }, { list:  "bullet" }],
          [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
          ["link", "image", "video"],
          ["clean"],
      ],
    };
    
    //console.log(formData);
    useEffect(() => {
      const toDate = moment();
      
      setToDate(toDate.format("YYYY-MM-DD"));
      setFromDate(toDate.clone().subtract(1, 'years').format("YYYY-MM-DD")) ;

      //setFormData({ ...formData,from_date:fromDate,to_date:toDate });
    },[]);

    useEffect(() => {
    
        if (!id) {
          setFormData( [] );
          return;
        }
        const api = url + "getEmailTemplateById/" + id;
    
        fetch(api, {
          method: "GET",
        })
          .then((apidata) => {
            return apidata.json();
          })
          .then((actualdata) => {
            //console.log(actualdata);
            if (actualdata.emailTemplate.length > 0) {
              setEmailTemplate(actualdata.emailTemplate[0]);
              setFetchAgain(false);
            }
            console.log(fromDate + toDate);
            setFormData({ ...actualdata.emailTemplate[0] });
          });
          getJobStats();

      }, [fetchAgain]);
  
    // Form submitting logic, prevent default page refresh
    const handleSubmit = (event) => {
      event.preventDefault();
      console.log(userId);

      //THIS api will save the pr_bulk_email record and then trigger the email job
      const api = url + "updateEmailTemplate";
      //console.log(postData);
      //return ;
      fetch(api, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: { "Content-Type": "application/json" },
      })
        .then((apidata) => {
          return apidata.json();
        })
        .then((apiDataJson) => {
          console.log(apiDataJson);
          window.scrollTo(0, 0);
          setShow(true);
          setFetchAgain(true);
          setAPIMessage(apiDataJson.message);

        });
    };
  
    // Form submitting logic, prevent default page refresh
    const getJobStats = () => {
      
      const api = url + "getBulkEmailJobStats/" + id;
      fetch(api, {
        method: "GET",
      })
        .then((apidata) => {
          return apidata.json();
        })
        .then((apiDataJson) => {
          console.log(apiDataJson);
          setShow(true);
          setEmailJobStats(apiDataJson.emailJobStats[0]);

        });
    };

    const handleChange = (event) => {
      //setFormData({...formData, [event.target.name]:event.target.value} );
      //setFormData( {[event.target.name]:event.target.value} );
  
      const { name, value } = event.target;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
  
    const handleFromDateChange = (e) => {
      setFormData((prevState) => ({
        ...prevState,
        from_date: e.target.value,
      }));
    };
    const handleToDateChange = (e) => {

      setFormData((prevState) => ({
        ...prevState,
        to_date: e.target.value,
      }));
    };

    const handleAllCheckbox = (e) => {
      if(e.target.checked){
        //setFromDate(null);
        //setToDate(null);
        setFormData((prevState) => ({
          ...prevState,
          from_date: "",
          to_date: "",
          email_addresses: "",
          send_to_all:1
        }));
      }else{
        setFormData((prevState) => ({
          ...prevState,
          from_date: fromDate,
          to_date: toDate,
          send_to_all:0
        }));
      }
    };

    const handleEditorChange = (content) => {
      const { name, value } = { name: "email_message", value: content };
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
  
      /*
          setFormData({ ...formData, element_description: content });
          console.log(content);
          */
    };
  
    const handleBulkEmail = (event) => {
      const emailTemplateId = id;
      const api = url + "sendBulkEmailByTemplate";

      fetch(api, {
        method: "POST",
        body: JSON.stringify({
          emailTemplateId
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((apidata) => {
          return apidata.json();
        })
        .then((apiDataJson) => {
          console.log(apiDataJson);
          window.scrollTo(0, 0);
          setShow(true);
          //setFetchAgain(true);
          setAPIMessage(apiDataJson.message);

        });
    };


    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                console.log(json);
                var emailString = json.map(function(val) {
                  return val.Email.trim()
                }).join(',');
                const { name, value } = {name: "email_addresses", value: emailString};
                setFormData((prevState) => ({
                  ...prevState,
                  [name]: value,
                }));

            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }

    return (
      <Fragment>
        <Row>
          {console.log(formData)}
          <Col lg={12} md={12} sm={12}>
            <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
              <div className="mb-3 mb-md-0">
                <h1 className="mb-1 h2 fw-bold">
                  {id ? "Edit Email Template" : "Add New Email Template"}
                </h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item href="/bulkemail/all-templates">Email Templates</Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {id ? "Edit Email Template" : "Add New Email Template"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <Toast
                bg="success"
                onClose={() => setShow(false)}
                show={show}
                delay={2000}
                autohide
              >
                <Toast.Header>
                  <img
                    src="holder.js/20x20?text=%20"
                    className="rounded me-2"
                    alt=""
                  />
                  <strong className="me-auto text-success ">Success</strong>
                </Toast.Header>
                <Toast.Body className="text-white">
                  {apiMessage}
                </Toast.Body>
              </Toast>
              <div>
                <Link to="/bulkemail/all-templates" className="btn btn-outline-primary btn-sm">
                  Back to All Templates
                </Link>
              </div>
            </div>
          </Col>
        </Row>
  
        <Row>
          <Col xl={9} lg={8} md={12} sm={12}>
            <Card>
              <Card.Header>
                <h4 className="mb-0">{id ? "Edit Email Template" : "Add Email Template"}</h4>
              </Card.Header>
              <Card.Body>
                {/*  Form  */}
                <Form className="mt-1">
                  <Row>

                    <Col lg={12} md={12} sm={12}>
                      {/*   Email Subject  */}
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="postTitle">Email Subject</Form.Label>
                        <Form.Control
                          name="email_subject"
                          type="text"
                          value={formData.email_subject || ""}
                          placeholder="Email Subject"
                          id="email_subject"
                          maxLength="500"
                          minLength="20"
                          required
                          onChange={handleChange}
                        />
                        <Form.Text className="text-muted">
                          Field must contain a unique value. Max 500 characters.
                        </Form.Text>
                      </Form.Group>
                      {/* Student Registration start date and end date (By default 1 year students will be selected) */}
                      <div className="col-12 d-flex mb-4">
                        <div className="col-5 col-sm-3 d-flex">
                          <Form.Group>
                          <Form.Label htmlFor="postTitle">Registration From Date</Form.Label>
                            <Form.Control
                              type="date"
                              size="sm"
                              placeholder="Registratio From date"
                              className="expected-y"
                              onFocus={(e) => e.target.select()}
                              defaultValue={fromDate}
                              value={formData.from_date}
                              onChange={handleFromDateChange}
                              required
                            />
                          </Form.Group>
                        </div>
                        <div className="Col-5 col-sm-4 d-flex">
                          <Form.Group>
                          <Form.Label htmlFor="postTitle">To Date</Form.Label>
                            <Form.Control
                              type="date"
                              size="sm"
                              placeholder="Registratio From date"
                              className="expected-y"
                              onFocus={(e) => e.target.select()}
                              defaultValue={toDate}
                              value={formData.to_date}
                              onChange={handleToDateChange}
                              required
                            />
                          </Form.Group>
                        </div>             
                        <div className="Col-2 col-sm-4 d-flex">
                          <Form.Group>
                          <Form.Label htmlFor="postTitle">All Students</Form.Label>
                          <Form.Check
                              type="checkbox"
                              defaultChecked={false}
                              size="sm"
                              checked={formData.send_to_all}
                              placeholder="Registratio From date"
                              className="expected-y"
                              onChange={handleAllCheckbox}
                            />
                          </Form.Group>
                        </div>                                      
                      </div>

                      {/*   Email Addresses  */}
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="postTitle">Email Addresses</Form.Label>
                        <Form.Text className="text-muted">
                          (Add Comma delimited Email Addresses.)
                        </Form.Text>                        
                        <Form.Control
                          name="email_addresses"
                          as="textarea"
                          rows={6}
                          value={formData.email_addresses || ""}
                          placeholder="Comma Delimited Email Addresses"
                          id="email_addresses"
                          onChange={handleChange}
                        />

                        <Row>
                        <label className="pt-2" htmlFor="upload">Or select Excel Sheet containing Emails</label>
                        <input
                            className="pt-2"
                            type="file"
                            accept="application/excel, .xlsx"
                            name="upload"
                            id="upload"
                            onChange={readUploadFile}
                        /> 
                        <div className="pt-2">
                          Make sure that:
                          <ul>
                            <li>All the emails must be listed in the first column only in the excel sheet (Column A)</li>
                            <li>First cell in the list is a header and must containt value "Email"</li>
                            <li><a onClick={handleShow} style={{cursor: 'pointer'}}>Click here to see sample</a></li>
                          </ul>
                        </div>  
                        </Row>                     
                      </Form.Group>  

                      {/* Editor */}
                      {/*console.log(formData.element_description)*/}
    
                        <Form.Group className="mb-3">
                            <ReactQuillEditor
                            modules={modules}
                            name="email_message"
                            //id="element_description"
                            value={formData.email_message}
                            onChange={handleEditorChange}
                            required
                            />
                        </Form.Group>

                    </Col>
  
                    <Col lg={12} md={12} sm={12}>
                      {/* button */}
                      <Form.Group className="mb-3">
                        <Button
                          onClick={handleSubmit}
                          variant="primary"
                          className="m-1"
                        >
                          Save
                        </Button>
                        {/* <Button variant="outline-white">Save to Draft</Button> */}
                        <Button
                          onClick={handleBulkEmail}
                          variant="primary"
                          className="ms-3 m-1"
                        >
                          Send Bulk Email
                        </Button>                        
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
            <Card className="mt-4">
              <Card.Header className="d-flex">
                <h4 className="mb-0">Last Bulk Email Job Status</h4>
                <Col><div className="mb-3 d-flex justify-content-end">
                  <Button className="justify-content-end btn-sm" variant="outline-primary" onClick={getJobStats}>Refresh</Button>
                </div>
                </Col>
              </Card.Header>
              <Card.Body>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="selectDate">
                      Last Email sent at: { emailJobStats.last_email_sent_at }
                    </Form.Label>
                </Form.Group>                
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="selectDate">
                      Current Status: {(emailJobStats.bulk_email_job_status == 1 ? <>Running... <Spinner size="sm" /></> : emailJobStats.bulk_email_job_status == 2 ? "Finished" : "Not Started") }
                    </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="selectDate">
                   Total Emails in the batch: {emailJobStats.no_of_emails}
                  </Form.Label>
                </Form.Group>   
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="selectDate">
                   No. Of emails processed: {emailJobStats.no_of_emails_processed}
                  </Form.Label>
                </Form.Group>    
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="selectDate">
                   Last Process Message: {emailJobStats.job_return_message}
                  </Form.Label>
                </Form.Group>                                              
              </Card.Body>
            </Card>          
          </Col>
          <Modal show={showSampleModal} onHide={handleClose} size="md">
            <Modal.Header closeButton>
                <Modal.Title>Sample Excel sheet</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Col>
                  <Image src="https://internshipgates3.s3.ap-south-1.amazonaws.com/imgupload/EmailFormat.png" fluid alt="Excel sample"></Image>
                </Col>
            </Modal.Body>

          </Modal>
        </Row>
      </Fragment>
    );
  };
  
  export default EmailTemplate;
  