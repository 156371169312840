// src/VICourseProject.js
import React, { useState } from 'react';
import { Container, Form, Button, Spinner, Row, Col } from 'react-bootstrap';
import Api from 'Api';

const VICourseProject = () => {
  const [subject, setSubject] = useState('');
  const [difficulty, setDifficulty] = useState('');
  const [openAPIResult, setOpenAPIResult] = useState('');
  const [geminiProResult, setGeminiProResult] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleDifficultyChange = (e) => {
    setDifficulty(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setGeminiProResult('');
    setOpenAPIResult('');
    try {
      const response = await Api.post('http://127.0.0.1:8000/api/suggestProjects', {
        subject,
        difficulty_level: difficulty,
      });
      setOpenAPIResult(response.data.html);

    } catch (error) {
      console.error('Error fetching project suggestions:', error);
      setOpenAPIResult('An error occurred while fetching project suggestions.');
    }finally {
        setLoading(false);
      }

    // try {
    //   const response1 = await Api.post('http://127.0.0.1:8000/api/suggestProjectsV1', {
    //   subject,
    //   difficulty_level: difficulty,
    // });
    //   setGeminiProResult(response1.data.html);
    // } catch (error) {
    //   console.error('Error fetching project suggestions:', error);
    //   setGeminiProResult('An error occurred while fetching project suggestions.');
    // } finally {
    //   setLoading(false);
    // }
  };

  return (
    <Container className="py-5">
      <Row className="justify-content-md-center">
        <Col md={8}>
          <h1>Project Suggestions</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formSubject">
              <Form.Label>Subject</Form.Label>
              <Form.Control as="select" value={subject} onChange={handleSubjectChange}>
                <option value="">Select a subject</option>
                <option value="AWS Cloud">AWS Cloud</option>
                <option value="Python">Python</option>
                <option value="Azure">Azure</option>
                <option value="Big Data">Big Data</option>
                <option value="Blockchain">Blockchain</option>
                <option value="Digital Marketing">Digital Marketing</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formDifficulty" className="mt-3">
              <Form.Label>Difficulty Level</Form.Label>
              <Form.Control as="select" value={difficulty} onChange={handleDifficultyChange}>
                <option value="">Select difficulty level</option>
                <option value="Beginner">Beginner</option>
                <option value="Intermediate">Intermediate</option>
                <option value="Advanced">Advanced</option>
              </Form.Control>
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-4">
              {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Get Suggestions'}
            </Button>
          </Form>

          {loading ? (
            <div className="mt-3">Loading...</div>
          ) : (
            <>
              <Col md={12}>
                <Row>
                  <div className="col-12 mt-3 result" dangerouslySetInnerHTML={{ __html: openAPIResult }}></div>
                  {/* <div className="col-6 mt-3 result" dangerouslySetInnerHTML={{ __html: geminiProResult }}></div> */}
                </Row>
              </Col>
            </>

          )}
        </Col>
      </Row>
    </Container>
  );
};

export default VICourseProject;
