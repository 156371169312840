// import node module libraries
import { useState } from "react";
//import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { ChevronDown, ChevronUp } from "react-feather";
import { Form, Card, Row, Col } from "react-bootstrap";

// import sub components
// import Ratings from 'components/marketing/common/ratings/Ratings';

const FilterOptions = ({ categoryOptions, onData }) => {
  // console.log("Inside FilterOptions", categoryOptions);
  // var filterValues = []

  const [showCategories, setShowCategories] = useState(
    window.innerWidth <= 500 ? false : true
  );
  const [showCertification, setShowCertification] = useState(
    window.innerWidth <= 500 ? false : true
  );
  const [showDifficulty, setShowDifficulty] = useState(
    window.innerWidth <= 500 ? false : true
  );
  const [showProvider, setShowProvider] = useState(
    window.innerWidth <= 500 ? false : true
  );
  // const [showCourseLength, setShowCourseLength] = useState(false);

  const [selectedLevels, setSelectedLevels] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCourseProvider, setSelectedCourseProvider] = useState([]);
  const [selectedCertification, setSelectedCertification] = useState([]);
  const [selectedCourseLength, setSelectedCourseLength] = useState([]);

  const handleLevelChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      // console.log("Checked");
      setSelectedLevels([...selectedLevels, value]);
    } else {
      // console.log("UnChecked");
      setSelectedLevels(selectedLevels.filter((item) => item !== value));
      // console.log("After Unchecked", filterValues);
    }
  };

  const handleCategoryChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      // console.log("Checked");
      setSelectedCategories([...selectedCategories, value]);
    } else {
      // console.log("UnChecked");
      // filterValues = selectedCheckboxes
      // filterValues.splice(selectedCheckboxes.indexOf(value), 1);
      setSelectedCategories(
        selectedCategories.filter((item) => item !== value)
      );
      // console.log("After Unchecked", filterValues);
    }
  };

  const handleCourseProviderChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      // console.log("Checked");
      setSelectedCourseProvider([...selectedCourseProvider, value]);
    } else {
      // console.log("UnChecked");
      setSelectedCourseProvider(
        selectedCourseProvider.filter((item) => item !== value)
      );
      // console.log("After Unchecked", filterValues);
    }
  };
  const handleCertificationChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      // console.log("Checked");
      setSelectedCertification([...selectedCertification, value]);
    } else {
      // console.log("UnChecked");
      setSelectedCertification(
        selectedCertification.filter((item) => item !== value)
      );
      // console.log("After Unchecked", filterValues);
    }
  };

  const handleCourseLengthChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      // console.log("Checked");
      setSelectedCourseLength([...selectedCourseLength, value]);
    } else {
      // console.log("UnChecked");
      setSelectedCourseLength(
        selectedCourseLength.filter((item) => item !== value)
      );
      // console.log("After Unchecked", filterValues);
    }
  };

  const level_filter = selectedLevels;
  const category_filter = selectedCategories;
  const courseProvider_filter = selectedCourseProvider;
  const certification_filter = selectedCertification;
  const courseLength_filter = selectedCourseLength;

  // console.log("COURSE LENGTH : ", courseLength_filter);

  onData(
    level_filter,
    category_filter,
    courseProvider_filter,
    certification_filter,
    courseLength_filter
  );
  // console.log(selectedLevels);

  return (
    <Card>
      {/* Card header */}
      <Card.Header>
        <h4 className="mb-0">Filter</h4>
      </Card.Header>
      {/* Card body */}
      <Card.Body>
        <Col
          sm="9"
          className="cate mb-2 d-flex justify-content-between"
          onClick={() => setShowCategories(!showCategories)}
        >
          <div className="m-0 mt-1">CATEGORIES</div>
          <div className="m-0">
            {showCategories ? (
              <ChevronUp size={20} />
            ) : (
              <ChevronDown size={20} />
            )}
          </div>
        </Col>
        {/* <span className="dropdown-header px-0 mb-2"> Category</span> */}
        <div
          className="m-0"
          style={
            showCategories
              ? { height: "auto" }
              : {
                  height: "0px",
                  overflow: "hidden",
                }
          }
        >
          <Form>
            {/* Checkboxes for Courses */}
            {categoryOptions.map((item, index) => (
              <Form.Check
                type="checkbox"
                className="mb-1"
                label={item}
                key={index}
                value={item}
                checked={selectedCategories.includes(item)}
                onChange={handleCategoryChange}
              />
            ))}
          </Form>
        </div>
      </Card.Body>

      {/* Card body */}
      <Card.Body className="border-top">
        <Col
          sm="9"
          className="cate mb-2 d-flex justify-content-between"
          onClick={() => setShowDifficulty(!showDifficulty)}
        >
          <div className="m-0 mt-1">SKILL LEVEL</div>
          <div className="m-0">
            {showDifficulty ? (
              <ChevronUp size={20} />
            ) : (
              <ChevronDown size={20} />
            )}
          </div>
        </Col>
        {/* <span className="dropdown-header px-0 mb-2"> Skill Level</span> */}
        <div
          className="m-0"
          style={
            showDifficulty
              ? { height: "auto" }
              : {
                  height: "0px",
                  overflow: "hidden",
                }
          }
        >
          <Form>
            {/* Checkboxes for Level */}
            {["Low", "Intermediate", "Expert"].map((item, index) => (
              <Form.Check
                type="checkbox"
                className="mb-1"
                label={item}
                key={index}
                value={item}
                checked={selectedLevels.includes(item)}
                onChange={handleLevelChange}
              />
            ))}
          </Form>
        </div>
      </Card.Body>
     
    </Card>
  );
};
export default FilterOptions;
