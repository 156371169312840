// import node module libraries
import React, { Fragment, useEffect, useState } from 'react';
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Url from "../Url";
// import profile layout wrapper
import ProfileLayoutWrap from '../marketing/account-settings/ProfileLayoutWrap';

import {BsPersonCheck, BsPersonDash } from 'react-icons/bs';

const VerifyEmail = (props) => {
	const location = useLocation();
	const emailHash = props.match.params.emailHash;
	
	const url1 = Url();
	const url = url1["url"];

	const [message,setMessage] = useState("");
    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => {
        VerifyEmailClicked();
      }, [emailHash]);

	const VerifyEmailClicked = (e) => {

        // console.log(id)
        const api = url + "verifyMentorEmail";
        let statusCode = 0;
        fetch(api, {
            method: 'POST',
            body: JSON.stringify({id:emailHash}),
            headers: { 'Content-Type': 'application/json' }
        })
        .then((apidata) => { 
            statusCode = apidata.status;
            return apidata.json();
        })
        .then((actualdata) => {
            setMessage(actualdata.message);
            if(statusCode == 200){
                setIsVerified(true);
            }else{
                setIsVerified(false);
            }

        })
	};
    
	return (
        <Container className="p-4">
            <Row className="justify-content-md-center">
            <Col sm={4}>
			<Card className="border-0">
				<Card.Header className="text-center">
					<div className="mb-3 mb-lg-0">
						<h4 className="mb-0">Email Verification</h4>
					</div>
				</Card.Header>
				<Card.Body className="text-center">
                    <div className={isVerified ? "text-success" : "text-danger"}>
                        {isVerified ? 
                        <BsPersonCheck size={40} />
                        :
                        <BsPersonDash size={40} />
                        }
                        <p className="fs-4 mt-3">{message}</p>

                        {isVerified && 
                        <p className="fs-4 mt-3"><Link to="/mentor/login">Mentor Login</Link></p>
                        }
                    </div>
				</Card.Body>
			</Card>
            </Col>
            </Row>
            </Container>
        
	);
};

export default VerifyEmail;
