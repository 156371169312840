// import node module libraries
import React, { Fragment, useState, useEffect } from "react";
import {
  Col,
  Row,
  Container,
  Nav,
  Card,
  Tab,
  ListGroup,
  Image,
  Button,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import "tippy.js/animations/scale.css";

import Ratings from "components/marketing/common/ratings/Ratings";

import BuyNowModal from "components/buynow/BuyNowModal";
import Url from "components/Url";
import LoginModalNew from "components/authentication/LoginModalNew";

// import utility file
import { numberWithCommas } from "helper/utils";

import { LuMailQuestion } from "react-icons/lu";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { BsFillPersonLinesFill,BsCheckCircle } from "react-icons/bs";

import { BsPersonGear } from "react-icons/bs";

import MentorService from "./MentorService";

import moment from "moment";

const MentorshipDetails = (props) => {
  const [isOpen, setOpen] = useState(false);
  const [YouTubeURL] = useState("JRzWRZahOVU");

  const url1 = Url();
  const url = url1["url"];
  const s3 = url1["s3"];
  const s3URL = s3;

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [showBuyNowPopup, setShowBuyNowPopup] = useState(false);

  const id = props.match.params.id;
  const [loginModalShow, setLoginModalShow] = useState(false);
  //console.log("here 1");
  //console.log(generatedURL);
  let location = useLocation();

  const [mentorshipDetails, setMentorshipDetails] = useState([]);
  const [mentorEducation, setMentorEducation] = useState([]);
  const [mentorSkills, setMentorSkills] = useState([]);
  const [mentorTopics, setMentorTopics] = useState([]);
  const [mentorExperiences, setMentorExperiences] = useState([]);
  const [mentorServices, setMentorServices] = useState([]);
  const [mentorSlots, setMentorSlots] = useState([]);
  const [mentorFeedback, setMentorFeedback] = useState([]);

  const [showSlots, setShowSlots] = useState(false);
  const [dayOfWeek, setDayOfWeek] = useState();


  const [product,setProduct] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [mentorServiceId, setMentorServiceId] = useState();
  const [showBookNow, setShowBookNow] = useState(false);
  const [activeServiceIndex, setActiveServiceIndex] = useState(null);
  useEffect(() => {
    const api = url + "getMentorshipDetails/" + id;

    fetch(api, {
      method: "GET",
    })
      .then((apidata) => {
        return apidata.json();
      })
      .then((actualdata) => {
        setMentorshipDetails(actualdata.mentorshipDetails?.[0]);
        setMentorEducation(actualdata.mentorEducation);
        setMentorSkills(actualdata.mentorSkills);
        setMentorTopics(actualdata.mentorTopics);
        setMentorExperiences(actualdata.mentorExperiences);
        setMentorServices(actualdata.mentorServices);
        setMentorSlots(actualdata?.mentorSlots);
        setMentorFeedback(actualdata?.mentorFeedback);

      });

    if (localStorage.getItem("stutoken")) {
      setIsUserLoggedIn(true);
    }

    if (mentorshipDetails) {

      if (sessionStorage.getItem("buynowLogin")) {

        if (
          mentorshipDetails.id == sessionStorage.getItem("mentorId") &&
          isUserLoggedIn
        ) {

          setShowBuyNowPopup(true);
        }
      }
    }
  }, [id]);

  const handleBuyNow = () => {
    if (isUserLoggedIn) {
      sessionStorage.removeItem("buynowLogin");
      sessionStorage.removeItem("redirectURL");
      const productDetails = {
        course_amount: parseInt(selectedProduct.price), 
        subject_id : mentorServiceId,
        itemType: 3,
        subject_name: selectedProduct.service_name + " Mentorship",
        course_duration: selectedProduct.service_duration_mins,
        customer_type: 1,
        dayOfWeek:selectedProduct.dayOfWeek, 
        mentor_service_id:selectedProduct.mentor_service_id,
        slot_from:selectedProduct.slot_from, 
        slot_to:selectedProduct.slot_to,
        slot_date:selectedProduct.slot_date
        };

        setProduct(productDetails);      
      setShowBuyNowPopup(true);
    } else {
      //console.log("NOT loggedin");
      sessionStorage.setItem("buynowLogin", true);
      sessionStorage.setItem("redirectURL", "mentorship-details/" + id);
      sessionStorage.setItem("mentorId", id);
      //window.location.href = "/student-login";
      setLoginModalShow(true);
    }
  };


  return (
    <Fragment>
      <LoginModalNew
        show={loginModalShow}
        setModalShow={setLoginModalShow}
        onHide={() => setLoginModalShow(false)}
        activeTab={"student"}
      />
      {showBuyNowPopup && (
        <BuyNowModal
          show={showBuyNowPopup}
          onHide={() => setShowBuyNowPopup(false)}
          product={product}
        />
      )}
      {mentorshipDetails.id ? (
        <Fragment>
          {/* <Helmet>
            <title>{(mentorshipDetails.seo_title ? mentorshipDetails.seo_title : "Internshipgate - " + mentorshipDetails.mentorship_category )}</title>
            <meta name="keywords" content={(mentorshipDetails.meta_keywords ? course.meta_keywords : course.subject_heading)} />
            <meta name="description" content={(course.meta_description ? course.meta_description : course.subject_description)} />
          </Helmet>           */}
          <div className="pt-lg-8 pb-lg-16 pt-8 pb-12 bg-primary">
            <Container>
              <Row className="align-items-center">
                <Col xl={7} lg={7} md={12} sm={12}>
                  <div>
                    <h1 className="text-white display-4 fw-semi-bold">
                      {mentorshipDetails.fullname}
                    </h1>
                    <p className="text-white mb-6 lead">
                      {mentorshipDetails.headline}
                    </p>
                    <div className="d-flex align-items-center">
                      <span className="text-white ms-3">
                        <i className="fe fe-user text-white-50"></i>{" "}
                        {mentorshipDetails.no_of_mentorships} Mentorships{" "}
                      </span>
                      <span className="ms-4">
                        <span className="text-warning">
                          <Ratings rating={mentorshipDetails.mentor_rating} />
                          <span className="text-white ms-1">
                            ({numberWithCommas(mentorshipDetails.mentor_rating)}
                            )
                          </span>
                        </span>
                      </span>
                      <span className="text-white ms-4 d-none d-md-block">
                        <span className="text-white-50">
                          <BsPersonGear size={17} />{" "}
                        </span>
                        <span className="align-middle">
                          {mentorshipDetails.work_experience_year} Years Of
                          Industry Experience
                        </span>
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="pb-10">
            <Container>
              <Row>
                <Col lg={8} md={12} sm={12} className="mt-4 mb-4 mb-lg-0">
                  <Card>
                    <Card.Body className="p-3">
                      <h3>About Mentor</h3>
                      <div
                        className="fs-4"
                        dangerouslySetInnerHTML={{
                          __html: mentorshipDetails.short_bio,
                        }}
                      ></div>
                    </Card.Body>
                  </Card>
                  {/* <Card className="mt-3">
                    <Card.Body className="p-3">
                      <h3>Mentor Topics</h3>
                      <ListGroup variant="flush">
                        {mentorTopics?.length > 0 &&
                          mentorTopics.map((mentorTopic, index) => (
                            <Col key={index} className="mt-3">
                              <IoMdCheckmarkCircleOutline />
                              <span className="fs-4 ms-2">
                                {mentorTopic.mentor_topic}
                              </span>
                            </Col>
                          ))}
                      </ListGroup>
                    </Card.Body>
                  </Card> */}

                  {mentorSkills?.length > 0 && (
                    <Card className="mt-3">
                      <Card.Body className="p-3">
                        <h3>Mentor Skills</h3>
                          <ul style={{listStyle:"none", paddingLeft:"0.5rem"}}>
                          {mentorSkills.map((skill, index) => (
                            <li className="fs-5 mt-2" key={index}>
                              <BsCheckCircle  size={15} className="me-3"/>
                              {skill.skill}
                            </li>
                          ))}
                        </ul>
                      </Card.Body>
                    </Card>
                  )}


                  {/* {mentorSkills?.length > 0 && (
                    <Card className="mt-3">
                      <Card.Header className="p-1">
                        <h3>Mentor Skills</h3>
                      </Card.Header>
                      <Card.Body className="p-1">
                        <ListGroup variant="flush">
                          {mentorSkills.map((mentorSkill, index) => (
                            <ListGroup.Item key={index}>
                              <IoMdCheckmarkCircleOutline />
                              <span className="fs-4 ms-2">
                                {mentorSkill.skill}
                              </span>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Card.Body>
                    </Card>
                  )} */}
                  {mentorExperiences?.length > 0 && (
                    <Card className="mt-3">
                      <Card.Body className="p-3">
                          <h3>Experience</h3>
                          {mentorExperiences.map((mentorExperience, index) => (

                            <Col className="my-2 py-2 col " key={index}>
                              <div className="h5">
                                {mentorExperience.org_name}
                              </div>
                              <div className="mainHeadings">
                                {mentorExperience.designation}
                              </div>
                              <div className="mainHeadings">
                                {mentorExperience.start_date}-{(mentorExperience.end_date ?mentorExperience.end_date :"Till Date") }
                              </div>

                            </Col>
                          ))}
                      </Card.Body>
                    </Card>
                  )}

                  {mentorEducation?.length > 0 && (
                    <Card className="mt-3">
                      <Card.Body className="p-3">
                        <h3>Education</h3>
                          {mentorEducation.map((education, index) => (
                            <Col className="my-2 py-2 col " key={index}>
                            <div className="h5">
                              {education.degree_name}
                            </div>
                            <div className="mainHeadings">
                              {education.institute_name}
                            </div>
                            <div className="mainHeadings">
                              {education.start_date}-{education.end_date}
                            </div>

                          </Col>
                          ))}

                      </Card.Body>
                    </Card>
                  )}

                  {mentorFeedback?.length > 0 && (
                    <Card className="mt-3">
                      <Card.Body className="p-3">
                        <h3>Feedback</h3>
                          {mentorFeedback.map((feedback, index) => (
                              <div className="d-flex mt-3" key={index}>
                                <BsFillPersonLinesFill size={30}  />
                                <div className="ms-3">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                      <h4 className="mb-0">{feedback.fullname}</h4>
                                      <span className="text-muted fs-6">{feedback.feedback_date}</span>
                                    </div>
                                  </div>
                                  <div className="mt-2">
                                    <span className="me-1 text-warning">
                                      <Ratings rating={feedback.star_rating} />
                                    </span>
                                    <span className="me-1">for</span>
                                    <span className="h5">{feedback.service_name}</span>
                                    <p className="mt-2">{feedback.feedback} </p>
                                  </div>
                                </div>
                                
                              </div>
                          ))}

                      </Card.Body>
                    </Card>
                  )}

                </Col>

                <Col lg={3} md={12} sm={12} className="mt-lg-n22">
                  {/* Card */}
                  <Card className="mb-3 mb-4">
                    <div className="p-1 text-center">
                      <Image
                        src={
                          s3URL +
                          "Mentors/" +
                          mentorshipDetails.profile_pic
                        }
                        alt={"Mentor Profile Pic"}
                        style={{ width: "50%"}}
                        roundedCircle
                        className="img-fluid"
                      />
                    </div>

                    <Card.Header>
                      <h2 className="mb-0 text-center">Mentorship Services</h2>
                    </Card.Header>
                    {/* Card Body */}
                    <Card.Body className="p-0">
                      <ListGroup variant="flush">
                        {mentorServices?.length > 0
                          ? mentorServices.map((mentorService, index) => (
                            <div key={index}>
                              <MentorService
                                service={mentorService}
                                index={index}
                                mentorSlots={mentorSlots}
                                handleBuyNow={handleBuyNow}
                                setSelectedProduct={setSelectedProduct}
                                setMentorServiceId={setMentorServiceId}
                                activeServiceIndex={activeServiceIndex}
                                setActiveServiceIndex={setActiveServiceIndex}
                              />
                              </div>
                            ))
                          : null}
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Fragment>
      ) : (
        <Container className="text-center">
          <h1>Mentorship not found</h1>
          <Link to="/mentorship">
            <Button>Go Back</Button>
          </Link>
        </Container>
      )}
    </Fragment>
  );



};

export default MentorshipDetails;
