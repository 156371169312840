import { Font } from "@react-pdf/renderer";
import RobotoRegular from "../../../../assets/fonts/Roboto/Roboto-Regular.ttf";
import RobotoRegularItalic from "../../../../assets/fonts/Roboto/Roboto-Italic.ttf";
import RobotoBold from "../../../../assets/fonts/Roboto/Roboto-Bold.ttf";
import RobotoBoldItalic from "../../../../assets/fonts/Roboto/Roboto-BoldItalic.ttf";
import RobotoMedium from "../../../../assets/fonts/Roboto/Roboto-Medium.ttf";
import RobotoMediumItalic from "../../../../assets/fonts/Roboto/Roboto-MediumItalic.ttf";
import RobotoLight from "../../../../assets/fonts/Roboto/Roboto-Light.ttf";
import RobotoLightItalic from "../../../../assets/fonts/Roboto/Roboto-LightItalic.ttf";

Font.register({
	family: "Roboto",
	fonts: [
		{
			src: RobotoRegular,
		},
		{
			src: RobotoRegularItalic,
			fontStyle: "italic",
		},
		{
			src: RobotoBold,
			fontWeight: 700,
		},
		{
			src: RobotoBoldItalic,
			fontWeight: 700,
			fontStyle: "italic",
		},
		{
			src: RobotoMedium,
			fontWeight: 500,
		},
		{
			src: RobotoMediumItalic,
			fontWeight: 500,
			fontStyle: "italic",
		},
		{
			src: RobotoLight,
			fontWeight: 300,
		},
		{
			src: RobotoLightItalic,
			fontWeight: 300,
			fontStyle: "italic",
		},
	],
});
