// import node module libraries
import { Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// import custom components
import LevelIconWithTooltip from 'components/marketing/common/miscellaneous/LevelIconWithTooltip';

// import media files
import CheckedMark from 'assets/images/svg/checked-mark.svg';
import ProfileBackground from 'assets/images/background/profile-bg.jpg';
import Url from 'components/Url';

const ProfileCover = ({  dashboardData, updateImage=true}) => {

	const url1 = Url();
	const url = url1["url"];
	const s3 = url1["s3"];
	return (
		<Row className="align-items-center">
			<Col xl={12} lg={12} md={12} sm={12}>
				{/* <!-- Bg --> */}
				<div
					className="pt-16 rounded-top-md"
					style={{
						background: `url(${ProfileBackground})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover'
					}}
				>
					{' '}
				</div>
				<div className="d-flex align-items-end justify-content-between bg-white px-4 pt-2 pb-4 rounded-none rounded-bottom-md shadow-sm">
					<div className="d-flex align-items-center">
						<div className="me-2 position-relative d-flex justify-content-end align-items-end mt-n5">
							<Image
								src={`${s3}Mentors/${dashboardData.avatar}${updateImage ? '?' + new Date().getTime() : ''}`}
								className="avatar-xl rounded-circle border border-4 border-white position-relative"
								alt=""
							/>
							{dashboardData.verified ? (
								<Link
									to="#"
									className="position-absolute top-0 end-0"
									data-bs-toggle="tooltip"
									data-placement="top"
									title=""
									data-original-title="Verified"
								>
									<Image src={CheckedMark} alt="" height="30" width="30" />
								</Link>
							) : (
								''
							)}
						</div>
						<div className="lh-1">
							<h2 className="mb-0">
								{dashboardData.name}{' '}
								<LevelIconWithTooltip level={dashboardData.level} />{' '}
							</h2>
							<p className="mb-0 d-block">{dashboardData.username}</p>
						</div>
					</div>

				</div>
			</Col>
		</Row>
	);
};

export default ProfileCover;
