// import node module libraries
import React, { Fragment, useState, useEffect } from "react";
import {
  Col,
  Row,
  Container,
  Nav,
  Card,
  Tab,
  ListGroup,
  Image,
  Button,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

// import popup youtube video
import ModalVideo from "react-modal-video";

// tippy tooltip
import Tippy from "@tippyjs/react";
import "tippy.js/animations/scale.css";

// import custom components
import GKAccordionDefault from "components/courses/accordions/GKAccordionDefault";
import Ratings from "components/marketing/common/ratings/Ratings";

// import sub components
import CourseCard from "./CourseCard";
import GetEnrolledCourseCard from "../GetEnrolledCourseCard";
// import sub components tabs
import ReviewsTab from "./ReviewsTab";
import DescriptionTab from "./DescriptionTab";
import TranscriptTab from "./TranscriptTab";
import FAQTab from "./FAQTab";

// import media files
import CheckedMark from "assets/images/svg/checked-mark.svg";
import CourseJavascript from "assets/images/course/course-javascript.jpg";
import Avatar1 from "assets/images/avatar/avatar-1.jpg";

// import data files
import { CourseIndex } from "data/marketing/CourseIndexData";
import { AllCoursesData } from "data/slider/AllCoursesData";

import BuyNowModal from "components/buynow/BuyNowModal";
import Url from "../../Url";
import { Helmet } from "react-helmet";
import LoginModalNew from "components/authentication/LoginModalNew";

// import utility file
import { numberWithCommas } from "helper/utils";

const CourseSingle = (props) => {
  const [isOpen, setOpen] = useState(false);
  const [YouTubeURL] = useState("JRzWRZahOVU");

  const url1 = Url();
  const url = url1["url"];
  const [course, setCourse] = useState();
  const [CourseIndex, setCourseIndex] = useState([]);
  const [allCourseData, setAllCourseData] = useState([]);
  const [relatedCourses, setRelatedCourses] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const s3 = url1["s3"];
  const s3URL = s3 + "courseImages/";

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [showBuyNowPopup, setShowBuyNowPopup] = useState(false);

  const generatedURL = props.match.params.generatedURL;
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  //console.log("here 1");
  //console.log(generatedURL);
  let location = useLocation();

  useEffect(() => {
    const api = url + "getVICourseDetailsByURL/" + generatedURL;

    fetch(api, {
      method: "GET",
    })
      .then((apidata) => {
        return apidata.json();
      })
      .then((actualdata) => {
        setAllCourseData(actualdata);
        setCourse(actualdata.courseData[0]);
        setCourseIndex(actualdata.courseTopicSubtopic);
        setRelatedCourses(actualdata.relatedCourses);
        setLoading(false);
        //console.log(CourseIndex);
      });

    if (localStorage.getItem("stutoken")) {
      setIsUserLoggedIn(true);
    }

    if (course) {
      //console.log("here");
      if (sessionStorage.getItem("buynowLogin")) {
        //console.log("here111");
        if (course.id == sessionStorage.getItem("courseId") && isUserLoggedIn) {
          //console.log("here2222");
          setShowBuyNowPopup(true);
        }
      }
    }
  }, [generatedURL]);

  const handleBuyNow = () => {
    if (isUserLoggedIn) {
      //console.log("loggedin");
      sessionStorage.removeItem("buynowLogin");
      sessionStorage.removeItem("redirectURL");
      setShowBuyNowPopup(true);
    } else {
      //console.log("NOT loggedin");
      sessionStorage.setItem("buynowLogin", true);
      sessionStorage.setItem("redirectURL", course.generated_url);
      //window.location.href = "/student-login";
      setLoginModalShow(true);
    }
  };

  // console.log("Redirect url : ", course);

  return (
    <Fragment>
      <Helmet>
        <title>
          {course?.seo_title
            ? course?.seo_title
            : "Internshipgate - " + course?.subject_heading}
        </title>
        <meta
          name="keywords"
          content={
            course?.meta_keywords
              ? course?.meta_keywords
              : course?.subject_heading
          }
        />
        <meta
          name="description"
          content={
            course?.meta_description
              ? course?.meta_description
              : course?.subject_description
          }
        />
      </Helmet>      
      <LoginModalNew
        show={loginModalShow}
        setModalShow={setLoginModalShow}
        onHide={() => setLoginModalShow(false)}
        activeTab={"student"}
      />
      {showBuyNowPopup && (
        <BuyNowModal
          show={showBuyNowPopup}
          onHide={() => setShowBuyNowPopup(false)}
          product={{ ...course, itemType: 1, customer_type: 1 }}
        />
      )}
      {loading ? (
        <Container className="text-center">
          <div className="col-12">Loading...</div>
        </Container>
      ) : course ? (
        <Fragment>

          <div className="pt-lg-8 pb-lg-16 pt-8 pb-12 bg-primary">
            <Container>
              <Row className="align-items-center">
                <Col xl={7} lg={7} md={12} sm={12}>
                  <div>
                    <h1 className="text-white display-4 fw-semi-bold">
                      {course.subject_heading}
                    </h1>
                    <p className="text-white mb-6 lead">
                      {course.preview_text}
                    </p>
                    <div className="d-flex align-items-center">
                      {/*											
											<Tippy content="Add to Bookmarks" animation={'scale'}>
												<Link
													to="#"
													className="bookmark text-white text-decoration-none"
												>
													<i className="fe fe-bookmark text-white-50 me-2"></i>
													Bookmark
												</Link>
											</Tippy>
*/}
                      <span className="text-white ms-3">
                        <i className="fe fe-user text-white-50"></i>{" "}
                        {course.enrolled_numbers} Enrolled{" "}
                      </span>
                      <span className="ms-4">
                        <span className="text-warning">
                          <Ratings rating={course.star_rating} />
                          <span className="text-white ms-1">
                            ({numberWithCommas(course.star_rating)})
                          </span>
                        </span>
                      </span>
                      <span className="text-white ms-4 d-block">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="3"
                            y="8"
                            width="2"
                            height="6"
                            rx="1"
                            fill="#DBD8E9"
                          ></rect>
                          <rect
                            x="7"
                            y="5"
                            width="2"
                            height="9"
                            rx="1"
                            fill="#DBD8E9"
                          ></rect>
                          <rect
                            x="11"
                            y="2"
                            width="2"
                            height="12"
                            rx="1"
                            fill="#DBD8E9"
                          ></rect>
                        </svg>{" "}
                        <span className="align-middle">
                          {course.course_competency_level_desc}
                        </span>
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="pb-10">
            <Container>
              <Row>
                <Col lg={8} md={12} sm={12} className="mt-n8 mb-4 mb-lg-0">
                  <Tab.Container defaultActiveKey="contents">
                    <Card>
                      <Nav className="nav-lb-tab">
                        {["Contents", "Description", "FAQ"].map(
                          (item, index) => (
                            <Nav.Item key={index}>
                              <Nav.Link
                                eventKey={item.toLowerCase()}
                                className="mb-sm-3 mb-md-0"
                              >
                                {item}
                              </Nav.Link>
                            </Nav.Item>
                          )
                        )}
                      </Nav>
                      <Card.Body className="p-0">
                        <Tab.Content>
                          <Tab.Pane
                            eventKey="contents"
                            className="pb-4 pt-3 px-4"
                          >
                            {/* Course Index Accordion */}
                            {CourseIndex.length > 0 ? (
                              <GKAccordionDefault
                                accordionItems={CourseIndex}
                                itemClass="px-0"
                              />
                            ) : null}
                          </Tab.Pane>
                          <Tab.Pane eventKey="description" className="pb-4 p-4">
                            {/* Description */}
                            {/* <DescriptionTab /> */}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: course.subject_description,
                              }}
                            ></div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="faq" className="pb-4 p-4">
                            {/* FAQ */}
                            {/* <FAQTab />*/}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: course.faqs,
                              }}
                            ></div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Card.Body>
                    </Card>
                  </Tab.Container>
                </Col>
                <Col lg={4} md={12} sm={12} className="mt-lg-n22">
                  {/* Card */}
                  <Card className="mb-3 mb-4">
                    <div className="p-1">
                      <Image
                        src={s3URL + course.preview_image}
                        alt={course.preview_image_alt}
                        className="card-img-top rounded-top-md"
                      />
                    </div>
                    {/* video popup */}
                    <ModalVideo
                      channel="youtube"
                      autoplay
                      isOpen={isOpen}
                      videoId={YouTubeURL}
                      onClose={() => setOpen(false)}
                    />
                    {/* end of video popup */}

                    {/* Card body */}
                    <Card.Body>
                      {/* Price single page */}
                      <div className="mb-3">
                        <span className="text-dark fw-bold h2 me-2">
                          &#8377;{course.course_amount}
                        </span>
                        {course.discount_percent > 0 && (
                          <del className="fs-4 text-muted">
                            &#8377;{course.course_mrp}
                          </del>
                        )}
                        {course.discount_percent > 0 && (
                          <span className="text-danger small">
                            {" "}
                            (-{course.discount_percent}%)
                          </span>
                        )}
                        {course.course_amount > 0 && (
                          <span className="fs-6 ms-1">+ GST</span>
                        )}
                      </div>
                      <div className="d-grid">
                        <Link
                          to="#"
                          onClick={handleBuyNow}
                          className="btn btn-primary mb-2  "
                        >
                          Buy Now
                        </Link>
                        {/* 
											<Link
												to="/marketing/pages/pricing/"
												className="btn btn-outline-primary"
											>
												Get Full Access
											</Link>
											*/}
                      </div>
                    </Card.Body>
                  </Card>
                  {/* Card */}
                  <Card className="mb-4">
                    {/* Card header */}
                    <Card.Header>
                      <h4 className="mb-0">What’s included</h4>
                    </Card.Header>
                    {/* Card Body */}
                    <Card.Body className="p-0">
                      <ListGroup variant="flush">
                        {allCourseData.courseFeatures.length > 0
                          ? allCourseData.courseFeatures.map(
                              (courseFeature) => (
                                <ListGroup.Item>
                                  <i
                                    className={
                                      courseFeature.feature_icon +
                                      " align-middle me-2 text-primary"
                                    }
                                  ></i>
                                  {courseFeature.feature_name}
                                </ListGroup.Item>
                              )
                            )
                          : null}
                        {/*
											<ListGroup.Item>
												<i className="fe fe-award me-2 align-middle text-success"></i>
												Certificate
											</ListGroup.Item>
											<ListGroup.Item>
												<i className="fe fe-calendar align-middle me-2 text-info"></i>
												12 Article
											</ListGroup.Item>
											<ListGroup.Item>
												<i className="fe fe-video align-middle me-2 text-secondary"></i>
												Watch Offline
											</ListGroup.Item>
											<ListGroup.Item className="bg-transparent">
												<i className="fe fe-clock align-middle me-2 text-warning"></i>
												Lifetime access
											</ListGroup.Item>
												*/}
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              {/* Card */}
              <div className="pt-4 pb-3">
                <Row className="d-md-flex align-items-center mb-4">
                  <Col lg={12} md={12} sm={12}>
                    <h2 className="mb-0">Related Courses</h2>
                  </Col>
                </Row>
                <Row>
                  {relatedCourses.map((course, index) => (
                    <Col lg={3} md={6} sm={12} key={index}>
                      {" "}
                      <GetEnrolledCourseCard item={course} />
                    </Col>
                  ))}
                </Row>
              </div>
            </Container>
          </div>
        </Fragment>
      ) : (
        <Container className="text-center">
          <h1>Course not found</h1>
          <Link to="/virtual-internship">
            <Button>Go Back</Button>
          </Link>
        </Container>
      )}
    </Fragment>
  );
};

export default CourseSingle;
