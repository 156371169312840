// import node module libraries
import { v4 as uuid } from 'uuid';

export const InternshipListData = [
    {
        id: uuid(),
        internshipName: 'Campus Ambassodor',
        date: '13 Feb, 2023',
        status: 'Inactive',
        received: 'View Applications (12)',
        badge: 'danger'
    },
    {
        id: uuid(),
        internshipName: 'Business Development (Sales)',
        date: '13 Feb, 2023',
        status: 'Draft',
        received: 'View Applications',
        badge: 'info'
    },
    {
        id: uuid(),
        internshipName: 'Graphic Design',
        date: '13 Feb, 2023',
        status: 'Inactive',
        received: 'View Applications (12)',
        badge: 'danger'
    },
    {
        id: uuid(),
        internshipName: 'UX/UI Design',
        date: '13 Feb, 2023',
        status: 'Active',
        received: 'View Applications',
        badge: 'success'
    },
    {
        id: uuid(),
        internshipName: 'Graphic Design',
        date: '13 Feb, 2023',
        status: 'Inactive',
        received: 'View Applications (12)',
        badge: 'danger'
    },
    {
        id: uuid(),
        internshipName: 'Business Development (Sales)',
        date: '14 Feb, 2023',
        status: 'Active',
        received: 'View hired candidates',
        badge: 'success'
    },
    {
        id: uuid(),
        internshipName: 'Campus Ambassodor',
        date: '13 Feb, 2023',
        status: 'Inactive',
        received: 'View hired candidates',
        badge: 'danger'
    },
    {
        id: uuid(),
        internshipName: 'Campus Ambassodor',
        date: '13 Feb, 2023',
        status: 'Draft',
        received: 'View Applications',
        badge: 'info'
    },
    {
        id: uuid(),
        internshipName: 'Campus Ambassodor',
        date: '13 Feb, 2023',
        status: 'Inactive',
        received: 'View hired candidates',
        badge: 'danger'
    }
];

export default InternshipListData;
