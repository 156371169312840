// import node module libraries
import React, { Fragment, useContext } from 'react';
import { Card } from 'react-bootstrap';

// import context file
import { ChatContext } from 'context/Context';

const Message = (props) => {
	const { chatScript, chats } = props;
	const emptoken = localStorage.getItem("emptoken")
	const stutoken = localStorage.getItem("stutoken")
	// const {
	// 	ChatState: { loggedInUserId }
	// } = useContext(ChatContext);

	return (
		<Fragment>		
				{emptoken && chats?.map(item => (
					item.message_from === "E"
					?
				<div className="d-flex justify-content-end mb-4">
					<div className=" me-3 text-end">
						<Card className="mt-2 rounded-top-md-end-0 bg-primary-100">
							<Card.Body className="text-end p-3">
								<p className="mb-2" dangerouslySetInnerHTML={{ __html: item.message_from === "E" ?  item.message : null }} />
								<div>
									<span>{item.created_at.substr(11, 5)}</span>
									<span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--isg-gray-500)" className="bi bi-check2-all" viewBox="0 0 16 16">
										<path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
										<path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
									</svg></span>
								</div>
							</Card.Body>
						</Card>
					</div>
				</div>
				:
				<div className="d-flex mb-4">
					<div className="me-3">
						<Card className="mt-2 rounded-top-md-end-0 bg-light">
							<Card.Body className="p-3">
								<p className="mb-2" dangerouslySetInnerHTML={{ __html: item.message_from === "S" ?  item.message : null }} />
								<div className="text-end">
									<span>{item.created_at.substr(11, 5)}</span>
									<span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--isg-gray-500)" className="bi bi-check2-all" viewBox="0 0 16 16">
										<path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
										<path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
									</svg></span>
								</div>
							</Card.Body>
						</Card>
					</div>
				</div>
				))}
				{stutoken && chats?.map(item => (
					item.message_from === "S"
					?
				<div className="d-flex justify-content-end mb-4">
					<div className=" me-3 text-end">
						<Card className="mt-2 rounded-top-md-end-0 bg-primary-100">
							<Card.Body className="text-end p-3">
								<p className="mb-2" dangerouslySetInnerHTML={{ __html: item.message_from === "S" ?  item.message : null }} />
								<div>
									<span>{item.created_at.substr(11, 5)}</span>
									<span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--isg-gray-500)" className="bi bi-check2-all" viewBox="0 0 16 16">
										<path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
										<path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
									</svg></span>
								</div>
							</Card.Body>
						</Card>
					</div>
				</div>
				:
				<div className="d-flex mb-4">
					<div className="me-3">
						<Card className="mt-2 rounded-top-md-end-0 bg-light">
							<Card.Body className="p-3">
								<p className="mb-2" dangerouslySetInnerHTML={{ __html: item.message_from === "E" ?  item.message : null }} />
								<div className="text-end">
									<span>{item.created_at.substr(11, 5)}</span>
									<span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--isg-gray-500)" className="bi bi-check2-all" viewBox="0 0 16 16">
										<path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
										<path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
									</svg></span>
								</div>
							</Card.Body>
						</Card>
					</div>
				</div>
				))}

		</Fragment>
	);
};
export default Message;
