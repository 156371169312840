import React, { useEffect, useRef, useState, createRef } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  Svg,
  Path,
  Circle,
  Polyline,
} from "@react-pdf/renderer";
import { Html } from "react-pdf-html";
import {
  useA4Scale,
  TemplatePage,
  renderHTMLContent,
  getDisplayDate,
} from "../../utils";
import { Row, Col } from "react-bootstrap";
import DummyProfilePic from "../../../../../assets/images/background/profilepic.png";
import { FiPhone } from "react-icons/fi";

Font.registerHyphenationCallback((word) => {
  // Return entire word as unique part
  return [word];
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    paddingVertical: "15px",
    // border: "1px solid black",
  },
  name: {
    fontSize: "18px",
    color: "#000000",
    fontWeight: 700,
    marginBottom: "4px",
    // width: "80px",
  },
  jobTitle: {
    fontFamily: "Roboto",
    fontSize: "12px",
    color: "#101214",
    fontWeight: 700,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
  },
  header: {
    marginBottom: "50px",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    flex: 1,
    height: "100%",
  },
  right: {
    width: "385px",
    padding: "10px  30px 30px 40px",
  },
  left: {
    width: "210px",
    padding: "10px  30px 30px 50px",
  },
  contact: {
    fontSize: "12px",
    color: "#646464",
    fontWeight: 300,
    marginBottom: "10px",
  },

  profileImage: {
    width: "135px",
    height: "210px",
    borderRadius: "55%",
   // marginTop: "15px",
   marginTop: "-5px",
   backgroundColor:"white",
    
  },
});

const ContactInfo = ({ data: { Phone, Country, display_email } }) => (
  <View style={styles.contact}>
    <Text
      style={{
        marginTop: "12px",
        fontFamily: "Roboto",
      }}
    >
      {display_email}
    </Text>
    <Text
      style={{
        fontFamily: "Roboto",
        marginTop: "8px",
      }}
    >
      {Phone}
    </Text>
  </View>
);
const Languages = ({ data: { languages } }) => {
  if (!languages || !languages.length) {
    return <View />;
  }
  return (
    <View style={{ paddingTop: "16px", fontSize: "14px", fontWeight: "bold" }}>
      <Text
        style={{
          color: "#000000",
          // textDecoration: "underline",
          fontSize: "14px",
          marginBottom: "12px",
          fontWeight: "550",
        }}
      >
        Languages
      </Text>
      {languages.map((s) => (
        <Text
          style={{
            color: "#73808d",
            fontSize: "10px",
            paddingBottom: "5px",
          }}
        >
          {s.language_name}
        </Text>
      ))}
    </View>
  );
};
const Skills = ({ data: { skills } }) => {
  if (!skills || !skills.length) {
    return <View />;
  }
  return (
    <View
      style={{ paddingTop: "24px", paddingBottom: "5px", marginBottom: "3px" }}
    >
      <Text
        style={{
          color: "#000000",
          fontSize: "14px",
          marginBottom: "12px",
          fontWeight: "bold",
        }}
      >
        Skills
      </Text>
      <View>
        {skills.map((s, index) => (
          <Text
            style={{
              color: "#646464",
              fontSize: "10px",
              fontWeight: 300,
              marginBottom: "8px",
            }}
          >
            {s.skill_name}
          </Text>
        ))}
      </View>
    </View>
  );
};

const Hobbies = ({ data: { hobbies } }) => {
  if (!hobbies || !hobbies.length) {
    return <View />;
  }
  return (
    <View style={{ marginTop: "10px" }}>
      <Text
        style={{
          color: "#000000",
          fontWeight: 700,
          fontSize: "14px",
          marginBottom: "10px",
          letterSpacing: "2.75px",
        }}
      >
        HOBBIES
      </Text>

      {hobbies.map((s) => (
        <Text
          style={{
            color: "#000000",
            fontSize: "10px",
            paddingBottom: "10px",
          }}
        >
          {s.hobbies}
        </Text>
      ))}
    </View>
  );
};

const RenderView = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  // console.log("data key", data[dataKey]);
  const { title, subTitle, startDate, endDate, description, city } = fieldMap;

  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View
      style={{
        marginBottom: "8px",
        // marginTop: "2px",
      }}
    >
      <Text
        style={{
          fontSize: "16px",
          fontWeight: 600,
          color: "#000000",
        }}
      >
        {heading}
      </Text>
      {items.map((item) => (
        <View style={{ marginBottom: "8px" }} wrap={true}>
          <View
            style={{
              fontSize: "13px",
              fontWeight: "500",
              marginTop: "10px",
            }}
          >
            {/* {console.log(dataKey, item[description])} */}
            <Text>{item[title]}</Text>
          </View>
          <Text style={{ fontSize: "11px", marginTop: "6px", fontWeight: 300 }}>
            {item[subTitle]}
          </Text>
          <Text
            style={{
              fontSize: "10px",
              color: "#363434",
              marginTop: "3px",
              marginBottom: "4px",
            }}
          >
            {getDisplayDate(item[startDate], item[endDate])}

            {item[city] ? ` ,  ${item[city]}` : ""}
            
          </Text>
          {item[description] && (
            <View
              style={{
                marginTop: "4px",
                display: "flex",
                flexDirection: "row",
                alignItems: "baseline",
              }}
            >
              {renderHTMLContent(item[description], {
                "*": {
                  color: "#000000",
                  fontWeight: 500,
                  fontSize: "12px",
                  paddingRight: "5px",
                  alignItems: "baseline",
                  position: "sticky",
                  textAlign: "justify",
                },
              })}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

const CustomSection = ({ data, dataKey, heading, fieldMap = {} }) =>{
  const items=data[dataKey];
  console.log("data key", data[dataKey]);
  const {title, city, startDate, endDate, description,custom_title }=fieldMap;
    if(!items||!items.length){
       return <View/>;
    }
    return (
      <View
        style={{
          marginBottom: "8px",
        }}
      >
        <Text
          style={{
            fontSize: "16px",
            fontWeight: 600,
            color: "#000000",
          }}
        >
        
          {heading}
        </Text>
        {items.map((item) => (
          
          <View style={{ marginBottom: "8px" }} wrap={true}>
            
            <View
              style={{
                fontSize: "16px",
                fontWeight: "900",
                
              }}
            >
              <Text>{item[custom_title]}</Text>
              
            </View>



            <View
              style={{
                fontSize: "13px",
                fontWeight: "500",
                marginTop: "10px",
              }}
            >
              <Text>{item[title]}</Text>
              
            </View>
          
            <Text
              style={{
                fontSize: "10px",
                color: "#363434",
                marginTop: "3px",
                marginBottom: "4px",
              }}
            >
              {getDisplayDate(item[startDate], item[endDate])}
  
              {item[city] ? ` ,  ${item[city]}` : ""}
              
            </Text>

            {item[description] && (
              <View
                style={{
                  marginTop: "4px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                }}
              >
              {console.log(dataKey, item[description])}

                {renderHTMLContent(item[description], {
                  "*": {
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "12px",
                    paddingRight: "5px",
                    alignItems: "baseline",
                    position: "sticky",
                    textAlign: "justify",
                  },
                })}
              </View>
            )}


          </View>
        ))}
      </View>
    );
};


const References = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const { title, subTitle, phoneNo, emailId } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View>
      <Text
        style={{
          fontSize: "14px",
          fontWeight: "bold",
          color: "#000000",
        }}
      >
        {heading}
      </Text>
      {items.map((item) => (
        <View style={{ marginTop: "15px" }} wrap={false}>
          <Text style={{ fontSize: "13px", fontWeight: "300" }}>
            {item[title]}
          </Text>
          <Text style={{ fontSize: "10px", marginTop: "3px", fontWeight: 300 }}>
            {item[subTitle]}
          </Text>
          <Text style={{ fontSize: "8px", color: "#a6a6a6", marginTop: "3px" }}>
            {item[phoneNo]}
          </Text>
          <Text style={{ fontSize: "10px", color: "#a6a6a6" }}>
            {item[emailId]}
          </Text>
        </View>
      ))}
    </View>
  );
};

const AdditionalInfo = ({
  data: {
    Address,
    City,
    Postal_Code,
    Driving_License,
    Nationality,
    dob,
    //Place_of_Birth,
    //hobbies,
  },
}) => {
  const details = [
    {
      label: "Address",
      value: `${Address || ""} ${City || ""} ${Postal_Code || ""}`.trim(),
    },
    {
      label: "Driving License",
      value: Driving_License,
    },
    {
      label: "Nationality",
      value: Nationality,
    },
    {
      label: "Date of Birth",
      value: dob,
    },
    {
      /* {
      label: "Place of Birth",
      value: Place_of_Birth,
  
    },  */
    },
  ];
  return details.map((info) =>
    info.value ? (
      <View style={{ padding: "5x 0px" }}>
        <Text
          style={{
            color: "#000000",
            fontWeight: 550,
            fontSize: "14px",
          }}
        >
          {info.label}
        </Text>
        <Text
          style={{
            marginTop: "2px",
            color: "#73808d",
            fontSize: "10px",
          }}
        >
          {info.value}
        </Text>
      </View>
    ) : (
      <View />
    )
  );
};

const Template3 = ({ data }) => {
  // console.log("here is the data :  ", data);
  // console.log("inside");
  const {
    profileImage,
    First_Name,
    Last_Name,
    Wanted_Job_Title,
    template_color_code,
    professionalSummary: { Description = "" } = {},
    
  } = data;
  return (
    <TemplatePage data={data}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={{
              height: "104px",
              backgroundColor: template_color_code,
              marginTop: "-30px",
            }}
          ></View>
          <View
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <View
              style={
                (styles.profileImageWrapper,
                {
                  border: `3px solid ${template_color_code}`,
                  borderRadius: "55%",
                  width: "140px",
                  height: "160px",
                  marginTop: "-60px",
                  paddingLeft:"-12%",
                  paddingTop:"10px",

                })
              }
            >
              <Image
                src={profileImage || DummyProfilePic}
                style={styles.profileImage}
              />
            </View>
          </View>

          <View style={styles.wrapper}>
            <View style={styles.left}>
              <Text style={styles.name}>
                {First_Name && Last_Name
                  ? First_Name + " " + Last_Name
                  : "Your Name"}{" "}
              </Text>
              <Text style={styles.jobTitle}>
                {Wanted_Job_Title ? Wanted_Job_Title : "Job Title"}
              </Text>
              <ContactInfo data={data} />
              <Skills data={data} />
              <RenderView
                data={data}
                dataKey="courses"
                heading="Courses"
                fieldMap={{
                  title: "course_name",
                  subTitle: "institute_name",
                  startDate: "start_date",
                  endDate: "end_date",
                  city: "city",
                }}
              />
              <Hobbies data={data} />

              <Languages data={data} />

              <AdditionalInfo data={data} />
              <References
                data={data}
                dataKey="References"
                heading="References"
                fieldMap={{
                  title: "ref_name",
                  subTitle: "company_name",
                  phoneNo: "phone_no",
                  emailId: "ref_email",
                }}
              />
            </View>
            <View style={styles.right}>
              {/* {console.log(data.professionalSummary.Description)} */}
              {data.professionalSummary.Description && (
                <View style={{ marginBottom: "35px" }}>
                  <Text
                    style={{
                      fontSize: "13px",
                      fontWeight: 800,
                      color: "#000000",
                      marginBottom: "2px",
                    }}
                  >
                    About Me
                  </Text>
                  <View>
                    {renderHTMLContent(data.professionalSummary.Description, {
                      "*": {
                        color: "#000000",
                        fontWeight: 500,
                        fontSize: "12px",
                        textAlign: "justify",
                        paddingRight:"5px",
                      },
                    })}
                  </View>
                </View>
              )}
              <RenderView
                data={data}
                dataKey="employmentHistory"
                heading="Experience"
                fieldMap={{
                  title: "Employer",
                  subTitle: "Job_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "Description",
                  city: "city",
                }}
              />
              <RenderView
                data={data}
                dataKey="education"
                heading="Education"
                fieldMap={{
                  title: "institute_name",
                  subTitle: "degree",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "Description",
                  city: "city",
                }}
              />
             
              <RenderView
                data={data}
                dataKey="internships"
                heading="Internships"
                fieldMap={{
                  title: "employer_name",
                  subTitle: "job_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "description",
                  city: "city",
                }}
              />
              <RenderView
              data={data}
              dataKey="activities"
              heading="Extra_Curricular_Activity"
              fieldMap={{
                title: "employer_name",
                subTitle: "function_title",
                startDate: "start_date",
                endDate: "end_date",
                description: "description",
              }}
            />
              <CustomSection
              data={data}
                dataKey="customSection"
                //heading="custom_title"
                fieldMap={{
                  custom_title:"custom_title",
                  title: "section_name",
                  city: "city",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "description",
                }}
              />
              {/*
              <RenderView
                data={data}
                dataKey="courses"
                heading="Courses"
                fieldMap={{
                  title: "course_name",
                  subTitle: "institute_name",
                  startDate: "start_date",
                  endDate: "end_date",
                }}
              />
              <References
                data={data}
                dataKey="References"
                heading="References"
                fieldMap={{
                  title: "ref_name",
                  subTitle: "company_name",
                  phoneNo: "phone_no",
                  emailId: "ref_email",
                }}
              />
              */}
            </View>
          </View>
        </Page>
      </Document>
    </TemplatePage>
  );
};

Template3.displayName = "Template3";
export default Template3;
