// import node module libraries
import React, { Fragment, useState, useEffect } from "react";
import { Card, Row, Col, Button , Image} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Url from "components/Url";
import Axios from "axios";
// import media files

import SurveyImg from "assets/images/svg/survey-img.svg";
import { ToastContainer, toast } from "react-toastify";
import Logo from 'assets/images/brand/logo/logo.svg';
import LogoIcon from 'assets/images/brand/logo/logo-icon.svg';
import studentPic from "assets/images/png/Group7.png";
const Overview = (props) => {
  const encryptedCareerDetailId = props.match.params.encryptedCareerDetailId;
  const topicId = props.match.params.topicId;

  const history = useHistory();
  const [lmsStudentTests, setLMSStudentTests] = useState([]);
  const [careerDetail, setCareerDetail] = useState();
  const [load, setLoad] = useState(false);

  const url1 = Url();
  const url = url1["url"];
  const studentId = localStorage.getItem("studentId");
  const token = localStorage.getItem("stutoken");

  return (
    <div>
      <Card className="border-0">
        <Card.Body className="mb-8">
          <Row>
            <Col xs={12} className="d-sm-flex">
              <Col
                xs={12} sm={4}
                style={{ backgroundColor: "#0D2851", color: "white" }}
                className="rounded text-center d-flex align-items-center"
              >
                <span className="display-6 text-white lh-lg">
                  <em>
                    Embrace Your Future: Welcome To Our Virtual Internship
                  </em>
                </span>
              </Col>
              <Col xs={12} sm={8} className="p-4">
                <h3 className="text-center text-secondary">
                  Guidelines for successful completion of your internship
                  program
                </h3>
                <p>
                  <strong>Dear Intern</strong>
                </p>
                <p>
                  <em>
                    In order to achieve a successful completion of your
                    internship program, kindly adhere to the following
                    three-part process:
                  </em>
                </p>

                <p>
                  <span className="text-secondary h5">
                    Step1-Online Training Completion:{" "}
                  </span>
                  <em>
                    Begin with the completion of the designated online training.
                    This crucial phase will equip you with the requisite
                    knowledge and skills for the subsequent steps.
                  </em>
                </p>
                <p>
                  <span className="text-secondary h5">
                    Step2-Project Selection and Submission:
                  </span>{" "}
                  <em>
                    Once you have gained adequate knowledge from the training,
                    select your project topic from AI-generated IGBot options. Use the provided steps as a guideline to execute your task. <strong><em>Note that once a project is selected and initiated, it cannot be changed.</em></strong> After completion, submit your project for
                    evaluation and approval.
                  </em>
                </p>
                <p>
                  <span className="text-secondary h5">
                    Step3-Online Assessment Achievement:
                  </span>{" "}
                  <em>
                    The final phase requires you to undergo an online
                    assessment. It is mandatory to attain a minimum of <strong>65% marks</strong>
                    in this evaluation. Upon successful completion, you will be
                    eligible to receive/download your internship certificate.
                  </em>
                </p>
                <Row className="d-flex">
                    <Col xs={4} sm={2}>
                        <Image src={studentPic} style={{height:"100px"}} />
                    </Col>
                    <Col xs={6} sm={3}>
                        <Image src={Logo} />
                    </Col>                    
                </Row>
              </Col>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col xs={12} sm={4} className="d-sm-flex">
              <Button
                variant="primary"
                onClick={() => {
                  window.location.href = `/lms/online-training/video/${topicId}/${encryptedCareerDetailId}`;
                }}
              >
                Start Virtual Internship
              </Button>
            </Col>
            <Col xs={4} className="d-flex"></Col>
          </Row>           
        </Card.Body>
      </Card>
      <ToastContainer />
    </div>
  );
};

export default Overview;
