import React, { useState,useEffect } from 'react';
import { Row, Col, Button, InputGroup, FormControl, Form } from 'react-bootstrap';
import axios from 'axios';
import {toast} from "react-toastify";
import Api from "NewApi";
import ProfileLayout from './ProfileLayout';
import { BsPlusCircle, BsDashCircle } from "react-icons/bs";

const mentorId = localStorage.getItem("mentorId");
const token = localStorage.getItem("admtoken") ? localStorage.getItem("admtoken") : localStorage.getItem("mentortoken");

function SlotForm() {
  const [slots, setSlots] = useState({
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  });

  const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

// Generate time slots in HH:mm format with labels
const generateTimeSlots = () => {
  const slots = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
      const period = hour < 12 ? 'AM' : 'PM';
      const label = `${time} ${period} IST`;
      slots.push({ label, value: time });
    }
  }
  return slots;
};

const timeSlots = generateTimeSlots();

  useEffect(() => {
    fetchSlots();
  }, []); // Empty dependency array to run only on component mount

  const fetchSlots = async () => {
    try {
      const response = await Api.get('getMentorSlots/' +mentorId, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-USER-ID": mentorId,
        }
      });
      if (response.data.mentorSlots && response.data.mentorSlots.length > 0) {
        // Assuming response.data is an array of slots
        const fetchedSlots = response.data.mentorSlots?.reduce((acc, slot) => {
          const dayName = daysOfWeek[slot.day_of_week - 1]; // Convert day number back to day name
          if (!acc[dayName]) {
            acc[dayName] = [];
          }
          acc[dayName].push({ id: slot.id, from: slot.slot_from, to: slot.slot_to });
          return acc;
        }, {
          monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [], sunday: [],
        });
        setSlots(fetchedSlots);
      }
    } catch (error) {
      toast.error('Error fetching slots', {autoClose: 1000});
      console.error('Error fetching slots:', error);
    }
  };


  const handleAddSlot = (day) => {
    const newSlot = { id: null, from: '', to: '' };
    setSlots((prevSlots) => ({
      ...prevSlots,
      [day]: [...prevSlots[day], newSlot],
    }));
  };

  const handleRemoveSlot = (day, index) => {
    // If index is provided, remove the slot at the specific index
    if (index !== undefined) {
      setSlots(prevSlots => {
        const updatedSlots = { ...prevSlots };
        updatedSlots[day].splice(index, 1); // Remove the slot at the given index
        return updatedSlots;
      });
    } else {
      // If no index is provided, remove the last slot of the day
      setSlots(prevSlots => {
        const updatedSlots = { ...prevSlots };
        if (updatedSlots[day].length > 0) {
          updatedSlots[day].pop(); // Remove the last slot of the day
        }
        return updatedSlots;
      });
    }
  }

  const handleTimeChange = (day, index, field, newValue) => {
    const updatedSlots = slots[day].map((slot, i) =>
      i === index ? { ...slot, [field]: newValue } : slot
    );
    setSlots((prevSlots) => ({
      ...prevSlots,
      [day]: updatedSlots,
    }));
  };

  const saveSlots = async () => {
    // Transform slots data to the format expected by your Laravel API
    const dataToSave = Object.entries(slots).flatMap(([day, daySlots]) =>
      daySlots.map(slot => {
        const slotData = {
          day_of_week: daysOfWeek.indexOf(day) + 1,
          slot_from: slot.from,
          slot_to: slot.to,
          mentor_id: mentorId,
        };
        // Include the ID for existing slots
        if (slot.id !== undefined) {
          slotData.id = slot.id;
        }
        return slotData;
      })
    );

    try{
    const response = await Api.post('saveMentorSlots', dataToSave,{headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-USER-ID": mentorId,
      }});
      toast.success(response.data.message,{autoClose:1000});
        // Handle success response

    }catch(error){
        toast.warning('Error saving slots', error,{autoClose:1000});
        // Handle error response
    }
  };

  return (
    <>
    <ProfileLayout>
    <Row className="mb-2 border-bottom">
      <h2 className="pb-2">Create Mentor Slots</h2>
    <div>
    <Row className="mb-3 align-items-start">
        <Col md={3}>
          <h5>Day Of the week</h5>
        </Col>
        <Col md={9}>
          <Row className="mb-3 align-items-start">
            <Col md={3}>
              <h5>Slot Start Time</h5>
            </Col>
            <Col md={3}>
            <h5>Slot End Time</h5>
            </Col>
          </Row>      
        </Col>          
    </Row>
    {daysOfWeek.map((day) => (
  <Row key={day} className="mb-3 align-items-start">
    {/* Left column for day of the week */}
    <Col md={3}>
      <span className="h4">{day.charAt(0).toUpperCase() + day.slice(1)}</span>
    </Col>

    {/* Right column for slots and Add Slot button */}
    <Col md={9}>

      {slots[day].length > 0 ? slots[day].map((slot, index) => (
        <React.Fragment key={index}>
          <Row className="mb-3"> {/* Add some bottom margin to space out each slot group */}
            {/* <Col md={4}>
              <FormControl
                type="time"
                value={slot.from}
                className="m-2 p-2"
                onChange={(e) => handleTimeChange(day, index, 'from', e.target.value)}
              />
            </Col>
            <Col md={4}>
              <FormControl
                type="time"
                value={slot.to}
                className="m-2 p-2"
                onChange={(e) => handleTimeChange(day, index, 'to', e.target.value)}
              />
            </Col> */}
            <Col md={3}>
              <Form.Select value={slot.from} className="selectpicker form-control" data-width="100%" size="6"
                onChange={(e) => handleTimeChange(day, index, 'from', e.target.value)}
                >
                <option value="">Slot Start Time(HH:MM)</option>
                {timeSlots.map((timeslot, index) => (
                  <option key={index} value={timeslot.value}>{timeslot.label}</option>
                ))}
              </Form.Select>
            </Col>      
            <Col md={3}>
              <Form.Select  value={slot.to} className="selectpicker form-control" data-width="100%"
                onChange={(e) => handleTimeChange(day, index, 'to', e.target.value)} disabled={!slot.from}
              >
                <option value="" className="p-2">Slot End Time (HH:MM)</option>
                  {timeSlots.map((timeslot, index) => (
                    <option className="p-2" key={index} value={timeslot.value} disabled={timeslot.value <= slot.from}>{timeslot.label}</option>
                  ))}
              </Form.Select >
            </Col>


            {/* Conditionally render the Add Slot button after the last slot */}
            <Col md={2} className="d-flex align-items-center">
            
              {/* Conditionally render the Delete Slot button before Add Slot button on the last row */}
              {index === slots[day].length - 1 && (
               <BsPlusCircle className="text-primary me-2" size={20} onClick={() => handleAddSlot(day)} />
              )}
              <BsDashCircle size={20} className="text-danger" onClick={() => handleRemoveSlot(day, index)} />
            </Col>
          </Row>
          
        </React.Fragment>
      )) : (
        // This ensures the Add Slot button is rendered even if there are no slots
        <Row className="mb-3">
          <Col md={4} className="d-flex align-items-center">
            <BsPlusCircle size={20} onClick={() => handleAddSlot(day)} />
          </Col>
        </Row>
      )}
      
    </Col>
    <hr />
  </Row>
))}
      <Button variant="success" onClick={saveSlots} className="mt-3">Save Slots</Button>
    </div>
    </Row>
    </ProfileLayout>
    </>
  );
}

export default SlotForm;
