// import node module libraries
import { Fragment, useState, useEffect } from 'react';
import { Menu, Search } from 'react-feather';
import { Link } from 'react-router-dom';
import {
	Nav,
	Navbar,
	InputGroup,
	Dropdown,
	Form,
	ListGroup,
	Row,
	Col,
	OverlayTrigger,
	Tooltip,
	Image
} from 'react-bootstrap';

// import simple bar scrolling used for notification item scrolling
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import Url from '../../components/Url';
// import custom components
import DotBadge from 'components/elements/bootstrap/DotBadge';

// import media files
import Avatar1 from 'assets/images/avatar/avatar-1.jpg';

// import data files
import NotificationList from 'data/Notification';

const HeaderDefault = (props) => {
	//Internshipgate - set the username and email it in the header
	const [loginName, setLoginName] = useState('');
	const [loginEmail, setLoginEmail] = useState('');
	const stutoken = localStorage.getItem("stutoken");
	const admtoken = localStorage.getItem("admtoken");

    const url1 = Url();
    const url = url1["url"];
	const s3 = url1["s3"];
	const s3url = s3 + "blogImages/";

	useEffect(() => {
		setLoginName(localStorage.loginName ? localStorage.loginName : localStorage.getItem("studentName"));
		setLoginEmail(localStorage.supervisor ? localStorage.supervisor : localStorage.getItem("email"));
	});

	return (
		<Fragment>
			<Navbar expanded="lg" className="navbar-default">
				<div className="d-flex justify-content-between w-100">
					<div className="d-flex align-items-center">
						<Link
							id="nav-toggle"
							to="#"
							onClick={() => props.data.SidebarToggleMenu(!props.data.showMenu)}
						>
							<Menu size="18px" />
						</Link>
					</div>

					<Nav className="navbar-nav navbar-right-wrap ms-auto d-flex align-items-center nav-top-wrap">
						<Dropdown as={Nav.Item}>
							<Dropdown.Toggle
								as={Nav.Link}
								bsPrefix="dt"
								className="rounded-circle border-bottom-0"
								id="dropdownUser"
							>
								<div className="avatar avatar-md avatar-indicators avatar-online">
									<Image
										alt="avatar"
										src={s3+"Account.svg"}
										className="rounded-circle"
									/>
								</div>
							</Dropdown.Toggle>
							<Dropdown.Menu
								className="dashboard-dropdown dropdown-menu-end mt-4 py-0"
								aria-labelledby="dropdownUser"
								align="end"
							>
								<Dropdown.Item className="mt-3">
									<div className="d-flex">
										<div className="avatar avatar-md avatar-indicators avatar-online">
											<Image
												alt="avatar"
												src={s3+"Account.svg"}
												className="rounded-circle"
											/>
										</div>
										<div className="ms-3 lh-1">
											<h5 className="mb-1">{loginName}</h5>
											<p className="mb-0 text-muted">{loginEmail}</p>
										</div>
									</div>
								</Dropdown.Item>
								{/**
								<Dropdown.Divider />
								<Dropdown.Item eventKey="2">
									<i className="fe fe-user me-2"></i> Profile
								</Dropdown.Item>
								<Dropdown.Item eventKey="3">
									<i className="fe fe-star me-2"></i> Subscription
								</Dropdown.Item>
								<Dropdown.Item>
									<i className="fe fe-settings me-2"></i> Settings
								</Dropdown.Item>
								 */}
								<Dropdown.Divider />
								{stutoken ? 
								<Dropdown.Item className="mb-3">
									<Link to="/student-dashboard">
									<i className="fe fe-layout me-2"></i>Student Dashboard</Link>
								</Dropdown.Item>
								:
								<Dropdown.Item className="mb-3">
									<Link to="/logout">
									<i className="fe fe-power me-2"></i> Sign Out1</Link>
								</Dropdown.Item>								
								}
							</Dropdown.Menu>
						</Dropdown>
					</Nav>
				</div>
			</Navbar>
		</Fragment>
	);
};

export default HeaderDefault;
