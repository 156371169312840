import React, { useEffect, useState } from 'react'
import { Col, Row, Container, Button, Image } from 'react-bootstrap';
import BlogCard from './BlogCard';
import Url from 'components/Url';
function Blogs() {
    const url1 = Url();
    const url = url1["url"];
	const [blogList,setBlogList] = useState([]);

	useEffect(() => {

        const api = url + "getAllActiveContents"

        fetch(api, {
            method: 'GET'
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
				console.log(actualdata);
                setBlogList(actualdata.contentData.data.slice(0,3));

            })
    }, [])
  return (
    <section className="py-lg-6 bg-white py-6">
        <Container>
          <Row>
            <Col xl={{ span: 10, offset: 1 }} md={12} xs={12}>
              <Row className="text-center">
                <Col md={12} className="px-md-16 mb-4 mt-2">
                  <span className="text-uppercase text-primary fw-semi-bold ls-md">Latest Blogs</span>
                  <h2 className="h1 fw-bold mt-3  mb-2">Read our interesting blogs</h2>
                </Col>
              </Row>
                  <Row>
                    {blogList.map((item,index) => (
                    <Col xl={4} lg={4} md={6} sm={12} className="d-flex" key={index}>
                      <BlogCard item={item} />
                    </Col>
                    ))}
                  </Row>
              <Row>
                <Col className='text-center' xs>
                  <Button as="a" variant="outline-primary" className="mt-lg-2 mt-4" href="/blogs">View All Blogs</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
    </section>
  )
}

export default Blogs