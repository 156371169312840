// import node module libraries
import { Fragment, useEffect,useState } from 'react';
import { Row, Col, Badge, Button } from 'react-bootstrap';

// import sub components
//import CommonHeaderTabs from '../CommonHeaderTabs';
import ProjectSummary from './ProjectHeader';
//import BudgetSection from './BudgetSection';
import Runbooks from './Runbooks';
import Axios from "NewApi";
import Url from 'components/Url';
import ProjectHeader from './ProjectHeader';
import ProjectIntructions from './ProjectInstructions';
import UploadProject from './UploadProject';
import {
	BsRobot,
  } from "react-icons/bs";

import IGBot from './IGBot';

const ProjectOverview = (props) => {
    
	const projectId = props.match.params.projectId;
	const careerDetailId = props.match.params.careerDetailId;
	const [projectDetails,setProjectDetails] = useState();
	const [careerDetails, setCareerDetails] = useState();
	//const [projectRunbooks,setProjectRunbooks] = useState();
    const url1 = Url();
    const url = url1["url"];	
    const token = localStorage.getItem("stutoken");
	const studentId = localStorage.getItem("studentId");	
	const [load,setLoad] = useState(false);
	const [showSuggestions, setShowSuggestions] = useState(false);


	const handleStartVI = async () => {
		try {
		  const response = await Axios.get("getCareerDetails/" + careerDetailId, {
			headers: {
			  "Content-type": "application/json",
			  Authorization: `Bearer ${token}`,
			  "X-USER-ID": studentId,
			},
		  });
	
		  setCareerDetails(response.data.careerDetails);
		  if (!response.data.careerDetails?.student_vi_project_id)
			//window.location.href = `/lms/online-training/video/${topicId}/${encryptedCareerDetailId}`;
		  //else
			window.location.href = `/lms/online-training/project-selection/${careerDetailId}`;
		  //setLoading(false);
		} catch (error) {
		  console.error("Error fetching projects:", error);
		  //setLoading(false);
		}
	
		// window.location.href = `/lms/online-training/video/${topicId}/${encryptedCareerDetailId}`;
	  };


	useEffect(() => {
		handleStartVI();
		console.log(careerDetailId);

		let apiURL =  "getLMSProjectDetails/" + careerDetailId;
		Axios.get(
			apiURL,
			{headers: {
					"Content-type": "Application/json",
					"Authorization": `Bearer ${token}`,
					"X-USER-ID": studentId
					}   
				}
		  )
		  .then((response) => {
			  //var response = response.data.virtualInternshipList;
			  //console.log(response.data);
			  setProjectDetails (response.data.projectDetails[0]);
			},
			(error) => {
			  var status = error.response.status
			}
		  );	
	},[projectId , load]);	

	const resetProject = () => {
		let apiURL =  "deleteProjectTest";
		Axios.post(
			apiURL,
			{careerDetailId},
			{headers: {
					"Content-type": "Application/json",
					"Authorization": `Bearer ${token}`,
					"X-USER-ID": studentId
					}   
				}
		  )
		  .then((response) => {
			  //var response = response.data.virtualInternshipList;
			  //console.log(response.data);
			  alert(response.data.message);
			},
			(error) => {
			  var status = error.response.status
			}
		  );			
	};
	return (
		<Fragment>
			
			{/* <Row>
				<Col xs={12} className="mb-3" >
					<h2 style={{"color":"var(--geeks-primary)"}}>{projectDetails?.project_name}</h2>
				</Col>

            	<Col xs={12} className="d-flex flex-row mb-2">
					<div>
					<span>Current Status: 
						<Badge className="small ms-1" bg={projectDetails?.project_status ==0 ? "secondary" : projectDetails?.project_status == 1 ? "warning" : projectDetails?.project_status == 2 ? "success" : projectDetails?.project_status == 3 ? "danger" : "secondary"}>
							{projectDetails?.project_status_desc}
						</Badge>
						</span>
						</div>
						<div>
				{projectDetails?.project_status == 2 && 
					<a href={projectDetails?.online_assessment_link} className="btn btn-primary btn-sm ms-2">Go to Online Assessment</a>
				}		
				</div>					
				</Col>

			</Row>			 */}
			<Row>
				<Col xs={12}>
					<Row>
						{/* <Col xs={12} className="mb-4">
							<ProjectHeader />
						</Col>						 */}
						<Col xs={12} className="mb-4">
							{/* project summary section */}
							{console.log(careerDetails)}
							<h2>{careerDetails?.subject} - {careerDetails?.plan == 90 ? `3 Months`:`1 Month` }</h2>
						</Col>						
						<Col xs={12} className="mb-4">
							{/* project summary section */}
							<ProjectIntructions projectDetails={projectDetails} />
						</Col>

						{/* <Col md={12} className="mb-4">
							<Runbooks projectDetails={projectDetails} />
						</Col> */}

						<Col md={12} className="mb-4">
							{/* upcoming deadlines table section */}
							<UploadProject projectDetails={projectDetails} load={load} setLoad={setLoad} />
						</Col>

					</Row>
				</Col>
				{/* <Col><button className="btn btn-sm btn-primary" onClick={resetProject}>Reset Project</button>
					<br />
					<span className="small">Note: This button is added only for testing to delete the existing project and restart the flow (Once the project is deleted start over the process by going to the "Overview" page of the course) </span>
				</Col>								 */}
			</Row>

{/* Wrapper for button and AICard */}
	<div
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 1000,
          textAlign: 'center',
        }}
      >

        <Button
          variant="primary"
          className="rounded-circle"
          style={{
            width: '60px',
            height: '60px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10px' /* Ensures there is space between the button and the card */
          }}
          onClick={() => {
            setShowSuggestions(!showSuggestions);
          }}
        >
          <BsRobot size={30} />
        </Button>
	  </div>
	  <IGBot
          showSuggestions={showSuggestions}
          setShowSuggestions={setShowSuggestions}
		  studentId={studentId}
		  careerDetailId={careerDetailId}
        />
		</Fragment>
	);
};

export default ProjectOverview;
