// import node module libraries
import { Link } from "react-router-dom";
import { Col, Row, Container, Modal } from "react-bootstrap";

// import media files
import Avatar6 from "assets/images/avatar/avatar-6.jpg";
import Avatar8 from "assets/images/avatar/avatar-8.jpg";
import Avatar10 from "assets/images/avatar/avatar-10.jpg";
import Avatar11 from "assets/images/avatar/avatar-11.jpg";
import Avatar12 from "assets/images/avatar/avatar-12.jpg";
import Avatar13 from "assets/images/avatar/avatar-13.jpg";
import Avatar14 from "assets/images/avatar/avatar-14.jpg";
import MentorsImageSection from "./MentorsImageSection";

const HeroMentor = () => {
  const scrollToView = (e) => {
    e.preventDefault();
    var element = document.querySelector("#topMentors");

    // smooth scroll to element and align it at the bottom
    element.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToView1 = (e) => {
    e.preventDefault();
    var element = document.querySelector("#becomeamentor");

    // smooth scroll to element and align it at the bottom
    element.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <section className="py-lg-8 py-4 bg-white">
      <Container>
        <Row className="align-items-center justify-content-lg-start justify-content-center">
          <Col lg={6} className="mb-6 mb-lg-0">
            <div>
              {/* <h1 className="display-1 fw-bolder">1-on-1</h1>
              <h1 className="display-1 fw-bolder">Mentorship</h1> */}
               <h1 className="display-1 fw-bolder text-wrap text-break">1-on-1</h1>
               <h1 className="display-2 display-md-3 display-lg-2 fw-bolder text-wrap">
      Mentorship
    </h1>
              <p
                className="pe-lg-10 mb-5"
                style={{
                  fontSize: "16px",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.56",
                  textAlign: "left",
                }}
              >
                Elevate your career with personalized mentorship from top-tier experts across diverse domains.Book a session today and embark on a journey to elevate your career!
              </p>
              <Link to="#" onClick={scrollToView} className="btn btn-primary">
                Find a Mentor
              </Link>
              <Link to="/mentor/register" className=" ms-2 btn btn-secondary">
                Become a Mentor
              </Link>              
              <Link to="/mentor/login" className="ms-2 btn btn-primary pt-2 mt-3 mt-sm-0 pr-3" >
           
                Mentor Login
              </Link>  
              {/* <Link to="#" className="btn btn-outline-secondary mx-2">
                View Certificate
              </Link> */}
            </div>

          </Col>
          <Col lg={6} xs={12}>
            <Row>
              <MentorsImageSection />
            </Row>

          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroMentor;
