import axios from "axios";
import serviceConfig from "./components/Url";

const Api = axios.create({
  baseURL: serviceConfig().url,
});

Api.interceptors.request.use((config)=>
{
  // console.log(config.headers.Authorization);
  config.headers.Authorization =  "Bearer " + localStorage.getItem("stutoken");

  return config ;
}
)
export const ENDPOINTS = {
  GET_ALL_SPECIALIZATIONS: "/GetAllSpecilizations",
  GET_ALL_LOCATIONS: "/area",
  GET_ALL_RESUMES: "/getResumeByStudentId",
  GET_RESUME_BY_ID: "/getResumeByResumeId",
  UPLOAD_PROFILE_IMAGE: "/ResumePicUpload",
  CREATE_RESUME: "/createresume_basic_detail",
  UPDATE_RESUME: "/updateresume_basic_detail",
  DELETE_RESUME: "/delresume_basic_detailById",
  COPY_RESUME: "/copyResume",
  CREATE_PROFESSIONAL_SUMMARY: "/createprofessional_summary",
  UPDATE_PROFESSIONAL_SUMMARY: "/updateprofessional_summary",
  CREATE_EXP_DETAIL: "/createresume_experience_detail",
  UPDATE_EXP_DETAIL: "/updateresume_experience_detail",
  DELETE_EXP_DETAIL: "/delresume_experience_detailById",
  CREATE_EDU_DETAIL: "/createresume_education_detail",
  UPDATE_EDU_DETAIL: "/updateresume_education_detail",
  DELETE_EDU_DETAIL: "/delresume_education_detailById",
  CREATE_SKILL: "/createresume_skill",
  UPDATE_SKILL: "/updateresume_skill",
  DELETE_SKILL: "/delresume_skillById",
  CREATE_EXTRA_CURRICULAR_ACTIVITIE: "/createresume_extra_curricular_activitie",
  UPDATE_EXTRA_CURRICULAR_ACTIVITIE: "/updateresume_extra_curricular_activitie",
  DELETE_EXTRA_CURRICULAR_ACTIVITIE: "/delresume_extra_curricular_activitieById",
  CREATE_HOBBY: "/createresume_hobby",
  UPDATE_HOBBY: "/updateresume_hobby",
  DELETE_HOBBY: "/delresume_hobbyById",
  CREATE_REFERENCE: "/createresume_Reference",
  UPDATE_REFERENCE: "/updateresume_Reference",
  DELETE_REFERENCE: "delresume_ReferenceById",
  CREATE_COURSE: "/createresume_course",
  UPDATE_COURSE: "/updateresume_course",
  DELETE_COURSE: "/delresume_courseById",
  CREATE_INTERNSHIP: "/createresume_internship",
  UPDATE_INTERNSHIP: "/updateresume_internship",
  DELETE_INTERNSHIP: "/delresume_internshipById",
  CREATE_LANGUAGE: "/createresume_language",
  UPDATE_LANGUAGE: "/updateresume_language",
  DELETE_LANGUAGE: "/delresume_languageById",
};

export const getS3Url = (bucket) => serviceConfig().s3 + bucket;

export default Api;
