import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Url from "components/Url";
import Select from "react-select";

const Sites = () => {
    const [allLocations, setAllLocations] = useState([]);
    const [allSkills, setAllSkills] = useState([]);
    const [selectedNavItem, setSelectedNavItem] = useState("internshipByLocation");
    const [search, setSearch] = useState("");
    const [courseList, setCourseList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [tabs, setTabs] = useState([]);
    const [endDate, setEndDate] = useState("");
    const [dataLoaded, setDataLoaded] = useState(false)
    const url1 = Url();
    const url = url1["url"];

    const handleNavItemClick = (navItem) => {
        setSelectedNavItem(navItem);
    };

    useEffect(() => {
        const api1 = url + "getLocationMasterDetails";
        fetch(api1, {
            method: "GET",
        })
            .then((apidata1) => {
                return apidata1.json();
            })
            .then((actualdata1) => {
                setAllLocations(actualdata1);
            });
    }, []);

    useEffect(() => {
        const api1 = url + "getSoftwareSkill";
        fetch(api1, {
            method: "GET",
        })
            .then((apidata1) => {
                return apidata1.json();
            })
            .then((actualdata1) => {
                setAllSkills(actualdata1);
            });
    }, []);


    useEffect(() => {
        const api = url + "getAllActiveVICourses";

        fetch(api, {
            method: "GET",
        })
            .then((apidata) => {
                return apidata.json();
            })
            .then((actualdata) => {
                setCourseList(actualdata.courseData);
                setCategoryList(actualdata.categoryList);
                setTabs(actualdata.planList);
                setEndDate(actualdata.courseData[0].discount_end_date)
                setDataLoaded(true);
                console.log(actualdata.courseData);
                // console.log(actualdata.courseData.subject_na);
            });
    }, []);

    const renderContent = () => {
        const commonLinkStyle = "text-dark mb-3";

        switch (selectedNavItem) {
            case "internshipByLocation":
                return (
                    <div className="row render-content text-dark">
                        {allLocations && allLocations.length > 0 && (
                            allLocations.map(location => (
                                <div className="col-md-3" key={location}>
                                    <Link className={commonLinkStyle} to={`/Internship/internships-in-${location.label}`}>
                                        Internship in {location.label}
                                    </Link>
                                </div>
                            ))
                        )}
                    </div>
                );
            case "internshipBySkills":
                return (
                    <div className="row render-content">
                        {allSkills && allSkills.length > 0 && (
                            allSkills.map(skills => (
                                <div className="col-md-3" key={skills}>
                                    <Link className={commonLinkStyle} to={`/Internship/${skills.label}`}>
                                        Internships in {skills.label}
                                    </Link>
                                </div>
                            ))
                        )}
                    </div>
                );
            case "generalLink":
                return (
                    <div className="row render-content text-dark">
                        <div className="row ">
                            <Link className="col-md-2 mb-3 text-dark" to="/student/registration">Student Signup</Link>
                            <Link className="col-md-2 mb-3 text-dark" to="/study-abroad">Study Abroad</Link>
                            {/* <Link className="col-md-2 mb-3 text-dark" to="/OnlineTraining">Online Training</Link> */}
                            <Link className="col-md-2 mb-3 text-dark" to="/blogs">Blogs</Link>
                            <Link className="col-md-2 mb-3 text-dark" to="/AboutUs">About us</Link>
                            <Link className="col-md-2 mb-3 text-dark" to="/student-dashboard">Student Dashboard</Link>
                            <Link className="col-md-2 mb-3 text-dark" to="/virtual-internship">Virtual-Internships</Link>
                            <Link className="col-md-2 mb-3 text-dark" to="/Privacy">Privacy</Link>
                            <Link className="col-md-2 mb-3 text-dark" to="/ContactUs">Contact us</Link>



                        </div>
                    </div>
                );
            case "Virtual-Internships":
                const groupedCourses = courseList.reduce((acc, subjects) => {
                    const duration = subjects.course_duration;
                    acc[duration] = acc[duration] || [];
                    acc[duration].push(subjects);
                    return acc;
                }, {});

                return (
                    <div className="row render-content" style={{marginTop:"20px"}}>
                        <div className="row  text-dark">
                            {Object.keys(groupedCourses).map(duration => (
                                <div key={duration}>
                                    <h4 className="mt-4 mb-3">{`${duration} month Internship Courses`}</h4>
                                    <div className="row render-content">
                                        {groupedCourses[duration].map(subjects => (
                                            <div className="col-md-3" key={subjects}>
                                                <div className="row">
                                                    <Link className={commonLinkStyle} to={subjects.generated_url}>
                                                        Internships in {subjects.subject_name}
                                                    </Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>


                    </div>

                );


            default:
                return null;
        }
    };


    return (
        <>
            <Helmet>
                <title>Site-Map</title>
            </Helmet>


            <style>
                {`
                    .render-content {
                        color: black;
                        background-color: white;
                        padding: 20px;
                        border-radius: 10px;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                        margin-top: 20px;
                    }


                `}
            </style>


            <style>
                {`
                    .navItem {
                        position: relative;
                        display: inline-block;
                        cursor: pointer;
                        transition: color 0.3s;
                    }

                    .navItem::before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        bottom: -5px;
                        left: 0;
                        background-color: #007bff; /* You can change the color as per your preference */
                        transform-origin: bottom right;
                        transform: scaleX(0);
                        transition: transform 0.3s ease;
                    }

                    .navItem:hover::before,
                    .navItem.active::before {
                        transform: scaleX(1);
                    }
                `}
            </style>

            <div className="container mx-auto" style={{ marginTop: "40px" }}>
                <h3>Sitemap</h3>

                <div className="row text-dark shadow pt-3 bg-white rounded containerMenu" style={{ marginTop: "20px", fontSize: "13px", textAlign: window.innerWidth < 426 ? "center" : "left" }}>
                    <div className="col-md-3 col-sm-6">
                        <p
                            onClick={() => handleNavItemClick("internshipByLocation")}
                            className={`navItem ${selectedNavItem === "internshipByLocation" ? "active" : ""}`}
                        >
                            Internships by Location
                        </p>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <p
                            onClick={() => handleNavItemClick("internshipBySkills")}
                            className={`navItem ${selectedNavItem === "internshipBySkills" ? "active" : ""}`}
                        >
                            Internships by Skills
                        </p>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <p
                            onClick={() => handleNavItemClick("Virtual-Internships")}
                            className={`navItem ${selectedNavItem === "Virtual-Internships" ? "active" : ""}`}
                        >
                            Virtual-Internships
                        </p>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <p
                            onClick={() => handleNavItemClick("generalLink")}
                            className={`navItem ${selectedNavItem === "generalLink" ? "active" : ""}`}
                        >
                            General Links
                        </p>
                    </div>
                </div>

                {renderContent()}
            </div>


        </>
    );
};


export default Sites;
