// import node module libraries
import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import Url from "../Url";
import { Fragment } from 'react';

const MessageBox = (props) => {
	const [show, setShow] = useState(true);
	return (
		<Fragment>
			<Alert variant={props.type ? props.type : "warning"} onClose={() => setShow(false)} dismissible>
				<p>{props.message}</p>
			</Alert>
		</Fragment>
	);
}
export default MessageBox;