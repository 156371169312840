// import node module libraries
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Image
} from 'react-bootstrap';

// import media files
import Avatar from 'assets/images/avatar/avatar-1.jpg';
import { useEffect, useState } from 'react';
import Url from 'components/Url';


const Profile = () => {
    const url1 = Url();
    const url = url1["url"];
    const s3 = url1["s3"];
    const id = window.localStorage.getItem("id");
    const [empData, setEmpData] = useState()

        useEffect(() => {
            let status;
            const api3 = url + "getEmployerProfileById" + "/" + id;
            fetch(api3, {
              method: "GET",
            })
              .then((apidata1) => {
                status = apidata1.status;
                return apidata1.json();
              })
              .then((actualdata1) => {
               console.log(actualdata1);
               setEmpData(actualdata1)
              });
          }, []);
    return (
        <section className="pt-5 pb-6 bg-white">
            <Container>
                <Row>
                    <Col lg={{ offset: 2, span: 8 }}>
                        <div className="d-md-flex justify-content-between align-items-center border-bottom pb-6 mb-6">
                            <div className="d-flex align-items-center">
                                {empData?.org_logo
                                ?
                                <Image src={s3+"imgupload/"+empData?.org_logo} alt="" className="avatar avatar-lg rounded-circle" />
                                :
                                <Image src={Avatar} alt="" className="avatar avatar-lg rounded-circle" />
                            }
                                <div className="ms-4">
                                    <h3>{empData?.org_name}</h3>
                                    <div className="">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                            </svg>
                                        </span><span>{empData?.address}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 mt-lg-0">
                                <Link to="/empdashboard/edit-profile" className="btn btn-primary">Edit profile</Link>
                            </div>
                        </div>
                        <div>
                            <h3 className="mb-5">Organization Details</h3>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <span className="text-dark">Name:</span>
                                </Col>
                                <Col md={9}>
                                    <span className="">{empData?.name}</span>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <span className="text-dark">Email:</span>
                                </Col>
                                <Col md={9}>
                                    <Link to="#" className="">{empData?.email}</Link>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <span className="text-dark">Phone number:</span>
                                </Col>
                                <Col md={9}>
                                    <span className="">{empData?.mobileno}</span>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <span className="text-dark">Address:</span>
                                </Col>
                                <Col md={9}>
                                    <span className="">{empData?.address}</span>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <span className="text-dark">Organisation Name:</span>
                                </Col>
                                <Col md={9}>
                                    <span className="">{empData?.org_name}</span>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <span className="text-dark">Website:</span>
                                </Col>
                                <Col md={9}>
                                    <span className="">{empData?.website}</span>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col md={3}>
                                    <span className="text-dark">Description:</span>
                                </Col>
                                <Col md={9}>
                                   <p>{empData?.org_description}</p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Profile