import React from "react";
import { useState } from "react";
import "./Colorswitch.css";
import Url from "components/Url";

const Colorswitch = ({ templateData, resumeId,setRefreshData}) => {
  // console.log("templateData", templateData);

  const token = localStorage.getItem("stutoken");

  const templateColors = [
    templateData?.template_color1,
    templateData?.template_color2,
    templateData?.template_color3,
    templateData?.template_color4,
    templateData?.template_color5,
  ];

  const [backgroundColor, setBackgroundColor] = useState(templateData?.template_color1);

  const url1 = Url();
  const url = url1["url"];

  const apiUrl = url + "updateTemplateColorCode";

  const updateColor = async (color) => {
    const requestOptions = {
      resumeId,
      templateColorCode: color,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(requestOptions),
      });

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error updating color:", error);
      throw error;
    }
  };

  const handleColorChange =(newColor) => {
    const status =updateColor(newColor);
    status
      .then((res) => {
        console.log("res", res);
       // window.location.reload(false);
        setRefreshData(true);
      
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleColorPicker = (e) => {
    const newColor = e.target.value;
    setBackgroundColor(newColor);
    handleColorChange(newColor);
  };

  const colorTab =
    templateColors &&
    templateColors.map((color) => (
      <div
        key={color}
        className="color-option"
        style={{
          backgroundColor: color,
        }}
        onClick={() => handleColorChange(color)}
      ></div>
    ));


  return (
    <>
      <div  >
        <div className="color-picker" >
          <div className="color-options">
            {colorTab}

            {/* input RGV color*/}
            <div className="picker">
              <input
                type="color"
                id="favcolor1"
                value={backgroundColor}
                onChange={handleColorPicker}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Colorswitch;
