import React, { useState, useEffect } from "react";
//import { FacebookShareButton, FacebookIcon } from "react-share";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
//import Navbar from './Studentnavbar'
//import Navbar from '../NavbarFullLength/NavbarFullLength'
import Card from "react-bootstrap/Card";
// import CardDeck from "react-bootstrap/CardDeck";
//import Form from "react-bootstrap/Form";

import Url from "components/Url";
import { convertToHoursMinutes } from "helper/formatTime";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const ViewCourse = () => {
  const { id } = useParams();
  // console.log("ID = ", id);

  //alert(sname)

  const url1 = Url();
  const url = url1["url"];
  //const [s3url, setS3url] = useState(s3 + 'blogpicupload/')
  //const [shareurl, setShareurl] = useState(window.location.href)

  const [getvideo, setGetvideo] = useState([]);

  const [mobilescr, setMobilescr] = useState(false);
  const [getdevwidth, setGetdevwidth] = useState("");

  useEffect(() => {
    setGetdevwidth(window.innerWidth + "px");

    if (window.innerWidth <= 500) {
      setMobilescr(true);
    }
    //remove the sessionStorage items is they are set
    sessionStorage.removeItem("buynowLogin");
    sessionStorage.removeItem("redirectURL");
    //console.log(getdevwidth)
  }, [getdevwidth]);

  //let i=0

  ///////////////Render from top/////////////////////
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const api = url + "getVideoByIds/" + id;
    fetch(api, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        // console.log(actualdata1);
        setGetvideo(actualdata1);
      });
  }, []);

  const getId = (url) => {
    //alert(url)

    var regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
      return match[2];
    } else {
      return "error";
    }
  };

  return (
    <div style={{ backgroundColor: "whitesmoke" }} className="pb-12">
      {/*------------ Desktop View --------------*/}

      {/*<Row className="mb-5">
                <Navbar />
            </Row>
    <Row className="mt-5"></Row>*/}

      {!mobilescr ? (
        <div className="d-none d-sm-block studashwidth">
          {/*-----------Desktop view-------------*/}

          <Col className="mt-5 videorow">
            {getvideo.length > 0 &&
              getvideo.map((item) => (
                <Col className="ml-0">
                  {/* <CardDeck className="mt-4 mb-5"> */}
                  <Card
                    className="ml-5 mr-5 videoboxrext"
                    style={{
                      marginLeft: "300px",
                      marginRight: "300px",
                      boxShadow: "10px 20px 20px 10px rgba(0,0,0,.3)",
                    }}
                  >
                    <iframe
                      title={item.id}
                      src={"//www.youtube.com/embed/" + getId(item.video_url)}
                      frameBorder="0"
                      allowFullScreen="allowfullscreen"
                      style={{
                        border: "none",
                        height: "300px",
                        display: "block",
                        width: "100%",
                        overflowX: "hidden",
                        overflowY: "auto",
                      }}
                    />

                    <Card.Body className="videobody1">
                      <Card.Title className="Blogcarddate mt-3">
                        <p className="ml-3 mr-5">
                          {convertToHoursMinutes(item.video_length * 3600)}
                        </p>
                      </Card.Title>

                      <Card.Text className="videosdesc ml-3 mr-3 mt-4 mb-2">
                        {item.video_description}
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <Col md={12} className="d-flex justify-content-end">
                        <Link to="/OnlineTraining" className="btn btn-link">
                          {"<< Back"}
                        </Link>
                      </Col>
                    </Card.Footer>
                  </Card>

                  {/* </CardDeck> */}
                </Col>
              ))}
            <Row className="pb-3"></Row>
          </Col>
        </div>
      ) : (
        <div className=" ">
          {/*-----------Mobile view-------------*/}
          <Row
            className=" mt-3"
            style={{ textAlign: "center", color: "#212529" }}
          >
            <b className="">Online Training</b>
          </Row>

          <Col className="mt-5 ">
            {getvideo.length > 0 &&
              getvideo.map((item) => (
                <Col className="ml-0">
                  {/* <CardDeck className="mt-4 mb-5"> */}
                  <Card className="ml-0 mr-5 ">
                    <iframe
                      src={"//www.youtube.com/embed/" + getId(item.video_url)}
                      title={item.id}
                      frameborder="0"
                      allowfullscreen="allowfullscreen"
                      style={{
                        border: "none",
                        height: "300px",
                        display: "block",
                        width: "100%",
                        margin: "0",
                        padding: "0",
                      }}
                    />

                    <Card.Body className="">
                      <Card.Title className="Blogcarddate mt-3">
                        <p className="ml-0 mr-5">
                          {" "}
                          {convertToHoursMinutes(item.video_length * 3600)}{" "}
                        </p>
                      </Card.Title>

                      <Card.Text className=" ml-0 mr-3 mt-4 mb-2">
                        {item.video_description}
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <Col md={12} className="d-flex justify-content-end">
                        <Link to="/OnlineTraining" className="btn btn-link">
                          {"<< Back"}
                        </Link>
                      </Col>
                    </Card.Footer>
                  </Card>

                  {/* </CardDeck> */}
                </Col>
              ))}
          </Col>
        </div>
      )}
    </div>
  );
};

export default ViewCourse;
