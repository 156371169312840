// import node module libraries
import { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';

// import sub custom components
import Sidebar from './sidebar/Sidebar';
import ChatBox from './chatbox/ChatBox';
import Url from 'components/Url';
const Chat = ({ids, names, internshipList}) => {
    const url1 = Url();
    const url = url1["url"];
    const [hideChatBox, setHideChatBox] = useState(false);
    const empId = localStorage.getItem("id");
    const stuId = localStorage.getItem("studentId");
    const token = localStorage.getItem("emptoken")
    const stuToken = localStorage.getItem("stutoken")
    const [chatList, setChatList] = useState()
    const [name, setName] = useState("")
    const [chats, setChats] = useState()
    const [message, setMessage] = useState("")
    const [id, setId] = useState()
    
    let message_from;
    let t
    if(token){
      message_from = "E"
      t = token
    }else{
      message_from = "S"
      t = stuToken
    }

    const getInitiatedChats = () => {
        const api = url + "getAllInitiatedChats/" + empId + "/" + "E" + token;
        fetch(api, {
          method: "GET",
        })
          .then((apidata1) => {
            return apidata1.json();
          })
          .then((actualdata1) => {
            console.log(actualdata1.initiatedChatList);
            setChatList(actualdata1.initiatedChatList);
          });
      }

      useEffect(() => {
        setId(ids)
        setName(names)
        getChatMessage(ids)
      },[ids])

      const getStudentChats = () => {
        const api =
      url +
      "getStudentInternshipListWithChat/" +
      stuId +
      "/" +
      "S" +
      stuToken;
    //Umesh - 20220907 - end
    fetch(api, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        console.log(actualdata1);
        setChatList(actualdata1);

      });
      }

      useEffect(() => {
        if(token){
          getInitiatedChats()
        }
        if(stuToken){
          getStudentChats()
        }
      },[])

      const getChatMessage = (intid=id) => {
        setChats()
        if (intid) {
          const api1 =
            url +
            "getChatByInternshipApplicationId/" +
            intid +
            "/" +
            message_from +
             t;
          fetch(api1, {
            method: "GET",
          })
            .then((apidata1) => {
              return apidata1.json();
            })
            .then((actualdata1) => {
              console.log(actualdata1)
              setChats(actualdata1);
            });
        } else {
          // setChatTranscript([]);
        }
      };

      const postChatMessage = () => {
        if (message) {
          if (chats.length <= 0 && message_from === "S") {
            return;
          } else {
            const api = url + "postMessage";
    
            fetch(api, {
              method: "POST",
              body: JSON.stringify({
                message_from,
                internshipApplicationId: id,
                message,
                token: t,
              }),
              headers: { "Content-Type": "application/json" },
            })
              .then((apidata) => {
                //console.log(apidata.body)
                return apidata.json();
              })
              .then((actualdata) => {
                if (actualdata["message"] === "message posted") {
                  getChatMessage(id);
                  setMessage("");
                  document.getElementById("chatWindow").scrollIntoView();
                } else {
                  alert("Chat is offline, please refresh");
                }
              });
          }
        }
      };

    return (
        <div className="chat-layout" id="db-wrapper">
            <main id="page-content" className="m-0">
                <Container as="section" fluid className="p-2">
                    <Row className="g-0">
                        <Col xl={3} lg={12} md={12} xs={12} className='d-sm-block d-none'>
                            <Sidebar getStudentChats={getStudentChats} setChatList={setChatList} getInitiatedChats={getInitiatedChats} internshipList={internshipList} setId={setId} id={id} setName={setName} chatList={chatList} hideChatBox={hideChatBox} getChatMessage={getChatMessage} setHideChatBox={setHideChatBox} />
                        </Col>
                        <Col xl={3} lg={12} md={12} xs={12} className='d-sm-none d-block'>
                            {!id && <Sidebar setId={setId} id={id} setName={setName} chatList={chatList} hideChatBox={hideChatBox} getChatMessage={getChatMessage} setHideChatBox={setHideChatBox} />}
                        </Col>
                        <Col xl={9} lg={12} md={12} xs={12}>
                        <ChatBox setId={setId} id={id} setMessage={setMessage} message={message} postChatMessage={postChatMessage} chats={chats} name={name} />  
                      </Col>
                    </Row>
                </Container>
            </main>
        </div>
    );
};

export default Chat;
