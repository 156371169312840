import { Fragment } from "react";
import { Col, Row } from "react-bootstrap";

const OurStats = () => {
  const title = "Our reach";
  const description = `Spanning across diverse fields and industries, Internshipgate's expansive platform connects students with internship opportunities in various domains, fostering their growth and paving the way for their seamless entry into the corporate world.`;

  const counters = [
    {
      id: 1,
      title: "Learners",
      value: "1.2L+",
    },
    {
      id: 2,
      title: "Instructors",
      value: "1K+",
    },
    {
      id: 3,
      title: "Courses",
      value: "100+",
    },
    {
      id: 4,
      title: "Course enrollments",
      value: "20K+",
    },
  ];
  return (
    <Fragment>
      {/* <SectionHeading title={title} description={description} /> */}
      <Row>
        <Col
          md={6}
          sm={12}
          className="offset-right-md-6 my-7 m-auto text-center"
        >
          <h2 className="display-3 mb-3 fw-bold" style={{ color: "#2c3895" }}>
            {title}
          </h2>
          <p className="lead">{description}</p>
        </Col>
      </Row>
      <Row className="text-center">
        {counters.map((item, index) => {
          return (
            <Col lg={3} md={6} sm={6} xs={6} key={index}>
              {/* Counter */}
              <div className="border-top pt-4 mt-6 mb-5">
                <h1 className="display-3 fw-bold mb-0">{item.value}</h1>
                <p className="text-uppercase text-muted">{item.title}</p>
              </div>
            </Col>
          );
        })}
      </Row>
    </Fragment>
  );
};

export default OurStats;
