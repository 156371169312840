// import node module libraries
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Button,
  Modal,
  Row,
  Col,
  Breadcrumb,
  Spinner,
  Form,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { ChevronLeft, ChevronRight } from "react-feather";

// import custom components
import { FormSelect } from "components/elements/form-select/FormSelect";
import Sidebar from "./Sidebar";
import Select from "react-select";
import CandidateInfoCard from "../common/cards/CandidateInfoCard";
import CandidateInfoData from "../data/CandidateInfoData";
import ChatApp from "../chat/Chat";
import Url from "components/Url";
const Applications = () => {
  const statusInfo = useParams();
  const appStatus = "recommended";
  const intDetails = JSON.parse(window.localStorage.getItem("intDetails"));
  const [skillsOptions, setSkillsOptions] = useState();
  const [locationOptions, setLocationOptions] = useState();
  const [skills, setSkills] = useState([]);
  const [location, setLocation] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const id = window.localStorage.getItem("intId");
  const [name, setName] = useState("");
  const [ids, setId] = useState();
  const [load, setLoad] = useState(false);
  const url1 = Url();
  const url = url1["url"];
  const [loading, setLoading] = useState(false);
  const [candidateInfo, setCandidateInfo] = useState();
  const [internshipRecord, setInternshipRecord] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [pageCount,setPageCount] = useState();
  const [totalCount, setTotalCount] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const RecordsPerPage = 10;
  const pagesVisited = pageNumber * RecordsPerPage;

  const changePage = ({ selected }) => {
    //console.log(selected);
    setPageNumber(selected);
  };

  //Logic start for filters other than AI recommended

  const handleSkillChange = (e) => {
    const changedSkills = e;
    const selectedSkillsArray = changedSkills.map((skill) => skill.value);
    setSelectedSkills(selectedSkillsArray);
    setPageNumber(1);
  };

  const handleLocationChange = (e) => {
    const changedLocations = e;
    const selectedLocationsArray = changedLocations.map(
      (location) => location.value
    );
    setSelectedLocations(selectedLocationsArray);
    setPageNumber(1);
  };

  const filteredStudents = candidateInfo?.filter((student) => {
    // Convert the student's skills and locations to an array
    const studentSkillsArray = student.software_skill?.split(",");
    const studentLocationArray = student.student_location?.split(",");

    const skillMatch =
      selectedSkills.length === 0 ||
      selectedSkills.some((selectedSkill) =>
        studentSkillsArray.includes(selectedSkill)
      );

    const locationMatch =
      selectedLocations.length === 0 ||
      selectedLocations.some((selectedLocation) =>
        studentLocationArray.includes(selectedLocation)
      );

    return skillMatch && locationMatch;
  });

  //Logic end for filters other than AI recommended

  var displayRecords = (
    appStatus === "recommended" ? candidateInfo : filteredStudents
  )
    ?.map((item, index) => {
      return (
        <CandidateInfoCard
          item={item}
          key={index}
          infoType={appStatus}
          handleShow={handleShow}
          employerInternshipId={id}
          setId={setId}
          setName={setName}
          internshipApplicationId={
            appStatus === "recommended"
              ? item.internship_application_id
              : item.id
          }
          url={url}
          setLoad={setLoad}
          internshipRecord={internshipRecord}
        />
      );
    });

  // console.log(displayRecords);

  useEffect(() => {
    setCandidateInfo([]); //reset the data first,
    let statusID = "";
    if (appStatus === "shortlisted") {
      statusID = "/1";
    }
    if (appStatus === "hired") {
      statusID = "/2";
    }
    if (appStatus === "rejected") {
      statusID = "/4";
    }
    var api = "";

    var skillsFilter = skills?.map((item) => item.value).join(",");
    var locationFilter = location?.map((item) => item.value).join(",");
    var params = "";
    if (skillsFilter) {
    params = params + "/" + skillsFilter;
    } else {
    params = params + "/null";
    }
    if (locationFilter) {
    params = params + "/" + locationFilter;
    } else {
    params = params + "/null";
    }
    // console.log("SkillsFilter", skillsFilter);
    
    console.log(pageNumber);

    api = url + "inviteCandidate/" + id + params + "/" + (parseInt(pageNumber)+parseInt(1));
    fetch(api, {
    method: "GET",
    })
    .then((apidata1) => {
        return apidata1.json();
    })
    .then((actualdata1) => {
        setCandidateInfo(actualdata1.studentList);
        setTotalCount(actualdata1.totalRecords);
        setPageCount(Math.ceil(actualdata1.totalRecords/RecordsPerPage));
        setInternshipRecord(actualdata1.internshipSkills)
        setLoad(false);
    });

    // console.log("candidateInfo ", candidateInfo);
    //setLoad(false);
  }, [load, skills, location, pageNumber]);

  useEffect(() => {
    const locapi = url + "getLocationList";
    fetch(locapi, {
      method: "GET",
    })
      .then((locdata) => {
        return locdata.json();
      })
      .then((locdata1) => {
        setLocationOptions(locdata1);
      });

    //get software skills
    const api4 = url + "getSoftwareSkill";
    fetch(api4, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        setSkillsOptions(actualdata1);
      });
  }, []);

  return (
    <Fragment>
      <section className="pt-5 pb-6 bg-white">
        <Container>
          <Row className="border-bottom pb-5 g-0 align-items-center">
            <Col md={9}>
              <div className="mb-4">
                <Breadcrumb>
                  <Breadcrumb.Item href="/empdashboard" as="li">
                    Dashboard
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Internship Single</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <h1 className="mb-md-0">
                Applications for{" "}
                <span className="text-secondary">{intDetails.name}</span>
              </h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pb-8 bg-white">
        <Container>
          <Row>
            <Col lg={3}>
              <Sidebar setLoad={setLoad} candidateInfo={candidateInfo} />
            </Col>
            <Col lg={6}>
              <div className="mt-6 mt-lg-0 mb-4">
                {load ? (
                  <Modal
                    show={load}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Body>
                      <Spinner size={30} /> Please Wait{" "}
                    </Modal.Body>
                  </Modal>
                ) : (
                  <>
                    {totalCount > 0 &&
                    <h3 className="mb-4">
                      Showing {(pageNumber*RecordsPerPage)+1}-{((pageNumber*RecordsPerPage)+10 > totalCount ? totalCount:(pageNumber*RecordsPerPage)+10 )} of {totalCount} 
                      {" " } candidates
                    </h3>
                      }
                    <Fragment>
                      <Row>
                        {displayRecords?.length > 0 ? (
                          displayRecords
                        ) : (
                          <Col>No matching records found.</Col>
                        )}
                      </Row>
                      
                      {totalCount > 0 &&
                      <ReactPaginate
                        previousLabel={<ChevronLeft size="14px" />}
                        nextLabel={<ChevronRight size="14px" />}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        containerClassName={
                          "justify-content-center mb-0 pagination"
                        }
                        previousLinkClassName={"page-link mx-1 rounded"}
                        nextLinkClassName={"page-link mx-1 rounded"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link mx-1 rounded"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"active"}
                      />
                      }
                    </Fragment>
                  </>
                )}
              </div>
            </Col>

            {/* Filter For AI recommended profiles */}
            <Col lg={3}>
            <div className="">
                <h2 className="mb-3">Filter</h2>
                <Form>
                <div className="mb-3">
                    <Form.Group className="mb-3" controlId="skills">
                    <Form.Label>Skills</Form.Label>
                    <Select
                        isMulti
                        options={skillsOptions}
                        onChange={(e) => setSkills(e)}
                    />
                    </Form.Group>
                </div>
                <div className="mb-3">
                    <Form.Group className="mb-3" controlId="locations">
                    <Form.Label>Locations</Form.Label>
                    <Select
                        isMulti
                        options={locationOptions}
                        onChange={(e) => setLocation(e)}
                    />
                    </Form.Group>
                </div>
                </Form>
            </div>
            </Col>
            
          </Row>
        </Container>
      </section>
      <Modal
        backdrop="static"
        show={show}
        onHide={handleClose}
        scrollable
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="chatModalLabel">
            Messages from all internships & jobs
          </Modal.Title>
        </Modal.Header>
        <ChatApp ids={ids} names={name} />
      </Modal>
    </Fragment>
  );
};

export default Applications;
