import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  createRef,
  Fragment,
  useRef,
} from "react";
import {
  Col,
  Container,
  Form,
  ProgressBar,
  Row,
  Accordion,
  Card,
  Modal,
  ListGroup,
  Button,
  Collapse
} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Spinner from "react-bootstrap/Spinner";
import { FiChevronDown, FiChevronUp, FiUser, FiX,FiGrid } from "react-icons/fi";
import { HiDocumentDuplicate, HiCheckCircle } from "react-icons/hi";
import { BiArrowBack } from "react-icons/bi";
import { BsCircleFill, BsCheckCircleFill, BsRobot, BsFillEyeFill,BsXLg,BsFillQuestionCircleFill } from "react-icons/bs";
import "./ProfessionalSummaryAICard.css";
import {FcLeft} from "react-icons/fc";
import Api, { ENDPOINTS, getS3Url } from "../api.js";

const EmploymentHistoryAICard = (props) => {
  {
    /*************************AI Suggestions modal - start **************/
  }

  const {
    setEmploymentSummary,
    employmentSummary,
    showEmployerSuggetion,
    setShowEmployerSuggetion,
    setEmploymentSummaryDescription
  } = props;

  const [open, setOpen] = useState(false);

  const [empSuggestionsSearchText, setEmpSuggestionsSearchText] = useState("");
  const [empSuggestionsWait,setEmpSuggestionsWait] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const studentId = localStorage.getItem("studentId");

  const getEmployerSuggestions = async () => {      

		if (studentId) {
      //if(resumeId){
        //console.log(resumeData.employmentSummary?.Description);
        //if(resumeData.employmentSummary?.Description){suggestionsSearchText
        setSuggestions([]);
        if(empSuggestionsSearchText){
          setEmpSuggestionsWait(true);
          try{
            
            let message = strip_html_tags(empSuggestionsSearchText);
            const ApiResponse = await Api.get(ENDPOINTS.GET_EMPLOYMENT_SUGGESTIONS + "/" + message);
            if(ApiResponse.data.suggestions.length > 0){
              setSuggestions(ApiResponse.data.suggestions);    
            }else{
              //toast.warning("No Suggestions, please check your keywords (only use 3-4 keywords)")
            }
          } catch (e) {
            console.log(e);
          }finally{
            setEmpSuggestionsWait(false);
          }
        }else{
          //toast.warning("Add some keywords to the employment summary field")
        } 
		} else {
      //toast.warning("Cannot get suggestions")
		}
    
	};
  
  function strip_html_tags(str)
  {
     if ((str===null) || (str===''))
         return false;
    else
     str = str.toString();
    return str.replace(/<[^>]*>/g, '');
  }
  const handleSuggestionLineClick = (event) => {
    event.preventDefault();
    let message = event.target.innerText;
    console.log(event);
    setEmploymentSummaryDescription(message);
  }  

  console.log(props.divPosition);
  return (
  <Fragment>
    
    <Card style={{right: props.divPosition.right, top: props.divPosition.top}} className= {showEmployerSuggetion ? `visible h-100 employment-ai-suggestion-card` : "d-none"}  >
      <Card.Body className="overflow-auto ps-2 ps-sm-4">
          <Row className="border-bottom pb-3">
          <Form.Group
            as={Col}
            lg={12}
            className="d-sm-flex"
            //controlId="suggestionsSearchText"
          >
            {/* Search box - start */}
            <Col xs={12} md={9} className="mt-2 mt-sm-0">
              
                <Form.Control
                  type="text"
                  placeholder="Team Member Order processing application, PHP,Mysql, ReactJS"
                  id="suggestionsSearchText"
                  name="suggestionsSearchText"
                  value={empSuggestionsSearchText}
                  onChange={(e) =>
                    setEmpSuggestionsSearchText(
                      e.target.value ? e.target.value : ""
                    )
                  }
                />

            </Col>
            {/* Search box - end */}            
            <Col xs={12} md={2} className="ms-2 mt-2 mt-sm-0">
              {/* Search <Robot> button / spinner after search - start */}
              {empSuggestionsWait ? (
                <Spinner animation="grow" className="ms-3" />
              ) : (
                <Button
                onClick={getEmployerSuggestions}
                //style={{ cursor: "pointer" }}                
                >
                  <BsRobot
                    size={20}

                  />
                </Button>
              )}
              {/* Search <Robot> button / spinner after search - end */}
              
              {/* Help Button - start */}
              <span
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
                className="ms-2"
              >
                <BsFillQuestionCircleFill size={15} />
              </span>
              {/* Help Button - end */}
            </Col>

          </Form.Group>
            <Col xs sm="12">
              <Collapse in={open}>
                <div id="example-collapse-text"><p className="small pt-2">Use 4-5 keywords like "Team Member Order processing application, PHP,Mysql, ReactJS" or "Project Manager, 12 people team, agile methodoloty". <span className="fst-italic">From the suggestions, each line is selectable. To copy complete paragraph, click on the blue arrow</span></p></div>
              </Collapse>                
            </Col>
          </Row>
        {suggestions.length > 0 ? (
            <Fragment>
            {suggestions.map((suggestion, index) => {
              return (
                  
                  <Row>
                    <Col className="d-flex align-items-top border-bottom py-2" style={{fontSize:".8rem"}}>
                      <Col xs={2} sm="1">
                        <span
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => setEmploymentSummary(suggestions[index])}                          
                        ><FcLeft size={20} /></span>
                      </Col>
                      <Col xs={10} sm="11">
                      
                      {suggestion.split(".").map((suggestionLine, index) => (
                          <span href="#" className="suggestionLine" onClick={handleSuggestionLineClick} key={index}>{suggestionLine.trim() +"."}</span>
                      ))}  
                      </Col>
                    </Col>
                  </Row>
              );
            })}
          </Fragment>
        ) : (
          <Fragment>
            <ListGroup variant="flush">
              <ListGroup.Item>
                Add keywords, click on the IGBot and let IGBot create
                project Description for you..!!!
              </ListGroup.Item>
            </ListGroup>
          </Fragment>
        )}
        <span className="position-absolute top-0 end-0 m-2 m-md-4 strong" style={{cursor: "pointer"}}> <BsXLg size={20} onClick={ () => {setShowEmployerSuggetion(false)}} /></span>
      </Card.Body>
    </Card>
  </Fragment>
  
  );
};

export default EmploymentHistoryAICard;
