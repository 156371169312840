// import node module libraries
import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import {
  Image,
  Card,
  Row,
  Col,
  ProgressBar,
  ListGroup,
  Badge,
} from "react-bootstrap";

// import tippy tooltip
import Tippy from "@tippyjs/react";
import "tippy.js/animations/scale.css";

// import custom components
import Ratings from "components/marketing/common/ratings/Ratings";
import LevelIcon from "components/marketing/common/miscellaneous/LevelIcon";

// import utility file
import { numberWithCommas } from "helper/utils";

import LoginModalNew from "components/authentication/LoginModalNew";
import { convertToHoursMinutes } from "helper/formatTime";

const CourseCard = ({ item, courseImg, viewby, extraclass }) => {
  /** Used in Course Index, Course Category, Course Filter Page, Student Dashboard etc...  */

  // const history = useHistory();
  // console.log(history);

  const stutoken = localStorage.getItem("stutoken");

  // const base_url = window.location.href;
  // console.log(base_url);

  const redirect = `OnlineTrainings/${item.id}`;
  const [loginModalShow, setLoginModalShow] = useState(false);
  const handleRedirect = (e) => {
    // console.log("Inside handle redirect");
    // console.log("stuToken", stutoken);
    if (stutoken) {
      // console.log("inside IF");
      sessionStorage.removeItem("redirectURL");
    } else {
      // console.log("Inside else");
      e.preventDefault();
      sessionStorage.setItem("redirectURL", redirect);
      setLoginModalShow(true);
      // console.log("REDIRECT", redirect);
    }
  };

  // console.log(redirect_url);

  const video_length_in_seconds = item.video_length * 3600;

  const video_time = convertToHoursMinutes(video_length_in_seconds);

  // console.log(video_time);

  const GridView = () => {
    return (
      <Card className={`mb-4 card-hover ${extraclass}`}>
        {item.video_url.includes("youtube") ? (
          <Link
            to={stutoken == null ? "/student-login" : `${redirect}`}
            onClick={(e) => {
              handleRedirect(e);
            }}
          >
            <Image
              src={courseImg + item.displayimg}
              alt=""
              className="card-img-top rounded-top-md"
              style={{ minHeight: "16em", maxHeight: "16em" }}
            />

            {/* Card body  */}
            <Card.Body>
              <h3 className="h4 mb-2 text-truncate-line-2 ">
                {" "}
                {item.subject_name}
              </h3>
              <ListGroup as="ul" bsPrefix="list-inline" className="mb-3">
                <ListGroup.Item as="li" bsPrefix="list-inline-item">
                  <i className="far fa-clock me-1"></i>
                  {video_time}
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix="list-inline-item">
                  <LevelIcon level={item.level} />
                  {item.level}
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
            {/* Card Footer */}
            <Card.Footer>
              <Row className="align-items-center g-0">
                <Col className="col ms-2">
                  <span>{item.partner_name}</span>
                </Col>
              </Row>
            </Card.Footer>
          </Link>
        ) : (
          <a
            href={stutoken == null ? "/student-login" : item.video_url}
            target="_blank"
            rel="noreferrer"
          >
            <Image
              src={courseImg + item.displayimg}
              alt=""
              className="card-img-top rounded-top-md"
              style={{ minHeight: "16em", maxHeight: "16em" }}
            />

            {/* Card body  */}
            <Card.Body>
              <h3 className="h4 mb-2 text-truncate-line-2 ">
                {item.subject_name}
              </h3>
              <ListGroup as="ul" bsPrefix="list-inline" className="mb-3">
                <ListGroup.Item as="li" bsPrefix="list-inline-item">
                  <i className="far fa-clock me-1"></i>
                  {video_time}
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix="list-inline-item">
                  <LevelIcon level={item.level} />
                  {item.level}
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
            {/* Card Footer */}
            <Card.Footer>
              <Row className="align-items-center g-0">
                <Col className="col ms-2">
                  <span>{item.partner_name}</span>
                </Col>
              </Row>
            </Card.Footer>
          </a>
        )}
      </Card>
    );
  };

  /** Used in Course Filter Page  */
  const ListView = () => {
    // console.log(courseImg + item.displayimg)
    return (
      <Card className="mb-4 card-hover">
        <Link
          to={stutoken == null ? "/student-login" : `${redirect}`}
          onClick={handleRedirect}
        >
          <Row className="g-0">
            <Image
              src={courseImg + item.displayimg}
              alt="..."
              className="img-fluid bg-cover img-left-rounded col-12 col-md-12 col-xl-3 col-lg-3"
              style={{ minHeight: "13em", maxHeight: "13em" }}
            />

            <Col lg={9} md={12} sm={12}>
              {/* <!-- Card body --> */}
              <Card.Body>
                <h3 className="mb-2 text-truncate-line-2 ">
                  {item.subject_name}
                </h3>
                {/* <!-- List inline --> */}
                <ListGroup as="ul" bsPrefix="list-inline" className="mb-5">
                  <ListGroup.Item as="li" bsPrefix="list-inline-item">
                    <i className="far fa-clock me-1"></i>
                    {video_time}
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="list-inline-item">
                    <LevelIcon level={item.level} />
                    {item.level}
                  </ListGroup.Item>
                </ListGroup>
                {/* <!-- Row --> */}
                <Row className="align-items-center g-0">
                  <Col className="col ms-2">
                    <span>{item.partner_name.toUpperCase()}</span>
                  </Col>
                </Row>
              </Card.Body>
            </Col>
          </Row>
        </Link>
      </Card>
    );
  };

  /** Used in Instructor Profile Page  */
  const ListGroupView = () => {
    return (
      <div className="d-lg-flex align-items-center">
        <div>
          <Image src={item.image} alt="" className="rounded img-4by3-lg" />
        </div>
        <div className="ms-lg-3 mt-2 mt-lg-0">
          <h4 className="text-primary-hover">
            {item.title}{" "}
            <Badge bg="light-success" className="text-success">
              New
            </Badge>
          </h4>
          <ListGroup
            as="ul"
            bsPrefix="list-inline"
            className="fs-6 mb-0 text-inherit"
          >
            <ListGroup.Item as="li" bsPrefix="list-inline-item">
              <i className="far fa-clock me-1"></i>
              {item.duration}
            </ListGroup.Item>
            <ListGroup.Item as="li" bsPrefix="list-inline-item">
              <LevelIcon level={item.level} />
              {item.level}
            </ListGroup.Item>
            <ListGroup.Item as="li" bsPrefix="list-inline-item">
              <span className="text-warning">
                {" "}
                <Ratings rating={item.rating} /> {item.rating.toFixed(1)}
              </span>
              <span className="fs-6 text-muted">
                {" "}
                ({numberWithCommas(item.ratingby)})
              </span>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </div>
    );
  };
  return (
    <Fragment>
      <LoginModalNew
        show={loginModalShow}
        setModalShow={setLoginModalShow}
        onHide={() => setLoginModalShow(false)}
        activeTab={"student"}
      />
      {viewby === "grid" ? (
        <GridView />
      ) : viewby === "list" ? (
        <ListView />
      ) : (
        <ListGroupView />
      )}
    </Fragment>
  );
};

// Specifies the default values for props
CourseCard.defaultProps = {
  free: false,
  viewby: "grid",
  showprogressbar: false,
  extraclass: "",
};

// Typechecking With PropTypes
CourseCard.propTypes = {
  item: PropTypes.object.isRequired,
  free: PropTypes.bool,
  viewby: PropTypes.string,
  showprogressbar: PropTypes.bool,
  extraclass: PropTypes.string,
};

export default CourseCard;

// const GridView = () => {
// 	return (
// 		<Card className={`mb-4 card-hover ${extraclass}`}>
// 			<Link to="#">
// 				<Image
// 					src={courseImg + item.displayimg}
// 					alt=""
// 					className="card-img-top rounded-top-md"
// 				/>
// 			</Link>
// 			{/* Card body  */}
// 			<Card.Body>
// 				<h3 className="h4 mb-2 text-truncate-line-2 ">
// 					<Link to="#" className="text-inherit">
// 						{item.subject_name}
// 					</Link>
// 				</h3>
// 				<ListGroup as="ul" bsPrefix="list-inline" className="mb-3">
// 					<ListGroup.Item as="li" bsPrefix="list-inline-item">
// 						<i className="far fa-clock me-1"></i>
// 						{item.video_length}
// 					</ListGroup.Item>
// 					<ListGroup.Item as="li" bsPrefix="list-inline-item">
// 						<LevelIcon level={item.level} />
// 						{item.level}
// 					</ListGroup.Item>
// 				</ListGroup>

// 			</Card.Body>
// 			{/* Card Footer */}
// 			<Card.Footer>
// 				<Row className="align-items-center g-0">

// 					<Col className="col ms-2">
// 						<span>{item.partner_name}</span>
// 					</Col>

// 				</Row>

// 			</Card.Footer>
// 		</Card>
// 	);
// };
