import React from 'react';
import { Card, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Url from 'components/Url';

function BlogCard({item}) {
    const url1 = Url();
    const url = url1["url"];
    const s3 = url1["s3"];
    const s3url = s3 + "blogImages/";
  return (
    <Card className="mb-4 shadow-lg">
      <Link to={`/blog/${item.generated_url}`}>
        <Card.Img
          variant="top"
          src={s3url + item.image_1000_540_scroller}
          className="rounded-top-md img-fluid"
        />
      </Link>
      {/* Card body  */}
      <Card.Body>
        <Link
          to={`/blog/${item.generated_url}`}
          className={`fs-5 fw-semi-bold d-block mb-3`}
        >
          {item.category_name}
        </Link>
        <h3>
          <Link to={`/blog/${item.generated_url}`} className="text-inherit">
            {item.content_header}
          </Link>
        </h3>
        <p> {item.preview_text} </p>
        {/*  Media content  */}
        <Row className="align-items-center g-0 mt-4">
          <Col className="col-auto">
              <Image
              src={s3 + "Account.svg"}
              alt=""
              className="rounded-circle avatar-md"
            />
          </Col>
          <Col className="col lh-1">
            <h5 className="mb-1">{item.author_name}</h5>
            <p className="fs-6 mb-0">{item.created_at}</p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default BlogCard