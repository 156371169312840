// import node module libraries
import React, { useState, useRef, useEffect } from "react";
import {
  Row,
  ListGroup,
  Button,
} from "react-bootstrap";

import { BsCameraVideo, BsHourglassSplit } from "react-icons/bs";
import { LuMailQuestion } from "react-icons/lu";
import DatePicker from "react-datepicker";
import moment from "moment";
import Axios from "NewApi";
import { toast } from "react-toastify";

const MentorService = (props) => {
  const {
    service,
    index,
    handleBuyNow,
    setSelectedProduct,
    setMentorServiceId,
    activeServiceIndex,
    setActiveServiceIndex
  } = props;

  console.log(service);
  console.log(index);
  
  const [showSlots, setShowSlots] = useState(false);
  const [showBookNow, setShowBookNow] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  
  const today = new Date();
  const minDate = moment().toDate();
  const maxDate = moment().add(6, "days").toDate();
  const [slotDate, setSlotDate] = useState(today.setDate(today.getDate()));
  const [selectedMentorSlots, setSelectedMentorSlots] = useState();
  const [selectedSlotIndex,setSelectedSlotIndex] = useState();
  const [mentorSlots, setMentorSlots] = useState([]);

  const datePickerRef = useRef(null);

  useEffect(() => {
      if (activeServiceIndex === index && datePickerRef.current) {
          // Access the DOM node
          const today = new Date();
          const dayNode = document.querySelector(`.react-datepicker__day--${today.getDate()}`);
          if (dayNode) {
              // Trigger click event on today's date
              dayNode.click();
          }
      }
  }, [activeServiceIndex, index]);

  const handleDateChange = async (date, mentorServiceId) => {
    const mentorId = service.mentor_id;
    setSelectedSlotIndex(-1);
    setSlotDate(date);
    const selectedDate = moment(date);

    var api = "getAvailableSlots" + "/" + mentorId + "/" + mentorServiceId + "/" + selectedDate.format("YYYY-MM-DD");

    try {
      const response = await Axios.get(api);
      // console.log(response.data.mentorService);
      setMentorSlots(response.data.mentorSlots);
      setShowSlots(true);
    } catch (error) {
      // console.log(error);
      toast.warning("Slots not available for the selected date",{autoClose: 1000});
    }

    setShowBookNow(false);
  };

  const handleSlotClick = (selectedIndex, dayOfWeek, mentor_service_id,slot_from, slot_to) => {
    const selectedDate = moment(slotDate);

    setSelectedProduct({...service, dayOfWeek, mentor_service_id,slot_from, slot_to,
      slot_date: selectedDate.format("YYYY-MM-DD")});
    setMentorServiceId(mentor_service_id);
    setSelectedSlotIndex(selectedIndex);
    setShowBookNow(true);
  };

  return (
    <ListGroup.Item key={index}>
      <i className="align-middle me-2 text-primary"></i>
      <h3 className="fs-3 text-secondary">{service.service_name}</h3>
      {service.service_type == 1 ? (
        <span>
          <BsCameraVideo /> Video Call
        </span>
      ) : (
        <span>
          <LuMailQuestion /> Email
        </span>
      )}
      {service.service_type == 1 && (
        <span className="ms-3">
          <BsHourglassSplit />
          {service.service_duration_mins} Mins
        </span>
      )}
      <div className="mb-3">
        <span className="fw-bold h2 me-2" style={{ color: "var(--geeks-primary)" }}>
          &#8377;{service.discount_percent > 0 ? service.price * ((100-service.discount_percent)/100) : service.price}
        </span>
        {service.discount_percent > 0 && (
          <del className="fs-4 text-muted">
            &#8377;{service.price }
          </del>
        )}
        {service.discount_percent > 0 && (
          <span className="text-danger small">
            {" "}
            (-{service.discount_percent}%)
          </span>
        )}
        {service.price > 0 && <span className="fs-6 ms-1">+ GST</span>}
      </div>

      <Row>
        <Button
          variant="primary"
          className="btn-sm btn-block"
          onClick={() => {
            if (service.service_type === 1) {
              setActiveServiceIndex(prevIndex => prevIndex === index ? null : index);
              console.log(moment().format("YYYY-MM-DD"));
              handleDateChange(new Date(),service.mentor_service_id );
            } else {
              handleBuyNow(index);
            }
          }}
        >
          {(showSlots && activeServiceIndex === index) ? "Hide Slots" : "Show Slots"}
        </Button>
      </Row>


        {console.log(activeServiceIndex)}
        {console.log(index)}
        {/* <div className={showCalendar ? "d-block" : "d-none"}> */}
        {activeServiceIndex === index && (         
          <Row>   
            <div>
              <p>Select date to display available slots</p>
              <DatePicker
                onChange={(e) => handleDateChange(e, service.mentor_service_id)}
                minDate={minDate}
                maxDate={maxDate}
                dayClassName={(date) => "badge"}
                inline
                selected={slotDate}
                calendarStartDay={moment().day()}
                ref={datePickerRef}
              />
            </div>
            <Row>
              <div
                className={`${showSlots ? "d-block" : "d-none"} p-2`}
                style={{ overflowX: "auto", whiteSpace: "nowrap" }}
              >
                {mentorSlots?.length > 0
                  ? mentorSlots?.map((slot, index1) => (
                      <span
                        className={`badge ${selectedSlotIndex==index1 ? "bg-success" : "bg-primary"} me-2`}
                        kye={index1}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleSlotClick(index1, slot.dayOfWeek, slot.mentor_service_id,slot.slot_from, slot.slot_to,);
                        }}
                      >
                        {moment(slot.slot_from, "HH:mm:ss").format("hh:mm A")}
                      </span>
                    ))
                  : "No Slots available"}
              </div>
            </Row>
            <Row className={`${showBookNow ? "d-block" : "d-none"}`}>
              <Button variant="primary" className="btn btn-sm" onClick={handleBuyNow}>
                Book Now
              </Button>
            </Row>
          </Row>

        )}
    </ListGroup.Item>
  );
};

export default MentorService;
