import React, { useCallback, useState } from "react";
import { Modal, Button,  Form } from "react-bootstrap";
import cx from "classnames";
import { Image } from "react-feather";
import { useDropzone } from "react-dropzone";
import Cropper from "react-easy-crop";

//import Slider from "@material-ui/core/Slider";
//import Slider from "@material-ui/core/Slider";
import "./CropImageDialog.css";
import getCroppedImg from "./cropImage";

const CropImageDialog = ({ setCroppedImage, close }) => {
  const [file, setFile] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(file, croppedAreaPixels, rotation);
      setCroppedImage(croppedImage);
      close();
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);
  const onDrop = useCallback((acceptedFiles) => {
    const img = URL.createObjectURL(acceptedFiles[0]); 
    setFile(img);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: "image/jpeg, image/x-png, .jpg, .jpeg, .png"
  });

  return (
    <Modal.Body>
      {file ? (
        <>
          <div className="image-wrapper">
            <Cropper
              image={file}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={1}
              cropShape="round"
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="image-controls">
            <div className="px-4 mb-1">
              <Form.Label as="span">Zoom</Form.Label>
              <Form.Range
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => setZoom(e.target.value)}
              />
            </div>
            <div className="px-4 mb-1">
              <Form.Label as="span">Rotation</Form.Label>
              <Form.Range
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                onChange={(e, rotation) => setRotation(e.target.value)}
              />
            </div>
          </div>
          <Modal.Footer>
            <Button type="button" onClick={showCroppedImage}>
              Save Image
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <div className="" {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <>
              <p>
                <Image size={40} />
              </p>
              <p>Drag &amp; drop or select a photo from your computer.</p>
              <Button>Choose Image</Button>
            </>
          )}
        </div>
      )}
    </Modal.Body>
  );
};

export default CropImageDialog;
