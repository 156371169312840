// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Nav, Navbar, Card, Modal } from 'react-bootstrap';
import Url from 'components/Url';
import Chat from '../chat/Chat';
import {PiCertificateLight} from "react-icons/pi";

const Sidebar = ({ setLoad }) => {
    const location = useLocation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [appStatusMenuRoutes, setAppStatusMenuRoutes] = useState();
    const id = window.localStorage.getItem("intId");
    const intDetails = JSON.parse(window.localStorage.getItem("intDetails"))
    const url1 = Url();
    const url = url1["url"];

    useEffect(() => {
        const api = url + 'getInternshipSummeryCard/' + id;
		fetch(api, {
			method: 'GET',
		})
			.then((apidata1) => {
				return apidata1.json();
			})
			.then((actualdata1) => {
				//console.log(actualdata1)
                setAppStatusMenuRoutes([
                    // {
                    //     id: 1,
                    //     menuitem: 'All Applications',
                    //     link: '/empdashboard/applications/all',
                    //     counter : 18
                    // },
                    {
                        id: 2,
                        menuitem: 'Students Applied',
                        link: '/empdashboard/applications/applied',	
                        counter : actualdata1.applied,
                        disabled : false
                    },
                    {
                        id: 4,
                        menuitem: 'Shortlisted',
                        link: '/empdashboard/applications/shortlisted',
                        counter : actualdata1.shortlist,
                        disabled : false
                    },
                    {
                        id: 5,
                        menuitem: 'Hired',
                        link: '/empdashboard/applications/hired',
                        counter : actualdata1.hire,
                        disabled : false
                    },
                    {
                        id: 6,
                        menuitem: 'Rejected',
                        link: '/empdashboard/applications/rejected',
                        counter : actualdata1.reject,
                        disabled : false
                    },
                    {
                        id: 3,
                        menuitem: 'AI Recommended Profiles',
                        link: '/empdashboard/applications/recommended',
                        counter : "",
                        disabled : (actualdata1.internship_status !== "Active" ? true : false)	
                    },
                    {
                        id: 7,
                        menuitem: 'Internshipgate Certified Interns',
                        link: '/empdashboard/applications/internshipgate-certified-interns',
                        counter : "",
                        disabled : (actualdata1.internship_status !== "Active" ? true : false)		
                    },                    
                ])
			});
    },[])
    return (
        <Fragment>
            <Card className="bg-light bg-opacity-50 mb-4">
                <Navbar expand="lg" className="sidebar-menu" style={{padding: "0.625rem 0.75rem"}}>
                    <Link className="d-lg-none" to="#" >Menu</Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="p-0 focus-none border-0" label="Responsive Menu">
                        <span className="navbar-toggler-icon"></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="nav flex-column w-100" as="ul" activeKey="0">
                            {appStatusMenuRoutes?.map((item, index) => {
                                return (
                                    <div key={index}>
                                    {(item.disabled) ? 
                                       <Nav.Item as="li" key={index} >
                                            <div className="d-flex justify-content-between align-items-center">
                                                <Nav.Link>
                                                {item.id == 7 && <PiCertificateLight size={20} className="text-success" /> } {item.menuitem}
                                                </Nav.Link>
                                            </div>
                                        </Nav.Item>
                                        :
                                        <Nav.Item as="li" key={index} >
                                            <Link onClick={(e) => setLoad(true)} style={{backgroundColor: `${item.link === location.pathname ? '#e2e8f0' : ''}`}} className={`nav-link  ${item.link === location.pathname  ? 'active' : ''}`}  to={item.link} >
                                                <div className="d-flex justify-content-between align-items-center">
                                                    
                                                    {item.id == 7 ? <span> <span style={{color:"#2C3895"}}>Internship</span><span style={{color:"#F98F43"}}>gate</span> certified Interns <PiCertificateLight size={24} className="text-success" /></span>
                                                    : <span> {item.menuitem}</span>}
                                                    <span className="badge text-primary">{item.counter}</span>
                                                </div>
                                            </Link>
                                        </Nav.Item>
                                    }
                                    </div>
                                );
                            })}
                            {/* 
                            <Nav.Item as="li">
                                <Link className="nav-link " to="#" onClick={handleShow}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span> Chat message</span>
                                        <span className="badge text-primary">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                                fill="currentColor" className="bi bi-chat" viewBox="0 0 16 16">
                                                <path
                                                    d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" />
                                            </svg>
                                        </span>
                                    </div>
                                </Link>
                            </Nav.Item>
                            */}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Card>
            <Card className="bg-light bg-opacity-50">
                <Card.Body>
                    <h4 className="mb-3">Application Details</h4>
                    <div className="mb-3">
                        <small className="text-uppercase text-muted ls-md">
                            Location:
                        </small>
                        <p className="mb-0">{intDetails.location}</p>
                    </div>
                    <div className="mb-3">
                        <small className="text-uppercase text-muted ls-md">
                            Starts in:
                        </small>
                        <p className="mb-0">{intDetails.startDate}</p>
                    </div>
                    <div className="mb-3">
                        <small className="text-uppercase text-muted ls-md">
                            Duration:
                        </small>
                        <p className="mb-0">{intDetails.duration}</p>
                    </div>
                    <div className="">
                        <small className="text-uppercase text-muted ls-md">
                            Stipend:
                        </small>
                        <p className="mb-0">{intDetails.stipend}</p>
                    </div>
                </Card.Body>
            </Card>

            {/* Chat Modal Popup  */}
            <Modal show={show} onHide={handleClose} scrollable size="xl">
                <Modal.Header closeButton>
                    <Modal.Title id="chatModalLabel">
                        Messages from all internships & jobs
                    </Modal.Title>
                </Modal.Header>
                <Chat/>
            </Modal>

        </Fragment>
    )
}

export default Sidebar