// import node module libraries
import React, { Fragment, useEffect } from 'react';

// import layouts
import NavbarDefault from 'layouts/marketing/navbars/NavbarDefault';
import Footer from 'layouts/marketing/footers/Footer';
import InternshipgateFooter from './footers/InternshipgateFooter';

const DefaultLayout = (props) => {
	useEffect(() => {
		// document.body.style.backgroundColor = '#f1f5f9';
	});
	return (
		<Fragment>
			<NavbarDefault login />
			{props.children}
			{ /*<Footer />*/ }
			<InternshipgateFooter />
		</Fragment>
	);
};

export default DefaultLayout;
