// import node module libraries
import { Card, Form, Button, Col, Row } from "react-bootstrap";

// import custom components
import GKTagsInput from "components/elements/tags/GKTagsInput";
import Url from "components/Url";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

const Settings = (props) => {
  const {
    previous,
    courseid,
    price,
    setPrice,
    setRating,
    rating,
    setDiscountPercent,
    setCourseFeatures,
    featuresListData,
    selectedFeatures,
    discountPercent,
    setDiscountStart,
    discountStart,
    discountEnd,
    setDiscountEnd,
    courseData,
    lmsID,
    setLmsID,
    handleSubmit,
    setEnrollNumbers,
    isSaved,
    enrollNumbers,
    seoTitle,
    setSeoTitle,
    metaDescription,
    setMetaDescription,
    metaKeywords,
    setMetaKeywords
  } = props;
  const url1 = Url();
  const url = url1.url;
  const history = useHistory();
  const [featureList, setFeatureList] = useState();
  const [activeInd, setActiveInd] = useState(0);
  var fdata = [];

  const ratingData = [
    { value: "Select", label: "Select" },
    { value: "3.00", label: "3 Star" },
    { value: "3.50", label: "3.5 Star" },
    { value: "4.00", label: "4 Star" },
    { value: "4.50", label: "4.5 Star" },
    { value: "5.00", label: "5 Star" },
  ];

  const handleStatusSubmit = () => {
    if (!isSaved) {
      alert("Please save the changes.");
      return;
    }
    const api = url + "changeVICourseStatus";
    const config = {
      method: "post",
      body: JSON.stringify({
        courseId: courseid,
        status: 1,
      }),
      headers: { "Content-type": "application/json" },
    };
    fetch(api, config)
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Course Status Updated") {
          history.push("/courses/all-courses");
        }
      });
  };

  useEffect(() => {
    console.log("rendered");
    if (selectedFeatures.length > 0) {
      selectedFeatures.map((item) => fdata.push(item.feature_id));
    }
    if (fdata.length > 0) {
      setFeatureList(fdata);
    }
  }, []);

  useEffect(() => {
    setCourseFeatures(featureList);
  }, [featureList]);

  useEffect(() => {
    if (courseData) {
      console.log(courseData[0]);
      setPrice(courseData[0].product_price);
      setDiscountPercent(courseData[0].discount_percent);
      setDiscountStart(courseData[0].discount_start_date);
      setDiscountEnd(courseData[0].discount_end_date);
      setRating(courseData[0].star_rating);
      setLmsID(courseData[0].lms_course_id);
      setEnrollNumbers(courseData[0].enrolled_numbers);
      setActiveInd(courseData[0].active_ind);
      setSeoTitle(courseData[0].seo_title);
      setMetaDescription(courseData[0].meta_description);
      setMetaKeywords(courseData[0].meta_keywords);
    }
  }, [courseData]);
  return (
    <Form>
      {/* Card */}
      <Card className="mb-3  border-0">
        {/* <Card.Header className="border-bottom px-4 py-3">
					<h4 className="mb-0">Pricing</h4>
				</Card.Header> */}
        {/* Card body */}
        {/* <Card.Body className='pb-0'>
			<Form.Group className="mb-3" controlId="formaddnewprice">
            <Form.Control type="number" value={price} onChange={e => setPrice(e.target.value)} placeholder="Enter Course Price" />
			</Form.Group>
			<Form.Group className="mb-3" controlId="formaddnewpercent">
            <Form.Control type="number" value={discountPercent} onChange={e => setDiscountPercent(e.target.value)} placeholder="Enter Discount Percent" />
			</Form.Group>
			<div className='d-flex'>
			<Form.Group className="mb-3 me-1 w-50" controlId="formaddnewpercent">
            <Form.Control type="text" value={discountStart} onChange={e => setDiscountStart(e.target.value)} placeholder="Discount Start Date eg: YYYY-MM-DD" />
			</Form.Group>
			<Form.Group className="mb-3 w-50" controlId="formaddnewpercent">
            <Form.Control type="text" value={discountEnd} onChange={e => setDiscountEnd(e.target.value)} placeholder="Discount End Date eg: YYYY-MM-DD" />
			</Form.Group>
			</div>
			</Card.Body> */}
        <Card.Header className="border-bottom">
          <h4 className="mb-0">Settings</h4>
        </Card.Header>
        <Card.Body>
          <Row className="mb-3">
            <h5>What includes</h5>
            <Col>
              {featuresListData.slice(0, 10).map((item, i) => (
                <Form.Check
                  type="checkbox"
                  id="free content"
                  value={item.feature_id}
                  label={item.feature_name}
                  checked={featureList?.includes(item.feature_id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      console.log(fdata);
                      setFeatureList((prev) =>
                        prev
                          ? [...prev, parseInt(e.target.value)]
                          : [parseInt(e.target.value)]
                      );
                    } else {
                      fdata = featureList;
                      fdata.splice(featureList.indexOf(item.feature_id), 1);
                      console.log(fdata);
                      setFeatureList([...fdata]);
                    }
                  }}
                />
              ))}
            </Col>
            <Col>
              {featuresListData
                .slice(10, featuresListData.length)
                .map((item) => (
                  <Form.Check
                    type="checkbox"
                    id="free content"
                    value={item.feature_id}
                    label={item.feature_name}
                    checked={featureList?.includes(item.feature_id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFeatureList((prev) =>
                          prev
                            ? [...prev, parseInt(e.target.value)]
                            : [parseInt(e.target.value)]
                        );
                      }
                      if (!e.target.checked) {
                        fdata = featureList;
                        fdata.splice(featureList.indexOf(item.feature_id), 1);
                        setFeatureList([...fdata]);
                      }
                    }}
                  />
                ))}
            </Col>
          </Row>
          <Row className="d-flex">
            <Form.Group className="mb-3 w-25">
              <Form.Label>Star Rating</Form.Label>
              <Form.Select
                id="star_rating"
                name="star_rating"
                placeholder="Select rating"
                value={rating}
                onChange={(e) => setRating(e.target.value)}
              >
                {ratingData.map(({ value, label }, index) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 w-25">
              <Form.Label>LMS ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter LMS ID"
                id="lms_id"
                name="lms_id"
                value={lmsID}
                onChange={(e) => setLmsID(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3 w-25">
              <Form.Label>Enrolled Numbers</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Enrolled Numbers"
                id="enrolled_numbers"
                name="enrolled_numbers"
                value={enrollNumbers}
                onChange={(e) => setEnrollNumbers(e.target.value)}
              />
            </Form.Group>
          </Row>
          {/* <GKTagsInput defaulttags={['jQuery', 'bootstrap']} /> */}
        </Card.Body>
      </Card>
      
      { /*************** SEO SETTINGS - start **********************************  */ }
      <Card className="mb-3  border-0">
      <Card.Header className="border-bottom">
          <h4 className="mb-0">Page SEO Settings</h4>
        </Card.Header>
        <Card.Body>
          <Row>
            <Form.Group className="mb-3">
                <Form.Label>Page Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Page Title"
                  id="seo_title"
                  name="seo_title"
                  value={seoTitle}
                  onChange={(e) => setSeoTitle(e.target.value)}
                />
              </Form.Group>          
              <Form.Group className="mb-3">
                <Form.Label>Meta Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Meta Description"
                  id="meta_description"
                  name="meta_description"
                  value={metaDescription}
                  onChange={(e) => setMetaDescription(e.target.value)}
                />
              </Form.Group>          
              <Form.Group className="mb-3">
                <Form.Label>Meta Keywords</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Meta Keywords"
                  id="meta_keywords"
                  name="meta_keywords"
                  value={metaKeywords}
                  onChange={(e) => setMetaKeywords(e.target.value)}
                />
              </Form.Group>          

            </Row>
        </Card.Body>
      </Card>      
      { /*************** SEO SETTINGS - end ************************************  */ }

      <div className="d-flex justify-content-between mb-22">
        {/* Button */}
        <Button variant="success" className="mx-2" onClick={handleSubmit}>
          Save
        </Button>
        <div>
          <Button variant="secondary" className="mx-2" onClick={previous}>
            Previous
          </Button>
          {activeInd !== 2 && (
          <Button variant="danger" onClick={handleStatusSubmit} >
            Submit For Review
          </Button>
          )}
        </div>
      </div>
    </Form>
  );
};
export default Settings;
