import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom";
import { Row, Col, Form, Container, Button } from 'react-bootstrap';
import Url from "../../Url";

const Makecertificate = () => {

    const history = useHistory()

    const url1 = Url()
    const url = url1["url"]

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [cap_type, setCap_type] = useState("")
    const [start_date, setStart_date] = useState("")
    const [end_date, setEnd_date] = useState("")
    const [salutation, setSalutation] = useState("Mr.");
    const [certificatecreated, setCertificatecreated] = useState(false)

    const createCertificate = () => {

        var certificate_id = 0;
        const api = url + "createQuickCertificate"

        const generated_by = localStorage.getItem('supervisor')
        fetch(api, {
            method: 'POST',
            body: JSON.stringify({name, email, subject, cap_type,start_date,end_date,salutation,generated_by}),
            headers: { 'Content-Type': 'application/json' }
        })
            .then((apidata) => {
                //console.log(apidata)
                return apidata.json()
            })
            .then((actualdata) => {
                console.log(actualdata)
                if (actualdata["id"] != 0) {
                    certificate_id = actualdata["id"];
                    console.log(certificate_id);
                    //localStorage.setItem('adminCert_id',certificate_id)
                    //setCertificatecreated(true);
                    //var certificateID  = localStorage.getItem('adminCert_id');
                    history.push('/Certificateshow/' + certificate_id);
                    return;
                }
            })
    }

    
    if (certificatecreated) {
        var certificateID  = localStorage.getItem('adminCert_id');
        history.push('/Certificateshow/' + {certificateID});
    }
    
    const [formData, setFormData] = useState({
        salutation: '',
        name: '',
        email: '',
        subject: '',
        internshipType: '',
        startDate: '',
        endDate: '',
      });
    
      const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
      };
    

    return (
  
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <h2 className="text-center mb-4"><b>Make CAP Certificate</b></h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Row>
                <Col lg={2} sm={2} md={12}>
                <Form.Group className="mb-3">
              <Form.Label>Salutation</Form.Label>
              <Form.Select
                name="salutation"
                value={salutation}
                onChange={(e) => setSalutation(e.target.value)}
              >
                <option value="">Select Salutation</option>
                <option value="Mr.">Mr.</option>
                <option value="Ms.">Ms.</option>
                <option value="Mrs.">Mrs.</option>
                <option value="Miss">Miss</option>
              </Form.Select>
            </Form.Group>
                </Col>
                <Col lg={10} sm={4} md={12}>
                <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e)=> setName(e.target.value)}
                    placeholder="Student Name"
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={email}
                onChange={(e)=> setEmail(e.target.value)}
                placeholder="Enter Email"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                name="subject"
                value={subject}
                onChange={(e)=> setSubject(e.target.value)}
                placeholder="Enter Subject"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Internship Type</Form.Label>
              <Form.Select
                name="internshipType"
                value={cap_type}
                onChange={(e)=> setCap_type(e.target.value)}
              >
                <option value="">Select Internship Type</option>
                <option value="Domestic">Domestic</option>
                <option value="Global">Global</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                value={start_date}
                onChange={(e)=> setStart_date(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                name="endDate"
                value={end_date}
                onChange={(e)=> setEnd_date(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
                <Button onClick={createCertificate}>Create Certificate</Button>
            </Form.Group>
            </Form>
            </Col>
            </Row>
    </Container>

    )
}

export default Makecertificate