import React, { useState, useEffect, Fragment } from "react";
import { Col, Row, Button, Modal, Form, FormSelect } from 'react-bootstrap';
import { PencilSquare, Trash } from "react-bootstrap-icons";
import Url from "components/Url";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Projects = (props) => {
    const {studentProjects,
           setLoadData,
           applicantId,
           locationList,
           employerView
        } = props; 

    const [projectDetail, setProjectDetail] = useState({});
    const [showProjectModal, setShowProjectModal] = useState(false);

    const url1 = Url();
    const url = url1["url"];
    const s3URL = url1["s3"];

    function deleteProject(id) {
        if (id && window.confirm("Are you sure? Want to delete Project detail?")) {
            const api = url + "deleteApplicantProjectById/" + id;
            const config = {
            method: "DELETE",
            };
            fetch(api, config)
            .then((res) => res.json())
            .then((data) => {
                if (data.message === "Record deleted") {
                alert("Project Details Deleted Successfuly");
                setLoadData(true);
                }
            });
        }
    }    
    function editProject(id) {
        //console.log("editProject" + id);
        const api1 = url + "getApplicantProjectById/" + id;
        fetch(api1, {
            method: "GET",
        })
        .then((apidata1) => {
            return apidata1.json();
        })
        .then((actualdata1) => {
            setProjectDetail(actualdata1);
        });        
        setShowProjectModal(true);
    }       
    
    const addProject = () => {
        setProjectDetail([]);
        setShowProjectModal(true);
    }        
    
    return (
        <Row className="mb-2 border-bottom">
        <Col md={3}>
            <span className="text-dark">Projects:</span>
        </Col>
        <Col md={9}>
            {studentProjects.length > 0 ? (
                studentProjects.map((item,index) => (
                    <Col className="ml-2" key={index}>
                        <div className="h5">
                            {item.prj_profile}
                            {!employerView && 
                            <Fragment>
                            <span
                                onClick={() => deleteProject(item.id)}
                                className="float-end"
                                style={{cursor:"pointer"}}
                                >
                                <Trash />
                            </span>
                            <span
                                onClick={() => editProject(item.id)}  
                                className="float-end mx-2 pe-auto"
                                style={{cursor:"pointer"}}
                                >
                                <PencilSquare />
                            </span>           
                            </Fragment>
                            }                    
                        </div>
                        <div>
                            {item.prj_org_name}, {item.prj_location}
                        </div>
                        <div className="">
                            From{" "}
                            {
                                item.prj_start_date
                            }{" "}
                            to{" "}
                            {item.prj_current_status ===
                            "Currently working here"
                                ? "Present"
                                : 
                                        item.prj_end_date
                                    }
                        </div>
                        <div className="mb-4 text-justify">
                            {item.prj_description}
                        </div>
                    </Col>
                ))
                ) : (
                    <div className="my-3">No project details.....</div>
                )}
                {!employerView && 
                <Row>
                    <Col><div className="mb-3 d-flex justify-content-end">
                        <button type="button" className="btn btn-primary btn-sm" onClick={addProject}>Add Project </button></div>
                    </Col>
                </Row>
                }
        </Col>
    
            {showProjectModal ? (
                <ProjectPopup
                    setShowProjectModal={setShowProjectModal}
                    showProjectModal={showProjectModal}
                    setLoadData={setLoadData}
                    projectDetail={projectDetail}
                    locationList={locationList}
                />
            ) : null}            
        </Row>
    )
}

/**********************************************************************************************/
/************************* ProjectPopup Modal Component ************************************/
/**********************************************************************************************/

const ProjectPopup = (props) => {

    const {setShowProjectModal,
           showProjectModal,
           setLoadData,
           projectDetail,
           locationList,
           employerView
         } = props;

    const url1 = Url();
    const url = url1["url"];    

    //const projectDetail = [];

    const [isEdit,setIsEdit] = useState(false);
    
    const [show, setShow] = useState(props.show);
    
    const [id,setId] = useState("");
    const [email, setEmail] = useState("");
	const [prj_profile, setPrj_profile] = useState("");
	const [prj_org_name, setPrj_org_name] = useState("");
	const [prj_location, setPrj_location] = useState("");
	const [prj_start_date, setPrj_start_date] = useState("");
	const [prj_end_date, setPrj_end_date] = useState("");
	const [prj_current_status, setPrj_current_status] = useState("");
	const [prj_description, setPrj_description] = useState("");

    const [apiMessage, setApiMessage] = useState("");
    const [apiStatus, setApiStatus] = useState(0);

    const [currentDate,setCurrentDate] = useState(new Date());

	const [currentlyWorkHere, setCurrentlyWorkHere] = useState(false);
    
    const [buttonDisabled, setButtonDisabled] = useState(false);

    console.log("resetting...");
    console.log(currentlyWorkHere);

    useEffect(() => {
        console.log(projectDetail);
        if(projectDetail.id){

            setIsEdit(true);

            setId(projectDetail.id);
            setEmail((projectDetail.email ? projectDetail.email : localStorage.getItem("email")));

            setPrj_profile(projectDetail.prj_profile);
            setPrj_org_name(projectDetail.prj_org_name);
            setPrj_location(projectDetail.prj_location);
            setPrj_start_date(projectDetail.prj_start_date);
            setPrj_end_date(projectDetail.prj_end_date);

            setPrj_current_status(projectDetail.prj_current_status);
            if(projectDetail.prj_current_status == "Currently working here"){
                setCurrentlyWorkHere(true);
            }else{
                setCurrentlyWorkHere(false);                
            }

            setPrj_description(projectDetail.prj_description);
        }else{
            setCurrentlyWorkHere(false);
        }

    },[projectDetail]);

	const handleCurrentlyWorkHere = () => {

		setCurrentlyWorkHere(!currentlyWorkHere);
        //the new state will be available in next render hence we need to check for the existing
        if(!currentlyWorkHere){
            setPrj_current_status("Currently working here");
            setPrj_end_date("");
        }else{
            setPrj_current_status("NO");
        }

	};

    const handleClose = () => {
        setShowProjectModal(false);
        setLoadData(true);
    }

    const saveProjectDetails = e => {
        
        e.preventDefault();
        const applicantId = localStorage.getItem("applicantid");
        const email = localStorage.getItem("email");

        const creapi = url + "updateApplicantProject";
        fetch(creapi, {
            method: "POST",
            body: JSON.stringify({
                id,
                email,
                prj_profile,
                prj_org_name,
                prj_location,
                prj_start_date,
                prj_end_date,
                prj_current_status,
                prj_description,
                applicantId,
            }),
            headers: { "Content-Type": "application/json" },
        })
        .then((apidata) => {
            return apidata.json();
        })
        .then((actualdata) => {
            setApiMessage(actualdata["message"]);
            setApiStatus(actualdata["status"]);
            if(!id && actualdata["status"] == 1){
                setButtonDisabled(true);
            }            
        });
        return;
    }

    return (
        <Modal show={showProjectModal} onHide={handleClose} size="md">
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? "Edit Project Details" : "Add Project Details"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>            
                <Row className="mb-2">
                    <div className="col-4 text-end ">
                        <span className="h5 m-1">Project Name</span>
                    </div>
                    <div className="col-8">
                        <Form.Control
                            type="text"
                            placeholder="e.g. Optical Character Recognition"
                            id="prj_profile"
                            name="prj_profile"
                            value={prj_profile}
                            onChange={(e) => setPrj_profile(e.target.value)}
                            required
                        />     
                    </div>
                </Row>
                
                <Row className="mb-2">
                    <div className="col-4 text-end ">
                        <span className="h5 m-1">Organization</span>
                    </div>
                    <div className="col-8">
                        <Form.Control
                            type="text"
                            placeholder="Organization/Institute Name"
                            id="prj_org_name"
                            name="prj_org_name"
                            value={prj_org_name}
                            onChange={(e) => setPrj_org_name(e.target.value)}
                            required
                        /> 
                    </div>
                </Row>
                
                <Row className="mb-2">
                    <div className="col-4 text-end ">
                            <span className="h5 m-1">Work From Home / City</span>
                        </div>

                        <div className="col-8">

                            <Form.Control
                                type="text"
                                placeholder="Work from home/City"
                                id="prj_location"
                                name="prj_location"
                                value={prj_location}
                                onChange={(e) => setPrj_location(e.target.value)}
                                required
                                //options={salutationOptions}
                            />
                    </div>
                </Row>     
                <Row className="mb-2">
                    <div className="col-4 text-end ">
                        <h5 className="m-1">Start Date</h5>
                    </div>
                    <div className="col-8 d-md-flex">
                        <Form.Group >
                            <Form.Control
                                type="date"
                                size="sm"
                                placeholder="Enter Collage Name"
                                className="expected-y"
                                onFocus={(e) => e.target.select()}
                                value={prj_start_date}
                                onChange={(e) => setPrj_start_date(e.target.value)}
                                max={currentDate}
                                required
                            />                
                        </Form.Group>   
                    </div>
                </Row>
                
                <Row className="mb-2">
                    <div className="col-4 text-end ">
                        <h5 className="m-1">End Date</h5>
                    </div>
                     
                    <div className="col-8 d-md-flex">
                        {!currentlyWorkHere ? 
                        (
                        <Form.Group >
                            <Form.Control
                                type="date"
                                size="sm"
                                placeholder="End date"
                                className="expected-y"
                                onFocus={(e) => e.target.select()}
                                value={prj_end_date}
                                onChange={(e) => setPrj_end_date(e.target.value)}
                                max={currentDate}
                                required
                            />                
                        </Form.Group>
                        ):"Currently working"
                        }   
                    </div>
                    
                </Row>
      
                <Row className="mb-2">
                    <div className="col-4 text-end ">
                        <h5 className="m-1">Currently working</h5>
                    </div>
                    <div className="col-8">
                        <Form.Check
                            className="option radiotype1 "
                            type="checkbox"
                            name="prj_current_status"
                            onClick={handleCurrentlyWorkHere}
                            checked={currentlyWorkHere}
                            //onChange={(e) => {handleCurrentlyWorkHere}}
                            label={false}
                        />                     
                    </div>
                </Row>
 
                <Row className="mb-2">
                    <div className="col-4 text-end ">
                        <h5 className="m-1">Description</h5>
                    </div>
                    <div className="col-8">
                        <Form.Control
                            placeholder="short discription max -500"
                            as="textarea"
                            rows={3}
                            name="prj_description"
                            onFocus={(e) => e.target.select()}
                            value={prj_description}
                            onChange={(e) => setPrj_description(e.target.value)}
                            required
                        />                     
                    </div>
                </Row>
 
                <Row className="mb-2 d-flex d-inline">
                    <div className="mb-3 d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary btn-sm" disabled={buttonDisabled} onClick={saveProjectDetails}>Save</button>

                        <button type="button" className="btn btn-outline-warning btn-sm ms-2" onClick={handleClose}>Close</button>
                    </div>
                </Row>               

                <Row>
                    {apiMessage !== "" && <span className={ (apiStatus ? "text-success " : "text-danger ")}>{apiMessage}</span> }
                    {/*apiStatus==1 && <span className="text-success small">Project details updated successfully</span> */}
                </Row>                                                                                           
            </Modal.Body>

        </Modal>
    );
}


export default Projects;