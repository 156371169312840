/* eslint-disable prettier/prettier */
// import node module libraries
import { Fragment, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { ChevronLeft, ChevronRight } from 'react-feather';

// import sub components
import CourseCard from './CourseCard';

// import data files
// import { AllCoursesData } from 'data/slider/AllCoursesData';

const CourseListView = ({ courseData, courseImg, levelFilter, categoryFilter, courseProviderFilter, certificationFilter }) => {

	// const filteredCourses = levelFilter.length > 0 ? courseData.filter(course => levelFilter.includes(course.level)) : courseData

	let filteredCourses = courseData;

	if (levelFilter.length !== 0) {
		filteredCourses = filteredCourses.filter(course => levelFilter.includes(course.level));
	}

	if (categoryFilter.length !== 0) {
		filteredCourses = filteredCourses.filter(course => categoryFilter.includes(course.category));
	}

	if (courseProviderFilter.length !== 0) {
		filteredCourses = filteredCourses.filter(course => courseProviderFilter.includes(course.partner_name
		));
	}

	if (certificationFilter.length !== 0) {
		filteredCourses = filteredCourses.filter(course => certificationFilter.includes(course.certification
		));
	}

	// paging start
	const [pageNumber, setPageNumber] = useState(0);
	const RecordsPerPage = 48;
	const pagesVisited = pageNumber * RecordsPerPage;
	const pageCount = Math.ceil(filteredCourses.length / RecordsPerPage);
	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};
	const displayRecords = filteredCourses.slice(
		pagesVisited,
		pagesVisited + RecordsPerPage
	).map((courseData, index) => {
		return (
			<Col sm={12} md={12} lg={12} key={index}>
				<CourseCard item={courseData} courseImg={courseImg} viewby="list" />
			</Col>
		);
	});
	// end of paging

	return (
		<Fragment>
			<Row>
				{displayRecords.length > 0 ? (
					displayRecords
				) : (
					<Col>No matching records found.</Col>
				)}
			</Row>

			<ReactPaginate
				previousLabel={<ChevronLeft size="14px" />}
				nextLabel={<ChevronRight size="14px" />}
				pageCount={pageCount}
				onPageChange={changePage}
				containerClassName={'justify-content-center mb-0 pagination'}
				previousLinkClassName={'page-link mx-1 rounded'}
				nextLinkClassName={'page-link mx-1 rounded'}
				pageClassName={'page-item'}
				pageLinkClassName={'page-link mx-1 rounded'}
				disabledClassName={'paginationDisabled'}
				activeClassName={'active'}
			/>
		</Fragment>
	);
};
export default CourseListView;
