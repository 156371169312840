// import node module libraries
import { Link } from "react-router-dom";
import {
  Container,
  Button,
  Row,
  Col,
  Navbar,
  Card,
  Form,
  Nav,
  Image,
  Modal,
  Spinner,
} from "react-bootstrap";

import Select from "react-select";
// import media files
import Avatar from "assets/images/avatar/avatar-1.jpg";
import { useState, useRef, useEffect } from "react";
import CropImageDialog from "../ProfileCrop/CropImageDialog";
import Url from "components/Url";
import { ToastContainer, toast } from "react-toastify";

const EditProfile = () => {
  const hiddenFileInput = useRef(null);
  const [avatar, setAvatar] = useState(Avatar);
  const [editMode, setEditMode] = useState(null);
  const [showProfileImageDialog, setShowProfileImageDialog] = useState(false);

  const [profilePicModalMessage, setProfilePicModalMessage] = useState("");
  const [showProfilePicModalMessage, setShowProfilePicModalMessage] =
    useState(false);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [locationOptions,setLocationOptions] = useState([]);
  const url1 = Url();
  const url = url1["url"];
  const s3 = url1["s3"];
  const college_Id = localStorage.getItem("collegeId");

  const [locationId, setLocationId] = useState();

  const [collegeData, setCollegeData] = useState({
    college_Id: parseInt(college_Id),
    college_name: "",
    mobileno: "",
    email: "",
    location_id: 6,
    college_logo: "",
    contact_name: "",
    website: "",
    address: "",
    created_at: "",
    active_ind: 1,
  });

  useEffect(() => {
    const api = url + "getLocationListWithId";
    fetch(api, {
      method: "GET",
    })
      .then((apidata1) => {
        status = apidata1.status;
        return apidata1.json();
      })
      .then((actualdata1) => {
        console.log(actualdata1);
        setLocationOptions(actualdata1);
      });

    let status;
    const api3 = url + "getCollegeProfile" + "/" + college_Id;
    fetch(api3, {
      method: "GET",
    })
      .then((apidata1) => {
        status = apidata1.status;
        return apidata1.json();
      })
      .then((actualdata1) => {
        console.log(actualdata1);
        setCollegeData(actualdata1[0]);
        setLocationId({value:actualdata1[0].location_id, label:actualdata1[0].location_name});
      });

  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setCollegeData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkHandleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setLoading(true);

    const api = url + "saveCollegeProfile";
    var status;
    fetch(api, {
      method: "POST",
      body: JSON.stringify({
        id: college_Id, // Replace with the correct college ID
        college_name: collegeData?.college_name,
        location_id: locationId.value,
        mobileno: collegeData?.mobileno,
        email: collegeData?.email,
        college_logo: collegeData?.college_logo,
        contact_name: collegeData?.contact_name,
        website: collegeData?.website,
        address: collegeData?.address,
        active_ind: collegeData?.active_ind,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((op) => {
        status = op.status;
        return op.json();
      })
      .then((op1) => {
        if (status === 200 || status === 201) {
          toast.success("college profile updated successfully...");

          localStorage.removeItem("colProfileStatus");

          window.location.href = "/coldashboard/profile";
        }
      });

    console.log(collegeData);
  };

  const picSubmit = async (data) => {
    const college_Id = localStorage.getItem("collegeId");

    const x =
      collegeData.college_logo === undefined
        ? "not-found"
        : collegeData.college_logo;
    var logo;
    const imgFile = await fetch(data).then((r) => r.blob());
    console.log(imgFile);
    const formData = new FormData();
    formData.append("college_logo", imgFile);
    formData.append("college_id", college_Id);
    fetch(url + "uploadCollegeLogo", {
      method: "POST",
      body: formData,
    })
      .then((op) => {
        return op.json();
      })
      .then((op1) => {
        return op1;
      })
      .then((op2) => {
        console.log(op2["message"]);
        if (op2["message"] !== "not-found") {
          setShowProfileImageDialog(false);
          logo = op2["message"];
          setCollegeData((prevState) => ({
            ...prevState,
            ["college_logo"]: logo,
          }));
        } else {
          setProfilePicModalMessage(op2["message"]);
          setShowProfilePicModalMessage(true);
        }
      });
  };

  return (
    <>
      <ToastContainer />
      <section className="pt-5 pb-6 bg-white">
        <Container>
          <Row>
            <Col lg={3} sm={12}>
              <Card className="mb-4 border">
                <Navbar expand="lg" className="sidebar-menu">
                  <Link className="d-lg-none" to="#">
                    Menu
                  </Link>
                  <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    className="p-0 focus-none border-0"
                    label="Responsive Menu"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </Navbar.Toggle>
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav
                      className="nav flex-column w-100"
                      as="ul"
                      activeKey="0"
                    >
                      <Nav.Item as="li">
                        <Link className="nav-link" to="/coldashboard">
                          Dashboard
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          className="nav-link"
                          style={{ backgroundColor: "#e2e8f0" }}
                          to="#"
                        >
                          Profile
                        </Link>
                      </Nav.Item>

                      <Nav.Item as="li">
                        <Link className="nav-link " to="/logout">
                          Sign Out
                        </Link>
                      </Nav.Item>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </Card>
            </Col>
            <Col className="col-lg-9">
              <div className="mt-6 mt-lg-0 mb-4">
                <div className="border-bottom mb-4 pb-4">
                  <h2 className="mb-0">Profile</h2>
                  <Form.Group
                      as={Col}
                      lg={6}
                      controlId="email"
                    >
                      <Form.Label className="small">Email ID : {collegeData?.email}</Form.Label>
                    </Form.Group>                  
                </div>
                <div>
                  <Form
                    noValidate
                    validated={validated}
                    className="row"
                    onSubmit={checkHandleSubmit}
                  >
                    <Col xs={12} className="mb-3">
                      <h3>College details</h3>
                      <p>
                        Edit college details,contact person, address, and website etc...
                      </p>
                    </Col>
                    <Form.Group
                      as={Col}
                      lg={6}
                      className="mb-3"
                      controlId="college_name"
                    >
                      <Form.Label>College Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="college_name"
                        value={collegeData?.college_name}
                        onChange={handleChange}
                        placeholder="Enter college Name"
                        required
                        isValid={validated && collegeData.college_name}
                        isInvalid={validated && !collegeData.college_name}
                      />
                      <Form.Control.Feedback type="valid">
                        Looks good!
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please Enter College Name.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      lg={6}
                      className="mb-3"
                      controlId="mobile-no"
                    >
                      <Form.Label>Mobile No.</Form.Label>
                      <Form.Control
                        type="text"
                        name="mobileno"
                        maxLength={10}
                        value={collegeData?.mobileno}
                        onChange={handleChange}
                        placeholder="Enter Mobile No."
                        required
                        pattern="^[0-9]{10}$"
                        isInvalid={
                          validated &&
                          !!collegeData.mobileno &&
                          !collegeData.mobileno.match(/^[0-9]{10}$/)
                        }
                      />

                      <Form.Control.Feedback type="valid">
                        Looks good!
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Mobile No.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Col xs={12} className="mb-3 mt-6">
                      <h3>Personal Details</h3>
                      <p>
                        Edit your Personal Details, address and website etc...
                      </p>
                    </Col>
                    <Col xs={12} className="mb-5">
                      <div className="d-flex">
                        <div className="d-flex align-items-center position-relative ">
                          <span>
                            <div className="ms-auto mb-1">
                              <Link to="#" className="me-1" title="Edit">
                                <i
                                  className="fe fe-edit fs-3"
                                  onClick={() =>
                                    setShowProfileImageDialog(true)
                                  }
                                ></i>
                              </Link>
                            </div>
                            <Modal
                              show={showProfileImageDialog}
                              onHide={() => setShowProfileImageDialog(false)}
                              className=""
                            >
                              <CropImageDialog
                                close={() => setShowProfileImageDialog(true)}
                                setCroppedImage={picSubmit}
                              />

                              {showProfilePicModalMessage && (
                                <Row className="p-2">
                                  {profilePicModalMessage}
                                </Row>
                              )}
                            </Modal>
                            {collegeData?.college_logo ? (
                              <Image
                                className="image avatar avatar-sm rounded-circle"
                                src={
                                  s3 + "imgupload/" + collegeData?.college_logo
                                }
                                alt="Image"
                              />
                            ) : (
                              <Image
                                className="image avatar avatar-sm rounded-circle"
                                src={s3 + "/Account.svg"}
                                alt="Image"
                              />
                            )}
                          </span>
                          <div className="ms-3">
                            <h4 className="mb-1">College logo</h4>
                            <small>
                              Max file size: 1Mb and max resolution: 500px x
                              500px. File type: jpeg, jpg, png, gif, bmp
                            </small>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Form.Group
                      as={Col}
                      lg={6}
                      className="mb-3"
                      controlId="full-name"
                    >
                      <Form.Label>Contact Person</Form.Label>
                      <Form.Control
                        type="text"
                        name="contact_name"
                        value={collegeData?.contact_name}
                        onChange={handleChange}
                        placeholder="e.g. John Doe"
                        required
                        isValid={validated && collegeData.contact_name}
                        isInvalid={validated && !collegeData.contact_name}
                      />

                      <Form.Control.Feedback type="valid">
                        Looks good!
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Full Name.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      lg={6}
                      className="mb-3"
                      controlId="locationId"
                    >
                      <Form.Label>Location</Form.Label>
                      {/* Replace with a select box to choose the location */}
                      {console.log(locationId)}
                        <Select
                            options={locationOptions}
                            onChange={setLocationId}
                            defaultValue={locationId}
                            value={locationId}
                            required
                        />
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      lg={6}
                      className="mb-3"
                      controlId="address"
                    >
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        name="address"
                        value={collegeData?.address}
                        onChange={handleChange}
                        placeholder="e.g. HSR Layout, Bangalore"
                        required
                        isValid={validated && collegeData.address}
                        isInvalid={validated && !collegeData.address}
                      />

                      <Form.Control.Feedback type="valid">
                        Looks good!
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Address.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Col lg={6} className="mb-3 "></Col>
                    <Form.Group
                      as={Col}
                      lg={6}
                      className="mb-3"
                      controlId="website"
                    >
                      <Form.Label>Website</Form.Label>
                      <Form.Control
                        type="text"
                        name="website"
                        value={collegeData?.website}
                        onChange={handleChange}
                        placeholder="e.g. https://college.com/"
                        required
                        isValid={validated && collegeData.website}
                        isInvalid={validated && !collegeData.website}
                      />

                      <Form.Control.Feedback type="valid">
                        Looks good!
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Website.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Col lg={6} className="mb-4 "></Col>
                    <Col lg={6}>
                      <Button variant="primary" type="submit">
                        {loading ? <Spinner size="sm" /> : "Update Profile"}
                      </Button>
                      <Link to="/coldashboard/profile">
                        <Button
                          variant="outline-primary"
                          className="ms-1"
                          type="button"
                        >
                          Cancel
                        </Button>
                      </Link>
                    </Col>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default EditProfile;
