import React, { Fragment,useState, useEffect } from "react"
import { useForm } from "react-hook-form";
// import "./ApproveCAProjects.css"
import {
    Col,
    Row,
    Dropdown,
    Card,
    Breadcrumb,
    Button,
    Table,
    Form
} from 'react-bootstrap';
import {
    useTable,
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
} from 'react-table';
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import { Trash, Send, Inbox, MoreVertical } from 'react-feather';
import Pagination from 'components/elements/advance-table/Pagination';
import { Link } from "react-router-dom";
import { useMemo } from "react";

// import Navadmin from './Navadmin'
import Url from "../../Url";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
//import { set } from "react-ga";

toast.configure()
const OnlineTrainingcontent = () => {

    const { register, handleSubmit } = useForm()

    const url1 = Url()
    const url = url1["url"]
    const s3 = url1["s3"]

    const [s3url, setS3url] = useState(s3 + 'imguploadvideolink/')

    const [getcoursedetails, setGetcoursedetails] = useState([])
    //const [getcoursedetailsbyid, setGetcoursedetailsbyid] = useState([])
    const [getotid, setGetotid] = useState("")
    const [searchcontent, setSearchcontent] = useState(null)

    const [partner_name, setPartner_name] = useState("")
    const [subject_name, setSubject_name] = useState("")
    const [video_url, setVideo_url] = useState("")
    const [video_description, setVideo_description] = useState("")
    const [video_length, setVideo_length] = useState("")
    const [category, setCategory] = useState("")
    const [certification, setCertification] = useState("")
    const [level, setLevel] = useState("")
    const [displayimg, setDisplayimg] = useState("")

    const [createtraingmodal, setCreatetraingmodal] = useState(false)
    const [editrainingmodal, setEditrainingmodal] = useState(false)

    const [deletescuucess, setDeletescuucess] = useState(false)

    useEffect(() => {
        const api = url + "getAllVideolinks"
        fetch(api, {
            method: 'GET',
        })
            .then((apidata1) => {
                return apidata1.json()
            })
            .then((actualdata1) => {
                //console.log(actualdata1)
                setGetcoursedetails(actualdata1)
            }
            )
    }, [deletescuucess])

    const getOTbyid = (id) => {
        
        const api = url + "getVideoById" + "/" + id
        fetch(api, {
            method: 'GET',
        })
            .then((apidata1) => {
                return apidata1.json()
            })
            .then((actualdata1) => {
                //console.log(actualdata1)
                //setGetcoursedetailsbyid(actualdata1)
                setPartner_name(actualdata1.partner_name)
                setSubject_name(actualdata1.subject_name)
                setCategory(actualdata1.category)
                setCertification(actualdata1.certification)
                setLevel(actualdata1.level)
                setVideo_description(actualdata1.video_description)
                setVideo_length(actualdata1.video_length)
                setVideo_url(actualdata1.video_url)
                setDisplayimg(actualdata1.displayimg)

                setGetotid(actualdata1.id)
                setEditrainingmodal(true)
            }
            )
    }


    const onSubmit = (data) => {
        //console.log(data)
        setDeletescuucess(false)
        const formData = new FormData()
        formData.append("displayimg", data.displayimg[0])
        /*if (!data.pic[0]) {
            toast.error('Please select your Profile picture....', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
            return
        }*/

        fetch(url + 'imgvdeiolinkup', {
            method: 'POST',
            body: formData
        })
            .then((op) => {
                return op.json()
            })
            .then((op1) => {
                return op1
            })
            ///////////////////////////
            .then((op2) => {

                const displayimg = op2["message"]
                const video_url = data.link
                const partner_name = data.pname
                const subject_name = data.subject
                const video_length = data.length
                const video_description = data.vdesc
                const level = data.level
                const category = data.category
                const certification = data.certification

                const controller = "createvideoMaster"
                const api = url + controller
                fetch(api, {
                    method: 'POST',
                    body: JSON.stringify({ subject_name, video_url, video_length, video_description, partner_name, level, displayimg, category, certification }),
                    headers: { 'Content-Type': 'application/json' }
                })
                    .then((op) => {
                        return op.json()
                    })
                    .then((op1) => {
                        //console.log(op1)
                        if (op1["message"] === "video_link_master_created") {
                            setCreatetraingmodal(false)
                            setDeletescuucess(true)
                            toast.success('Training added successfully ....', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
                        }
                    })
            })
    }

    const editOnlineTraining = (data) => {
        setDeletescuucess(false)
        // console.log(data)
        const formData = new FormData()
        formData.append("displayimg", data.displayimg[0])
        /*if (!data.pic[0]) {
            toast.error('Please select your Profile picture....', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
            return
        }*/

        fetch(url + 'imgvdeiolinkup', {
            method: 'POST',
            body: formData
        })
            .then((op) => {
                return op.json()
            })
            .then((op1) => {
                return op1
            })
            ///////////////////////////
            .then((op2) => {

                const displayimg = op2["message"]
                const id = getotid
                const video_url = data.link
                const partner_name = data.pname
                // console.log(partner_name)
                const subject_name = data.subject
                const video_length = data.length
                const video_description = data.vdesc
                const level = data.level
                const category = data.category
                const certification = data.certification

                const controller = "UpdateVideomaster" + "/" + id
                const api = url + controller
                fetch(api, {
                    method: 'POST',
                    body: JSON.stringify({ subject_name, video_url, video_length, video_description, partner_name, level, displayimg, category, certification }),
                    headers: { 'Content-Type': 'application/json' }
                })
                    .then((op) => {
                        return op.json()
                    })
                    .then((op1) => {
                        //console.log(op1)
                        setEditrainingmodal(false)
                        setDeletescuucess(true)
                        toast.success('Training upadated successfully ....', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
                    })
            })
    }

    const deleteOnlineTraining = (id) => {

        const res = window.confirm("The trainig will be deleted, are you sure?")
        if (res) {
            const api = url + "DeleteVideoLinkById" + "/" + id
            fetch(api, {
                method: 'DELETE',
                body: JSON.stringify({}),
                headers: { 'Content-Type': 'application/json' }
            })
                .then((apidata) => {
                    //console.log(apidata)
                    return apidata.json()
                })
                .then((actualdata) => {
                    //console.log(actualdata)

                    toast.success('Training deleted successfully ....', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
                    setDeletescuucess(true)

                })
        }
    }   

    const CustomCell = ({ value }) => {
        const maxHeight = "40px";
        const whiteSpace = value.length > 30 ? "pre-wrap" : "nowrap";
        return (
          <div style={{ maxHeight, overflow: "hidden", whiteSpace }}>{value}</div>
        );
    };

    const columns = useMemo(
        () => [
            { accessor: 'partner_name', Header: 'Partner Name' },
            { accessor: 'subject_name', Header: 'Subject Name',
            Cell: ({value}) => {
                return(
                  <span>
                    {value === null ? "No Skill Added" : 
                      <CustomCell value={value} />
                    }
                  </span>
                )
              } 
            },
            { accessor: 'category', Header: 'Category', id: "email" },
            { accessor: 'certification', Header: 'Certification' },
            { accessor: 'level', Header: 'Level' },
            { accessor: 'video_description', Header: 'Video Description',
                Cell: ({value}) => {
                    return(
                    <span>
                        {value === null ? "No Skill Added" : 
                        <CustomCell value={value} />
                        }
                    </span>
                    )
                }  
            },
            { accessor: 'video_length', Header: 'Video Length', id: "status"},
            { accessor: 'video_url', Header: 'Video URL', 
            Cell: ({value}) => {
                return(
                  <span>
                    {value === null ? "No Skill Added" : 
                      <CustomCell value={value} />
                    }
                  </span>
                )
              }     
        },
            { accessor: 'created_at', Header: 'Registration Date' },
            { accessor: 'action', Header: 'Action',
                Cell: ({value, row}) => {
                    return(
                        <>
                            <Button className="btn-sm btn-outline-danger btn-white m-1" onClick={() => deleteOnlineTraining(row.original.id)}>
                                Delete
                            </Button>
                            <Button variant="secondary" className="btn-sm m-1"onClick={() => getOTbyid(row.original.id)}>
                                Edit
                            </Button>
                        </>
                    )
                }
            },
        ],
        []
        );
    const data = useMemo(() => getcoursedetails, [getcoursedetails]);

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            nextPage,
            previousPage,
            state,
            gotoPage,
            pageCount,
            prepareRow,
            setGlobalFilter
        } = useTable(
            {
                columns,
                data,
                initialState: {
                    pageSize: 100,
                    hiddenColumns: columns.map((column) => {
                        if (column.show === false) return column.accessor || column.id;
                        else return false;
                    })
                }
            },
            useFilters,
            useGlobalFilter,
            usePagination,
            useRowSelect,
            (hooks) => {
                hooks.visibleColumns.push((columns) => [
                    ...columns
                ]);
            }
        );

        const { pageIndex, globalFilter } = state;


    return (
        <>

            <Modal
                size=""
                show={createtraingmodal}
                className="modalbgscreen d-none d-sm-block"
                aria-labelledby="example-modal-sizes-title-sm"
            >

                <Modal.Body className="mt-0 firstloginmodal modalnotification">

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            Add new Training Details
                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 mt-3 oltrainingmodal">Partner Name:</span>
                            <span className="mt-0">
                                <Form.Control placeholder="Partner name" className="oltrainingcreate ml-0 "
                                    name="pname"
                                    required
                                    ref={register}
                                />
                            </span>

                        </Row>
                        <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Subject:</span>
                            <span>
                                <Form.Control placeholder="Subject" className="oltrainingcreate ml-5 "

                                    name="subject"
                                    required
                                    ref={register}

                                />
                            </span>

                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Difficulty Level:</span>
                            <span>
                                <Form.Control as="select" placeholder="Difficulty Level" className="oltrainingcreate ml-0 "

                                    name="level"
                                    required
                                    ref={register}

                                >
                                    <option value="">Select</option>
                                    <option value="Low">Low</option>
                                    <option value="Intermediate">Intermediate</option>
                                    <option value="Expert">Expert</option>
                                </Form.Control>
                            </span>

                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Course Link:</span>
                            <span>
                                <Form.Control placeholder="Course Link" className="oltrainingcreate ml-4"

                                    name="link"
                                    required
                                    ref={register}

                                />
                            </span>
                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Category:</span>
                            <span>
                                <Form.Control as="select" placeholder="Category" className="oltrainingcreate ml-5"

                                    name="category"
                                    required

                                    ref={register}
                                >
                                    <option value="">Select</option>
                                    <option value="AWS">AWS</option>
                                    <option value="AZURE">AZURE</option>
                                    <option value="GCP">GCP</option>
                                    <option value="Python">Python</option>
                                    <option value="Java">Java</option>
                                    <option value="Web Development">Web Development</option>
                                    <option value="Cyber Security">Cyber Security</option>
                                    <option value="Node JS">Node JS</option>
                                    <option value="Blockchain">Blockchain</option>
                                    <option value="User Experience and Interface">User Experience and Interface</option>
                                    <option value="Linux">Linux</option>
                                    <option value="Digital Marketing">Digital Marketing</option>
                                    <option value="Data Science">Data Science</option>
                                    <option value="Internet of things (IoT)">Internet of things (IoT)</option>
                                    <option value="Container">Container</option>
                                    <option value="Bigdata">Bigdata</option>
                                    <option value="DevOPs">DevOPs</option>
                                    <option value="Management">Management</option>
                                </Form.Control>
                            </span>
                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Course Length:</span>
                            <span>
                                <Form.Control placeholder="Course Length" className="oltrainingcreate ml-0"

                                    name="length"
                                    required
                                    ref={register}

                                />
                            </span>
                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Course Description:</span>
                            <span>
                                <Form.Control placeholder="Course Description" className="oltrainingcreate ml-0"

                                    name="vdesc"
                                    required
                                    ref={register}

                                />
                            </span>
                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Certification:</span>
                            <span>
                                <Form.Control as="select" placeholder="Course Length" className="oltrainingcreate ml-4"

                                    name="certification"
                                    required
                                    ref={register}

                                >
                                    <option value="">Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </Form.Control>

                            </span>
                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Display Image:</span>
                            <span>
                                <Form.Control placeholder="Course Length" className="oltrainingcreate ml-2"

                                    name="displayimg"
                                    type="file"
                                    ref={register}
                                    id="file-input"
                                    required
                                />
                            </span>
                        </Row>

                        <Row className="mt-2 ml-0 mr-0">
                            <Col>
                                <Button className="m-1">
                                    Add Training Detail
                                </Button>
                                <Button className="btn-white btn-outline-primary m-1"
                                    onClick={() => setCreatetraingmodal(false)}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
                <Row className=" mb-3">

                    


                </Row>

            </Modal>
            {/*-------------------------- Create Training Modal end-------------------------- */}


            {/*-------------------------- Update Training Modal-------------------------- */}
           
              <Modal
                size=""
                show={editrainingmodal}
                className="modalbgscreen d-none d-sm-block"
                aria-labelledby="example-modal-sizes-title-sm"
            >

                <Modal.Body className="mt-0 firstloginmodal modalnotification">

                    <form onSubmit={handleSubmit(editOnlineTraining)}>
                        <Row>
                            Update Training Details
                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 mt-3 oltrainingmodal">Partner Name:</span>
                            <span className="mt-0">
                                <Form.Control placeholder="Partner name" className="oltrainingcreate ml-0 "

                                    name="pname"
                                    required
                                    ref={register}
                                    value={partner_name}
                                    onChange={(e) => setPartner_name(e.target.value)}
                                />
                            </span>

                        </Row>
                        <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Subject:</span>
                            <span>
                                <Form.Control placeholder="Subject" className="oltrainingcreate ml-5 "

                                    name="subject"
                                    required
                                    ref={register}
                                    value={subject_name}
                                    onChange={(e) => setSubject_name(e.target.value)}
                                />
                            </span>

                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Difficulty Level:</span>
                            <span>
                                <Form.Control as="select" placeholder="Difficulty Level" className="oltrainingcreate ml-0 "

                                    name="level"
                                    required
                                    ref={register}

                                >
                                    <option value={level}>{level}</option>
                                    <option value="Low">Low</option>
                                    <option value="Intermediate">Intermediate</option>
                                    <option value="Expert">Expert</option>
                                </Form.Control>
                            </span>

                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Course Link:</span>
                            <span>
                                <Form.Control placeholder="Course Link" className="oltrainingcreate ml-4"

                                    name="link"
                                    required
                                    ref={register}
                                    value={video_url}
                                    onChange={(e) => setVideo_url(e.target.value)}
                                />
                            </span>
                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Category:</span>
                            <span>
                                <Form.Control as="select" placeholder="Category" className="oltrainingcreate ml-5"

                                    name="category"
                                    required

                                    ref={register}

                                >
                                    <option value={category}>{category}</option>
                                    <option value="AWS">AWS</option>
                                    <option value="AZURE">AZURE</option>
                                    <option value="GCP">GCP</option>
                                    <option value="Python">Python</option>
                                    <option value="Java">Java</option>
                                    <option value="Web Development">Web Development</option>
                                    <option value="Cyber Security">Cyber Security</option>
                                    <option value="Node JS">Node JS</option>
                                    <option value="Blockchain">Blockchain</option>
                                    <option value="User Experience and Interface">User Experience and Interface</option>
                                    <option value="Linux">Linux</option>
                                    <option value="Digital Marketing">Digital Marketing</option>
                                    <option value="Data Science">Data Science</option>
                                    <option value="Internet of things (IoT)">Internet of things (IoT)</option>
                                    <option value="Container">Container</option>
                                    <option value="Bigdata">Bigdata</option>
                                    <option value="DevOPs">DevOPs</option>
                                    <option value="Management">Management</option>
                                </Form.Control>
                            </span>
                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Course Length:</span>
                            <span>
                                <Form.Control placeholder="Course Length" className="oltrainingcreate ml-0"

                                    name="length"
                                    required
                                    ref={register}
                                    value={video_length}
                                    onChange={(e) => setVideo_length(e.target.value)}
                                />
                            </span>
                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Course Description:</span>
                            <span>
                                <Form.Control placeholder="Course Description" className="oltrainingcreate ml-0"

                                    name="vdesc"
                                    required
                                    ref={register}
                                    value={video_description}
                                    onChange={(e) => setVideo_description(e.target.value)}
                                />
                            </span>
                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Certification:</span>
                            <span>
                                <Form.Control as="select" placeholder="Course Length" className="oltrainingcreate ml-4"

                                    name="certification"
                                    required
                                    ref={register}

                                >
                                    <option value={certification}>{certification}</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </Form.Control>

                            </span>
                        </Row>

                        <Row className="mt-3">
                            <span className="ml-0 mr-1 oltrainingmodal">Display Image:</span>
                            <span>
                                <Form.Control placeholder="Course Length" className="oltrainingcreate ml-2"

                                    name="displayimg"
                                    type="file"
                                    ref={register}
                                    id="file-input"
                                />


                            </span>
                        </Row>
                        {displayimg === "NA" ?
                            null
                            :
                            <Row className="mt-3">
                                <img src={s3url + displayimg} width="100px" height="100px" />
                            </Row>
                        }



                        <Row className="mt-2 ml-0 mr-0">
                            <Col>
                                <Button className="m-1">
                                    Update Training Detail
                                </Button>
                                 <Button className="m-1 ml-0 btn-outline-primary btn-white"
                                    onClick={() => setEditrainingmodal(false)}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
            {/*-------------------------- Update Training Modal end-------------------------- */}


            <Fragment>
    <Row>
      <Col lg={12} md={12} sm={12}>
        <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
          <div className="mb-3 mb-md-0">
            <h1 className="mb-1 h2 fw-bold">Online Training Content List</h1>
            <Breadcrumb>
              <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item href="#">Online Training</Breadcrumb.Item>
              <Breadcrumb.Item active>Add Online Training</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
          </div>
        </div>
      </Col>
    </Row>
    <Row>
    <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-3 px-3 ">
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              placeholder="Search"
            />
    </Col>
    <Col sm={12} className="ml-0">
    <Button 
            onClick={() => { setCreatetraingmodal(true) }}
            >Add New Training</Button>
            </Col>
    </Row>

    <Row className="mt-3">
      <Col lg={12} md={12} sm={12}>
        <Card>
          <Card.Body className="p-0">
            <div className="table-responsive" style={{overflow: "auto",height:"500px"}}>
              <Table {...getTableProps()} className="text-nowrap" style={{height:"400px"}}>
                <thead className="table-light">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>

            {/* Pagination @ Footer */}
            <Pagination
              previousPage={previousPage}
              pageCount={pageCount}
              pageIndex={pageIndex}
              gotoPage={gotoPage}
              nextPage={nextPage}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
            </Fragment>

        </>
    )
}

export default OnlineTrainingcontent