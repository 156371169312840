// import node module libraries
import { Link } from 'react-router-dom';
import { Col, Row, Container } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

// import custom components
import SectionHeadingCenter from './SectionHeadingCenter';

// import sub components
import InstructorCard from './InstructorCard';

// import data files
import { InstructorsList } from 'data/marketing/LandingCoursesData';

const WorldClassInstructors = (props) => {

	const {mentorList} = props;

	const title = 'Classes Taught by Industry Expert';
	const subtitle = 'World-class Instructors';
	const description = 'Choose the perfect mentor to shape your success.';

	const isLaptop = useMediaQuery({ minWidth: 1024, maxWidth: 1445 });

	return (
		// <div className="py-2 py-lg-4 bg-light-gradient-top bg-white">
		<div className="py-2 py-lg-4">
			<Container>
				<SectionHeadingCenter
					title={title}
					description={description}
					subtitle={subtitle}
				/>
				<Row>
					{mentorList.map((item, index) => (
						<Col
							key={index}
							xl={4}
							lg={4}
							md={6}
							sm={12}
							className={`${
								isLaptop && index === 3 ? 'd-lg-none d-xl-block' : ''
							}`}
						>
							<InstructorCard item={item} />
						</Col>
					))}
				</Row>
				{/* <Row>
					<Col md={12} className="mt-3 text-center">
						<Link to="#" className="btn btn-primary">
							See All Instructors
						</Link>
					</Col>
				</Row> */}
			</Container>
		</div>
	);
};

export default WorldClassInstructors;
