// import node module libraries
import React, { useContext, useState, useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import { ListGroup, Badge } from 'react-bootstrap';

// import hook file
import useChatOperations from 'hooks/useChatOperations';

// import context file
import { ChatContext } from 'context/Context';
import Url from 'components/Url';

const AllMessagesList = (props) => {
	const { setHideChatBox, chatList, getChatMessage, search, setName, setId, id } = props;
	const token = localStorage.getItem("emptoken")
	const stutoken = localStorage.getItem("stutoken")
	const studentid = localStorage.getItem("studentId")
	const empid = localStorage.getItem("id")
	const [newChatCounter, setNewChatCounter] = useState()
	const url1 = Url();
	const url = url1["url"];
	// const {
	// 	ChatState: { threads, activeThread },
	// 	ChatDispatch
	// } = useContext(ChatContext);

	// const {
	// 	getUserDetails,
	// 	getLastMessageTime
	// } = useChatOperations();

	// const handleOpenChatThread = (item) => {
	// 	setHideChatBox(true);
	// 	ChatDispatch({ type: 'CHANGE_THREAD', payload: item });
	const getNewSMsgCounter = () => {
		const api = url + 'getNewChatCounterForStudent/' + studentid + '/S' + stutoken;
		 fetch(api, {
			 method: 'GET',
		 })
		 .then((apidata1) => {
			 //console.log(apidata1)
			 return apidata1.json();
		 })
		 .then((actualdata1) => {
			 setNewChatCounter(actualdata1.newChatMessageCount);
		 });
	 }

	 const getNewEMsgCounter = () => {
		const api1 = url + 'getNewChatCounterForEmployer/' + empid + '/E' + token;
			fetch(api1, {
				method: 'GET',
			})
				.then((apidata1) => {
					//console.log(apidata1)
					return apidata1.json();
				})
				.then((actualdata1) => {
					setNewChatCounter(actualdata1.newChatMessageCount)
				});
	  }

	 useEffect(() => {
		if(token){
			getNewEMsgCounter()
		}
		if(stutoken){
			getNewSMsgCounter()
		}
	 },[])

	 const displayCount = (id) => {
		if (newChatCounter && id) {
			if (newChatCounter[id]) {
			  return newChatCounter[id].no_of_unread_messages;
			}
		  }
	 }

	return (
		<SimpleBar style={{ maxHeight: '650px' }}>
			<ListGroup bsPrefix="list-unstyled" as="ul" className="contacts-list">
				{chatList?.map((item) => (
							<ListGroup.Item
								as="li"
								bsPrefix=" "
								role="button"
								className={`py-3 px-4 chat-item contacts-item ${item.id === id ? "bg-light" : "bg-white"}`}
								onClick={() => {
									setId(item.id)
									setName(!token ? item.companyname : item.student_name)
									getChatMessage(item.id)
									displayCount(item.chat_id)
								}}
							>
								<div className="d-flex w-100 align-items-center"
									// onClick={() => handleOpenChatThread(item)}
								>
										<div className="w-100">
											<div className="d-flex w-100 align-items-center justify-content-between mb-1">
												<h4 className="mb-0">
													{!token ? item.companyname : item.student_name} 
												</h4>
												<small>{item.created_at.substr(11, 5)}</small>
											</div>
											<div>
												<p>{item.internship_details}</p>
												<div className='d-flex justify-content-between w-100 align-items-center'>
													<Badge
														bg={item.application_status === "Not Shortlisted" ? "warning" : item.application_status === "Hired/Offer Sent" ? "success" : item.application_status === "Rejected" ? "danger" : "info"}
														className="border text-bg-white"
														pill>
														{item.application_status}
													</Badge>
													<Badge
													bg="danger"
													className={id && "d-none"}
													style={{
														background: "red",
														color: "white",
														float: "right",
														fontSize: "9px",
													}}
													>
													{displayCount(item.chat_id)}
													</Badge>
												</div>
											</div>
										</div>
								</div>
							</ListGroup.Item>
				))}
			</ListGroup>
		</SimpleBar>
	);
};
export default AllMessagesList;
