// import sub custom components
import SendMessage from './SendMessage';

const ChatFooter = (props) => {
	const { setMessage, message, postChatMessage } = props;
	return (
		<div className="px-4 py-3 chat-footer mt-auto border-top bg-white">
			<SendMessage setMessage={setMessage} message={message} postChatMessage={postChatMessage} />
		</div>
	);
};
export default ChatFooter;
