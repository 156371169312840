/* eslint-disable prettier/prettier */
// import node module libraries
import { Fragment, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { ChevronLeft, ChevronRight } from "react-feather";

// import sub components
import CourseCard from "./CourseCard";
import CoursesTab from "components/marketing/pages/courses/course-path-single/CoursesTab";

// import data files
// import { AllCoursesData } from 'data/slider/AllCoursesData';

const CourseGridView = ({
	courseData,
	courseImg,
	levelFilter,
	categoryFilter,
	courseProviderFilter,
	certificationFilter,
	courseLengthFilter,
}) => {
	// console.log("Course Grid View", levelFilter.length);
	// const filteredCourses = levelFilter.length > 0 ? courseData.filter(course => levelFilter.includes(course.level)) : courseData

	//   console.log(courseData);

	let filteredCourses = courseData;

	// const lengthFilter = (filter) => {
	// 	// console.log("Length filter function start");

	// 	if (filter === "Under 2 hours") {
	// 		return filteredCourses.filter((course) => course.video_length < 2);
	// 	} else if (filter === "2-10 hours") {
	// 		return filteredCourses.filter(
	// 			(course) => course.video_length >= 2 && course.video_length <= 10
	// 		);
	// 	} else if (filter === "11-20 hours") {
	// 		return filteredCourses.filter(
	// 			(course) => course.video_length > 10 && course.video_length <= 20
	// 		);
	// 	} else if (filter === "20+ hours") {
	// 		return filteredCourses.filter((course) => course.video_length > 20);
	// 	} else {
	// 		return filteredCourses;
	// 	}
	// };

	if (levelFilter.length !== 0) {
		filteredCourses = filteredCourses.filter((course) =>
			levelFilter.includes(course.level)
		);
	}

	if (categoryFilter.length !== 0) {
		filteredCourses = filteredCourses.filter((course) =>
			categoryFilter.includes(course.category)
		);
	}

	if (courseProviderFilter.length !== 0) {
		filteredCourses = filteredCourses.filter((course) =>
			courseProviderFilter.includes(course.partner_name)
		);
	}

	if (certificationFilter.length !== 0) {
		filteredCourses = filteredCourses.filter((course) =>
			certificationFilter.includes(course.certification)
		);
	}

	// if (courseLengthFilter.length !== 0) {
	// 	courseLengthFilter.map((option) => {
	// 		console.log("Inside length filter function", option);
	// 		return lengthFilter(option);
	// 	});
	// }

	// console.log(filteredCourses);

	//------paging start----------
	const [pageNumber, setPageNumber] = useState(0);
	const RecordsPerPage = 48;
	const pagesVisited = pageNumber * RecordsPerPage;
	const pageCount = Math.ceil(filteredCourses.length / RecordsPerPage);
	// console.log("Pages = ", pageCount);
	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};
	const displayRecords = filteredCourses
		.slice(pagesVisited, pagesVisited + RecordsPerPage)
		.map((courseData, index) => {
			return (
				<Col lg={4} md={6} sm={12} key={index}>
					<CourseCard item={courseData} courseImg={courseImg} />
				</Col>
			);
		});
	//---end of paging start----------

	return (
		<Fragment>
			<Row>
				{displayRecords.length > 0 ? (
					displayRecords
				) : (
					<Col>No matching records found.</Col>
				)}
			</Row>

			<ReactPaginate
				previousLabel={<ChevronLeft size="14px" />}
				nextLabel={<ChevronRight size="14px" />}
				pageCount={pageCount}
				onPageChange={changePage}
				containerClassName={"justify-content-center mb-0 pagination"}
				previousLinkClassName={"page-link mx-1 rounded"}
				nextLinkClassName={"page-link mx-1 rounded"}
				pageClassName={"page-item"}
				pageLinkClassName={"page-link mx-1 rounded"}
				disabledClassName={"paginationDisabled"}
				activeClassName={"active"}
			/>
		</Fragment>
	);
};
export default CourseGridView;

// const [pageNumber, setPageNumber] = useState(0);
// const RecordsPerPage = 9;
// const pagesVisited = pageNumber * RecordsPerPage;
// const pageCount = Math.ceil(Records.length / RecordsPerPage);
// const changePage = ({ selected }) => {
// 	setPageNumber(selected);
// };
// const displayRecords = Records.slice(
// 	pagesVisited,
// 	pagesVisited + RecordsPerPage
// ).map((Records, index) => {
// 	return (
// 		<Col lg={4} md={6} sm={12} key={index}>
// 			<CourseCard item={Records} courseImg={courseImg} />
// 		</Col>
// 	);
// });
