import React, { memo, forwardRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./index.css";

export const isEmpty = (value) =>
  !value || (value && value.replace(/<(.|\n)*?>/g, "").trim().length === 0);

const checkCharacterCount = (event) => {
    const key = event.key.toLowerCase();
    if (event.target.innerText.length > 1000 && (event.key !== 'Backspace' || event.key !== "Enter")){
      alert("Only 1000 characters allowed");
      event.preventDefault();
    }
}  
const RTE = forwardRef(({ value, onChange, editableProps = {} }, ref) => (

  <ReactQuill
    className="igRte"
    ref={ref}
    theme="snow"
    defaultValue={value}
    onChange={onChange}
    {...editableProps}
    onKeyPress={ (e) => checkCharacterCount(e) }
  />
));

export default memo(RTE);
