// import node module libraries
import { BrowserRouter as Router } from 'react-router-dom';

// import layouts
import ScrollToTop from 'layouts/dashboard/ScrollToTop';
import AllRoutes from 'layouts/AllRoutes';
import { FaWhatsapp } from "react-icons/fa"
// import required stylesheet
import 'simplebar/dist/simplebar.min.css';
import 'tippy.js/animations/scale.css';
import "./App.css";

function App() {
	return (
		<>
		<Router>
			<ScrollToTop />
			<AllRoutes />
		</Router>

		<div className="widgets-wrapper" id="whatsappwidget">
			<a href="https://api.whatsapp.com/send?phone=918105566534&text=Hi,%20i%20want%20more%20information%20about%20the%20course." target="_blank">
			<button className="widgets" >
				<FaWhatsapp />
			</button>
		</a>
		</div>	
		</>	
	);

}

export default App;
