// import node module libraries
import React, { Fragment, useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  Col,
  Row,
  Dropdown,
  Card,
  Breadcrumb,
  Button,
  Modal,
  Table,
  Form,
  InputGroup,
} from "react-bootstrap";
import { Trash, Send, Inbox, MoreVertical } from "react-feather";
import { useDebounce } from "helper/utils";
// import custom components
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import Checkbox from "components/elements/advance-table/Checkbox";
import DotBadge from "components/elements/bootstrap/DotBadge";
import { ToastContainer, toast } from "react-toastify";

import Url from "components/Url";
// import data files
//import { courses } from 'data/courses/OrderPaymentData';
import {JSONToCSVConvertor} from "helper/utils";

const OrderPayment = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [courseUpdate, setCourseUpdate] = useState(false);

  const [courses, setCourses] = useState([]);

  const [filteredCourses, setFilteredCourses] = useState(courses);
  const [searchcontent, setSearchcontent] = useState("");
  const url1 = Url();
  const url = url1["url"];
  const s3 = url1["s3"];

  const debouncedSearchTerm = useDebounce(searchcontent, 2000);
  useEffect(() => {
    getReport();
  }, [courseUpdate]);

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const getReport = () => {

    var searchString = "";
    if (searchcontent) {
      searchString = "/" + searchcontent;
    }

    const api = url + "orderPaymentReport" + searchString;

    fetch(api, {
      method: "GET",
    })
      .then((apidata) => {
        return apidata.json();
      })
      .then((actualdata) => {
        // console.log("Data from API",actualdata);
        if (actualdata.orderPaymentReport.length > 0) {
          setCourses(actualdata.orderPaymentReport);
          setFilteredCourses(actualdata.orderPaymentReport);
        }
      });
    setCourseUpdate(false);
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
        // Assume there's an API function to call
        getReport();
    }
  }, [debouncedSearchTerm]); // Effect runs on change of debounced search term

  const assignCourseInLMS = (orderId) => {
    const res = window.confirm(
      "The course will be assigned to the student, are you sure?"
    );
    if (res) {
      const admtoken = localStorage.getItem("admtoken");
      const adminid = localStorage.getItem("adminid");
      const api = url + "assignCourseInLMS";

      fetch(api, {
        method: "POST",
        body: JSON.stringify({ orderId, adminid, admtoken }),
        headers: { "Content-Type": "application/json" },
      })
        .then((apidata) => {
          return apidata.json();
        })
        .then((actualdata) => {
          console.log(actualdata);
          if (actualdata?.status == 0) {
            toast.warning("Error Processing request");
            return;
          } else if (actualdata?.paymentStatus == "Success") {
            toast.success("Course assigned successfully");
            setCourseUpdate(true);
            return;
          }
        });
    }
  }; //end of assignCourseInLMS

  const ActionMenu = () => {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-secondary" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Header>ACTION</Dropdown.Header>
          <Dropdown.Item eventKey="1">
            {" "}
            <Send size="18px" className="dropdown-item-icon" /> Publish
          </Dropdown.Item>
          <Dropdown.Item eventKey="2">
            {" "}
            <Inbox size="18px" className="dropdown-item-icon" /> Moved Draft
          </Dropdown.Item>
          <Dropdown.Item eventKey="3">
            {" "}
            <Trash size="18px" className="dropdown-item-icon" /> Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      { accessor: "order_id", Header: "Order Id" },
      { accessor: "order_date", Header: "Order Date" },
      { accessor: "student_name", Header: "Student Name" },
      { accessor: "email", Header: "email" },
      { accessor: "mobileno", Header: "mobileno" },
      { accessor: "education_details", Header: "Education" },
      { accessor: "Skills", Header: "software_skill" },
      { accessor: "product_type", Header: "Product Type" },
      { accessor: "product_name", Header: "Product Name" },
      { accessor: "product_amount", Header: "Total Amount" },
      { accessor: "pg_order_id", Header: "PG Order Id" },
      {
        accessor: "payment_status",
        Header: "Payment status",
        Cell: ({ value }) => {
          return (
            <DotBadge
              bg={
                value === "Paid"
                  ? "success"
                  : value === "Not Paid"
                  ? "warning"
                  : ""
              }
            >
              {value}
            </DotBadge>
          );
        },
      },
      {
        accessor: "product_assigned",
        Header: "Product Assigned?",
        Cell: ({ value }) => {
          return (
            <DotBadge
              bg={
                value === "Assigned"
                  ? "success"
                  : value === "Not Assigned"
                  ? "warning"
                  : ""
              }
            >
              {value}
            </DotBadge>
          );
        },
      },
      {
        Header: "Assign Course",
        Cell: ({ row, value }) => {
          return (
            row.original.lms_course_assigned === "Not Assigned" && (
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => {
                  alert("functionality not available")
                  //assignCourseInLMS(row.original.order_id);
                }}
              >
                Assign Product
              </Button>
            )
          );
        },
      },
    ],
    []
  );

  const handleSearchChange = (e) => {
    const search = e.target.value;
    console.log(search)
    const filteredData = courses?.filter((course) => {
      return course.email.toLowerCase().includes(search.toLowerCase());
    });

    setFilteredCourses(filteredData);
  };

  // const data = useMemo(() => courses, [courses]);
  const data = useMemo(() => filteredCourses, [filteredCourses]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 100,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );

  const { pageIndex, globalFilter } = state;


  const handleDownload = () => {
    JSONToCSVConvertor(courses, "Order_Payments",true);
  }//end of handleDownload

  return (
    <Fragment>
      <ToastContainer />
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-2 mb-2 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <div className="mb-2">
                <h1 className="mb-1 h2 fw-bold">Order-Payment Report</h1>
                {/* <ReactHTMLTableToExcel
                  id="download-button"
                  className="btn btn-success"
                  table="payments-table"
                  filename="Payments-table-xls"
                  sheet="sheet1"
                  buttonText="Download as Excel"
                /> */}
                <button className="btn btn-success" onClick={handleDownload}>Download</button>
              </div>
              <div>
                <InputGroup size="sm" className="mb-3">
                  <Form.Control
                    type="email"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="Search Student Email"
                    value={searchcontent}
                    onChange={e => setSearchcontent(e.target.value)}
                  />

                  {/* {console.log("Filtered Courses : ", filteredCourses)} */}
                </InputGroup>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Reports</Breadcrumb.Item>
                <Breadcrumb.Item active>Order Payment Report</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div></div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card>
            <Card.Body className="p-0">
              {/**
                            <div className=" overflow-hidden">
								<Row>
									<Col
										lg={12}
										md={12}
										sm={12}
										className="mb-lg-0 mb-2 px-5 py-4"
									>
										<GlobalFilter
											filter={globalFilter}
											setFilter={setGlobalFilter}
											placeholder="Search Course"
										/>
									</Col>
								</Row>
							</div>
                            */}

              <div
                className="table-responsive"
                style={{ overflow: "auto", height: "500px" }}
              >
                <Table
                  {...getTableProps()}
                  id="payments-table"
                  className="text-nowrap"
                  style={{ height: "400px" }}
                >
                  <thead className="table-light">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              {/* Pagination @ Footer */}
              <Pagination
                previousPage={previousPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default OrderPayment;
