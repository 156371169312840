// import node module libraries
import { Fragment, useState } from "react";
import { ListGroup, Form } from "react-bootstrap";

const Question = (props) => {
  const { item, handleAnswerSelection, answer } = props;
  const questionInterface = "checkbox";
  //const [selectedValue, setSelectedValue] = useState(item.submitted_answer);
  const type = item.interface == "radio" ? "radio" : "radio";
  return (
    <Fragment>
      <h3 className="mb-3">{item.question}</h3>

      <ListGroup>
        <ListGroup.Item
          key={1}
          className={`list-group-item-action ${
            item?.submitted_answer == 1 ? "bg-light" : ""
          }`}
          aria-current="true"
        >
          <Form.Check
            type="radio"
            name="quiz_option"
            id={`quiz_option_1`}
            label={item.op1}
            key={1}
            value={1}
            onChange={handleAnswerSelection}
            checked={answer == 1}
          />
        </ListGroup.Item>

        <ListGroup.Item
          key={2}
          className={`list-group-item-action ${
            item?.submitted_answer == 2 ? "bg-light" : ""
          }`}
          aria-current="true"
        >
          <Form.Check
            type="radio"
            name="quiz_option"
            id={`quiz_option_2`}
            label={item.op2}
            key={2}
            value={2}
            onChange={handleAnswerSelection}
            checked={answer == 2}
          />
        </ListGroup.Item>
        <ListGroup.Item
          key={3}
          className={`list-group-item-action ${
            item?.submitted_answer == 3 ? "bg-light" : ""
          }`}
          aria-current="true"
        >
          <Form.Check
            type="radio"
            name="quiz_option"
            id={`quiz_option_3`}
            label={item.op3}
            key={3}
            value={3}
            onChange={handleAnswerSelection}
            checked={answer == 3}
          />
        </ListGroup.Item>

        <ListGroup.Item
          key={4}
          className={`list-group-item-action ${
            item?.submitted_answer == 4 ? "bg-light" : ""
          }`}
          aria-current="true"
        >
          <Form.Check
            type="radio"
            name="quiz_option"
            id={`quiz_option_4`}
            label={item.op4}
            key={4}
            value={4}
            onChange={handleAnswerSelection}
            checked={answer == 4}
          />
        </ListGroup.Item>
      </ListGroup>
    </Fragment>
  );
};

export default Question;
