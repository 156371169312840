// import node module libraries
import Typed from 'react-typed';
import { Link } from 'react-router-dom';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';

// import MDI icons
import Icon from '@mdi/react';
import { mdiCheck } from '@mdi/js';

//import images
// import udemy from '../../../../assets/images/brand/logo/Udemy.png';
// import youtube from '../../../../assets/images/brand/logo/Youtube.png';
// import johnacademy from '../../../../assets/images/brand/logo/John academy - transparent.png';
// import edureka from '../../../../assets/images/brand/logo/Edureka - transparent.png';
// import eduonix from '../../../../assets/images/brand/logo/Eduonix - transparent.png';

// Import required Media files
import HeroGraphics from 'assets/images/hero/hero-graphics.svg';

const TopBanner = () => {
	return (
		<div
			className="pb-lg-10 py-4 py-md-10 bg-auto"
			style={{
				background: `url(${HeroGraphics}), linear-gradient(45deg, #fff8f5,#ffc7b4)`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				backgroundPosition: 'top center'
			}}
		>
			<Container>
				<Row className="justify-content-center">
					<Col xl={7} lg={7} md={12}>
						<div className="py-4 py-lg-0 text-center">
							<h1 className="display-4 fw-bold mb-4 text-primary">
								<span className="text-dark px-3 px-md-0">Empower your journey with personalized guidance from the mentors at Internshipgate</span>
								{/* <span className="text-primary ms-2">
									<Typed
										strings={['from Expert Mentors', 'at IG Mentorship']}
										typeSpeed={60}
										backSpeed={50}
										loop
									/>
								</span> */}
							</h1>
							<p className="mb-6 h4 lead ">
								<p className="h5 text-dark">
								Internshipgate offers you the opportunity to empower your journey with personalized guidance from our esteemed mentors.
								</p>
							</p>
							{/* <Link
								to="/marketing/pages/pricing"
								className="btn btn-primary me-2"
							> */}
							<button className='btn btn-primary me-2' style={{ fontSize: "16px", fontWeight: 400 }} onClick={() => window.scrollBy({
								top: 900,
								behavior: "smooth"
							})}>
								Explore Trainings
							</button>
							{/* </Link> */}
							{/* <Link
								to="/authentication/sign-up"
								className="btn btn-outline-primary"
							>
								Try for Free
							</Link> */}

							<div className="mt-8 mb-0">
								<ListGroup as="ul" bsPrefix="list-inline">
									<ListGroup.Item
										as="li"
										bsPrefix="list-inline-item text-dark fw-semi-bold lh-1 fs-4 me-3 mb-2 mb-md-0"
									>
										<span className="icon-shape icon-xs rounded-circle bg-light-success text-center me-2">
											<Icon
												path={mdiCheck}
												size={0.7}
												className="text-success"
											/>
										</span>
										<span className="align-middle">Industry expert Mentors</span>
									</ListGroup.Item>
									<ListGroup.Item
										as="li"
										bsPrefix="list-inline-item text-dark fw-semi-bold lh-1 fs-4 me-3 mb-2 mb-md-0"
									>
										<span className="icon-shape icon-xs rounded-circle bg-light-success text-center me-2">
											<Icon
												path={mdiCheck}
												size={0.7}
												className="text-success"
											/>
										</span>
										<span className="align-middle">Expert instruction</span>
									</ListGroup.Item>
									<ListGroup.Item
										as="li"
										bsPrefix="list-inline-item text-dark fw-semi-bold lh-1 fs-4"
									>
										<span className="icon-shape icon-xs rounded-circle bg-light-success text-center me-2">
											<Icon
												path={mdiCheck}
												size={0.7}
												className="text-success"
											/>
										</span>
										<span className="align-middle">Lifetime access</span>
									</ListGroup.Item>
								</ListGroup>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};
export default TopBanner;
