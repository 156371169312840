// import node module libraries
import { Fragment, useState,useEffect } from "react";
import { Container, Modal, Form, Row, Col, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
// import custom components
import LogosTopHeading from "components/marketing/common/clientlogos/LogosTopHeading";

// import sub components
import Features4Columns from "./Features4Columns";
import BrowseCategories from "./BrowseCategories";
import TopStudyDestinations from "./TopStudyDestinations";
import HeroTyped from "./TopBanner";
import BecomeInstructor from "./BecomeInstructor";
import TestimonialSection from "./TestimonialSection";
import Url from "components/Url";
// import layouts
import NavbarDefault from "layouts/marketing/navbars/NavbarDefault";
import FooterLandings from "layouts/marketing/footers/FooterLandings";

// import required data files
import LogoList2 from "data/marketing/clientlogos/LogoList2";
import { ToastContainer, toast } from 'react-toastify';

const StudyAbroad = () => {
	const [showLeadCapture, setShowLeadCapture] = useState(false);
	const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [mobileno, setMobileno] = useState("")
	const [studyLocations, setStudyLocations] = useState("");

    
	
    const url1 = Url();
	const url = url1["url"];
    
    const [seodetails, setSeodetails] = useState("");
    useEffect(() => {
        /*********** get page SEO Settings */

        var api1 = url + "getPageSeoSettingById/" + 7;
        fetch(api1, {
        method: "GET",
        })
        .then((apidata1) => {
        return apidata1.json();
        })
        .then((actualdata1) => {
        setSeodetails(actualdata1);
        });      
      }, []);    


    const submit = (e) => {
        setLoading(true);
        e.preventDefault()
        // console.log(email)
        // console.log(name)
        // console.log(msg)
       
        var status = 0;
		const api = url + "saveStudyAbroadLead"
        fetch(api, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, mobileno, name, studyLocations }),
        })
            .then((apidata) => {
				status = apidata.status;

                return apidata.json()
            })
            .then((actualdata) => {
				if(status > 201){
					if(actualdata.data = "Validation Error."){
						toast.warning("Please fill all the fields"); 
					}
				}				
                if (status == 201) {
					toast.success("Thank you for Writing to us. We will get back to you shortly..."); 
                    setEmail("");
                    setName("");
                    setMobileno("");
                }
            })
        setLoading(false);
    };

	return (
		<Fragment>
            <Helmet>
                <title>{seodetails.page_title}</title>
                <meta name="keywords" content={seodetails.page_meta_keyword} />
                <meta name="description" content={seodetails.page_meta_description} />
            </Helmet>   
			{/* Page Content */}
			<HeroTyped />

			{/*  World Class Instructors Section  */}
			<TopStudyDestinations showLeadCapture={showLeadCapture} setShowLeadCapture={setShowLeadCapture} loading={loading} setLoading={setLoading} />

			<div className="py-4 py-lg-4 bg-white">

				<Container>
					<hr className="my-5 my-lg-6" />
					{/*  Testimonials start */}
					<TestimonialSection />
				</Container>
			</div> 
            {/*-------------------------- Create Discount coupon Modal-------------------------- */}
			<Modal
				show={showLeadCapture}
				onHide={ () => setShowLeadCapture(false)}
			>
				<Modal.Header closeButton>
				<Modal.Title>Submit your details</Modal.Title>
				</Modal.Header>
                <Modal.Body className="mt-0">

				<Form>
                    <Row className="mt-1">
                        <span className="ml-0 mr-1 mt-1">Full Name</span>
                        <span className="mt-0">
                            <Form.Control placeholder="Name" className="ml-0 "
                                required
								maxLength={100}
								value={name}
                                onChange={(e) => { setName(e.target.value) }}
                            />
                        </span>
                    </Row>

                    <Row className="mt-4">
                        <span className="ml-0 mr-1 mt-1">Mobile No</span>
                        <span className="mt-0">
                            <Form.Control placeholder="Mobile No" className="ml-0 "
                                required
								maxLength={10}
								value={mobileno}
                                onChange={(e) => { setMobileno(e.target.value) }}
                            />
                        </span>
                    </Row>

                    <Row className="mt-4">
                        <span className="ml-0 mr-1 mt-1">Email</span>
                        <span className="mt-0">
                           
                               <Form.Control placeholder="Email" 
                                    required
									maxLength={50}
									value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                    
                        </span>
                    </Row>
                    <Row className="mt-4">
                        <span className="ml-0 mr-1 mt-1">Study Location</span>
                        <span className="mt-0">
                           
						<Form.Select
							name="studyLocations"
							value={studyLocations}
							onChange={(e) => setStudyLocations(e.target.value)}
						>
							<option value="">Select Destination</option>
							<option value="Canada">Canada</option>
							<option value="USA">USA</option>
							<option value="UK">UK</option>
							<option value="Australia">Australia</option>
							<option value="Germany">Germany</option>
							<option value="Singapore">Singapore</option>
							<option value="China">China</option>
							<option value="Russia">Russia</option>
							<option value="Europe - Other">Europe - Other</option>
							<option value="Middle East">Middle East</option>
							<option value="South Asia">South Asia</option>
							<option value="South America">South America</option>
						</Form.Select>
                        </span>
                    </Row>

                    <Row className="mt-4 ml-0 mr-0">
                        <Col>
						<Button className="btn-sm" type="submit" variant="primary" disabled={loading} onClick={(e) => { submit(e) ; }}> {loading ? <Fragment><Spinner size="sm" /> Please wait....</Fragment> : "Submit Query"}</Button>
						<Button className="btn btn-white btn-outline-primary m-1 btn-sm" onClick={() => setShowLeadCapture(false)}>
							Cancel
						</Button>
                        </Col>
                    </Row>
					</Form>
                </Modal.Body>
            </Modal>
            {/*-------------------------- Create Discount coupon Modal end-------------------------- */}			
		</Fragment>
	);
};
export default StudyAbroad;
