import React, { Fragment,useEffect,useState } from "react";
import { Link } from "react-router-dom";
//import Col from "react-bootstrap/Col";
//import Row from "react-bootstrap/Row";
import Select from "react-select";
import {Form, Col, Row,Card, Image,Button,FormSelect} from "react-bootstrap";
import Url from "../../Url";
//import { FormSelect } from 'components/elements/form-select/FormSelect';
import { PencilSquare,XSquare } from "react-bootstrap-icons";

const KeySkills = (props) => {
    const {
        studentSoftwareSkills,
        skillsList,
        viewOnly=false,
        applicantId,
        setLoadData,
        employerView
    } = props;

    const url1 = Url();
    const url = url1["url"];
    const s3URL = url1["s3"];

    const [showSkillsDropdown, setShowSkillsDropdown] = useState(false);
    const [softwareSkills, setSoftwareSkills] = useState([]);
    const [apiMessage, setApiMessage] = useState("");
    const [apiStatus, setApiStatus] = useState(0);

    useEffect(() => {
        if(studentSoftwareSkills.length == 1){
            let softwareSkillsArray = [];
            studentSoftwareSkills[0].software_skill.split(",").map( (skill,index) =>  {
                softwareSkillsArray.push({value:skill,label:skill })
            }
            );
            setSoftwareSkills(softwareSkillsArray);

        }else{
            setSoftwareSkills([])
        }
    },[studentSoftwareSkills, showSkillsDropdown]);

    const saveSoftwareSkills = e => {
        let email = localStorage.getItem("email");
        var software_skill = "";

        if (softwareSkills.length > 0) {
            software_skill = softwareSkills.map((item) => item.value).join(",");
            //software_skill = software_skill + "," + stusftskill.software_skill
            //console.log(software_skill)
        }
        else {
            alert("Skills are mandatory");
            return false;
        }

        const api2 = url + "updateApplicantSoftwareSkill";
        fetch(api2, {
            method: "POST",
            body: JSON.stringify({ email, software_skill, applicantId }),
            headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
            return response.json();
        })
        .then((actualdata) => {
            setApiMessage(actualdata["message"]);
            setApiStatus(actualdata["status"]);
            setLoadData(true);
        });
    }

    return (
        <Row className="mb-2 pt-2">

            <Col md={3}>
                <span className="text-dark">*Key Skills:</span>
            </Col>
            {!showSkillsDropdown && 
            (
                <Fragment>
                    <Col md={7}>

                    {studentSoftwareSkills.length > 0
                        ? studentSoftwareSkills.map((item,index) => (
                            <div key={index}>
                                <ul className="mb-0" >
                                {item.software_skill
                                    .split(",")
                                    .map((sftskill,index1) => (
                                        <li key={index1}><span className="h5">{sftskill}</span></li>
                                    ))}
                                    </ul>
                            </div>
                            ))
                        : null}
                </Col>
                {!employerView && 
                <Col md="2">
                    <span
                        onClick={() => {setShowSkillsDropdown(true);setApiStatus(-1); setApiMessage("");}}  
                        className="float-end mx-2 pe-auto"
                        style={{cursor:"pointer"}}
                        >
                        <PencilSquare />
                    </span>                  

                </Col>
                }
            </Fragment>
            )
            }
            {showSkillsDropdown && 
            <Fragment>
                <Col md={7}>
                    <Select
                        isMulti
                        required
                        name="software_skills"
                        options={skillsList}
                        className=""
                        placeholder="Select more software skill"
                        value={softwareSkills}
                        onChange={(e) => {
                            setSoftwareSkills(e);
                        }}
                    />
                </Col>
                <Col md="2">
                    {/*
                    <span
                        onClick={() => setShowSkillsDropdown(false)}  
                        className="float-end mx-2 pe-auto"
                        style={{cursor:"pointer"}}
                        >
                        <XSquare />
                    </span>
                    */}     
                    <div className="mb-3 d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary btn-sm" onClick={saveSoftwareSkills}>Save</button>

                        <button type="button" className="btn btn-outline-warning btn-sm ms-2" onClick={() => setShowSkillsDropdown(false)}  >Close</button>
                    </div>                    
                </Col> 
            </Fragment>                
            }        
            <Row className="mt-2">
                {apiMessage !== "" && <span className={ (apiStatus ? "text-success " : "text-danger ")}>{apiMessage}</span> }
                {/*apiStatus==1 && <span className="text-success small">Education details updated successfully</span> */}
            </Row>                    
        </Row>
    );
}

export default KeySkills;
