// import node module libraries
import React, {
	Fragment,
	useEffect,
	useState,
	useRef,
  } from "react";
import { Col, Card, Form, Button, Image, Row, Spinner } from 'react-bootstrap';
import Url from 'components/Url';


const BannerImageUpload = () => {
    const [isUploading, setIsUploading] = useState(false);
    const [bannerNumber, setBannerNumber] = useState(0);
    const [status, setStatus] = useState();
    
    const bannerNumbersList = [1,2,3,4];
    const fileInputRef = useRef(null);
    const token = localStorage.getItem('admtoken');

    const handleImage = (event) => {

        if(!bannerNumber) {
          setStatus("Please select banner number");
          // Clear the file input if banner number is not selected
          if (fileInputRef.current) {
              fileInputRef.current.value = '';
          }
          return;
      }
        setStatus("");

        const fileName = "Banner_" + bannerNumber + ".jpg";

        const res = window.confirm("Do you want to upload this image? Existing image will be overwrittern...!!!");
        if (!res) {
          return;
        }        
        
        const img = event.target.files[0];
        const fd = new FormData();
        fd.append("pic", img);
        fd.append("fileName",fileName);

        const api = url + "uploadBannerImage";
        const config = {
          method: "POST",
          body: fd,
          headers: {
            "Authorization": `Bearer ${token}`, // Add Bearer token
        },          
        };
        fetch(api, config)
        .then((res) => {
          return res.json(); // Continue with the JSON response if successful
          })
          .then((data) => {
            if (data.message) {
              setStatus(data.message);
              setIsUploading(false);
              setBannerNumber(0);
              fileInputRef.current.value = '';
              window.location.reload();
            }
          })
          .catch((error) => {
            // Handle any other errors
            console.error('Error:', error);
            setIsUploading(false);
        });
      };

	//const { next, previous, handleImage, handleSubmit, setIsSaved, courseData, isUploading, setIsUploading } = props;
	const url1 = Url();
	const url = url1["url"];
	const s3 = url1["s3"];
	return (
		<Form>
			{/* Card */}
			<Card className="mb-3  border-0">
				<Card.Header className="border-bottom px-4 py-3">
					<h4 className="mb-0">Banner Image Upload</h4>
				</Card.Header>
				{/* Card body */}
				<Card.Body>
					{/* Course cover image */}
					<Form.Label>Banner image</Form.Label>
					<Form.Group className="mb-1 input-group">

					<Row>
          <Col sm={3} className="mt-3">
          <select placeholder='Select Banner'
                  value={bannerNumber}
                  onChange={(e) => setBannerNumber(e.target.value)}
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    padding: '5px',
                  }}
                >
                  <option value="">Select banner Number</option>
                  {bannerNumbersList.map((bannerNumber) => (
                    <option key={bannerNumber} value={bannerNumber}>
                      {bannerNumber}
                    </option>
                  ))}
                </select>
          </Col>
            <Col sm={6}>
						<Form.Control
							id="inputbannerimage"
							type="file"
							className="form-control mt-2"
              ref={fileInputRef}
							onChange={event => {
								setIsUploading(true)
								handleImage(event)
							}}
						/>
						<Form.Text className="text-muted">
							Upload the banner image here. Important guidelines: 850x190
							pixels; Only .jpg is allowed.
						</Form.Text>
            </Col>
            
            <Col sm={12}>
              <h4 className="text-danger">{status}</h4>
            </Col>
					</Row>


						<Row style={{maxWidth: "900px"}}>
              <Col>
              <h4  className="mt-2">Banner 1:</h4 > 
                            <Image
                              src={
                                s3 + "creatives/Banner_1.jpg"
                              }
                            />
            </Col>
            <Col>
              <h4  className="mt-2">Banner 2:</h4 > 
                            <Image
                              src={
                                s3 + "creatives/Banner_2.jpg"
                              }
                            />
            </Col>
            <Col>
              <h4  className="mt-2">Banner 3:</h4 > 
                            <Image
                              src={
                                s3 + "creatives/Banner_3.jpg"
                              }
                            />>
            </Col>
            <Col>
              <h4  className="mt-2">Banner 4:</h4 > 
                            <Image
                              src={
                                s3 + "creatives/Banner_4.jpg"
                              }
                            />
              </Col>
						</Row>	

						</Form.Group>
				</Card.Body>
			</Card>
		</Form>
	);
};
export default BannerImageUpload;
