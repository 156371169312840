// import node module libraries
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Container,Modal } from 'react-bootstrap';

// import popup youtube video
import ModalVideo from 'react-modal-video';

// import media files
import BGThumb from 'assets/images/background/acedamy-img/bg-thumb.svg';
import GirlImage from 'assets/images/background/acedamy-img/girl-image.png';
import Frame1 from 'assets/images/background/acedamy-img/frame-1.svg';
import Frame2 from 'assets/images/background/acedamy-img/frame-2.svg';
import Target from 'assets/images/background/acedamy-img/target.png';
import Sound from 'assets/images/background/acedamy-img/sound.png';
import Trophy from 'assets/images/background/acedamy-img/trophy.png';
import PlayBtn from 'assets/images/svg/play-btn.svg';
import demoCertificate from 'assets/images/job/png/demoCerti.4736f221.png'
import Url from 'components/Url';
const url1 = Url();
const url = url1["url"];
const s3URL = url1["s3"] + "courseImages/";

const HeroAcademy = () => {
  const [isOpen, setOpen] = useState(false);
	const [YouTubeURL] = useState('JRzWRZahOVU');
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const scrollToView = (e) => {
    e.preventDefault();
    var element = document.querySelector("#popularcourses");

    // smooth scroll to element and align it at the bottom
    element.scrollIntoView({ behavior: 'smooth'});
  };

  return (
    <section className="py-lg-8 py-4 bg-white">
        <Container>
          <Row className="align-items-center">
            <Col lg={6} className="mb-6 mb-lg-0">
              <div className="">
                <h5 className="text-dark mb-4"><i
                  className="fe fe-check icon-xxs icon-shape bg-light-success text-success rounded-circle me-2"></i> Most
                  trusted education platform</h5>
                <h2 className="display-6 fw-bold mb-3">Boost your career with our</h2>
                <h1 className="display-4">AI powered Virtual Internship</h1>
                <p className="pe-lg-10 mb-5" style={{fontSize : "16px",fontWeight: "400",fontStretch: "normal",fontStyle: "normal",lineHeight: "1.56",textAlign: "left"}}>Finding it hard to land your first job, or need to get in an internship program for your college graduation. <br />The hassle is over with Virtual Internship</p>
                <Link to="#" onClick={scrollToView} className="btn btn-primary">Join Now</Link>
                {
                <Link to="#" onClick={() => setShow(true)} className="btn btn-outline-secondary mx-2">
                  View Certificate
                </Link>
                 }
                {/* video popup */}
								<ModalVideo
									//channel="youtube"
									//autoplay
									isOpen={isOpen}
									//videoId={YouTubeURL}
									onClose={() => setOpen(false)}
								/>
              </div>
            </Col>
            <Col lg={6} className="d-flex justify-content-center">
              <div className="position-relative">
                <img src={BGThumb} alt="" className="" />
                <img src={GirlImage} alt="" className="position-absolute end-0 bottom-0" />
                <img src={Target} alt="" className="position-absolute top-0 mb-10 ms-n12 ms-lg-n1 " />
                <img src={Frame1} alt="" className="position-absolute mt-20 ms-lg-n10 ms-n21" />
                <img src={Frame2} alt="" className="position-absolute bottom-0 start-0 ms-lg-n14 ms-n6 mb-n7" />
                
                <img src={Sound} alt="" className="position-absolute top-0  start-0 mt-18 ms-lg-n19 ms-n8" />
                <img src={Trophy} alt="" className="position-absolute top-0  start-0 ms-lg-n14 ms-n5" />
              </div>
            </Col>
          </Row>
        </Container>
        <Modal show={show} onHide={handleClose} size="lg" dialogClassName="modal-90w">
            <Modal.Header closeButton>
              <Modal.Title>Demo Internship Certificate</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* {<img src={demoCertificate} alt="Demo Certificate" className="img-fluid me-2" />} */}
              <img src={s3URL + "internshipgate_demo_certificate.png"} alt="Demo Certificate" className="img-fluid me-2" />
            </Modal.Body>
        </Modal>                

      </section>
  )
}

export default HeroAcademy