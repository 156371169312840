// import node module libraries
import { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Dropdown,
  ListGroup,
  DropdownButton,
  Modal,
  Tabs,
  Tab,
  Form,
  Button,
  ModalHeader,
} from "react-bootstrap";

import { PiStudentFill } from "react-icons/pi";
import { FaUserTie, FaUniversity } from "react-icons/fa";

import { ToastContainer, toast } from "react-toastify";
import Url from "components/Url";
import GoogleAuthenticator from "./GoogleAuthenticator";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import media files

const LoginModalNew = (props) => {
  const [enablelogin, setEnablelogin] = useState(false);
  const url1 = Url();
  const url = url1.url;
  const url2 = url1["url1"];
  const [show, setShow] = useState(false);
  const [token, setToken] = useState("");

  const [activeTab, setActiveTab] = useState("student");

  const [showGoogleLogin, setShowGoogleLogin] = useState(false);
  const [showSelectionButtons, setShowSelectionButtons] = useState(true);
  const [showTabNumber, setShowTabNumber] = useState(1);

  if (enablelogin) {
    //if the internshipId field is set then redirect to the internship-details page and handle the automatic application

    if (!(sessionStorage.getItem("redirectURL") === null)) {
      var redirectURL = "/" + sessionStorage.getItem("redirectURL");
      //history.push(redirectURL);
      window.location.href = redirectURL;
      return;
    }

    if (localStorage.getItem("internshipIdApplication")) {
      window.location.href =
        "/internship-details/" +
        localStorage.getItem("internshipIdApplication");
      return;
    }
    window.location.href = "/student-dashboard";
    return 1;
  }

  useEffect(() => {
    //setShowSelectionButtons(true);
  }, []);

  const handleSelectionButton = (event) => {};

  const handleSubmit = (event) => {
    event.preventDefault();

    const email = event.target.email.value;
    const password = event.target.password.value;

    const api = url + "login";
    fetch(api, {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: { "Content-Type": "application/json" },
    })
      .then((apidata) => {
        //console.log(apidata)
        return apidata.json();
      })
      .then((actualdata) => {
        if (
          actualdata.message === "Email not verified, please verify your email"
        ) {
          toast.warning(actualdata.message);
          return;
        }
        console.log(actualdata);
        const success = actualdata["message"];
        const applicant_id = actualdata["applicant_id"];
        const student_id = actualdata["id"];
        const studentToken = actualdata["token"];

        if (success === "logged-in") {
          console.log(actualdata);

          localStorage.setItem("stutoken", studentToken);
          localStorage.setItem("email", email);
          localStorage.setItem("applicantid", applicant_id);
          localStorage.setItem("studentId", student_id);
          localStorage.setItem("studentName", actualdata.fullname);
          localStorage.setItem("token1", actualdata.token1);
          localStorage.setItem("token2", actualdata.token2);
          console.log(actualdata.studentStatus);
          if (actualdata.studentStatus == "1") {
            localStorage.removeItem("profileStatus");
          } else {
            localStorage.setItem("profileStatus", "0");
          }
          setEnablelogin(true);
        } else {
          toast.error("Invalid Email or password");
        }
      });
  };

  const handleCollegeLogin = (event) => {
    event.preventDefault();

    const email = event.target.email.value;
    const password = event.target.password.value;

    const api = url + "checkCollegeLogin"; // Replace with the correct API endpoint for college login
    fetch(api, {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: { "Content-Type": "application/json" },
    })
      .then((apidata) => {
        return apidata.json();
      })
      .then((actualdata) => {
        if (
          actualdata.message === "Email not verified, please verify your email"
        ) {
          toast.warning(actualdata.message);
          return;
        }

        const success = actualdata["message"];
        // const applicant_id = actualdata["applicant_id"];
        const college_id = actualdata["id"];
        const collegeToken = actualdata["token"];

        if (success === "logged-in") {
          // localStorage.setItem("ACHARYA INSTITUTE OF TECHNOLOGY");
          localStorage.setItem("collegetoken", collegeToken);
          localStorage.setItem("email", email);
          localStorage.setItem("collegeId", college_id);
          // localStorage.setItem("applicantid", applicant_id);
          localStorage.setItem("collegeName", actualdata.college_name);
          localStorage.setItem("token1", actualdata.token1);
          localStorage.setItem("token2", actualdata.token2);

          if (actualdata.collegeStatus == "1") {
            localStorage.removeItem("colProfileStatus");
          } else {
            localStorage.setItem("colProfileStatus", "0");
          }
          window.location.href = "/coldashboard";
          //window.location.href = "/";
          return 1;
        } else {
          toast.error("Invalid Email or password");
        }
      });
  };

  const handleEmployerSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    const controller = "employerlogin";
    const api = url + controller;
    fetch(api, {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: { "Content-Type": "application/json" },
    })
      .then((apidata) => {
        return apidata.json();
      })
      .then((actualdata) => {
        if (
          actualdata.message === "Email not verified, please verify your email"
        ) {
          toast.warning(actualdata.message);
          return;
        }
        if (actualdata["message"] === "logged-in") {
          //localStorage.setItem("emptoken", "33vsefkjevkfk6546kkrguk" + Math.random() * 1000 + "546vfjvfefejyewrhfnb2164hhdfh");
          localStorage.setItem("emptoken", actualdata["token"]);
          localStorage.setItem("email", email);
          localStorage.setItem("id", actualdata.id);
          localStorage.setItem("loginName", actualdata.fullname);
          localStorage.setItem("token1", actualdata.token1);
          localStorage.setItem("token2", actualdata.token2);

          if (actualdata.employerStatus == "1") {
            localStorage.removeItem("empProfileStatus");
          } else {
            localStorage.setItem("empProfileStatus", "0");
          }
          //window.location.href="http://localhost:8001/Employer/" + actualdata.token2 +"/" + actualdata.token1 +"/" + actualdata["token"];
          window.location.href = "/empdashboard";
          //window.location.href = "/";
          return 1;
        } else {
          toast.error("Invalid Email or password");
        }
      });
  };

  const handleForgotPass = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const api =
      url + `${token === "E" ? "employerFpass" : "studentFpass"}` + "/" + url2;
    fetch(api, {
      method: "POST",
      body: JSON.stringify({ email }),
      headers: { "Content-Type": "application/json" },
    })
      .then((apidata) => {
        return apidata.json();
      })
      .then((actualdata) => {
        if (actualdata["message"] === "Mail Sent") {
          //alert("Password sent to your registered Mail-id")
          toast.success("Password sent to your registered Mail-id", {
            position: toast.POSITION.TOP_CENTER,
          });
          setShow(false);
        } else {
          //alert("Invalid Mail-id")
          toast.error("Invalid Mail-id", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
        }
      });
  };

  return (
    <>
      <ToastContainer />

      {/***********************  LOGIN MODAL START *********************************/}
      <Modal {...props} centered>
        <Modal.Header
          closeButton
          style={{ border: "0", padding: "15px 25px 0 25px" }}
        >
          {!showSelectionButtons && 
            <Button variant="link" onClick={ () => { setShowSelectionButtons(true); }}>Back</Button>
          }

        </Modal.Header>
        <Modal.Body>
        {showSelectionButtons ? (
          <Fragment>
            <Row className="justify-content-center">
              <Col xs={4}>
                <Button
                  variant="primary"
                  onClick={() => {
                    setActiveTab("student");
                    setShowSelectionButtons(false);
                  }}
                >
                  <span><PiStudentFill className="m-1" size={20} /> Student</span>
                </Button>
              </Col>
              <Col  xs={4}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setActiveTab("employer");
                    setShowSelectionButtons(false);
                  }}
                >
                  <span><FaUserTie  className="m-1" size={18} /> Employer</span>
                </Button>
              </Col>

              <Col  xs={4}>
                <Button
                  variant="outline-warning"
                  onClick={() => {
                    setActiveTab("college");
                    setShowSelectionButtons(false);
                  }}
                >
                  <span><FaUniversity  className="m-1" size={18} /> College </span>
                </Button>
              </Col>
            
            </Row>
          </Fragment>
        ) : (
          <Fragment>
            <div className={activeTab == "student" ? "p-4" : "d-none"}>
              <h4>Student Login</h4>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-2" controlId="student-email">
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Student Email Id"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-2" controlId="student-password">
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Password"
                    required
                  />
                </Form.Group>
                <Row className="border-bottom  mb-2">
                  <div className="col-12 d-flex mb-4">
                    <div className="col-2 justify-content-start">
                      <Button variant="primary" type="submit">
                        Login
                      </Button>
                      {/* <Button variant="outline-primary" className="ms-1" type="button">Login with OTP</Button> */}
                    </div>
                    <div className="col-10 justify-content-end">
                      <Link
                        to="#"
                        onClick={() => {
                          setToken("S");
                          setShow(true);
                          props.setModalShow(false);
                        }}
                        className="btn btn-link d-flex justify-content-end"
                      >
                        Forgot password?
                      </Link>
                    </div>
                  </div>
                </Row>

                <div className="text-center mb-4">
                  <Col className="h5 text-center">Or</Col>
                  <Col xs={{ span: 8, offset: 1 }} sm={{ span: 6, offset: 3 }}>
                    <GoogleOAuthProvider clientId="295493707204-t8uvccmg3mf93tvi75eh1s26kuso18je.apps.googleusercontent.com">
                      <GoogleAuthenticator />
                    </GoogleOAuthProvider>
                  </Col>
                </div>
                <div className="text-center">
                  <p className=" mb-1">New to InternshipGate? Register </p>
                  <span>
                    <Link to="/student/registration">Student </Link>
                  </span>
                </div>
              </Form>
            </div>

            <div className={activeTab == "employer" ? "p-4" : "d-none"}>
              <h4>Employer Login</h4>
              <Form onSubmit={handleEmployerSubmit}>
                <Form.Group className="mb-2" controlId="employer-email">
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Official Email Id"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-2" controlId="employer-password">
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Password"
                    required
                  />
                </Form.Group>
                <div className="d-flex justify-content-end">
                  <Link
                    to="#"
                    onClick={() => {
                      setToken("E");
                      setShow(true);
                      props.setModalShow(false);
                    }}
                    className="btn btn-link"
                  >
                    Forgot password?
                  </Link>
                </div>
                <div className="mb-4">
                  <Button variant="primary" type="submit">
                    Login
                  </Button>
                  {/* <Button variant="outline-primary" className="ms-1" type="button">Login with OTP</Button> */}
                </div>
                <div className="text-center">
                  <p className=" mb-1">New to InternshipGate? Register </p>
                  <span>
                    <Link to="/employer/registration">Company</Link>
                  </span>
                </div>
              </Form>
            </div>

            <div className={activeTab == "college" ? "p-4" : "d-none"}>
              <Row>
                <h4>College Login</h4>
              </Row>
              <Form onSubmit={handleCollegeLogin}>
                <Form.Group className="mb-2" controlId="College-email">
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Official Email Id"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-2" controlId="College-password">
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Password"
                    required
                  />
                </Form.Group>
                <div className="d-flex justify-content-end">
                  <Link
                    to="#"
                    onClick={() => {
                      setToken("C");
                      setShow(true);
                      props.setModalShow(false);
                    }}
                    className="btn btn-link"
                  >
                    Forgot password?
                  </Link>
                </div>
                <div className="mb-4">
                  <Button variant="primary" type="submit">
                    Login
                  </Button>
                  {/* <Button variant="outline-primary" className="ms-1" type="button">Login with OTP</Button> */}
                </div>
                <div className="text-center">
                  <p className=" mb-1">New to InternshipGate? Register </p>
                  <span>
                    <Link to="/college/registration">College</Link>
                  </span>
                </div>
              </Form>
            </div>
          </Fragment>
        )}
        </Modal.Body>
      </Modal>
      {/***********************  LOGIN MODAL END *********************************/}
      
      {/***********************  FORGOT PASSWORD MODAL START *********************************/}
      <Modal centered show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleForgotPass}>
          <Form.Group className="mb-2 mt-2 px-3" controlId="employer-email">
            <Form.Control
              type="email"
              name="email"
              placeholder={
                token === "E" ? "Official Email Id" : "Student Email Id"
              }
              required
            />
          </Form.Group>
          <div className="mb-4 px-3">
            <Button variant="primary" type="submit">
              Send Password
            </Button>
            {/* <Button variant="outline-primary" className="ms-1" type="button">Login with OTP</Button> */}
          </div>
        </Form>
      </Modal>
      {/***********************  FORGOT PASSWORD MODAL END *********************************/}

    </>
  );
};

export default LoginModalNew;
