import React, { useState, useEffect, Fragment } from "react";
import { Col, Row, Button, Modal, Form, FormSelect } from "react-bootstrap";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import Url from "components/Url";
import axios from 'axios';
import Select from 'react-select/creatable';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isNull } from "util";

const Education = (props) => {
  const { studentEducation, setLoadData, employerView } = props;

  const [educationDetail, setEducationDetail] = useState({});
  const [showEducationModal, setShowEducationModal] = useState(false);

  const url1 = Url();
  const url = url1["url"];
  const s3URL = url1["s3"];

  function deleteEducation(id) {
    if (
      id &&
      window.confirm("Are you sure? Want to delete Education detail?")
    ) {
      const api = url + "deleteApplicantEducationById/" + id;
      const config = {
        method: "DELETE",
      };
      fetch(api, config)
        .then((res) => res.json())
        .then((data) => {
          if (data.message === "Record deleted successfully") {
            alert("Education Details Deleted Successfuly");
            setLoadData(true);
          }
        });
    }
  }

  function editEducation(id) {
    //console.log("editEducation" + id);
    const api1 = url + "getApplicantEducationById/" + id;
    fetch(api1, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        setEducationDetail(actualdata1);
      });
    setShowEducationModal(true);
  }

  const addEducation = () => {
    setEducationDetail([]);
    setShowEducationModal(true);
  };

  return (
    <Row className="mb-2 border-bottom">
      <Col md={3}>
        <span className="text-dark">*Education:</span>
      </Col>
      <Col md={9}>
        {studentEducation.length > 0 ? (
          studentEducation.map((item, index) => (
            <Col className="my-2 py-2 col " key={index}>
              <div className="h5">
                {item.edu_type === "hsecondary"
                  ? "Senior Secondary (XII)"
                  : item.edu_type === "secondary"
                  ? "Secondary (X)"
                  : null}
                {item.degree !== "NA" && item.degree !== "hsecondary"
                  ? item.degree
                  : null}
                {item.edu_type !== "secondary" ? "-" : ""}
                {/* -{" "} */}
                {item.specialization !== "NA" ? item.specialization : null}
                {/*Bachelor of Computer Applications (BCA), Computer Application*/}
                {!employerView && (
                  <Fragment>
                    <span
                      onClick={() => deleteEducation(item.id)}
                      className="float-end"
                      style={{ cursor: "pointer" }}
                    >
                      <Trash />
                    </span>
                    <span
                      onClick={() => editEducation(item.id)}
                      className="float-end mx-2 pe-auto"
                      style={{ cursor: "pointer" }}
                    >
                      <PencilSquare />
                    </span>
                  </Fragment>
                )}
              </div>
              <div className="mainHeadings">
                {item.institute_name !== "NA" ? item.institute_name : null}
              </div>
              <div className="mainHeadings">
                {item.edu_type === "hsecondary" ||
                item.edu_type === "secondary" ? (
                  <>
                    {item.edu_status === "completed"
                      ? "Year of completion " + item.end_date
                      : "Expected Year of completion " + item.end_date}
                  </>
                ) : (
                  <>
                    From {item.start_date === "NA" ? null : item.start_date} to{" "}
                    {item.end_date === null ? "Present" : item.end_date}
                  </>
                )}
              </div>
              {item.end_date !== null && (
                <div className="mainHeadings">
                  {item.performance_scale !== "NA"
                    ? item.performance_scale
                    : null}
                  - {item.cgpa !== "NA" ? item.cgpa : null}
                </div>
              )}
            </Col>
          ))
        ) : (
          <div className="mt-2 text-danger">
            Education Details are mandatory...!!
          </div>
        )}

        {!employerView && (
          <Row>
            <Col>
              <div className="mb-3 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={addEducation}
                >
                  Add Education{" "}
                </button>
              </div>
            </Col>
          </Row>
        )}
      </Col>

      {showEducationModal ? (
        <EducationPopup
          setShowEducationModal={setShowEducationModal}
          showEducationModal={showEducationModal}
          setLoadData={setLoadData}
          educationDetail={educationDetail}
        />
      ) : null}
    </Row>
  );
};

/**********************************************************************************************/
/************************* EducationPopup Modal Component *************************************/
/**********************************************************************************************/

const EducationPopup = (props) => {
  const {
    setShowEducationModal,
    showEducationModal,
    setLoadData,
    educationDetail,
  } = props;

  const educationTypeList = [
    { value: "pgraduation", label: "Post Graduation" },
    { value: "graduation", label: "Graduation" },
    { value: "hsecondary", label: "Senior Secondary" },
    { value: "secondary", label: "Secondary" },
  ];

  const educationStatusList = [
    { value: "pursuing", label: "Pursuing" },
    { value: "completed", label: "Completed" },
  ];

  const url1 = Url();
  const url = url1["url"];

  //const educationDetail = [];

  const [isEdit, setIsEdit] = useState(false);
  const [createValidate, setCreateValidate] = useState(false);

  const [show, setShow] = useState(props.show);

  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [edu_type, setEdu_Type] = useState("graduation");
  const [edu_status, setEdu_Status] = useState("pursuing");
  const [edu_board, setEdu_Board] = useState("");
  const [institute_name, setInstitute_Name] = useState("");
  const [degree, setDegree] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [start_date, setStart_Date] = useState("");
  const [end_date, setEnd_Date] = useState("");
  const [performance_scale, setPerformance_Scale] = useState("CGPA");
  const [cgpa, setCgpa] = useState("");
  const [completion_year, setCompletion_year] = useState("");

  const [degreeList, setDegreeList] = useState("");
  const [specializationList, setSpecializationList] = useState("");
  const [educationTypeDesc, setEducationTypeDesc] = useState("");
  const [apiMessage, setApiMessage] = useState("");
  const [apiStatus, setApiStatus] = useState(0);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  let currentYear = new Date().getFullYear();

  let startYearArray = [];
  let endYearArray = [];
  for (var i = 0; i < 30; i++) {
    startYearArray.push(currentYear - i);
  }

  for (var i = 0; i < 34; i++) {
    endYearArray.push(currentYear + 4 - i);
  }

  const [startYearList, setStartYearList] = useState(startYearArray);
  const [endYearList, setEndYearList] = useState(endYearArray);

  const [collegeList, setCollegeList] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedCollege, setSelectedCollege] = useState();
  
  useEffect(() => {    

    searchCollegeList();
    
    if (educationDetail.id) {
      setIsEdit(true);

      setId(educationDetail.id);
      setEmail(
        educationDetail.email
          ? educationDetail.email
          : localStorage.getItem("email")
      );
      setEdu_Type(educationDetail.edu_type);
      setEdu_Status(educationDetail.edu_status);
      setEdu_Board(
        educationDetail.edu_type == "pgraduation" ||
          educationDetail.edu_type == "graduation"
          ? ""
          : educationDetail.edu_board
      );
      setInstitute_Name(educationDetail.institute_name);
      setDegree(
        educationDetail.edu_type == "pgraduation" ||
          educationDetail.edu_type == "graduation"
          ? educationDetail.degree
          : ""
      );
      setSpecialization(
        educationDetail.edu_type == "secondary"
          ? ""
          : educationDetail.specialization
      );
      setStart_Date(educationDetail.start_date);
      setEnd_Date(educationDetail.end_date);
      setPerformance_Scale(educationDetail.performance_scale);
      setCgpa(educationDetail.cgpa);

      populateDegreeList(educationDetail.edu_type);
      if (educationDetail.degree) {
        populateSpecializationList(educationDetail.degree);
      }

      if (edu_type == "hsecondary") {
        populateSpecializationList("hsecondary");
      }
      if (typeof edu_type !== "undefined" && edu_type) {
        let educationTypeRecord = educationTypeList.find(
          (item) => item.value === educationDetail.edu_type
        );
        if (educationTypeRecord) {
          setEducationTypeDesc(educationTypeRecord.label);
        }
      }

      setSelectedCollege({value:educationDetail.institute_name, label:educationDetail.institute_name})
    } else {
      console.log("in else..");
      setEdu_Type("graduation");
      populateDegreeList("graduation");
    }
  }, [educationDetail]);

  const handleEducationTypeChange = (e) => {
    e.persist();
    console.log(e.target.value);
    setEdu_Type(e.target.value);

    if (e.target.value == "hsecondary") {
      populateSpecializationList("hsecondary");
      setDegreeList([]);
    } else {
      populateDegreeList(e.target.value);
    }
  };

  const handleDegreeChange = (e) => {
    e.persist();
    setDegree(e.target.value);
    populateSpecializationList(e.target.value);
  };

  const handleEducationStatusChange = (e) => {
    e.persist();
    setEdu_Status(e.target.value);
  };

  const handleClose = () => {
    console.log("handle close");
    setShowEducationModal(false);
    setLoadData(true);
  };

  function populateDegreeList(educationTypeVal) {
    //get Degree list
    //e.preventDefault();

    const educationType = educationTypeVal == "graduation" ? "G" : "P";

    const locapi = url + "getDegreeList/" + educationType;
    fetch(locapi, {
      method: "GET",
    })
      .then((locdata) => {
        return locdata.json();
      })
      .then((locdata1) => {
        setDegreeList(locdata1);
      });
  }

  function populateSpecializationList(degreeVal) {
    //get Degree list
    //e.preventDefault();
    //const degree = e.target.value;
    setDegree(degreeVal);
    const locapi = url + "getSpecializationList/" + degreeVal;
    fetch(locapi, {
      method: "GET",
    })
      .then((locdata) => {
        return locdata.json();
      })
      .then((locdata1) => {
        setSpecializationList(locdata1);
      });
  }

  const saveEducationDetails = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setCreateValidate(true);
      return;
    }
    const applicantId = localStorage.getItem("applicantid");
    const email = localStorage.getItem("email");

    const creapi = url + "updateApplicantEducation";
    fetch(creapi, {
      method: "POST",
      body: JSON.stringify({
        id,
        applicantId,
        email,
        edu_type,
        edu_status,
        edu_board,
        institute_name,
        degree,
        specialization,
        start_date,
        end_date,
        completion_year,
        performance_scale,
        cgpa,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((apidata) => {
        return apidata.json();
      })
      .then((actualdata) => {
        setApiMessage(actualdata["message"]);
        setApiStatus(actualdata["status"]);

        if (!id && actualdata["status"] == 1) {
          setButtonDisabled(true);
        }
      });
    return;
  };

/************ COLLEGE DROPDOWN DYNAMIC SEARCH - START ************************* */

let cancel;

const searchCollegeList = async (query) => {
  if (cancel) {
    // Cancel the previous request before making a new request
    cancel.cancel();
  }
  // Create a new CancelToken
  cancel = axios.CancelToken.source();
  const resources = {};
  try {
    if (resources[query]) {
      // Return result if it exists
      setCollegeList(resources[query]);
      return ;
    }
    const apiURL = url + "getCollegeListByName" + (query ? "/" + query : "");
    const res = await axios.get(apiURL, { cancelToken: cancel.token });

    const result = res.data.collegeList;
    // Store response
    resources[query] = result;

    setCollegeList(result);
  } catch (error) {
    if (axios.isCancel(error)) {
      // Handle if request was cancelled
      console.log("Request canceled", error.message);
    } else {
      // Handle usual errors
      console.log("Something went wrong: ", error.message);
    }
  }
};

useEffect(() => {
  if (inputValue) {
    searchCollegeList(inputValue);
  } 
}, [inputValue]);

  // Handles changes in selection
  const handleCollegeSelectionChange = (selectedOption) => {
    setSelectedCollege(selectedOption); // Update the selected option state

  // Check if selectedOption is not null before trying to access its value
  if (selectedOption) {
    setInstitute_Name(selectedOption.value);
  } else {
    // If selectedOption is null (selection was cleared), set institute name to null
    setInstitute_Name(null);
  }
  };

const createOption = (label) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ''),
});  
const handleCreate = async (inputValue) => {
  console.log("creating new optiopn " + inputValue);
  const apiURL = url + "addNewCollege";
  const res = await axios.post(apiURL,{collegeName: inputValue});
  console.log(res);
  const result = res.data.collegeList;
  setInstitute_Name(inputValue);
  setSelectedCollege(result);
  setCollegeList(result);  
  // setIsLoading(true);
  // setTimeout(() => {
  //   const newOption = createOption(inputValue);
  //   setIsLoading(false);
  //   setCollegeList((prev) => [...prev, newOption]);
  //   setValue(newOption);
  // }, 1000);
}

/************ COLLEGE DROPDOWN DYNAMIC SEARCH - END ************************* */

  return (
    
    <Modal show={showEducationModal} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          {isEdit ? "Edit Education Details" : "Add Education Details"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={saveEducationDetails}>
          <Row className="mb-2">
            <div className="col-4 text-end ">
              <span className="h5 m-1">Type</span>
            </div>

            <div className="col-8">
              {!isEdit
                ? educationTypeList.map((item, index) => (
                    <Col key={index}>
                      <Form.Check
                        type="radio"
                        name="edu_Type"
                        id={`edu_Type_${index}`}
                        className="m-1"
                        label={item.label}
                        key={index}
                        value={item.value}
                        //onChange={(e) => setEdu_Type(e.target.value ? e.target.value : "")}
                        onChange={handleEducationTypeChange}
                        checked={
                          (edu_type ? edu_type : "graduation") === item.value
                        }
                        required
                      />
                    </Col>
                  ))
                : educationTypeDesc}
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-4 text-end ">
              <h5 className="m-1">Status</h5>
            </div>

            <div className="col-8 d-md-flex">
              <Row>
                {educationStatusList.map((item, index) => (
                  <Col md={6} key={index}>
                    <Form.Check
                      type="radio"
                      name="edu_status"
                      id={`edu_status_${item.value}`}
                      className="m-1"
                      label={item.label}
                      key={index}
                      value={item.value}
                      //onChange={(e) => setEdu_Status(e.target.value ? e.target.value : "")}
                      onChange={handleEducationStatusChange}
                      checked={
                        item.value === (edu_status ? edu_status : "pursuing")
                      }
                      required
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-4 text-end ">
              <h5 className="m-1">
                {edu_type == "secondary" || edu_type == "hsecondary"
                  ? "School Name"
                  : "College Name"}
              </h5>
            </div>
            <div className="col-8">
              <Form.Group className="mb-3" controlId="institute_name">
                {edu_type == "secondary" || edu_type == "hsecondary" ?
                (
                <Form.Control
                  type="text"
                  placeholder={
                    edu_type == "secondary" || edu_type == "hsecondary"
                      ? "e.g. Delhi Public School"
                      : edu_type == "graduation"
                      ? "e.g. Hindu College"
                      : "e.g. Indian Institute Of Management"
                  }
                  id="institute_name"
                  name="institute_name"
                  value={institute_name}
                  onChange={(e) =>
                    setInstitute_Name(e.target.value ? e.target.value : "")
                  }
                  required
                  isValid={createValidate && institute_name}
                  isInvalid={createValidate && (!institute_name)}
                /> 
                ) :
                (
                <Select
                    name="institute_name"
                    isClearable
                    isSearchable
                    inputValue={inputValue}
                    onInputChange={(value) => setInputValue(value)}
                    // onChange={(e) =>
                    //   setInstitute_Name(e.target.value ? e.target.value : "")
                    // }
                    onChange={handleCollegeSelectionChange}
                    options={collegeList}
                    value={selectedCollege}
                    onCreateOption={handleCreate}
                    isValid={createValidate && institute_name}
                    isInvalid={createValidate && (!institute_name)}                      
                  />
                  )
                }
                <Form.Control.Feedback type="invalid">
                  Please enter Institute Name
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Row>
          {(edu_type == "secondary" || edu_type == "hsecondary") && (
            <Row className="mb-2">
              <div className="col-4 text-end ">
                <h5 className="m-1">Board</h5>
              </div>
              <div className="col-8">
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="Board Name"
                    id="edu_board"
                    name="edu_board"
                    value={edu_board}
                    onChange={(e) =>
                      setEdu_Board(e.target.value ? e.target.value : "")
                    }
                    required
                    isValid={createValidate && edu_board}
                    isInvalid={createValidate && !edu_board}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter Board Name
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Row>
          )}
          {(edu_type == "pgraduation" || edu_type == "graduation") && (
            <Row className="mb-2">
              <div className="col-4 text-end ">
                <h5 className="m-1">Degree</h5>
              </div>
              <div className="col-8">
                <Form.Select
                  id="degree"
                  name="degree"
                  placeholder="Select Degree"
                  value={degree}
                  //onChange={(e) => setDegree(e.target.value ? e.target.value : "")}
                  onChange={handleDegreeChange}
                  required
                  isValid={createValidate && degree}
                  isInvalid={createValidate && !degree}
                >
                  <option value="">Select Degree</option>
                  {degreeList.length > 0 &&
                    degreeList.map((item, index) => (
                      <option value={item.value} key={index}>
                        {item.label}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select Degree
                </Form.Control.Feedback>
              </div>
            </Row>
          )}
          {edu_type !== "secondary" && (
            <Row className="mb-2">
              <div className="col-4 text-end ">
                <h5 className="m-1">Stream / Specialization</h5>
              </div>
              <div className="col-8">
                <Form.Select
                  id="specialization"
                  name="specialization"
                  placeholder="Select Specialization"
                  value={specialization}
                  onChange={(e) =>
                    setSpecialization(e.target.value ? e.target.value : "")
                  }
                  required
                  isValid={createValidate && specialization}
                  isInvalid={createValidate && !specialization}
                >
                  <option value="">Select Specialization</option>
                  {specializationList.length > 0 &&
                    specializationList.map((item, index) => (
                      <option value={item.value} key={index}>
                        {item.label}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select Specialization
                </Form.Control.Feedback>
              </div>
            </Row>
          )}
          {(edu_type == "graduation" || edu_type == "pgraduation") && (
            <Row className="mb-2">
              <div className="col-4 text-end ">
                <h5 className="m-1">Start Year</h5>
              </div>
              <div className="col-8">
                <Form.Select
                  id="start_date"
                  name="start_date"
                  placeholder="Select Start Date"
                  value={start_date}
                  onChange={(e) => setStart_Date(e.target.value)}
                  required
                  isValid={createValidate && start_date}
                  isInvalid={createValidate && !start_date}
                >
                  <option value="">Select Start Year</option>
                  {startYearList.length > 0 &&
                    startYearList.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select Start Year
                </Form.Control.Feedback>
              </div>
            </Row>
          )}
          {(edu_status == "completed" ||
            edu_type == "secondary" ||
            edu_type == "hsecondary") && (
            <Row className="mb-2">
              {console.log(edu_status)}
              <div className="col-4 text-end ">
                <h5 className="m-1">
                  {edu_status === "pursuing" ? "Expected End Year" : "End Year"}
                </h5>
              </div>
              <div className="col-8">
                <Form.Select
                  id="end_date"
                  name="end_date"
                  placeholder="Select End Year"
                  value={end_date}
                  onChange={(e) => setEnd_Date(e.target.value)}
                  required
                  isValid={createValidate && end_date}
                  isInvalid={createValidate && !end_date}
                >
                  <option value="">Select End Year</option>
                  {endYearList.length > 0 &&
                    endYearList.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select End Year
                </Form.Control.Feedback>
              </div>
            </Row>
          )}
          <Row className="mb-2">
            <div className="col-4 text-end ">
              <h5 className="m-1">Performance Chart / Scale</h5>
            </div>
            <div className="col-8">
              <Form.Select
                id="performance_scale"
                name="performance_scale"
                placeholder="PERFORMANCE CHART/ SCALE"
                value={performance_scale}
                onChange={(e) => setPerformance_Scale(e.target.value)}
                required
                isValid={createValidate && performance_scale}
                isInvalid={createValidate && !performance_scale}
              >
                <option value="">Select Scale</option>
                <option value="Percentage">Percentage</option>
                <option value="CGPA">CGPA</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please select Performance Chart / Scale
              </Form.Control.Feedback>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-4 text-end ">
              <h5 className="m-1">{performance_scale}</h5>
            </div>
            <div className="col-8">
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  placeholder={"Enter " + performance_scale}
                  id="cgpa"
                  name="cgpa"
                  value={cgpa}
                  onChange={(e) =>
                    setCgpa(e.target.value ? e.target.value : "")
                  }
                  required
                  isValid={createValidate && cgpa}
                  isInvalid={createValidate && !cgpa}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter {performance_scale}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Row>
          <Row className="mb-2 d-flex d-inline">
            <div className="mb-3 d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary btn-sm"
                disabled={buttonDisabled}
              >
                Save
              </button>

              <button
                type="button"
                className="btn btn-outline-warning btn-sm ms-2"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </Row>
          <Row>
            {apiMessage !== "" && (
              <span className={apiStatus ? "text-success " : "text-danger "}>
                {apiMessage}
              </span>
            )}
            {/*apiStatus==1 && <span className="text-success small">Education details updated successfully</span> */}
          </Row>{" "}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Education;
