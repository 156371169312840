// import node module libraries
import React, { useState, Fragment } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

// import custom components
import GKStepper from "components/elements/stepper/GKStepper";

// import sub components ( Steps )
import BasicInformation from "./steps/BasicInformation";
import CoursesMedia from "./steps/CoursesMedia";
import Curriculum from "./steps/Curriculum";
import Settings from "./steps/Settings";
import { useEffect } from "react";
import Url from "components/Url";

const AddNewCourse = (props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const courseid = props.match.params.id;
  const history = useHistory();
  const [courseData, setCourseData] = useState();
  const [load, setLoad] = useState(false);
  const [image, setImage] = useState("");
  const [duration, setDuration] = useState();
  const urls = Url();
  const url = urls["url"];
  const [title, setTitle] = useState("");
  const [level, setLevel] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [faqs, setFaqs] = useState("");
  const [heading, setHeading] = useState("");
  const [price, setPrice] = useState();
  const [discountPercent, setDiscountPercent] = useState();
  const [discountStart, setDiscountStart] = useState();
  const [discountEnd, setDiscountEnd] = useState();
  const [courseFeatures, setCourseFeatures] = useState();
  const [previewText, setPreviewText] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [rating, setRating] = useState();
  const [lmsID, setLmsID] = useState("");
  const [enrollNumbers, setEnrollNumbers] = useState();
  const [seoTitle,setSeoTitle] = useState();
  const [metaDescription,setMetaDescription] = useState();
  const [metaKeywords,setMetaKeywords] = useState();

  const [isSaved, setIsSaved] = useState(false);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const next = () => {
    if (!isSaved) {
      alert("Please save the changes.");
      return;
    }
    setCurrentStep(currentStep === 4 ? 1 : currentStep + 1);
    setIsSaved(false);
  };
  const previous = () => {
    if (!isSaved) {
      alert("Please save the changes.");
      return;
    }
    setCurrentStep(currentStep === 1 ? 1 : currentStep - 1);
  };

  const handleImage = (event) => {
    const img = event.target.files[0];
    const fd = new FormData();
    fd.append("pic", img);
    const api = url + "uploadVICourseImage";
    const config = {
      method: "POST",
      body: fd,
    };
    fetch(api, config)
      .then((res) => res.json())
      .then((data) => {
        if (data.message) {
          setImage(data.message);
          setIsUploading(false);
        }
      });
  };

  const handleSubmit = () => {
    if (!title || !level || !duration || !heading || !previewText) {
      alert("Please fill required fields.");
      return;
    }
    let levelNum;
    if (level === "Beignner") {
      levelNum = 1;
    } else if (level === "Intermediate") {
      levelNum = 2;
    } else if (level === "Advance") {
      levelNum = 3;
    }
    const api = url + "updateVICourse";
    const config = {
      method: "POST",
      body: JSON.stringify({
        id: courseid === "null" ? null : courseid,
        preview_image: image,
        course_duration: duration,
        enrolled_numbers: enrollNumbers,
        subject_name: title,
        subject_heading: heading,
        subject_description: description,
        faqs: faqs,
        generated_url: courseData?.generated_url,
        lms_course_id: lmsID,
        preview_text: previewText,
        course_competency_level: level,
        product_price: price,
        courseFeatures: courseFeatures,
        star_rating: rating,
        seo_title: seoTitle,
        meta_description: metaDescription,
        meta_keywords: metaKeywords
      }),
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(api, config)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        if (result.message === "success") {
          alert("Information updated successfully.");
          setIsSaved(true);
          history.push(
            "/marketing/instructor/add-new-course/" + result.courseId
          );
          setLoad(true);
        }
      });
  };

  const steps = [
    {
      id: 1,
      title: "Basic Information",
      content: (
        <BasicInformation
          title={title}
          setTitle={setTitle}
          level={level}
          setLevel={setLevel}
          duration={duration}
          setDuration={setDuration}
          description={description}
          setDescription={setDescription}
          tags={tags}
          setTags={setTags}
          faqs={faqs}
          setFaqs={setFaqs}
          heading={heading}
          setHeading={setHeading}
          previewText={previewText}
          setPreviewText={setPreviewText}
          handleSubmit={handleSubmit}
          courseData={courseData ? courseData.courseData[0] : null}
          categoryList={courseData ? courseData.categoryList : null}
          isSaved={isSaved}
          next={next}
          courseDuration={courseData ? courseData.planList : null}
        />
      ),
    },
    {
      id: 2,
      title: "Courses Media",
      content: (
        <CoursesMedia
          handleImage={handleImage}
          next={next}
          previous={previous}
          courseData={courseData ? courseData.courseData[0] : null}
          handleSubmit={handleSubmit}
          setIsUploading={setIsUploading}
          isUploading={isUploading}
          setIsSaved={setIsSaved}
        />
      ),
    },
    {
      id: 3,
      title: "Curriculum",
      content: (
        <Curriculum
          next={next}
          previous={previous}
          courseid={courseid}
          setIsSaved={setIsSaved}
          isSaved={isSaved}
        />
      ),
    },
    {
      id: 4,
      title: "Settings",
      content: (
        <Settings
          handleChange={handleChange}
          next={next}
          previous={previous}
          courseid={courseid}
          setPrice={setPrice}
          price={price}
          discountPercent={discountPercent}
          setDiscountPercent={setDiscountPercent}
          setDiscountStart={setDiscountStart}
          discountStart={discountStart}
          setDiscountEnd={setDiscountEnd}
          discountEnd={discountEnd}
          courseData={courseData?.courseData}
          handleSubmit={handleSubmit}
          setCourseFeatures={setCourseFeatures}
          featuresListData={courseData?.courseFeatureList}
          selectedFeatures={courseData?.courseFeatures}
          setRating={setRating}
          rating={rating}
          lmsID={lmsID}
          setLmsID={setLmsID}
          enrollNumbers={enrollNumbers}
          setEnrollNumbers={setEnrollNumbers}
          isSaved={isSaved}
          setIsSaved={setIsSaved}
          setSeoTitle={setSeoTitle}
          setMetaDescription={setMetaDescription}
          setMetaKeywords={setMetaKeywords}
          seoTitle={seoTitle}
          metaDescription={metaDescription}
          metaKeywords={metaKeywords}
        />
      ),
    },
  ];

  useEffect(() => {
    const api = url + "getVICourseDetails/" + courseid;
    const config = {
      method: "GET",
    };
    fetch(api, config)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setCourseData(data);
        }

        setLoad(false);
      });
  }, [courseid, load, courseFeatures]);

  return (
    <Fragment>
      <div className="py-4 py-lg-6 bg-primary">
        <Container>
          <Row>
            <Col lg={{ span: 10, offset: 1 }} md={12} sm={12}>
              <div className="d-lg-flex align-items-center justify-content-between">
                <div className="mb-4 mb-lg-0">
                  <h1 className="text-white mb-1">Add New Course</h1>
                  <p className="mb-0 text-white lead">
                    Just fill the form and create your courses.
                  </p>
                </div>
                <div>
                  <Link to="/courses/all-courses" className="btn btn-white ">
                    Back to Courses
                  </Link>{" "}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <GKStepper currentStep={currentStep} steps={steps} setCurrentStep={setCurrentStep} />
    </Fragment>
  );
};

export default AddNewCourse;
