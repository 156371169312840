// import node module libraries
import React, { Fragment, useState,useEffect } from 'react';
import { Col, Row, Container, Card, Dropdown, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
//import { MoreVertical } from 'react-feather';
//import SimpleBar from 'simplebar-react';
//import 'simplebar/dist/simplebar.min.css';

// import MDI icons
//import Icon from '@mdi/react';
//import { mdiFacebook, mdiTwitter, mdiLinkedin, mdiContentCopy } from '@mdi/js';

// import custom components
import GKYouTube from 'components/marketing/common/video/GKYouTube';
//import GKAccordionDefault from 'components/marketing/common/accordions/GKAccordionDefault';

// import data
//import { CourseIndex } from 'data/marketing/CourseIndexData';
import Axios from 'axios';
import Url from 'components/Url';

import {BsArrowRightCircleFill, BsArrowLeftCircleFill} from 'react-icons/bs';

export const Video = (props) => {

    const topicId = props.match.params.topicId;
	const encryptedCareerDetailId = props.match.params.encryptedCareerDetailId;
    //const subtopicId = props.match.params.subtopicId;
	const history = useHistory();
    const url1 = Url();
    const url = url1["url"];	

	const [YouTubeURL,setYouTubeURL] = useState();

	const [nextVideoLink,setNextVideoLink] = useState("");
	const [prevVideoLink,setPrevVideoLink] = useState("");
	const [projectLink,setProjectLink] = useState("");
	const [topicHeading, setTopicHeading] = useState("");

    const token = localStorage.getItem("stutoken");
	const studentId = localStorage.getItem("studentId");

	useEffect(() => {
		//API URL Signature - getLMSVideoLink/{userId}/{topicId}/{encryptedSubtopicId?}/{encryptedCareerDetailId?}

		let apiURL =  url + "getLMSVideoLink/" + studentId + "/" + topicId + "/0/" + encryptedCareerDetailId;
		Axios.get(
			apiURL,
			{headers: {
					"Content-type": "Application/json",
					"Authorization": `Bearer ${token}`
					}   
				}
		  )
		  .then((response) => {
			  //var response = response.data.virtualInternshipList;
			  console.log(response.data);
			  setYouTubeURL (response.data.videoLink);
			  setNextVideoLink(response.data.nextVideoLink);
			  setPrevVideoLink(response.data.prevVideoLink);
			  setProjectLink(response.data.projectLink);
			  setTopicHeading(response.data.topicHeading);
			},
			(error) => {
			  var status = error.response.status
			}
		  );	
	},[topicId]);
    
    const redirectToProject = () =>{
		const res = window.confirm("End of training, Do you want to proceed to Projects Section?");
		if(res){
			history.push(projectLink);
			return 1;
		}
	}//

	return (
		<Fragment>
            <div >
				<Row className="mb-2">
					<Col>
						<span className="h3">{topicHeading}</span>
					</Col>
				</Row>
                <Row className="d-flex">
				
				<Col sm={1} className="d-sm-flex align-items-center d-none">
					<Link to={`/lms/online-training/video/${prevVideoLink}/${encryptedCareerDetailId}`} style={{pointerEvents: prevVideoLink ? '' : 'none'}}><BsArrowLeftCircleFill size={40} style={{color:(prevVideoLink ? '' : 'gray') }} /></Link>
				</Col>
                <div 
                    className="embed-responsive position-relative col-sm-10 col-xs-12 d-block justify-content-center overflow-hidden p-0"
                    style={{ height: '600px' }}
                >
                    {YouTubeURL && 
                        <GKYouTube videoId={YouTubeURL} />
                    }
                </div>            
				<Col sm={1} className="d-sm-flex align-items-center justify-content-end d-none">
					{/* <Link to={`/lms/online-training/video/${nextVideoLink}/${encryptedCareerDetailId}`} style={{pointerEvents: nextVideoLink ? '' : 'none'}}><BsArrowRightCircleFill size={40} style={{color:(nextVideoLink ? '' : 'gray') }} /></Link> */}
					{nextVideoLink ? <Link to={`/lms/online-training/video/${nextVideoLink}/${encryptedCareerDetailId}`}><BsArrowRightCircleFill size={40} /></Link>
					: projectLink ? <Button variant='link' onClick={redirectToProject}><BsArrowRightCircleFill size={40} /></Button>
					: <Button variant='disabled'><BsArrowRightCircleFill size={40} /></Button>
					}
				</Col>    
				</Row>

				<Row className="d-xs-flex justify-content-center align-items-center pt-4 d-sm-none">
					<div className="col-3 "><Link to={`/lms/online-training/video/${prevVideoLink}/${encryptedCareerDetailId}`} style={{pointerEvents: (prevVideoLink ? '' : 'none')}}><BsArrowLeftCircleFill size={40} style={{color:(prevVideoLink ? '' : 'gray') }}/></Link></div>
					<div className="col-3">
						{/* <Link to={`/lms/online-training/video/${nextVideoLink}/${encryptedCareerDetailId}`} style={{pointerEvents: (nextVideoLink ? '' : 'none')}}><BsArrowRightCircleFill size={40} style={{color:(nextVideoLink ? '' : 'gray') }}/></Link> */}
						{nextVideoLink ? <Link to={`/lms/online-training/video/${nextVideoLink}/${encryptedCareerDetailId}`}><BsArrowRightCircleFill size={40} /></Link>
						: projectLink ? <Button variant='link' onClick={redirectToProject}><BsArrowRightCircleFill size={40} /></Button>
						: <Button variant='disabled'><BsArrowRightCircleFill size={40} /></Button>
						}						
					</div>
				</Row>
            </div>
		</Fragment>
	);
};

export default Video;
