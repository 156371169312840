import React from "react";
import { Route } from "react-router-dom";
import ResumeBuilder from ".";
//import ResumeList from "./ResumeList";

const getResumeBuilderRoutes = () => (
  <>
    {/* <Route exact path="/resumes" component={ResumeList} /> */}
    <Route exact path="/resumes/:id/edit" component={ResumeBuilder} />
  </>
);

export default getResumeBuilderRoutes;
