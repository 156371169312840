// import node module libraries
import React, { Fragment, useState, useEffect } from "react";

import {
  Col,
  Row,
  Button,
  Table,
  Container,
  Modal,
  Form,
} from "react-bootstrap";
import Axios from "axios";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import { BiPlus, BiMinus } from "react-icons/bi";
import ReactQuillEditor from "react-quill";
import { ToastContainer, toast } from "react-toastify";
import Url from "components/Url";
import YouTube from "react-youtube";
import { ListItem } from "@material-ui/core";

const ProjectTopics = (props) => {
  const { projectTopics, setLoad, courseId, load } = props;
  console.log(courseId);
  const admtoken = localStorage.getItem("admtoken");
  const url1 = Url();
  const s3 = url1.s3;
  const url = url1.url;

  const [showModal, setShowModal] = useState(false);
  const [projectAITopicId, setProjectAITopicId] = useState();

  const handleEdit = (itemId) => {
    setProjectAITopicId(itemId);
    // if (itemId) 
    //   setFormData(projectList[index]);
    // else 
    //   setFormData({ course_id: courseId });

    setShowModal(true);
  };

  return (
    <Container>
      <div className="mt-3">
        <div><p>Following topics will be displayed to the students when the access the VI project for the first time. The IGBot will create new projects based on the selected topic</p></div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Subject Name</th>
              <th>Topic 1</th>
              <th>Topic 2</th>
              <th>Topic 3</th>
              <th>Topic 4</th>
              <th>Topic 5</th>
              <th>Last Modified at</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
                <tr key={1}>
                  <td>{projectTopics?.project_ai_topic_id}</td>
                  <td>{projectTopics?.subject_name}</td>
                  <td>{projectTopics?.topic_1}</td>
                  <td>{projectTopics?.topic_2}</td>
                  <td>{projectTopics?.topic_3}</td>
                  <td>{projectTopics?.topic_4}</td>
                  <td>{projectTopics?.topic_5}</td>
                  <td>{projectTopics?.updated_at}</td>
                  <td>
                    <Col className="d-flex">
                      <span
                        onClick={() => handleEdit(projectTopics.project_ai_topic_id)}
                        style={{ cursor: "pointer" }}
                      >
                        <PencilSquare />
                      </span>
                    </Col>
                  </td>
                </tr>
          </tbody>
        </Table>

      </div>
      {showModal && (
        <FormPopup
          projectAITopicId={projectAITopicId}
          projectTopics={projectTopics}
          showModal={showModal}
          setShowModal={setShowModal}
          setLoad={setLoad}
          load={load}
          courseId={courseId}
        />
      )}
    </Container>
  );
};

/**********************************************************************************************/
/************************* Popup Modal Component *************************************/
/**********************************************************************************************/

const FormPopup = (props) => {
  const { projectAITopicId, projectTopics: projectTopics, setShowModal, showModal, setLoad, courseId } = props;
  const url1 = Url();
  const url = url1["url"];

  const [isEdit, setIsEdit] = useState(false);
  const [createValidate, setCreateValidate] = useState(false);
  const [id, setId] = useState(projectAITopicId);
  const [projectName, setProjectName] = useState();

  const [topics, setTopics] = useState([projectTopics.topic_1,projectTopics.topic_2,projectTopics.topic_3,projectTopics.topic_4,projectTopics.topic_5,]); // Initialize topics from props
  const [apiMessage, setApiMessage] = useState("");
  const [apiStatus, setApiStatus] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const admtoken = localStorage.getItem("admtoken");

  const handleClose = () => {
    setShowModal(false);
    setLoad(true);
  };

  const handleTopicChange = (value, index) => {
    const updatedTopics = [...topics];
    updatedTopics[index] = value;
    setTopics(updatedTopics);
  };

  const saveRecord = (e) => {
    e.preventDefault();
    
    let apiURL = url + "saveProjectAITopics";
    try {
      Axios.post(
        apiURL,
        {
          id: projectAITopicId,
          topics: topics,
          subject_id: courseId
        },
        {
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${admtoken}`,
          },
        }
      ).then(
        (response) => {
          if (response.status === 200) {
            setApiMessage(response.data.message);
            setApiStatus(1);
            if (!id) {
              setId(response.data.project_ai_topic.id);
              setButtonDisabled(true);
            }
          } else {
            setApiMessage(response.data.message);
            setApiStatus(2);
          }
        },
        (error) => {
          setApiMessage("Failed to save the project topics.");
          setApiStatus(2);
        }
      );
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? "Edit Course Project" : "Add Course Project"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={saveRecord}>

          {topics.map((topic, index) => (
            <Row className="mb-2" key={index}>
              <div className="col-2 text-end">
                <h5 className="m-1">Topic {index + 1}</h5>
              </div>
              <div className="col-10">
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder={`Topic ${index + 1}`}
                    value={topic}
                    onChange={(e) => handleTopicChange(e.target.value, index)}
                    required
                    isValid={createValidate && topic}
                    isInvalid={createValidate && !topic}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a topic
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Row>
          ))}
          <Row className="mb-2 d-flex justify-content-end">
            <button type="submit" className="btn btn-primary btn-sm" disabled={buttonDisabled}>
              Save
            </button>
            <button type="button" className="btn btn-outline-warning btn-sm ms-2" onClick={handleClose}>
              Close
            </button>
          </Row>
          {apiMessage !== "" && (
            <span className={apiStatus ? "text-success" : "text-danger"}>
              {apiMessage}
            </span>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};


export default ProjectTopics;
