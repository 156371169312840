import React, { useState, Fragment } from 'react';
import { Col, Row, Spinner, Button, Form } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import Url from 'components/Url';
import Gmap from 'assets/images/background/Gmap.png';

import { ToastContainer, toast } from 'react-toastify';
import { Telephone, EnvelopeOpen, GeoAlt } from 'react-bootstrap-icons';
import Message from 'components/dashboard/chat/chatbox/Message';

const ContactUs = () => {

    const url1 = Url()
    const url = url1["url"]

    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [msg, setMessage] = useState("")
    const [loading, setLoading] = useState(false);

    const submit = (e) => {
        e.preventDefault();


        if (!email || !name || !msg)      // Adding Validation 
        {
            toast.error("Please fill all the required fields ");
            // alert(" Please fill all the required fields");
            return;
        }
        setLoading(true);

        // Getting API Call once validation passes 
        const api = url + "SendMailContactUs";
        fetch(api, {
            method: "post",
            headers: { 'content-Type': 'application/json' },
            body: JSON.stringify({ email, msg, name }),
        })
            .then((apidata)=>apidata.json())
            .then((actualdata)=>{
                if (actualdata["message"] === "Mail Sent") {
                setEmail("");
                setName("");
                setMessage("");
                toast.success("Thank you for writing to us. We will get back to you shortly.");
                }
                else {
                    toast.error("Failed to send message.!please try again later!")
                }
            })
            .finally(() => setLoading(false));
    };




    return (
        <>

            <Helmet>
                {/*<title>Internship | Summer Internship | Internship in India
                </title> */}
                <title>Contact us | Internshipgate
                </title>
                <meta name="description" content="Internshipgate is the best internship and online training platform with  paid or unpaid internships in Engineering, MBA, and other streams.Contact now!" />
            </Helmet>

            <ToastContainer />

            <div className="position-relative">
                <div className="" style={{ backgroundImage: `url(${Gmap})` }} >
                    <img src={Gmap} alt="Map" className="img-fluid" />
                </div>
                <div className="col-sm-8 col-12 offset-sm-2 bg-white px-6 py-2 rounded" style={{ position: "relative", top: "-100px" }}>

                    <Row className="mb-5">
                        <Col xs={12} md={6} className="mt-3">
                            <div className="fs-2 lead">Feel free to get in touch</div>
                            <div className="fs-4 mt-3">We hope you like coffee!</div>
                            <div className="fs-5 mt-3"><Telephone size={16} className="text-primary me-1" /> +91 8105566534, +91 7678360337</div>
                            <div className="fs-5 mt-3"><EnvelopeOpen size={16} className="text-primary me-2" />info@internshipgate.com</div>
                            <div className="fs-5 mt-3"><GeoAlt size={17} className="text-primary me-2" />1st floor, 27th main, 13th Cross Rd, 1st Sector, HSR Layout, Bengaluru, Karnataka 560102</div>
                        </Col>

                        <Col xs={12} md={6} className="mt-3">
                            <div className="h4">Share your Views / Thoughts / Suggestions / Queries</div>

                            <Form>
                                <Form.Group>
                                    <Form.Text className="mb-3 ml-0">Email*</Form.Text>
                                    <Form.Control type="email" className="form-control-contact" required placeholder=""
                                        name="email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Text className="Email mt-5 mb-3 ml-0">Name*</Form.Text>
                                    <Form.Control type="text" className="form-control-contact" required placeholder=""
                                        name="name"
                                        value={name}
                                        onChange={e => setName(e.target.value)} />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Text className="mt-5 mb-3 ml-0">Message</Form.Text>
                                    <Form.Control as="textarea" className="form-control-contact-msg" rows="5"
                                        required
                                        name="msg"
                                        value={msg}
                                        onChange={e => setMessage(e.target.value)}
                                    />
                                </Form.Group>
                                {console.log(loading)}
                                <Button className="mt-2" type="submit" variant="primary" disabled={loading} onClick={(e) => { submit(e); }}> {loading ? <Fragment><Spinner size="sm" /> Please wait....</Fragment> : "Send Message"}</Button>

                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default ContactUs
