// import node module libraries
import { useEffect, useState } from 'react';
import { Nav, Tab, Form } from 'react-bootstrap';

// import sub custom components
import AllMessagesList from './AllMessagesList';
import UnreadMessagesList from './UnreadMessagesList';

// import custom components
import { FormSelect } from 'components/elements/form-select/FormSelect';
import Select from 'react-select'
import Url from 'components/Url';
// import context file
import { ChatContext } from 'context/Context';

const Sidebar = (props) => {
	const { hideChatBox, setHideChatBox, getStudentChats, chatList, setChatList, getInitiatedChats, getChatMessage, setName, setId, internshipList, id } = props;
	// const {
	// 	ChatState: { threads }
	// } = useContext(ChatContext);
	const url1 = Url()
	const url = url1.url
	const [search, setSearch] = useState("")
	var employerInternshipListOptions = [{value: "All", label: "All"}];
	const token = localStorage.getItem('emptoken')

	for (let i = 0; i < internshipList?.length; i++) {
		employerInternshipListOptions.push({
		  value: internshipList[i].id,
		  label: internshipList[i].internship_details,
		});
	  }

	  const getAppliedList = (id) => {
		if(id === "All"){
			  getInitiatedChats();
		}else{
	
		  const api = url + "getApplicantListForInternship/" + id;
		  //Umesh - 20220907 - end
		  fetch(api, {
			method: "GET",
		  })
			.then((apidata1) => {
			  return apidata1.json();
			})
			.then((actualdata1) => {
			  console.log(actualdata1);
	  
			  if (actualdata1 !== 0) {
				console.log(actualdata1);
				setChatList(actualdata1);
			  }
			});
		}
	  };

	  const searchChats = (e) => {
		setSearch(e);
		if(e){
			const data = chatList?.filter(item => {
				if(token && item.student_name.toLowerCase().includes(e.toLowerCase())){
					return item
				}else if(!token && item.companyname.toLowerCase().includes(e.toLowerCase())){
					return item
				}
				
			})
			if(data){
				setChatList(data);
			}
			return
		}
		if(e === '' && token){
			getInitiatedChats();
		}else{
			getStudentChats()
		}
	  }



	// const UnreadMessages = threads.filter(function (dataSource){ return dataSource.read === false; })
	return (
		<div className="bg-white border-end border-top vh-100">
			{/* chat list */}
			<Tab.Container defaultActiveKey="messages-tab">
				<div className="chat-window">
					<div className="chat-sticky-header sticky-top bg-white">
						<div className="px-4 pt-3">
							<div className={token ? "mb-2" : "d-none"}>
								<Form.Group className="mb-3" controlId="industry-type">
									<Select onChange={e => getAppliedList(e.value)} options={employerInternshipListOptions} defaultValue={employerInternshipListOptions[0]}  />
								</Form.Group>
							</div>
							<div className="">
								<Form.Group controlId="cities">
									<Form.Control value={search} onChange={(e) => {
										searchChats(e.target.value)
										}} type="search" placeholder="Search applicants by name" />
								</Form.Group>
							</div>
						</div>
						{/* nav tabs*/}
						<Nav className="nav-line-bottom" as="ul">
							<Nav.Item as="li">
								<Nav.Link eventKey="messages-tab" className="py-2" role="button">
									All messages
								</Nav.Link>
							</Nav.Item>
							{/* <Nav.Item as="li">
								<Nav.Link eventKey="unread-tab" className="py-2" role="button">
									Unread 10
								</Nav.Link>
							</Nav.Item> */}
						</Nav>
					</div>
					<Tab.Content className="h-100">
						<Tab.Pane eventKey="messages-tab" className="pb-0">
							<AllMessagesList
								hideChatBox={hideChatBox}
								chatList={chatList}
								setHideChatBox={setHideChatBox}
								getChatMessage={getChatMessage}
								setName={setName}
								setId={setId}
								search={search}
								id={id}
							/>
						</Tab.Pane>
						<Tab.Pane eventKey="unread-tab" className="pb-0">
							<UnreadMessagesList 
								hideChatBox={hideChatBox}
								setHideChatBox={setHideChatBox}/>
						</Tab.Pane>
					</Tab.Content>
				</div>
			</Tab.Container>
		</div>
	);
};
export default Sidebar;
