import React from 'react';

export default function Url() {
	const checklocal = window.location.href;

	var url;
	var url1;
	var s3;
	var razor_key;

	switch (true) {
		case checklocal.includes('localhost'):
			/*----------Local------------ */
			//url = "http://localhost:8000/api/"

			//url1 = "http,localhost,api"

			url = 'https://staging-dev.internshipgate.com/public/api/';

			url1 = 'https,staging-dev.internshipgate.com,public,api';

			s3 = 'https://internshipgates3.s3.ap-south-1.amazonaws.com/';
			razor_key = 'rzp_test_noeYHoC2q9Qxp1';
			break;

		// /*----------blog staging------------ */
		case checklocal.includes('newblog.internshipgate.com') || 
			checklocal.includes('vi.internshipgate.com'):
			url = 'https://staging-dev.internshipgate.com/public/api/';

			url1 = 'https,staging-dev.internshipgate.com,public,api';

			s3 = 'https://internshipgates3.s3.ap-south-1.amazonaws.com/';

			razor_key = 'rzp_test_noeYHoC2q9Qxp1';
			break;

		// /*----------Staging------------ */
		case checklocal.includes('staging.internshipgate'):
			url = 'https://staging-dev.internshipgate.com/public/api/';

			url1 = 'https,staging-dev.internshipgate.com,public,api';

			s3 = 'https://internshipgates3.s3.ap-south-1.amazonaws.com/';

			razor_key = 'rzp_test_noeYHoC2q9Qxp1';
			break;

		/*----------Prod------------ */

		case checklocal.includes('internshipgate.com') &&
			!checklocal.includes('staging.internshipgate'):
			url = 'https://dev.internshipgate.com/public/api/';

			url1 = 'https,dev.internshipgate.com,public,api';

			s3 = 'https://internshipgates3.s3.ap-south-1.amazonaws.com/';

			razor_key = 'rzp_live_Bx3awOCxzFKlwc';
			break;

		default:
			url = '';
			url1 = '';
			s3 = '';
			razor_key = '';
	}

	// if (checklocal.includes("localhost")) {
	//   /*----------Local------------ */
	//     url = "http://localhost:8000/api/"

	//     url1 = "http,localhost,api"

	//   //  s3 = "https://internshipgates3.s3.ap-south-1.amazonaws.com/"

	//   //url = "https://staging-dev.internshipgate.com/public/api/";

	//   //url1 = "https,staging-dev.internshipgate.com,public,api";

	//   s3 = "https://internshipgates3.s3.ap-south-1.amazonaws.com/";

	//   //     /*------------------------Local--------------------------*/
	//   //      razor_key = "rzp_test_noeYHoC2q9Qxp1"
	// }

	// // /*----------New Server ------------ */
	// if (checklocal.includes("3.110.173.242") || checklocal.includes("newblog.internshipgate.com")) {
	//   url = "https://staging-dev.internshipgate.com/public/api/";

	//   url1 = "https,staging-dev.internshipgate.com,public,api";

	//   s3 = "https://internshipgates3.s3.ap-south-1.amazonaws.com/";

	//   razor_key = "rzp_test_noeYHoC2q9Qxp1";
	// }

	// // /*----------Staging------------ */
	// if (checklocal.includes("newblog.internshipgate.com")) {
	//   url = "https://staging-dev.internshipgate.com/public/api/";

	//   url1 = "https,staging-dev.internshipgate.com,public,api";

	//   s3 = "https://internshipgates3.s3.ap-south-1.amazonaws.com/";

	//   razor_key = "rzp_test_noeYHoC2q9Qxp1";
	// }

	// // /*----------Staging------------ */
	// if (checklocal.includes("staging.internshipgate")) {
	//   url = "https://staging-dev.internshipgate.com/public/api/";

	//   url1 = "https,staging-dev.internshipgate.com,public,api";

	//   s3 = "https://internshipgates3.s3.ap-south-1.amazonaws.com/";

	//   razor_key = "rzp_test_noeYHoC2q9Qxp1";
	// }

	// /*----------Prod------------ */

	// if (
	//   checklocal.includes("internshipgate.com") &&
	//   !checklocal.includes("staging.internshipgate")
	// ) {
	//   url = "https://dev.internshipgate.com/public/api/";

	//   url1 = "https,dev.internshipgate.com,public,api";

	//   s3 = "https://internshipgates3.s3.ap-south-1.amazonaws.com/";

	//   razor_key = "rzp_live_5mXgmBxAxo5pnB";
	// }

	return { url, url1, s3, razor_key };
}
