import React, { useEffect, useRef, useState, createRef } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  Svg,
  Path,
  Circle,
  Polyline,
} from "@react-pdf/renderer";
import { Html } from "react-pdf-html";
import {
  useA4Scale,
  TemplatePage,
  renderHTMLContent,
  getDisplayDate,
} from "../../utils";
import { Row, Col } from "react-bootstrap";
import DummyProfilePic from "../../../../../assets/images/background/profilepic.png";
import { FiPhone } from "react-icons/fi";
import { isEmpty } from "../../../foundation";

Font.registerHyphenationCallback((word) => {
  // Return entire word as unique part
  return [word];
});

const styles = StyleSheet.create({
  page: {
    display: "flex",
    fontFamily: "Roboto",
    paddingBottom: "40px",
     paddingVertical: "30px",
    // position: "absolute",
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  left: {
    backgroundColor: "#ffffff",
    paddingTop: "60px",
    width: "300px",
  },
  right: {
    width: "540px",
    paddingRight: "10px",
    paddingTop: "40px",
    fontSize: "10px",
  },
  profileImageWrapper: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
    paddingBottom: "5px",
  },
  profileImage: {
    width: "143px",
    height: "143px",
    borderRadius: "50%",
    border: "1px solid #3644a1",
    margin: " 8px 46px",
    marginBottom: "10px",
  },
});

const ContactInfo = ({
  data: { Phone, Address, Postal_Code, City, display_email },
}) => (
  <View
    style={{
      //    padding: "24px",
      paddingLeft: "32px",
      // bottom: "12px",
    }}
  >
    <Text
      style={{
        color: "#000000",
        fontWeight: 700,
        fontSize: "18px",
        marginBottom: "5px",
        paddingBottom: "5px",
      }}
    >
      Contact
    </Text>
    <View
      style={{
        display: "flex",
        // flexDirection: "row",
        // alignItems: "center",
        marginBottom: "8px",
      }}
    >
      <Text
        style={{
          fontSize: "14px",
          color: "#000000",
          // fontWeight: "bold",
        }}
      >
        Phone
      </Text>
      <Text
        style={{
          color: "#000000",
          fontSize: "12px",

          paddingTop: "3px",
          marginBottom: "10px",
        }}
      >
        {Phone}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        // flexDirection: "row",
        // alignItems: "center",
        marginBottom: "8px",
        // paddingLeft: "4px",
      }}
    >
      <Text
        style={{
          fontSize: "14px",
          color: "#000000",
          // fontWeight: "bold",
        }}
      >
        Email
      </Text>
      <Text
        style={{
          color: "#000000",
          fontSize: "12px",

          paddingTop: "3px",
          marginBottom: "10px",
        }}
      >
        {display_email}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        // flexDirection: "row",
        // alignItems: "center",
        marginBottom: "8px",
        // paddingLeft: "4px",
      }}
    >
      <Text
        style={{
          fontSize: "14px",
          color: "#000000",
          // fontWeight: "bold",
        }}
      >
        Address 
      </Text>
      <Text
        style={{
          color: "#000000",
          fontSize: "12px",
          paddingTop: "3px",
          marginBottom: "15px",
        }}
      >
        {City} | {Address} | {Postal_Code}
      </Text>
    </View>
  </View>
);

const Skills = ({ data: { skills } }) => {
  if (!skills || !skills.length) {
    return <View />;
  }
  return (
    <View
      style={{
        // padding: "8px 20px",
        marginTop: "4px",
        paddingLeft: "32px",

        // border: "2px solid blue",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontWeight: "bold",
          fontSize: "18px",
          marginBottom: "30px",
          fontstretch: "normal",
        }}
      >
        Skills
      </Text>
      {skills.map((s) => (
        <View
          style={{
            display: "flex",

            //flexDirection: "row",
            justifyContent: "space-between",
          }}
          wrap={false}
        >
          <Text
            style={{
              color: "#000000",
              fontSize: "12px",
              paddingBottom: "5px",
            }}
          >
            {s.skill_name}
          </Text>
          <View
            style={{
              backgroundColor: "#000000",
              width: "150px",
              height: "4px",
              marginBottom: "12px",
            }}
          >
            <View
              style={{
                backgroundColor: "#10edd2",

                height: "4px",
                width: `${s.level * 50}px`,
                // color: "white",
              }}
            ></View>
          </View>
        </View>
      ))}
    </View>
  );
};

const Hobbies = ({ data: { hobbies } }) => {
  if (!hobbies || !hobbies.length) {
    return <View />;
  }
  return (
    <View style={{ paddingLeft: "32px", marginRight: "15px" }}>
      <Text
        style={{
          color: "#000000",
          fontWeight: 700,
          fontSize: "18px",
          marginBottom: "10px",
          paddingTop: "30px",
        }}
      >
        Hobbies
      </Text>

      {hobbies.map((s) => (
        <Text
          style={{
            color: "#000000",
            fontSize: "10px",
            paddingBottom: "10px",
            marginRight: "15px",
          }}
        >
          {s.hobbies}
        </Text>
      ))}
    </View>
  );
};

const Languages = ({ data: { languages } }) => {
  if (!languages || !languages.length) {
    return <View />;
  }
  return (
    <View
      style={{
        marginTop: "4px",
        paddingLeft: "32px",

        // border: "2px solid yellow"
      }}
      wrap={false}
    >
      <Text
        style={{
          color: "#000000",
          //textDecoration: "underline",
          fontSize: "18px",
          fontWeight: "bold",
          marginBottom: "30px",
        }}
      >
        Languages
      </Text>
      {languages.map((s) => (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // justifyContent: "space-between",
          }}
        >
          <Text

            style={{
              color: "#000000",
              fontSize: "12px",
              fontWeight: "bold",
              paddingBottom: "10px",
              paddingRight: "20px",

              // paddingRight: "5px",
            }}
          >
            {s.language_name}
          </Text>
          <View
            style={{
              backgroundColor: "#000000",
              width: "78px",
              height: "6px",
              marginBottom: "10px",
              borderRadius: "50px",
            }}
          >
            <View
              style={{
                backgroundColor: "#10edd2",
                borderRadius: "50px",
                height: "6px",
                width: `${s.level * 19.5}px`,
                // color: "white",
              }}
            ></View>
          </View>
        </View>
      ))}
    </View>
  );
};

const References = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const {
    title,
    subTitle,
    phoneNo,
    emailId,
    startDate,
    endDate,
    description,
    location = "city",
  } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View
      style={{
        paddingLeft: "32px ",
        marginTop: "20px",
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
        }}
      >
        <View>
          <Text
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              fontstretch: "normal",

              color: "#000000",
            }}
          >
            {heading}
          </Text>
        </View>
      </View>
      {items.map((item) => (
        <View style={{ marginTop: "10px" }}>
          <Text
            style={{
              fontSize: "10px",
              fontWeight: "bold",
              color: "#000000",
              marginTop: "3px",
            }}
          >
            {item[title]}
            {", "}
            {getDisplayDate(item[startDate], item[endDate])}{" "}
            {item[location] && `, ${item[location]}`}
          </Text>
          {/* <Text style={{ fontSize: "12px", fontWeight: "500" }}>
            {item[title]}
          </Text> */}
          <Text
            style={{ fontSize: "12px", color: "#000000", marginTop: "3px" }}
          >
            {item[subTitle]}
          </Text>
          <Text
            style={{ fontSize: "12px", color: "#363434", marginTop: "3px" }}
          >
            {item[phoneNo]}
          </Text>
          <Text
            style={{ fontSize: "12px", color: "#363434", marginTop: "3px" }}
          >
            {item[emailId]}
          </Text>

          {item[description] && (
            <View style={{ marginTop: "5px", marginBottom: "5px" }}>
              {renderHTMLContent(item[description], {
                "*": { color: "#3f3f3f" },
              })}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};
{/*
const AdditionalInfo = ({
  data: { Country, Driving_License, Nationality, dob, Place_of_Birth },
}) => (
  <View style={{ marginTop: "40px", marginLeft: "32px" }}>
    <Text
      style={{
        color: "#000000",
        //fontWeight: 700,
        fontSize: "20px",
        marginBottom: "2px",
        fontWeight: "bold",
        //textDecoration: "underline",
      }}
    >
    contact
    </Text>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        marginBottom: "3px",
        alignItems: "center",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontSize: "10px",
        }}
      >
        {Country}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "3px",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontSize: "10px",
        }}
      >
        {dob}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "3px",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontSize: "10px",
        }}
      >
        {Nationality}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "3px",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontSize: "10px",
        }}
      >
        {Place_of_Birth}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "3px",
      }}
    >
      <Text
        style={{
          color: "#000000",
          fontSize: "10px",
        }}
      >
        {Driving_License}
      </Text>
    </View>
  </View>
);
      */}  
const RenderView = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const {
    title,
    subTitle,
    startDate,
    endDate,
    description,
    location = "city",
  } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View
      style={{
        marginTop: "20px",
        marginLeft: "30px",
      }}
    >
      <Text
        style={{
          fontSize: "16px",
          fontWeight: "bold",
          color: "#000000",
          //textDecoration: "underline",
        }}
      >
        {heading}
      </Text>
      {items.map((item) => (
        <View
          style={{
            marginTop: "10px",
            paddingLeft: "4px",
            marginButton: "3px",
          }}
          wrap={true}
        >
          <Text
            style={{
              fontSize: "10px",
              fontWeight: "bold",
              color: "#000000",
              marginTop: "3px",
              marginBottom: "10px",
              marginRight: "65%",
            }}
          >
            {getDisplayDate(item[startDate], item[endDate])}{" "}
            {item[location] && `, ${item[location]}`}
          </Text>
          <Text
            style={{
              fontSize: "10px",
              fontWeight: "bold",
              color: "#242732",
              marginRight: "60%",
              // backgroundColor: "red",
              marginBottom: "5px",
            }}
          >
            {item[title]}
          </Text>
          <Text
            style={{
              fontSize: "8px",
              color: "#242732",
              marginTop: "3px",
              // marginBottom: "10px",
            }}
          >
            {item[subTitle]}
          </Text>

          {item[description] && (
            <View
              style={{
                marginTop: "3px",
                marginBottom: "5px",
                position: "absolute",
                paddingLeft: "140px",
              }}
            >
              {renderHTMLContent(item[description], {
                "*": { color: "#3f3f3f" },
              })}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

const Courses = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const {
    title,
    subTitle,
    startDate,
    endDate,
    // description,
    // location = "city",
  } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View
      style={{
        marginTop: "40px",
        marginLeft: "30px",
      }}
    >
      <Text
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          color: "#000000",
          //textDecoration: "underline",
        }}
      >
        {heading}
      </Text>
      {items.map((item) => (
        <View style={{ marginTop: "12px" }} wrap={false}>
          <Text style={{ fontSize: "11px", fontWeight: "500" }}>
            {item[title]}
          </Text>
          <Text
            style={{ fontSize: "11px", color: "#242732", marginTop: "3px" }}
          >
            {item[subTitle]}
          </Text>
          <Text
            style={{ fontSize: "10px", color: "#363434", marginTop: "3px" }}
          >
            {getDisplayDate(item[startDate], item[endDate])}
            {/* {item[location] && `, ${item[location]}`} */}
          </Text>
          {/* {item[description] && (
            <View style={{ marginTop: "5px" }}>
              {renderHTMLContent(item[description], {
                "*": { color: "#3f3f3f" },
              })}
            </View>
          )} */}
        </View>
      ))}
    </View>
  );
};

const template20 = ({ data }) => {
  const {
    profileImage,
    First_Name,
    Last_Name,
    Wanted_Job_Title,
    professionalSummary: { Description = "" } = {},
  } = data;
  return (
    <TemplatePage data={data}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={{
              position: "absolute",
              height: "100vh",
              width: "164px",
              left: "0px",
              top: "0px",
            }}
            fixed
          >
            <View
              style={{
                height: "100%",
                backgroundColor: "#ffffff",
                width: "212px ",
              }}
            ></View>
          </View>
          <View style={styles.wrapper}>
            <View style={styles.left}>
              <Text
                style={{
                  fontWeight: 600,
                  fontFamily: "Roboto",
                  color: "#00ffff",
                  fontSize: "35px",
                  marginBottom: "14px",
                  textAlign: "center",
                  textTransform:"uppercase",
                }}
              >
                {First_Name}
              </Text>
              <Text
                style={{
                  fontWeight: 500,
                  fontFamily: "Roboto",
                  color: "#000000",
                  fontSize: "20px",
                  marginBottom: "14px",
                  paddingLeft: "60px",
                }}
              >
                {Wanted_Job_Title}
              </Text>

              <View style={styles.profileImageWrapper}>
                <Image
                  src={profileImage || DummyProfilePic}
                  style={styles.profileImage}
                />
              </View>
              <ContactInfo data={data} />

              <Skills data={data} />

              <Languages data={data} />

             {/* <AdditionalInfo data={data} />*/}
              <References
                data={data}
                dataKey="References"
                heading="References"
                fieldMap={{
                  title: "ref_name",
                  subTitle: "company_name",
                  phoneNo: "phone_no",
                  emailId: "ref_email",
                }}
              />

              <Hobbies data={data} />
            </View>
            <View style={styles.right}>
              <View
                style={{
                  position: "absolute",

                  backgroundColor: "#ffffff",
                }}
              >
                <View
                  style={{
                    // paddingVertical: "30px",
                    flex: "0 0 16px",
                    display: "flex",

                    width: "80px",
                    height: "50px",
                  }}
                >

                </View>
              </View>
              <View
                style={{
                  marginBottom: "10px",
                  marginTop: "20px",
                }}
              >
                <Text
                  style={{
                    fontWeight: 700,
                    fontFamily: "Roboto",
                    color: "#000000",
                    fontSize: "35px",
                   marginLeft:"-30px",
                    marginBottom: "5px",
                    textTransform:"uppercase",
                  }}
                >
                  {Last_Name}
                </Text>
              </View>
              <View>
                <View
                  style={{
                    position: "absolute",
                 
                    backgroundColor: "#ffffff",
                  }}
                >
                 
                
                  <View
                    style={{
                       paddingVertical: "30px",
                      flex: "0 0 16px",
                     // display: "flex",

                      width: "80px",
                      height: "150px",
                    }}
                  ></View>
                </View>
                

                {!isEmpty(Description) && (
                  <View
                
                
                    style={{
                      marginTop: "-20px",
                      // width: "200px",
                      marginLeft: "-4px",
                      marginBottom: "10px",
                    }}
                  >
  
                    <View
                      style={{
                        textTransform: "capitalize",
                        color: "#3f3f3f",
                        fontSize: "10px",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        fontWeight: 500,
                        paddingLeft: "40px",
                        marginTop: "60px",
                      }}
                    >
                      {renderHTMLContent(Description)}
                    </View>
                  </View>
                )}
              </View>

              <View
                style={{
                  position: "absolute", backgroundColor: "#ffffff",
                }}
              >
                <View
                  style={{
                    // paddingVertical: "30px",
                    flex: "0 0 16px",
                    display: "flex",

                    width: "50px",
                    height: "50px",
                  }}
                ></View>
              </View>
              <References
                data={data}
                dataKey="employmentHistory"
                heading="EmploymentHistory"
                fieldMap={{
                  title: "Employer",
                  subTitle: "Job_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "Description",
                }}
              />

              <RenderView
                data={data}
                dataKey="education"
                heading="Education"
                fieldMap={{
                  title: "institute_name",
                  subTitle: "degree",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "Description",
                }}
              />

              <References
                data={data}
                dataKey="internships"
                heading="Internships"
                fieldMap={{
                  title: "employer_name",
                  subTitle: "job_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "description",
                }}
              />

              <RenderView
                data={data}
                dataKey="activities"
                heading="EXTRA_CURRICULAR_ACTIVITY"
                fieldMap={{
                  title: "employer_name",
                  subTitle: "function_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "description",
                }}
              />
              {/* <Courses data={data} /> */}

              <Courses
                data={data}
                dataKey="courses"
                heading="Courses"
                fieldMap={{
                  title: "course_name",
                  subTitle: "institute_name",
                  startDate: "start_date",
                  endDate: "end_date",
                }}
              />
            </View>
          </View>
        </Page>
      </Document>
    </TemplatePage>
  );
};

template20.displayName = "Template20";
export default template20;
