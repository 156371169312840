// import node module libraries
import React, { Fragment, useMemo, useState, useEffect } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Button,
  Image,
  Dropdown,
  Table,
  Container,
  Modal,
  Form
} from "react-bootstrap";
import Axios from 'axios';
import { PencilSquare, Trash } from "react-bootstrap-icons";
import { BsPlayCircle } from "react-icons/bs";

import { ToastContainer, toast } from "react-toastify";

// import custom components
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import DotBadge from "components/elements/bootstrap/DotBadge";
import Url from "components/Url";
import GKYouTube from "../GKYouTube";
import YouTube from 'react-youtube';

const OnlineTraining = (props) => {
  const { onlineTrainingList, setLoad, courseId } = props;
  const admtoken = localStorage.getItem("admtoken");
  const url1 = Url();
  const s3 = url1.s3;
  const url = url1.url;

  const [showModal, setShowModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [previewVideoId, setPreviewVideoId] = useState();

  const [formData, setFormData] = useState([]);

  const handleEdit = (itemId, index) => {
    if(itemId)
      setFormData(onlineTrainingList[index]);
    else
      setFormData({course_id:courseId});

    setShowModal(true); 
  };  

  const handleDelete = (e, itemId, index) => {
    e.preventDefault();
    const res = window.confirm("The trainig will be deleted, are you sure?")
    if (!res) {
      return;
    }

    let apiURL =  url + "deleteCourseVideoById";

    try{
        Axios.delete(
            apiURL,
            {
              headers: {
                    "Content-type": "Application/json",
                    "Authorization": `Bearer ${admtoken}`
                    },    
              data: {
                    id:itemId
                  },
            }
        ).then((response) => {

            if (response.status === 202) {
                toast.success(response.data.message,{autoClose: 1500});
                setLoad(true);
            }else{
              toast.warning(response.data.message,{autoClose: 1500});
            }
            },(error) => {
              toast.warning(response.data.message,{autoClose: 1500});
            }
        );	
        
    }catch(error){
        if (error.response) {
            // The request was made, but the server responded with an error
            toast.warning(error.response,{autoClose: 1500});
          } else if (error.request) {
            // The request was made, but no response was received
            toast.warning('No response received.',{autoClose: 1500});
          } else {
            // Something happened in setting up the request that triggered an error
            toast.warning('Error:' + error.message,{autoClose: 1500});
          }
    }	

  };

  const handlePlay = (e, videoId) => {
    setPreviewVideoId(videoId);
    setShowVideoModal(true);
  };
  return (
    <Container>
      <div className="mt-3">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Topic Name</th>
              <th>Video Link</th>
              <th>Video Id</th>
              <th>Last Modified at</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {onlineTrainingList &&
              onlineTrainingList.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.topic_name}</td>
                  <td>{item.video_link}</td>
                  <td>{item.video_id}</td>
                  <td>{item.updated_at}</td>
                  <td>
                    <Col className="d-flex">
                      <span
                        onClick={() => handleEdit(item.id, index)}
                        style={{ cursor: "pointer" }}
                      >
                        <PencilSquare />
                      </span>
                      <span
                        onClick={(e) => handleDelete(e, item.id, index)}
                        className="ms-3"
                        style={{ cursor: "pointer" }}
                      >
                        <Trash />
                      </span>
                      {item.video_id && 
                        
                        <span
                          onClick={(e) => handlePlay(e, item.video_id)}
                          className="ms-3"
                          style={{ cursor: "pointer" }}
                        >
                          <BsPlayCircle />
                        </span>
                      }
                    </Col>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <Button variant="primary"onClick={() => handleEdit(null, null)} size="sm" >Add New Course Video</Button>
      </div>
      {showModal && <FormPopup formData={formData} showModal={showModal} setShowModal={setShowModal} setLoad={setLoad} /> }
      
      <Modal show={showVideoModal} onHide={() => {setShowVideoModal(false)}} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
          <div style={{ height: '400px' }} >
            {/* <GKYouTube videoId={previewVideoId} /> */}
            <YouTube
				videoId={previewVideoId}
				opts={{width: '600',
        height: '400',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0
        }}}
				className="position-absolute top-0 end-0 start-0 end-0 bottom-0 h-100 w-100"
			/>
          </div>
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  );
};


/**********************************************************************************************/
/************************* Popup Modal Component *************************************/
/**********************************************************************************************/

const FormPopup = (props) => {
  const {
    formData,
    setShowModal,
    showModal,
    setLoad,
  } = props;

  const url1 = Url();
  const url = url1["url"];

  //const Detail = [];

  const [isEdit, setIsEdit] = useState(false);
  const [createValidate, setCreateValidate] = useState(false);

  const [show, setShow] = useState(props.show);

  const [id, setId] = useState();
  const [courseId, setCourseId] = useState();
  const [topicName, setTopicName] = useState();
  const [videoLink, setVideoLink] = useState();
  const [videoId, setVideoId] = useState();

  const [apiMessage, setApiMessage] = useState("");
  const [apiStatus, setApiStatus] = useState(0);

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const admtoken = localStorage.getItem("admtoken");

  useEffect(() => {
    setCourseId(formData.course_id);
    if(formData.id){
      setId(formData.id);
      setTopicName(formData.topic_name);
      setVideoLink(formData.video_link);
      setVideoId(formData.video_id);
    }
  },[]

  );

  const handleClose = () => {

    setShowModal(false);
    setLoad(true);
  };

  const saveRecord = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setCreateValidate(true);
      return;
    }

    // const creapi = url + "saveCourseVideoById";
    // fetch(creapi, {
    //   method: "POST",
    //   body: JSON.stringify({
    //     id:id,
    //     course_id:courseId,
    //     topic_name:topicName,
    //     video_link:videoLink,
    //     video_id:videoId,
    //   }),
    //   headers: { "Content-Type": "application/json" },
    // })
    //   .then((apidata) => {
    //     return apidata.json();
    //   })
    //   .then((actualdata) => {
    //     setApiMessage(actualdata["message"]);
    //     setApiStatus(actualdata["status"]);

    //     if (!id && actualdata["status"] == 1) {
    //       setButtonDisabled(true);
    //     }
    //   });

    let apiURL =  url + "saveCourseVideo";

    try{
        Axios.post(
            apiURL,
            {
              id:id,
              course_id:courseId,
              topic_name:topicName,
              video_link:videoLink,
              //video_id:videoId,
            },
            {headers: {
                    "Content-type": "Application/json",
                    "Authorization": `Bearer ${admtoken}`
                    }   
                }
        ).then((response) => {

            if (response.status === 200) {
                setApiMessage(response.data.message);
                setApiStatus(1);
                if(!id) setButtonDisabled(true);
                setLoad(true);
            }else{
              setApiMessage(response.data.message);
              setApiStatus(2);
            }
            },(error) => {
              setApiMessage(response.data.message);
              setApiStatus(2);
            }
        );	
        
    }catch(error){
        console.log(error.response);

        if (error.response) {
            // The request was made, but the server responded with an error
            handleErrorResponse(error.response);
          } else if (error.request) {
            // The request was made, but no response was received
            console.error('No response received.');
          } else {
            // Something happened in setting up the request that triggered an error
            console.error('Error:', error.message);
          }
    }	


  };

  return (
    
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {isEdit ? "Edit Course Video" : "Add Course Video"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={saveRecord}>
          <Row className="mb-2">
            <div className="col-2 text-end ">
              <h5 className="m-1">Topic Name</h5>
            </div>
            <div className="col-10">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Topic Name"
                  id="topic_name"
                  name="topic_name"
                  value={topicName}
                  onChange={(e) =>
                    setTopicName(e.target.value ? e.target.value : "")
                  }
                  required
                  isValid={createValidate && topicName}
                  isInvalid={createValidate && !topicName}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter Topic Name
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-2 text-end ">
              <h5 className="m-1">Video Link</h5>
            </div>
            <div className="col-10">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Video Link"
                  id="video_link"
                  name="video_link"
                  value={videoLink}
                  onChange={(e) =>
                    setVideoLink(e.target.value ? e.target.value : "")
                  }
                  required
                  isValid={createValidate && videoLink}
                  isInvalid={createValidate && !videoLink}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter Topic Name
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Row>
          {/* <Row className="mb-2">
            <div className="col-2 text-end ">
              <h5 className="m-1">Video Id</h5>
            </div>
            <div className="col-10">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Video Id"
                  id="videoId"
                  name="videoId"
                  value={videoId}
                  onChange={(e) =>
                    setVideoId(e.target.value ? e.target.value : "")
                  }
                  required={(videoLink?.includes("youtube") || videoLink?.includes("youtu.be")) }
                  isValid={createValidate && videoId}
                  isInvalid={createValidate && !videoId}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter Video Id
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Row>           */}
          <Row className="mb-2 d-flex d-inline">
            <div className="mb-3 d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary btn-sm"
                disabled={buttonDisabled}
              >
                Save
              </button>

              <button
                type="button"
                className="btn btn-outline-warning btn-sm ms-2"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </Row>
          <Row>
            {apiMessage !== "" && (
              <span className={apiStatus ? "text-success " : "text-danger "}>
                {apiMessage}
              </span>
            )}
            {/*apiStatus==1 && <span className="text-success small">Education details updated successfully</span> */}
          </Row>{" "}
        </Form>
      </Modal.Body>
    </Modal>
  );

}
export default OnlineTraining;
