import axios from "axios";
import serviceConfig from "./components/Url";

const NewApi = axios.create({
  baseURL: serviceConfig().url,
});

export const getS3Url = (bucket) => serviceConfig().s3 + bucket;

export default NewApi;
