// import node module libraries
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Tab, Card, Nav, Breadcrumb } from "react-bootstrap";
import Url from "components/Url";

// import sub custom components
import CoursesTable from "./CoursesTable";

const AllCourses = () => {
  const urls = Url();
  const url1 = urls["url"];
  const [courses, setCourses] = useState();
  const [approved, setApproved] = useState();
  const [pending, setPending] = useState();
  const [underReview, setUnderReview] = useState()
  const [load, setLoad] = useState(false)

  useEffect(() => {
    const api = url1 + "getVICourseList";
    const config = {
      method: "GET",
    };
    fetch(api, config)
      .then((res) => res.json())
      .then((data) => {
        console.log(data.contentList);
        setCourses(data.contentList);
        sortCourses(data.contentList)
        setLoad(false)
      });
  }, [load]);

  const sortCourses = (data) => {
    const pendingArray = [];
    const approvedArray = [];
    const underreviewArray = [];
    data?.map((el) => {
      if (el.active_ind === 2) {
        approvedArray.push(el);
      } 
      if (el.active_ind === 0) {
        pendingArray.push(el);
      }
      if(el.active_ind === 1){
        underreviewArray.push(el)
      }
    });
    if (pendingArray) {
      setPending(pendingArray);
    }
    if (approvedArray) {
      setApproved(approvedArray);
    }
    if(underreviewArray){
      setUnderReview(underreviewArray)
    }
  };


  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">All Courses</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="#">LMS-Courses</Breadcrumb.Item>
                <Breadcrumb.Item active>All</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Tab.Container defaultActiveKey="all">
            <Card>
              {/* <Card.Header className="border-bottom-0 p-0 bg-white">
                <Nav className="nav-lb-tab">
                  <Nav.Item>
                    <Nav.Link eventKey="all" className="mb-sm-3 mb-md-0">
                      All
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="approved" className="mb-sm-3 mb-md-0">
                      Approved
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="draft" className="mb-sm-3 mb-md-0">
                      Draft
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="underReview" className="mb-sm-3 mb-md-0">
                      Under Review
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header> */}
              <Card.Body className="p-0">
                <Tab.Content>
                  <Tab.Pane eventKey="all" className="pb-4">
                    {courses ? <CoursesTable setLoad={setLoad} courses_data={courses} /> : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="approved" className="pb-4">
                    {approved ? <CoursesTable setLoad={setLoad} courses_data={approved} /> : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="draft" className="pb-4">
                    {pending ? <CoursesTable setLoad={setLoad} courses_data={pending} /> : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="underReview" className="pb-4">
                    {underReview ? <CoursesTable setLoad={setLoad} courses_data={underReview} /> : null}
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Card>
          </Tab.Container>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AllCourses;
