// import node module libraries
import { Fragment, useState, useEffect } from 'react';
import { Card, Row, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// import sub/custom components
import Question from './Question';
import QuizProgress from './QuizProgress';
import QuizPagination from './QuizPagination';
import QuizTimer from './QuizTimer';

// import data files
import { QuizData } from 'data/marketing/quiz/QuizData';

// import media files
import CourseReact from 'assets/images/course/course-react.jpg';

import Url from 'components/Url';
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const QuizStart = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(1);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = QuizData.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(QuizData.length / recordsPerPage);

  const studentTestId = props.match.params.studentTestId;
  const [testQuestion,setTestQuestion] = useState([]);
  const [studentTest,setStudentTest] = useState([]);
  const [totalQuestions,setTotalQuestions] = useState(0);
  const [answer, setAnswer] = useState();
  const [isAnswered, setIsAnswered] = useState(false);

  const [load, setLoad] = useState(false);
	const url1 = Url();
	const url = url1["url"];
  const s3 = url1.s3;
  const studentId = localStorage.getItem('studentId');
  const token = localStorage.getItem('stutoken');
   
  useEffect(() => {
    getQuestion();
	},[studentTestId , load]);	

  const getQuestion = (position) => {

    if(position) {position= "/" + position;} else {position=""}

		let apiURL =  url + "getStudentTestQuestion/" + studentId + "/" + studentTestId + position;
		Axios.get(
			apiURL,
			{headers: {
					"Content-type": "Application/json",
					"Authorization": `Bearer ${token}`
					}   
				}
		  )
		  .then((response) => {
			  //var response = response.data.virtualInternshipList;
			  //console.log(response.data);
			  setStudentTest(response.data.studentTest);
        setTestQuestion(response.data.testQuestion);
        setTotalQuestions(response.data.totalQuestions);
        setAnswer(response.data.testQuestion?.submitted_answer)
        console.log(response.totalQuestions);

			},
			(error) => {
			  var status = error.response.status
			}
		  );	    
  }//end of getQuestion function

  const saveTestResponse = () => {
    
    if(answer){ 
      let apiURL =  url + "saveTestResponse";
      let questionId = testQuestion.question_id;
      
      Axios.post(
        apiURL,
        {studentTestId, questionId, answer,userId: studentId },
        {headers: {
                "Content-type": "Application/json",
                "Authorization": `Bearer ${token}`
                }   
            }
      ).then((response) => {
          console.log(response);

          if (response.status !== 200) {
            toast.warning("Please submit the answer again",{autoClose: 1500});
            return 0;
          }
          }
      );    
    }else{
      setIsAnswered(false); 
    }

  }//end of saveTestResponse function


  const handleAnswerSelection = (e) => {
    // e.persist();
    console.log(e.target.value);

    setAnswer(e.target.value);
  };

  const handleNextClick = (e) => {
    console.log("next click");
    saveTestResponse();
    getQuestion("next");
  };

  const handlePreviousClick = (e) => {
    console.log("prev click");
    getQuestion("previous");
  };

  const handleSubmitTest = (e) => {

    saveTestResponse();

    const res = window.confirm("You will not be able to revisit the questions, Do you want to submit the test? ");

    if (res) {
      let apiURL =  url + "submitTest";
      
      Axios.post(
        apiURL,
        {studentTestId, userId: studentId },
        {headers: {
                "Content-type": "Application/json",
                "Authorization": `Bearer ${token}`
                }   
            }
        ).then((response) => {
            console.log(response);

            if (response.status == 200) {
              window.location.href = "/lms/online-training/quiz/result/" + studentTestId;
            }
            }
          );      
      } 
  };

  return (
    <Fragment>

      <Card className="mb-4">
        <Card.Body>
          {/* Question Title + Timer */}
          {testQuestion?.question_id ? 
          <Row>
          <div className="d-flex justify-content-between align-items-center border-bottom pb-3 mb-3">
            <div className="d-flex align-items-center">
              {/* quiz img */}
              {/* <Link to="#"> <img src={CourseReact} alt="" className="rounded img-4by3-lg" /></Link> */}
              <Image
                    src={s3 + "courseImages/" +studentTest?.preview_image}
                    alt="course Image"
                    className="img-4by3-lg rounded"
              />
              {/* quiz content */}
              <div className="ms-3">
                <h3 className="mb-0"><Link to="#" className="text-inherit">{studentTest?.course_name} Quiz </Link></h3>
              </div>
            </div>
            {/* Timer */}
            {/* <QuizTimer hours={0} minutes={5} seconds={55} /> */} 
          </div>

          {/* Progress */}
          <QuizProgress currentQuestion={testQuestion?.row_number} totalQuestion={totalQuestions} />

          {/* Question(s) */}
          <div className="mt-5">
            <span>Question {testQuestion?.row_number}</span>
            <Question item={testQuestion} handleAnswerSelection={handleAnswerSelection} answer={answer} />
          </div>
          </Row>
          :
          <Row>
            <div className="d-flex justify-content-between align-items-center border-bottom pb-3 mb-3">
              <div className="d-flex align-items-center">
                Question Not Found ...!!!
              </div>
            </div>
          </Row>
          }
        </Card.Body>
      </Card>

      {/* Quiz Pagination */}
      {/* <QuizPagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} /> */}
      <QuizPagination nPages={totalQuestions} 
                      currentPage={testQuestion?.row_number} 
                      setCurrentPage={setCurrentPage} 
                      handleNextClick={handleNextClick} 
                      handlePreviousClick={handlePreviousClick} 
                      handleSubmitTest={handleSubmitTest} />

      <ToastContainer />
      </Fragment>
  )
}

export default QuizStart;
