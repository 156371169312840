// import node module libraries
import React from 'react';
import { Card, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Url from '../Url';

const url1 = Url();
const url = url1["url"];
const s3 = url1["s3"];
const s3url = s3 + "blogImages/";
const BlogCardFullWidth = ({ item }) => {
	//console.log(item)
	const CategoryColors = (category) => {
		switch (category) {
			case '1':
				return 'success';
			case '2':
				return 'primary';
			case '3':
				return 'warning';
			case '4':
				return 'info';
			default:
				return 'primary';
		}
	};

	return (
		<Card className="mb-4 mt-4 shadow-lg">
			<Row className="g-0">
				{/*  Image */}
				<Link
					to={`/blog/${item.generated_url}`}
					className="col-lg-8 col-md-12 col-12 bg-cover img-left-rounded"
					style={{
						background: `url(${s3url + item.image_1000_540_scroller})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						backgroundPosition: 'top center'
					}}
				>
					<Card.Img
						variant="left"
						src={s3url + item.image_1000_540_scroller}
						className="img-fluid"
					/>
				</Link>
				<Col lg={4} md={12} sm={12}>
					{/*  Card body */}
					<Card.Body>
						<Link
							to={`/blog/${item.generated_url}`}
							className={`fs-5 mb-3 fw-semi-bold d-block text-${CategoryColors(
								item.category_id
							)}`}
						>
							{item.category_name}
						</Link>
						<h1 className="mb-2 mb-lg-4">
							<Link
								to={`/blog/${item.generated_url}`}
								className="text-inherit"
							>
								{item.content_header}
							</Link>
						</h1>
						<p> {item.preview_text} </p>
						{/*  Media content */}
						<Row className="align-items-center g-0 mt-lg-7 mt-4">
							<Col className="col-auto">
								{/*  Img  */}
								<Image
									src={s3 + item.author_image}
									alt=""
									className="rounded-circle avatar-sm me-2"
								/>
							</Col>
							{/* <Col className="col lh-1 ">
								<h5 className="mb-1">{item.author_name}</h5>
								<p className="fs-6 mb-0">{item.created_on}</p>
							</Col> */}
							{/* 
							<Col className="col-auto">
								<p className="fs-6 mb-0">{item.readinglength} Min Read</p>
							</Col>
							*/}
						</Row>
					</Card.Body>
				</Col>
			</Row>
		</Card>
	);
};

// Typechecking With PropTypes
BlogCardFullWidth.propTypes = {
	item: PropTypes.object.isRequired
};

export default BlogCardFullWidth;
