import React, { useState, useEffect, Fragment } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  Form,
  FormSelect,
  Card,
  Spinner,
  Table,
} from "react-bootstrap";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import Axios from "NewApi";
import Select from "react-select/creatable";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import ProfileLayout from "./ProfileLayout";

const MentorServices = () => {
  const [loadData, setLoadData] = useState(false);
  const [mentorService, setMentorService] = useState([]);
  const [mentorServiceDetails, setMentorServiceDetail] = useState({});
  const [showModal, setShowModal] = useState(false);

  const mentorId = localStorage.getItem("mentorId");
  const token = localStorage.getItem("admtoken") ? localStorage.getItem("admtoken") : localStorage.getItem("mentortoken");

  useEffect(() => {
    fetchData();
    setLoadData(false);
  }, [loadData]);

  const fetchData = async () => {
    var api = "getMentorServices" + "/" + mentorId;

    try {
      const response = await Axios.get(api, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-USER-ID": mentorId,
        },
      });
      // console.log(response.data.mentorService);
      setMentorService(response.data.mentorService);
    } catch (error) {
      // console.log(error);
      toast.warning("Error getting the mentor workExperience data");
    }
  }; //end of fetchData()

  const editService = async (id) => {
    var api = "getMentorServiceDetails" + "/" + id;
    try {
      const response = await Axios.get(api, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-USER-ID": mentorId,
        },
      });

      if (response.data.mentorServiceDetails) {
        setMentorServiceDetail(response.data.mentorServiceDetails);
        setShowModal(true);
      }
    } catch (error) {
      toast.warning("Error getting the mentor workExperience data");
    }
  };

  async function deleteService(id) {
    if (id && window.confirm("Are you sure? Want to delete Service detail?")) {
      const api = "deleteMentorServiceDetails/" + id;

      try {
        const response = await Axios.delete(api, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-USER-ID": mentorId,
          },
        });

        toast.success(response.data.message, { autoClose: 1000 });
        setLoadData(true);
      } catch (error) {
        toast.warning("Error getting the mentor workExperience data");
      }
    }
  }

  const addService = () => {
    setMentorServiceDetail([]);
    setShowModal(true);
  };

  return (
    <ProfileLayout>
      <Row className="mb-2 border-bottom">
        <Card className="border-0">
          <Card.Header>
            <div className="mb-2 mb-lg-0">
              <h3 className="mb-0">Service Details</h3>
            </div>
          </Card.Header>
          <Card.Body>

            <div style={{ overflowX: "auto" }}>
              {mentorService.length > 0 ? (
                <table
                  style={{ minWidth: "800px", whiteSpace: "nowrap" }}
                  className="table table-striped table-bordered table-hover"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Service Name</th>
                      <th>Duration (Mins)</th>
                      <th>Price (INR)</th>
                      <th>Discount (%)</th>
                      <th>Status</th>
                      <th>Created At</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mentorService.map((item, index) => (
                      <tr key={item.mentor_service_id}>
                        <td>{index + 1}</td>
                        <td>{item.service_name}</td>
                        <td>{item.service_duration_mins}</td>
                        <td>{item.price}</td>
                        <td>
                          {item.discount_percent
                            ? `${item.discount_percent}%`
                            : ""}
                        </td>
                        <td>
                          <span
                            className={`badge ${
                              item.active_ind === 1
                                ? "bg-success"
                                : "bg-warning"
                            }`}
                          >
                            {item.active_ind === 1 ? "Active" : "Inactive"}
                          </span>
                        </td>
                        <td>
                          {new Date(item.created_at).toLocaleDateString()}
                        </td>
                        <td>
                          <Fragment>
                            <span
                              onClick={() =>
                                deleteService(item.mentor_service_id)
                              }
                              className="float-end"
                              style={{ cursor: "pointer" }}
                            >
                              <Trash />
                            </span>
                            <span
                              onClick={() =>
                                editService(item.mentor_service_id)
                              }
                              className="float-end mx-2 pe-auto"
                              style={{ cursor: "pointer" }}
                            >
                              <PencilSquare />
                            </span>
                          </Fragment>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="mt-2 text-danger">
                  Service Details are mandatory...!!
                </div>
              )}
            </div>
            <Row>
              <Col>
                <div className="mt-3 d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={addService}
                  >
                    Add Service{" "}
                  </button>
                </div>
              </Col>
            </Row>

            {showModal ? (
              <MentorServicePopup
                setShowModal={setShowModal}
                showModal={showModal}
                setLoadData={setLoadData}
                mentorServiceDetails={mentorServiceDetails}
                token={token}
                mentorId={mentorId}
              />
            ) : null}
          </Card.Body>
        </Card>
      </Row>
    </ProfileLayout>
  );
};

/**********************************************************************************************/
/************************* MentorServicePopup Modal Component *************************************/
/**********************************************************************************************/

const MentorServicePopup = (props) => {
  const {
    setShowModal,
    showModal,
    setLoadData,
    mentorServiceDetails,
    token,
    mentorId,
  } = props;

  //const mentorServiceDetails = [];

  const [isEdit, setIsEdit] = useState(false);
  const [createValidate, setCreateValidate] = useState(false);

  const [loading, setLoading] = useState(false);

  const [degreeList, setDegreeList] = useState([]);
  const [specializationList, setSpecializationList] = useState([]);
  const [apiMessage, setApiMessage] = useState("");
  const [apiStatus, setApiStatus] = useState(0);

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [degree, setDegree] = useState("");

  const [formData, setFormData] = useState({
    id: mentorServiceDetails?.id ? mentorServiceDetails?.id : 0,
    mentor_id: mentorServiceDetails?.mentor_id
      ? mentorServiceDetails?.mentor_id
      : mentorId,
    service_name: mentorServiceDetails?.service_name
      ? mentorServiceDetails?.service_name
      : null,
    service_type: mentorServiceDetails?.service_type
      ? mentorServiceDetails?.service_type
      : null,
    service_duration_mins: mentorServiceDetails?.service_duration_mins
      ? mentorServiceDetails?.service_duration_mins
      : null,
    price: mentorServiceDetails?.price ? mentorServiceDetails?.price : null,
    discount_percent: mentorServiceDetails?.discount_percent
      ? mentorServiceDetails?.discount_percent
      : null,
    description: mentorServiceDetails?.description
      ? mentorServiceDetails?.description
      : null,
    expectations: mentorServiceDetails?.expectations
      ? mentorServiceDetails?.expectations
      : null,
    between_break: mentorServiceDetails?.between_break
      ? mentorServiceDetails?.between_break
      : null,
    active_ind: mentorServiceDetails?.active_ind
      ? mentorServiceDetails?.active_ind
      : null,
  });

  //   useEffect(() => {

  //     setLoading(true);
  //     setIsEdit(!!mentorServiceDetails.id);
  //     if(mentorServiceDetails?.id)
  //         setFormData(...mentorServiceDetails);

  //     setLoading(true);

  //   }, [mentorServiceDetails]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const saveMentorServiceDetails = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setCreateValidate(true);
      return;
    }
    try {
      const creapi = "saveMentorServiceDetails";
      const response = await Axios.post(
        creapi,
        {
          ...formData,
          mentor_id: formData.mentor_id ? formData.mentor_id : mentorId,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-USER-ID": mentorId,
          },
        }
      );

      setApiMessage(response.data.message);
      setApiStatus(response.data.status);

      if (!formData.id && response.data.status == 1) {
        setButtonDisabled(true);
      }

      if (response.data.status == 1) setLoadData(true);
    } catch (error) {
      console.error(error);
      // Handle different types of errors
      if (error.response) {
        // The server responded with a status code outside the 2xx range
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);

        // Example: set an error message based on the status code or error response
        if (error.response.status === 400) {
          setApiMessage("Validation error: Please check your input.");
        } else {
          setApiMessage("An unexpected error occurred. Please try again.");
        }
        setApiStatus(error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        // console.log(error.request);
        setApiMessage(
          "No response from the server. Please check your network connection."
        );
      } else {
        // Something happened in setting up the request that triggered an error
        // console.log("Error", error.message);
        setApiMessage("An error occurred. Please try again.");
      }
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setLoadData(true);
  };
  return (
    <Modal show={showModal} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          {isEdit ? "Edit Service Details" : "Add Service Details"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <>
            Loading Data...Please wait {} <Spinner />
          </>
        ) : (
          <Form noValidate onSubmit={saveMentorServiceDetails}>
            <Row>
              <Col sm={12} className="mb-2">
                <Form.Group className="mb-2" controlId="service_name">
                  <Form.Label>Mentorship Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Service Name"
                    name="service_name"
                    value={formData.service_name}
                    onChange={handleChange}
                    required
                    isValid={createValidate && formData.service_name}
                    isInvalid={createValidate && !formData.service_name}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} className="mb-2">
                <Form.Group className="mb-2" controlId="description">
                  <Form.Label>Description (3-4 Lines)</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    required
                    isValid={createValidate && formData.description}
                    isInvalid={createValidate && !formData.description}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} className="mb-2">
                <Form.Group className="mb-2" controlId="expectations">
                  <Form.Label>
                    Expectations from the mentorship (3-4 Lines)
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Expectations from the mentorship"
                    name="expectations"
                    value={formData.expectations}
                    onChange={handleChange}
                    required
                    isValid={createValidate && formData.expectations}
                    isInvalid={createValidate && !formData.expectations}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {/* First name */}
              {/* <Col md={6} sm={12} className="mb-2">
                <Form.Group className="mb-2" controlId="service_type">
                  <Form.Label>Mentorship Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Service Type"
                    name="service_type"
                    value={formData.service_type}
                    onChange={handleChange}
                    required
                    isValid={createValidate && formData.service_type}
                    isInvalid={createValidate && !formData.service_type}                              
                  />
                </Form.Group>
              </Col> */}
              {/* Phone */}
              <Col md={12} sm={12} className="mb-2">
                <Form.Group
                  className="mb-2"
                  controlId="formservice_duration_mins"
                >
                  <Form.Label>Mentorship Duration (Mins) </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Duration in minutes"
                    name="service_duration_mins"
                    value={formData.service_duration_mins}
                    onChange={handleChange}
                    required
                    isValid={createValidate && formData.service_duration_mins}
                    isInvalid={
                      createValidate && !formData.service_duration_mins
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={12} sm={12} className="mb-2">
                <Form.Group className="mb-2" controlId="formbetween_break">
                  <Form.Label>Break Between (Mins) (Idle time between 2 consecutive sessions) </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Break Between sessions"
                    name="between_break"
                    value={formData.between_break}
                    onChange={handleChange}
                    required
                    isValid={createValidate && formData.between_break}
                    isInvalid={createValidate && !formData.between_break}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12} className="mb-2">
                <Form.Group className="mb-2" controlId="formprice">
                  <Form.Label>Mentorship service price </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Mentorship Service Price"
                    name="price"
                    value={formData.price}
                    onChange={handleChange}
                    required
                    isValid={createValidate && formData.price}
                    isInvalid={createValidate && !formData.price}
                  />
                </Form.Group>
              </Col>
              <Col md={6} sm={12} className="mb-2">
                <Form.Group className="mb-2" controlId="formdiscount_percent">
                  <Form.Label>Discount% </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Discount %"
                    name="discount_percent"
                    value={formData.discount_percent}
                    onChange={handleChange}
                    required
                    isValid={createValidate && formData.discount_percent}
                    isInvalid={createValidate && !formData.discount_percent}
                  />
                </Form.Group>
              </Col>
              <Col md={6} sm={12} className="mb-2">
                <Form.Group className="mb-2" controlId="formActiveInd">
                  <Form.Check
                    type="checkbox"
                    label="Is Active?"
                    name="active_ind"
                    checked={formData.active_ind}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              {/* Button */}
              <Col sm={12} md={12}>
                <Button variant="primary" type="submit">
                  Save Profile {loading && <Spinner></Spinner>}
                </Button>
              </Col>
            </Row>
            <Row>
              {apiMessage !== "" && (
                <span
                  className={
                    "mt-2 " + (apiStatus ? "text-success " : "text-danger ")
                  }
                >
                  {apiMessage}
                </span>
              )}
              {/*apiStatus==1 && <span className="text-success small">Education details updated successfully</span> */}
            </Row>{" "}
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MentorServices;
