import { v4 as uuid } from "uuid";

// import media files
import StripeLogo from "assets/images/brand/gray-logo-stripe.svg";
import AirbnbLogo from "assets/images/brand/gray-logo-airbnb.svg";
import DiscordLogo from "assets/images/brand/gray-logo-discord.svg";
import IntercomLogo from "assets/images/brand/gray-logo-intercom.svg";
import PinterestLogo from "assets/images/brand/gray-logo-pinterest.svg";
import NetflixLogo from "assets/images/brand/gray-logo-netflix.svg";
import udemy from "assets/images/brand/logo/Udemy.png";
import youtube from "assets/images/brand/logo/Youtube.png";
import johnacademy from "assets/images/brand/logo/John academy - transparent.png";
import edureka from "assets/images/brand/logo/Edureka - transparent.png";
import eduonix from "assets/images/brand/logo/Eduonix - transparent.png";
const LogoList2 = [
	{
		id: uuid(),
		logoimage: udemy,
	},
	// {
	// 	id: uuid(),
	// 	logoimage: youtube,
	// },
	{
		id: uuid(),
		logoimage: johnacademy,
	},
	{
		id: uuid(),
		logoimage: edureka,
	},
	{
		id: uuid(),
		logoimage: eduonix,
	},
];

export default LogoList2;
