import React from 'react'
import { Button } from 'react-bootstrap'

const Pagination = (props) => {
    const { nPages, 
            currentPage, 
            setCurrentPage, 
            handleNextClick, 
            handlePreviousClick,
            handleSubmitTest } = props;

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
    // const nextPage = () => {
    //         if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    // }
    // const prevPage = () => {
    //     if(currentPage !== 1) setCurrentPage(currentPage - 1)
    // }
    return (
        <div className="d-flex justify-content-between">
          <button className="btn btn-secondary" disabled={currentPage == 1} onClick={handlePreviousClick} >
            <i className="fe fe-arrow-left"></i> Previous
          </button>
          {nPages !== currentPage ?
          (
          <button className="btn btn-primary" onClick={handleNextClick}>
            Next <i className="fe fe-arrow-right"></i>
          </button>
          ) : (
          <button className="btn btn-primary" onClick={handleSubmitTest}>
            Submit Test <i className="fe fe-check"></i>
          </button>
          )
          }
        </div>
        
    )
}

export default Pagination