import React, { useState, useEffect, Fragment } from "react";
import { Col, Row, Button, Modal, Form, FormSelect } from "react-bootstrap";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import Url from "components/Url";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Internships = (props) => {
  const {
    studentInternships,
    setLoadData,
    applicantId,
    locationList,
    employerView,
  } = props;

  const [internshipDetail, setInternshipDetail] = useState({});
  const [showInternshipModal, setShowInternshipModal] = useState(false);

  const url1 = Url();
  const url = url1["url"];
  const s3URL = url1["s3"];

  function deleteInternship(id) {
    if (
      id &&
      window.confirm("Are you sure? Want to delete Internship detail?")
    ) {
      const api = url + "deleteApplicantInternshipById/" + id;
      const config = {
        method: "DELETE",
      };
      fetch(api, config)
        .then((res) => res.json())
        .then((data) => {
          if (data.message === "Record deleted") {
            alert("Internship Details Deleted Successfuly");
            setLoadData(true);
          }
        });
    }
  }
  function editInternship(id) {
    //console.log("editInternship" + id);
    const api1 = url + "getApplicantInternshipById/" + id;
    fetch(api1, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        setInternshipDetail(actualdata1);
      });
    setShowInternshipModal(true);
  }

  const addInternship = () => {
    setInternshipDetail([]);
    setShowInternshipModal(true);
  };

  return (
    <Row className="mb-2 border-bottom">
      <Col md={3}>
        <span className="text-dark">Internships:</span>
      </Col>
      <Col md={9}>
        {studentInternships.length > 0 ? (
          studentInternships.map((item, index) => (
            <Col className="ml-2" key={index}>
              <div className="h5">
                {item.int_profile}
                {!employerView && (
                  <Fragment>
                    <span
                      onClick={() => deleteInternship(item.id)}
                      className="float-end"
                      style={{ cursor: "pointer" }}
                    >
                      <Trash />
                    </span>
                    <span
                      onClick={() => editInternship(item.id)}
                      className="float-end mx-2 pe-auto"
                      style={{ cursor: "pointer" }}
                    >
                      <PencilSquare />
                    </span>
                  </Fragment>
                )}
              </div>
              <div>
                {item.int_org_name}, {item.int_location}
              </div>
              <div className="">
                From {item.int_start_date} to{" "}
                {item.int_current_status === "Currently working here"
                  ? "Present"
                  : item.int_end_date}
              </div>
              <div className="mb-4 text-justify">{item.int_description}</div>
            </Col>
          ))
        ) : (
          <div className="my-3">No internship details.....</div>
        )}
        {!employerView && (
          <Row>
            <Col>
              <div className="mb-3 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={addInternship}
                >
                  Add Internship{" "}
                </button>
              </div>
            </Col>
          </Row>
        )}
      </Col>

      {showInternshipModal ? (
        <InternshipPopup
          setShowInternshipModal={setShowInternshipModal}
          showInternshipModal={showInternshipModal}
          setLoadData={setLoadData}
          internshipDetail={internshipDetail}
          locationList={locationList}
        />
      ) : null}
    </Row>
  );
};

/**********************************************************************************************/
/************************* InternshipPopup Modal Component ************************************/
/**********************************************************************************************/

const InternshipPopup = (props) => {
  const {
    setShowInternshipModal,
    showInternshipModal,
    setLoadData,
    internshipDetail,
    locationList,
  } = props;

  const url1 = Url();
  const url = url1["url"];

  //const internshipDetail = [];

  const [isEdit, setIsEdit] = useState(false);

  const [show, setShow] = useState(props.show);

  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [int_profile, setInt_profile] = useState("");
  const [int_org_name, setInt_org_name] = useState("");
  const [int_location, setInt_location] = useState("");
  const [int_start_date, setInt_start_date] = useState("");
  const [int_end_date, setInt_end_date] = useState("");
  const [int_current_status, setInt_current_status] = useState(false);
  const [int_description, setInt_description] = useState("");

  const [apiMessage, setApiMessage] = useState("");
  const [apiStatus, setApiStatus] = useState(0);

  const [currentDate, setCurrentDate] = useState(new Date());

  const [checkedint, setCheckedint] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [createValidate, setCreateValidate] = useState(false);

  const handleClickint = () => {
    setCheckedint(!checkedint);
    if (!checkedint) {
      setInt_current_status("Currently working here");
      setInt_end_date("");
    } else {
      setInt_current_status("NO");
    }
  };

  useEffect(() => {
    if (internshipDetail.id) {
      setIsEdit(true);

      setId(internshipDetail.id);
      setEmail(
        internshipDetail.email
          ? internshipDetail.email
          : localStorage.getItem("email")
      );

      setInt_profile(internshipDetail.int_profile);
      setInt_org_name(internshipDetail.int_org_name);
      setInt_location(internshipDetail.int_location);
      setInt_start_date(internshipDetail.int_start_date);
      setInt_end_date(internshipDetail.int_end_date);
      console.log(internshipDetail.int_current_status);
      setInt_current_status(internshipDetail.int_current_status);

      if (internshipDetail.int_current_status == "Currently working here") {
        setCheckedint(true);
      } else {
        setCheckedint(false);
      }

      setInt_description(internshipDetail.int_description);
    }
  }, [internshipDetail]);

  const handleClose = () => {
    setShowInternshipModal(false);
    setLoadData(true);
  };

  const saveInternshipDetails = (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setCreateValidate(true);
      return;
    }

    const applicantId = localStorage.getItem("applicantid");
    const email = localStorage.getItem("email");

    const creapi = url + "updateApplicantInternship";
    fetch(creapi, {
      method: "POST",
      body: JSON.stringify({
        id,
        email,
        int_profile,
        int_org_name,
        int_location,
        int_start_date,
        int_end_date,
        int_current_status,
        int_description,
        applicantId,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((apidata) => {
        return apidata.json();
      })
      .then((actualdata) => {
        setApiMessage(actualdata["message"]);
        setApiStatus(actualdata["status"]);
        if (!id && actualdata["status"] == 1) {
          setButtonDisabled(true);
        }
      });
    return;
  };

  return (
    <Modal show={showInternshipModal} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          {isEdit ? "Edit Internship Details" : "Add Internship Details"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={saveInternshipDetails}>
          <Row className="mb-2">
            <div className="col-4 text-end ">
              <span className="h5 m-1">Job Profile</span>
            </div>
            <div className="col-8">
              <Form.Control
                type="text"
                placeholder="e.g. Operations"
                id="int_profile"
                name="int_profile"
                value={int_profile}
                onChange={(e) => setInt_profile(e.target.value)}
                required
                isValid={createValidate && int_profile}
                isInvalid={createValidate && !int_profile}
              />
              <Form.Control.Feedback type="invalid">
                Please enter job profile.
              </Form.Control.Feedback>
              <Form.Control.Feedback type="valid">
                Looks good!
              </Form.Control.Feedback>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-4 text-end ">
              <span className="h5 m-1">Organization</span>
            </div>
            <div className="col-8">
              <Form.Control
                type="text"
                placeholder="e.g. Internshipgate"
                id="int_org_name"
                name="int_org_name"
                value={int_org_name}
                onChange={(e) => setInt_org_name(e.target.value)}
                required
              />
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-4 text-end ">
              <span className="h5 m-1">Location</span>
            </div>

            <div className="col-8">
              <Form.Control
                as={FormSelect}
                placeholder="Select Location"
                name="int_location"
                defaultValue={int_location}
                onChange={(e) => setInt_location(e.target.value)}
                required
                //options={salutationOptions}
              >
                {locationList.map((item, index) => (
                  <option value={item.placename} key={index}>
                    {item.placename}
                  </option>
                ))}
              </Form.Control>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-4 text-end ">
              <h5 className="m-1">Start Date</h5>
            </div>
            <div className="col-8 d-md-flex">
              <Form.Group>
                <Form.Control
                  type="date"
                  size="sm"
                  placeholder="Enter Collage Name"
                  className="expected-y"
                  onFocus={(e) => e.target.select()}
                  value={int_start_date}
                  onChange={(e) => setInt_start_date(e.target.value)}
                  max={currentDate}
                  required
                />
              </Form.Group>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-4 text-end ">
              <h5 className="m-1">End Date</h5>
            </div>

            <div className="col-8 d-md-flex">
              {!checkedint ? (
                <Form.Group>
                  <Form.Control
                    type="date"
                    size="sm"
                    placeholder="End date"
                    className="expected-y"
                    onFocus={(e) => e.target.select()}
                    value={int_end_date}
                    onChange={(e) => setInt_end_date(e.target.value)}
                    max={currentDate}
                    required
                  />
                </Form.Group>
              ) : (
                "Currently work here"
              )}
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-4 text-end ">
              <h5 className="m-1">Currently working here</h5>
            </div>
            <div className="col-8">
              <Form.Check
                className="option radiotype1 "
                type="checkbox"
                name="int_current_status"
                onClick={handleClickint}
                checked={checkedint}
                // onChange={(e) => {
                //   handleClickint;
                // }}
                label={false}
              />
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-4 text-end ">
              <h5 className="m-1">Description</h5>
            </div>
            <div className="col-8">
              <Form.Control
                placeholder="short discription max -500"
                as="textarea"
                rows={3}
                name="int_description"
                onFocus={(e) => e.target.select()}
                value={int_description}
                onChange={(e) => setInt_description(e.target.value)}
                required
              />
            </div>
          </Row>
          <Row className="mb-2 d-flex d-inline">
            <div className="mb-3 d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary btn-sm"
                disabled={buttonDisabled}
              >
                Save
              </button>

              <button
                type="button"
                className="btn btn-outline-warning btn-sm ms-2"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </Row>
          <Row>
            {apiMessage !== "" && (
              <span className={apiStatus ? "text-success " : "text-danger "}>
                {apiMessage}
              </span>
            )}
            {/*apiStatus==1 && <span className="text-success small">Internship details updated successfully</span> */}
          </Row>{" "}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Internships;
