// import node module libraries
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Image, Container, ListGroup } from "react-bootstrap";

import { useState, useEffect } from "react";

// import MDI icons
import Icon from "@mdi/react";
import { mdiFacebook, mdiLinkedin, mdiInstagram, mdiYoutube } from "@mdi/js";

// import media files
import FooterLogo from "assets/images/brand/logo/logoend.svg";
import AppStore from "assets/images/svg/appstore.svg";
import PlayStore from "assets/images/svg/playstore.svg";

import fb from "assets/images/brand/logo/fb.svg";
import insta from "assets/images/brand/logo/insta.svg";
import linkedin from "assets/images/brand/logo/linkedin.svg";
import yt from "assets/images/brand/logo/yt.svg";

import "./Footer.css";

const InternshipgateFooter = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);

  return (
    <Fragment>
      {/*  footer */}
      <section
        className="pt-lg-10 pt-5 footer"
        style={{ backgroundColor: "#2e3a97", color: "#ffffff" }}
      >
        <Container>
          <Row>
            <Col lg={4} md={6} sm={12}>
              {/* about company  */}
              <div className="mb-4">
                <Image src={FooterLogo} style={{ width: "250px" }} alt="" />
                <div className="mt-2">
                  {/* social media */}
                  <div className="fs-4 mt-2">
                    <a
                      href="https://www.facebook.com/Internshipgate-104735634814921"
                      target="_blank"
                      className="mdi mdi-facebook text-muted me-2"
                    >
                      <img src={fb} className="linkedin m-2" alt="fb" />
                    </a>
                    <a
                      href="https://www.instagram.com/internshipgate/"
                      target="_blank"
                      className="mdi mdi-twitter text-muted me-2"
                    >
                      <img src={insta} className="linkedin m-2" alt="insta" />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCyiZAORDsCJyIzTmqLZw6TA"
                      target="_blank"
                      className="mdi mdi-instagram text-muted "
                    >
                      <img src={yt} className="linkedin m-2" alt="youtube" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/71005402/admin/"
                      target="_blank"
                      className="mdi mdi-instagram text-muted"
                    >
                      <img
                        src={linkedin}
                        className="linkedin m-3"
                        alt="linkedin"
                      />
                    </a>
                  </div>
                  <div className="col-sm-5 col-12 pt-3">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.internshipgate.igapp&pcampaignid=web_share"
                      className="btn btn-sm"
                      style={{ padding: 0, margin: 0 }}
                    >
                      <Image
                        className="img-fluid rounded " style={{width:"160px", height:"50px"}}
                        src="https://internshipgates3.s3.ap-south-1.amazonaws.com/logos/google-play.png"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={3} md={3} sm={6}>
              <div className="mb-4">
                {/* list */}
                <h3 className="fw-bold mb-3 text-white">INTERNSHIPGATE</h3>
                <ListGroup
                  as="ul"
                  bsPrefix="list-unstyled"
                  className="nav nav-footer flex-column nav-x-0"
                >
                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a href="/AboutUs" alt="about" className="allLink">
                      About Us
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a href="/virtual-internship" className="allLink">
                      Virtual Internship
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a href="/mentorship" className="allLink">
                      Mentorship
                    </a>
                  </ListGroup.Item>
                  {/* <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a
                      href="/OnlineTraining"
                      alt="Affilate"
                      className="allLink"
                    >
                      Online Training
                    </a>
                  </ListGroup.Item> */}
                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a href="/ContactUs" alt="contact" className="allLink">
                      Contact Us
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a
                      href="/Disclaimer"
                      alt="disclaimer"
                      className="allLink"
                      target="_blank"
                    >
                      Disclaimer
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a
                      href="/Privacy"
                      alt="privacy"
                      className="allLink"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a
                      href="/Terms"
                      alt="terms"
                      className="allLink"
                      target="_blank"
                    >
                      Terms of Service
                    </a>
                  </ListGroup.Item>

                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a href="/Sites" className="allLink">
                      Site map
                    </a>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Col>
            <Col lg={3} md={3} sm={6}>
              <div className="mb-4">
                {/* list  */}
                <h3 className="fw-bold mb-3 text-white">CATEGORIES</h3>
                <ListGroup
                  as="ul"
                  bsPrefix="list-unstyled"
                  className="nav nav-footer flex-column nav-x-0"
                >
                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a
                      alt="Design"
                      className="allLink"
                      href="/Internship/Design"
                    >
                      Design Internship
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a
                      alt="coding"
                      className="allLink"
                      href="/Internship/Coding,Development"
                    >
                      Coding Internship
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a
                      alt="Business"
                      className="allLink"
                      href="/Internship/Business Analytics"
                    >
                      Business Analytics Internship
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a
                      alt="Digital"
                      className="allLink"
                      href="/Internship/Digital Marketing"
                    >
                      Digital Marketing Internship
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a
                      alt="Finance"
                      className="allLink"
                      href="/Internship/Finance"
                    >
                      Finance Internship
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a
                      alt="Content"
                      className="allLink"
                      href="/Internship/Content Writing"
                    >
                      Content Writing Internship
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a
                      alt="Machine"
                      className="allLink"
                      href="/Internship/Marketing"
                    >
                      Marketing Internship
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a alt="Blogs" className="allLink" href="/blogs">
                      Blogs
                    </a>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Col>
            <Col lg={2} md={12} sm={12}>
              <div className="mb-4">
                {/* list  */}
                <h3 className="fw-bold mb-3 text-white">LOCATIONS</h3>
                <ListGroup
                  as="ul"
                  bsPrefix="list-unstyled"
                  className="nav nav-footer flex-column nav-x-0"
                >
                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a
                      alt="banglore"
                      href="/Internship/internships-in-bangalore"
                      className="allLink"
                    >
                      Internship in Bangalore
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a
                      alt="pune"
                      href="/Internship/internships-in-pune"
                      className="allLink"
                    >
                      Internship in Pune
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a
                      alt="Mumbai"
                      href="/Internship/internships-in-mumbai"
                      className="allLink"
                    >
                      Internship in Mumbai
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a
                      alt="delhi"
                      href="/Internship/internships-in-delhi"
                      className="allLink"
                    >
                      Internship in Delhi NCR
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a
                      alt="kolkata"
                      className="allLink"
                      href="/Internship/internships-in-kolkata"
                    >
                      Internship in Kolkata
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="mb-3">
                    <a
                      alt="Hyderabad"
                      className="allLink"
                      href="/Internship/internships-in-hyderabad"
                    >
                      Internship in Hyderabad
                    </a>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section
        className="py-lg-4 pt-2 footer"
        style={{ backgroundColor: "#1a2577", color: "#ffffff" }}
      >
        <Container>
          <Row className="align-items-center g-0">
            {/* Desc  */}
            <Col lg={6} md={6} sm={12}>
              <span>
                @Copyright {year} - INTERNSHIPGATE, A Unit of ISAN DATA SYSTEMS
              </span>
            </Col>
            {/*  Links  */}
            <Col
              lg={6}
              md={6}
              sm={12}
              className="d-md-flex justify-content-end"
            >
              <nav className="nav nav-footer">
                {/*
								<Link className="allLink ps-0" href="https://www.isandatasystems.com/">
									Powered by ISAN DATA SYSTEMS
								</Link>
								*/}
                <span>Powered by ISAN DATA SYSTEMS</span>
              </nav>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default InternshipgateFooter;
