import React from "react";
import Select, { components } from "react-select";

const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};

const Filter = ({ name, options, placeholder, className = "", onChange }) => {
  return (
    <Select
      isMulti
      name={name}
      options={options}
      closeMenuOnSelect={false}
      components={{ Placeholder }}
      placeholder={placeholder}
      className={className}
      onChange={onChange}
    />
  );
};

export default Filter;
