import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Form } from "react-bootstrap";
import Url from "components/Url";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PageSEOSettings = () => {
  const url1 = Url();
  const url = url1["url"];
  const [allSeo, setAllSeo] = useState([]);
  const [editmodal, setEditmodal] = useState(false);
  const [id, setId] = useState("");
  const [page_name, setPageName] = useState("");
  const [page_title, setPageTitle] = useState("");
  const [page_meta_keyword, setMetaKeyword] = useState("");
  const [page_meta_description, setMetaDescription] = useState("");
  const [page_canonical_url, setCanonicalURL] = useState("");
  const [isNew, setIsnew] = useState(false);
  const [isSent, setIssent] = useState(false);

  toast.configure();

  const handleClose1 = () => {
    setEditmodal(false);
    setPageName("");
    setPageTitle("");
    setMetaKeyword("");
    setMetaDescription("");
  };

  useEffect(() => {
    const api = url + "getPageSeoSettings";
    fetch(api, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        setAllSeo(actualdata1);
      });
    setIssent(false);
  }, [isSent]);

  const getPageSEO = (id) => {
    const api = url + "getPageSeoSettingById/" + id;
    fetch(api, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        setId(actualdata1.id);
        setPageName(actualdata1.page_name);
        setPageTitle(actualdata1.page_title);
        setMetaKeyword(actualdata1.page_meta_keyword);
        setMetaDescription(actualdata1.page_meta_description);
        setCanonicalURL(actualdata1.page_canonical_url);
      });
  };

  const submitSEO = () => {
    if (isNew) {
      var resp;
      const api = url + "updatePageSeoSetting";
      fetch(api, {
        method: "POST",
        body: JSON.stringify({
          id: null,
          page_name,
          page_title,
          page_meta_keyword,
          page_meta_description,
          page_canonical_url,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((apidata) => {
          resp = apidata.status;
          return apidata.json();
        })
        .then((apidata1) => {
          if (resp === 200 || resp === 201) {
            setEditmodal(false);
            setIssent(true);
            toast.success("SEO keywords added successfully....", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            });
          }
        });
    } else {
      var resp;
      const api = url + "updatePageSeoSetting";
      fetch(api, {
        method: "POST",
        body: JSON.stringify({
          id,
          page_name,
          page_title,
          page_meta_keyword,
          page_meta_description,
          page_canonical_url,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((apidata) => {
          resp = apidata.status;
          return apidata.json();
        })
        .then((apidata1) => {
          if (resp === 200 || resp === 201) {
            setEditmodal(false);
            setIssent(true);
            toast.success("SEO updated successfully....", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            });
          }
        });
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={editmodal}
        onHide={handleClose1}
      >
        <Modal.Header closeButton>
          <Modal.Title> SEO SETTINGS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row><h5 className="m-1">Page Name</h5></Row>
          <Row className="mb-3">
            <Form.Control
              type="textarea"
              onChange={(e) => setPageName(e.target.value)}
              value={page_name}
            />
          </Row>
          <Row><h5 className="m-1">Page Title</h5></Row>
          <Row className="mb-3">
            <Form.Control
              type="textarea"
              onChange={(e) => setPageTitle(e.target.value)}
              value={page_title}
            />
          </Row>
          <Row><h5 className="m-1">Meta Keywords</h5></Row>
          <Row className="mb-3">
            <Form.Control
              as="textarea"
              rows="3"
              onChange={(e) => setMetaKeyword(e.target.value)}
              value={page_meta_keyword}
            />
          </Row>
          <Row><h5 className="m-1">Meta Description</h5></Row>
          <Row className="mb-4">
            <Form.Control
              as="textarea"
              rows="3"
              onChange={(e) => setMetaDescription(e.target.value)}
              value={page_meta_description}
            />
          </Row>
          <button className="btn btn-primary btn-sm ml-3" onClick={submitSEO}>
            SAVE
          </button>
        </Modal.Body>
      </Modal>

      <Row className="mt-5"></Row>

      <Row className="mt-5">
        <span className="ml-0">
          <h3 align="center">
            <b>SEO Settings</b>
          </h3>
        </span>

        <table
          className="table table-striped tabledata"
          style={{ marginTop: 50, marginLeft: "auto", marginRight: "auto" }}
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>Page Name</th>
              <th>Title</th>
              <th>Keywords</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody className="">
            {allSeo.length > 0
              ? allSeo.map((el) => (
                  <>
                    <tr>
                      <td
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        className="text-primary"
                        onClick={() => {
                          getPageSEO(el.id);
                          setEditmodal(true);
                          setIsnew(false);
                        }}
                      >
                        {el.id}
                      </td>
                      <td
                        style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          className="text-primary"
                          onClick={() => {
                            getPageSEO(el.id);
                            setEditmodal(true);
                            setIsnew(false);
                          }}                      
                      >{el.page_name}</td>
                      <td>{el.page_title}</td>
                      <td>{el.page_meta_keyword}</td>
                      <td>{el.page_meta_description}</td>
                    </tr>
                  </>
                ))
              : null}
          </tbody>
        </table>
      </Row>
    </>
  );
};

export default PageSEOSettings;
