import React, { useEffect, useRef, useState, createRef } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  Svg,
  Path,
  Circle,
  Polyline,
} from "@react-pdf/renderer";
import { Html } from "react-pdf-html";
import {
  useA4Scale,
  TemplatePage,
  renderHTMLContent,
  getDisplayDate,
} from "../../utils";
import { Row, Col } from "react-bootstrap";
import DummyProfilePic from "../../../../../assets/images/background/profilepic.png";
import { FiPhone } from "react-icons/fi";
import { isEmpty } from "../../../foundation";
import { green } from "@material-ui/core/colors";

Font.registerHyphenationCallback((word) => {
  // Return entire word as unique part
  return [word];
});

const styles = StyleSheet.create({
  page: { display: "flex", fontFamily: "Roboto", paddingVertical: "30px" },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  left: {
    //backgroundColor: "red",//#3644a1
    width: "228px",
  },
  right: {
    padding: "3px 63px 25px 22px", //26px 30px 0px 20px
    width: "367px",
  },
  contentPadding: {
    paddingLeft: "30px",
    paddingRight: "54px",
  },
  profileImageWrapper: {
    display: "flex",
    alignItems: "center",
    padding: "30px",
  },
  profileImage: {
    width: "122px",
    height: "122px",
    borderRadius: "50%",
    border: "1px solid #3644a1",
  },
  courses: {
    paddingLeft: "42px",
  },
});

const ContactInfo = ({ data: { Phone, Country, display_email } }) => (
  <View style={{ padding: "12px", marginTop: "4px" }}>
    {/* <Text
      style={{
        color: "black",//#ffffff
        fontWeight: 700,
        fontSize: "11px",
        marginBottom: "10px",
      }}
    >
      CONTACT ME
    </Text> */}
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "5px",
        marginTop: "5px",
      }}
    >
      <View
        style={{
          //backgroundColor: "#f1bb1a",
          padding: "2px",
          //marginTop:"5px",
          borderRadius: "50%",
          flex: "0 0 16px",
        }}
      >
        <Svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="15px"
          width="15px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></Path>
        </Svg>
      </View>
      <Text
        style={{
          color: "#242732",
          fontSize: "10px",
          paddingLeft: "13px",
        }}
      >
        {Phone}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "5px",
        marginTop: "20px",
      }}
    >
      <View
        style={{
          // backgroundColor: "#f1bb1a",
          padding: "3px",
          borderRadius: "50%",
          flex: "0 0 16px",
        }}
      >
        <Svg
          stroke="#000000"
          fill="#000000"
          strokeWidth="2"
          viewBox="0 0 20 20"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="10px"
          width="12px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></Path>
          <Polyline points="22,6 12,13 2,6"></Polyline>
        </Svg>
      </View>
      <Text
        style={{
          color: "#242732",
          fontSize: "10px",
          paddingLeft: "13px",
        }}
      >
        {display_email}
      </Text>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: "10px",
      }}
    >
      <View
        style={{
          // backgroundColor: "#f1bb1a",
          padding: "3px",
          borderRadius: "50%",
          flex: "0 0 16px",
        }}
      >
        <Svg
          stroke="#000000"
          fill="#000000"
          strokeWidth="2"
          viewBox="0 0 20 20"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="40px"
          width="12px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></Path>
          <Circle fill="#000000" cx="12" cy="10" r="3"></Circle>
        </Svg>
      </View>
      <Text
        style={{
          color: "#242732", //#ffffff
          fontSize: "10px",
          paddingLeft: "13px",
        }}
      >
        {Country}
      </Text>
    </View>
  </View>
);

const Skills = ({ data: { skills,template_color_code } }) => {
  if (!skills || !skills.length) {
    return <View />;
  }
  return (
    <View
      style={{
        padding: "8px 32px",
        marginTop: "10px",
        paddingLeft: "42px",
      }}
    >
      <Text
        style={{
          color: "#242732",
          fontWeight: "bold",
          fontSize: "13px",
          marginBottom: "10px",
          letterSpacing: "1.95px",
          fontstretch: "normal",
        }}
      >
        SKILLS
      </Text>
      {skills.map((s) => (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent:"space-between",
          }}
          wrap={false}
        >
          <Text
            style={{
              color: "#242732",
              fontSize: "11px",
              paddingBottom: "5px",
              textAlign:"left",
               width:"250px",
            }}
          >
            {s.skill_name}
          </Text>
          <View
            style={{
              width: "175px",
              height: "9px",
              marginBottom: "5px",
              marginTop:"5px",
              marginRight:"-15px",
              alignItems:"flex-start",
              border:"1px solid black",
              borderRadius:"5px",
          }}
          >
            <View
              style={{
                backgroundColor:template_color_code,
                height:"7px",
                width: `${s.level * 22.5}px`,
                marginRight:"20px",
              }}
            ></View>
          </View>
        </View>
      ))}
    </View>
  );
};

const Hobbies = ({ data: { hobbies } }) => {
  if (!hobbies || !hobbies.length) {
    return <View />;
  }
  return (
    <View
      style={{
        padding: "8px 32px",
        paddingLeft: "42px",
        // border: "2px solid red"
      }}
    >
      <Text
        style={{
          color: "#242732",
          //textDecoration: "underline",
          fontSize: "13px",
          fontWeight: "bold",
          letterSpacing: "1.95px",
          marginBottom: "10px",
        }}
      >
        HOBBIES
      </Text>

      {hobbies.map((s) => (
        <Text
          style={{
            color: "#242732",
            fontSize: "11px",
          }}
        >
          {s.hobbies}
        </Text>
      ))}
    </View>
  );
};

const Languages = ({ data: { languages } }) => {
  if (!languages || !languages.length) {
    return <View />;
  }
  return (
    <View
      style={{
        padding: "8px 32px",
        paddingLeft: "42px",
        // border: "2px solid yellow"
      }}
    >
      <Text
        style={{
          color: "#242732",
          //textDecoration: "underline",
          fontSize: "13px",
          fontWeight: "bold",
          marginBottom: "10px",
          letterSpacing: "1.95px",
        }}
      >
        LANGUAGES
      </Text>
      {languages.map((s) => (
        
          <Text
            style={{
              color: "#242732",
              fontSize: "11px",
              paddingBottom: "10px",
              // paddingRight: "5px",
            }}
          >
            {s.language_name}
          </Text>
          
        
      ))}
    </View>
  );
};

const References = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const { title, subTitle, phoneNo, emailId } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View style={{ marginTop: "15px" }}>
      <Text
        style={{
          fontSize: "13px",
          fontWeight: "bold",
          color: "#242732",
          letterSpacing: "1.95px",
          //textDecoration: "underline",
        }}
      >
        {heading}
      </Text>
      {items.map((item) => (
        <View style={{ marginTop: "5px" }}>
          <Text style={{ fontSize: "11px", fontWeight: "500" }}>
            {item[title]}
          </Text>
          <Text
            style={{ fontSize: "11px", color: "#242732", marginTop: "3px" }}
          >
            {item[subTitle]}
          </Text>
          <Text
            style={{ fontSize: "10px", color: "#242732", marginTop: "3px" }}
          >
            {item[phoneNo]}
          </Text>
          <Text
            style={{ color: "#242732", marginTop: "3px", fontSize: "10px" }}
          >
            {item[emailId]}
          </Text>
        </View>
      ))}
    </View>
  );
};

const AdditionalInfo = ({
  data: {
    Address,
    City,
    Postal_Code,
    Driving_License,
    Nationality,
    dob,
    Place_of_Birth,
  },
}) => {
  const details = [
    {
      label: "Address",
      value: `${Address || ""} ${City || ""} ${Postal_Code || ""}`.trim(),
    },
    {
      label: "Driving License",
      value: Driving_License,
    },
    {
      label: "Nationality",
      value: Nationality,
    },
    {
      label: "Date of Birth",
      value: dob,
    },
    {
      label: "Place of Birth",
      value: Place_of_Birth,
    },
  ];
  return details.map((info) =>
    info.value ? (
      <View style={{ padding: "5px 32px", paddingLeft: "42px" }}>
        <Text
          style={{
            color: "#242732",
            fontWeight: 700,
            fontSize: "11px",
            paddingBottom: "4px",
          }}
        >
          {info.label}
        </Text>
        <Text
          style={{
            color: "#242732",
            fontSize: "10px",
          }}
        >
          {info.value}
        </Text>
      </View>
    ) : (
      <View />
    )
  );
};

const RenderView = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const {
    title,
    subTitle,
    startDate,
    endDate,
    description,
    location = "city",
  } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View style={{ marginTop: "10px" }}>
      <Text
        style={{
          fontSize: "13px",
          fontWeight: "600",
          letterSpacing: "1.95px",
          color: "#242732",
          //textDecoration: "underline",
        }}
      >
        {heading}
      </Text>
      {items.map((item) => (
        <View style={{ marginTop: "5px" }}>
          <Text style={{ fontSize: "11px", fontWeight: "500" }}>
            {item[title]}
          </Text>
          <Text
            style={{ fontSize: "11px", color: "#242732", marginTop: "3px" }}
          >
            {item[subTitle]}
          </Text>
          <Text
            style={{ fontSize: "10px", color: "#363434", marginTop: "3px" }}
          >
            {getDisplayDate(item[startDate], item[endDate])}{" "}
            {item[location] && `, ${item[location]}`}
          </Text>
          {item[description] && (
            <View style={{ marginTop: "5px" }}>
              {renderHTMLContent(item[description], {
                "*": { color: "#3f3f3f",textAlign:"justify", },
              })}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};
const CustomSection = ({ data, dataKey, heading, fieldMap = {} }) =>{
  const items=data[dataKey];
  console.log("data key", data[dataKey]);
  const {title, city, startDate, endDate, description,custom_title }=fieldMap;
    if(!items||!items.length){
       return <View/>;
    }
    return (
      <View
        style={{
          marginBottom: "8px",
          paddingTop:"10px",
        }}
      >
        <Text
          style={{
            fontSize: "16px",
            fontWeight: 600,
            color: "#000000",
            color: "#242732",
          }}
        >
        
          {heading}
        </Text>
        {items.map((item) => (
          
          <View style={{ marginBottom: "8px" }} wrap={true}>
            
            <View
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#242732",
                
              }}
            >
              <Text>{item[custom_title]}</Text>
              
            </View>



            <View
              style={{
                fontSize: "13px",
                fontWeight: "500",
                marginTop: "10px",
              }}
            >
              <Text>{item[title]}</Text>
              
            </View>
          
            <Text
              style={{
                fontSize: "10px",
                color: "#363434",
                marginTop: "3px",
                marginBottom: "4px",
              }}
            >
              {getDisplayDate(item[startDate], item[endDate])}
  
              {item[city] ? ` ,  ${item[city]}` : ""}
              
            </Text>

            {item[description] && (
              <View
                style={{
                  marginTop: "4px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                }}
              >
              {console.log(dataKey, item[description])}

                {renderHTMLContent(item[description], {
                  "*": {
                    color: "#3f3f3f",
                    fontWeight: 400,
                    fontSize: "12px",
                    paddingRight: "5px",
                    alignItems: "baseline",
                    position: "sticky",
                    textAlign: "justify",
                  },
                })}
              </View>
            )}


          </View>
        ))}
      </View>
    );
};

const Template5 = ({ data }) => {
  const {
    profileImage,
    First_Name,
    Last_Name,
    Wanted_Job_Title,
    template_color_code,
    professionalSummary: { Description = "" } = {},
  } = data;
  return (
    <TemplatePage data={data}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={{
              position: "absolute",
              height: "100vh",
              width: "164px",
              left: "0px",
              top: "0px",
            }}
            fixed
          >
            <View
              style={{
                height: "363px",
                width: "37px",
                backgroundColor: template_color_code,
              }}
            ></View>
          </View>
          <View style={styles.wrapper}>
            <View style={styles.left}>
              <View style={styles.profileImageWrapper}>
                <Image
                  src={profileImage || DummyProfilePic}
                  style={styles.profileImage}
                />
              </View>
              <ContactInfo data={data} />
              <AdditionalInfo data={data} />
              <Skills data={data} />
              <Hobbies data={data} />
              <Languages data={data} />

              <View style={styles.courses}>
                <RenderView
                  data={data}
                  dataKey="courses"
                  heading="COURSES"
                  fieldMap={{
                    title: "course_name",
                    subTitle: "institute_name",
                    startDate: "start_date",
                    endDate: "end_date",
                  }}
                />
                <References
                  data={data}
                  dataKey="References"
                  heading="REFERENCES"
                  fieldMap={{
                    title: "ref_name",
                    subTitle: "company_name",
                    phoneNo: "phone_no",
                    emailId: "ref_email",
                  }}
                />
                <RenderView
                  data={data}
                  dataKey="activities"
                  heading="EXTRA CURRICULAR ACTIVITY"
                  fieldMap={{
                    title: "employer_name",
                    subTitle: "function_title",
                    startDate: "start_date",
                    endDate: "end_date",
                    description: "description",
                  }}
                />
              </View>
            </View>
            <View style={styles.right}>
              <View>
                <View
                  style={{
                    display: "inline-block",
                    // flexDirection: "row",
                    // justifyContent: "space-between",
                    border: "2px solid #333",
                    padding: "20px",
                    // width: "300px",
                  }}
                >
                  {renderHTMLContent(
                    `<div style="text-align : center"> <span class="first" >${First_Name ? First_Name : "FirstName"} </span>  <span class="second">${Last_Name ? Last_Name : "LastName"}</span> </div>`,
                    {
                      ".first": {
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                        fontSize: "20px",
                        // margin: "0px 14px 0 -2px",
                        color: "#242732",
                        letterSpacing: "3.75px",
                        width: "100px",
                        border: "2px solid red",
                      },
                      ".second": {
                        display: "inline-block",
                        fontWeight: "normal",
                        fontFamily: "Roboto",
                        fontSize: "20px",
                        color: "#242732",
                        letterSpacing: "3.75px",
                        // margin: "0 20px 0 30px",
                        paddingLeft: "4px",
                      },
                    }
                  )}
                </View>

                {/* <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // justifyContent: "space-between",
                    border: "2px solid #333",
                    padding: "20px",
                    width: "300px",
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      // border: "2px solid red",
                      marginRight: "4px",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                        fontSize: "20px",
                        margin: "0px 14px 0 -2px",
                        color: "#242732",
                        letterSpacing: "3.75px",
                        width: "100px",
                      }}
                    >
                      {First_Name.length <= 10 ? First_Name : `${First_Name} `}
                      
                    </Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontWeight: "normal",
                        fontFamily: "Roboto",
                        fontSize: "20px",
                        color: "#242732",
                        letterSpacing: "3.75px",
                        margin: "0 20px 0 30px",
                        width: "120px",
                      }}
                    >
                      {First_Name.length > 10
                        ? `  
                      ${Last_Name}`
                        : Last_Name}
                    </Text>
                  </View>
                </View> */}
                <Text
                  style={{
                    position: "relative",
                    fontWeight: "normal",
                    fontSize: "10px",
                    fontSize: "14px",
                    fontFamily: "Roboto",
                    letterSpacing: "1.5px",
                    textAlign: "center",
                    color: "#333",
                    bottom: "8px",
                    backgroundColor: "white",
                    width: "120px",
                    margin: "0 80px 0 80px",
                  }}
                >
                  {Wanted_Job_Title}
                </Text>
              </View>

              {!isEmpty(Description) && (
                <View style={{ marginTop: "5px" }}>
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      letterSpacing: "1.95px",
                      color: "#242732",
                      //   marginBottom: "3px",
                    }}
                  >
                    ABOUT ME
                  </Text>
                  <Text 
                   style={{

                    textAlign:"justify",
                    paddingTop:"5px",
                   }}
                  >
                  {renderHTMLContent(Description)}
                </Text>
                </View>
              )}

            
              <RenderView
                data={data}
                dataKey="employmentHistory"
                heading="EXPERIENCE"
                fieldMap={{
                  subTitle: "Employer",
                  title: "Job_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "Description",
                }}
              />
              <RenderView
              data={data}
              dataKey="education"
              heading="EDUCATION"
              fieldMap={{
                title: "institute_name",
                subTitle: "degree",
                startDate: "start_date",
                endDate: "end_date",
                description: "Description",
              }}
            />

              <RenderView
                data={data}
                dataKey="internships"
                heading="INTERNSHIPS"
                fieldMap={{
                  title: "employer_name",
                  subTitle: "job_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "description",
                }}
              />
              <CustomSection
              data={data}
                dataKey="customSection"
                //heading="custom_title"
                fieldMap={{
                  custom_title:"custom_title",
                  title: "section_name",
                  city: "city",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "description",
                }}
              />
            </View>
          </View>
        </Page>
        <View
          style={{
            borderBottom: "16px",
            borderBottomColor:template_color_code,
            //margin: "44px 0 0",
          }}
        ></View>
      </Document>
    </TemplatePage>
  );
};

Template5.displayName = "Template5";
export default Template5;
