// import node module libraries
import { Card, Table, ProgressBar, Image, Form, Badge, Row, Col, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { Fragment, useEffect, useState, useRef } from 'react';

// import data files
//import UpcomingDeadlinesData from 'data/dashboard/projects/UpcomingDeadlinesData';
import {FiFileText} from 'react-icons/fi';
import Url from 'components/Url';
import {ToastContainer,toast} from 'react-toastify';

const UploadProject = (props) => {

	const {projectDetails ,load, setLoad } = props;

    const url1 = Url();
    const url = url1["url"];
	const s3 = url1["s3"];

    const [isUploading, setIsUploading] = useState(false);
    const [emailImages, setEmailImages] = useState([]);
    const [projectFile,setProjectFile] = useState();

    const handleFileChange = (e) => {
        console.log(e.target.files);

        if (e.target.files) {
            setProjectFile(e.target.files[0]);
        }
    };

    const handleUpload = () => {
        console.log(projectFile);
        const fd = new FormData();
        if(projectFile){
        fd.append("projectFile", projectFile);
        fd.append("careerDetailId",projectDetails?.career_detail_id)
        const api = url + "projectupload";
        const config = {
          method: "POST",
          body: fd,
        };
        fetch(api, config)
          .then((res) => res.json())
          .then((data) => {
            if (data.message) {
              setIsUploading(false);
              setLoad(true);
            }
        });
        }else{
            toast.warning("Please select a file");
        }
    };


	return (
		<Card>
			<Card.Header>
				<h4 className="mb-0" style={{"color":"var(--geeks-secondary)"}}>Upload Project</h4>
			</Card.Header>
            <Card.Body>
			<Row>
                <div className="col-12">
                    <Row className="">
                        <div className="col-sm-auto">
                            <Badge className="small mb-2" bg={projectDetails?.project_status == 0 ? "secondary" : projectDetails?.project_status == 1 ? "warning" : projectDetails?.project_status == 2 ? "success" : projectDetails?.project_status == 3 ? "danger" : "secondary"}>
                                {projectDetails?.project_status_desc}
                            </Badge>
                        </div>
                        {projectDetails?.project_status == 2 && 
                        <div className="col">
                            <Link to={projectDetails?.online_assessment_link} className="btn btn-primary btn-sm">Go to Online Assessment</Link>
                        </div>
                        }
                    </Row>
                    <Row>
                        <div className="col-12">
                        <p className="ms-2">{projectDetails?.project_status == 1 ? " Project Upload Date: " + projectDetails?.project_upload_date : projectDetails?.project_status == 3 ? " Comment: " + projectDetails?.project_comment : null}</p>
                        </div>
                    </Row>
                </div>                        
                {console.log(projectDetails?.project_status)}
				{(projectDetails?.project_status == 0 || projectDetails?.project_status == 3) &&

                    (
                    <Fragment>
                    <Form>
                    {/* Card */}
                    
                            <Form.Group className="mb-1 input-group">				
                            <Row>
                                {console.log("here")}
                            <Form.Control
                                    id="inputfavicon"
                                    type="file"
                                    className="form-control mt-2"
                                    onChange={handleFileChange}
                                />
                                {/* <Form.Label
                                    htmlFor="inputfavicon"
                                    className="input-group-text mb-0"
                                >
                                    Upload
                                </Form.Label> */}
                                <Form.Text className="text-muted">
                                    Compress all the project related files and upload single zip file. Max file size 5MB only. 
                                </Form.Text>
                            </Row>
                                </Form.Group>
                    {/* Button */}
                    <div className="d-flex justify-content-between">
                        <Button disabled={isUploading ? true : false} variant="success" 
                                    onClick={handleUpload}
                                    >
                            {isUploading
                            ?
                            <Spinner animation="border" variant="light" size="sm" />
                            :
                            "Upload Project"
                        }
                        </Button>
                    </div>                        
                </Form>
                </Fragment>
                ) 
				}
            </Row>
            </Card.Body>
            <ToastContainer />                    
		</Card>
	);
};
export default UploadProject;
