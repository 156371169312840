import React, { Fragment, useState, useMemo, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Axios from "axios";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import {
    Container,
    Modal,
    Row,
    Col,
    Badge,
    Dropdown,
    Table,
    Form,
    ListGroup,
    Button,
  } from "react-bootstrap";

import GlobalFilter from "components/common/advance-table/GlobalFilter";
import Pagination from "components/common/advance-table/Pagination";
import Url from "components/Url";
import StarRating from "components/common/StarRating";
import {toast}  from "react-toastify";
const MentorshipList = (props) => {

  const { mentorships, setReloadMentorData } = props;

  console.log(mentorships);

  const [mentorBookingID, setMentorBookingID]  = useState();
  const [mentorFeedbackID, setMentorFeedbackID]= useState();
  const [showFeedbackModal, setShowFeedbackModal]  = useState(false);

  const url1 = Url();
  const url = url1["url"];
  const s3URL = url1["s3"];

  const ActionMenu = (props) => {
    const { status } = props;
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-gray" />
        </Dropdown.Toggle>
        <Dropdown.Menu align={"end"}>
          {status === "Draft" && (
            <Fragment>
              <Dropdown.Item eventKey="1">
                <FileArrowUp size={15} className="dropdown-item-icon" /> Publish
              </Dropdown.Item>
            </Fragment>
          )}
          {(status === "Inactive" || status === "Draft") && (
            <Fragment>
              <Dropdown.Item eventKey="1">
                <FileEarmarkText size={15} className="dropdown-item-icon" />{" "}
                View Internship
              </Dropdown.Item>
              <Dropdown.Item eventKey="2">
                <Trash3 size={15} className="dropdown-item-icon" /> Delete
                Internship
              </Dropdown.Item>
            </Fragment>
          )}
          {status === "Active" && (
            <Fragment>
              <Dropdown.Item eventKey="1">
                <FileEarmarkText size={15} className="dropdown-item-icon" />{" "}
                View
              </Dropdown.Item>
              <Dropdown.Item eventKey="1">
                <XCircle size={15} className="dropdown-item-icon" /> Close
              </Dropdown.Item>
              <Dropdown.Item eventKey="1">
                <div classname="d-flex ">
                  <div>
                    <Calendar2Week size={15} className="dropdown-item-icon" />
                  </div>
                  <div classname="ms-2">
                    <div> Extend deadline by Month</div>
                    <small classname="text-muted">
                      (Expires on 2023-03-31)
                    </small>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item eventKey="1">
                <FileEarmarkText size={15} className="dropdown-item-icon" />{" "}
                Post Similar
              </Dropdown.Item>
              <Dropdown.Item eventKey="3">
                <Trash3 size={15} className="dropdown-item-icon" /> Delete
              </Dropdown.Item>
            </Fragment>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  // Column heading and cell format setup
  const columns = useMemo(
    () => [
      { accessor: "mentor_booking_id", Header: "ID", show: false },
      { accessor: "mentor_name", Header: "Mentor Name" },
      { accessor: "service_name", Header: "Mentorship Subject" },
      { accessor: "mentorship_date", Header: "Mentorship Date" },
      { accessor: "service_duration_mins", Header: "Duration (Mins)" },
      { accessor: "created_at", Header: "Booking Date" },
      {
        accessor: "mentorship_status",
        Header: "Status",
        Cell: ({ value, row }) => {
          return (
            <Badge
              bg={
                value === "Scheduled"
                  ? "warning"
                  : value === "Completed"
                  ? "success"
                  : value === "Cancelled"
                  ? "info"
                  : value === "Overdue"
                  ? "danger"
                  : null
              }
              className="text-white"
            >
              {value}{" "}
            </Badge>
          );
        },
      },
      {
        accessor: "action",
        Header: "Feedback",
        Cell: ({ row }) => {
            return (
            (row.original.mentorship_status_id == 2 ? 
                (row.original.mentor_feedback_id ?
                    <Button variant="outline-info" size="sm"
                        onClick={() => {
                          setMentorBookingID(row.original.mentor_booking_id);
                          setMentorFeedbackID(row.original.mentor_feedback_id);
                          setShowFeedbackModal(true);
                        }}>Change Feedback
                    </Button>
                    :
                    <Button variant="outline-warning" size="sm"
                        onClick={() => {
                            setMentorBookingID(row.original.mentor_booking_id);
                            setMentorFeedbackID(row.original.mentor_feedback_id);
                            setShowFeedbackModal(true);
                        }}>Give Feedback
                    </Button>                
                )
                :
                (
                  <span>Not Completed</span>
                )
            )
            )
        },
      },
      /*
                {
                    accessor: 'received', Header: 'Received',
                    Cell: ({ value, row }) => {
                        return (
                            <Link to="/dashboard/applications/all"
                                className={`btn btn-${value === 'View Applications' ? 'light' : 'primary'} btn-sm`}>{value}</Link>
                        );
                    }
                },
                {
                    accessor: 'action',
                    Header: '',
                    Cell: ({ row }) => {
                        return <ActionMenu status={row.original.status} />;
                    }
                }
                */
    ],
    []
  );

  const data = useMemo(() => mentorships, [mentorships]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { pageIndex, globalFilter } = state;

  return (
    <>
    {
    (showFeedbackModal && 
      <MentorFeedbackModal
        showFeedbackModal={showFeedbackModal}
        setShowFeedbackModal={setShowFeedbackModal}
        mentorBookingID={mentorBookingID}
        mentorFeedbackID={mentorFeedbackID}
        setReloadMentorData={setReloadMentorData}
      />
      )
      }
      <div>
        <Row className="align-items-center">
          <Col md={8}>
            <div>
              <h2 className="mb-md-0 mb-3">Mentorship List </h2>
            </div>
          </Col>
          <Col md={4}>
            <div className="pt-2">
              <GlobalFilter
                filter={globalFilter}
                setFilter={setGlobalFilter}
                placeholder="Search mentorships"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-6">
          <Col lg={12}>
            <Table
              hover
              responsive
              {...getTableProps()}
              className="text-nowrap table-centered"
            >
              <thead className="table-light">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  //console.log(row)
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            {/* Pagination @ Footer */}
            <Pagination
              previousPage={previousPage}
              pageCount={pageCount}
              pageIndex={pageIndex}
              gotoPage={gotoPage}
              nextPage={nextPage}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

const MentorFeedbackModal = (props) => {
  const {
    showFeedbackModal,
    setShowFeedbackModal,
    mentorBookingID,
    mentorFeedbackID,
    setReloadMentorData
  } = props;

  const [ mentorFeedback, setMentorFeedback] = useState();

  const { register, handleSubmit, setValue, control } = useForm({
    defaultValues: mentorFeedback, // Set default values for editing
  });
  
  const url1 = Url();
  const url = url1["url"];
  const s3URL = url1["s3"];
  const [createValidate, setCreateValidate] = useState(false);
  const [starRating,setStarRating] = useState();
  const [feedback, setFeedback] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);


  const token = localStorage.getItem("stutoken");
  const studentId = localStorage.getItem("studentId");
  
  useEffect(() => {

    if(mentorFeedbackID) {
        const fetchData = async () => {
            let apiURL = url + "getMentorFeedback/" + mentorFeedbackID;
            
            try {
            const response = await Axios.get(
                apiURL,
                {
                headers: {
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                    "X-USER-ID" : studentId
                },
                }
            );
            
              if(typeof response !== undefined && typeof response.data !== undefined ){
                setStarRating(response.data.mentor_feedback.star_rating);
                setFeedback(response.data.mentor_feedback.feedback);
              } 
            } catch (error) {
                console.log(error)
                var status = error.response.status;
            // Handle error if needed
            }
        }
        fetchData();
    }
  }, [mentorFeedbackID]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // You can perform additional actions here if needed
    
    let apiURL = url + "saveMentorFeedback";

    try {
      const response = await Axios.post(
        apiURL,
        { mentor_feedback_id: mentorFeedbackID, mentor_booking_id:mentorBookingID,star_rating: starRating,feedback,userId:studentId },
        {
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setReloadMentorData(true);
      toast.success(response.data.message,{autoClose: 1000});
      
    } catch (error) {
      //var status = error.response.status;
      // Handle error if needed
      alert(error);
    }
  };


  const handleClose = () => {
    setShowFeedbackModal(false);
  };

  const handleStarRatingChange = (e) => {
    
    setStarRating(e);
  }

  return (
    <Modal show={showFeedbackModal} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          {mentorFeedbackID ? "Edit Feedback" : "Give Feedback"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={handleFormSubmit}>

          <Row className="mb-2">
            <div className="col-4 text-end ">
              <h5 className="m-1">Star Rating</h5>
            </div>

            <div className="col-8 d-md-flex">
              <Row>
                  <Col md={6}>
                    <StarRating value={starRating} onChange={handleStarRatingChange} />
                  </Col>
              </Row>
            </div>
          </Row>

          <Row className="mb-2">
            <div className="col-4 text-end ">
              <h5 className="m-1">Feedback</h5>
            </div>
            <div className="col-8">
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  placeholder={"Share your Feedback"}
                  id="feedback"
                  name="feedback"
                  value={feedback}
                  onChange={(e) =>
                    setFeedback(e.target.value ? e.target.value : "")
                  }
                  required
                  isValid={createValidate && feedback}
                  isInvalid={createValidate && !feedback}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter feedback
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Row>
          <Row className="mb-2 d-flex d-inline">
            <div className="mb-3 d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary btn-sm"
                disabled={buttonDisabled}
              >
                Save
              </button>

              <button
                type="button"
                className="btn btn-outline-warning btn-sm ms-2"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </Row>
        </Form>        
      </Modal.Body>
    </Modal>
  );
};

export default MentorshipList;
