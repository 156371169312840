// import node module libraries
import { v4 as uuid } from 'uuid';

export const CandidateInfoData = [
    {
        id: uuid(),
        company: 'Campus Ambassador internship',
        name: 'Arijit Dey',
        location: 'Hooghly',
        skills: 'SQL,Python,Machine Learning,Tableau,AWS,Azure',
        education: 'B.Tech',
        skillMatchPercent : 50,
        status : 'applied'
    },
    {
        id: uuid(),
        company: 'Campus Ambassador internship',
        name: 'Giaan Sridhar',
        location: 'Bangalore',
        skills: 'JavaScript,MySQL,Python,Machine Learning,Tableau,AWS,Azure',
        education: 'education',
        skillMatchPercent : 50,
        status : 'applied'
    },
    {
        id: uuid(),
        company: 'Campus Ambassador internship',
        name: 'Rohini Sarin',
        location: 'Delhi',
        skills: 'SQL,MySQL,Python,Machine Learning,Tableau,AWS,Azure',
        education: 'BE (Computer)',
        skillMatchPercent : 50,
        status : 'recommended'
    },
    {
        id: uuid(),
        company: 'Campus Ambassador internship',
        name: 'Karim Suresh',
        location: 'Bangalore',
        skills: 'MySQL, GraphQL, Python,Machine Learning,Tableau,AWS,Azure',
        education: 'ME',
        skillMatchPercent : 50,
        status : 'recommended'
    },
    {
        id: uuid(),
        company: 'Campus Ambassador internship',
        name: 'Kanika Chawla',
        location: 'Ahmedabad',
        skills: 'NextJS, NodeJS, Machine Learning,Tableau,AWS,Azure',
        education: 'MCA',
        skillMatchPercent : 50,
        status : 'shortlisted'
    },
    {
        id: uuid(),
        company: 'Campus Ambassador internship',
        name: 'Kunti Chhabra',
        location: 'Delhi',
        skills: 'NodeJS, Machine Learning,Tableau,AWS,Azure',
        education: 'BCA',
        skillMatchPercent : 50,
        status : 'shortlisted'
    },
    {
        id: uuid(),
        company: 'Campus Ambassador internship',
        name: 'Karim Anthony',
        location: 'Ahmedabad',
        skills: 'ReactJS, Machine Learning,Tableau,AWS,Azure',
        education: 'B.Sc. (CS)',
        skillMatchPercent : 50,
        status : 'hired'
    },
    {
        id: uuid(),
        company: 'Campus Ambassador internship',
        name: 'Namita Prabhakar',
        location: 'Bangalore',
        skills: 'SQL,Python,Machine Learning,Tableau,AWS,Azure',
        education: 'BE',
        skillMatchPercent : 50,
        status : 'hired'
    },
    {
        id: uuid(),
        company: 'Campus Ambassador internship',
        name: 'Hetan Ram Kumer',
        location: 'Ahmedabad',
        skills: 'Machine Learning,Tableau,AWS,Azure',
        education: 'MCA',
        skillMatchPercent : 50,
        status : 'rejected'
    }
];

export default CandidateInfoData;
