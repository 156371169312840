import React, { Fragment, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Dropdown, Image, Table, Badge  } from 'react-bootstrap';
import {
	Trash,
	MoreVertical,
	Edit,
	Move,
	Copy,
	ToggleLeft,
	ToggleRight,
	Video
} from 'react-feather';
//import Badge from 'components/elements/bootstrap/DotBadge';


const BlogsTable = ({ table_data }) => {
    return (
    <Fragment>
        <Table responsive  >
            <thead>
                <tr>
                    <th>Blog Heading</th>
                    <th>Cateogry</th>
                    <th>Author</th>
                    <th>Status</th>
                    <th>Creat Date</th>
                    {/* <th>*</th> */}
                </tr>
            </thead>
            <tbody>
        {console.log(table_data)}
        {

        table_data.map((item) => {
            return (
                <tr key={item.id}>
                    <td><Link to={"/cms/blog-details/" + item.id}>{item.content_header}</Link></td>
                    <td>{item.category_name}</td>
                    <td>{item.author_name}</td>
                    <td>
							<Badge
								bg={
									item.active_ind === 1
										? 'warning'
										: item.active_ind === 2
										? 'success'
										: item.active_ind === 0
										? 'info'
										: item.active_ind === 3
										? 'danger'
										: ''
								}
							>
						                        
                        {item.content_status}
                        </Badge>
                    </td>
                    <td>{item.created_at}</td>
                    {/* <td>{item.active_ind}</td> */}
                </tr>
                )
            })
        }      
        </tbody>
        </Table>
    </Fragment>
    );
};

export default BlogsTable ;