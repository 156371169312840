import { v4 as uuid } from 'uuid';

// import media files
import JobListLogo1 from 'assets/images/job/job-brand-logo/job-list-logo-1.svg';
import JobListLogo2 from 'assets/images/job/job-brand-logo/job-list-logo-2.svg';
import JobListLogo3 from 'assets/images/job/job-brand-logo/job-list-logo-3.svg';
import JobListLogo4 from 'assets/images/job/job-brand-logo/job-list-logo-4.svg';
import JobListLogo5 from 'assets/images/job/job-brand-logo/job-list-logo-5.svg';

import JobCompanyLogoA from 'assets/images/job/job-company-logo-a.svg';
import JobCompanyLogoG from 'assets/images/job/job-company-logo-g.svg';
import JobCompanyLogoL from 'assets/images/job/job-company-logo-l.svg';
import JobCompanyLogoP from 'assets/images/job/job-company-logo-p.svg';

export const ComapniesListData = [
  {
    id: uuid(),
    company: 'Peak Health',
    slug: 'about-amazon',
    logo: "https://internshipgates3.s3.ap-south-1.amazonaws.com/imgupload/1676471314blob",
    experience: '28 years old',
    type: 'Private',
    country : 'USA',
    location: 'Seattle, Washington and Arlington, Virginia',
    briefInfo: 'Worlds largest Internet Company',
    employees: 1468000,
    totalReviews: 500,
    rating: 5.0,
    jobPosting : '40+',
    featured: true,
  },
  {
    id: uuid(),
    company: 'IIDE Education',
    slug: 'about-google',
    logo: "https://internshipgates3.s3.ap-south-1.amazonaws.com/imgupload/1665132479blob",
    experience: '24 years old',
    type: 'Private',
    country : 'United States',
    location: 'Menlo Park, California',
    briefInfo: 'Search the world\'s information',
    employees: 139995,
    totalReviews: 500,
    rating: 5.0,
    jobPosting : '26+',
    featured: true,
  },
  {
    id: uuid(),
    company: 'Grizmo Labs',
    slug: 'about-lenovo',
    logo: "https://internshipgates3.s3.ap-south-1.amazonaws.com/imgupload/1671093952blob",
    experience: '38 years old',
    type: 'Private',
    country : 'Hong Kong',
    location: 'Hong Kong',
    briefInfo: 'Official site and buy online the best laptops',
    employees: 75000,
    totalReviews: 800,
    rating: 5.0,
    jobPosting : '15+',
    featured: true,
  },
  {
    id: uuid(),
    company: 'EldersIndia',
    slug: 'about-payPal',
    logo: "https://internshipgates3.s3.ap-south-1.amazonaws.com/imgupload/1675267960blob",
    experience: '24 years old',
    type: 'Private',
    country : 'U.S.',
    location: 'San Jose, California',
    briefInfo: 'Safer and Easier Way to Pay Online',
    employees: 75000,
    totalReviews: 800,
    rating: 5.0,
    jobPosting : '32+',
    featured: true,
  }
  

];

export default ComapniesListData;
