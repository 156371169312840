import Api, { ENDPOINTS } from "./api";
import Axios from "axios";

export const saveProfessionalSummary = async (resume_id, id, data) => {
	const email = localStorage.getItem("email");
	const psUrl = id
		? `${ENDPOINTS.UPDATE_PROFESSIONAL_SUMMARY}/${id}`
		: ENDPOINTS.CREATE_PROFESSIONAL_SUMMARY;
	const resp = await Api.post(psUrl, {
		...data,
		email,
		resume_id,
	});
	return resp;
};

export const saveExperienceDetails = async (resume_id, data = []) => {
	const email = localStorage.getItem("email");
	const apiPromises = data.map(async ({ id, ...exp }) => {
		const url = id
			? `${ENDPOINTS.UPDATE_EXP_DETAIL}/${id}`
			: ENDPOINTS.CREATE_EXP_DETAIL;
		const resp = await Api.post(url, {
			...exp,
			email,
			resume_id,
		});
		return resp;
	});
	const response = await Axios.all(apiPromises);
};

export const saveEducationDetails = async (resume_id, data = []) => {
	const email = localStorage.getItem("email");
	const apiPromises = data.map(async ({ id, ...edu }) => {
		const url = id
			? `${ENDPOINTS.UPDATE_EDU_DETAIL}/${id}`
			: ENDPOINTS.CREATE_EDU_DETAIL;
		const resp = await Api.post(url, {
			...edu,
			email,
			resume_id,
		});
		return resp;
	});
	const response = await Axios.all(apiPromises);
};

export const saveSkills = async (resume_id, data = []) => {
	const email = localStorage.getItem("email");
	const apiPromises = data.map(async ({ id, ...skl }) => {
		const url = id ? `${ENDPOINTS.UPDATE_SKILL}/${id}` : ENDPOINTS.CREATE_SKILL;
		const resp = await Api.post(url, {
			...skl,
			email,
			resume_id,
		});
		return resp;
	});
	const response = await Axios.all(apiPromises);
};

export const saveExtraCurricularActivities = async (resume_id, data = []) => {
	const email = localStorage.getItem("email");
	const apiPromises = data.map(async ({ id, ...extra }) => {
		const url = id
			? `${ENDPOINTS.UPDATE_EXTRA_CURRICULAR_ACTIVITIE}/${id}`
			: ENDPOINTS.CREATE_EXTRA_CURRICULAR_ACTIVITIE;
		const resp = await Api.post(url, {
			...extra,
			email,
			resume_id,
		});
		return resp;
	});
	const response = await Axios.all(apiPromises);
};

export const saveHobbies = async (resume_id, data = []) => {
	const email = localStorage.getItem("email");
	const apiPromises = data.map(async ({ id, ...hby }) => {
		const url = id ? `${ENDPOINTS.UPDATE_HOBBY}/${id}` : ENDPOINTS.CREATE_HOBBY;
		const resp = await Api.post(url, {
			...hby,
			email,
			resume_id,
		});
		return resp;
	});
	const response = await Axios.all(apiPromises);
};

export const saveReferences = async (resume_id, data = []) => {
	// console.log(data);
	try {
		const email = localStorage.getItem("email");
		const apiPromises = data.map(async ({ id, ...ref }) => {
			const url = id
				? `${ENDPOINTS.UPDATE_REFERENCE}/${id}`
				: ENDPOINTS.CREATE_REFERENCE;
			const resp = await Api.post(url, {
				...ref,
				email,
				resume_id,
			});
			return resp;
		});
		const response = await Axios.all(apiPromises);
	} catch (error) {
		alert(error);
	}
};

export const saveCourses = async (resume_id, data = []) => {
	const email = localStorage.getItem("email");
	const apiPromises = data.map(async ({ id, ...crs }) => {
		const url = id
			? `${ENDPOINTS.UPDATE_COURSE}/${id}`
			: ENDPOINTS.CREATE_COURSE;
		const resp = await Api.post(url, {
			...crs,
			email,
			resume_id,
		});
		return resp;
	});
	const response = await Axios.all(apiPromises);
};

export const saveInternships = async (resume_id, data = []) => {
	const email = localStorage.getItem("email");
	const apiPromises = data.map(async ({ id, ...intsh }) => {
		const url = id
			? `${ENDPOINTS.UPDATE_INTERNSHIP}/${id}`
			: ENDPOINTS.CREATE_INTERNSHIP;
		const resp = await Api.post(url, {
			...intsh,
			email,
			resume_id,
		});
		return resp;
	});
	const response = await Axios.all(apiPromises);
};

export const saveLanguages = async (resume_id, data = []) => {
	const email = localStorage.getItem("email");
	const apiPromises = data.map(async ({ id, ...lng }) => {
		const url = id
			? `${ENDPOINTS.UPDATE_LANGUAGE}/${id}`
			: ENDPOINTS.CREATE_LANGUAGE;
		const resp = await Api.post(url, {
			...lng,
			email,
			resume_id,
		});
		return resp;
	});
	const response = await Axios.all(apiPromises);
};

export const saveCustomSection = async (resume_id, data = []) => {
	const email = localStorage.getItem("email");
	const apiPromises = data.map(async ({ id, ...cus }) => {
		const url = id
			? `${ENDPOINTS.UPDATE_CUSTOMDATA}/${id}`
			: ENDPOINTS.CREATE_CUSTOMDATA;
		const resp = await Api.post(url, {
			...cus,
			email,
			resume_id,
		});
		return resp;
	});
	const response = await Axios.all(apiPromises);
};
