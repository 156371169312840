// import node module libraries
import Typed from 'react-typed';
import { Link } from 'react-router-dom';
import { Container, Row, Col, ListGroup,Image } from 'react-bootstrap';

// import MDI icons
import Icon from '@mdi/react';
import { mdiCheck } from '@mdi/js';
import './StudyAbroad.css';
//import images
// import udemy from '../../../../assets/images/brand/logo/Udemy.png';
// import youtube from '../../../../assets/images/brand/logo/Youtube.png';
// import johnacademy from '../../../../assets/images/brand/logo/John academy - transparent.png';
// import edureka from '../../../../assets/images/brand/logo/Edureka - transparent.png';
// import eduonix from '../../../../assets/images/brand/logo/Eduonix - transparent.png';

// Import required Media files
import HeroGraphics from 'assets/images/hero/hero-graphics.svg';

import usa from "assets/images/studyabroad/flagicons/usa.png";
import uk from "assets/images/studyabroad/flagicons/uk.png";
import australia from "assets/images/studyabroad/flagicons/australia.png";
import china from "assets/images/studyabroad/flagicons/china.png";
import brazil from "assets/images/studyabroad/flagicons/brazil.png";
import canada from "assets/images/studyabroad/flagicons/canada.png";
import germany from "assets/images/studyabroad/flagicons/germany.png";
import nz from "assets/images/studyabroad/flagicons/nz.png";
import sa from "assets/images/studyabroad/flagicons/sa.png";
import skoria from "assets/images/studyabroad/flagicons/skoria.png";
import swiden from "assets/images/studyabroad/flagicons/swiden.png";

const TopBanner = () => {

	const scroll = () => {
		console.log("clicked")
		const section = document.querySelector( '#studydestinations_section' );
		section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
	}

	return (
		<div
			className="pb-lg-18 py-4 py-md-10 bg-auto"
			style={{
				background: ` linear-gradient(45deg, #fff8f5,#ffc7b4)`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				backgroundPosition: 'top center',
				position:"relative"
			}}
		>
			{/* <Image className="img-fluid rounded d-none d-lg-block d-xl-block" src={usa} style={{position:"absolute", top:"40px", left:"100px", width:"48px", transform:"rotate(-45deg)"}} alt="Study in USA" />
			<Image className="img-fluid rounded d-none d-lg-block d-xl-block" src={uk} style={{position:"absolute", top:"150px", left:"250px", width:"48px", transform:"rotate(45deg)"}} alt="Study in USA" />
			<Image className="img-fluid rounded d-none d-lg-block d-xl-block" src={australia} style={{position:"absolute", top:"300px", left:"300px", width:"48px", transform:"rotate(50deg)"}} alt="Study in USA" />
			<Image className="img-fluid rounded d-none d-lg-block d-xl-block" src={sa} style={{position:"absolute", top:"450px", left:"100px", width:"48px", transform:"rotate(-65deg)"}} alt="Study in USA" />
			<Image className="img-fluid rounded d-none d-lg-block d-xl-block" src={skoria} style={{position:"absolute", top:"40px", right:"150px", width:"48px", transform:"rotate(-20deg)"}} alt="Study in USA" />
			<Image className="img-fluid rounded d-none d-lg-block d-xl-block" src={china} style={{position:"absolute", top:"150px", right:"250px", width:"48px", transform:"rotate(35deg)"}} alt="Study in USA" />
			<Image className="img-fluid rounded d-none d-lg-block d-xl-block" src={nz} style={{position:"absolute", top:"300px", right:"100px", width:"48px", transform:"rotate(-75deg)"}} alt="Study in USA" />
			<Image className="img-fluid rounded d-none d-lg-block d-xl-block" src={brazil} style={{position:"absolute", top:"450px", right:"350px", width:"48px", transform:"rotate(45deg)"}} alt="Study in USA" /> */}

			<Image className="img-fluid rounded icon1" src={usa} alt="Study in USA" />
			<Image className="img-fluid rounded icon2" src={uk} alt="Study in USA" />
			<Image className="img-fluid rounded icon3" src={australia} alt="Study in USA" />
			<Image className="img-fluid rounded icon4" src={sa} alt="Study in USA" />
			<Image className="img-fluid rounded icon5" src={skoria} alt="Study in USA" />
			<Image className="img-fluid rounded icon6" src={china} alt="Study in USA" />
			<Image className="img-fluid rounded icon7" src={nz} alt="Study in USA" />
			<Image className="img-fluid rounded icon8" src={brazil} alt="Study in USA" />			

			<Container>
				<Row className="justify-content-center">
					<Col xl={7} lg={7} md={12}>
						<div className="py-4 py-lg-0 text-center">
							<h1 className="display-4 fw-bold mb-4 text-primary">
								<span className="text-dark px-3 px-md-0">Discover, Dream, Study Abroad </span>
								<br></br>
								<span className="text-primary ms-2">
									Your Global Education Starts Here!
								</span>
							</h1>
							<p className="mb-6 h4 lead ">
								<p className="h5 text-dark">
								Internshipgate's partnerships with renowned universities guarantee you access to the latest, industry-relevant course programs to stay at the forefront of your profession 
								</p>
							</p>
							{/* <Link
								to="/marketing/pages/pricing"
								className="btn btn-primary me-2"
							> */}
							<button className='btn btn-primary me-2' style={{ fontSize: "16px", fontWeight: 400 }} onClick={scroll}>
								Explore Study Destinations
							</button>
							{/* </Link> */}
							{/* <Link
								to="/authentication/sign-up"
								className="btn btn-outline-primary"
							>
								Try for Free
							</Link> */}

							{/* <div className="mt-8 mb-0">
								<ListGroup as="ul" bsPrefix="list-inline">
									<ListGroup.Item
										as="li"
										bsPrefix="list-inline-item text-dark fw-semi-bold lh-1 fs-4 me-3 mb-2 mb-md-0"
									>
										<span className="icon-shape icon-xs rounded-circle bg-light-success text-center me-2">
											<Icon
												path={mdiCheck}
												size={0.7}
												className="text-success"
											/>
										</span>
										<span className="align-middle">100 + online courses</span>
									</ListGroup.Item>
									<ListGroup.Item
										as="li"
										bsPrefix="list-inline-item text-dark fw-semi-bold lh-1 fs-4 me-3 mb-2 mb-md-0"
									>
										<span className="icon-shape icon-xs rounded-circle bg-light-success text-center me-2">
											<Icon
												path={mdiCheck}
												size={0.7}
												className="text-success"
											/>
										</span>
										<span className="align-middle">Expert instruction</span>
									</ListGroup.Item>
									<ListGroup.Item
										as="li"
										bsPrefix="list-inline-item text-dark fw-semi-bold lh-1 fs-4"
									>
										<span className="icon-shape icon-xs rounded-circle bg-light-success text-center me-2">
											<Icon
												path={mdiCheck}
												size={0.7}
												className="text-success"
											/>
										</span>
										<span className="align-middle">Lifetime access</span>
									</ListGroup.Item>
								</ListGroup>
							</div> */}
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};
export default TopBanner;
