/* eslint-disable prettier/prettier */
// import node module libraries
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Container, Tab } from "react-bootstrap";
import { useHistory } from "react-router";

// import custom components
import FormSelect from "components/elements/form-select/FormSelect";
import GridListViewButton from "components/elements/miscellaneous/GridListViewButton";
import PageHeading from "components/marketing/common/page-headings/PageHeading";
import Url from "components/Url";
// import sub components
import FilterOptions from "./FilterOptions";
import CourseGridView from "./CourseGridView";
import CourseListView from "./CourseListView";
import HeroTyped from "components/marketing/landings/landing-courses/HeroTyped";
import LogosTopHeading from "components/marketing/common/clientlogos/LogosTopHeading";
import LogoList2 from "data/marketing/clientlogos/LogoList2";
import Features4Columns from "components/marketing/landings/landing-courses/Features4Columns";
import { Helmet } from "react-helmet";

const CourseFilterPage = () => {
  // const history = useHistory();

  // const stutoken = localStorage.getItem("stutoken");
  // console.log(stutoken);

  const url1 = Url();
  const url = url1["url"];
  const s3 = url1["s3"];

  const [s3url, setS3url] = useState(s3 + "imguploadvideolink/");
  const [allCoursesData, setAllCoursesData] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const [levelFilter, setlevelFilter] = useState([]); // data coming from filterOptions
  const [categoryFilter, setCategoryFilter] = useState([]); // data coming from filterOptions
  const [courseProviderFilter, setCourseProviderFilter] = useState([]); // data coming from filterOptions
  const [certificationFilter, setCertificationFilter] = useState([]); // data coming from filterOptions
  const [courseLengthFilter, setCourseLengthFilter] = useState([]); // data coming from filterOptions
  const [seodetails, setSeodetails] = useState("");

  function filter_data_child(
    level,
    category,
    courseProvider,
    certification,
    courseLengthFilter
  ) {
    setlevelFilter(level);
    setCategoryFilter(category);
    setCourseProviderFilter(courseProvider);
    setCertificationFilter(certification);
    setCourseLengthFilter(courseLengthFilter);
  }

  // console.log("DATA from filter options : ", certificationFilter);

  // const sortByOptions = [
  // 	{ value: 'newest', label: 'Newest' },
  // 	{ value: 'free', label: 'Free' },
  // 	{ value: 'most-popular', label: 'Most Popular' },
  // 	{ value: 'highest-rated', label: 'Highest Rated' }
  // ];

  useEffect(() => {
    const api = url + "getAllVideolinks";
    fetch(api, { method: "GET" })
      .then((response) => response.json())
      .then((actualResponse) => {
        setAllCoursesData(actualResponse);
        const allCoursesCat = actualResponse.map((data) => data.category);
        const uniqueCategories = [...new Set(allCoursesCat)];
        setCategoryOptions(uniqueCategories);
      });

    /*********** get page SEO Settings */

    var api1 = url + "getPageSeoSettingById/" + 5;
    fetch(api1, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        setSeodetails(actualdata1);
      });
  }, []);

  // console.log("DATA", allCoursesData);
  // console.log("Categories", categoryOptions);

  return (
    <Fragment>
      <Helmet>
        <title>{seodetails.page_title}</title>
        <meta name="keywords" content={seodetails.page_meta_keyword} />
        <meta name="description" content={seodetails.page_meta_description} />
      </Helmet>
      <HeroTyped />

      <LogosTopHeading title="Our Trusted Partners" logos={LogoList2} />

      {/* <Features4Columns /> */}
      {/* Page header */}
      {/* <PageHeading pagetitle="Filter Page" /> */}

      {/* Content */}
      <div className="py-6">
        <Container>
          <Tab.Container defaultActiveKey="grid">
            <Row>
              <Col lg={12} md={12} sm={12} className="mb-4">
                <Row className="d-lg-flex justify-content-between align-items-center">
                  <Col md={6} lg={8} xl={9}>
                    <h4 className="mb-3 mb-lg-0">
                      {/* Displaying 9 out of total {allCoursesData.length} courses */}
                    </h4>
                  </Col>
                  <Col md={6} lg={4} xl={3} className="d-md-inline-flex d-none">
                    <div className="me-2">
                      <GridListViewButton keyGrid="grid" keyList="list" />
                    </div>
                    {/* <FormSelect options={sortByOptions} placeholder="Sort by" /> */}
                  </Col>
                </Row>
              </Col>
              <Col xl={3} lg={3} md={4} sm={12} className="mb-4 mb-lg-0">
                <FilterOptions
                  categoryOptions={categoryOptions}
                  onData={filter_data_child}
                />
              </Col>
              {/* Tab content */}
              <Col xl={9} lg={9} md={8} sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="grid" className="pb-4 px-0">
                    <CourseGridView
                      courseData={allCoursesData}
                      courseImg={s3url}
                      levelFilter={levelFilter}
                      categoryFilter={categoryFilter}
                      courseProviderFilter={courseProviderFilter}
                      certificationFilter={certificationFilter}
                      courseLengthFilter={courseLengthFilter}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="list" className="pb-4 px-0 react-code">
                    <CourseListView
                      courseData={allCoursesData}
                      courseImg={s3url}
                      levelFilter={levelFilter}
                      categoryFilter={categoryFilter}
                      courseProviderFilter={courseProviderFilter}
                      certificationFilter={certificationFilter}
                      courseLengthFilter={courseLengthFilter}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </div>
    </Fragment>
  );
};

export default CourseFilterPage;
