// import node module libraries
import { Link } from "react-router-dom";
import {
  Container,
  Button,
  Row,
  Col,
  Navbar,
  Card,
  Form,
  Nav,
  Image,
  Modal,
  Spinner,
} from "react-bootstrap";

// import media files
import Avatar from "assets/images/avatar/avatar-1.jpg";
import { useState, useRef, useEffect } from "react";
import CropImageDialog from "../ProfileCrop/CropImageDialog";
import Url from "components/Url";
import { ToastContainer, toast } from "react-toastify";

const EditProfile = () => {
  const hiddenFileInput = useRef(null);
  const [avatar, setAvatar] = useState(Avatar);
  const [editMode, setEditMode] = useState(null);
  const [showProfileImageDialog, setShowProfileImageDialog] = useState(false);

  const [profilePicModalMessage, setProfilePicModalMessage] = useState("");
  const [showProfilePicModalMessage, setShowProfilePicModalMessage] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  // const handleChange = (e) => {
  //     setAvatar(URL.createObjectURL(e.target.files[0]));
  // }

  const url1 = Url();
  const url = url1["url"];
  const s3 = url1["s3"];
  const id = window.localStorage.getItem("id");
  const [empData, setEmpData] = useState({
    id: parseInt(id),
    name: "",
    mobileno: "",
    email: "",
    org_name: "",
    org_logo: "",
    org_description: "",
    website: "",
  });

  useEffect(() => {
    let status;
    const api3 = url + "getEmployerProfileById" + "/" + id;
    fetch(api3, {
      method: "GET",
    })
      .then((apidata1) => {
        status = apidata1.status;
        return apidata1.json();
      })
      .then((actualdata1) => {
        console.log(actualdata1);
        setEmpData(actualdata1);
      });
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setEmpData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    setLoading(true);
    if (!empData?.org_logo) {
      toast.warning("Please select Company Logo....");
      setLoading(false);
      return;
    }
    const api = url + "updateEmployerDetail";
    var status;
    fetch(api, {
      method: "POST",
      body: JSON.stringify({
        id: id,
        name: empData?.name,
        address: empData?.address,
        mobileno: empData?.mobileno,
        email: empData?.email,
        org_name: empData?.org_name,
        org_logo: empData?.org_logo,
        org_description: empData?.org_description,
        website: empData?.website,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((op) => {
        status = op.status;
        return op.json();
      })
      .then((op1) => {
        if (status === 200 || 201) {
          toast.success("Employer details updated successfuly...");

          localStorage.removeItem("empProfileStatus");

          window.location.href = "/empdashboard/profile";
        }
      });
    console.log(empData);
  };

  const picSubmit = async (data) => {
    const employerId = localStorage.getItem("id");

    const x = empData.org_logo === undefined ? "not-found" : empData.org_logo;
    var logo;
    const imgFile = await fetch(data).then((r) => r.blob());
    console.log(imgFile);
    const formData = new FormData();
    formData.append("org_logo", imgFile);
    formData.append("employer_id", employerId);
    fetch(url + "uploadEmployerLogo", {
      method: "POST",
      body: formData,
    })
      .then((op) => {
        return op.json();
      })
      .then((op1) => {
        return op1;
      })
      ///////////////////////////
      .then((op2) => {
        console.log(op2["message"]);
        if (op2["message"] !== "not-found") {
          setShowProfileImageDialog(false);
          logo = op2["message"];
          setEmpData((prevState) => ({
            ...prevState,
            ["org_logo"]: logo,
          }));
        } else {
          setProfilePicModalMessage(op2["message"]);
          setShowProfilePicModalMessage(true);
        }
      });
  };

  return (
    <>
      <ToastContainer />
      <section className="pt-5 pb-6 bg-white">
        <Container>
          <Row>
            <Col lg={3} sm={12}>
              <Card className="mb-4 border">
                <Navbar expand="lg" className="sidebar-menu">
                  <Link className="d-lg-none" to="#">
                    Menu
                  </Link>
                  <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    className="p-0 focus-none border-0"
                    label="Responsive Menu"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </Navbar.Toggle>
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav
                      className="nav flex-column w-100"
                      as="ul"
                      activeKey="0"
                    >
                      <Nav.Item as="li">
                        <Link className="nav-link" to="/empdashboard">
                          Dashboard
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          className="nav-link"
                          style={{ backgroundColor: "#e2e8f0" }}
                          to="#"
                        >
                          Profile
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          className="nav-link "
                          to="/empdashboard/post-internship"
                        >
                          {" "}
                          Post Internship
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link className="nav-link " to="/logout">
                          Sign Out
                        </Link>
                      </Nav.Item>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </Card>
            </Col>
            <Col className="col-lg-9">
              <div className="mt-6 mt-lg-0 mb-4">
                <div className="border-bottom mb-4 pb-4">
                  <h2 className="mb-0">Profile</h2>
                </div>
                <div>
                  <Form
                    noValidate
                    validated={validated}
                    className="row"
                    onSubmit={handleSubmit}
                  >
                    <Col xs={12} className="mb-3">
                      <h3>Personal Details</h3>
                      <p>
                        Edit your organization details, address and website
                        etc...
                      </p>
                    </Col>
                    <Form.Group
                      as={Col}
                      lg={6}
                      className="mb-3"
                      controlId="full-name"
                    >
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={empData?.name}
                        onChange={handleChange}
                        placeholder="Enter full Name"
                        required
                        isValid={validated && empData.name}
                        isInvalid={validated && !empData.name}
                      />
                      <Form.Control.Feedback type="valid">
                        Looks good!
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Full Name.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      lg={6}
                      className="mb-3"
                      controlId="mobile-no"
                    >
                      <Form.Label>Mobile No.</Form.Label>
                      <Form.Control
                        type="text"
                        name="mobileno"
                        maxLength={10}
                        value={empData?.mobileno}
                        onChange={handleChange}
                        placeholder="Enter Mobile No."
                        required
                        pattern="^[0-9]{10}$"
                        isInvalid={
                          validated &&
                          !!empData.mobileno &&
                          !empData.mobileno.match(/^[0-9]{10}$/)
                        }
                      />
                      <Form.Control.Feedback type="valid">
                        Looks good!
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid 10-digit mobile number.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      lg={6}
                      className="mb-3"
                      controlId="email"
                    >
                      <Form.Label>Email Account</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={empData?.email}
                        onChange={handleChange}
                        placeholder="Enter Email Account"
                        required
                        pattern="/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/"
                        isInvalid={
                          validated &&
                          !empData.email &&
                          !empData.email.match(
                            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/
                          )
                        }
                      />
                      <Form.Control.Feedback type="valid">
                        Looks good!
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please enter correct Email ID.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Col xs={12} className="mb-3 mt-6">
                      <h3>Organization Details</h3>
                      <p>
                        Edit your organization details, address and website
                        etc...
                      </p>
                    </Col>
                    <Col xs={12} className="mb-5">
                      <div className="d-flex">
                        <div className="d-flex align-items-center position-relative ">
                          <span>
                            <div className="ms-auto mb-1">
                              <Link to="#" className="me-1" title="Edit">
                                <i
                                  className="fe fe-edit fs-3"
                                  onClick={() =>
                                    setShowProfileImageDialog(true)
                                  }
                                ></i>
                              </Link>
                            </div>
                            <Modal
                              show={showProfileImageDialog}
                              onHide={() => setShowProfileImageDialog(false)}
                              className=""
                            >
                              <CropImageDialog
                                close={() => setShowProfileImageDialog(true)}
                                setCroppedImage={picSubmit}
                              />

                              {showProfilePicModalMessage && (
                                <Row className="p-2">
                                  {profilePicModalMessage}
                                </Row>
                              )}
                            </Modal>
                            {empData?.org_logo ? (
                              <Image
                                className="image avatar avatar-md rounded-circle"
                                src={s3 + "imgupload/" + empData?.org_logo}
                                alt="Image"
                              />
                            ) : (
                              <Image
                                className="image avatar avatar-md rounded-circle"
                                src={avatar}
                                alt="Image"
                              />
                            )}
                          </span>
                          <div className="ms-3">
                            <h4 className="mb-1">Organization logo</h4>
                            <small>
                              Max file size: 1Mb and max resolution: 500px x
                              500px. File type: jpeg, jpg, png, gif, bmp
                            </small>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Form.Group
                      as={Col}
                      lg={6}
                      className="mb-3"
                      controlId="organization-name"
                    >
                      <Form.Label>Organization name</Form.Label>
                      <Form.Control
                        type="text"
                        name="org_name"
                        value={empData?.org_name}
                        onChange={handleChange}
                        placeholder="e.g. internshipgate"
                        required
                        isValid={validated && empData.org_name}
                        isInvalid={validated && !empData.org_name}
                      />
                      <Form.Control.Feedback type="valid">
                        Looks good!
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Organization Name.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Col lg={6} className="mb-3 "></Col>
                    <Form.Group
                      as={Col}
                      lg={6}
                      className="mb-3"
                      controlId="organization-descriptions"
                    >
                      <Form.Label>Organization Descriptions</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="org_description"
                        value={empData?.org_description}
                        onChange={handleChange}
                        rows={3}
                        placeholder="Write Description"
                        required
                        isValid={validated && empData.org_description}
                        isInvalid={validated && !empData.org_description}
                      />
                      <Form.Control.Feedback type="valid">
                        Looks good!
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Organization Description.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Col lg={6} className="mb-3 "></Col>
                    <Form.Group
                      as={Col}
                      lg={6}
                      className="mb-3"
                      controlId="address"
                    >
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        name="address"
                        value={empData?.address}
                        onChange={handleChange}
                        placeholder="e.g. HSR Layout, Bangalore"
                        required
                        isValid={validated && empData.address}
                        isInvalid={validated && !empData.address}
                      />
                      <Form.Control.Feedback type="valid">
                        Looks good!
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Address.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Col lg={6} className="mb-4 "></Col>
                    <Form.Group
                      as={Col}
                      lg={6}
                      className="mb-4"
                      controlId="website"
                    >
                      <Form.Label>Website</Form.Label>
                      <Form.Control
                        type="text"
                        name="website"
                        value={empData?.website}
                        onChange={handleChange}
                        placeholder="e.g. https://internshipgate.com/"
                        required
                        isValid={validated && empData.website}
                        isInvalid={validated && !empData.website}
                      />
                      <Form.Control.Feedback type="valid">
                        Looks good!
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid"> 
                        Please Enter Website Link.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Col lg={6}></Col>
                    <Col lg={6}>
                      <Button variant="primary" type="submit">
                        {loading ? <Spinner size="sm" /> : "Update Profile"}
                      </Button>
                      <Link to="/empdashboard/profile">
                        <Button
                          variant="outline-primary"
                          className="ms-1"
                          type="button"
                        >
                          Cancel
                        </Button>
                      </Link>
                    </Col>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default EditProfile;
