import React, { useState, useEffect, Fragment } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  Form,
  FormSelect,
  Card,
  Spinner,
} from "react-bootstrap";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import Url from "components/Url";
import Axios from "axios";
import Select from "react-select/creatable";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import ProfileLayout from "./ProfileLayout";

const MentorTopics = () => {
  const [loadData, setLoadData] = useState(false);
  const [mentorTopics, setMentoTopics] = useState([]);
  const [topicDetail, setTopicDetail] = useState({});
  const [showTopicModal, setShowTopicModal] = useState(false);

  const mentorId = localStorage.getItem("mentorId");
  const token = localStorage.getItem("admtoken") ? localStorage.getItem("admtoken") : localStorage.getItem("mentortoken");
  const url1 = Url();
  const url = url1["url"];
  const s3URL = url1["s3"];

  useEffect(() => {
    
    fetchData();
    setLoadData(false);
  }, [loadData]);

  const fetchData = async () => {

    var api = url + "getMentorTopics" + "/" + mentorId;

    try {
      const response = await Axios.get(api, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-USER-ID": mentorId,
        },
      });

      setMentoTopics(response.data.mentorTopics);
    } catch (error) {

      toast.warning("Error getting the mentor topic data");
    }
  }; //end of fetchData()

  const editTopic = async (id) => {

    var api = url + "getMentorTopicDetails" + "/" + id;
    try {
      const response = await Axios.get(api, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-USER-ID": mentorId,
        },
      });

      if (response.data.mentorTopics) {
        setTopicDetail(response.data.mentorTopics);
        setShowTopicModal(true);
      }
    } catch (error) {
      toast.warning("Error getting the mentor topic data");
    }
  };

  async function deleteTopic(id) {
    if (
      id &&
      window.confirm("Are you sure? Want to delete Topic detail?")
    ) {
      const api = url + "deleteMentorTopicDetails/" + id;


      try {
        const response = await Axios.delete(api, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-USER-ID": mentorId,
          },
        });
  
        toast.success(response.data.message,{autoClose:1000});
        setLoadData(true);

      } catch (error) {
        toast.warning("Error getting the mentor topic data");
      }

    }
  }

  const addTopic = () => {
    setTopicDetail([]);
    setShowTopicModal(true);
  };

  return (
    <ProfileLayout>
      <Row className="mb-2 border-bottom">
        <Card className="border-0">
          <Card.Header>
            <div className="mb-3 mb-lg-0">
              <h3 className="mb-0">Topic Details</h3>
            </div>
          </Card.Header>
          <Card.Body>

            {mentorTopics.length > 0 ? (
              mentorTopics.map((item, index) => (
                <Col className="my-2 py-2 col " key={index}>
                  <div className="h5">
                    {item.topic_name}
                    {/*Bachelor of Computer Applications (BCA), Computer Application*/}
                    <Fragment>
                      <span
                        onClick={() =>
                          deleteTopic(item.id)
                        }
                        className="float-end"
                        style={{ cursor: "pointer" }}
                      >
                        <Trash />
                      </span>
                      <span
                        onClick={() => editTopic(item.id)}
                        className="float-end mx-2 pe-auto"
                        style={{ cursor: "pointer" }}
                      >
                        <PencilSquare />
                      </span>
                    </Fragment>
                  </div>
                </Col>
              ))
            ) : (
              <div className="mt-2 text-danger">
                Topic Details are mandatory...!!
              </div>
            )}

            <Row>
              <Col>
                <div className="mb-3 d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={addTopic}
                  >
                    Add Topic{" "}
                  </button>
                </div>
              </Col>
            </Row>

            {showTopicModal ? (
              <TopicPopup
                setShowTopicModal={setShowTopicModal}
                showTopicModal={showTopicModal}
                setLoadData={setLoadData}
                topicDetail={topicDetail}
                token={token}
                mentorId={mentorId}
              />
            ) : null}
          </Card.Body>
        </Card>
      </Row>
    </ProfileLayout>
  );
};

/**********************************************************************************************/
/************************* TopicPopup Modal Component *************************************/
/**********************************************************************************************/

const TopicPopup = (props) => {
  const {
    setShowTopicModal,
    showTopicModal,
    setLoadData,
    topicDetail,
    token,
    mentorId,
  } = props;

  const url1 = Url();
  const url = url1["url"];

  //const topicDetail = [];

  const [isEdit, setIsEdit] = useState(false);
  const [createValidate, setCreateValidate] = useState(false);

  const [loading, setLoading] = useState(false);

  const [apiMessage, setApiMessage] = useState("");
  const [apiStatus, setApiStatus] = useState(0);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [formData, setFormData] = useState({
    id: topicDetail?.id ? topicDetail?.id : 0,
    mentor_id: topicDetail?.mentor_id ? topicDetail?.mentor_id : mentorId,
    topic_name: topicDetail?.topic_name ? topicDetail?.topic_name : "",
  });


  useEffect(() => {
      setFormData({
        ...topicDetail,
      });
  }, [topicDetail]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const saveTopicDetails = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setCreateValidate(true);
      return;
    }

    try{
    const creapi = url + "saveMentorTopicDetails";
    const response = await Axios.post(
      creapi,
      {
        id: formData.id ? formData.id : null,
        mentor_id: formData.mentor_id ? formData.mentor_id : mentorId,
        topic_name: formData.topic_name,
      },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-USER-ID": mentorId,
        },
      }
    );

    setApiMessage(response.data.message);
    setApiStatus(response.data.status);
    
    if (!formData.id && response.data.status == 1) {
      setButtonDisabled(true);
    }

    if(response.data.status == 1) setLoadData(true);
    }catch (error) {
      console.error(error);
      // Handle different types of errors
      if (error.response) {
        // The server responded with a status code outside the 2xx range
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
  
        // Example: set an error message based on the status code or error response
        if (error.response.status === 400) {
          setApiMessage("Validation error: Please check your input.");
        } else {
          setApiMessage("An unexpected error occurred. Please try again.");
        }
        setApiStatus(error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
        setApiMessage("No response from the server. Please check your network connection.");
      } else {
        // Something happened in setting up the request that triggered an error
        console.log('Error', error.message);
        setApiMessage("An error occurred. Please try again.");
      }
    }
  };

  const handleClose = () => {
    setShowTopicModal(false);
    setLoadData(true);
  };
  return (
    <Modal show={showTopicModal} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          {isEdit ? "Edit Topic Details" : "Add Topic Details"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? 
        <>
        Loading Data...Please wait { } <Spinner />

        </>
        :
          (
        <Form noValidate onSubmit={saveTopicDetails}>
          <Row className="mb-2">
            <div className="col-4 text-end ">
              <h5 className="m-1">Topic Name</h5>
            </div>
            <div className="col-8">
                <Form.Group className="mb-3" controlId="formtopic_name">
                    <Form.Control
                        type="text"
                        placeholder="Enter Mentorship Topic"
                        name="topic_name"
                        value={formData.topic_name}
                        onChange={handleChange}
                        required
                        isValid={createValidate && formData.topic_name}
                        isInvalid={createValidate && !formData.topic_name}                              
                    />

                <Form.Control.Feedback type="invalid">
                  Please enter topic name
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Row>
          <Row className="mb-2 d-flex d-inline">
            <div className="mb-3 d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary btn-sm"
                disabled={buttonDisabled}
              >
                Save
              </button>

              <button
                type="button"
                className="btn btn-outline-warning btn-sm ms-2"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </Row>
          <Row>
            {apiMessage !== "" && (
              <span className={apiStatus ? "text-success " : "text-danger "}>
                {apiMessage}
              </span>
            )}
            {/*apiStatus==1 && <span className="text-success small">Topic details updated successfully</span> */}
          </Row>{" "}
        </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MentorTopics;
