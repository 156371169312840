import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image
} from "@react-pdf/renderer";
// Create styles
import Certificate_template from "./certificate-v2-Final_Blank.png";
import { Font } from "@react-pdf/renderer";
import React, { useState, useEffect, Fragment } from "react"
import { useHistory } from 'react-router'
import RobotoRegular from "../../../assets/fonts/Roboto/Roboto-Regular.ttf";
import RobotoRegularItalic from "../../../assets/fonts/Roboto/Roboto-Italic.ttf";
import RobotoBold from "../../../assets/fonts/Roboto/Roboto-Bold.ttf";
import RobotoBoldItalic from "../../../assets/fonts/Roboto/Roboto-BoldItalic.ttf";
import RobotoMedium from "../../../assets/fonts/Roboto/Roboto-Medium.ttf";
import RobotoMediumItalic from "../../../assets/fonts/Roboto/Roboto-MediumItalic.ttf";
import RobotoLight from "../../../assets/fonts/Roboto/Roboto-Light.ttf";
import RobotoLightItalic from "../../../assets/fonts/Roboto/Roboto-LightItalic.ttf";
import Url from "../../Url";
import { ToastContainer, toast } from 'react-toastify';

import { Row, Col, Form, Container, Button } from 'react-bootstrap';
// Create Document Component
const BasicDocument =  (props) => {

  const {
    match: {
      params: { id, studentTestId },
    },
    history,
  } = props;

  const url1 = Url();
  const url = url1["url"];
  
  const [personalRef1, setPersonalRef1] = useState("he/she");
  const [personalRef2, setPersonalRef2] = useState("his/her");
  const [personalRef3, setPersonalRef3] = useState("him/her");
  const [certificatedetail, setCertificatedetail] = useState("");
  
  const studentId = localStorage.getItem("studentId");
  const token = localStorage.getItem("stutoken");

  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: RobotoRegular,
      },
      {
        src: RobotoRegularItalic,
        fontStyle: "italic",
      },
      {
        src: RobotoBold,
        fontWeight: 700,
      },
      {
        src: RobotoBoldItalic,
        fontWeight: 700,
        fontStyle: "italic",
      },
      {
        src: RobotoMedium,
        fontWeight: 500,
      },
      {
        src: RobotoMediumItalic,
        fontWeight: 500,
        fontStyle: "italic",
      },
      {
        src: RobotoLight,
        fontWeight: 300,
      },
      {
        src: RobotoLightItalic,
        fontWeight: 300,
        fontStyle: "italic",
      },
    ],
  });
  
  const styles = StyleSheet.create({
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
    page: {
      flexDirection: "column",
      backgroundColor: "#F6F8FA",
      display: "flex",
      width: "100%",
      fontFamily:"Roboto",
      margin: 0,
      padding: 0,    
    },
    section: {
      margin: 0,
      padding: 0,
    },
  });

  //Following values are set to quickly adjust the co-ordinates automatically with respect to each other
  const nameTop = 250;
  const certificateText1Top = nameTop + 60;
  const certificateText2Top = certificateText1Top + 30;
  const certificateText3Top = certificateText2Top + 60;
  const certificateDateTop = certificateText3Top + 60;

  ////////////////////// getcertificate data //////////////////////////
  
  const getByCareerDetailId = () => {
    const api = url + "getCertificateDetails" + "/" + studentId +"/"+ studentTestId;
    fetch(api, {
      method: "GET",
      headers: new Headers({
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json', // adjust the content type as needed
        // other headers as needed
      }),      
    })
      .then((apidata1) => {
        console.log(apidata1);

        return apidata1.json();
      })
      .then((actualdata1) => {
        console.log(actualdata1);
        if(actualdata1.length >=1){
          if (actualdata1[0].title == "Mr.") {
            setPersonalRef1("he");
            setPersonalRef2("his");
            setPersonalRef3("him");
          } else {
            setPersonalRef1("she");
            setPersonalRef2("her");
            setPersonalRef3("her");
          }
          setCertificatedetail(actualdata1[0]);
          //setSubject(actualdata1.subject)
        }else{
          toast.warning("Cannot generate certificate, please contact admin",{autoClose: 1500});
        }
      });    
  };

  useEffect(() => {

    if(studentTestId){
      getByCareerDetailId();
    }else{
    const api = url + "getQuickCertificate" + "/" + id;
    fetch(api, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        console.log(actualdata1);

        if (actualdata1.title == "Mr.") {
          setPersonalRef1("he");
          setPersonalRef2("his");
          setPersonalRef3("him");
        } else {
          setPersonalRef1("she");
          setPersonalRef2("her");
          setPersonalRef3("her");
        }
        setCertificatedetail(actualdata1);
        //setSubject(actualdata1.subject)
      });
    }
  }, []);
  
  //////////////////////////////////////////////////////////////////////

  return (
    <Fragment>
    {certificatedetail.cert_serial_no ? 
    <PDFViewer style={styles.viewer}  >
      {/* Start of the document*/}
      <Document title="certificate.pdf">
        {/*render a single page*/}
        <Page size="A4" orientation="landscape" style={styles.page}>
          <View>
            <Image src={Certificate_template} />
          </View>
          <View style={{position:"absolute", top: "30px", left:"25px",fontSize:"14px"}}>
            <Text style={{fontSize:"10px"}}>Certificate No: {certificatedetail.cert_serial_no}</Text>
          </View>            
          <View style={{position:"absolute",  top:nameTop +"px",color:"#2E3A97", display: "flex", flexDirection: "row",  }}>
            
            <Text style={{fontSize:"30px", fontWeight:"700", textAlign:"center",  width: "100vw", }}>{certificatedetail.name}</Text>
          </View>
          <View style={{position:"absolute", top: certificateText1Top + "px", left:"20px",fontSize:"14px"}}>
            <Text style={{textAlign:"center", paddingHorizontal:"80px", lineHeight:"2px"}}>
                  This is to certify that 
                  <Text style={{fontWeight:"bold"}}> {certificatedetail.name} </Text>has completed  
                  {setPersonalRef1} internship successfully in 
            <Text style={{fontWeight:"bold"}}> {certificatedetail.subject} </Text>
                   at 
                   <Text style={{fontWeight:"bold"}}> ISAN DATA SYSTEMS PVT LTD</Text> from  
                   <Text style={{fontWeight:"bold"}}> {certificatedetail.start_date}</Text> to 
                   <Text style={{fontWeight:"bold"}}> {certificatedetail.end_date}</Text>.
            </Text>
            <Text style={{textAlign:"center",paddingHorizontal:"80px", lineHeight:"2px"}}>
                  During the internship {personalRef1} demonstrated good skills, communication with a self motivated attitude to learn new things. 
                  <Text style={{textTransform:"capitalize"}}> {personalRef2}</Text> performance exceeded expectations and was able to complete the project successfully on time.
               </Text>               
              <Text style={{textAlign:"center",paddingHorizontal:"80px", lineHeight:"2px", position:"absolute", left:"170px", top:"115px"}}>
                  We wish {personalRef3} all the best for {personalRef2} future endeavours.
              </Text>             
          </View>
          <View style={{position:"absolute", bottom: "100px", right:"220px",fontSize:"14px"}}>
            <Text>{certificatedetail.end_date}</Text>
          </View>  
        </Page>
      </Document>
    </PDFViewer>
    :
    <div className="container">
      <Row>
        <Col>
          <p className="py-9">Cannot generate certificate, contact admin</p>
          <p><a href="/lms" className="btn btn-primary btn-sm">Back To LMS</a></p>
          <ToastContainer />
        </Col>
        
      </Row>
    </div>
    }
    </Fragment>
  );
}
export default BasicDocument;