import React, { Fragment, useMemo, useState, useEffect } from 'react';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination,
  } from "react-table";
import { Link } from 'react-router-dom';
import { Col, Row, Dropdown, Image, Table, Tab, Card, Button  } from 'react-bootstrap';
import {
	Trash,
	MoreVertical,
	Edit,
	Move,
	Copy,
	ToggleLeft,
	ToggleRight,
	Video
} from 'react-feather';
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import DotBadge from "components/elements/bootstrap/DotBadge";
import Url from 'components/Url';


// import data files
/*
import {
	AllTemplates,
	allPublishedPosts,
	allScheduledPosts,
	allDraftPosts,
	allDeletedPosts
} from 'data/courses/AllTemplatesData';
*/
const AllTemplates = () => {

    const url1 = Url();
    const url = url1["url"];
	const [emailTemplates,setEmailTemplates] = useState([]);
	const [load,setLoad] = useState(false);
	useEffect(() => {

        const api = url + "getEmailTemplates"

        fetch(api, {
            method: 'GET'
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                setEmailTemplates(actualdata.emailTemplate);
				
            })
		setLoad(false);
    }, [load]);

	const deleteTemplate = (id) => {
		if (id && window.confirm("Are you sure? Want to delete Template?")) {
		  const api = url + "deleteEmailTemplate/" + id;
		  const config = {
			method: "DELETE",
		  };
		  fetch(api, config)
			.then((res) => res.json()) 
			.then((data) => {
			  if (data.message === "Record Deleted") {
				setLoad(true);
				alert("Template Deleted Successfuly");
			  }
			});
		}
	  };

	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">All Templates</h1>
						</div>
						<div>
							<Link to="/bulkemail/add-new-template" className="btn btn-primary">
								New Template
							</Link>
						</div>
					</div>
				</Col>
			</Row>

			<Row>
				<Col lg={12} md={12} sm={12}>
					<Tab.Container defaultActiveKey="all">
						<Card>
							{
						//	<Card.Header className="border-bottom-0 p-0 bg-white">
						//		<Nav className="nav-lb-tab">
						//			<Nav.Item>
						//				<Nav.Link eventKey="all" className="mb-sm-3 mb-md-0">
						//					All
						//				</Nav.Link>
						//			</Nav.Item>
						//			{/** 
						//			<Nav.Item>
						//				<Nav.Link eventKey="published" className="mb-sm-3 mb-md-0">
						//					Published
						//				</Nav.Link>
						//			</Nav.Item>
						//			<Nav.Item>
						//				<Nav.Link eventKey="scheduled" className="mb-sm-3 mb-md-0">
						//					Scheduled
						//				</Nav.Link>
						//			</Nav.Item>
						//			<Nav.Item>
						//				<Nav.Link eventKey="draft" className="mb-sm-3 mb-md-0">
						//					Draft
						//				</Nav.Link>
						//			</Nav.Item>
						//			<Nav.Item>
						//				<Nav.Link eventKey="deleted" className="mb-sm-3 mb-md-0">
						//					Deleted
						//				</Nav.Link>
						//			</Nav.Item>
						//			*/}									
						//		</Nav>
						//	</Card.Header>
						}
							<Card.Body className="p-0">
								<Tab.Content>
									{/* 
									<Tab.Pane eventKey="all" className="pb-0">
										<PostsTable table_data={emailTemplates} />
									</Tab.Pane>
									*/}
									<Tab.Pane eventKey="all" className="pb-0">
										<RecordTable table_data={emailTemplates} deleteTemplate={deleteTemplate} />
									</Tab.Pane>									
									{/**
									<Tab.Pane eventKey="published" className="pb-0">
										<PostsTable table_data={allPublishedPosts} />
									</Tab.Pane>
									<Tab.Pane eventKey="scheduled" className="pb-4">
										<PostsTable table_data={allScheduledPosts} />
									</Tab.Pane>
									<Tab.Pane eventKey="draft" className="pb-4">
										<PostsTable table_data={allDraftPosts} />
									</Tab.Pane>
									<Tab.Pane eventKey="deleted" className="pb-4">
										<PostsTable table_data={allDeletedPosts} />
									</Tab.Pane>
									 */}
								</Tab.Content>
							</Card.Body>
						</Card>
					</Tab.Container>
				</Col>
			</Row>
		</Fragment>
	);
};

const RecordTable = ({ table_data, deleteTemplate }) => {
	const url1 = Url();
	const s3 = url1.s3;
	const url = url1.url;
  
	// The forwardRef is important!!
	// Dropdown needs access to the DOM node in order to position the Menu
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
	  <Link
		to="#"
		ref={ref}
		onClick={(e) => {
		  e.preventDefault();
		  onClick(e);
		}}
	  >
		{children}
	  </Link>
	));

	const ActionMenu = () => {
	  return (
		<Dropdown>
		  <Dropdown.Toggle as={CustomToggle}>
			<MoreVertical size="15px" className="text-secondary" />
		  </Dropdown.Toggle>
		  <Dropdown.Menu align="end">
			<Dropdown.Header>SETTINGS</Dropdown.Header>
			<Dropdown.Item eventKey="1">
			  {" "}
			  <XCircle size="18px" /> Reject with Feedback
			</Dropdown.Item>
		  </Dropdown.Menu>
		</Dropdown>
	  );
	};
  
	const columns = useMemo(
	  () => [
		{ accessor: "id", Header: "ID", show: false },
		{
		  accessor: "email_subject",
		  Header: "Email Subject",
		  Cell: ({ value, row }) => {
			let date;
			if (row.original.created_at) {
			  date = row.original.created_at.split(" ")[0];
			} else {
			  date = "NA";
			}
			return (
			  <Link
				className="text-inherit"
				to={
				  "/bulkemail/add-new-template/" +
				  row.original.id
				}
			  >
				{value}
			  </Link>
			);
		  },
		},
		{ accessor: "created_at", Header: "Created at"},
		{ accessor: "updated_at", Header: "Last Modified"},
		{ accessor: "last_email_sent_at", Header: "Last Email sent at"},
        { accessor: 'action', Header: 'Action',
            Cell: ({value, row}) => {
                return(
                    <Button className="btn-sm btn-outline-danger btn-white m-1" onClick={() => deleteTemplate(row.original.id)}>
                        Delete
                    </Button>
                )
            }
        },
	  ],
	  []
	);
  
	const data = useMemo(() => table_data, [table_data]);
  
	const {
	  getTableProps,
	  getTableBodyProps,
	  headerGroups,
	  page,
	  nextPage,
	  previousPage,
	  state,
	  gotoPage,
	  pageCount,
	  prepareRow,
	  setGlobalFilter,
	} = useTable(
	  {
		columns,
		data,
		initialState: {
		  pageSize: 10,
		  hiddenColumns: columns.map((column) => {
			if (column.show === false) return column.accessor || column.id;
			else return false;
		  }),
		},
	  },
	  useFilters,
	  useGlobalFilter,
	  usePagination
	);
  
	const { pageIndex, globalFilter } = state;
  
	return (
	  <Fragment>
		<div className=" overflow-hidden">
		  <Row>
			<Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-4 px-5 ">
			  <GlobalFilter
				filter={globalFilter}
				setFilter={setGlobalFilter}
				placeholder="Search Course"
			  />
			</Col>
		  </Row>
		</div>
  
		<div className="table-responsive border-0 overflow-y-hidden">
		  <Table {...getTableProps()} className="text-nowrap">
			<thead className="table-light">
			  {headerGroups.map((headerGroup) => (
				<tr {...headerGroup.getHeaderGroupProps()}>
				  {headerGroup.headers.map((column) => (
					<th {...column.getHeaderProps()}>
					  {column.render("Header")}
					</th>
				  ))}
				</tr>
			  ))}
			</thead>
			<tbody {...getTableBodyProps()}>
			  {page.map((row) => {
				prepareRow(row);
				return (
				  <tr {...row.getRowProps()}>
					{row.cells.map((cell) => {
					  return (
						<td {...cell.getCellProps()}>{cell.render("Cell")}</td>
					  );
					})}
				  </tr>
				);
			  })}
			</tbody>
		  </Table>
		</div>
  
		{/* Pagination @ Footer */}
		<Pagination
		  previousPage={previousPage}
		  pageCount={pageCount}
		  pageIndex={pageIndex}
		  gotoPage={gotoPage}
		  nextPage={nextPage}
		/>
	  </Fragment>
	);
  };
  

export default AllTemplates;
