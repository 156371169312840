// import node module libraries
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Avatar1 from 'assets/images/avatar/avatar-1.jpg';
// import sub components
import TestimonialCard from './TestimonialCard';

const TestimonialsSlider = () => {
	const settings = {
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					initialSlide: 2
				}
			},
			{
				breakpoint: 540,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};
	const TestimonialsList = [
		{
			id: 1,
			name: 'Sandip Chauhan',
			designation: 'Sheffield University, UK',
			image: "https://internshipgates3.s3.ap-south-1.amazonaws.com/Account.svg",
			content:
			"Thanks to the internshipgate's online service, I was able to turn my dream of studying overseas into a reality. The platform provided invaluable guidance, streamlined the application process, and connected me with universities worldwide. It was a game-changer for me, and now I'm thriving in a new country, experiencing different cultures, and building an international network that will last a lifetime. I couldn't have done it without this fantastic service!",
			rating: 5.0,
			color: 'primary'
		},
		{
			id: 2,
			name: 'Meenal singh',
			designation: 'Canada',
			image: "https://internshipgates3.s3.ap-south-1.amazonaws.com/Account.svg",
			content:
			"The internshipgate's online service made my international education journey incredibly smooth. From helping me choose the perfect university to assisting with visa applications and scholarship opportunities, they were there every step of the way. I'm now studying abroad, broadening my horizons, and gaining a world-class education that wouldn't have been possible without their support. I can't thank them enough for making my dreams come true.",
			rating: 5.0,
			color: 'info'
		},
		{
			id: 3,
			name: 'Soumya Nagpure',
			designation: 'USA',
			image: "https://internshipgates3.s3.ap-south-1.amazonaws.com/Account.svg",
			content:
			"Using the internshipgate's online service was a game-changer for me. It took the complexity out of the application process and connected me with universities I hadn't even considered. Thanks to their expertise and resources, I'm now studying abroad, immersed in a new culture, and growing both academically and personally. This service has been an invaluable partner on my educational journey.",
			rating: 5.0,
			color: 'danger'
		},
	];	
	return (
		<Fragment>
			<Slider {...settings} className="pb-5 mb-5 testimonials">
				{TestimonialsList.map((item, index) => (
					<div className="item p-2" key={item.id}>
						<TestimonialCard key={index} item={item} />
					</div>
				))}
			</Slider>
		</Fragment>
	);
};

// Specifies the default values for props
TestimonialsSlider.defaultProps = {
	recommended: false,
	popular: false,
	trending: false
};

// Typechecking With PropTypes
TestimonialsSlider.propTypes = {
	recommended: PropTypes.bool,
	popular: PropTypes.bool,
	trending: PropTypes.bool
};

export default TestimonialsSlider;
