import React from "react";
import cx from "classnames";
import "./index.css";
import { arrayOf, elementType, node, oneOfType, string } from "prop-types";

const Label = React.forwardRef((props, ref) => {
  const { as: ComponentClass, children, className, ...otherProps } = props;
  return (
    <ComponentClass ref={ref} className={cx("atom__label", className)} {...otherProps}>
      {children}
    </ComponentClass>
  );
});

Label.propTypes = {
  as: elementType,
  children: oneOfType([node, arrayOf(node)]).isRequired,
  className: string
};

Label.defaultProps = {
  as: "label",
  className: ""
};

export default Label;
