/* eslint-disable prettier/prettier */
// import node module libraries
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Container, Tab } from "react-bootstrap";
import { useHistory } from "react-router";


import Url from "components/Url";
// import sub components
import FilterOptions from "./FilterOptions";
import TopBanner from "./TopBanner";
import LogosTopHeading from "components/marketing/common/clientlogos/LogosTopHeading";
import LogoList from "./LogoList";
import { Helmet } from "react-helmet";
import WorldClassInstructors from "./WorldClassInstructors";

const Mentorship = () => {
  // const history = useHistory();

  // const stutoken = localStorage.getItem("stutoken");
  // console.log(stutoken);

  const url1 = Url();
  const url = url1["url"];
  const s3 = url1["s3"];

  const [s3url, setS3url] = useState(s3 + "imguploadvideolink/");
  const [mentorList, setMentorList] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const [levelFilter, setlevelFilter] = useState([]); // data coming from filterOptions
  const [categoryFilter, setCategoryFilter] = useState([]); // data coming from filterOptions
  const [mentorProviderFilter, setMentorProviderFilter] = useState([]); // data coming from filterOptions
  const [certificationFilter, setCertificationFilter] = useState([]); // data coming from filterOptions
  const [mentorLengthFilter, setMentorLengthFilter] = useState([]); // data coming from filterOptions
  const [seodetails, setSeodetails] = useState("");
  
  function filter_data_child(
    level,
    category,
    mentorProvider,
    certification,
    mentorLengthFilter
  ) {
    setlevelFilter(level);
    setCategoryFilter(category);
    setMentorProviderFilter(mentorProvider);
    setCertificationFilter(certification);
    setMentorLengthFilter(mentorLengthFilter);
  }

  // console.log("DATA from filter options : ", certificationFilter);

  // const sortByOptions = [
  // 	{ value: 'newest', label: 'Newest' },
  // 	{ value: 'free', label: 'Free' },
  // 	{ value: 'most-popular', label: 'Most Popular' },
  // 	{ value: 'highest-rated', label: 'Highest Rated' }
  // ];

  useEffect(() => {
    const api = url + "getMentorList";
    fetch(api, { method: "GET" })
      .then((response) => response.json())
      .then((actualResponse) => {
        setMentorList(actualResponse.mentorList);
        // const allMentorsCat = actualResponse.map((data) => data.category);
        // const uniqueCategories = [...new Set(allMentorsCat)];
        // setCategoryOptions(uniqueCategories);
      });

		/*********** get page SEO Settings */

		var api1 = url + "getPageSeoSettingById/" + 8;
		fetch(api1, {
		method: "GET",
		})
		.then((apidata1) => {
		return apidata1.json();
		})
		.then((actualdata1) => {
		setSeodetails(actualdata1);
		});      
  }, []);

  // console.log("DATA", mentorData);
  // console.log("Categories", categoryOptions);

  return (
    <Fragment>
      <Helmet>
        <title>{seodetails.page_title}</title>
        <meta name="keywords" content={seodetails.page_meta_keyword} />
        <meta name="description" content={seodetails.page_meta_description} />
      </Helmet>      
      
      <TopBanner />

      {/* <LogosTopHeading title="Our Trusted Partners" logos={LogoList} /> */}

      {/* <Features4Columns /> */}
      {/* Page header */}
      {/* <PageHeading pagetitle="Filter Page" /> */}

      {/* Content */}
      <div className="py-1">
        <Container>
          <Tab.Container defaultActiveKey="grid">
            <Row>

              <Col xl={3} lg={3} md={4} sm={12} className="mb-4 mb-lg-0">
                 <FilterOptions
                  categoryOptions={categoryOptions}
                  onData={filter_data_child}
                />
              </Col>
              {/* Tab content */}
              <Col xl={9} lg={9} md={8} sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="grid" className="pb-4 px-0">
                    <WorldClassInstructors mentorList={mentorList} />
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="list" className="pb-4 px-0 react-code">
                    <MentorListView
                      mentorData={mentorData}
                      mentorImg={s3url}
                      levelFilter={levelFilter}
                      categoryFilter={categoryFilter}
                      mentorProviderFilter={mentorProviderFilter}
                      certificationFilter={certificationFilter}
                      mentorLengthFilter={mentorLengthFilter}
                    />
                  </Tab.Pane> */}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </div>
    </Fragment>
  );
};

export default Mentorship;
