// import node module libraries
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Image,
    Navbar,
    Container,
    Dropdown,
    ListGroup,
    Row,
    Col,
    DropdownButton,
    Modal,
    Tabs,
    Tab,
    Form,
    Button,
    ModalHeader
} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import Url from 'components/Url';
// import media files


const ResetPasswordModal = (props) => {
   
    const url1 = Url();
    const url = url1.url
    const url2 = url1["url1"]
    const [show, setShow]  = useState(false)
    const [token, setToken] = useState('')

    const [pwdchngmodal, setPwdchngmodal] = useState(false);

	const pwdmodalClose = () => setPwdchngmodal(false);

	const [newpwd, setNewpwd] = useState("");
	const [confirmpwd, setConfirmpwd] = useState("");
	const [password, setPassword] = useState("");
    const stutoken = localStorage.getItem("stutoken");
    const email = localStorage.getItem("email");
    const pwdchange = (e) => {
		//alert("")
		e.preventDefault();
		if (newpwd !== confirmpwd) {
			toast.error("Password mismatch", {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
			});
			return;
		}
		const new_password = newpwd;
		const api = url + "UpdateStudentPassword/" + email;
		fetch(api, {
			method: "POST",
			body: JSON.stringify({ email, password, new_password }),
			headers: { "Content-Type": "application/json" },
		})
			.then((apidata) => {
				//console.log(apidata.body)
				return apidata.json();
			})
			.then((actualdata) => {
				//console.log(actualdata);
				if (actualdata["message"] === "password_not_found") {
					toast.error("Worng password....", {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 2000,
					});
					return;
				}
				if (actualdata["message"] === "Records_updated_successfully") {
					pwdmodalClose();
					toast.success("Password changed successfully....", {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 2000,
					});
				}
			});
	};

        return (
            <>
            <ToastContainer />
            <Modal
                {...props}
                centered
            >
                <Modal.Header closeButton style={{border:"0",padding:"15px 25px 0 0"}}></Modal.Header>              
                
                <Modal.Body className="mt-0 firstloginmodal modalnotification">
						<Row>
							<span className="board">Current Password</span>
						</Row>
						<Row className="">
							<Col lg="12" className="p-0">
								<Form.Control
									size="md"
									type="password"
									placeholder=""
									className="college-w"
									name="password"
									onChange={(e) => setPassword(e.target.value)}
									required
								/>
							</Col>
						</Row>
						<Row>
							<span className="board">New Password</span>
						</Row>
						<Row className="">
							<Col lg="12" className="p-0">
								<Form.Control
									size="md"
									type="password"
									placeholder=""
									className="college-w"
									name="newpassword"
									onChange={(e) => setNewpwd(e.target.value)}
									required
								/>
							</Col>
						</Row>
						<Row>
							<span className="board">Confirm Password</span>
						</Row>
						<Row className="">
							<Col lg="12" className="p-0">
								<Form.Control
									size="md"
									type="password"
									placeholder=""
									className="college-w"
									name="confirmpassword"
									onChange={(e) => setConfirmpwd(e.target.value)}
									required
								/>
							</Col>
						</Row>
                        <div className='d-flex justify-content-center'>
                            <Button className="mt-2 mb-2" onClick={pwdchange}>
                                Change Password
                            </Button>
                        </div>
					</Modal.Body>
                
                
            </Modal>
            </>
           
        );
}

export default ResetPasswordModal