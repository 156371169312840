import React from 'react';
import "./StarRating.css";

const StarRating = ({ value, onChange, viewOnly=false }) => {
  return (
    <div className="star-rating d-flex">
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            key={index}
            className={index <= value ? "on" : "off"}
            onClick={() => onChange(index)}
            disabled={viewOnly}
          >
            <span className="star">&#9733;</span>
          </button>
        );
      })}
    </div>
  );
};

export default StarRating;