import React, { useState } from "react";
import { Card, Row, Col, Image, Button } from "react-bootstrap";
import Frame from "../../assets/images/png/Frame.png";
import { Link } from "react-router-dom";
import Url from "components/Url";

function CustomerStoriesCard(props) {

  const { item, handleVideo, index } = props;

  const [MobileScr, setMobilescr] = useState(
    window.innerWidth > 520 ? false : true
  );

  const url1 = Url();
  const url = url1["url"];
  const s3 = url1["s3"];
  const s3url = s3 + "blogImages/";

  return (
    
    <div className="d-flex justify-content-center">
      <div className={` card ${MobileScr ? "w-100" : "w-75"}`}>
        <div className={`d-md-flex d-flex justify-content-between flex-wrap`}>
          <Col md={4}>
            <img
              onClick={() => handleVideo(item.videoId)}
              src={item.img}
              alt=".."
              width="100%"
              className="p-2"
            />
          </Col>
          <Col md={6} className="ml-5">
            <div className="d-flex flex-column justify-content-between p-2  mt-5">
              <div>
                <img
                  src={Frame}
                  className="mb-3"
                  style={{ width: "40px", height: "40px" }}
                ></img>
              </div>
              <div>
                <p>{item.description}</p>
                <div>
                  <h4>{item.name}</h4>
                  {item.designation}
                </div>
              </div>
            </div>
          </Col>
        </div>
      </div>
    </div>

    // <Col xl={{ span: 10, offset: 1 }} md={12} xs={12}>
    //   <div className="card">
    //     <Row className="align-items-center">
    //       <Col lg={5} md={12} xs={12}>
    //         <div>
    //           <Image
    //             onClick={() => handleVideo(item.videoId)}
    //             src={item.img}
    //             alt=""
    //             className=" w-100 p-2"
    //           />
    //         </div>
    //       </Col>

    //       <Col lg={7} md={12} xs={12}>
    //         <div className="d-flex flex-column justify-content-between p-2 mt-5">
    //           <div>
    //             <img
    //               src={Frame}
    //               className="mb-3"
    //               style={{ width: "40px", height: "40px" }}
    //             ></img>
    //           </div>
    //           <div className="d-flex flex-column p-4">
    //             <p className="display-7 mb-4 ml-10 lh-2">{item.description} </p>
    //             <p className="mb-0 ">
    //               <h4>{item.name}</h4>
    //             </p>
    //             <span className=" ml-3">{item.designation}</span>
    //           </div>
    //         </div>
    //       </Col>
    //     </Row>
    //   </div>
    // </Col>
  );
}

export default CustomerStoriesCard;
