import React, { useEffect, useState } from "react";
import { Accordion, Col, Row, Form } from "react-bootstrap";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { InputField, Label, Button } from "../../foundation";
import { Controller } from "react-hook-form";
import Api, { ENDPOINTS } from "../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slider from "@material-ui/core/Slider";
import "./Skill.css";

// Sample job title to skills mapping
const JOB_TITLE_SKILL_MAP = {
    // IT & Tech
    "Full Stack Developer": ["MongoDB", "Express.js", "React", "Node.js", "Angualr", "MySQL"],
    "Software Engineer": ["JavaScript", "React", "Node.js", "Python", "Java"],
    "Frontend Developer": ["HTML", "CSS", "JavaScript", "React", "Responsive Design", "Version Control (Git)", "Bootstrap", "UI/UX Design", "Angular", "APIs"],
    "Backend Developer": ["Node.js", "Express.js", "Python", "Django", "Ruby on Rails", "SQL", "NoSQL", "REST APIs", "GraphQL", "Microservices", "Docker", "Kubernetes", "CI/CD", "AWS", "Azure"],
    "Data Scientist": ["Python", "R", "Machine Learning", "Data Analysis", "SQL"],
    "Web Developer": ["HTML", "CSS", "JavaScript", "React", "Node.js"],
    "Mobile App Developer": ["Swift", "Kotlin", "Flutter", "iOS", "Android"],
    "Cloud Engineer": ["AWS", "Azure", "Google Cloud", "DevOps", "Docker", "Kubernetes"],
    "Cybersecurity Specialist": ["Network Security", "Ethical Hacking", "Cryptography", "Risk Assessment", "Penetration Testing"],
    "AI Engineer": ["Machine Learning", "Deep Learning", "Python", "TensorFlow", "Natural Language Processing"],
    "Blockchain Developer": ["Solidity", "Smart Contracts", "Ethereum", "Cryptography", "Decentralized Applications"],
    "Digital Marketer": ["SEO", "Google Analytics", "Content Marketing", "Social Media Marketing", "Email Marketing", "PPC", "Conversion Rate Optimization (CRO)", "Copywriting", "Affiliate Marketing", "A/B Testing", "Marketing Automation", "Google Ads", "Facebook Ads", "Data Analysis", "Influencer Marketing", "Brand Management"],
    "HR": ["Recruitment", "Employee Relations", "Performance Management", "Payroll", "Compliance"],
    "Data Analyst": ["SQL", "Python", "Excel", "Data Visualization", "Tableau", "Power BI", "Statistical Analysis", "Data Cleaning", "R", "Machine Learning", "Business Intelligence", "Data Mining"],
    "Application Packager": ["SCCM", "Windows Installer (MSI)", "PowerShell", "Application Deployment", "Troubleshooting"],
    "Storage Engineer": ["SAN Management", "NAS Management", "Backup and Recovery", "Data Migration", "Storage Virtualization"],
    "IoT Engineer": ["Embedded Systems", "IoT Protocols", "Cloud Platforms", "Sensor Integration", "Networking"],
    "Power BI Developer": ["Data Modeling", "DAX (Data Analysis Expressions)", "Power Query", "Data Visualization", "SQL"],
    "Game Developer": ["Unity", "C#", "Unreal Engine", "3D Modeling", "Game Physics", "Animation", "Artificial Intelligence", "Game Design", "Version Control (Git)", "Problem-Solving"],
    "AR/VR Developer": ["Unity", "Unreal Engine", "3D Modeling", "C#", "VR/AR SDKs", "Spatial Computing", "Gesture Recognition", "Computer Vision", "User Interface Design", "Physics Simulation"],
    "DevOps Engineer": ["CI/CD Pipelines", "Docker", "Kubernetes", "AWS", "Linux", "Automation Scripting", "Jenkins", "Ansible", "Monitoring and Logging", "Version Control (Git)"],
    "Systems Administrator": ["Linux Administration", "Windows Server", "Network Configuration", "Scripting", "Backup Solutions"],
    "Database Administrator": ["SQL", "Database Design", "Performance Tuning", "Backup and Recovery", "Security Management"],
    "Technical Support Specialist": ["Problem-Solving", "Customer Service", "Technical Knowledge", "Communication", "Documentation"],
    "Business Analyst": ["Data Analysis", "SQL", "Communication", "Requirements Gathering", "Process Improvement"],
    "DBA": ["SQL", "Database Design", "Performance Tuning", "Backup and Recovery", "Security Management"],
    "Content Creator": ["Content Strategy", "Copywriting", "Social Media Management", "SEO Optimization",
        "Video Production", "Graphic Design", "Editing and Proofreading", "Research Skills", "Audience Analysis",
        "Content Management Systems (CMS)", "Brand Storytelling", "Adaptability", "Project Management",
        "Analytical Skills", "Collaboration"],

    // Medical Field
    "Doctor": ["Patient Diagnosis", "Medical Knowledge", "Surgery", "Critical Thinking", "EMR"],
    "Nurse": ["Patient Care", "Clinical Skills", "Medical Terminology", "Communication", "EMR"],
    "Pharmacist": ["Pharmacology", "Prescription Management", "Medication Therapy", "Customer Service", "Medical Terminology"],
    "Dentist": ["Oral Health", "Dental Surgery", "Patient Care", "X-ray Diagnosis", "Communication"],
    "Veterinarian": ["Animal Care", "Surgery", "Diagnosis", "Animal Anatomy", "Communication"],

    // Teaching & Education
    "Teacher": ["Lesson Planning", "Classroom Management", "Communication", "Curriculum Development", "Assessment"],
    "Professor": ["Research", "Lecturing", "Academic Writing", "Curriculum Design", "Student Mentorship"],
    "School Counselor": ["Student Guidance", "Counseling", "Psychology", "Communication", "Conflict Resolution"],
    "Special Education Teacher": ["IEP Development", "Behavioral Management", "Adapted Instruction", "Communication", "Patience"],

    // Defense & Military
    "Army Officer": ["Leadership", "Strategic Planning", "Physical Fitness", "Communication", "Tactical Operations"],
    "Police Officer": ["Law Enforcement", "Physical Fitness", "Negotiation", "Conflict Resolution", "Problem Solving"],
    "Firefighter": ["Emergency Response", "Fire Safety", "Physical Fitness", "Rescue Operations", "Problem Solving"],
    "Military Pilot": ["Aircraft Operation", "Navigation", "Critical Thinking", "Leadership", "Team Coordination"],

    // Science & Research
    "Research Scientist": ["Laboratory Skills", "Data Analysis", "Research Methodology", "Critical Thinking", "Grant Writing"],
    "Astronomer": ["Telescope Operation", "Data Analysis", "Astrophysics", "Programming", "Research"],
    "Marine Biologist": ["Marine Ecology", "Field Research", "Data Analysis", "SCUBA Diving", "Conservation"],
    "Physicist": ["Mathematics", "Theoretical Analysis", "Experiment Design", "Research", "Problem Solving"],

    // Animal Care & Agriculture
    "Zoologist": ["Animal Behavior", "Conservation", "Research", "Data Collection", "Field Work"],
    "Animal Trainer": ["Training Techniques", "Animal Behavior", "Patience", "Communication", "Safety Protocols"],
    "Farmer": ["Crop Management", "Animal Husbandry", "Soil Science", "Farm Equipment Operation", "Irrigation"],
    "Agricultural Scientist": ["Soil Science", "Crop Management", "Data Analysis", "Agricultural Research", "Food Production"],

    // Creative & Design
    "Graphic Designer": ["Adobe Photoshop", "Illustrator", "UI/UX Design", "Creativity", "Typography"],
    "Product Designer": ["Design Thinking", "Prototyping", "User Research", "Figma", "Industrial Design"],
    "Fashion Designer": ["Sketching", "Sewing", "Pattern Making", "Creativity", "Textile Knowledge"],
    "Interior Designer": ["Space Planning", "AutoCAD", "Color Theory", "Furniture Design", "Creativity"],

    // Business & Management
    "Product Manager": ["Agile", "Scrum", "Project Management", "Communication", "Strategic Thinking"],
    "Business Analyst": ["Data Analysis", "SQL", "Communication", "Requirements Gathering", "Process Improvement"],
    "HR Manager": ["Recruitment", "Employee Relations", "Performance Management", "Payroll", "Compliance"],
    "Sales Manager": ["Sales Strategy", "CRM", "Lead Generation", "Negotiation", "Communication"],
    "Marketing Manager": ["Market Research", "Digital Marketing", "SEO", "Brand Management", "Google Analytics"],
    "Customer Support Specialist": ["Communication", "Problem-Solving", "CRM", "Customer Service", "Active Listening"],

    // Construction & Engineering
    "Mechanical Engineer": ["AutoCAD", "SolidWorks", "MATLAB", "Thermodynamics", "Problem-Solving"],
    "Civil Engineer": ["Structural Analysis", "AutoCAD", "Project Management", "Surveying", "Building Codes"],
    "Electrical Engineer": ["Circuit Design", "Power Systems", "Control Systems", "MATLAB", "PLC Programming"],
    "Architect": ["AutoCAD", "SketchUp", "Building Codes", "3D Modeling", "Structural Engineering"],

    // Arts & Entertainment
    "Musician": ["Music Theory", "Instrument Skills", "Composition", "Collaboration", "Stage Performance"],
    "Actor": ["Acting Skills", "Voice Modulation", "Improvisation", "Memorization", "Collaboration"],
    "Photographer": ["Photography", "Photo Editing", "Creativity", "Composition", "Adobe Photoshop"],
    "Video Editor": ["Video Editing", "Adobe Premiere Pro", "After Effects", "Creativity", "Storytelling"],

    // Legal & Compliance
    "Legal Advisor": ["Legal Research", "Contract Law", "Compliance", "Negotiation", "Litigation"],
    "Compliance Officer": ["Regulatory Knowledge", "Risk Assessment", "Auditing", "Communication", "Legal Research"],

    // Logistics & Supply Chain
    "Supply Chain Manager": ["Logistics", "Inventory Management", "Procurement", "Supply Chain Optimization", "Negotiation"],
    "Warehouse Manager": ["Inventory Control", "Logistics", "Leadership", "Problem Solving", "Forklift Operation"],

    //Fresher
    "Fresher": ["Communication", "Teamwork", "Time Management", "Critical Thinking", "Adaptability", "Interpersonal Skills", "Basic Computer Skills"],
    "Student": ["Sports", "crafting", "Gaming", "Studies",],
};




// General skills
const GENERAL_SKILLS = ["Communication", "Teamwork", "Problem-solving", "Leadership", "Creativity"];

// Skill levels mapping
const SKILLS_MAP = ["Novice", "Beginner", "Intermediate", "Expert"];

const Skill = ({ jobTitle, watch, register, fields, append, remove, control }) => {
    const [deleting, setDeleting] = useState(null);
    const [suggestedSkills, setSuggestedSkills] = useState([]);
    const skills = watch("skills");

    // useEffect(() => {
    //     
    //     const skillsForJobTitle = JOB_TITLE_SKILL_MAP[jobTitle] || [];
    //     setSuggestedSkills(skillsForJobTitle);
    // }, [jobTitle]);

    // Suggest skills based on job title

    useEffect(() => {
        if (!jobTitle) {
            // If jobTitle is undefined or empty, reset suggested skills
            setSuggestedSkills([]);
            return;
        }

        // Normalize job title to lowercase and consider only the first 3 letters
        const normalizedJobTitle = jobTitle.toLowerCase().slice(0, 3);

        // Find a matching job title in JOB_TITLE_SKILL_MAP that starts with the same initial 3 letters
        const matchingJobTitle = Object.keys(JOB_TITLE_SKILL_MAP).find(key =>
            key.toLowerCase().startsWith(normalizedJobTitle)
        );

        // Get the skills for the matching job title or set an empty array if no match
        const skillsForJobTitle = JOB_TITLE_SKILL_MAP[matchingJobTitle] || [];
        setSuggestedSkills(skillsForJobTitle);
    }, [jobTitle]);



    const handleDelete = async (index, id) => {
        try {
            setDeleting(index);
            if (id) {
                await Api.delete(`${ENDPOINTS.DELETE_SKILL}/${id}`);
            }
            remove(index);
        } catch (error) {
            console.error("Failed to delete skill:", error);
        } finally {
            setDeleting(null);
        }
    };

    const handleSkillSelect = (skill) => {
        // Check if skill is already selected
        const isSkillAlreadySelected = skills?.some((s) => s.skill_name === skill);
        if (!isSkillAlreadySelected) {
            append({ skill_name: skill, level: 1 });
        }
    };

    return (
        <div className="resume-builder__section">
            <h2>Skills</h2>
            <Label as="p">
                Choose important skills to showcase your talents based on your Job Profile: {jobTitle}
            </Label>

            {/* Display suggested skills based on job title */}
            {suggestedSkills.length > 0 && (
                <div className="skills-selection">
                    {/* <h4>Suggested Skills</h4> */}
                    {suggestedSkills.map((skill, index) => (
                        <div
                            key={index}
                            className={`skill-box ${skills?.some(s => s.skill_name === skill) ? "selected-skill" : ""}`}
                            onClick={() => handleSkillSelect(skill)}
                        >
                            {skill}
                        </div>
                    ))}
                </div>
            )}

            {/* Display general skills */}
            <div className="skills-selection">
                {/* <h4>General Skills</h4> */}
                {GENERAL_SKILLS.map((skill, index) => (
                    <div
                        key={index}
                        className={`skill-box ${skills?.some(s => s.skill_name === skill) ? "selected-skill" : ""}`}
                        onClick={() => handleSkillSelect(skill)}
                    >
                        {skill}
                    </div>
                ))}
            </div>

            {fields.map((field, index) => {
                const { skill_name, level } = skills?.[index] || {};
                return (
                    <div key={field.key} className="resume-builder__section__card p-0">
                        <input
                            type="hidden"
                            id={`skills[${index}].id`}
                            name={`skills[${index}].id`}
                            defaultValue={field.id}
                            {...register(`skills[${index}].id`)}
                        />
                        <Accordion defaultActiveKey={`${field.id ? "" : field.key}`}>
                            <Accordion.Item
                                as={"div"}
                                variant="link"
                                eventKey={`${field.key}`}
                                className="resume-builder__section__card--title"
                            >
                                <Accordion.Header className="w-100">
                                    <div className="m-0">
                                        <p className="m-0">{skill_name || "(Not Specified)"}</p>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body eventKey={`${field.key}`}>
                                    <Row className="mb-3">
                                        <Col>
                                            <Label>Skill</Label>
                                            <Controller
                                                control={control}
                                                name={`skills[${index}].skill_name`}
                                                render={({
                                                    field: { onChange, onBlur, value, name, ref },
                                                }) => (
                                                    <InputField
                                                        stretch
                                                        placeholder="Enter Skill Name"
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        inputRef={ref}
                                                        value={skill_name}
                                                    />
                                                )}
                                            />
                                        </Col>
                                        <Col>
                                            <Label className="mb-0">{SKILLS_MAP[level] || ""}</Label>
                                            <Controller
                                                control={control}
                                                name={`skills[${index}].level`}
                                                render={({ field: { onChange, value } }) => (
                                                    <Slider
                                                        min={0}
                                                        max={3}
                                                        value={value}
                                                        onChange={(e, val) => onChange(val)}
                                                        style={{ color: "#007bff" }}
                                                    />
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Button
                            variant="link"
                            disabled={deleting === index}
                            className="resume-builder__section__card--delete"
                            onClick={() => handleDelete(index, field.id)}
                        >
                            {deleting === index ? (
                                <CircularProgress size={20} color="primary" />
                            ) : (
                                <FiTrash2 size={25} />
                            )}
                        </Button>
                    </div>
                );
            })}

            <Row className="mb-3">
                <Col>
                    <Button
                        className="text-info fs-4"
                        variant="link"
                        type="button"
                        onClick={() => append({ level: 1 })}
                    >
                        <FiPlus />
                        {fields.length < 1 ? "Add Skill" : "Add one more Skill"}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default Skill;