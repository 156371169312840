import Template1 from "./template1";
import Template2 from "./template2";
import Template3 from "./template3";
import Template4 from "./template4";
import Template5 from "./template5";
import Template6 from "./template6";
import Template7 from "./template7";
import Template8 from "./template8";
import Template9 from "./template9";
import Template10 from "./template10";
import Template11 from "./template11/template11";
import Template12 from "./template12";
import Template13 from "./template13";
//import Template14 from "./template14";
import Template15 from "./template15";
import Template16 from "./template16";
import Template17 from "./template17";
import Template18 from "./template18";
import Template20 from "./template20";
import Template21 from "./template21";
import Template22 from "./template22";
import Template23 from "./template23";

export default {
  [Template1.displayName]: Template1,
  [Template2.displayName]: Template2,
  [Template3.displayName]: Template3,
  [Template4.displayName]: Template4,
  [Template5.displayName]: Template5,
  [Template6.displayName]: Template6,
  [Template7.displayName]: Template7,
  [Template8.displayName]: Template8,
  [Template9.displayName]: Template9,
  [Template10.displayName]: Template10,
  [Template11.displayName]: Template11,
  [Template12.displayName]: Template12,
  [Template13.displayName]: Template13,
 // [Template14.displayName]: Template14,
  [Template15.displayName]: Template15,
  [Template16.displayName]: Template16,
  [Template17.displayName]: Template17,
  [Template18.displayName]: Template18,
  [Template20.displayName]: Template20,
  [Template21.displayName]: Template21,
  [Template22.displayName]: Template22,
  [Template23.displayName]: Template23,
 

};
