// import node module libraries
import React from 'react';
import { Card, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useState } from 'react';
// import React, { Fragment, useEffect, useState } from 'react';

import Url from '../Url';

const url1 = Url();
const url = url1["url"];
const s3 = url1["s3"];
const s3url = s3 + "blogImages/";

const BlogCard = ({ item }) => {
  const [categoryFilter, setCategoryFilter] = useState("");

  const handleCategoryFilter = (value) => {
    setCategoryFilter(value);
  };
	const CategoryColors = (category) => {
		switch (category) {
			case '1':
				return 'success';
			case '2':
				return 'primary';
			case '3':
				return 'warning';
			case '4':
				return 'info';
			default:
				return 'primary';
		}
	};

	return (
    <Card className="mb-4 shadow-lg">
      <Link to={`/blog/${item.generated_url}`}>
        <Card.Img
          variant="top"
          src={s3url + item.image_1000_540_scroller}
          className="rounded-top-md img-fluid"
        />
      </Link>
      {/* Card body  */}
      <Card.Body>
        <Link
          to={`/blog/${item.generated_url}`}
          className={`fs-5 fw-semi-bold d-block mb-3 text-${CategoryColors(
            item.category_id
          )}`}
        >
          {item.category_name}
        </Link>
        <h3>
          <Link to={`/blog/${item.generated_url}`} className="text-inherit">
            {item.content_header}
          </Link>
        </h3>
        <p> {item.preview_text} </p>
        {/*  Media content  */}
        <Row className="align-items-center g-0 mt-4">
          <Col className="col-auto">
            {item.author_image ? (
              <Image
                src={s3 + item.author_image}
                alt=""
                className="rounded-circle avatar-md"
              />
            ) : (
              <Image
                src={s3 + "Account.svg"}
                alt=""
                className="rounded-circle avatar-md"
              />
            )}
          </Col>
          <Col className="col lh-1">
            <h5 className="mb-1">{item.author_name}</h5>
            <p className="fs-6 mb-0">{item.created_at}</p>
          </Col>
          {/* 
					<Col className="col-auto">
						<p className="fs-6 mb-0">{item.readinglength} Min Read</p>
					</Col>
					*/}
        </Row>
      </Card.Body>
    </Card>
  );
};

// Typechecking With PropTypes
BlogCard.propTypes = {
	item: PropTypes.object.isRequired
};

export default BlogCard;
