import React, { useEffect, useRef, useState, createRef } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  Svg,
  Path,
  Circle,
  Polyline,
} from "@react-pdf/renderer";
import { Html } from "react-pdf-html";
import {
  useA4Scale,
  TemplatePage,
  renderHTMLContent,
  getDisplayDate,
} from "../../utils";
import { Row, Col } from "react-bootstrap";
import DummyProfilePic from "../../../../../assets/images/background/profilepic.png";
import { FiPhone } from "react-icons/fi";

Font.registerHyphenationCallback((word) => {
  // Return entire word as unique part
  return [word];
});

const styles = StyleSheet.create({
  page: { display: "flex", fontFamily: "Roboto", paddingVertical: "30px" },

  circle: {
    position: "absolute",
    marginLeft: "-15px",
    marginTop: "4px",
  },
  name: {
    fontFamily: "Roboto",
    fontSize: "22px",
    color: "#ffb800",
    fontWeight: 700,
    marginBottom: "4px",
  },
  jobTitle: {
    fontFamily: "Roboto",
    fontSize: "17px",
    color: "#101214",
    fontWeight: 300,
  },
  wrapper: {
    // border: "4px solid black",
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  header: {
    marginBottom: "20px",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    flex: 1,
    height: "100%",
  },
  right: {
    width: "200px",
    backgroundColor: "#f9f9f9",
    // paddingTop: "30px",
    paddingLeft: "10px",
    // marginVertical: "-30px",
    // border: "4px solid blue",
    // height: "95vh",
  },
  left: {
    width: "395px",
    padding: "10px  30px 30px 50px",
    // border: "2px solid green",
  },
  contact: {
    fontSize: "12px",
    color: "#73808d",
    fontWeight: 300,
  },
  profileImageWrapper: {
    // backgroundColor: "#6f7bca",
    padding: "3px",
    width: "86px",
    height: "86px",
    // marginTop: "-70px",
    // borderRadius: "50%",
  },
  profileImage: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
  },
});

const ContactInfo = ({ data: { Phone, Country, display_email } }) => (
  <View style={styles.contact}>
    <Text
      style={{
        marginTop: "18px",
        fontFamily: "Roboto",
      }}
    >
      {display_email}
    </Text>
    <Text
      style={{
        fontFamily: "Roboto",

        marginTop: "6px",
      }}
    >
      {Phone}
    </Text>
    <Text></Text>
  </View>
);
const Skills = ({ data: { skills } }) => {
  console.log(`Skills template 2 = ${skills}`); 
  if (!skills || !skills.length) {
    return <View />;
  }
  return (
    <View style={{ paddingTop: "24px" }}>
      <Text
        style={{
          color: "#eeac00",
          fontSize: "11px",
          marginBottom: "7px",
          fontWeight: 500,
        }}
      >
        Skills
      </Text>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {skills.map((s, index) => (
          <Text
            style={{
              color: "#73808d",
              fontSize: "10px",
              fontWeight: 300,
              marginBottom: "7px",
              marginRight: "7px",
            }}
          >
            {s.skill_name != undefined &&
              (index == skills.length - 1 ? s.skill_name : s.skill_name + " ,")}
          </Text>
        ))}
      </View>
    </View>
  );
};

const RenderView = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  // console.log("data key", data[dataKey]);
  const { title, subTitle, startDate, endDate, description, city } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View
      style={{
        // marginLeft: "15px",
        color: "#101214",
        marginBottom: "15px",
      }}
    >
      <Text
        style={{
          fontSize: "10px",
          fontWeight: 500,
          letterSpacing: "2px",
          color: "#ffb800",
        }}
      >
        {heading}
      </Text>
      {items.map((item) => (
        <View style={{ marginBottom: "15px" }}>
          <View
            style={{
              fontSize: "15px",
              fontWeight: "500",
              marginTop: "9px",
            }}
          >
            <Svg
              xmlns="http://www.w3.org/2000/svg"
              height="10px"
              width="10px"
              stroke="#ffb800"
              fill="#ffb800"
              viewBox="0 0 24 24"
              style={styles.circle}
            >
              <g>
                <Circle cx="10" cy="10" r="6"></Circle>
              </g>
            </Svg>

            <Text> {item[title]} </Text>
          </View>
          <Text style={{ fontSize: "12px", marginTop: "4px", fontWeight: 300 }}>
            {item[subTitle]}
          </Text>
          <Text
            style={{ fontSize: "10px", color: "#363434", marginTop: "6px" }}
          >
            {getDisplayDate(item[startDate], item[endDate])}
            {item[city] ? ` ,  ${item[city]}` : ""}
          </Text>
          {item[description] && (
            <View style={{ marginTop: "4px" }}>
              {renderHTMLContent(item[description], {
                "*": { color: "#3f3f3f", fontWeight: 300, fontSize: "12px" },
              })}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};
const References = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const { title, subTitle, phoneNo, emailId } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View
      style={{
        // marginLeft: "15px",
        // color: "#101214",
        marginBottom: "15px",
      }}
    >
      <Text
        style={{
          fontSize: "10px",
          fontWeight: 500,
          letterSpacing: "2px",
          color: "#ffb800",
        }}
      >
        {heading}
      </Text>
      {items.map((item) => (
        <View style={{ marginBottom: "15px" }}>
          <View
            style={{
              fontSize: "15px",
              fontWeight: "500",
              marginTop: "9px",
            }}
          >
            <Svg
              xmlns="http://www.w3.org/2000/svg"
              height="10px"
              width="10px"
              stroke="#ffb800"
              fill="#ffb800"
              viewBox="0 0 24 24"
              style={styles.circle}
            >
              <g>
                <Circle cx="10" cy="10" r="6"></Circle>
              </g>
            </Svg>

            <Text>{item[title]}</Text>
          </View>
          <Text style={{ fontSize: "12px", marginTop: "4px", fontWeight: 300 }}>
            {item[subTitle]}
          </Text>
          <Text
            style={{
              fontSize: "10px",
              color: "#a6a6a6",
              marginTop: "5px",
              fontWeight: 500,
            }}
          >
            {item[phoneNo]}
          </Text>
          <Text
            style={{
              fontSize: "11px",
              color: "#a6a6a6",
              marginTop: "2px",
              fontWeight: 500,
            }}
          >
            {item[emailId]}
          </Text>
        </View>
      ))}
    </View>
  );
};
const AdditionalInfo = ({
  data: {
    Address,
    City,
    Postal_Code,
    Driving_License,
    Nationality,
    dob,
    Place_of_Birth,
    hobbies,
  },
}) => {
  const details = [
    {
      label: "Address",
      value: `${Address || ""} ${City || ""} ${Postal_Code || ""}`.trim(),
    },
    {
      label: "Driving License",
      value: Driving_License,
    },
    {
      label: "Nationality",
      value: Nationality,
    },
    {
      label: "Date of Birth",
      value: dob,
    },
    {
      label: "Place of Birth",
      value: Place_of_Birth,
    },
    {
      label: "Hobbies",
      value: !hobbies || hobbies.length == 0 ? null : hobbies[0].hobbies,
    },
  ];
  return details.map((info) =>
    info.value ? (
      <View style={{ padding: "12x 0px" }}>
        <Text
          style={{
            color: "#eeac00",
            fontWeight: 500,
            fontSize: "11px",
          }}
        >
          {info.label}
        </Text>
        <Text
          style={{
            marginTop: "3px",
            color: "#73808d",
            fontSize: "10px",
          }}
        >
          {info.value}
        </Text>
      </View>
    ) : (
      <View />
    )
  );
};
const Languages = ({ data: { languages } }) => {
  if (!languages || !languages.length) {
    return <View />;
  }
  return (
    <View style={{ paddingTop: "12px" }}>
      <Text
        style={{
          color: "#eeac00",
          // textDecoration: "underline",
          fontSize: "11px",
          marginBottom: "6px",
          fontWeight: 500,
        }}
      >
        Languages
      </Text>
      {languages.map((s) => (
        <Text
          style={{
            color: "#73808d",
            fontSize: "10px",
            paddingBottom: "3px",
          }}
        >
          {s.language_name}
        </Text>
      ))}
    </View>
  );
};
const Template2 = ({ data }) => {
  // console.log("inside template 2", data);
  const {
    profileImage,
    First_Name,
    Last_Name,
    Wanted_Job_Title,
    professionalSummary: { Description = "" } = {},
  } = data;
  return (
    <TemplatePage data={data}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.wrapper}>
            <View style={styles.left}>
              {/*  name and job title */}

              <View style={styles.header}>
                <Text style={styles.name}>
                  {console.log(First_Name !== false && Last_Name !== false)}

                  {First_Name !== false && Last_Name !== false
                    ? First_Name + " " + Last_Name
                    : "Your Name"}
                </Text>
                <Text style={styles.jobTitle}>
                  {Wanted_Job_Title !== false ? Wanted_Job_Title : "Job Title" }
                </Text>
              </View>
              <View>
                {data.professionalSummary.Description && (
                  <View style={{ marginBottom: "25px" }}>
                    <Text
                      style={{
                        lineHeight: "1.5px",
                        fontSize: "15px",
                        fontWeight: 500,
                        letterSpacing: "2px",
                        color: "#ffb800",
                      }}
                    >
                      About Me
                    </Text>
                    <View>
                      {renderHTMLContent(data.professionalSummary.Description, {
                        "*": {
                          color: "#000000",
                          fontWeight: 400,
                          fontSize: "11px",
                        },
                      })}
                    </View>
                  </View>
                )}
              </View>
              <RenderView
                data={data}
                dataKey="employmentHistory"
                heading="WORK EXPERIENCE"
                fieldMap={{
                  title: "Employer",
                  subTitle: "Job_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "Description",
                  city: "city",
                }}
              />
              <RenderView
                data={data}
                dataKey="education"
                heading="EDUCATION"
                fieldMap={{
                  title: "institute_name",
                  subTitle: "degree",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "Description",
                  city: "city",
                }}
              />
              <RenderView
                data={data}
                dataKey="activities"
                heading="EXTRA_CURRICULAR_ACTIVITY"
                fieldMap={{
                  title: "employer_name",
                  subTitle: "function_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "description",
                }}
              />
              <RenderView
                data={data}
                dataKey="internships"
                heading="INTERNSHIPS"
                fieldMap={{
                  title: "employer_name",
                  subTitle: "job_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "description",
                }}
              />
              <RenderView
                data={data}
                dataKey="courses"
                heading="COURSES"
                fieldMap={{
                  title: "course_name",
                  subTitle: "institute_name",
                  startDate: "start_date",
                  endDate: "end_date",
                }}
              />
              <References
                data={data}
                dataKey="References"
                heading="REFERENCES"
                fieldMap={{
                  title: "ref_name",
                  subTitle: "company_name",
                  phoneNo: "phone_no",
                  emailId: "ref_email",
                }}
              />
            </View>

            <View style={styles.right}>
              <View
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "164px",
                  left: "0px",
                  top: "-10px",
                  // border: "2px solid red",
                }}
                fixed
                render={({ pageNumber }) => (
                  <View
                    style={{
                      height: "100%",
                      backgroundColor:
                        pageNumber === 1 ? "transparent" : "#F9F9F9",
                    }}
                  ></View>
                )}
              />
              <View style={styles.profileImageWrapper}>
                <Image
                  src={profileImage || DummyProfilePic}
                  style={styles.profileImage}
                />
              </View>
              <ContactInfo data={data} />
              <AdditionalInfo data={data} />
              <Skills data={data} />
              <Languages data={data} />
            </View>
          </View>
        </Page>
      </Document>
    </TemplatePage>
  );
};

Template2.displayName = "Template2";
export default Template2;
