// import node module libraries
import React, { Fragment } from "react";
import { ListGroup, Image, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

// import custom components
import { AiOutlineClockCircle, AiOutlineCloudDownload } from "react-icons/ai";
import { FcTemplate } from "react-icons/fc";
import { GiArtificialIntelligence } from "react-icons/gi";
import { BiCustomize } from "react-icons/bi";
import { MdMobileFriendly } from "react-icons/md";
import { BsSpellcheck } from "react-icons/bs";

const iconComponent = {
  AiOutlineClockCircle: <AiOutlineClockCircle size={20} color="#06188c" />,
  FcTemplate: <FcTemplate size={20} color="#06188c" />,
  GiArtificialIntelligence: (
    <GiArtificialIntelligence size={20} color="#06188c" />
  ),
  BiCustomize: <BiCustomize size={20} color="#06188c" />,
  AiOutlineCloudDownload: <AiOutlineCloudDownload size={20} color="#06188c" />,
  MdMobileFriendly: <MdMobileFriendly size={20} color="#06188c" />,
  BsSpellcheck: <BsSpellcheck size={20} color="#06188c" />,
};

const PricingCard = (props) => {

  const {plan ,
        handleBuyNow,
        img
      } = props;

  // console.log("plan", plan);

  return (
    <Card className="border-0 mb-3">
      <Card.Body className="p-0">
        <div className="p-5 text-center">
          <Image src={img} alt="" className="mb-5" />
          <div class="mb-5">
            <h2 class="fw-bold">{plan.plan_name}</h2>
            <p class="mb-0">
              {plan.product_description}
            </p>
          </div>

          <div class="d-flex justify-content-center mb-4">
              <span class="h3 mb-0 fw-bold">&#8377;</span>
              <div class="toggle-price-content h1">{plan.product_price}
              {plan.discount > 0 && (
                <Fragment>
                  <del className="fs-4 text-muted ms-2">
                    &#8377;{plan.base_price}
                  </del>
                  <span className="text-danger fs-4">
                  {" "}
                  (-{plan.discount}%)
                </span>           
                {plan.product_price > 0 && <span className="fs-6 text-muted ms-1">+ GST</span>}
                </Fragment>
              )}
              </div>
          </div>

          <div className="d-grid">
            <button 
	  	        className="btn btn-outline-secondary"
              onClick={ () => {plan.id > 1 ? handleBuyNow(plan) : window.location.href="/ResumeBuilder#products"}}
            >
              {plan.plan_name.includes("Basic")
                ? "Get Started for Free"
                : "Get Started"}
            </button>
          </div>
        </div>
        <hr className="m-0" />
        <div className="p-5">
          <h4 className="fw-bold mb-4">{plan.featureHeading}</h4>
          {/* List of features */}
          <ListGroup bsPrefix="list-unstyled ">
            {plan.resumeProductFeature.map((item, index) => {
              return (
                <ListGroup.Item
                  key={index}
                  className="mb-3"
                  bsPrefix="list-item"
                >
                  <span className="me-2">
                    {iconComponent[item.feature_icon]}
                  </span>
                  <span>{item.feature_key} : </span>
                  <span className="fw-bolder">{item.feature_description}</span>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PricingCard;
