import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  createRef,
  Fragment,
  useRef,
} from "react";
import {
  Col,
  Container,
  Form,
  ProgressBar,
  Row,
  Accordion,
  Image,
  Modal,
  ListGroup,
} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Spinner from "react-bootstrap/Spinner";

import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Axios from "axios";
import {
  FiChevronDown,
  FiChevronUp,
  FiUser,
  FiX,
  FiGrid,
} from "react-icons/fi";
import { HiDocumentDuplicate, HiCheckCircle } from "react-icons/hi";
import {PiLockKeyLight, PiLockKeyOpenLight} from "react-icons/pi";
import { BiArrowBack } from "react-icons/bi";
import {
  BsCircleFill,
  BsCheckCircleFill,
  BsRobot,
  BsFillEyeFill,
} from "react-icons/bs";
import { AiOutlineFileSearch } from "react-icons/ai";

import Api, { ENDPOINTS, getS3Url } from "../api.js";
import templates from "./templates/index.js";
import "./ResumeBuilder.css";
import {
  Label,
  InputField,
  SelectStyles,
  Button,
  RTE,
  isEmpty,
  ScrollButton,
} from "../foundation/index.js";
// remaining
import "./templates/fonts";
import EmploymentHistory from "./form-sections/EmploymentHistory.js";
import Dialog from "./image-components/Dialog.js";
import Education from "./form-sections/Education.js";
import AddSection from "./form-sections/AddSection.js";

import {
  saveProfessionalSummary,
  saveExperienceDetails,
  saveEducationDetails,
  saveSkills,
  saveExtraCurricularActivities,
  saveHobbies,
  saveReferences,
  saveCourses,
  saveInternships,
  saveLanguages,
  saveCustomSection,
} from "../actions.js";

import Skill from "./form-sections/Skill.js";
import Hobbies from "./form-sections/Hobbies.js";
import Activities from "./form-sections/Activities.js";
import Courses from "./form-sections/Courses.js";
import References from "./form-sections/References.js";
import Internships from "./form-sections/Internships.js";
import Languages from "./form-sections/Languages.js";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ProfessionalSummaryAICard from "./ProfessionalSummaryAICard.js";
import ReactQuillEditor from "react-quill";
import EmploymentHistoryAICard from "./EmploymentHistoryAICard.js";
import Colorswitch from "../Colorswitch.js";
import CustomSection from "./form-sections/CustomSection.jsx";


import popupimg from "assets/images/png/Group6.png";
import ResumeDummy from "assets/images/png/ResumeDummy.jpg";

const Loading = (message = "Loading...") => (
  <div>
    <CircularProgress size={20} color="primary" /> {message}
  </div>
);

const CAPTIONS = {
  PERSONAL_DETAILS: "Personal Details",
};

const sanitizeData = (data, defaultValue = []) =>
  typeof data === "object" ? data : defaultValue;

export const ResumeBuilder = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const email = localStorage.getItem("email");
  const studentId = localStorage.getItem("studentId");
  const s3 = getS3Url("resumepicupload");
  const templateIMGURL = getS3Url("ResumeBuilderTemplates");

  const [accordionActive, setAccordionActive] = useState("1");
  const [isSaving, setIsSaving] = useState(false);
  const [snackBarData, setSnackBarData] = useState({
    open: false,
    anchorOrigin: { vertical: "bottom", horizontal: "right" },
    message: "",
  });

  const [imageModal, setImageModal] = useState(false);
  const [showCustomSection, setShowCustomSection] = useState(false);

  const [profileImage, setProfileImage] = useState("");
  const [profileImageBuffer, setProfileImageBuffer] = useState("");
  const [completeness, setCompleteness] = useState(0);
  const [resumeDetail, setResumeDetail] = useState({});
  const [templateModal, setTemplateModal] = useState(false);

  const [resumeId, setResumeId] = useState();

  const [currentTemplateId, setCurrentTemplateId] = useState();
  const [currentTemplateDetails, setCurrentTemplateDetails] = useState();
  const [refreshData, setRefreshData] = useState(false);
  const [isActiveResumeSubscription, setIsActiveResumeSubscription] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionsSearchText, setSuggestionsSearchText] = useState("");
  const [suggestionsWait, setSuggestionsWait] = useState(false);
  const [employmentSummaryDescription, setEmploymentSummaryDescription] = useState("");

  const [showEmployerSuggetion, setShowEmployerSuggetion] = useState(false);
  const [employerQuillRef, setEmployerQuillRef] = useState();
  const [employmentSummary, setEmploymentSummary] = useState(false);
  const [divPosition, setDivPosition] = useState({ left: 0, top: 0 });

  const tooltip = (
    <Tooltip id="tooltip">
      Use 4-5 keywords like "Software Engineer,5 years experience, ReactJS,
      Mysql" or "Project Manager, 12 years experience, Banking and financial
      solutions"
    </Tooltip>
  );
  //Date validation
  const [currentdate, setCurrentdate] = useState(null);
  const formatDate = () => {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    setCurrentdate([year, month, day].join("-"));
  };
  //Date validation
  const [hideTemplate, setHideTemplate] = useState(
    window.innerWidth > 767 ? false : true
  );
  const [showTemplate, setShowTemplate] = useState(false);
  const [resumeTemplates, setResumeTemplates] = useState([]);

  const imageRef = createRef();
  const quillRef = useRef(null);

  const [showSelectTemplate, setShowSelectTemplate] = useState(false);

  const resumeForm = useForm({
    mode: "onChange",
    defaultValues: {
      caption: "Personal Details",
      email,
      display_email: email,
    },
    reValidateMode: "onChange",
  });
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    control,
    watch,
    errors,
    formState,
  } = resumeForm;
  // console.log(`error log : ${errors}`);
  const employmentHistory = useFieldArray({
    control,
    name: "employmentHistory",
    keyName: "key",
  });
  const education = useFieldArray({
    control,
    name: "education",
    keyName: "key",
  });
  const skills = useFieldArray({
    control,
    name: "skills",
    keyName: "key",
  });
  const hobbies = useFieldArray({
    control,
    name: "hobbies",
    keyName: "key",
  });
  const customSection = useFieldArray({
    control,
    name: "customSection",
    keyName: "key",
  });
  const activities = useFieldArray({
    control,
    name: "activities",
    keyName: "key",
  });
  const courses = useFieldArray({
    control,
    name: "courses",
    keyName: "key",
  });
  const references = useFieldArray({
    control,
    name: "References",
    keyName: "key",
  });
  const internships = useFieldArray({
    control,
    name: "internships",
    keyName: "key",
  });
  const languages = useFieldArray({
    control,
    name: "languages",
    keyName: "key",
  });

  const [masterData, setMasterData] = useState({});

  //console.log("setting template...!!!");
  var TemplateComponent =
    templates[resumeDetail.resume_template || "Template1"] ||
    templates["Template1"];

  //const [TemplateComponent , setTemplateComponent] = useState(templates[resumeDetail.resume_template || "Template1"] || templates["Template1"]);
  //console.log(TemplateComponent);

  const { specializations = [], locations = [] } = masterData;

  const getResumeData = async () => {
    //console.log("in getResumeData....");
    try {
      //console.log("inside resume");
      const resumeDetails = await Api.get(
        `${ENDPOINTS.GET_RESUME_BY_ID}/${id}`
      );
      //console.log(resumeDetails);
      const {
        resume_basic_detail,
        professional_summary,
        resume_experience_detail,
        resume_education_detail,
        resume_skill,
        resume_extra_curricular_activitie,
        resume_hobby,
        resume_Reference,
        resume_course,
        resume_internship,
        resume_language,
        resume_custom_data,
      } = resumeDetails.data;
      const formData = {
        ...sanitizeData(resume_basic_detail, {}),
        professionalSummary: sanitizeData(professional_summary, {}),
        employmentHistory: sanitizeData(resume_experience_detail),
        education: sanitizeData(resume_education_detail),
        skills: sanitizeData(resume_skill),
        activities: sanitizeData(resume_extra_curricular_activitie),
        hobbies: sanitizeData(resume_hobby),
        References: sanitizeData(resume_Reference),
        courses: sanitizeData(resume_course),
        internships: sanitizeData(resume_internship),
        languages: sanitizeData(resume_language),
        customSection: sanitizeData(resume_custom_data),
      };
      if (!formData.id) {
        history.replace("/resumes");
      }
      reset(formData);
      setResumeDetail(formData);
      //setProfileImage(formData.pic);
      setProfileImage(`${s3}/${formData.pic}`);
      setResumeId(formData.id);
      setCurrentTemplateDetails(resumeDetails.data.resume_template);
      setIsActiveResumeSubscription((resumeDetails.data?.resumeSubscription.length > 0))
    } catch (e) {
      console.error(e);
    }

    //console.log(`${ENDPOINTS.GET_ALL_RESUME_TEMPLATES}/1`);

    const templatesList = await Api.get(
      `${ENDPOINTS.GET_ALL_RESUME_TEMPLATES}/1`
    );
    setResumeTemplates(templatesList.data.resumeTemplateList);
    //console.log(templatesList.data.resumeTemplateList);
  };
  // aman start
  function debounce(fn, ms) {
    let timer;
    return (_) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
  }
  // aman end

  function strip_html_tags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/<[^>]*>/g, "");
  }
  useEffect(() => {
    //console.log("inside fetch");
    // Fetch initial Data
    // aman start
    //console.log("in 1....");
    let transitionWidth = 767;

    const debouncedResizeHandler = debounce(() => {
      if (window.innerWidth <= transitionWidth) setHideTemplate(true);
      else setHideTemplate(false);
    }, 100);

    if (window.innerWidth <= transitionWidth) {
      const waWidget = document.getElementById("whatsappwidget");
      waWidget.style.visibility = "hidden";
      const showMessage = toast.warning(
        "ResumeBuilder works best on desktop, login from desktop to access powerful features"
      );
    }
    window.addEventListener("resize", debouncedResizeHandler);
    // aman end
    if (!email) {
      history.replace("/StudentLink");
    }
    const fetchData = async () => {
      setSnackBarData({ ...snackBarData, open: true, message: Loading() });
      await getResumeData();
      try {
        const [specializations, locations] = await Axios.all([
          Api.get(ENDPOINTS.GET_ALL_SPECIALIZATIONS),
          Api.get(ENDPOINTS.GET_ALL_LOCATIONS),
        ]);
        const data = {
          specializations: specializations.data,
          locations: locations.data.map(({ placename }) => ({
            label: placename,
            value: placename,
          })),
        };
        setMasterData(data);
      } catch (e) {
        console.error(e);
      }
      setSnackBarData({ ...snackBarData, open: false, message: "" });
    };
    fetchData();
    setRefreshData(false);
    // aman start
    return () => window.removeEventListener("resize", debouncedResizeHandler);
    // aman end
  }, [refreshData]);

  // useEffect(() => {
  //   console.log("in useEffect 2....");
  //   if (profileImage) {
  //     // Axios.get(`${profileImage}?timestamp=${Date.now()}`, {
  //     //   responseType: "blob",
  //     // }).then(({ data: imageData }) => {
  //     //   var reader = new FileReader();
  //     //   reader.readAsDataURL(imageData);
  //     //   reader.onloadend = function () {
  //     //     var base64data = reader.result;
  //     //     setProfileImageBuffer(base64data);
  //     //   };
  //     // });
  //     setProfileImage(`${s3}/${profileImage}`);
  //   }
  // }, [profileImage]);

  const uploadProfileImage = useCallback(async (imgData) => {
    const imgFile = await fetch(imgData).then((r) => r.blob());
    const formData = new FormData();
    formData.append("pic", imgFile);
    formData.append("resumeId", id);
    const {
      data: { message },
    } = await Api.post(ENDPOINTS.UPLOAD_PROFILE_IMAGE, formData);
    setProfileImage(`${s3}/${message}`);
  }, []);

 // Watch for job title input changes
  const jobTitle = watch("Wanted_Job_Title");

  //cjhan

  /**** CHANGE / SELECT TEMPLATE API - Start */
  const selectThisTemplate = (resume_template, template_id) => {
    console.log(resumeId);

    if (studentId) {
      if (resumeId) {
        let resume_id = resumeId;
        console.log(
          "updating resume: " +
            resume_id +
            " with template " +
            resume_template +
            ", " +
            template_id
        );

        try {
          Api.post(ENDPOINTS.UPDATE_RESUME_TEMPLATE, {
            email,
            resume_id,
            resume_template,
            studentId,
            template_id,
          }).then(() => {
            console.log("updated");
            //window.location.reload();
            setCurrentTemplateId(template_id);
            setRefreshData(true);
          });
        } catch (e) {
          console.log(e);
        }
      }
    } else {
      sessionStorage.setItem("redirectURL", "ResumeBuilder");
      sessionStorage.setItem("resumeTemplate", resume_template);
      //history.push("/student-login");
      setLoginModalShow(true);
    }
  };
  /**** CHANGE / SELECT TEMPLATE API - End */

  /**** CHANGE / AI Suggestions - Start */
  const getSuggestions = async () => {
    scroll();
    if (studentId) {
      if (resumeId) {
        //console.log(resumeData.professionalSummary?.Description);
        //if(resumeData.professionalSummary?.Description){suggestionsSearchText
        setSuggestions([]);
        if (suggestionsSearchText) {
          setSuggestionsWait(true);
          try {
            let message = strip_html_tags(suggestionsSearchText);
            const ApiResponse = await Api.get(
              ENDPOINTS.GET_SUGGESTIONS + "/" + message + "/" + studentId + "/" + resumeId
            );
            if (ApiResponse.data.suggestions.length > 0) {
              setSuggestions(ApiResponse.data.suggestions);
            } else {
              toast.warning(
                "No Suggestions, please check your keywords (only use 3-4 keywords)"
              );
            }
          } catch (e) {
            console.log(e);
          } finally {
            setSuggestionsWait(false);
          }
        } else {
          toast.warning("Add some keywords to the professional summary field");
        }
      } else {
        toast.warning("Cannot get suggestions");
      }
    }
  };

  const scroll = () => {
    const section = document.querySelector("#profSummary");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleSuggestionLineClick = (event) => {
    event.preventDefault();
    let message = event.target.innerText;
    console.log(event);
    setProfessionalSummary(message);
  };

  const setProfessionalSummary = (message) => {
    console.log(message);
    //setValue("professionalSummary.Description","</p>" + message + "</p>");
    // // existingContent = quillRef.current.getEditor().root.innerText;
    //setResumeDetail({"professionalSummary": message});
    // newContent = newContent.replace(/[\r\n]/g, '');

    let existingContent = quillRef.current.getEditor().root.innerHTML;
    let indexPosition = existingContent.length - 4;
    let newContent =
      existingContent.replace(/[\r\n]/g, "") + " " + message.trim();

    newContent =
      existingContent.slice(0, indexPosition) +
      " " +
      message +
      existingContent.slice(indexPosition);

    if (quillRef.current) {
      //quillRef.current.getEditor().setContents(quillRef.current.getEditor().clipboard.convert(newContent));
      //quillRef.current.getEditor().root.innerHTML = "";
      //quillRef.current.getEditor().clipboard.dangerouslyPasteHTML(0, newContent);

      //quillRef.current.getEditor().root.innerText = newContent;
      /*
      const quill = quillRef.current.getEditor();
      const currentDelta = quill.getContents();
      console.log(currentDelta);
      currentDelta.ops[currentDelta.ops.length-1] = currentDelta.ops[currentDelta.ops.length-1].insert + message;

      console.log(currentDelta.ops[currentDelta.ops.length+1].insert);
      quill.updateContents(currentDelta);
      quill.update();
      */
      setResumeDetail({
        ...resumeDetail,
        professionalSummary: {
          id: resumeDetail.professionalSummary.id,
          resume_id: resumeDetail.professionalSummary.resume_id,
          Description: newContent,
        },
      });
      setValue("professionalSummary.Description", newContent);

      const quill = quillRef.current.getEditor();
      quill.root.innerHTML = newContent;
    }
  };

  /**** CHANGE / AI Suggestions - End */

  //console.log(resumeDetail);

  const submitForm = async (data) => {
    const payload = { ...data };
    // console.log(`Form data : ${data}`);
    const {
      professionalSummary,
      employmentHistory,
      education,
      skills,
      activities,
      hobbies,
      References,
      courses,
      internships,
      languages,
      customSection,
      ...personalDetail
    } = payload;
    try {
      setIsSaving(true);
      // Save Basic Detail
      await Api.post(`${ENDPOINTS.UPDATE_RESUME}/${resumeDetail.id}`, {
        pic: profileImage,
        ...personalDetail,
      });
      await saveProfessionalSummary(
        id,
        resumeDetail.professionalSummary.id,
        professionalSummary
      );
      await saveExperienceDetails(id, employmentHistory);
      await saveEducationDetails(id, education);
      await saveSkills(id, skills);
      await saveExtraCurricularActivities(id, activities);
      await saveHobbies(id, hobbies);
      await saveReferences(id, References);
      await saveCourses(id, courses);
      await saveInternships(id, internships);
      await saveLanguages(id, languages);
      await saveCustomSection(id, customSection);
    } catch (e) {
      console.log(e);
    } finally {
      getResumeData();
      setIsSaving(false);
    }
  };
  const level =
    completeness < 33 ? "danger" : completeness < 66 ? "warning" : "success";
  //const resumeData = { ...watch(), profileImage: profileImageBuffer };
  const resumeData = { ...watch(), profileImage: profileImage };

  const handleCellClick = (event) => {
    const cellRect = event.target.getBoundingClientRect();
    const divRight = cellRect.right + window.scrollX;
    const divTop = cellRect.top + window.scrollY;

    setDivPosition({ right: -(divRight + 250), top: divTop - 780 });
  };

  useEffect(() => {
    /*
    if(formState.isDirty){
      const btn = document.getElementById("submitButton");
      btn.click();
    }
    */
    const completenessMetrics = [
      resumeData.profileImage,
      resumeData.Wanted_Job_Title,
      resumeData.display_email,
      resumeData.First_Name,
      resumeData.Last_Name,
      resumeData.Phone,
      isEmpty(resumeData.professionalSummary?.Description)
        ? ""
        : resumeData.professionalSummary?.Description,
      resumeData.employmentHistory?.length,
      resumeData.education?.length,
      resumeData.skills?.length,
      resumeData.extraCurricularActivities?.length,
      resumeData.hobbies?.length,
      resumeData.references?.length,
      resumeData.courses?.length,
      resumeData.internships?.length,
      resumeData.languages?.length,
      resumeData.customSection?.length,
    ];
    let completed = 0;
    completenessMetrics.forEach((metric, i) => {
      if (metric) completed++;
    });
    const percentage = (completed / completenessMetrics.length) * 100;
    setCompleteness(percentage.toFixed());
    setResumeId(resumeData.id);
    setCurrentTemplateId(resumeData.template_id);

    formatDate();

    if (localStorage.getItem("showResumeTemplates") == "1") {
      localStorage.removeItem("showResumeTemplates");
      setShowSelectTemplate(true);
    }
  }, [resumeData]);
  return (
    <div className="resume-builder">
      <Row
        className={
          hideTemplate && showTemplate
            ? "hideTemplate"
            : showSelectTemplate && "d-none"
        }
      >
        <Col md={6} className="resume-builder__form m-0 px-5">
          <Container>
            <ToastContainer />
            <Modal
                show={showSubscriptionModal}
                onHide={() => setShowSubscriptionModal(false)}
                size="md"
                centered
              >
                <Modal.Body style={{color:"#000"}}>
                  <Row>
                    <Col sm={4} className="text-center">
                    <Image src={popupimg} />
                    </Col>
                    <Col sm={7} className="ms-sm-4">
                      <p className="h5">Only one free template is available in free plan. <span className="h3" style={{color:"var(--geeks-primary)"}}>Upgrade</span> your plan to unlock all the premium templates.</p>
                      <p className="h4">With upgrade you will get</p>
                      <ul>
                        <li>All premium templates</li>
                        <li>Unlimited downloads</li>
                        <li>Full Customization Options</li>
                      </ul>
                      <Link to="/ResumeBuilder/Pricing" class="btn btn-primary">Upgrade</Link>
                      <button className="btn btn-outline-warning ms-2" onClick={() => {setShowSubscriptionModal(false)}}>Cancel</button>
                  </Col>
                  </Row>
                </Modal.Body>
            </Modal>            
            <Row>
              <Col xs={11} className="position-relative w-100">
                {hideTemplate ? (
                  <Link to="/resumes">
                    <BiArrowBack size={20} />
                  </Link>
                ) : (
                  ""
                )}
                <div className="resume-builder__progress">
                  <span
                    className={`resume-builder__progress__now resume-builder__progress__now--${level}`}
                  >
                    {completeness} %
                  </span>
                  <Label as="span">Profile Completeness</Label>
                  <ProgressBar now={completeness} variant={level} />
                </div>
                <Form onSubmit={handleSubmit(submitForm)}>
                  <input
                    type="hidden"
                    stretch
                    id="template_id"
                    name="template_id"
                    value={currentTemplateId}
                    {...register("template_id")}
                  />
                  <div className="resume-builder__section">
                    <Controller
                      control={control}
                      name="caption"
                      render={({ onChange, value, name, ref }) => (
                        <h4>{value || CAPTIONS.PERSONAL_DETAILS}</h4>
                      )}
                    />
                    <Row className="mb-3">
                      <Col>
                        <Button
                          //   type="button"
                          //   variant="link"
                          className="pt-0 ps-0 upload"
                          onClick={() => setImageModal(true)}
                        >
                          {profileImage ? (
                            <img
                            alt=""
                            ref={imageRef}
                            className="rounded-circle"
                            src={ResumeDummy}
                            width="75"
                            Color="blue"
                          />
                          ) : (
                            <FiUser size={68} color="#6c757d" />
                          )}{" "}
                          <span className="ml-2 text-info fs-4">
                            Upload photo
                          </span>
                        </Button>
                        <Modal
                          show={imageModal}
                          onHide={() => setImageModal(false)}
                          className="resume-builder__img-modal"
                        >
                          <Dialog
                            close={() => setImageModal(false)}
                            setCroppedImage={uploadProfileImage}
                          />
                        </Modal>
                      </Col>
                      <Col>
                        <Label htmlFor="Wanted_Job_Title">Job Title</Label>
                        <InputField
                          stretch
                          id="Wanted_Job_Title"
                          placeholder="Enter Job Title"
                          name="Wanted_Job_Title"
                          {...register("Wanted_Job_Title")}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Label htmlFor="First_Name">First Name</Label>
                        <InputField
                          stretch
                          id="First_Name"
                          placeholder="Enter first name"
                          name="First_Name"
                          {...register("First_Name")}
                        />
                      </Col>
                      <Col>
                        <Label htmlFor="Last_Name">Last Name</Label>
                        <InputField
                          stretch
                          id="Last_Name"
                          placeholder="Enter last name"
                          name="Last_Name"
                          {...register("Last_Name")}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Label htmlFor="display_email">Email</Label>
                        <InputField
                          stretch
                          id="display_email"
                          placeholder="Enter Email"
                          name="display_email"
                          // {...register("email")}
                          {...register("display_email", {
                            pattern: {
                              value:
                                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                              message: "*please enter a valid e-mail address",
                            },
                          })}
                        />
                        {/* {errors.display_email && (
													<div style={{ color: "red", fontSize: "10px" }}>
														{errors.display_email.message}
													</div>
												)} */}
                      </Col>
                      <Col>
                        <Label htmlFor="Phone">Phone</Label>
                        <InputField
                          stretch
                          id="Phone"
                          placeholder="Enter Phone Number"
                          name="Phone"
                          {...register("Phone")}
                        />
                      </Col>
                    </Row>
                    <Accordion
                      activeKey={accordionActive}
                      onSelect={(key) => setAccordionActive(key)}
                    >
                      <Accordion.Collapse eventKey="0">
                        <>
                          <Row className="mb-3">
                            <Col>
                              <Label htmlFor="Country">Country</Label>
                              <InputField
                                stretch
                                id="Country"
                                placeholder="Enter Country"
                                name="Country"
                                {...register("Country")}
                              />
                            </Col>
                            <Col>
                              <Label htmlFor="City">City</Label>
                              <Controller
                                control={control}
                                name="City"
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                  fieldState: {
                                    invalid,
                                    isTouched,
                                    isDirty,
                                    error,
                                  },
                                  formState,
                                }) => (
                                  <Select
                                    value={
                                      locations.find(
                                        (c) => c.value === value
                                      ) || {
                                        label: value,
                                        value,
                                      }
                                    }
                                    inputRef={ref}
                                    onChange={(item) => onChange(item.value)}
                                    options={locations}
                                    styles={SelectStyles}
                                  />
                                )}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <Label htmlFor="Address">Address</Label>
                              <InputField
                                stretch
                                id="Address"
                                placeholder="Enter address"
                                name="Address"
                                {...register("Address")}
                              />
                            </Col>
                            <Col>
                              <Label htmlFor="Postal_Code">Postal Code</Label>
                              <InputField
                                stretch
                                id="Postal_Code"
                                placeholder="Enter postal code"
                                name="Postal_Code"
                                {...register("Postal_Code")}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <Label htmlFor="Driving_License">
                                Driving License
                              </Label>
                              <InputField
                                stretch
                                id="Driving_License"
                                placeholder="Enter driving license number"
                                name="Driving_License"
                                {...register("Driving_License")}
                              />
                            </Col>
                            <Col>
                              <Label htmlFor="Nationality">Nationality</Label>
                              <InputField
                                stretch
                                id="Nationality"
                                placeholder="Enter nationality"
                                name="Nationality"
                                {...register("Nationality")}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <Label htmlFor="Place_of_Birth">
                                Place of Birth
                              </Label>
                              <InputField
                                stretch
                                id="Place_of_Birth"
                                placeholder="Enter Place of Birth"
                                name="Place_of_Birth"
                                {...register("Place_of_Birth")}
                              />
                            </Col>
                            <Col>
                              <Label htmlFor="dob">Date Of Birth</Label>
                              <InputField
                                type="date"
                                stretch
                                id="dob"
                                placeholder="Enter Date Of Birth"
                                name="dob"
                                {...register("dob")}
                                max={currentdate}
                              />
                            </Col>
                          </Row>
                        </>
                      </Accordion.Collapse>

                      <Button
                        className="w-50 ps-2 text-info text-start"
                        onClick={() => {
                          if (accordionActive == "0") setAccordionActive("1");
                          else setAccordionActive("0");
                        }}
                      >
                        {accordionActive === "0" ? (
                          <>
                            Hide additional details <FiChevronUp size={20} />
                          </>
                        ) : (
                          <>
                            Edit additional details <FiChevronDown size={20} />
                          </>
                        )}
                      </Button>
                    </Accordion>
                  </div>
                  <div
                    id="profSummary"
                    className="resume-builder__section"
                    style={{ position: "relative" }}
                  >
                    <h4>Professional Summary</h4>
                    <div>
                      <Label as="p" className="position-relative">
                        Write 2-4 short &amp; energetic sentences to interest
                        the reader! Mention your role, experience &amp; most
                        importantly - your biggest achievements, best qualities
                        and skills. Get{" "}
                        <Button
                          variant="link"
                          onClick={() => {
                            setShowSuggestions(true);
                            scroll();
                          }}
                          style={{
                            cursor: "pointer",
                            color: "var(--geeks-primary)",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                          Help by AI Writer
                          </span>{" "}
                          <BsRobot className="mb-2" size={20} />{" "}
                        </Button>
                        {/*<Button><BsRobot size={20} onClick={getSuggestions} style={{cursor:"pointer"}} /></Button>*/}
                        {/* The AICard is place here just to position it relative to the label */}
                        <ProfessionalSummaryAICard
                          suggestions={suggestions}
                          suggestionsWait={suggestionsWait}
                          getSuggestions={getSuggestions}
                          setSuggestionsSearchText={setSuggestionsSearchText}
                          setProfessionalSummary={setProfessionalSummary}
                          suggestionsSearchText={suggestionsSearchText}
                          setShowSuggestions={setShowSuggestions}
                          showSuggestions={showSuggestions}
                          handleSuggestionLineClick={handleSuggestionLineClick}
                        />
                      </Label>
                    </div>
                    <Row>
                      <Col>
                        <Controller
                          control={control}
                          name="professionalSummary.Description"
                          render={({
                            field: {
                              onChange,
                              onBlur,
                              value,
                              name,
                              ref,
                              setValue,
                            },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) =>
                            !snackBarData.open && (
                              <RTE
                                value={value}
                                onBlur={onBlur} // notify when input is touched
                                onChange={onChange} // send value to hook form
                                checked={value}
                                inputRef={ref}
                                ref={quillRef}
                                modules={{
                                  clipboard: {
                                    matchVisual: false,
                                  },
                                }}
                              />
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <div
                    id="employmentHistorySection"
                    className="resume-builder__section"
                    style={{ position: "relative" }}
                  >
                    {/* <div id="employmentHistorySection" style={{postion:"relative"}} > */}
                    <EmploymentHistory
                      handleCellClick={handleCellClick}
                      {...resumeForm}
                      {...employmentHistory}
                      showEmployerSuggetion={showEmployerSuggetion}
                      setShowEmployerSuggetion={setShowEmployerSuggetion}
                      employmentSummaryDescription={
                        employmentSummaryDescription
                      }
                    />
                    <EmploymentHistoryAICard
                      divPosition={divPosition}
                      setEmploymentSummary={setEmploymentSummary}
                      employmentSummary={employmentSummary}
                      showEmployerSuggetion={showEmployerSuggetion}
                      setShowEmployerSuggetion={setShowEmployerSuggetion}
                      setEmploymentSummaryDescription={
                        setEmploymentSummaryDescription
                      }
                    />
                  </div>
                  <Education {...resumeForm} {...education} />
                  <Skill {...resumeForm} {...skills} 
                  jobTitle={jobTitle} 
                  watch={watch} 
                  register={register} 
                  control={control} 
                  />
                  <Languages {...resumeForm} {...languages} />

                  {/* {showCustomSection ? <CustomSection {...resumeForm} {...customSection} /> : ""} */}

                  {hobbies?.fields.length > 0 ? (
                    <Hobbies {...resumeForm} {...hobbies} />
                  ) : (
                    ""
                  )}
                  {courses?.fields.length > 0 ? (
                    <Courses {...resumeForm} {...courses} />
                  ) : (
                    ""
                  )}

                  {internships?.fields.length > 0 ? (
                    <Internships {...resumeForm} {...internships} />
                  ) : (
                    ""
                  )}

                  {/* {languages?.fields.length > 0 ? (
                    <Languages {...resumeForm} {...languages} />
                  ) : (
                    ""
                  )} */}
                  {references?.fields.length > 0 ? (
                    <References {...resumeForm} {...references} />
                  ) : (
                    ""
                  )}

                  {activities?.fields.length > 0 ? (
                    <Activities {...resumeForm} {...activities} />
                  ) : (
                    ""
                  )}

                  {customSection?.fields.length > 0 ? (
                    < CustomSection {...resumeForm} {...customSection} />
                  ) : (
                    ""
                  )}
                  <AddSection
                    resumeForm={resumeForm}
                    Courses={courses}
                    Activities={activities}
                    Internships={internships}
                    Hbby={hobbies}
                    References={references}
                    Languages={languages}
                    CustomSection={customSection}
                  />

                  <div
                    style={{
                      position: "sticky",
                      bottom: "15px",
                      display: "flex",
                      justifyContent: "center",
                      zIndex: 999,
                    }}
                  >
                    <button
                      className="btn btn-info btn-sm me-2"
                      style={{ width: 200, height: 50 }}
                      disabled={isSaving}
                    >
                      Save{" "}
                      {isSaving && (
                        <CircularProgress size={12} color="#ffffff" />
                      )}
                    </button>
                    {/* Mobile button for resume preview - start */}
                    {hideTemplate ? (
                      <div
                        style={{
                          position: "sticky",
                          bottom: "30px",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <ScrollButton
                          onClick={() => setShowTemplate(!showTemplate)}
                        >
                          <AiOutlineFileSearch size={20} />
                        </ScrollButton>
                      </div>
                    ) : null}
                    {/* Mobile button for resume preview - end */}
                  </div>
                  {/* {                    <button
                      className="btn btn-info"
                      style={{ width: 200 }}
                      disabled={isSaving}
                      id="submitButton"
                    >
                      Save{" "}
                      {isSaving && (
                        <CircularProgress size={12} color="#ffffff" />
                      )}
                    </button>} */}
                </Form>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      
      {/** SELECT TEMPLATE - When clicked on the Select Template button, hide the form using d-none class, and show the template selection section.
       * When clicked on close, then hide the template selection and show the form  */}

      <Col
        md={4}
        className={`resume-builder__form m-0 ${
          !showSelectTemplate && "d-none"
        }`}
      >
        {/* <Col
          className="pb-3 mb-4 bg-primary text-white fixed-top"
          style={{ width: "100%", height: "7.5%" }}
        > */}
        
        <Col
          className="pb-3 mb-4 bg-primary text-white fixed-top d-flex align-items-center justify-content-center"
          xs={12} // For full width on all screen sizes
          style={{ height: "7.5vh" }} // Adjusted height using viewport height units (vh)
        >
           <Colorswitch
            templateData={currentTemplateDetails}
            resumeId={resumeId}
            setRefreshData={setRefreshData}
          />
          {/* <Button onClick={() => setShowSelectTemplate(false)} className="mx-4"
           style={{
            border: "none",
            color: "#fff",
            borderRadius: "20px",
            backgroundColor: "#2C3895",
            position: "absolute",
            left: "-5px",
            top: "6px",
          }}
          >

            Go Back to Editor
          </Button> */}
           <Button
            onClick={() => setShowSelectTemplate(false)}
            className="mx-2 mx-md-4 mb-2 mb-md-0 position-absolute btn-sm"
            style={{
              left: "-12px",
              top: "3px",
              color: "#fff",
              borderRadius: "15px",
              zIndex: 1000, // Adjusted font size for smaller screens
              padding: "6px 12px", // Adjusted padding for smaller button size
              width:"80px",
              fontSize:"12px",
              cursor: "pointer",
              
              transition:" background-color 0.3s ease, font-size 0.3s ease, padding 0.3s ease",
              
             

            }}
          > Back to Editor
          </Button>
          {/** TEMPLATE COLOR - START */}
          {
            //currentTemplateDetails?.template_color1 &&
            // <Fragment>
            //   <BsCircleFill color={currentTemplateDetails.template_color1} size={30} className="pe-2" />
            //   <BsCircleFill color={currentTemplateDetails.template_color2} size={30} className="pe-2" />
            //   <BsCircleFill color={currentTemplateDetails.template_color3} size={30} className="pe-2" />
            //   <BsCircleFill color={currentTemplateDetails.template_color4} size={30} className="pe-2" />
            //   <BsCircleFill color={currentTemplateDetails.template_color5} size={30} className="pe-2" />
            // </Fragment>
          }
          {/** TEMPLATE COLOR - END */}
        </Col>
        <Row className="px-5 pt-10">
          {resumeTemplates.map((resumeTemplate, index) => (
            <Col md={6} xs={12} key={index} className="pb-4 position-relative ">
              <Button
                variant="link"
                size="lg"
                onClick={() =>
                  {isActiveResumeSubscription ? 
                  selectThisTemplate(
                    resumeTemplate.template_name,
                    resumeTemplate.id
                  )
                  :
                  (index > 0 ? setShowSubscriptionModal(true) : null)
                  }
                }
              >
                <img
                  className="img-fluid border"
                  src={
                    templateIMGURL + "/" + resumeTemplate.template_preview_image
                  }
                  alt={resumeTemplate.template_name}
                />
              </Button>
              {(!isActiveResumeSubscription && index>0 && !(currentTemplateId == resumeTemplate.id)) && 
                <PiLockKeyLight size={50} className="position-absolute top-50 start-50 translate-middle" onClick={() => setShowSubscriptionModal(true)} />
                }
              {currentTemplateId == resumeTemplate.id && (
                <HiCheckCircle
                  color="var(--geeks-primary)"
                  size={40}
                  className="position-absolute top-50 start-50 translate-middle"
                />
              )}
            </Col>
          ))}
        </Row>
      </Col>

      {/* Resume Preview - Mobile - start */}
      {hideTemplate && showTemplate ? (
        <Row>
          <Col
            xs={11}
            className={`resume-builder__template showTemplateInFullView ${
              showSelectTemplate ? "d-none" : ""
            }`}
          >
            <TemplateComponent data={resumeData} imageRef={imageRef} />
            <span className="resume-builder__back">
              <FiX size={20} onClick={() => setShowTemplate(!showTemplate)} />
            </span>
            <Button
              className="btn btn-info btn-sm me-2"
              variant="link"
              onClick={() => setShowSelectTemplate(true)}
              // className={"resume-builder_select_template "}
              style={{
                position: "fixed",
                top: "2.65vh",
                right: "210px",
                border:"1px solid #fff",
                borderRadius:"15px",
                color:"#fff",
                width:"130px",
               
              }}
            >
              <FiGrid size={20} />Change Template
            </Button>
          </Col>
        </Row>
      ) : (
        ""
      )}
      {/* Resume Preview - Mobile - end */}

      {/* Resume Preview - Desktop - start */}
      <Col
        md={showSelectTemplate ? 8 : 6}
        className={`resume-builder__template ${
          hideTemplate ? "hideTemplate" : ""
        }`}
      >
        <TemplateComponent data={resumeData} imageRef={imageRef} />

        {showSelectTemplate ? (
          /** Change the Close button in the top-right corner to close the select template div */
          <Button
            onClick={() => setShowSelectTemplate(false)}
            className="resume-builder__back"
          >
            <FiX size={20} />
          </Button>
        ) : (
          /** regular close button for resume templates */
          <Link to="/resumes" className="resume-builder__back">
            <FiX size={40} />
          </Link>
        )}
        <Button
          variant="link"
          onClick={() => setShowSelectTemplate(true)}
          className={
            showSelectTemplate ? "d-none" : "resume-builder_select_template "
          }
        >
          <FiGrid size={20} /> Change Template
        </Button>
      </Col>
      {/* Resume Preview - Desktop - end */}

      <Snackbar {...snackBarData} />
    </div>
    // aman end
  );
};
export default ResumeBuilder;
