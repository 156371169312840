import React from 'react'
//import logoutimg from "../DashboardEmployee/logout.gif"
import {Row} from "react-bootstrap"
 
const Logout = ()=> {
    
    localStorage.clear();
    var redirectURL = (!(sessionStorage.getItem("redirectURL") === null)) ? sessionStorage.getItem("redirectURL") : "/";
    sessionStorage.removeItem("redirectURL");
    window.location.href= redirectURL;

    return (
        <>
          Logout...
          <Row>
              {/* <img src={logoutimg}/> */}
              Logging Out....!!!
          </Row>
        </>
    )
}
export default Logout;