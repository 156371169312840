// import node module libraries
import { useEffect,useState,Fragment } from 'react';
import { Modal,Card,ListGroup,Button,Container,Col,Row,Badge } from 'react-bootstrap';


// import sub components
import HeroAcademy from './HeroAcademy';
import AcademyStats from './AcademyStats';
import MostPopularCourses from './MostPopularCourses';
import BecomeAnInstructor from './BecomeAnInstructor';
import WhatCustomersSay from './WhatCustomersSay';
import LogosTopHeading from 'components/common/LogosTopHeading';
// import layouts
//import NavbarMegaMenu from '../layouts/marketing/navbars/NavbarMegaMenu';
//import FooterWithLinks from '../layouts/marketing/footers/FooterWithLinks';
import Url from '../Url';
import { Helmet } from 'react-helmet';
const HomeAcademy = () => {
  useEffect(() => {document.body.style.backgroundColor = '#fff';});
	const url1 = Url();
	const url = url1["url"];
	const s3 = url1["s3"];
	const s3URL = s3 + "logos/";
  const tempInternshipURL = "https://internshipgate.com";
  const [viCourseList,setVICourseList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEmployerPopup,SetShowEmployerPopup] = useState(false);
  const [seodetails, setSeodetails] = useState("");

  useEffect(()=>{
    const studentId = localStorage.getItem('studentId');
    const token = localStorage.getItem('stutoken');
    console.log(studentId);
    if(studentId && token){

      const api = url + "getEnrolledVICourseList" + "/" + studentId + "/" + token;

      fetch(api, {
          method: 'GET'
      })
          .then((apidata) => {
              return apidata.json()
          })
          .then((actualdata) => {
            setVICourseList(actualdata);
            setShowModal(true);
          })      
    }

    /*************************** Check for Employer looged in - start */
    const empToken = localStorage.getItem('emptoken');
    const token1 = localStorage.getItem('token1');
    const token2 = localStorage.getItem('token2');
    if(empToken && token1 && token2){
      //SetShowEmployerPopup(true);
      window.location.href="/empdashboard";
      return 1;
    }

    /*************************** Check for Employer looged in - end */    

    /*********** get page SEO Settings */

    var api = url + "getPageSeoSettingById/" + 3;
      fetch(api, {
      method: "GET",
      })
    .then((apidata1) => {
      return apidata1.json();
    })
    .then((actualdata1) => {
      setSeodetails(actualdata1);
    });

  },[]);

  const redirectToLMS = () => {
    const token = localStorage.getItem("stutoken");
    const studentId = localStorage.getItem("studentId");
  
    const api = url + "verifyLMSUser";
    fetch(api, {
      method: "POST",
      body: JSON.stringify({ token, studentId }),
      headers: { "Content-Type": "application/json" },
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        //console.log(actualdata1.apiURL);
  
        if (actualdata1.apiURL !== "") {
          let a = document.createElement("a");
          a.target = "_blank";
          a.href = actualdata1.apiURL;
          a.click();
          //window.location.href = actualdata1.apiURL;
        } else {
          alert(
            "Your session has expired, please login again to access your courses"
          );
          return false;
        }
      });
  };  

  const accessEmployerDashboard = () => {
    
    const empToken = localStorage.getItem("emptoken");
    if(empToken){
        const token1 = localStorage.getItem("token1");
        const token2 = localStorage.getItem("token2");
        if(token1 && token2)
        { 
            window.location.href = tempInternshipURL + "/Employer/" + token2 +"/" + token1 +"/" + empToken;
            return 1;
        }
        else 
        {
            localStorage.clear();
            alert("Session expired,please login again");
            window.location.href="/";
            localStorage.clear();
            return 0;                
        };
    }
    return 1;
  }//end of function  

  const accessStudentDashboard = () => {
    
    const token = localStorage.getItem("stutoken");
    if(token){
        const token1 = localStorage.getItem("token1");
        const token2 = localStorage.getItem("token2");
        if(token1 && token2)
        { 
            window.location.href = tempInternshipURL + "/StudentLink/" + token2 +"/" + token1 +"/" + token;
            return 1;
        }
        else 
        {
            localStorage.clear();
            alert("Session expired,please login again");
            window.location.href="/";
            localStorage.clear();
            return 0;                
        };
    }
    return 1;
  }//end of function  

  const LogoList1 = [
    {
      id: 1,
      logoimage: s3URL + "Oracle-Logo.png"
    },
    {
      id:2 ,
      logoimage: s3URL + "ibm-logo.png"
    },
    {
      id: 3,
      logoimage: s3URL + "tech-mahindra-logo.png"
    },
    {
      id: 4,
      logoimage: s3URL + "HCL-Logo.png"
    },
    {
      id: 5,
      logoimage: s3URL + "astrazeneca-logo.png"
    },
    {
      id: 6,
      logoimage: s3URL + "Wipro-logo.png"
    }
  ];

  const LogoList2 = [
    {
      id: 1,
      logoimage: s3URL + "Cognizant-Logo.png"
    },
    {
      id:2 ,
      logoimage: s3URL + "Hexaware-Technologies-logo.png"
    },
    {
      id: 3,
      logoimage: s3URL + "l-n-t-logo.png"
    },
    {
      id: 4,
      logoimage: s3URL + "mindtree-logo.png"
    },
    {
      id: 5,
      logoimage: s3URL + "persistent-systems-logo.png"
    },
    {
      id: 6,
      logoimage: s3URL + "mpasis logo.png"
    }
  ];  
  return (


    <main>

      <Helmet>
					<title>{seodetails.page_title}</title>
					<meta name="keywords" content={seodetails.page_meta_keyword} />
					<meta name="description" content={seodetails.page_meta_description} />
      </Helmet>
      {/* Hero Academy banner section */}
      <HeroAcademy />

      {/* Company Logos */}
      <LogosTopHeading title="TOP COMPANIES HIRING ON INTERNSHIPGATE" logos={LogoList1} />
      <LogosTopHeading title="" logos={LogoList2} />

      {/* Most Popular Courses */}
      <MostPopularCourses />

      {/* Become an instructor 
      <BecomeAnInstructor />
*/}
      {/* Various acedamy statistics  
      <AcademyStats />
*/}
      {/* What our customers say 
      <WhatCustomersSay />
*/}
    <Fragment>
			{viCourseList.length >0 ? (

        <Modal
        //{...props}
        show={showModal}
        onHide={() => {setShowModal(false);}}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        >

        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Enrolled Courses
          </Modal.Title>
        </Modal.Header>
              
          <Modal.Body className="px-0">
          <Card.Body className="p-0">
										 
            <ListGroup variant="flush">
              {viCourseList.map((course, index) => {
                  return (
                    <ListGroup.Item key={index}>
                    <Row >
                      <Col className="d-flex align-items-center">
                      <Col xs={8} >  
                        <i className={"fe fe-play-circle align-middle me-2 text-primary"}></i>
                        <span className="h5 m-0">{course.subject_name + " ("+ course.course_duration_desc+ ")"} </span>
                      </Col>
                      <Col xs={3} >
                        {course.course_status == "Expired" ? <Badge className="small" bg="danger">Expired</Badge> : <Badge bg="success">{course.course_status}</Badge>}
                      </Col>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  )
                })}      
            </ListGroup>   
            </Card.Body>   
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-start border-0 pt-0">
              {/*  Action Buttons  */}
              {/* <Button variant="primary" onClick={redirectToLMS}> */}
              <a className="btn btn-primary" href="/lms">
                Go to Virtual Internship
              </a>  
              <a className="btn btn-outline-warning" href="/student-dashboard">
                Go to Student Dashboard
              </a>              
            </Modal.Footer>          
		    </Modal>
			) : null}      
    </Fragment>
    {/* 
    <Fragment>

        <Modal
        //{...props}
        show={showEmployerPopup}
        onHide={() => {SetShowEmployerPopup(false);}}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        >
        
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Employer Dashboard
          </Modal.Title>
        </Modal.Header>
          <Modal.Body className="px-4">
            <Container>
              <Row className="justify-content-center">
                <Col xs={10} sm={7}>
                    <Button variant="primary" onClick={accessEmployerDashboard}>
                      Go to Employer Dashboard
                    </Button>
                 </Col>
              </Row>
            </Container>
          </Modal.Body>      
		    </Modal>
   
    </Fragment>    
    */}
    </main >
  )
}
export default HomeAcademy