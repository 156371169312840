import { v4 as uuid } from "uuid";

const CollageStudentRoutes = [

  {
		id: uuid(),
		menuitem: "Dashboard",
		link: "/coldashboard",
	},

  {
    id: uuid(),
    menuitem: "Profile",
    link: "/coldashboard/profile",
  },
];

export default CollageStudentRoutes;
