import React, { useState } from "react";
import cx from "classnames";
import { Accordion, Col, Row } from "react-bootstrap";
import moment from "moment";
import { FiChevronDown, FiPlus, FiTrash2 } from "react-icons/fi";
import Switch from "@material-ui/core/Switch";
import { InputField, Label, Button, RTE } from "../../foundation";
import { Controller } from "react-hook-form";
import Api, { ENDPOINTS } from "../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RiPencilLine } from "react-icons/ri";



const CustomSection = ({ watch, register, fields, append, remove, control }) => {
  const [deleting, setDeleting] = useState(null);
  const [title, setTitle] = useState(false);
  const customSections = watch("customSection");

  const handleMouseOver = () => {
    setTitle(true);
  };


  return (
    <div className="resume-builder__section">
      <h2>Customized Section </h2>
      {/* <h4>
      {title ? (
        <input
          type="text"
          defaultValue={`Untitled`}
          {...register('custom_title')}
          //name={`customSection[${index}].custom_title`}
          style={{ width: "110px", fontWeight: "600", color: "#2c3a4b" }}
        />
      ) : (
        "Untitled"

      
      )}
      <Button
        variant="link"
        type="button"
        style={{ fontSize: "28px", marginLeft: "8px" }}
        onClick={() => setTitle(true)}
         //onClick={()=>append({})}
        disabled={title}
      >
        <RiPencilLine />
      </Button>
    </h4>  */}

      {fields.map((field, index) => {
        const { section_name, city, start_date, end_date,custom_title,description} =
          customSections?.[index] || {};
        const displayDate = [];
        if (start_date) displayDate.push(moment(start_date).format("MMM YYYY"));
        if (end_date) displayDate.push(moment(end_date).format("MMM YYYY"));

        return (

          <div key={field.key} className={cx("resume-builder__section__card p-0")}>
            <input
              type="hidden"
              id={`customSection[${index}].id`}
              name={`customSection[${index}].id`}
              defaultValue={field.id}
              // ref={register}
              {...register(`customSection[${index}].id`)}
            />
            <Accordion defaultActiveKey={`${field.id ? "" : field.key}`}>

              {/*  Main Custom Title */}

              <h1>
                <Controller
                  control={control}
                  name={`customSection[${index}].custom_title`}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <InputField
                      type="text"
                      defaultValue={`Untitled`}
                      //{...register('custom_title')}
                      style={{ width: "150px", fontWeight: "900", color: "#2c3a4b", backgroundColor: "white", border: " 0.25px solid black" }}
                      //stretch
                      //placeholder=" UNTITLED"
                      onBlur={onBlur}     // notify when input is touched
                      onChange={onChange} // send value to hook form
                      checked={value}
                      inputRef={ref}


                    />

                  )}
                />
                <Button
                  variant="link"
                  type="button"
                  style={{ fontSize: "28px", marginLeft: "8px", }}
                  //onClick={() => setTitle(true)}
                  onMouseOver={handleMouseOver}
                  disabled={title}
                >
                  {/* {title ?  'Add title':<RiPencilLine /> } */}
                  <RiPencilLine />
                </Button>



              </h1>


              <Accordion.Item
                as={"div"}
                variant="link"
                eventKey={`${field.key}`}
                className="resume-builder__section__card--title"
              >

                <Accordion.Header className="w-100">
                  <div className="m-0">
                    <p className="m-0">
                      {/* {section_name && "(Not Specified)"} */}

                      {section_name
                        ? `${section_name} `
                        : section_name || "(Not Specified)"}
                    </p>
                    {displayDate.length > 0 && (
                      <Label className="mb-0">{displayDate.join(" - ")}</Label>
                    )}
                  </div>{" "}
                  {/* <span className="d-flex m-0">
                                        <FiChevronDown size={20} />
                                    </span> */}
                </Accordion.Header>
                <Accordion.Body eventKey={`${field.key}`}>
                  <>
                    <Row className="mb-3 ">
                      <Col>
                        <Label>Projects , Sports and Achievements.</Label>
                        {/* <Controller
                                                    control={control}
                                                    name={`customSection[${index}].section_name`}
                                                    render={({ field }) => (
                                                        <InputField
                                                            stretch
                                                            placeholder="Untitled"
                                                            {...field}
                                                        />
                                                    )}
                                                /> */}

                        <Controller
                          control={control}
                          name={`customSection[${index}].section_name`}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) => (
                            <InputField
                              stretch
                              placeholder=" Activity Name  "
                              onBlur={onBlur}         // notify when input is touched
                              onChange={onChange}     // send value to hook form
                              checked={value}
                              inputRef={ref}
                            />
                          )}
                        />

                      </Col>

                      <Col>
                        <Label>City</Label>
                        <Controller
                          control={control}
                          name={`customSection[${index}].city`}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) => (
                            <InputField
                              stretch
                              placeholder="Enter city"
                              onBlur={onBlur}     // notify when input is touched
                              onChange={onChange} // send value to hook form
                              checked={value}
                              inputRef={ref}
                            />
                          )}
                        />
                      </Col>



                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Row>

                          <Col xs={6} className="pr-1">
                            <Label>Start Date</Label>
                            <Controller
                              control={control}
                              name={`customSection[${index}].start_date`}
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: {
                                  invalid,
                                  isTouched,
                                  isDirty,
                                  error,
                                },
                                formState,
                              }) => (
                                <InputField
                                  type="date"
                                  stretch
                                  onBlur={onBlur} // notify when input is touched
                                  onChange={onChange} // send value to hook form
                                  checked={value}
                                  inputRef={ref}
                                />
                              )}
                            />
                          </Col>

                          <Col xs={6} className="pl-1">
                            <Label>End Date</Label>
                            <Controller
                              control={control}

                              name={`customSection[${index}].end_date`}
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: {
                                  invalid,
                                  isTouched,
                                  isDirty,
                                  error,
                                },
                                formState,
                              }) =>
                                end_date === null ? (
                                  <InputField
                                    stretch
                                    value="Present"
                                    readOnly
                                  />
                                ) : (
                                  <InputField
                                    type="date"
                                    min={start_date}
                                    stretch
                                    onBlur={onBlur} // notify when input is touched
                                    onChange={onChange} // send value to hook form
                                    checked={value}
                                    inputRef={ref}
                                  />
                                )
                              }
                            />
                          </Col>



                        </Row>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Label>Description</Label>
                        <Controller
                          control={control}
                          name={`customSection[${index}].description`}
                        
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) => (
                            <RTE
                              value={value}
                              onBlur={onBlur} // notify when input is touched
                              onChange={onChange} // send value to hook form
                              checked={value}
                              inputRef={ref}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>


            <Button
              variant="link"
              disabled={deleting === index}
              className="resume-builder__section__card--delete"
              onClick={async () => {
                try {
                  setDeleting(index);
                  if (field.id) {
                    await Api.delete(
                      `${ENDPOINTS.DELETE_CUSTOMDATA}/${field.id}`
                    );
                  }
                  remove(index);
                } finally {
                  setDeleting(null);
                }
              }}
            >
              {deleting === index ? (
                <CircularProgress size={20} color="primary" />
              ) : (
                <FiTrash2 size={20} />
              )}
            </Button>
          </div>
        );
      })}
      <Row className="mb-3">
        <Col>
          <Button className="text-info fs-4" variant="link" type="button" onClick={() => append({})}>
            <FiPlus />
            {fields.length < 1 ? " Add section" : " Add one more custom section"}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default CustomSection;



