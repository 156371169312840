// import node module libraries
import { Fragment, useEffect, useState } from "react";
import { Card, Form, Button, Modal } from "react-bootstrap";
import { PencilSquare, Trash } from "react-bootstrap-icons";

// import custom components
import GKAccordionActions from "components/marketing/common/accordions/GKAccordionActions";

// import data files
import NewCourseData from "data/marketing/NewCourseData";
import Url from "components/Url";

const AddLecture = (props) => {
  const {
    setLoad,
    editSubtopic,
    topicId,
    subtopicName,
    setEditSubtopic,
    subtopicDesc,
  } = props;
  const [showSubtopic, setShowSubtopic] = useState(false);
  const handleCloseSubtopic = () => setShowSubtopic(false);
  const handleShowSubtopic = () => setShowSubtopic(true);
  const [subTopic, setSubTopic] = useState("");
  const [subTopicDesc, setSubTopicDesc] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const urls = Url();
  const url = urls.url;

  useEffect(() => {
    if (editSubtopic) {
      handleShowSubtopic();
      setSubTopic(subtopicName);
      setSubTopicDesc(subtopicDesc);
    } else {
      setSubTopic("");
      setSubTopicDesc("");
    }
  }, [editSubtopic, topicId, subtopicName, subtopicDesc]);

  const handleSubmit = () => {
    if (subTopic) {
      const api = url + "updateVICourseSubtopic";
      const config = {
        method: "POST",
        body: JSON.stringify({
          id: editSubtopic ? editSubtopic : null,
          course_topic_id: parseInt(topicId),
          subtopic_name: subTopic,
          subtopic_description: subTopicDesc,
          subtopic_order_num: null,
          video_link: null,
          subtopic_duration: "20:00",
          modified_by: null,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      fetch(api, config)
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
          if (result.message === "Success") {
            alert("Subtopic updated successfully.");
            setEditSubtopic();
            handleCloseSubtopic();
            setLoad(true);
          }
        });
    }
  };
  return (
    <Fragment>
      <Button
        variant="outline-primary"
        className="btn btn-outline-primary btn-sm mt-3 mx-1"
        onClick={() => {
          setEditSubtopic();
          handleShowSubtopic();
        }}
      >
        Add Subtopic +
      </Button>
      <Modal
        show={showSubtopic}
        onHide={handleCloseSubtopic}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Subtopic</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Form.Group className="mb-3" controlId="formaddnewlecture">
            <Form.Control
              type="text"
              value={subTopic}
              onChange={(e) => setSubTopic(e.target.value)}
              placeholder="Add new subtopic"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formaddnewlecture">
            <Form.Control
              type="text"
              value={subTopicDesc}
              onChange={(e) => setSubTopicDesc(e.target.value)}
              placeholder="Add new subtopic Description"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formaddnewlecture">
            <Form.Control
              type="text"
              value={videoLink}
              onChange={(e) => setVideoLink(e.target.value)}
              placeholder="Video Link"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="pt-0 border-0 d-inline ">
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => {
              setEditSubtopic();
              handleCloseSubtopic();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const AddSection = (props) => {
  const {
    courseid,
    show,
    handleClose,
    handleShow,
    editTopicID,
    topicName,
    topicDesc,
    setLoad,
    clearState,
    topicOrderNum,
  } = props;
  const [topic, setTopic] = useState("");
  const [topicDescription, setTopicDescription] = useState("");
  const [orderNum, setOrderNum] = useState();
  const urls = Url();
  const url = urls.url;

  const handleSubmit = () => {
    if (topic) {
      const api = url + "updateVICourseTopic";
      const ID = editTopicID ? editTopicID : null;
      const config = {
        method: "POST",
        body: JSON.stringify({
          id: ID,
          course_id: parseInt(courseid),
          topic_name: topic,
          topic_description: topicDescription,
          topic_order_num: parseInt(orderNum),
          vendor_id: null,
          modified_by: null,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      fetch(api, config)
        .then((res) => res.json())
        .then((result) => {
          if (result.message === "Success") {
            alert("Topic updated successfully.");
            handleClose();
            setLoad(true);
          }
        });
    }
  };

  useEffect(() => {
      setTopic(topicName);
      setOrderNum(topicOrderNum);
      setTopicDescription(topicDesc);
    console.log(topicOrderNum);
  }, [topicName, topicOrderNum, topicDesc]);

  return (
    <Fragment>
      <Button
        variant="outline-primary"
        className="btn btn-outline-primary btn-sm mt-3"
        onClick={clearState}
      >
        Add Topic
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Topic</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Form.Group className="mb-3" controlId="formaddnewsection">
            <Form.Control
              type="text"
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              placeholder="Add new topic"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formaddnewsection">
            <Form.Control
              type="text"
              value={topicDescription}
              onChange={(e) => setTopicDescription(e.target.value)}
              placeholder="Add new topic description"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formaddnewsection">
            <Form.Control
              type="number"
              value={orderNum}
              onChange={(e) => setOrderNum(e.target.value)}
              placeholder="Topic order number (Number Only)"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="pt-0 border-0 d-inline ">
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
          <Button variant="outline-secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const Curriculum = (props) => {
  const { next, previous, courseid, setIsSaved } = props;
  const urls = Url();
  const url = urls.url;
  const [topics, setTopics] = useState();
  const [topicId, setTopicId] = useState();
  const [subTopics, setSubTopics] = useState();
  const [subtopicDesc, setSubtopicDesc] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [topicName, setTopicName] = useState("");
  const [subtopicName, setSubtopicName] = useState("");
  const [editTopicID, setEditTopicID] = useState();
  const [topicOrderNum, setTopicOrderNum] = useState();
  const [topicDesc, setTopicDesc] = useState();

  const [checkTopics, setCheckTopics] = useState(false);

  const [editSubtopic, setEditSubtopic] = useState();
  const [load, setLoad] = useState(false);

  const handleNext = () => {
    if (checkTopics) {
      alert("You must add a topic");
      return;
    }
    setIsSaved(false);
    next();
  };

  useEffect(() => {
    setIsSaved(true);
    if (courseid) {
      const api = url + "getVICourseDetails/" + courseid;
      const config = {
        method: "GET",
      };
      fetch(api, config)
        .then((res) => res.json())
        .then((data) => {
          const topicids = [];
          const topic = [];
          //topics sorting
          if (data.courseTopicSubtopics.length > 0) {
            setCheckTopics(false);
            data.courseTopicSubtopics.map((item) => {
              if (!topicids.includes(item.topic_id)) {
                topicids.push(item.topic_id);
                topic.push(item);
              }
            });
            setSubTopics(data.courseTopicSubtopics);
          } else {
            setCheckTopics(true);
          }

          //subtopics sorting
          if (topic.length > 0) {
            setTopics(topic);
          }
          console.log(data);
          setLoad(false);
        });
    }
  }, [load]);

  function sendTopicname(editTopicName, editTopicID, tOrderNum, tDesc) {
    setTopicName(editTopicName);
    setEditTopicID(editTopicID);
    setTopicOrderNum(tOrderNum);
    setTopicDesc(tDesc);
    handleShow();
  }

  function clearState() {
    setTopicName("");
    setEditTopicID("");
    setTopicOrderNum();
    setTopicDesc("");
    handleShow();
  }

  function deleteTopic(id) {
    if (id && window.confirm("Are you sure? Want to delete Topic?")) {
      const api = url + "deleteVICourseTopicById/" + id;
      const config = {
        method: "DELETE",
      };
      fetch(api, config)
        .then((res) => res.json())
        .then((data) => {
          if (data.message === "Record Deleted") {
            alert("Topic Deleted Successfuly");
            setLoad(true);
          }
        });
    }
  }

  var subdata;
  const handleSubtopics = (topicID) => {
    const data = [];
    subTopics?.map((el) => {
      if (topicID === el.topic_id) {
        data.push({
          id: el.subtopic_id ? el.subtopic_id : -1,
          title: el.subtopic_name ? el.subtopic_name : "",
          topic_id: el.topic_id,
          description: el.subtopic_description,
        });
      }
    });
    return (
      <GKAccordionActions
        setSubtopicName={setSubtopicName}
        setLoad={setLoad}
        setSubtopicDesc={setSubtopicDesc}
        setEditSubtopic={setEditSubtopic}
        setTopicId={setTopicId}
        accordionItems={data}
      />
    );
  };

  return (
    <Form>
      {/* Card */}
      <Card className="mb-3  border-0">
        <Card.Header className="border-bottom px-4 py-3">
          <h4 className="mb-0">Curriculum</h4>
        </Card.Header>
        {/* Card body */}
        <Card.Body>
          {topics?.map((item) => (
            <div key={item.topic_id} className="bg-light rounded p-2 mb-4">
              <h4>
                {item.topic_name}
                <span className="h6 mx-2">
                  Order:{" "}
                  {item.topic_order_num === null
                    ? "Default"
                    : item.topic_order_num}
                </span>
                <span
                  onClick={() => deleteTopic(item.topic_id)}
                  className="float-end"
                >
                  <Trash />
                </span>
                <span
                  onClick={() =>
                    sendTopicname(
                      item.topic_name,
                      item.topic_id,
                      item.topic_order_num,
                      item.topic_description
                    )
                  }
                  className="float-end mx-2"
                >
                  <PencilSquare />
                </span>
              </h4>
              {/* Item list */}
              {handleSubtopics(item.topic_id)}
              {!editSubtopic ? (
                <AddLecture
                  editSubtopic={editSubtopic}
                  setEditSubtopic={setEditSubtopic}
                  subtopicDesc={subtopicDesc}
                  topicId={item.topic_id}
                  subtopicName={subtopicName}
                  setLoad={setLoad}
                />
              ) : null}
            </div>
          ))}
          {editSubtopic ? (
            <AddLecture
              editSubtopic={editSubtopic}
              setEditSubtopic={setEditSubtopic}
              topicId={topicId}
              subtopicDesc={subtopicDesc}
              subtopicName={subtopicName}
              setLoad={setLoad}
            />
          ) : null}

          <AddSection
            setLoad={setLoad}
            editTopicID={editTopicID}
            topicName={topicName}
            topicOrderNum={topicOrderNum}
            topicDesc={topicDesc}
            show={show}
            setShow={setShow}
            handleClose={handleClose}
            handleShow={handleShow}
            courseid={courseid}
            clearState={clearState}
          />
        </Card.Body>
      </Card>
      {/* Button */}
      <div className="d-flex justify-content-end">
        <div>
          <Button
            variant="secondary"
            className="mx-2"
            onClick={() => {
              setIsSaved(false);
              previous();
            }}
          >
            Previous
          </Button>
          <Button variant="primary" onClick={handleNext}>
            Next
          </Button>
        </div>
      </div>
    </Form>
  );
};
export default Curriculum;
