// import node module libraries
import { v4 as uuid } from 'uuid';
import {
    FileEarmarkText,
    Files,
    Star,
    CheckCircle,
    BoxArrowRight,
    Chat
} from 'react-bootstrap-icons';

export const InfoCardsData = [
    {
        id: uuid(),
        icon: <FileEarmarkText />,
        text: '15',
        subText: 'Posted',
        color: 'secondary',
        link: '#'
    },
    {
        id: uuid(),
        icon: <Files />,
        text: '173',
        subText: 'Received',
        color: 'success',
        link: '#'
    },
    {
        id: uuid(),
        icon: <Star />,
        text: '16',
        subText: 'Shortlisted',
        color: 'warning',
        link: '#'
    },
    {
        id: uuid(),
        icon: <CheckCircle />,
        text: '7',
        subText: 'Hired',
        color: 'info',
        link: '#'
    },
    {
        id: uuid(),
        icon: <BoxArrowRight />,
        text: '1',
        subText: 'Rejected',
        color: 'danger',
        link: '#'
    },
    {
        id: uuid(),
        icon: <Chat />,
        text: 'Chat',
        subText: '1 Online',
        color: 'primary',
        link: '#',
        modal: '#chatModal'
    }
];

export default InfoCardsData;
