import React, { useState, useEffect,useCallback } from 'react';
import Axios from "NewApi";
import { Col, Spinner, Button } from 'react-bootstrap';
import "./ProjectSelection.css";
import {
  BsRobot,
} from "react-icons/bs";

const ProjectSelection = (props) => {
  const studentId = localStorage.getItem("studentId");
  const token = localStorage.getItem("stutoken");
  const careerDetailId = props.match.params.careerDetailId;
  const projectId = props.match.params.projectId;

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Loading projects...');
  const [expandedProject, setExpandedProject] = useState(null);
  const [VICourse, setVICourse] = useState(null);
  const [selectedSubTopic, setSelectedSubTopic] = useState("");
  const [projectTopics, setProjectTopics] = useState([]);

  const loadingMessages = [
    'Getting virtual internship details...',
    'IGBot is creating a custom project...',
    'Creating project steps...',
    'Fetching project data...',
    'Almost there...'
  ];

  // const projectTopics = {
  //   "Web Development": [
  //     "Front-End Development",
  //     "Back-End Development",
  //     "Full-Stack Development",
  //     "Web Performance Optimization",
  //     "Web Security"
  //   ],
  //   "Data Science": [
  //     "Data Analysis",
  //     "Machine Learning",
  //     "Data Visualization",
  //     "Big Data Processing",
  //     "Statistical Modeling"
  //   ],
  //   "IoT": [
  //     "Sensor Networks",
  //     "Embedded Systems",
  //     "IoT Data Analytics",
  //     "IoT Security",
  //     "Smart Home Automation"
  //   ],
  //   "Python": [
  //     "Personal Expense Tracker App",
  //     "Basic To-Do List Application",
  //     "Weather Dashboard",
  //     "Online Quiz System",
  //     "Simple Blog Website"
  //   ],
  //   "Cybersecurity": [
  //     "Network Security",
  //     "Ethical Hacking",
  //     "Information Security Management",
  //     "Cryptography",
  //     "Cyber Threat Intelligence"
  //   ],
  //   "AWS Cloud": [
  //     "Simple Static Website Hosting on AWS S3",
  //     "AWS EC2 Web Server Setup",
  //     "AWS RDS Database Setup",
  //     "AWS S3 File Upload System",
  //     "AWS Lambda Function for Thumbnail Creation",
  //   ],
  //   "Java": [
  //     "Enterprise Application Development",
  //     "Web Application Development",
  //     "Mobile Application Development (Android)",
  //     "Java Frameworks (Spring, Hibernate)",
  //     "Java Microservices"
  //   ],
  //   "Human resources": [
  //     "Recruitment and Staffing",
  //     "Employee Relations",
  //     "Training and Development",
  //     "Performance Management",
  //     "Compensation and Benefits"
  //   ],
  //   "Digital Marketing": [
  //     "Search Engine Optimization (SEO)",
  //     "Content Marketing",
  //     "Social Media Marketing",
  //     "Email Marketing",
  //     "Pay-Per-Click (PPC) Advertising"
  //   ],
  //   "Marketing": [
  //     "Market Research and Analysis",
  //     "Brand Development Strategy",
  //     "Marketing Campaign Plan",
  //     "Customer Segmentation",
  //     "Digital Marketing Strategy"
  //   ],
  //   "Finance": [
  //     "Investment Portfolio Management",
  //     "Financial Statement Analysis",
  //     "Budgeting and Forecasting",
  //     "Risk Management Plan",
  //     "Personal Finance Management"
  //   ]    ,
  //   "BigData": [
  //     "Hadoop Basics",
  //     "Data Processing with Spark",
  //     "Big Data Analytics",
  //     "NoSQL Databases",
  //     "Data Warehousing"
  //   ],
  //   "DevOps": [
  //     "CI/CD Pipeline with Jenkins",
  //     "Infrastructure as Code with Terraform",
  //     "Monitoring with Prometheus and Grafana",
  //     "Configuration Management with Ansible",
  //     "Docker and Kubernetes Integration"
  //   ],
  //   "Linux": [
  //     "Linux Shell Scripting",
  //     "Setting up a Linux Server",
  //     "Linux System Monitoring",
  //     "Network Configuration",
  //     "User and File Management"
  //   ],    
  //   "Blockchain": [
  //     "Smart Contract Development",
  //     "Blockchain Basics",
  //     "Decentralized Application (DApp)",
  //     "Crypto Wallet",
  //     "Building a Private Blockchain"
  //   ],
  //   "Containers": [
  //     "Dockerizing a Web Application",
  //     "Kubernetes Basics",
  //     "Container Orchestration with Docker Swarm",
  //     "Microservices with Docker",
  //     "Continuous Integration with Jenkins and Docker"
  //   ],
  //   "Microsoft Azure Cloud": [
  //     "Deploying a Web App on Azure",
  //     "Azure Functions",
  //     "Azure Blob Storage",
  //     "Azure DevOps Pipeline",
  //     "Virtual Machines on Azure"
  //   ],    
  //   "Node JS": [
  //     "REST API with Express",
  //     "Real-time Chat Application",
  //     "File Upload System",
  //     "Task Scheduler",
  //     "CRUD Application"
  //   ],    
  // };

  useEffect(() => {
    getVICourseDetails();
  }, []);

  const fetchProjects = useCallback(async (subTopic) => {
    try {
      setLoading(true);
      const response = await Axios.post('suggestProjects', {
        careerDetailId: careerDetailId,
        subTopic: subTopic,
      },
      {headers: {
        "Content-type": "Application/json",
        "Authorization": `Bearer ${token}`,
        "X-USER-ID": studentId
        }   
      });
      setProjects(response.data.project_suggestions);
      if(response.data.project_suggestions.length > 0)
        setSelectedProject(response.data.project_suggestions[0]);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setLoading(false);
    }
  }, [careerDetailId]);

  useEffect(() => {
    if (selectedSubTopic) {
      fetchProjects(selectedSubTopic);
    }
  }, [selectedSubTopic, fetchProjects]);

  useEffect(() => {
    if (loading) {
      const intervalId = setInterval(() => {
        setLoadingMessage(prev => {
          const currentIndex = loadingMessages.indexOf(prev);
          const nextIndex = (currentIndex + 1) % loadingMessages.length;
          return loadingMessages[nextIndex];
        });
      }, 5000); // Change message every 5 seconds

      return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }
  }, [loading]);

  const getVICourseDetails = async () => {
    try {
      const response = await Axios.get("getCareerDetails/" + careerDetailId, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-USER-ID": studentId,
        },
      });

      setVICourse(response.data.careerDetails);
      setProjectTopics([response.data.projectTopics.topic_1,response.data.projectTopics.topic_2,response.data.projectTopics.topic_3,
        response.data.projectTopics.topic_4,response.data.projectTopics.topic_5]);
      projectTopics
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const toggleExpand = (project) => {
    if (expandedProject?.project === project.project) {
      setExpandedProject(null);
    } else {
      setExpandedProject(project);
    }
  };

  const handleCardClick = (project) => {
    setSelectedProject(project);
  };

  const createMarkup = (html) => {
    return { __html: html };
  };

  const handleButtonClick = async () => {
    // if (selectedProject) {
    //   try {
    //     await Axios.post('save-project', { project: selectedProject });
    //     alert('Project selected and saved successfully!');
    //   } catch (error) {
    //     alert('Error saving the selected project.');
    //   }
    // } else {
    //   alert('Please select a project first.');
    // }
    window.location.href = `/lms/online-training/project/${careerDetailId}`;
  };

  const renderSubTopicButtons = () => {
    console.log(projectTopics)
    const subject = VICourse?.subject;
    const subTopics = projectTopics || [];

    return subTopics.map((subTopic) => (
      <Button
        key={subTopic}
        variant="outline-primary"
        className="m-2"
        onClick={() => setSelectedSubTopic(subTopic)}
      >
        {subTopic}
      </Button>
    ));
  };

  if (loading) {
    return (
      <div className="row">
        <div className="col-12" style={{ cursor: "pointer", color: "var(--geeks-primary)", fontWeight: "bold" }}>
          <Col sm={12}>
            <h1>{VICourse?.subject}</h1>
            <BsRobot className="mb-2" size={30} />{" "} {loadingMessage} {""} <Spinner animation="grow" size={15} variant="primary" />
          </Col>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Col>
        <div className="row">
          <Col sm={12}>
            <h1>{VICourse?.subject}</h1>
            <div className="mt-2"><em>Welcome to your Virtual Internship Program! <br /> <br />To get started, please choose a topic of your interest for your project. Our AI, IGBot, will generate a unique project tailored to your selected topic. <br /> <span className="text-danger">Once you confirm the project, you'll embark on your virtual internship journey, and the project topic cannot be changed.</span></em></div>
            <div className="mt-2">{renderSubTopicButtons()}</div>
            {selectedSubTopic && (<div className="mt-2">You have selected: <h5>{selectedSubTopic}</h5></div>)}
          </Col>

          {projects.map((project) => (
            <div className="col-md-12" key={project.project}>
              <div
                className={`card mb-4 project-card ${selectedProject?.project === project.project ? 'selected' : ''}`}
                onClick={() => handleCardClick(project)}
                style={{ cursor: 'pointer' }}
              >
                <div className="card-body">
                  <h4 className="card-title text-secondary">Project Name:</h4>
                  <h3>{project.project}</h3>
                  <p className="card-text mt-2">
                    <h4 className="text-secondary">Project Description</h4>
                    {project.description}</p>

                    <p className="card-text">
                    <h4 className='text-secondary'>Project Prerequisite</h4>
                    {project.prerequisite.replace('.','.<br/>')}</p>

                  <p className="card-text">
                    <h4 className='text-secondary'>Project Execution Steps</h4>
                    <div
                      dangerouslySetInnerHTML={createMarkup(project.detailed_steps)}
                    ></div>

                  </p>
                  {selectedProject?.project === project.project && (
                    <div className="tick-icon">&#10003;</div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Col>
      <Col>
        <Button
          className="btn btn-primary"
          onClick={handleButtonClick}
          disabled={!selectedProject}
        >
          Start Project
        </Button>
      </Col>
    </div>
  );
};

export default ProjectSelection;
