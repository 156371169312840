// import node module libraries
import React, { Fragment, useState, useEffect } from "react";
import { Card, Table, Badge, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import Url from "components/Url";
import Axios from "axios";
// import media files

import SurveyImg from 'assets/images/svg/survey-img.svg';
import { ToastContainer, toast } from "react-toastify";

const Quiz = (props) => {

  const encryptedCareerDetailId = props.match.params.careerDetailId;
  
  localStorage.setItem('encryptedCareerDetailId',encryptedCareerDetailId);
  const history = useHistory();
  const [lmsStudentTests,setLMSStudentTests] = useState([]);
  const [careerDetail,setCareerDetail] = useState();
  const [load, setLoad] = useState(false);
	const url1 = Url();
	const url = url1["url"];
  const studentId = localStorage.getItem('studentId');
  const token = localStorage.getItem('stutoken');

  useEffect(() => {
		console.log(encryptedCareerDetailId);

		let apiURL =  url + "getLMSStudentTests/" + studentId + "/" + encryptedCareerDetailId;
		Axios.get(
			apiURL,
			{headers: {
					"Content-type": "Application/json",
					"Authorization": `Bearer ${token}`
					}   
				}
		  )
		  .then((response) => {
			  //var response = response.data.virtualInternshipList;
			  //console.log(response.data);
			  setLMSStudentTests(response.data.studentTests);
        setCareerDetail(response.data.careerDetail)
			},
			(error) => {
			  var status = error.response.status
			}
		  );	
	},[encryptedCareerDetailId , load]);	


  const retakeTest = (studentTestId) => {
    let apiURL =  url + "retakeTest";
		Axios.post(
			apiURL,
      {userId:studentId, studentTestId, encryptedCareerDetailId},
			{headers: {
					"Content-type": "Application/json",
					"Authorization": `Bearer ${token}`
					}   
				}
		  )
		  .then((response) => {
        if(response.status == 200){
          var studentTestId = response.data.newStudentTestId;
          if(studentTestId){
          window.location.href = "/lms/online-training/quiz/start/" + studentTestId;
          return;
          }else{
            toast.warning("Issue while starting new test, please try again",{autoClose: 1500});
          }
        }else{
          toast.warning("Issue while starting new test, please try again",{autoClose: 1500});
        }
			},
			(error) => {
			  var status = error.response.status
			}
		  );	
  }//end of  retakeTest

  const downloadCertificate = (studentTestId) => {
      //history.push ("/Certificateshow/"+ studentId + "/" + studentTestId);
      window.open ("/Certificateshow/"+ studentId + "/" + studentTestId,'_blank');
  }//end of  retakeTest

  return (
      <div>
      <Card className="border-0">
        <Card.Body className="mb-8">
          <div className="text-center">
            <img src={SurveyImg} alt="" className="img-fluid" />
            <div className="px-lg-4">
              <h1>Welcome to Quiz </h1>
              <p className="mb-2 h3">{"Online Assessment for " + careerDetail?.subject_name}</p>
              
              {careerDetail?.project_status == 2 ? (
                <Fragment>
                  <p>Pass with 65% on 25 questions. Retry if necessary. Certificate downloadable after passing.</p>
              <Table responsive hover className="text-nowrap text-lg-wrap table-centered">
                  <thead className="table-light">
                    <tr>
                      <th>Action</th> 
                      <th>Test Name</th>
                      <th>Start Date</th>
                      <th>Completion Date</th>
                      <th>Total Marks Obtained</th>
                      <th>Result</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {lmsStudentTests.map((test , index ) => {
                      return (
                        <tr key={index}>
                          <td> 
                          {
                              test.test_result == 2 && !test.retake_student_test_id  ? <Button variant="link" onClick={() => retakeTest(test.student_test_id)} >Retake Test</Button>  : 
                              test.test_result == 2 &&  test.retake_student_test_id > 0 ? "Retake Test" :
                              test.test_result == 1 ? <Button variant="link" onClick={ () => downloadCertificate(test.student_test_id)} >Download Certificate</Button> :
                              test.last_question_attempted > 0 ? <Link to={`/lms/online-training/quiz/start/${test.student_test_id}`} >Resume Test</Link> :
                              <Link to={`/lms/online-training/quiz/start/${test.student_test_id}`} >Start Test</Link>
                            }                            

                          </td>                             
                          <td>
                            {/* <Link to={`/lms/online-training/quiz/start/${test.student_test_id}`} >{`${careerDetail.subject_name} # ${index+1}`}</Link> */}
                            {/* {`${careerDetail.subject_name} # ${lmsStudentTests.length - index}`} */}
                            {`Test # ${lmsStudentTests.length - index}`}
                          </td>
                          <td>{test.test_start_date}</td>
                          <td>{test.test_completion_date}</td>
                          <td>{test.total_marks_obtained}</td>
                          <td>{test.test_result == 1 ? <Badge bg="success-soft">Passed</Badge> : 
                                test.test_result == 2 ? <Badge bg="danger-soft">Failed</Badge> : 
                                test.last_question_attempted > 0 ? <Badge bg="primary-soft">Started</Badge> : 
                                <Badge bg="secondary-soft">Not Attempted</Badge> }</td>
                          {/* <td><Link to={`/lms/online-training/quiz/start/${test.student_test_id}`} >
                            {
                              test.test_result == 2 ? "Retake Test" : 
                              test.test_result == 1 ? "Download Certificate" :
                              test.last_question_attempted > 0 ? "Resume Test" : "Start Test"
                            }
                          </Link>
                          </td> */}
                          {/*
                            Logic for different status
                            test_result == 1: Student has passed the test, allow him to download certificate
                            test_result == 2: Student has failed the test, retake the test. if there is a next exam record exists (count(item) > index) 
                                              that means the student has already retaken the test hence disable the link.
                            else just redirect to the start link to display the last accessed test record

                          */}
                       
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                </Fragment>
              ):(
                <div className="col-12">
                  <p className="h5">You can take test only after project approval. Current Project status is {careerDetail?.project_status_desc} </p>
                </div>
              )
              }
            </div>
          </div>
        </Card.Body>
      </Card>
      <ToastContainer />
    </div>
  )
}

export default Quiz;
