// ** Import from react dom
import { Route, Switch, Redirect } from "react-router-dom";

// ** Import core SCSS styles
import "assets/scss/theme.scss";

// ** Import Layouts
import DashboardIndex from "layouts/dashboard/DashboardIndex";
import AuthLayout from "layouts/dashboard/AuthLayout";
import HelpCenterLayout from "layouts/marketing/HelpCenterLayout"; // ( added in v1.3.0 )
import DashboardIndexCompact from "layouts/dashboard/DashboardIndexCompact"; // ( added in v1.3.0 )
import DashboardIndexTop from "layouts/dashboard/DashboardIndexTop"; // ( added in v1.3.0 )
import JobListingLayout from "layouts/marketing/JobListingLayout"; // ( added in v2.0.0 )

// ** Import Dahbaord Menu Pages
import Overview from "components/dashboard/overview/Overview";
import Analytics from "components/dashboard/analytics/Analytics";

// ** Import Courses Pages
import AllCourses from "components/dashboard/courses/all-courses/AllCourses";
import CoursesCategory from "components/dashboard/courses/CoursesCategory";
import CategorySingle from "components/dashboard/courses/CategorySingle";

import Sites from "components/pages/Sites";

// ** Import Users Pages
import Instructor from "components/dashboard/user/Instructor";
import Students from "components/dashboard/user/Students";

// ** Import CMS Pages
import CMSDashboard from "components/dashboard/cms/CMSDashboard";
import AllPosts from "components/dashboard/cms/all-posts/AllPosts";
import AddNewPost from "components/dashboard/cms/AddNewPost";
import Category from "components/dashboard/cms/Category";
import MentorshipBookingList from "components/dashboard/mentorship/MentorshipBookingList";
import MentorRegList from "components/dashboard/mentorship/MentorRegList";
// ** Import Authentication components
// ** Internshipgate Authentication Routes - start
import StudentLogin from "components/authentication/StudentLogin";
import CollegeLogin from "components/authentication/StudentLogin";
import Register from "components/authentication/Register";

// ** Internshipgate Authentication Routes - end

import SignIn from "components/dashboard/authentication/SignIn";
import Logout from "components/dashboard/authentication/Logout";
import SignUp from "components/dashboard/authentication/SignUp";
import ForgetPassword from "components/dashboard/authentication/ForgetPassword";
import Notifications from "components/dashboard/authentication/Notifications";

// ** Import Settings components
import General from "components/dashboard/settings/General";
import Google from "components/dashboard/settings/Google";
import Social from "components/dashboard/settings/Social";
import SocialLogin from "components/dashboard/settings/SocialLogin";
//import Payment from 'components/dashboard/settings/Payment';
import SMTPServer from "components/dashboard/settings/SMTPServer";

// Dashboard Projects ( new v1.2.0 )
import ProjectGrid from "components/dashboard/projects/grid/ProjectGrid";
import ProjectList from "components/dashboard/projects/list/ProjectList";
import CreateProject from "components/dashboard/projects/create-project/CreateProject";
import ProjectBudget from "components/dashboard/projects/single/budget/ProjectBudget";
import ProjectFiles from "components/dashboard/projects/single/files/ProjectFiles";
import ProjectOverview from "components/dashboard/projects/single/overview/ProjectOverview";
import ProjectSummary from "components/dashboard/projects/single/summary/ProjectSummary";
import ProjectTask from "components/dashboard/projects/single/task/ProjectTask";
import ProjectTeam from "components/dashboard/projects/single/team/ProjectTeam";

// Dashboard Apps -> Mail ( new v1.3.0 )
import Mail from "components/dashboard/mail-app/mail/Mail";
import MailDraft from "components/dashboard/mail-app/mail-draft/MailDraft";
import MailDetails from "components/dashboard/mail-app/mail-details/MailDetails";
import MailProvider from "context/providers/MailProvider";

// Dashboard Apps -> Task Kanban ( v1.4.0 )
import TaskKanban from "components/dashboard/task-kanban/Kanban";
import TaskKanbanProvider from "context/providers/TaskKanbanProvider";
import Chat from "components/dashboard/chat/Chat";
import ChatProvider from "context/providers/ChatProvider";

// ** Boostrap Forms components
import ChecksRadios from "components/elements/bootstrap/forms/ChecksRadios";
import FloatingLabels from "components/elements/bootstrap/forms/FloatingLabels";
import FormControls from "components/elements/bootstrap/forms/FormControls";
import FormText from "components/elements/bootstrap/forms/FormText";
import BSInputGroup from "components/elements/bootstrap/forms/BSInputGroup";
import Layouts from "components/elements/bootstrap/forms/Layouts";
import Range from "components/elements/bootstrap/forms/Range";
import BSSelect from "components/elements/bootstrap/forms/BSSelect";
import Validation from "components/elements/bootstrap/forms/Validation";

// ** Boostrap components
import Accordions from "components/elements/bootstrap/Accordions";
import Alerts from "components/elements/bootstrap/Alerts";
import AvatarStyles from "components/elements/bootstrap/AvatarStyles";
import Badges from "components/elements/bootstrap/Badges";
import Breadcrumbs from "components/elements/bootstrap/Breadcrumbs";
import Buttons from "components/elements/bootstrap/Buttons";
import ButtonGroup from "components/elements/bootstrap/ButtonGroup";
import Cards from "components/elements/bootstrap/Cards";
import Carousels from "components/elements/bootstrap/Carousels";
import CloseButtons from "components/elements/bootstrap/CloseButtons";
import Collapses from "components/elements/bootstrap/Collapses";
import Dropdowns from "components/elements/bootstrap/Dropdowns";
import Listgroups from "components/elements/bootstrap/Listgroups";
import Navbars from "components/elements/bootstrap/Navbars";
import Navs from "components/elements/bootstrap/Navs";
import BSOffcanvas from "components/elements/bootstrap/BSOffcanvas";
import Overlays from "components/elements/bootstrap/Overlays";
import Paginations from "components/elements/bootstrap/Paginations";
import Popovers from "components/elements/bootstrap/Popovers";
import Progress from "components/elements/bootstrap/Progress";
import Spinners from "components/elements/bootstrap/Spinners";
import Modals from "components/elements/bootstrap/Modals";
import Tables from "components/elements/bootstrap/Tables";
import Toasts from "components/elements/bootstrap/Toasts";
import Tooltips from "components/elements/bootstrap/Tooltips";

// ** Import docs and change log pages
import Documentation from "components/dashboard/documentation/Documentation";
import ChangeLog from "components/dashboard/changelog/ChangeLog";

/* ----------------------------------- */
/* IMPORTS FOR MARKETING PAGES - START */
import DefaultLayout from "layouts/marketing/DefaultLayout";
import LayoutFooterLinks from "layouts/marketing/LayoutFooterLinks";
import BlankLayout from "layouts/marketing/BlankLayout";
import NotFound from "layouts/marketing/NotFound";

import CourseIndex from "components/marketing/pages/courses/course-index/CourseIndex";
import CourseCategory from "components/marketing/pages/courses/course-category/CourseCategory";

/* IMPORTS FOR FRONT SIDE PAGES MENU */

import CourseSingle2 from "components/marketing/pages/courses/course-single2/CourseSingle2";
import CourseResume from "components/marketing/pages/courses/course-resume/CourseResume";
import CourseCheckout from "components/marketing/pages/courses/course-checkout/CourseCheckout";
// import CourseFilterPage from 'components/marketing/pages/courses/course-filter-page/CourseFilterPage';
/* Internshipgate - Moved the course code from marketing to dashboard/courses */
//import AddNewCourse from 'components/marketing/pages/courses/add-new-course/AddNewCourse';
import AddNewCourse from "components/dashboard/courses/add-new-course/AddNewCourse";

import CoursePath from "components/marketing/pages/courses/course-path/CoursePath";
import CoursePathSingle from "components/marketing/pages/courses/course-path-single/CoursePathSingle";
import About from "components/marketing/pages/about/About";
import Pricing from "components/marketing/pages/pricing/Pricing";
import ComparePlan from "components/marketing/pages/compare-plan/ComparePlan"; // new v1.1.0
import Contact from "components/marketing/pages/contact/Contact"; // new v1.1.0

// IMPORTS FOR HELP CENTER PAGES ( v1.3.0 )
import HelpCenter from "components/marketing/pages/help-center/help-center/HelpCenter";
import HelpCenterFAQ from "components/marketing/pages/help-center/help-center-faq/HelpCenterFAQ";
import HelpCenterGuide from "components/marketing/pages/help-center/help-center-guide/HelpCenterGuide";
import HelpCenterGuideSingle from "components/marketing/pages/help-center/help-center-guide-single/HelpCenterGuideSingle";
import HelpCenterSupport from "components/marketing/pages/help-center/help-center-support/HelpCenterSupport";

/* IMPORTS FOR FRONT BLOG SUBMENU  ROUTERS */
//import BlogListing from 'components/marketing/blog/BlogListing';
import BlogListing from "components/blogs/BlogListing";
//import BlogArticleSingle from 'components/marketing/blog/BlogArticleSingle';
import BlogArticleSingle from "components/blogs/BlogArticleSingle";

import BlogCategory from "components/marketing/blog/BlogCategory";
import BlogSidebar from "components/marketing/blog/BlogSidebar";

/* IMPORTS FOR FRONT CAREER SUBMENU  ROUTERS */
import Career from "components/marketing/pages/career/career/Career"; // new v1.1.0
import CareerList from "components/marketing/pages/career/career-list/CareerList"; // new v1.1.0
import CareerSingle from "components/marketing/pages/career/career-single/CareerSingle"; // new v1.1.0

/* IMPORTS FOR FRONT SPECIALTY SUBMENU  ROUTERS */
import ComingSoon from "components/marketing/pages/specialty/ComingSoon";
import Error404 from "components/marketing/pages/specialty/Error404";
import MaintenanceMode from "components/marketing/pages/specialty/MaintenanceMode";
//import TermsAndConditions from 'components/marketing/pages/specialty/TermsAndConditions';

// Landing menu item pages
import LandingCourses from "components/marketing/landings/landing-courses/LandingCourses";
import CourseLead from "components/marketing/landings/course-lead/CourseLead";
import RequestAccess from "components/marketing/landings/request-access/RequestAccess";
import LandingSass from "components/marketing/landings/landing-sass/LandingSass"; // new v1.1.0
/* Internshipgate customization - start */
import HomeAcademy from "components/courses/HomeAcademy"; // new v2.0.0
import CourseSingle from "components/courses/course-single/CourseSingle";

import Payment from "components/buynow/Payment";
/* Internshipgate customization - end */
// import LandingJob from "components/marketing/landings/landing-job/LandingJob"; // new v2.0.0
import LandingJob from "components/Homepage/LandingJob";

// Job Listing new pages ( v2.0.0 )
/* Internshipgate customization - start */
//import JobsList from 'components/marketing/pages/jobs/listing/JobsList';
//import JobSingle from 'components/marketing/pages/jobs/listing/JobSingle';
import JobsList from "components/IntListing/JobsList";
import JobSingle from "components/IntListing/JobSingle";
/* Internshipgate customization - end */
import CompanyList from "components/marketing/pages/jobs/company-list/CompanyList";
import CompanyAbout from "components/marketing/pages/jobs/company/About";
import CompanyReviews from "components/marketing/pages/jobs/company/Reviews";
import CompanyJobs from "components/marketing/pages/jobs/company/Jobs";
import CompanyBenifits from "components/marketing/pages/jobs/company/Benifits";
import CompanyPhotos from "components/marketing/pages/jobs/company/Photos";
import PostAJob from "components/marketing/pages/jobs/post-a-job/PostAJob";
import UploadResume from "components/marketing/pages/jobs/upload-resume/UploadResume";

// Instructor Dashboard Pages
import InstructorDashboard from "components/marketing/instructor/Dashboard";
import InstructorMyCourses from "components/marketing/instructor/MyCourses";
import InstructorReviews from "components/marketing/instructor/Reviews";
import Earnings from "components/marketing/instructor/Earnings";
import InstructorOrders from "components/marketing/instructor/Orders";
import InstructorStudents from "components/marketing/instructor/Students";
import ViewProfile from "components/marketing/instructor/ViewProfile";
// Instructor New Dashboard Pages for Quiz ( v2.0.0)
import InstructorQuiz from "components/marketing/instructor/Quiz";
import InstructorQuizSingle from "components/marketing/instructor/QuizSingle";
import InstructorQuizResult from "components/marketing/instructor/QuizResult";

//import StudentDashboard from "components/marketing/student/Dashboard";
import DeleteProfile from "components/marketing/account-settings/DeleteProfile";
import EditProfile from "components/marketing/account-settings/EditProfile";
import LinkedAccounts from "components/marketing/account-settings/LinkedAccounts";
import AccountNotifications from "components/marketing/account-settings/Notifications";
import StudentPayment from "components/marketing/account-settings/Payment";
import ProfilePrivacy from "components/marketing/account-settings/ProfilePrivacy";
import Security from "components/marketing/account-settings/Security";
import SocialProfiles from "components/marketing/account-settings/SocialProfiles";
import Subscriptions from "components/marketing/account-settings/Subscriptions";
// Student New Dashboard Pages for Quiz ( v2.0.0)
import StudentQuiz from "components/marketing/student/Quiz";
import StudentQuizStart from "components/marketing/student/quiz-start/QuizStart";
import StudentQuizAttempt from "components/marketing/student/QuizAttempt";
import StudentQuizResult from "components/marketing/student/QuizResult";
// New Imports Start - Priyansh
import CourseFilterPage from "components/OnlineTraining/courses/CourseFilterPage";
import ViewCourse from "components/OnlineTraining/courses/ViewCourse";
import MentorsLanding from "components/Mentorship/MentorsLanding";
import MentorsFilterPage from "components/Mentorship/MentorsFilterPage";
// New Imports End - Priyansh

//Employer Dashboard Pages
import EmployerDashboard from "components/employer/Dashboard";
import EmployerApplications from "components/employer/applications/Applications";
import Recommended from "components/employer/applications/Recommended";
import EmployerEditProfile from "components/employer/EditProfile";
import EmployerProfile from "components/employer/Profile";
import PostNewInternship from "components/employer/PostNewInternship";
import ScheduleConfirmation from "components/employer/applications/ScheduleConfirmation";
import VIStudents from "components/employer/applications/VIStudents";
import EmpPricing from "components/employer/pricing/Pricing";

import CollegeDashboard from "components/college/dashboard";
import CollegeProfile from "components/college/Profile";
import CollegeEditProfile from "components/college/EditProfile";
import CollegeVerifyEmail from "components/college/VerifyEmail";

// \\sandip\Allwin\codescandy

// Account Settings
import BillingInfo from "components/marketing/account-settings/BillingInfo";
import Payouts from "components/marketing/account-settings/Payouts";
import Invoice from "components/marketing/account-settings/Invoice";
import InvoiceDetails from "components/marketing/account-settings/InvoiceDetails";

/* IMPORTS FOR MARKETING PAGES - END */
/* --------------------------------- */
import StudentDashboard from "components/student/Dashboard";
import StudentProfile from "components/student/Profile";
import EditStudentProfile from "components/student/EditProfile";

/* IMPORTS FOR GENERAL PAGE - START */
import AboutUs from "components/pages/AboutUs";
import TermsAndConditions from "components/pages/TermsAndConditions";
import Disclaimer from "components/pages/Disclaimer";
import PrivacyPolicy from "components/pages/PrivacyPolicy";
import ContactUs from "components/pages/ContactUs";
/* IMPORTS FOR CMS REPORTS - START */
import OrderPayment from "../components/dashboard/reports/OrderPayment";
import EmployerInternshipReport from "components/dashboard/reports/EmployerInternship";
/* IMPORTS FOR CMS REPORTS - END */

/* IMPORTS FOR STUDY ABROAD - START */
import StudyAbroad from "components/StudyAbroad/StudyAbroad";
/* IMPORTS FOR STUDY ABROAD - END */

import EmailTemplate from "components/dashboard/emailTemplates/EmailTemplate";
import AllTemplates from "components/dashboard/emailTemplates/AllTemplates";
import EmailImages from "components/dashboard/emailTemplates/EmailImages";
import StudentRegistration from "components/authentication/StudentRegistration";
import EmployerRegistration from "components/authentication/EmployerRegistration";
import CollegeRegistration from "components/authentication/CollegeRegistration";
import Empreglist from "components/dashboard/registration/Empreglist";
import Stureglist from "components/dashboard/registration/Stureglist";
import CMScollegeRegistration from "components/dashboard/registration/CollegeRegistration";
import AddOnlineTraining from "components/dashboard/online-training/AddOnlineTraining";
import Makecertificate from "components/dashboard/certificate/MakeCertificate";
//import Certificateshow from 'components/dashboard/certificate/CertificateShow';
import Certificateshow from "components/dashboard/certificate/BasicDocument";
import BasicDocument from "components/dashboard/certificate/BasicDocument"; //test page can be removed
import CapCoupons from "components/dashboard/discounts/Coupons";
import Discount from "components/dashboard/discounts/Discount";
import CoursePricing from "components/dashboard/courses/CoursePricing";

import ResumeBuilderLanding from "components/ResumeBuilder/LandingPage";
import getResumeBuilderRoutes from "components/ResumeBuilder/v2/routes";
import ResumeList from "components/ResumeBuilder/v2/ResumeList";
import GoogleAuthenticator from "components/authentication/GoogleAuthenticator";
import ResumeBuilderPricing from "components/ResumeBuilder/v2/pricing/ResumeBuilderPricing";

import StudyAbroadRegistrations from "components/dashboard/studyabroad/StudyAbroadRegistrations";
import Unsubscribe from "components/pages/Unsubscribe";

/* LMS Imports - START */
import LMSDashboard from "components/lms/LMSDashboard";
import LMSVideo from "components/lms/video/Video";
import LMSProject from "components/lms/project/ProjectOverview";
import ProjectApproval from "components/dashboard/lms/ProjectApproval";
import DashboardLmsAllCourses from "components/dashboard/lms/AllCourses/AllCourses";
import CourseContent from "components/dashboard/lms/CourseContent";
// import LMSQuizLanding from 'components/lms/quiz/QuizLanding';
// import LMSQuizQuestion from 'components/lms/quiz/QuizQuestion';
import LMSStudentTest from "components/lms/quiz/Quiz";
import LMSStudentTestStart from "components/lms/quiz/quiz-start/QuizStart";
import LMSQuizAttempt from "components/lms/quiz/QuizAttempt";
import LMSQuizResult from "components/lms/quiz/QuizResult";
import LMSOverview from "components/lms/Overview";

/* LMS Imports - END */
import PageSEOSettings from "components/dashboard/seo/PageSEOSettings";
import Mentorship from 'components/Mentorship/Mentorship';
import MentorshipDetails from 'components/Mentorship/MentorshipDetails';
import MentorLogin from "components/authentication/MentorLogin";
import MentorRegistration from "components/authentication/MentorRegistration";
import MentorDashboard from "components/Mentorship/MentorDashboard/MentorDashboard";
import ProfileLayout from "components/Mentorship/MentorDashboard/ProfileLayout";
import MentorBasicDetails from "components/Mentorship/MentorDashboard/MentorBasicDetails";

import MentorEducations from "components/Mentorship/MentorDashboard/MentorEducations";
import MentorServices from "components/Mentorship/MentorDashboard/MentorServices";
import MentorWorkExperiences from "components/Mentorship/MentorDashboard/MentorWorkExperiences";
import MentorTopics from "components/Mentorship/MentorDashboard/MentorTopics";
import MentorEarnings from "components/Mentorship/MentorDashboard/MentorEarnings";
import MentorSlots from "components/Mentorship/MentorDashboard/MentorSlots";
import MentorVerifyEmail from "components/Mentorship/VerifyEmail";

import VICourseProject from "components/GenAI/VICourseProject";
import ProjectSelection from "components/lms/ProjectSelection";
import InternshipPostApproval from "components/dashboard/intListing/InternshipPostApproval";
import BannerImageUpload from "components/dashboard/cms/BannerImageUpload";

/* IMPORTS FOR GENERAL PAGE - END */
const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <Component {...props}></Component>
      </Layout>
    )}
  ></Route>
);

/** MAIL ROUTE FUNCTION WITH MAIL PROVIDER ( v1.3.0 )  */
const MailRoutes = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <MailProvider>
          <Component {...props}></Component>
        </MailProvider>
      </Layout>
    )}
  ></Route>
);

/** TASK KANBAN ROUTE FUNCTION WITH TASK KANBAN PROVIDER ( v1.4.0 )  */
const TaskKanbanRoutes = ({
  component: Component,
  layout: Layout,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <TaskKanbanProvider>
          <Component {...props}></Component>
        </TaskKanbanProvider>
      </Layout>
    )}
  ></Route>
);

/** CHAT ROUTE FUNCTION WITH CHAT PROVIDER ( v1.4.0 )  */
const ChatRoutes = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout className="px-0" overflowHidden>
        <ChatProvider>
          <Component {...props}></Component>
        </ChatProvider>
      </Layout>
    )}
  ></Route>
);

function AllRoutes() {
  return (
    <Switch>
      {/* --------------------------- */}
      {/* LANDING PAGES ROUTERS - START */}
      {/* <AppRoute exact path="/" layout={DefaultLayout} component={CourseIndex} />*/}
      {/* Internshipgate routes start */}

      {/** change the base URL for virtual.internshipgate.com */}
      {/**
			<AppRoute
				exact
				path="/"
				layout={DefaultLayout}
				component={BlogListing}
			/>
 			*/}
      {/** New Home page - start */}
      {/* <AppRoute exact path="/" layout={DefaultLayout} component={HomeAcademy} /> */}
      <AppRoute exact path="/" layout={DefaultLayout} component={LandingJob} />
      {/* <AppRoute exact path="/" layout={DefaultLayout} component={BlogListing} /> */}

      {/** New Home page - End */}

      <AppRoute
        exact
        path="/test-vi-projects"
        layout={DefaultLayout}
        component={VICourseProject}
      />

      {/* New Routes Start - Rohan */}
      <AppRoute
        exact
        path="/dashboard/registration/reg-employees"
        layout={DashboardIndex}
        component={Empreglist}
      />

      <AppRoute
        exact
        path="/dashboard/registration/reg-students"
        layout={DashboardIndex}
        component={Stureglist}
      />
      <AppRoute
        exact
        path="/dashboard/registration/reg-colleges"
        layout={DashboardIndex}
        component={CMScollegeRegistration}
      />
      <AppRoute
        exact
        path="/dashboard/registration/reg-mentors"
        layout={DashboardIndex}
        component={MentorRegList}
      />      
      <AppRoute
        exact
        path="/dashboard/online_training/addonlinetraining"
        layout={DashboardIndex}
        component={AddOnlineTraining}
      />
      <AppRoute
        exact
        path="/dashboard/vi_certificate/makecertificate"
        layout={DashboardIndex}
        component={Makecertificate}
      />

      <AppRoute
        exact
        path="/Certificateshow/:id/:studentTestId?"
        layout={DefaultLayout}
        component={Certificateshow}
      />
      <AppRoute
        exact
        path="/BasicDocument"
        layout={DefaultLayout}
        component={BasicDocument}
      />
      <AppRoute
        exact
        path="/dashboard/discounts/capcoupons"
        layout={DashboardIndex}
        component={CapCoupons}
      />
      <AppRoute
        exact
        path="/dashboard/discounts/capdiscount"
        layout={DashboardIndex}
        component={Discount}
      />

      <AppRoute
        exact
        path="/dashboard/projectapprovals"
        layout={DashboardIndex}
        component={ProjectApproval}
      />

      <AppRoute
        exact
        path="/dashboard/course-cms"
        layout={DashboardIndex}
        component={DashboardLmsAllCourses}
      />

      <AppRoute
        exact
        path="/dashboard/course-cms/:courseId"
        layout={DashboardIndex}
        component={CourseContent}
      />
  
      <AppRoute
        exact
        path="/dashboard/mentorship/booking-list"
        layout={DashboardIndex}
        component={MentorshipBookingList}
      />

      <AppRoute
        exact
        path="/courses/course-pricing"
        layout={DashboardIndex}
        component={CoursePricing}
      />
      {/* New Routes Start - Priyansh */}
      <AppRoute
        exact
        path="/OnlineTraining"
        layout={DefaultLayout}
        component={CourseFilterPage}
      />
      <AppRoute
        exact
        path="/OnlineTrainings/:id"
        layout={DefaultLayout}
        component={ViewCourse}
      />
      <AppRoute
        exact
        path="/mentorship"
        layout={DefaultLayout}
        component={MentorsLanding}
      />
      <AppRoute
        exact
        path="/mentor/login"
        layout={DefaultLayout}
        component={MentorLogin}
      />      
      <AppRoute
        exact
        path="/mentor/register"
        layout={DefaultLayout}
        component={MentorRegistration}
      />            
      <AppRoute
        exact
        path="/mentor/verify/:emailHash"
        layout={DefaultLayout}
        component={MentorVerifyEmail}
      />      
      {/* <AppRoute
        exact
        path="/mentor/dashboard"
        layout={DefaultLayout}
        component={ProfileLayout}
      />                */}
      <AppRoute
        exact
        path="/mentor/dashboard/"
        layout={DefaultLayout}
        component={MentorDashboard}
      />                
      <AppRoute
        exact
        path="/mentor/profile/"
        layout={DefaultLayout}
        component={MentorBasicDetails}
      />                
      <AppRoute
        exact
        path="/mentor/education/"
        layout={DefaultLayout}
        component={MentorEducations}
      />                
      <AppRoute
        exact
        path="/mentor/work-experience"
        layout={DefaultLayout}
        component={MentorWorkExperiences}
      />                
      <AppRoute
        exact
        path="/mentor/topics/"
        layout={DefaultLayout}
        component={MentorTopics}
      />           
      <AppRoute
        exact
        path="/mentor/services/"
        layout={DefaultLayout}
        component={MentorServices}
      />                         
      <AppRoute
        exact
        path="/mentor/instructor-earnings/"
        layout={DefaultLayout}
        component={MentorEarnings}
      />       
      <AppRoute
        exact
        path="/mentor/slots/"
        layout={DefaultLayout}
        component={MentorSlots}
      />           
      <AppRoute
        exact
        path="/allMentors"
        layout={DefaultLayout}
        component={MentorsFilterPage}
      />
      {/* New Routes End - Priyansh */}
      <AppRoute
        exact
        path="/college-login"
        layout={DefaultLayout}
        component={CollegeLogin}
      />

      <AppRoute
        exact
        path="/student-login"
        layout={DefaultLayout}
        component={StudentLogin}
      />
      <AppRoute
        exact
        path="/student/registration"
        layout={DefaultLayout}
        component={StudentRegistration}
      />
      <AppRoute
        exact
        path="/college/registration"
        layout={DefaultLayout}
        component={CollegeRegistration}
      />
      <AppRoute
        exact
        path="/employer/registration"
        layout={DefaultLayout}
        component={EmployerRegistration}
      />

			<AppRoute
				exact
				path="/mentorship"
				layout={DefaultLayout}
				component={Mentorship}
			/>
			<AppRoute
				exact
				path="/mentorship-details/:id"
				layout={DefaultLayout}
				component={MentorshipDetails}
			/>			
			{/*Employer Dashboard */}
			<AppRoute
				exact
				path="/empdashboard"
				layout={DefaultLayout}
				component={EmployerDashboard}
			/>
			<AppRoute
				exact
				path="/employer-pricing"
				layout={DefaultLayout}
				component={EmpPricing}
			/>
      <AppRoute
        exact
        path="/coldashboard"
        layout={DefaultLayout}
        component={CollegeDashboard}
      />
      <AppRoute
        exact
        path="/empdashboard/applications/recommended"
        layout={DefaultLayout}
        component={Recommended}
      />
      <AppRoute
        exact
        path="/empdashboard/applications/internshipgate-certified-interns"
        layout={DefaultLayout}
        component={VIStudents}
      />
      <AppRoute
        exact
        path="/empdashboard/applications/:appstatus"
        layout={DefaultLayout}
        component={EmployerApplications}
      />

      <AppRoute
        exact
        path="/empdashboard/profile"
        layout={DefaultLayout}
        component={EmployerProfile}
      />
      <AppRoute
        exact
        path="/coldashboard/profile"
        layout={DefaultLayout}
        component={CollegeProfile}
      />
      <AppRoute
        exact
        path="/empdashboard/edit-profile"
        layout={DefaultLayout}
        component={EmployerEditProfile}
      />

      <AppRoute
        exact
        path="/coldashboard/edit-profile"
        layout={DefaultLayout}
        component={CollegeEditProfile}
      />
      <AppRoute
        exact
        path="/college/verify/:emailHash"
        layout={DefaultLayout}
        component={CollegeVerifyEmail}
      />
      <AppRoute
        exact
        path="/empdashboard/post-internship"
        layout={DefaultLayout}
        component={PostNewInternship}
      />
      <AppRoute
        exact
        path="/empdashboard/post-internship/:intID"
        layout={DefaultLayout}
        component={PostNewInternship}
      />
      <AppRoute
        exact
        path="/confirminterview/:confirmationStatus/:encryptedInterviewId"
        layout={DefaultLayout}
        component={ScheduleConfirmation}
      />
      {/* INTERNSHIPGATE GENERAL PAGES - START */}
      <AppRoute
        exact
        path="/ContactUs"
        layout={DefaultLayout}
        component={ContactUs}
      />
      <AppRoute
        exact
        path="/AboutUs"
        layout={DefaultLayout}
        component={AboutUs}
      />

      <AppRoute exact path="/Sites" layout={DefaultLayout} component={Sites} />
      <AppRoute
        exact
        path="/Terms"
        layout={DefaultLayout}
        component={TermsAndConditions}
      />
      <AppRoute
        exact
        path="/Disclaimer"
        layout={DefaultLayout}
        component={Disclaimer}
      />
      <AppRoute
        exact
        path="/Privacy"
        layout={DefaultLayout}
        component={PrivacyPolicy}
      />
      {/** INTERNSHIPGATE GENERAL PAGES - START */}

      {/** INTERNSHIPGATE - CMS REPORTS PAGES - START */}
      <AppRoute
        exact
        path="/reports/order-payments"
        layout={DashboardIndex}
        component={OrderPayment}
      />
      <AppRoute
        exact
        path="/reports/employer-internships"
        layout={DashboardIndex}
        component={EmployerInternshipReport}
      />
      <AppRoute
        exact
        path="/reports/study-abroad-registrations"
        layout={DashboardIndex}
        component={StudyAbroadRegistrations}
      />
      {/** INTERNSHIPGATE - CMS REPORTS PAGES - END */}

      {/* FRONT SIDE LANDINGS MENU ROUTERS */}
      <AppRoute
        exact
        path="/marketing/landings/landing-courses/"
        layout={BlankLayout}
        component={LandingCourses}
      />
      <AppRoute
        exact
        path="/marketing/landings/course-lead/"
        layout={BlankLayout}
        component={CourseLead}
      />
      <AppRoute
        exact
        path="/marketing/landings/request-access/"
        layout={BlankLayout}
        component={RequestAccess}
      />
      <AppRoute
        exact
        path="/marketing/landings/landing-sass/"
        layout={BlankLayout}
        component={LandingSass}
      />
      {/* ADDED HOME ACADEMY AND JOB LANDING PAGES WITH v2.0.0 */}
      <AppRoute
        exact
        path="/marketing/landings/home-academy/"
        layout={BlankLayout}
        component={HomeAcademy}
      />
      <AppRoute
        exact
        path="/blogs"
        layout={DefaultLayout}
        component={BlogListing}
      />
      <AppRoute
        exact
        path="/blog/:generatedURL"
        layout={DefaultLayout}
        component={BlogArticleSingle}
      />
      <AppRoute
        path="/virtual-internship/:generatedURL"
        layout={DefaultLayout}
        component={CourseSingle}
      />
      <AppRoute
        exact
        path="/virtual-internship"
        layout={DefaultLayout}
        component={HomeAcademy}
      />
      <AppRoute
        exact
        path="/virtual-internship/:generatedURL"
        layout={DefaultLayout}
        component={CourseSingle}
      />
      <AppRoute
        exact
        path="/internship/:data"
        layout={DefaultLayout}
        component={JobsList}
      />
      <AppRoute
        exact
        path="/Intlisting"
        layout={DefaultLayout}
        component={JobsList}
      />
      <AppRoute
        exact
        path="/internship-details/:slug"
        layout={DefaultLayout}
        component={(props) => (
          <JobSingle key={window.location.href} {...props} />
        )}
        // key={window.location.href}
      />
      {/*Internshipgate Payment Gateway */}
      <AppRoute
        exact
        path="/payment"
        layout={DefaultLayout}
        component={Payment}
      />

      <AppRoute
        exact
        path="/marketing/course-category/"
        layout={DefaultLayout}
        component={CourseCategory}
      />

      <AppRoute
        exact
        path="/ResumeBuilder"
        layout={DefaultLayout}
        component={ResumeBuilderLanding}
      />
      <AppRoute
        exact
        path="/ResumeBuilder/Pricing"
        layout={DefaultLayout}
        component={ResumeBuilderPricing}
      />

      <AppRoute
        exact
        path="/resumes"
        layout={DefaultLayout}
        component={ResumeList}
      />
      <AppRoute
        exact
        path="/study-abroad"
        layout={DefaultLayout}
        component={StudyAbroad}
      />

      {/* <AppRoute
			exact 
			path="/unsubscribe/:ut/:emailHash"
			layout={DefaultLayout}
			component={Unsubscribe}
		/> */}
      <AppRoute
        exact
        path="/unsubscribe"
        layout={DefaultLayout}
        component={Unsubscribe}
      />

      {/* Internshipgate routes end */}
      <AppRoute
        exact
        path="/Homepage"
        layout={DefaultLayout}
        component={LandingJob}
      />

      <AppRoute
        exact
        path="/marketing/landings/landing-job/"
        layout={JobListingLayout}
        component={LandingJob}
      />

      {/* ADDED JOB PAGES WITH v2.0.0 */}
      <AppRoute
        exact
        path="/marketing/jobs/listing/job-list/"
        layout={JobListingLayout}
        component={JobsList}
      />
      <AppRoute
        exact
        path="/marketing/jobs/listing/:slug"
        layout={JobListingLayout}
        component={JobSingle}
      />
      <AppRoute
        exact
        path="/marketing/jobs/company-list/"
        layout={JobListingLayout}
        component={CompanyList}
      />
      <AppRoute
        exact
        path="/marketing/jobs/company/about/"
        layout={JobListingLayout}
        component={CompanyAbout}
      />
      <AppRoute
        exact
        path="/marketing/jobs/company/reviews/"
        layout={JobListingLayout}
        component={CompanyReviews}
      />
      <AppRoute
        exact
        path="/marketing/jobs/company/jobs/"
        layout={JobListingLayout}
        component={CompanyJobs}
      />
      <AppRoute
        exact
        path="/marketing/jobs/company/benifits/"
        layout={JobListingLayout}
        component={CompanyBenifits}
      />
      <AppRoute
        exact
        path="/marketing/jobs/company/photos/"
        layout={JobListingLayout}
        component={CompanyPhotos}
      />
      <AppRoute
        exact
        path="/marketing/jobs/post-a-job/"
        layout={JobListingLayout}
        component={PostAJob}
      />
      <AppRoute
        exact
        path="/marketing/jobs/upload-resume/"
        layout={JobListingLayout}
        component={UploadResume}
      />
      {/* FRONT SIDE PAGES MENU ROUTERS */}
      <AppRoute
        exact
        path="/marketing/courses/course-single2/"
        layout={DefaultLayout}
        component={CourseSingle2}
      />
      <AppRoute
        exact
        path="/marketing/courses/course-resume/"
        layout={BlankLayout}
        component={CourseResume}
      />
      <AppRoute
        exact
        path="/marketing/courses/course-checkout/"
        layout={DefaultLayout}
        component={CourseCheckout}
      />
      <AppRoute
        exact
        path="/marketing/courses/course-filter-page/"
        layout={DefaultLayout}
        component={CourseFilterPage}
      />
      <AppRoute
        exact
        path="/marketing/instructor/add-new-course/:id"
        layout={DefaultLayout}
        component={AddNewCourse}
      />
      <AppRoute
        exact
        path="/marketing/courses/course-path/"
        layout={DefaultLayout}
        component={CoursePath}
      />
      <AppRoute
        exact
        path="/marketing/courses/course-path-single/"
        layout={DefaultLayout}
        component={CoursePathSingle}
      />
      <AppRoute
        exact
        path="/marketing/pages/about/"
        layout={BlankLayout}
        component={About}
      />

      <AppRoute
        exact
        path="/marketing/pages/pricing/"
        layout={DefaultLayout}
        component={Pricing}
      />
      <AppRoute
        exact
        path="/marketing/pages/compare-plan/"
        layout={LayoutFooterLinks}
        component={ComparePlan}
      />
      <AppRoute
        exact
        path="/marketing/pages/contact/"
        layout={BlankLayout}
        component={Contact}
      />
      {/* FRONT SIDE BLOG SUBMENU ROUTERS */}
      <AppRoute
        exact
        path="/marketing/blog/article-single/:id"
        layout={DefaultLayout}
        component={BlogArticleSingle}
      />

      <AppRoute
        exact
        path="/marketing/blog/category/"
        layout={DefaultLayout}
        component={BlogCategory}
      />
      <AppRoute
        exact
        path="/marketing/blog/listing/"
        layout={DefaultLayout}
        component={BlogListing}
      />
      <AppRoute
        exact
        path="/marketing/blog/sidebar/"
        layout={BlankLayout}
        component={BlogSidebar}
      />

      {/* FRONT SIDE CAREER SUBMENU ROUTERS */}
      <AppRoute
        exact
        path="/marketing/pages/career/"
        layout={LayoutFooterLinks}
        component={Career}
      />
      <AppRoute
        exact
        path="/marketing/pages/career-list/"
        layout={LayoutFooterLinks}
        component={CareerList}
      />
      <AppRoute
        exact
        path="/marketing/pages/career-single/"
        layout={LayoutFooterLinks}
        component={CareerSingle}
      />

      {/* FRONT SIDE BLOG SPECIALTY ROUTERS */}
      <AppRoute
        exact
        path="/marketing/specialty/coming-soon/"
        layout={NotFound}
        component={ComingSoon}
      />
      <AppRoute
        exact
        path="/marketing/specialty/404-error/"
        layout={NotFound}
        component={Error404}
      />
      <AppRoute
        exact
        path="/marketing/specialty/maintenance-mode/"
        layout={NotFound}
        component={MaintenanceMode}
      />
      <AppRoute
        exact
        path="/marketing/specialty/terms-and-conditions/"
        layout={BlankLayout}
        component={TermsAndConditions}
      />

      {/* FRONT SIDE INSTRUCTOR DASHBOARD ROUTERS */}
      <AppRoute
        exact
        path="/marketing/instructor/dashboard/"
        layout={DefaultLayout}
        component={InstructorDashboard}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-my-courses/"
        layout={DefaultLayout}
        component={InstructorMyCourses}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-reviews/"
        layout={DefaultLayout}
        component={InstructorReviews}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-earnings/"
        layout={DefaultLayout}
        component={Earnings}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-orders/"
        layout={DefaultLayout}
        component={InstructorOrders}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-students/"
        layout={DefaultLayout}
        component={InstructorStudents}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-payouts/"
        layout={DefaultLayout}
        component={Payouts}
      />

      {/* FRONT SIDE INSTRUCTOR DASHBOARD-ACCOUNT SETTINGS ROUTERS */}

      <AppRoute
        exact
        path="/marketing/instructor/instructor-profile/"
        layout={DefaultLayout}
        component={ViewProfile}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-security/"
        layout={DefaultLayout}
        component={Security}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-social-profiles/"
        layout={DefaultLayout}
        component={SocialProfiles}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-notifications/"
        layout={DefaultLayout}
        component={AccountNotifications}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-profile-privacy/"
        layout={DefaultLayout}
        component={ProfilePrivacy}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-delete-profile/"
        layout={DefaultLayout}
        component={DeleteProfile}
      />
      {/* FRONT SIDE INSTRUCTOR DASHBOARD QUIZ PAGES - v2.0.0 */}
      <AppRoute
        exact
        path="/marketing/instructor/quiz/"
        layout={DefaultLayout}
        component={InstructorQuiz}
      />
      <AppRoute
        exact
        path="/marketing/instructor/quiz/single/"
        layout={DefaultLayout}
        component={InstructorQuizSingle}
      />
      <AppRoute
        exact
        path="/marketing/instructor/quiz/result/"
        layout={DefaultLayout}
        component={InstructorQuizResult}
      />

      {/* FRONT SIDE STUDENTS DASHBOARD ROUTERS */}
      <AppRoute
        exact
        path="/student-dashboard"
        layout={DefaultLayout}
        component={StudentDashboard}
      />
      <AppRoute
        exact
        path="/student-profile"
        layout={DefaultLayout}
        component={StudentProfile}
      />
      <AppRoute
        exact
        path="/student-dashboard/edit-profile"
        layout={DefaultLayout}
        component={EditStudentProfile}
      />
      {/* Internshipgate routes end */}

      {/* FRONT SIDE STUDENTS DASHBOARD-ACCOUNT SETTINGS ROUTERS */}

      <AppRoute
        exact
        path="/marketing/student/student-security/"
        layout={DefaultLayout}
        component={Security}
      />
      <AppRoute
        exact
        path="/marketing/student/student-social-profiles/"
        layout={DefaultLayout}
        component={SocialProfiles}
      />
      <AppRoute
        exact
        path="/marketing/student/student-notifications/"
        layout={DefaultLayout}
        component={AccountNotifications}
      />
      <AppRoute
        exact
        path="/marketing/student/student-profile-privacy/"
        layout={DefaultLayout}
        component={ProfilePrivacy}
      />
      <AppRoute
        exact
        path="/marketing/student/student-delete-profile/"
        layout={DefaultLayout}
        component={DeleteProfile}
      />
      <AppRoute
        exact
        path="/marketing/student/student-linked-accounts/"
        layout={DefaultLayout}
        component={LinkedAccounts}
      />
      {/* FRONT SIDE LANDINGS MENU ROUTERS */}
      <AppRoute
        exact
        path="/marketing/landings/landing-courses/"
        layout={BlankLayout}
        component={LandingCourses}
      />
      <AppRoute
        exact
        path="/marketing/landings/course-lead/"
        layout={BlankLayout}
        component={CourseLead}
      />
      <AppRoute
        exact
        path="/marketing/landings/request-access/"
        layout={BlankLayout}
        component={RequestAccess}
      />
      <AppRoute
        exact
        path="/marketing/landings/landing-sass/"
        layout={BlankLayout}
        component={LandingSass}
      />
      {/* ADDED HOME ACADEMY AND JOB LANDING PAGES WITH v2.0.0 */}
      <AppRoute
        exact
        path="/marketing/landings/home-academy/"
        layout={BlankLayout}
        component={HomeAcademy}
      />

      <AppRoute
        exact
        path="/Homepage"
        layout={DefaultLayout}
        component={LandingJob}
      />

      <AppRoute
        exact
        path="/marketing/landings/landing-job/"
        layout={JobListingLayout}
        component={LandingJob}
      />

      {/* ADDED JOB PAGES WITH v2.0.0 */}
      <AppRoute
        exact
        path="/marketing/jobs/listing/job-list/"
        layout={JobListingLayout}
        component={JobsList}
      />
      <AppRoute
        exact
        path="/marketing/jobs/listing/:slug"
        layout={JobListingLayout}
        component={JobSingle}
      />
      <AppRoute
        exact
        path="/marketing/jobs/company-list/"
        layout={JobListingLayout}
        component={CompanyList}
      />
      <AppRoute
        exact
        path="/marketing/jobs/company/about/"
        layout={JobListingLayout}
        component={CompanyAbout}
      />
      <AppRoute
        exact
        path="/marketing/jobs/company/reviews/"
        layout={JobListingLayout}
        component={CompanyReviews}
      />
      <AppRoute
        exact
        path="/marketing/jobs/company/jobs/"
        layout={JobListingLayout}
        component={CompanyJobs}
      />
      <AppRoute
        exact
        path="/marketing/jobs/company/benifits/"
        layout={JobListingLayout}
        component={CompanyBenifits}
      />
      <AppRoute
        exact
        path="/marketing/jobs/company/photos/"
        layout={JobListingLayout}
        component={CompanyPhotos}
      />
      <AppRoute
        exact
        path="/marketing/jobs/post-a-job/"
        layout={JobListingLayout}
        component={PostAJob}
      />
      <AppRoute
        exact
        path="/marketing/jobs/upload-resume/"
        layout={JobListingLayout}
        component={UploadResume}
      />
      {/* FRONT SIDE PAGES MENU ROUTERS */}

      <AppRoute
        exact
        path="/marketing/courses/course-single2/"
        layout={DefaultLayout}
        component={CourseSingle2}
      />
      <AppRoute
        exact
        path="/marketing/courses/course-resume/"
        layout={BlankLayout}
        component={CourseResume}
      />
      <AppRoute
        exact
        path="/marketing/courses/course-checkout/"
        layout={DefaultLayout}
        component={CourseCheckout}
      />
      {/* <AppRoute
				exact
				path="/marketing/courses/course-filter-page/"
				layout={DefaultLayout}
				component={CourseFilterPage}
			/> */}
      <AppRoute
        exact
        path="/marketing/instructor/add-new-course/:id"
        layout={DefaultLayout}
        component={AddNewCourse}
      />
      <AppRoute
        exact
        path="/marketing/courses/course-path/"
        layout={DefaultLayout}
        component={CoursePath}
      />
      <AppRoute
        exact
        path="/marketing/courses/course-path-single/"
        layout={DefaultLayout}
        component={CoursePathSingle}
      />
      <AppRoute
        exact
        path="/marketing/pages/about/"
        layout={BlankLayout}
        component={About}
      />

      <AppRoute
        exact
        path="/marketing/pages/pricing/"
        layout={DefaultLayout}
        component={Pricing}
      />
      <AppRoute
        exact
        path="/marketing/pages/compare-plan/"
        layout={LayoutFooterLinks}
        component={ComparePlan}
      />
      <AppRoute
        exact
        path="/marketing/pages/contact/"
        layout={BlankLayout}
        component={Contact}
      />
      {/* FRONT SIDE BLOG SUBMENU ROUTERS */}
      <AppRoute
        exact
        path="/marketing/blog/article-single/:id"
        layout={DefaultLayout}
        component={BlogArticleSingle}
      />

      <AppRoute
        exact
        path="/marketing/blog/category/"
        layout={DefaultLayout}
        component={BlogCategory}
      />
      <AppRoute
        exact
        path="/marketing/blog/listing/"
        layout={DefaultLayout}
        component={BlogListing}
      />
      <AppRoute
        exact
        path="/marketing/blog/sidebar/"
        layout={BlankLayout}
        component={BlogSidebar}
      />

      {/* FRONT SIDE CAREER SUBMENU ROUTERS */}
      <AppRoute
        exact
        path="/marketing/pages/career/"
        layout={LayoutFooterLinks}
        component={Career}
      />
      <AppRoute
        exact
        path="/marketing/pages/career-list/"
        layout={LayoutFooterLinks}
        component={CareerList}
      />
      <AppRoute
        exact
        path="/marketing/pages/career-single/"
        layout={LayoutFooterLinks}
        component={CareerSingle}
      />

      {/* FRONT SIDE BLOG SPECIALTY ROUTERS */}
      <AppRoute
        exact
        path="/marketing/specialty/coming-soon/"
        layout={NotFound}
        component={ComingSoon}
      />
      <AppRoute
        exact
        path="/marketing/specialty/404-error/"
        layout={NotFound}
        component={Error404}
      />
      <AppRoute
        exact
        path="/marketing/specialty/maintenance-mode/"
        layout={NotFound}
        component={MaintenanceMode}
      />
      <AppRoute
        exact
        path="/marketing/specialty/terms-and-conditions/"
        layout={BlankLayout}
        component={TermsAndConditions}
      />

      {/* FRONT SIDE INSTRUCTOR DASHBOARD ROUTERS */}
      <AppRoute
        exact
        path="/marketing/instructor/dashboard/"
        layout={DefaultLayout}
        component={InstructorDashboard}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-my-courses/"
        layout={DefaultLayout}
        component={InstructorMyCourses}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-reviews/"
        layout={DefaultLayout}
        component={InstructorReviews}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-earnings/"
        layout={DefaultLayout}
        component={Earnings}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-orders/"
        layout={DefaultLayout}
        component={InstructorOrders}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-students/"
        layout={DefaultLayout}
        component={InstructorStudents}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-payouts/"
        layout={DefaultLayout}
        component={Payouts}
      />

      {/* FRONT SIDE INSTRUCTOR DASHBOARD-ACCOUNT SETTINGS ROUTERS */}
      <AppRoute
        exact
        path="/marketing/instructor/instructor-edit-profile/"
        layout={DefaultLayout}
        component={EditProfile}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-profile/"
        layout={DefaultLayout}
        component={ViewProfile}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-security/"
        layout={DefaultLayout}
        component={Security}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-social-profiles/"
        layout={DefaultLayout}
        component={SocialProfiles}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-notifications/"
        layout={DefaultLayout}
        component={AccountNotifications}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-profile-privacy/"
        layout={DefaultLayout}
        component={ProfilePrivacy}
      />
      <AppRoute
        exact
        path="/marketing/instructor/instructor-delete-profile/"
        layout={DefaultLayout}
        component={DeleteProfile}
      />
      {/* FRONT SIDE INSTRUCTOR DASHBOARD QUIZ PAGES - v2.0.0 */}
      <AppRoute
        exact
        path="/marketing/instructor/quiz/"
        layout={DefaultLayout}
        component={InstructorQuiz}
      />
      <AppRoute
        exact
        path="/marketing/instructor/quiz/single/"
        layout={DefaultLayout}
        component={InstructorQuizSingle}
      />
      <AppRoute
        exact
        path="/marketing/instructor/quiz/result/"
        layout={DefaultLayout}
        component={InstructorQuizResult}
      />

      {/* FRONT SIDE STUDENTS DASHBOARD ROUTERS */}
      {/* <AppRoute
				exact
				path="/marketing/student/dashboard/"
				layout={DefaultLayout}
				component={StudentDashboard}
			/> */}
      <AppRoute
        exact
        path="/marketing/student/student-subscriptions/"
        layout={DefaultLayout}
        component={Subscriptions}
      />
      <AppRoute
        exact
        path="/marketing/student/student-billing-info/"
        layout={DefaultLayout}
        component={BillingInfo}
      />
      <AppRoute
        exact
        path="/marketing/student/student-payment/"
        layout={DefaultLayout}
        component={StudentPayment}
      />
      <AppRoute
        exact
        path="/marketing/student/student-invoice/"
        layout={DefaultLayout}
        component={Invoice}
      />
      <AppRoute
        exact
        path="/marketing/student/student-invoice-details/"
        layout={DefaultLayout}
        component={InvoiceDetails}
      />

      {/* FRONT SIDE STUDENTS DASHBOARD-ACCOUNT SETTINGS ROUTERS */}
      <AppRoute
        exact
        path="/marketing/student/student-edit-profile/"
        layout={DefaultLayout}
        component={EditProfile}
      />
      <AppRoute
        exact
        path="/marketing/student/student-security/"
        layout={DefaultLayout}
        component={Security}
      />
      <AppRoute
        exact
        path="/marketing/student/student-social-profiles/"
        layout={DefaultLayout}
        component={SocialProfiles}
      />
      <AppRoute
        exact
        path="/marketing/student/student-notifications/"
        layout={DefaultLayout}
        component={AccountNotifications}
      />
      <AppRoute
        exact
        path="/marketing/student/student-profile-privacy/"
        layout={DefaultLayout}
        component={ProfilePrivacy}
      />
      <AppRoute
        exact
        path="/marketing/student/student-delete-profile/"
        layout={DefaultLayout}
        component={DeleteProfile}
      />
      <AppRoute
        exact
        path="/marketing/student/student-linked-accounts/"
        layout={DefaultLayout}
        component={LinkedAccounts}
      />

      {/* FRONT SIDE STUDENT DASHBOARD QUIZ PAGES - v2.0.0 */}
      <AppRoute
        exact
        path="/marketing/student/quiz/"
        layout={DefaultLayout}
        component={StudentQuiz}
      />
      <AppRoute
        exact
        path="/marketing/student/quiz/attempt/"
        layout={DefaultLayout}
        component={StudentQuizAttempt}
      />
      <AppRoute
        exact
        path="/marketing/student/quiz/start/"
        layout={DefaultLayout}
        component={StudentQuizStart}
      />
      <AppRoute
        exact
        path="/marketing/student/quiz/result/"
        layout={DefaultLayout}
        component={StudentQuizResult}
      />

      {/* FRONT SIDE NEW HELP CENTER PAGES ROUTERS - v1.3.0 */}
      <AppRoute
        exact
        path="/marketing/help-center/"
        layout={HelpCenterLayout}
        component={HelpCenter}
      />
      <AppRoute
        exact
        path="/marketing/help-center/faq/"
        layout={HelpCenterLayout}
        component={HelpCenterFAQ}
      />
      <AppRoute
        exact
        path="/marketing/help-center/guide/"
        layout={HelpCenterLayout}
        component={HelpCenterGuide}
      />
      <AppRoute
        exact
        path="/marketing/help-center/guide-single/:categoryslug/:articleslug"
        layout={HelpCenterLayout}
        component={HelpCenterGuideSingle}
      />
      <AppRoute
        exact
        path="/marketing/help-center/support/"
        layout={HelpCenterLayout}
        component={HelpCenterSupport}
      />

      {/* LANDING PAGES ROUTERS - END */}
      {/* --------------------------- */}

      {/* LMS ROUTERS - START */}
      <AppRoute
        exact
        path="/lms"
        layout={DashboardIndex}
        component={LMSDashboard}
      />
      <AppRoute
        exact
        path="/lms/dashboard"
        layout={DashboardIndex}
        component={LMSDashboard}
      />
      <AppRoute
        exact
        path="/lms/online-training/video/:topicId/:encryptedCareerDetailId?"
        layout={DashboardIndex}
        component={LMSVideo}
      />
      <AppRoute
        exact
        path="/lms/online-training/video/overview/:topicId/:encryptedCareerDetailId?"
        layout={DashboardIndex}
        component={LMSOverview}
      />
      <AppRoute
        exact
        path="/lms/online-training/project-selection/:careerDetailId?"
        layout={DashboardIndex}
        component={ProjectSelection}
      />      
      
      <AppRoute
        exact
        path="/lms/online-training/project/:careerDetailId/:projectId?"
        layout={DashboardIndex}
        component={LMSProject}
      />
      <AppRoute
        exact
        path="/lms/online-training/quiz/:careerDetailId"
        layout={DashboardIndex}
        component={LMSStudentTest}
      />
      <AppRoute
        exact
        path="/lms/online-training/quiz/start/:studentTestId/:reTake?"
        layout={DashboardIndex}
        component={LMSStudentTestStart}
      />
      <AppRoute
        exact
        path="/lms/online-training/quiz/result/:studentTestId"
        layout={DashboardIndex}
        component={LMSQuizResult}
      />

      {/*
			<AppRoute
				exact
				path="/lms/online-training/quiz/{courseId}"
				layout={DashboardIndex}
				component={LMSQuizLanding}
			/>				
			<AppRoute
				exact
				path="/lms/online-training/quiz/question/{quizId}/{questionId}"
				layout={DashboardIndex}
				component={LMSQuizQuestion}
			/>				 */}
      {/* LMS ROUTERS - END */}

      {/* ADMIN PAGES ROUTERS - START */}
      {/* --------------------------- */}

      {/* DASHBOARD ROUTERS */}

      {/* Internshipgate - Content admin */}
      <AppRoute
        exact
        path="/contentadmin"
        layout={DashboardIndex}
        component={Overview}
      />
      <AppRoute
        exact
        path="/cms"
        layout={DashboardIndex}
        component={Overview}
      />
      <AppRoute
        exact
        path="/contentadmin/dashboard"
        layout={DashboardIndex}
        component={Overview}
      />
      <AppRoute
        exact
        path="/cms/blog-details/:id?"
        layout={DashboardIndex}
        component={AddNewPost}
      />
      <AppRoute
        exact
        path="/dashboard/overview"
        layout={DashboardIndex}
        component={Overview}
      />
      <AppRoute
        exact
        path="/cms/internship-approval"
        layout={DashboardIndex}
        component={InternshipPostApproval}
      />
      <AppRoute
        exact
        path="/cms/banner-images"
        layout={DashboardIndex}
        component={BannerImageUpload}
      />
      <AppRoute
        exact
        path="/dashboard/analytics"
        layout={DashboardIndex}
        component={Analytics}
      />

      <AppRoute
        exact
        path="/dashboard/seo/page-seo-settings"
        layout={DashboardIndex}
        component={PageSEOSettings}
      />
      {/* COURSES ROUTERS */}
      <AppRoute
        exact
        path="/courses/all-courses"
        layout={DashboardIndex}
        component={AllCourses}
      />
      <AppRoute
        exact
        path="/courses/courses-category"
        layout={DashboardIndex}
        component={CoursesCategory}
      />
      <AppRoute
        exact
        path="/courses/category-single"
        layout={DashboardIndex}
        component={CategorySingle}
      />

      {/* USER ROUTERS */}
      <AppRoute
        exact
        path="/user/instructor"
        layout={DashboardIndex}
        component={Instructor}
      />
      <AppRoute
        exact
        path="/user/students"
        layout={DashboardIndex}
        component={Students}
      />

      {/* CMS ROUTERS */}
      <AppRoute
        exact
        path="/cms/cms-dashboard"
        layout={DashboardIndex}
        component={CMSDashboard}
      />
      <AppRoute
        exact
        path="/cms/all-posts"
        layout={DashboardIndex}
        component={AllPosts}
      />
      <AppRoute
        exact
        path="/cms/add-new-post"
        layout={DashboardIndex}
        component={AddNewPost}
      />
      <AppRoute
        exact
        path="/cms/category"
        layout={DashboardIndex}
        component={Category}
      />

      {/* BULK EMAIL ROUTES */}
      <AppRoute
        exact
        path="/bulkemail/add-new-template/:id?"
        layout={DashboardIndex}
        component={EmailTemplate}
      />
      <AppRoute
        exact
        path="/bulkemail/all-templates"
        layout={DashboardIndex}
        component={AllTemplates}
      />
      <AppRoute
        exact
        path="/bulkemail/email-images"
        layout={DashboardIndex}
        component={EmailImages}
      />

      {/* AUTHENTICATION ROUTERS */}
      <AppRoute
        exact
        path="/login"
        layout={DefaultLayout}
        component={StudentLogin}
      />
      <AppRoute
        exact
        path="/google-login"
        layout={DefaultLayout}
        component={GoogleAuthenticator}
      />
      <AppRoute
        exact
        path="/authentication/sign-in"
        layout={AuthLayout}
        component={SignIn}
      />
      <AppRoute
        exact
        path="/authentication/sign-up"
        layout={AuthLayout}
        component={SignUp}
      />

      <AppRoute exact path="/logout" layout={AuthLayout} component={Logout} />

      <AppRoute
        exact
        path="/authentication/forget-password"
        layout={AuthLayout}
        component={ForgetPassword}
      />
      <AppRoute
        exact
        path="/authentication/notifications"
        layout={DashboardIndex}
        component={Notifications}
      />

      {/* LAYOUTS ROUTERS - v1.3.0 */}

      <AppRoute
        exact
        path="/dashboard/layouts/layout-horizontal"
        layout={DashboardIndexTop}
        component={Overview}
      />
      <AppRoute
        exact
        path="/dashboard/layouts/layout-compact"
        layout={DashboardIndexCompact}
        component={Overview}
      />
      <AppRoute
        exact
        path="/dashboard/layouts/layout-vertical"
        layout={DashboardIndex}
        component={Overview}
      />

      {/* APPS ROUTERS - v1.3.0 */}
      {/* Mail app Router with context provider */}
      <MailRoutes
        exact
        path="/dashboard/mail"
        layout={DashboardIndex}
        component={Mail}
      />
      <MailRoutes
        exact
        path="/dashboard/mail-draft"
        layout={DashboardIndex}
        component={MailDraft}
      />
      <MailRoutes
        exact
        path="/dashboard/mail-details"
        layout={DashboardIndex}
        component={MailDetails}
      />

      {/* APPS ROUTERS - v1.4.0 */}
      {/* Task Kanban app Router with context provider */}
      <TaskKanbanRoutes
        exact
        path="/dashboard/task-kanban"
        layout={DashboardIndex}
        component={TaskKanban}
      />

      <ChatRoutes
        exact
        path="/dashboard/chat"
        layout={DashboardIndex}
        component={Chat}
      />

      {/* SETTINGS ROUTERS */}
      <AppRoute
        exact
        path="/settings/general"
        layout={DashboardIndex}
        component={General}
      />
      <AppRoute
        exact
        path="/settings/google"
        layout={DashboardIndex}
        component={Google}
      />
      <AppRoute
        exact
        path="/settings/social"
        layout={DashboardIndex}
        component={Social}
      />
      <AppRoute
        exact
        path="/settings/social-login"
        layout={DashboardIndex}
        component={SocialLogin}
      />
      <AppRoute
        exact
        path="/settings/payment"
        layout={DashboardIndex}
        component={Payment}
      />
      <AppRoute
        exact
        path="/settings/smtp-server"
        layout={DashboardIndex}
        component={SMTPServer}
      />

      {/* PROJECTS ROUTERS */}
      <AppRoute
        exact
        path="/dashboard/projects/grid"
        layout={DashboardIndex}
        component={ProjectGrid}
      />
      <AppRoute
        exact
        path="/dashboard/projects/list"
        layout={DashboardIndex}
        component={ProjectList}
      />
      <AppRoute
        exact
        path="/dashboard/projects/create-project"
        layout={DashboardIndex}
        component={CreateProject}
      />
      <AppRoute
        exact
        path="/dashboard/projects/single/budget"
        layout={DashboardIndex}
        component={ProjectBudget}
      />
      <AppRoute
        exact
        path="/dashboard/projects/single/files"
        layout={DashboardIndex}
        component={ProjectFiles}
      />
      <AppRoute
        exact
        path="/dashboard/projects/single/overview"
        layout={DashboardIndex}
        component={ProjectOverview}
      />
      <AppRoute
        exact
        path="/dashboard/projects/single/summary"
        layout={DashboardIndex}
        component={ProjectSummary}
      />
      <AppRoute
        exact
        path="/dashboard/projects/single/task"
        layout={DashboardIndex}
        component={ProjectTask}
      />
      <AppRoute
        exact
        path="/dashboard/projects/single/team"
        layout={DashboardIndex}
        component={ProjectTeam}
      />

      {/* REACT-BOOTSTRAP FORMS COMPOENTS ROUTERS */}
      <AppRoute
        exact
        path="/elements/forms/checks-and-radios"
        layout={DashboardIndex}
        component={ChecksRadios}
      />
      <AppRoute
        exact
        path="/elements/forms/floating-labels"
        layout={DashboardIndex}
        component={FloatingLabels}
      />
      <AppRoute
        exact
        path="/elements/forms/form-controls"
        layout={DashboardIndex}
        component={FormControls}
      />
      <AppRoute
        exact
        path="/elements/forms/form-text"
        layout={DashboardIndex}
        component={FormText}
      />
      <AppRoute
        exact
        path="/elements/forms/input-group"
        layout={DashboardIndex}
        component={BSInputGroup}
      />
      <AppRoute
        exact
        path="/elements/forms/layouts"
        layout={DashboardIndex}
        component={Layouts}
      />
      <AppRoute
        exact
        path="/elements/forms/range"
        layout={DashboardIndex}
        component={Range}
      />
      <AppRoute
        exact
        path="/elements/forms/select"
        layout={DashboardIndex}
        component={BSSelect}
      />
      <AppRoute
        exact
        path="/elements/forms/validation"
        layout={DashboardIndex}
        component={Validation}
      />

      {/* REACT-BOOTSTRAP COMPOENTS ROUTERS */}
      <AppRoute
        exact
        path="/elements/accordions"
        layout={DashboardIndex}
        component={Accordions}
      />
      <AppRoute
        exact
        path="/elements/alerts"
        layout={DashboardIndex}
        component={Alerts}
      />
      <AppRoute
        exact
        path="/elements/avatar"
        layout={DashboardIndex}
        component={AvatarStyles}
      />
      <AppRoute
        exact
        path="/elements/badges"
        layout={DashboardIndex}
        component={Badges}
      />
      <AppRoute
        exact
        path="/elements/breadcrumbs"
        layout={DashboardIndex}
        component={Breadcrumbs}
      />
      <AppRoute
        exact
        path="/elements/buttons"
        layout={DashboardIndex}
        component={Buttons}
      />
      <AppRoute
        exact
        path="/elements/button-group"
        layout={DashboardIndex}
        component={ButtonGroup}
      />
      <AppRoute
        exact
        path="/elements/cards"
        layout={DashboardIndex}
        component={Cards}
      />
      <AppRoute
        exact
        path="/elements/carousels"
        layout={DashboardIndex}
        component={Carousels}
      />
      <AppRoute
        exact
        path="/elements/close-button"
        layout={DashboardIndex}
        component={CloseButtons}
      />
      <AppRoute
        exact
        path="/elements/collapse"
        layout={DashboardIndex}
        component={Collapses}
      />
      <AppRoute
        exact
        path="/elements/dropdowns"
        layout={DashboardIndex}
        component={Dropdowns}
      />
      <AppRoute
        exact
        path="/elements/list-group"
        layout={DashboardIndex}
        component={Listgroups}
      />
      <AppRoute
        exact
        path="/elements/modal"
        layout={DashboardIndex}
        component={Modals}
      />
      <AppRoute
        exact
        path="/elements/navs"
        layout={DashboardIndex}
        component={Navs}
      />
      <AppRoute
        exact
        path="/elements/offcanvas"
        layout={DashboardIndex}
        component={BSOffcanvas}
      />
      <AppRoute
        exact
        path="/elements/overlays"
        layout={DashboardIndex}
        component={Overlays}
      />
      <AppRoute
        exact
        path="/elements/navbar"
        layout={DashboardIndex}
        component={Navbars}
      />
      <AppRoute
        exact
        path="/elements/pagination"
        layout={DashboardIndex}
        component={Paginations}
      />
      <AppRoute
        exact
        path="/elements/popovers"
        layout={DashboardIndex}
        component={Popovers}
      />
      <AppRoute
        exact
        path="/elements/progress"
        layout={DashboardIndex}
        component={Progress}
      />
      <AppRoute
        exact
        path="/elements/spinners"
        layout={DashboardIndex}
        component={Spinners}
      />
      <AppRoute
        exact
        path="/elements/tables"
        layout={DashboardIndex}
        component={Tables}
      />
      <AppRoute
        exact
        path="/elements/toasts"
        layout={DashboardIndex}
        component={Toasts}
      />
      <AppRoute
        exact
        path="/elements/tooltips"
        layout={DashboardIndex}
        component={Tooltips}
      />

      <AppRoute
        exact
        path="/dashboard/documentation"
        layout={DashboardIndex}
        component={Documentation}
      />
      <AppRoute
        exact
        path="/dashboard/changelog"
        layout={DashboardIndex}
        component={ChangeLog}
      />

      {/* ADMIN PAGES ROUTERS - END */}
      {/* ------------------------- */}
      {getResumeBuilderRoutes()}
      {/*Redirect*/}
      <Redirect to="/marketing/specialty/404-error/" />
    </Switch>
  );
}

export default AllRoutes;
