// import node module libraries
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Tab, Card, Nav, Breadcrumb } from "react-bootstrap";
import Url from "components/Url";

// import sub custom components

import OnlineTraining from "./CourseCMS/OnlineTraining";
import Projects from "./CourseCMS/Projects";
import ProjectTopics from "./CourseCMS/ProjectTopics";
import AssessmentQuiz from "./CourseCMS/AssessmentQuiz";
import Axios from "axios";

const CourseContent = (props) => {
  const courseId = props.match.params.courseId;
  const urls = Url();
  const url1 = urls["url"];
  const [pageHeading, setPageHeading] = useState();
  const [onlineTrainingList, setOnlineTrainingList] = useState();
  const [projectList, setProjectList] = useState();
  const [courseQuizList, setCourseQuizList] = useState();
  const [courseDetails, setCourseDetails] = useState();
  const [projectTopics, setProjectTopics] = useState([]);
  const [load, setLoad] = useState(false);
  const token = localStorage.getItem("admtoken");

  useEffect(() => {
    const api = url1 + "getLMSCourseData" + "/" + courseId;
    // const config = {
    //   method: "GET",
    // };
    // fetch(api, config)
    //   .then((res) => res.json())
    //   .then((data) => {
    //     console.log(data)
    //     console.log(data.contentList);
    //     setOnlineTrainingList(data.onlineTrainingList);
    //     setProjectList(data.projectList);
    //     setCourseQuizList(data.courseQuizList);
    //     setLoad(false)
    //   });

    Axios.get(api, {
      headers: {
        "Content-type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then(
      (response) => {
        //setPageHeading("Course Content - " + response.data.onlineTrainingList.[0].subject_name)
        setOnlineTrainingList(response.data.onlineTrainingList);
        setProjectList(response.data.projectList);
        setCourseQuizList(response.data.courseQuizList);
        setCourseDetails(response.data.courseDetails);
        //console.log(response.data.projectTopics);

        setProjectTopics(response.data.projectTopics);
        setLoad(false);
      },
      (error) => {
        var status = error.response.status;
        sessionStorage.setItem("redirectURL","/authentication/sign-in");
        if(status == 403) window.location.href = "/logout";
      }
    );
    setLoad(false);
  }, [load]);

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-2 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <Breadcrumb>
                <Breadcrumb.Item href="/cms">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/dashboard/course-cms">
                  LMS-Courses
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {courseDetails?.subject_name}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={8}>
          <h1 className="h2 fw-bold d-flex">
            {"Course Content for "}&nbsp;
            <span className="text-secondary">
              {" "}
              {courseDetails?.subject_name}-
              {courseDetails?.course_duration == 3
                ? "3 Months"
                : courseDetails?.course_duration == 1
                ? "1 Month"
                : courseDetails?.course_duration == 6
                ? "Job Oriented"
                : null}
            </span>{" "}
          </h1>
        </Col>

        <Col sm={4} className="d-flex justify-content-end">
          <a
            className="btn btn-primary btn-sm btn-block"
            href="/dashboard/course-cms"
          >
            Back to Course List
          </a>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Tab.Container defaultActiveKey="onlineTraining">
            <Card>
              <Card.Header className="border-bottom-0 p-0 bg-white">
                <Nav className="nav-lb-tab">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="onlineTraining"
                      className="mb-sm-3 mb-md-0 fs-4"
                    >
                      Online Training Videos
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="projects"
                      className="mb-sm-3 mb-md-0 fs-4"
                    >
                      Projects
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="quiz" className="mb-sm-3 mb-md-0 fs-4">
                      Quiz
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">
                <Tab.Content>
                  <Tab.Pane eventKey="onlineTraining" className="pb-4">
                    <OnlineTraining
                      setLoad={setLoad}
                      onlineTrainingList={onlineTrainingList}
                      courseId={courseId}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="projects" className="pb-4">
                    <ProjectTopics
                      setLoad={setLoad}
                      load={load}
                      projectTopics={projectTopics}
                      courseId={courseId}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="quiz" className="pb-4">
                    <AssessmentQuiz
                      setLoad={setLoad}
                      courseQuizList={courseQuizList}
                      courseId={courseId}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Card>
          </Tab.Container>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CourseContent;
