// import node module libraries
import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Col, Row, Card, Form, Button, Spinner} from 'react-bootstrap';
import Url from "../Url";
import MessageBox from 'components/common/MessageBox';
import { ToastContainer, toast } from 'react-toastify';
const BuyNowModal = (props) => {
	const product = props.product;
	const url1 = Url();
	const url = url1["url"];
	const razor_key = url1["razor_key"];
	
	console.log(product);

	const [couponCode, setCouponCode] = useState("");
	const [totalAmount, setTotalAmount] = useState(product.course_amount);
	const [discountPercent, setDiscountPercent] = useState(0);
	const [totalAmountPayable, setTotalAmountPayable] = useState(totalAmount);
	const [showMessage, setShowMessage] = useState(false);
	const [message, setMessage] = useState("");
	const [orderId,setOrderId] = useState("");
	const [loading,setLoading] = useState(false);
	const [orderDetails,setOrderDetails] = useState([]);

	//console.log(discountPercent);
	
	const checkOrder = () => {
		//console.log(sessionStorage.getItem("pendingOrderId"));

		const sessionObject = JSON.parse(sessionStorage.getItem("pendingOrderId"));
		const order_id = (sessionObject?.orderId);
		const customer_id = localStorage.getItem("studentId");
		const customer_type = product.customer_type;//1-student,2-employer 
		const total_amount = totalAmount; 
		const discount = discountPercent;
		const totalWithDiscount = Math.ceil(totalAmount * (1-discountPercent/100));
		console.log(totalWithDiscount);
		const gst_amount = Math.ceil(totalWithDiscount*(0.18));
		const total_amount_payable = totalWithDiscount + gst_amount;
		console.log(total_amount_payable);

		const waiver_code = couponCode;
		const item_id = product.subject_id;
		const item_type = product.itemType;//1-CAP,2-Resume,3-Mentorship,4-Exclusive internship services

		//single API to add/edit order details. If order id is not null then order will be updated, if order id is null 
		//then new order is created
		//const api = url + "createUpdateOrder";

        //Following are the fields those must be set to Buynow modal work correctly. Rest of the fields will come from teh API
		//item_type;item_id;customer_id;order_id;

		const api = url + "addProduct";

		fetch(api, {
			method: "POST",
			body: JSON.stringify({ 
				order_id, 
				customer_id, 
				customer_type,
				item_id,
				item_type,
				product
			}),
			headers: { "Content-Type": "application/json" },
		})
			.then((response) => {
				if(response.status > 400){
					setMessage("Cannot create/update order, contact support");
					setShowMessage(true);
					return;
				}
				return response.json();
			})
			.then((actualdata) => {
				if(actualdata.order_id > 0){
					setOrderId(actualdata.order_id);
					setOrderDetails(actualdata.orderDetails);
					sessionStorage.setItem("pendingOrderId",JSON.stringify({itemType:2, orderId: actualdata.order_id}));
				}
			});		
	};

	const handleApplyCoupon = () => {
		if (couponCode !== "") {
			const api = url + "getCouponDiscountByCode/" + couponCode + "/" + orderId;
			fetch(api, {
				method: "GET",
			})
			.then((apidata1) => {
				return apidata1.json();
			})
			.then((actualdata1) => {
				//console.log(actualdata1)

				if (actualdata1["message"] === "Already_used-or-Expired") {
					setMessage("Coupon Already Used");
					setShowMessage(true);
					return;
				} else if (actualdata1["message"] === "Invalid-happy-code") {
					setMessage("Invalid Coupon Code");
					setShowMessage(true);
					// toast.error("Invalid Coupon codes....", { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
					setDiscountPercent(0);
					return;
				}
				setMessage("Coupon Applied Successfully");
				setShowMessage(true);
				console.log(actualdata1);
				// setDiscountPercent(actualdata1.discount);
				// setTotalAmountPayable(
				// 	Math.round( 
				// 		Math.round(
				// 			(totalAmount -
				// 				(totalAmount * actualdata1.discount) / 100 +
				// 				Number.EPSILON) *
				// 				100
				// 		) / 100
				// 	)
				// );
				setOrderDetails(actualdata1.orderDetails);
				console.log(totalAmount);
				console.log(totalAmountPayable);
				console.log(discountPercent);
				//setTotalAmountPayable(totalAmount - discountAmount);

				//Math.round((amount + Number.EPSILON) * 100) / 100;
			});
		}
	} 

	const startPayment = () => {
		//var amount = discAmount === "" ? baseamount : discAmount;
		setLoading(true);

		//checkOrder();

		const service = product.itemType == 1 ? "CAP" : product.itemType == 2 ? "RESUME" : product.itemType == 3 ? "MENTORSHIP" : "CAP";
		const sessionObject = JSON.parse(sessionStorage.getItem("pendingOrderId"));
		const order_id = sessionObject?.orderId;
		const customer_id = localStorage.getItem("studentId");
		const email= localStorage.getItem("email");

		const api = url + "createPaymentInfo";
		fetch(api, {
			method: "POST",
			//body: JSON.stringify({ email, totalAmountPayable, service, order_id,customer_id }),
			body: JSON.stringify({ email, service, order_id,customer_id }),
			headers: { "Content-Type": "application/json" },
		})
			.then((apidata) => {
				//console.log(apidata)
				return apidata.json();
			})
			.then((actualdata) => {
				//console.log(actualdata)

				if (actualdata["message"] === "payment_information_created") {
					//return
					//razorPay();
					window.location.href="/payment";
				}else{
					toast.warning(actualdata.message);
				}
				setLoading(false);
			});
	};

	useEffect(() => {
		checkOrder();
	},[]);

	return (
		<Modal
			//{...props}
			show={props.show}
			onHide={props.onHide}			
			aria-labelledby="contained-modal-title-vcenter"
			centered
			scrollable
		>
			<ToastContainer />
			<Modal.Header closeButton>
				
				<Modal.Title id="contained-modal-title-vcenter">
					<span>Buy Now - {orderId > 0 && "Order#: " + orderId}</span>
				</Modal.Title>
			</Modal.Header>
 						
			<Modal.Body className="px-0">
				<Card>
					<Card.Body className="px-6 pt-0">
						{ showMessage &&
							<MessageBox message={message} />
						}
						<div className="mb-2 text-center">
							<h2 className="mb-1 fw-bold p-3">{product.subject_name}</h2>
							<p className="lead">You have chosen {product.subject_name} for {product.course_duration_desc}</p>
							<p>
								{orderDetails?.discount > 0 && <span className="text-danger text-decoration-line-through display-6">&#8377;{ Number(Math.ceil(orderDetails?.item_price *1.18)) }</span>} 
								<span className="display-4"> &#8377;{Number(orderDetails?.total_amount_payable)}</span>
							</p>
							<span>{`${Number(orderDetails?.total_amount)} + ${Number(orderDetails?.gst_amount)} (GST)`}</span>
							
						</div>
						{/* Form */}
						<Form>
							<Row>
								<span>Do you have a coupon?</span>
								<Col lg={6} md={12} className="mb-3">
									{/*  email */}
									<Form.Label>Coupon Code</Form.Label>
									<Form.Control
										type="text"
										id="coupon_code"
										placeholder="Enter your coupon code"
										required
										onChange={(e) => {
											setCouponCode(e.target.value);
										}}										
									/>
								</Col>
								<Col lg={6} md={12} className="mt-5">
									{/* Button */}
									<Button variant="secondary" className="btn-md" onClick={handleApplyCoupon}>
										Apply
									</Button>
								</Col>
							</Row>
						</Form>
						<Col lg={12} md={12} className="mb-3 d-grid gap-2 mt-4">
							{/* Button */}
							<Button variant="primary" className="btn btn-lg" onClick={startPayment}>
								{loading ? <Fragment><Spinner size="sm" /> Please wait....</Fragment> : "Proceed to Payment"}
							</Button>
						</Col>						
					</Card.Body>
				</Card>				
			</Modal.Body>
		</Modal>
	);
};

export default BuyNowModal;
