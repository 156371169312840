import React from 'react';

import { GoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import axios from "axios";
import Url from "../../components/Url";
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  Row,
  Col
} from 'react-bootstrap';

const GoogleAuthenticator = () => {
    const url1 = Url();
    const url = url1.url
    const url2 = url1["url1"]
    const [enablelogin, setEnablelogin] = useState(false);
    const credentialResponse =  async  (response) => {
        var obj = jwtDecode(response.credential);
        const postData = { email: obj.email, name: obj.name, picture:obj.picture }
        const APIUrl = url + "googleLogin";
        try {
          const res = await axios.post(APIUrl, postData)
          const success = res.data.message;
          const applicant_id = res.data.applicant_id;
          const student_id = res.data.id;
          const studentToken = res.data.token;
          const email = obj.email;
          if (success === "logged-in") {
  
            localStorage.setItem("stutoken", studentToken);
            localStorage.setItem("email", email);
            localStorage.setItem("applicantid", applicant_id);
            localStorage.setItem("studentId", student_id);
            localStorage.setItem("studentName", res.data.fullname);
            localStorage.setItem("token1", res.data.token1);
            localStorage.setItem("token2", res.data.token2);
          
            if(res.data.studentStatus == '1'){
              localStorage.removeItem("profileStatus");
            }else{
              localStorage.setItem("profileStatus", '0');
            }
            setEnablelogin(true); 
          }else{
            toast.error("Invalid Email or password");
          }                     
        } catch (e) {
          alert(e)
        }
    }

    if (enablelogin) {
      //if the internshipId field is set then redirect to the internship-details page and handle the automatic application
  
      if (!(sessionStorage.getItem("redirectURL") === null)) {
        var redirectURL = "/" + sessionStorage.getItem("redirectURL");
        //history.push(redirectURL);
        window.location.href = redirectURL;
        return;
      }
  
      if (localStorage.getItem("internshipIdApplication")) {
        window.location.href =
          "/internship-details/" +
          localStorage.getItem("internshipIdApplication");
        return;
      }
      window.location.href = "/student-dashboard";
      return 1;
    }

    return (

          <GoogleLogin
              text="signup_with"
              context='signup'
              onSuccess={credentialResponse}
              onError={() => {
                toast('Login Failed');
              }}
              width="100%"
              theme="filled_blue"

            />

    )
}

export default GoogleAuthenticator;