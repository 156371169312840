import React, { useEffect, useRef, useState, createRef } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  Svg,
  Path,
  Circle,
  Polyline,
} from "@react-pdf/renderer";
import { Html } from "react-pdf-html";
import {
  useA4Scale,
  TemplatePage,
  renderHTMLContent,
  getDisplayDate,
} from "../../utils";
import { Row, Col } from "react-bootstrap";
import DummyProfilePic from "../../../../../assets/images/background/profilepic.png";
import { GrMail } from "react-icons/gr";
import { FiPhone } from "react-icons/fi";
import { isEmpty } from "../../../foundation";

// const styles = StyleSheet.create({
//     page: { display: "flex", fontFamily: "Roboto", paddingVertical: "30px"},
//     wrapper: {
//       display: "flex",
//       flexDirection: "row",
//       flex: 1,
//     },
//     left: {
//       backgroundColor: "#3644a1",
//       width: "205px",
//       marginVertical: "-30px",
//     },
//     right: {
//       padding: "26px 20px 0px 54px",
//       width: "540px",
//     },
//     contentPadding: {
//       paddingLeft: "54px",
//       paddingRight: "54px",
//     },
//     profileImageWrapper: {
//       backgroundColor: "#273489",
//       display: "flex",
//       alignItems: "center",
//       padding: "30px",
//     },
//     profileImage: {
//       width: "120px",
//       height: "120px",
//       borderRadius: "50%",
//       border: "1px solid #273489",
//     },
// });

const styles = StyleSheet.create({
  page: {
    // display: "flex",
    fontFamily: "Roboto",
    paddingVertical: "30px",
  },
  wrapper: {
    backgroundColor: "#ffffff",
    marginTop: "-40px",
  },
  contact: {
    fontSize: "12px",
    textAlign: "left",
    color: "black",
    marginLeft: "31px",
  },
  profileImage: {
    width: "75px",
    height: "76.1px",
    borderRadius: "50%",
    border: "1px solid #273489",
    marginBottom: "45px",
    // marginBottom: "20px",
    marginTop: "34px",
  },
  right: {
    top: "-20px",
    // border: "2px solid red",
    display: "flex",
    flexDirection: "row-reverse",
    // justifyContent: "space-between",
  },
});

// const ContactInfo = ({ data: { Phone, Country, display_email } }) => (
//   <View style={{ padding: "24px" }}>
//     <Text
//       style={{
//         color: "#ffffff",
//         textDecoration: "underline",
//         fontSize: "12px",
//         marginBottom: "20px",
//       }}
//     >
//       CONTACT ME
//     </Text>
//     <View
//       style={{
//         display: "flex",
//         flexDirection: "row",
//         alignItems: "center",
//         marginBottom: "5px",
//       }}
//     >
//       <View
//         style={{
//           backgroundColor: "#ffffff",
//           padding: "3px",
//           borderRadius: "50%",
//           flex: "0 0 16px",
//         }}
//       >
//         <Svg
//           stroke="currentColor"
//           fill="none"
//           strokeWidth="2"
//           viewBox="0 0 24 24"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//           height="10px"
//           width="10px"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <Path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></Path>
//         </Svg>
//       </View>
//       <Text
//         style={{
//           color: "#0f0f0f",
//           fontSize: "10px",
//           fontWeight: "500"
//         }}
//       >
//         {Phone}
//       </Text>
//     </View>
//     <View
//       style={{
//         display: "flex",
//         flexDirection: "row",
//         alignItems: "center",
//         marginBottom: "5px",
//       }}
//     >
//       <View
//         style={{
//           backgroundColor: "#ffffff",
//           padding: "3px",
//           borderRadius: "50%",
//           flex: "0 0 16px",
//         }}
//       >
//         <Svg
//           stroke="#ffffff"
//           fill="#000000"
//           strokeWidth="2"
//           viewBox="0 0 24 24"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//           height="10px"
//           width="10px"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <Path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></Path>
//           <Polyline points="22,6 12,13 2,6"></Polyline>
//         </Svg>
//       </View>
//       <Text
//         style={{
//           color: "#ffffff",
//           fontSize: "10px",
//           paddingLeft: "10px",
//         }}
//       >
//         {display_email}
//       </Text>
//     </View>
//     <View
//       style={{
//         display: "flex",
//         flexDirection: "row",
//         alignItems: "center",
//         borderBottom: "1px solid #ffffff",
//         paddingBottom: "20px",
//       }}
//     >
//       <View
//         style={{
//           backgroundColor: "#ffffff",
//           padding: "3px",
//           borderRadius: "50%",
//           flex: "0 0 16px",
//         }}
//       >
//         <Svg
//           stroke="#ffffff"
//           fill="currentColor"
//           strokeWidth="2"
//           viewBox="0 0 24 24"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//           height="10px"
//           width="10px"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <Path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></Path>
//           <Circle fill="#ffffff" cx="12" cy="10" r="3"></Circle>
//         </Svg>
//       </View>
//       <Text
//         style={{
//           color: "#ffffff",
//           fontSize: "10px",
//           paddingLeft: "10px",
//         }}
//       >
//         {Country}
//       </Text>
//     </View>
//   </View>
// );

const AdditionalInfo = ({
  data: {
    Address,
    City,
    Postal_Code,
    Driving_License,
    Nationality,
    dob,
    Place_of_Birth,
  
  },
}) => {
  const details = [
    {
      label: "Address",
      value: `${Address || ""} ${City || ""} ${Postal_Code || ""}`.trim(),
    },
    {
      label: "Driving License",
      value: Driving_License,
    },
    {
      label: "Nationality",
      value: Nationality,
    },
    {
      label: "Date of Birth",
      value: dob,
    },
    {
      label: "Place of Birth",
      value: Place_of_Birth,
    },
   
  ];
  return details.map((info) =>
    info.value ? (
      <View
      // style={{ padding: "12x 0px" }}
      >
        <Text
          style={{
            color: "black",
            fontWeight: 500,
            fontSize: "11px",
          }}
        >
          {info.label}
        </Text>
        <Text
          style={{
            marginTop: "3px",
            color: "black",
            fontSize: "10px",
          }}
        >
          {info.value}
        </Text>
      </View>
    ) : (
      <View />
    )
  );
};


const Hobbies = ({ data: { hobbies } }) => {
  if (!hobbies || !hobbies.length) {
    return <View />;
  }
  return (
    <View style={{  
      paddingBottom: "12px",
    marginTop: "20px",
    marginBottom: "4px",
    left: "33px", }}>
      <Text
        style={{
          color: "#a4a5ac",
            fontWeight: 700,
            fontSize: "12px",
        
        }}
      >
        HOBBIES
      </Text>

      {hobbies.map((s) => (
        <Text
          style={{
            marginTop: "3px",
            color: "#636a78",
            fontSize: "11px",
          }}
        >
          {s.hobbies}
        </Text>
      ))}
    </View>
  );
};

const ContactInfo = ({
  data: {
    Address,
    City,
    Postal_Code,
    Driving_License,
    Nationality,
    dob,
    Place_of_Birth,
    Phone,
    Country,
    First_Name,
    Last_Name,
    Wanted_Job_Title,
    display_email,
    profileImage,
    template_color_code,
    professionalSummary: { Description = "" } = {},
  },
}) => (
  <>
    <View style={styles.contact}>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Image
          src={profileImage || DummyProfilePic}
          style={styles.profileImage}
        />
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
          }}
        >
          <Text
            style={{
              fontSize: "26px",
              fontWeight: 600,
              color:template_color_code,                    /* #0f0f0f*/
              marginTop: "45px",
            }}
          >
            {First_Name && Last_Name ? First_Name +" " + Last_Name :"Your Name" }
            {/* YOUR NAME */}
          </Text>

          <Text
            style={{
              fontSize: "12px",
              color:template_color_code,
              marginTop: "6px",
              marginLeft: "3px",
              fontWeight: "600",
              marginBottom: "6px",
              width: "200px",
            }}
          >
            {Wanted_Job_Title ? Wanted_Job_Title : "Job Title"}
            {/* CURRENT JOB TITLE */}
          </Text>
          <Text
            style={{
              lineHeight: "1.25",
              fontWeight: "500",
            }}
          >
            {Address}
          </Text>
          <Text
            style={{
              lineHeight: "1.25",
              fontWeight: "500",
            }}
          >
            {City && City + "-"} {Postal_Code && Postal_Code + ", "} 
            {Country && Country}
          </Text>
          <Text
            style={{
              lineHeight: "2",
              fontWeight: "700",
              // textDecoration: "underline",
            }}
          >
            {Nationality && "Nationality : "}
            <Text style={{ fontWeight: "500" }}>{Nationality}</Text>
          </Text>
        </View>
      </View>
    </View>
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "right",
        marginRight: "30px",
        top: "-105px",
        // top: "-85px",
        fontSize: "10px",
      }}
    >
      {/* <Text style={{ lineHeight: "2", fontWeight: "500" }}>{"www.website.com"}</Text> */}
      <Text style={{ lineHeight: "2", fontWeight: "500",color:template_color_code, }}>
        {display_email ? display_email : "email@example.com"}
      </Text>
      <Text style={{ lineHeight: "2", fontWeight: "500",color:template_color_code, }}>
        {Phone ? Phone : "0123456789"}
      </Text>

      <Text
        style={{
          lineHeight: "2",
          fontWeight: "700",
          
        }}
      >
        {Driving_License && "Driving License NO. - "}

        <Text style={{ fontWeight: "500" }}>{Driving_License}</Text>
      </Text>
      <Text
        style={{
          lineHeight: "2",
          fontWeight: "700",
          // textDecoration: "underline",
        }}
      >
        {dob && "Date of Birth : "}{" "}
        <Text style={{ fontWeight: "500" }}>{dob}</Text>
      </Text>
      <Text
        style={{
          lineHeight: "2",
          fontWeight: "700",
          // textDecoration: "underline",
        }}
      >
        {Place_of_Birth  && "Place of Birth : "}{" "}
        <Text style={{ fontWeight: "500" }}>{Place_of_Birth}</Text>
      </Text>
    </View>

    
      <View
        style={{
          padding: "12px 16px 12px 20px",
          bottom: "75px",
          width: "100%",
          backgroundColor: template_color_code,      
          fontWeight: "400",
          fontSize: "8px",
          color: "#ffffff",
          fontFamily: "Roboto",
          fontStyle: "normal",
          letterSpacing: "1px",
          textAlign: "center",
        }}
      >
        <Text
          style={{
            fontWeight: 700,
            fontSize: "20px",
            marginBottom: "8px",
            // color: "black",
            // textDecoration: "underline",
          }}
        >
          Profile Summary
        </Text>
        <Text
          style={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            lineHeight: "1.5px",
            letterSpacing: "1.5px",
            textAlign:"justify",
            color:"#000000",
            fontWeight:700,
          }}
        >
          {renderHTMLContent(Description)}
        </Text>
      </View>
    )
  </>
);

const Skills = ({ data: { skills } }) => {
  if (!skills || !skills.length) {
    return <View />;
  }
  return (
    <View
      style={{
        paddingBottom: "12px",
        marginTop: "20px",
        marginBottom: "4px",
        left: "33px",
      }}
    >
      <Text
        style={{
          color: "#a4a5ac",
          fontSize: "16px",
          fontWeight: "700",
          marginBottom: "8px",
        }}
      >
        Skills
      </Text>
      {skills.map((s) => (
        <Text
          style={{
            color: "#636a78",
            fontSize: "10px",
            paddingVertical: "4px",
          }}
        >
          {s.skill_name}
        
        </Text>
      ))}
    </View>
  );
};

const Languages = ({ data: { languages } }) => {
  if (!languages || !languages.length) {
    return <View />;
  }
  return (
    <View
      style={{
        padding: "24px",
        paddingVertical:"12px",
        left: "8px",
        marginTop: "4px",
      }}
    >
      <Text
        style={{
          color: "#a4a5ac",
          fontSize: "16px",
          fontWeight: "700",
          marginBottom: "8px",
        }}
      >
        Languages
      </Text>
      {languages.map((s) => (
        <Text
          style={{
            color: "#636a78",
            fontSize: "10px",
            paddingBottom: "5px",
          }}
        >
          {s.language_name}
        </Text>
      ))}
    </View>
  );
};

const RenderView = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const { title, subTitle, startDate, endDate, description, city } = fieldMap;

  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View style={{ marginLeft: "32px", marginBottom: "8px" }}>
      <Text
        style={{
          fontSize: "16px",
          fontWeight: 900,
          color: "#a4a5ac",
          marginBottom:"4px"
        }}
      >
        {heading}
      </Text>
      {items.map((item) => (
        <View style={{ diplay: "inline-flex", marginVertical: "12px" }}>
          <Text
            style={{ fontSize: "14px", fontWeight: "500", color: "#636a78" }}
          >
            {item[title]}
            {/* Title */}
          </Text>
          <Text
            style={{ fontSize: "12px", color: "#636a78", marginTop: "3px" }}
          >
            {item[subTitle]}
            {/* SubTitle */}
          </Text>
          <Text
            style={{
              fontSize: "10px",
              color: "#363434",
              marginTop: "3px",
              fontWeight: "400",
            }}
          >
            {getDisplayDate(item[startDate], item[endDate])}{" "}
            {item[city] ? ` ,  ${item[city]}` : ""}
          </Text>

          {item[description] && (
            <View style={{ marginTop: "5px" }}>
              {renderHTMLContent(item[description], {
                "*": { color: "#636a78",textAlign:"justify" },
              })}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

const CustomSection = ({ data, dataKey, heading, fieldMap = {} }) =>{
  const items=data[dataKey];
  console.log("data key", data[dataKey]);
  const {title, city, startDate, endDate, description,custom_title }=fieldMap;
    if(!items||!items.length){
       return <View/>;
    }
    return (
      <View
        style={{
          marginLeft: "32px", marginBottom: "4px",paddingTop:"10px",
        }}
      >
        <Text
          style={{
            fontSize: "16px",
            fontWeight: 600,
            color: "#a4a5ac",
          }}
        >
        
          {heading}
        </Text>
        {items.map((item) => (
          
          <View style={{ marginBottom: "8px" }} wrap={true}>
            
            <View
              style={{
                fontSize: "16px",
                fontWeight: "900",
                 color: "#a4a5ac",
                
              }}
            >
              <Text>{item[custom_title]}</Text>
              
            </View>
            <View
              style={{
                fontSize: "13px",
                fontWeight: "500",
                marginTop: "10px",
                color: "#a4a5ac",
              }}
            >
              <Text>{item[title]}</Text>
              
            </View>
            <Text
              style={{
                fontSize: "10px",
                color: "#363434",
                marginTop: "3px",
                marginBottom: "4px",
              }}
            >
              {getDisplayDate(item[startDate], item[endDate])}
  
              {item[city] ? ` ,  ${item[city]}` : ""}
              
            </Text>

            {item[description] && (
              <View
                style={{
                  marginTop: "4px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                }}
              >
              {console.log(dataKey, item[description])}

                {renderHTMLContent(item[description], {
                  "*": {
                    color: "#636a78",
                    fontWeight: 500,
                    fontSize: "12px",
                    paddingRight: "5px",
                    alignItems: "baseline",
                    position: "sticky",
                    textAlign: "justify",
                  },
                })}
              </View>
            )}


          </View>
        ))}
      </View>
    );
};

const References = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const { title, subTitle, phoneNo, emailId } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  return (
    <View style={{ marginLeft: "32px" }}>
      <Text
        style={{
          fontSize: "16px",
          fontWeight: 700,
          color: "#a4a5ac",
          // marginTop: "12px",
        }}
      >
        {heading}
      </Text>
      {items.map((item) => (
        <View style={{ marginTop: "8px" }}>
          <Text
            style={{ fontSize: "14px", fontWeight: "500", color: "#636a78" }}
          >
            {item[title]}
          </Text>
          <Text
            style={{ fontSize: "12px", color: "#636a78", marginTop: "3px" }}
          >
            {item[subTitle]}
          </Text>
          <Text
            style={{ fontSize: "11px", color: "#a6a6a6", marginTop: "3px" }}
          >
            {item[phoneNo]}
          </Text>
          <Text
            style={{ fontSize: "11px", color: "#a6a6a6", marginTop: "3px" }}
          >
            {item[emailId]}
          </Text>
        </View>
      ))}
    </View>
  );
};

const Template4 = ({ data }) => {
  const {
    profileImage,
    First_Name,
    Last_Name,
    Wanted_Job_Title,
    template_color_code,
    //professionalSummary: { Description = "" } = {},
  } = data;
  return (
    <TemplatePage data={data}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.wrapper}>
            <View>
              {/* <View>
                <Image
                  src={profileImage || DummyProfilePic}
                  style={styles.profileImage}
                />
              </View> */}
              {/* <AdditionalInfo data={data} /> */}
              <ContactInfo data={data} />
            </View>
            <View
              style={{
                position: "absolute",
                height: "100vh",
                width: "164px",
                left: "0px",
                top: "-10px",
              }}
              fixed
              render={({ pageNumber }) => (
                <View
                  style={{
                    height: "100%",
                    backgroundColor:
                      pageNumber === 1 ? "transparent" : "#ffffff",
                  }}
                ></View>
              )}
            />
            <View style={styles.right}>
              <View
                style={{
                  // border: "2px solid blue",
                  flex: 1,
                  // width: "200px",
                  marginRight: "12px",
                  paddingRight:"25px",
                }}
              >
                <RenderView
                  data={data}
                  dataKey="education"
                  heading="Education"
                  fieldMap={{
                    title: "institute_name",
                    subTitle: "degree",
                    startDate: "start_date",
                    endDate: "end_date",
                    city: "city",
                    description: "Description",
                  }}
                />
                <View style={{ marginTop: "20px" }}>
                  <RenderView
                    data={data}
                    dataKey="courses"
                    heading="Courses"
                    fieldMap={{
                      title: "course_name",
                      subTitle: "institute_name",
                      startDate: "start_date",
                      city: "city",
                      endDate: "end_date",
                    }}
                  />
                </View>
                <Skills data={data} />
                <Hobbies data={data}/>
                <Languages data={data} />
              </View>
              <View
                style={{
                  paddingRight:"15px",
                  flex: 1,
                }}
              >
                <RenderView
                  data={data}
                  dataKey="employmentHistory"
                  heading="Work Experience"
                  fieldMap={{
                    title: "Employer",
                    subTitle: "Job_title",
                    startDate: "start_date",
                    endDate: "end_date",
                    city: "city",
                    description: "Description",
                  }}
                />

                <RenderView
                  data={data}
                  dataKey="internships"
                  heading="Internships"
                  fieldMap={{
                    title: "employer_name",
                    subTitle: "job_title",
                    startDate: "start_date",
                    endDate: "end_date",
                    city: "city",
                    description: "description",
                  }}
                />

                <RenderView
                  data={data}
                  dataKey="activities"
                  heading="Extra_Curricular_Activity"
                  fieldMap={{
                    title: "employer_name",
                    subTitle: "function_title",
                    startDate: "start_date",
                    endDate: "end_date",
                    description: "description",
                  }}
                />
                

                <References
                  data={data}
                  dataKey="References"
                  heading="References"
                  fieldMap={{
                    title: "ref_name",
                    subTitle: "company_name",
                    phoneNo: "phone_no",
                    emailId: "ref_email",
                  }}
                />

                <CustomSection
                data={data}
                  dataKey="customSection"
                  //heading="custom_title"
                  fieldMap={{
                    custom_title:"custom_title",
                    title: "section_name",
                    city: "city",
                    startDate: "start_date",
                    endDate: "end_date",
                    description: "description",
                  }}
                />
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </TemplatePage>
  );
};

Template4.displayName = "Template4";
export default Template4;