// import node module libraries

import { Link } from 'react-router-dom';
import {
    Container,
    Button,
    Row,
    Col,
    Navbar,
    Card,
    Form,
    Nav,
    Image
} from 'react-bootstrap';

// import media files
//import Avatar from 'assets/images/avatar-1.jpg';
import { useState, useRef } from 'react';
import Url from "../Url";

const EditProfile = () => {
    const url1 = Url();
	const url = url1["url"];
    const s3URL = url1["S3"];   
    
    const hiddenFileInput = useRef(null);
    //const [avatar, setAvatar] = useState(Avatar);
    const [avatar, setAvatar] = useState(s3URL+"Account.svg");
    const handleChange = (e) => {
        setAvatar(s3URL+e.target.files[0].filename);
    }
    return (
        <section className="pt-5 pb-6">
            <Container>
                <Row>
                    <Col lg={3} sm={12}>
                        <Card className="mb-4">
                            <Navbar expand="lg" className="sidebar-menu">
                                <Link className="d-lg-none" to="#" >Menu</Link>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" className="p-0 focus-none border-0" label="Responsive Menu">
                                    <span className="navbar-toggler-icon"></span>
                                </Navbar.Toggle>
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="nav flex-column w-100" as="ul" activeKey="0">
                                        <Nav.Item as="li">
                                            <Link className="nav-link" to="#">Dashboard</Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Link className="nav-link active" to="#">Profile</Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Link className="nav-link " to="#">Change password</Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Link className="nav-link " to="#"> Post Internship</Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Link className="nav-link " to="#">Sign Out</Link>
                                        </Nav.Item>
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                        </Card>
                    </Col>
                    <Col className="col-lg-9">
                        <div className="mt-6 mt-lg-0 mb-4">
                            <div className="border-bottom mb-4 pb-4">
                                <h2 className="mb-0">Profile</h2>
                            </div>
                            <div>
                                <Form className="row">
                                    <Col xs={12} className="mb-3">
                                        <h3>Personal Details</h3>
                                        <p>Edit your organization details, address and website etc...</p>
                                    </Col>
                                    <Form.Group as={Col} lg={6} className="mb-3" controlId="full-name">
                                        <Form.Label>Full Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter full Name" required />
                                    </Form.Group>
                                    <Form.Group as={Col} lg={6} className="mb-3" controlId="mobile-no">
                                        <Form.Label>Mobile No.</Form.Label>
                                        <Form.Control type="number" maxLength={10} placeholder="Enter Mobile No." required />
                                    </Form.Group>
                                    <Form.Group as={Col} lg={6} className="mb-3" controlId="email">
                                        <Form.Label>Email Account</Form.Label>
                                        <Form.Control type="email" placeholder="Enter Email Account" required />
                                    </Form.Group>
                                    <Col xs={12} className="mb-3 mt-6">
                                        <h3>Organization Details</h3>
                                        <p>Edit your organization details, address and website etc...</p>
                                    </Col>
                                    <Col xs={12} className="mb-5">
                                        <div className="d-flex">
                                            <div className="d-flex align-items-center position-relative ">
                                                <span>
                                                    <Image className="image avatar avatar-md rounded-circle" src={avatar} alt="Image" />
                                                    <div className="file-upload">
                                                        <Form.Control type="file" className="file-input" ref={hiddenFileInput} onChange={handleChange} />
                                                    </div>
                                                </span>
                                                <div className="ms-3">
                                                    <h4 className="mb-1">Organization logo</h4>
                                                    <small>Max file size: 1Mb and max resolution: 500px x 500px. File type: jpeg, jpg, png, gif, bmp</small>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Form.Group as={Col} lg={6} className="mb-3" controlId="organization-name">
                                        <Form.Label>Organization name</Form.Label>
                                        <Form.Control type="text" placeholder="e.g. internshipgate" required />
                                    </Form.Group>
                                    <Col lg={6} className="mb-3 "></Col>
                                    <Form.Group as={Col} lg={6} className="mb-3" controlId="organization-descriptions">
                                        <Form.Label>Organization Descriptions</Form.Label>
                                        <Form.Control as="textarea" rows={3} placeholder="Write Description" required />
                                    </Form.Group>
                                    <Col lg={6} className="mb-3 "></Col>
                                    <Form.Group as={Col} lg={6} className="mb-3" controlId="address">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control type="text" placeholder="e.g. HSR Layout, Bangalore" required />
                                    </Form.Group>
                                    <Col lg={6} className="mb-4 "></Col>
                                    <Form.Group as={Col} lg={6} className="mb-4" controlId="website">
                                        <Form.Label>Website</Form.Label>
                                        <Form.Control type="text" placeholder="e.g. https://internshipgate.com/" required />
                                    </Form.Group>
                                    <Col lg={6}></Col>
                                    <Col lg={6}>
                                        <Button variant="primary" type="submit">
                                            Update Profile
                                        </Button>
                                        <Button variant="outline-primary" className="ms-1" type="button">
                                            Cancel
                                        </Button>
                                    </Col>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section >
    )
}

export default EditProfile