export function convertToHoursMinutes(video_length_in_seconds) {
  let hours = Math.floor(video_length_in_seconds / 3600);
  let minutes = Math.floor((video_length_in_seconds % 3600) / 60);
  let time = "";

  if (hours > 0) {
    hours === 1 ? (time += hours + " hr") : (time += hours + " hrs");
  }
  if (minutes > 0) {
    minutes === 1
      ? (time += " " + minutes + " min")
      : (time += " " + minutes + " mins");
  }

  return time.trim();
}
