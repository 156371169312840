// import node module libraries
import { Card } from 'react-bootstrap';

const RightIconInfoCard = ({ item }) => {
    return (
        <Card className='border'>
            <Card.Body>
                <div className="d-flex justify-content-between">
                    <div>
                        <h2 className="mb-1">{item.text}</h2>
                        <span>{item.subText}</span>
                    </div>
                    <div>
                        <div style={{backgroundColor: `${item.bg}`}} className={`icon-shape icon-md rounded-circle text-${item.color}`}>
                            {item.icon}
                        </div>
                    </div>
                </div>
                <div>
                </div>
            </Card.Body>
        </Card>
    )
}
export default RightIconInfoCard;
