import React from "react";
import cx from "classnames";
import "./index.css";
import { string, bool } from "prop-types";

const InputField = React.forwardRef((props, ref) => {
  const { type, className, stretch, ...otherProps } = props;
  const Element = type === "textarea" ? "textarea" : "input";
  return (
    <Element
      ref={ref}
      type={type}
      className={cx("atom__input", { "atom__input--stretch": stretch }, className)}
      {...otherProps}
    />
  );
});

InputField.propTypes = {
  type: string,
  className: string,
  stretch: bool
};

InputField.defaultProps = {
  type: "text",
  className: "",
  stretch: false
};

export default InputField;
