import React, { Fragment, useMemo, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination,
	useRowSelect
} from 'react-table';
import {
	Col,
	Row,
	Dropdown,
	Card,
	Form,
	Button,
	Table,
    Image,
    Spinner

} from 'react-bootstrap';

import { Trash, Send, Inbox, MoreVertical } from 'react-feather';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';
import Checkbox from 'components/elements/advance-table/Checkbox';
import DotBadge from 'components/elements/bootstrap/DotBadge';
import Url from 'components/Url';


const EmailImages = () => {
	const url1 = Url();
	const url = url1["url"];
	const s3 = url1["s3"];

    const [isUploading, setIsUploading] = useState(false);
    const [emailImages, setEmailImages] = useState([]);
    const [image,setImage] = useState();
    const [load,setLoad] = useState(false);

    useEffect(() => {
        console.log("here");

        const api = url + "getEmailImages";
    
        fetch(api, {
          method: "GET", 
        })
          .then((apidata) => {
            return apidata.json();
          })
          .then((actualdata) => {
            console.log(actualdata);
            if (actualdata.emailImages.length > 0) {
              setEmailImages(actualdata.emailImages);
            }
          });
          setLoad(false);
      }, [load]);

    const handleFileChange = (e) => {
        if (e.target.files) {
            setImage(e.target.files[0]);
        }
    };

    const handleImage = () => {
        console.log(image);
        const fd = new FormData();
        fd.append("pic", image);
        const api = url + "uploadEmailImage";
        const config = {
          method: "POST",
          body: fd,
        };
        fetch(api, config)
          .then((res) => res.json())
          .then((data) => {
            if (data.message) {
              setImage(data.message);
              setIsUploading(false);
              setLoad(true);
            }
        });
    };

	// The forwardRef is important!!
	// Dropdown needs access to the DOM node in order to position the Menu
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
		</Link>
	));

	const deleteEmailImage = (id) => {
		if (id && window.confirm("Are you sure? Want to delete Image?")) {
		  const api = url + "deleteEmailImage/" + id;
		  const config = {
			method: "DELETE",
		  };
		  fetch(api, config)
			.then((res) => res.json()) 
			.then((data) => {
			  if (data.message === "Record Deleted") {
                setLoad(true);
				alert("Image Deleted Successfuly");
			  }
			});
		}
	  };

	const columns = useMemo(
		() => [
			{ accessor: 'id', Header: 'id' },
			{ accessor: 'created_at', Header: 'created_at' },
			{
				accessor: 'image_path',
				Header: 'Image Preview',
				Cell: ({ value }) => {
					return (
                        <Image
                        thumbnail
                        width={"20%"}
                        src={
                          s3 +
                          value
                        }
                      />
					);
				}
			},
            { accessor: 'action', Header: 'Action',
            Cell: ({value, row}) => {
                return(
                    <Button className="btn-sm btn-outline-danger btn-white m-1" onClick={() => deleteEmailImage(row.original.id)}>
                        Delete
                    </Button>
                )
            }
        },            
		],
		[]
	);

    console.log(emailImages);

	const data = useMemo(() => emailImages, [emailImages]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		pageCount,
		prepareRow,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 30,
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination,
		useRowSelect,
		(hooks) => {
			hooks.visibleColumns.push((columns) => [
				...columns
			]);
		}
	);

	const { pageIndex, globalFilter } = state;



    return (
        <Fragment>
		<Form>
			{/* Card */}
			<Card className="mb-3  border-0">
				<Card.Header className="border-bottom px-4 py-3">
					<h4 className="mb-0">Email Images</h4>
				</Card.Header>
				{/* Card body */}
				<Card.Body>
					<Form.Group className="mb-1 input-group">				
					<Row>
                    <Form.Control
							id="inputfavicon"
							type="file"
							className="form-control mt-2"
							onChange={handleFileChange}
						/>
						{/* <Form.Label
							htmlFor="inputfavicon"
							className="input-group-text mb-0"
						>
							Upload
						</Form.Label> */}
						<Form.Text className="text-muted">
							Upload your course image here.Important guidelines: max 100kb; .jpg, .jpeg,. gif, or .png..
						</Form.Text>
					</Row>
						</Form.Group>
			{/* Button */}
			<div className="d-flex justify-content-between">
				<Button disabled={isUploading ? true : false} variant="success" 
                            onClick={handleImage}
                            >
					{isUploading
					?
					<Spinner animation="border" variant="light" size="sm" />
					:
					"Save"
				}
				</Button>
			</div>                        
				</Card.Body>
			</Card>


		</Form>


        <Row>
            <Col lg={12} md={12} sm={12}>
                <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
                    <div className="mb-3 mb-md-0">
                        <h1 className="mb-1 h2 fw-bold">Email Images List</h1>
                    </div>
                    <div>
                    </div>
                </div>
            </Col>
        </Row>

        <Row>
            <Col lg={12} md={12} sm={12}>
                <Card>
                    <Card.Body className="p-0">
                        {/**
                        <div className=" overflow-hidden">
                            <Row>
                                <Col
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    className="mb-lg-0 mb-2 px-5 py-4"
                                >
                                    <GlobalFilter
                                        filter={globalFilter}
                                        setFilter={setGlobalFilter}
                                        placeholder="Search Course"
                                    />
                                </Col>
                            </Row>
                        </div>
                        */}

                        <div className="table-responsive" style={{overflow: "auto",height:"500px"}}>
                            <Table {...getTableProps()} className="text-nowrap" style={{height:"400px"}}>
                                <thead className="table-light">
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th {...column.getHeaderProps()}>
                                                    {column.render('Header')}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td {...cell.getCellProps()}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>

                        {/* Pagination @ Footer */}
                        <Pagination
                            previousPage={previousPage}
                            pageCount={pageCount}
                            pageIndex={pageIndex}
                            gotoPage={gotoPage}
                            nextPage={nextPage}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        </Fragment>        
	);
};
export default EmailImages;
